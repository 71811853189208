<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '25%' }" bordered>
    <a-descriptions-item label="GCPJ" :span="3">
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>

    <a-descriptions-item label="Número CNJ" :span="3">
      {{ formatCnj(props.compromisso.data.numeroCnj) }}
    </a-descriptions-item>

    <a-descriptions-item label="Cliente" :span="3">
      <a-tooltip>
        <template #title> Ir para página do cliente </template>
        <a :href="`/contato/${props.compromisso.data.clienteCodigo}`">{{
          props.compromisso.data.clienteNome
        }}</a>
      </a-tooltip>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.agenciaNome)"
      label="Agência"
      :span="3"
    >
      <a-tooltip>
        <template #title> Ir para página da agência </template>
        <a :href="`/agencia/${props.compromisso.data.agenciaCodigo}`">{{
          props.compromisso.data.agenciaNome
        }}</a>
      </a-tooltip>
    </a-descriptions-item>

    <a-descriptions-item label="Conta" :span="3">
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>

    <a-descriptions-item label="Carteira/Contrato" :span="3">
      {{ props.compromisso.data.carteira }}/{{
        props.compromisso.data.contrato
      }}
    </a-descriptions-item>

    <a-descriptions-item label="Valor da causa" :span="3">
      {{ formatDinheiro(props.compromisso.data.valorCausa) ?? '-' }}
    </a-descriptions-item>

    <a-descriptions-item label="Data remessa" :span="3">
      {{ onlyDate(props.compromisso.data.dataRemessa) ?? '-' }}
    </a-descriptions-item>

    <a-descriptions-item label="Dejur" :span="3">
      {{ props.compromisso.data.dejur ?? '-' }}
    </a-descriptions-item>

    <a-descriptions-item label="Advogado banco" :span="3">
      {{ props.compromisso.data.advogadoBanco ?? '-' }}
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
