<script setup lang="ts">
import { SaveOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { cloneDeep, isEqual } from 'lodash-es';
import { reactive, ref, toRaw, watch } from 'vue';
import { useJobCommand } from '~/composables/useJobCommand';
import { TipoJobEnum } from '~/enums/tipoJobEnum';

export type PreSubmit = (data: any) => Promise<boolean>;
export type PostSubmit = (status: any, data: any) => Promise<void>;

interface Props
{
  command: string
  jobType?: TipoJobEnum
  jobCommandPath?: string
  initialModel?: object
  preSubmit?: PreSubmit
  postSubmit?: PostSubmit
  submitButtonDisabled?: boolean
  submitButtonBlock?: boolean
  submitButtonText?: string
  submitButtonIconVisible?: boolean
  submitSuccessResultVisible?: boolean
  submitErrorNotificationVisible?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  jobType: TipoJobEnum.Command,
  submitButtonText: 'Salvar',
  submitSuccessResultVisible: true,
  submitErrorNotificationVisible: true,
});

const emit = defineEmits(['success', 'error']);

const { status, jobCommand, data, err } = useJobCommand({ path: props.jobCommandPath });
const commandStatus = status;
const command = jobCommand;
const commandData = data;
const commandErr = err;
const formRef = ref<any>();
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
const originalModel: any = cloneDeep(toRaw(props.initialModel));
const model = reactive<any>(props.initialModel ?? {});
let resultModel: any = {};

async function submit()
{
  await formRef.value.validate();

  submitStatusRef.value = 'loading';

  if (props.preSubmit)
  {
    const doContinue = await props.preSubmit(model);

    if (!doContinue)
    {
      submitStatusRef.value = null;
      return;
    }
  }

  resultModel = cloneDeep(toRaw(model));

  const requestData: any = {};

  Object.keys(resultModel).forEach((key: string) =>
  {
    if (!(key in originalModel))
    {
      requestData[key] = {
        changed: true,
        value: resultModel[key],
      };
    }
    else
    {
      const changed = !isEqual(resultModel[key], originalModel[key]);
      requestData[key] = {
        changed,
        value: resultModel[key],
      };
    }
  });

  await command(props.command, requestData, props.jobType);
}

watch(commandStatus, async (val: string) =>
{
  if (val === 'success')
  {
    submitStatusRef.value = 'success';
    emit('success', toRaw(commandData.value));
  }

  if (val === 'error')
  {
    if (commandErr.value?.resultado?.status === 400)
    {
      emit('error', commandErr.value);
      if (props.submitErrorNotificationVisible)
      {
        notification.error({
          message: commandErr.value.resultado.message,
          duration: 10,
        });
      }
    }
    else
    {
      emit('error', commandErr.value);
      if (props.submitErrorNotificationVisible)
      {
        notification.error({
          message: 'Tivemos um erro inesperado',
          description: 'Favor entrar em contato com a equipe de suporte',
          duration: 10,
        });
      }
    }

    submitStatusRef.value = 'error';
  }

  if ((val === 'success' || val === 'error') && props.postSubmit)
  {
    props.postSubmit(submitStatusRef.value, model);
  }
});

defineExpose({
  model,
});
</script>

<template>
  <a-form
    v-if="!props.submitSuccessResultVisible || commandStatus !== 'success'"
    ref="formRef"
    :model="model"
    name="basic"
    autocomplete="off"
    layout="vertical"
  >
    <slot
      name="form"
      :model="model"
    />
    <a-form-item>
      <slot
        name="submitButton"
        :command-status="commandStatus"
        :submit="submit"
      >
        <a-button
          :block="props.submitButtonBlock"
          type="primary"
          :loading="commandStatus === 'loading'"
          size="large"
          :disabled="props.submitButtonDisabled"
          @click.prevent="submit"
        >
          <template
            v-if="props.submitButtonIconVisible"
            #icon
          >
            <slot name="submitButtonIcon">
              <SaveOutlined />
            </slot>
          </template>
          {{ submitButtonText }}
        </a-button>
      </slot>
    </a-form-item>
  </a-form>

  <slot
    v-if="props.submitSuccessResultVisible && commandStatus === 'success'"
    name="submitSuccess"
    :data="commandData"
  />
</template>
