<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
  >
    <a-descriptions-item
      label="Referência"
      :span="3"
    >
      {{ props.compromisso.data.referenciaGcpjNome }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Andamento"
      :span="3"
    >
      {{ props.compromisso.data.andamento }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul
        v-else
        style="margin-bottom: 0; padding-left: 16px"
      >
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
          <a-tag
            v-if="arquivo.atributos"
            style="margin-left: 8px"
          >
            {{
              arquivo.atributos.referenciaGcpjNome
            }}
          </a-tag>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
