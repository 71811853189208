import { ref } from 'vue';
import { useGet } from '~/composables/useGet';
import { usePost } from '~/composables/usePost';
import { API_URL } from '~/utils';

export function useJobCompromissoCommand(opts?: any)
{
  const {
    runAsync: executePost,
    status: jobCommandStatus,
    data: jobCommandData,
  } = usePost({
    url: `${API_URL}/job-compromisso-command`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });

  const {
    runAsync: getJob,
    status: getJobStatus,
    data: getJobData,
  } = useGet({
    url: `${API_URL}/get-job`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });

  const poolingStatus = ref<any>(null);
  const poolingData = ref<any>(null);
  const poolingErr = ref<any>(null);
  const isActive = ref<any>(false);

  const polling = async (jobId: string) =>
  {
    if (!isActive.value)
    {
      return;
    }

    await getJob({ jobId });

    if (getJobStatus.value === 'success')
    {
      if (getJobData.value?.status === 1)
      {
        setTimeout(async () =>
        {
          await polling(jobId);
        }, opts?.interval || 2000);
      }

      if (getJobData.value?.status === 2)
      {
        poolingStatus.value = 'success';
        poolingData.value = getJobData.value;
      }

      if (getJobData.value?.status === 3)
      {
        poolingStatus.value = 'error';
        poolingErr.value = getJobData.value;
      }
    }
    else
    {
      poolingStatus.value = 'error';
    }
  };

  const jobCompromissoCommand = async (commandType: string, commandData: any) =>
  {
    isActive.value = true;
    poolingStatus.value = 'loading';
    poolingData.value = null;
    poolingErr.value = null;

    await executePost({ commandType, commandData });

    if (jobCommandStatus.value === 'success')
    {
      await polling(jobCommandData.value.jobId);
    }
    if (jobCommandStatus.value === 'error')
    {
      poolingStatus.value = 'error';
    }
  };

  const cancel = () =>
  {
    isActive.value = false;
  };

  return {
    cancel,
    data: poolingData,
    jobCompromissoCommand,
    status: poolingStatus,
    err: poolingErr,
  };
}
