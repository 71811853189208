<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Opção" :span="3">
      {{
        props.compromisso.data
          .tipoVerificarNecessidadeCadastroPublicacaoCapturadaText
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        props.compromisso.data
          .tipoVerificarNecessidadeCadastroPublicacaoCapturada === 1
      "
      label="Pasta"
      :span="3"
    >
      {{ props.compromisso.data.pastaNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
