import { useGet } from '../useGet';
import { API_URL } from '~/utils';

export function useGraficoPastasAptosParaApreensaoPorRegional() {
  return useGet({
    url: `${API_URL}/pastas/graficos/pastas-aptos-para-apreensao-por-regional`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
