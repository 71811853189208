<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { onMounted, reactive, ref, watch } from 'vue';
import { useGraphQL } from '~/composables/useGraphQL';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: verificarPublicacaoCapturadaFormData,
  runAsync: verificarPublicacaoCapturadaFormAsync,
  status: verificarPublicacaoCapturadaFormStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'VERIFICAR_PUBLICACAO_ANDAMENTO_FORM',
});

const {
  err: verificarPublicacaoCapturadaErr,
  runAsync: verificarPublicacaoCapturadaAsync,
  status: verificarPublicacaoCapturadaStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  dataCumprimento: null,
  haPrazo: null,
  observacao: null,
  podeEditarTipo: true,
  prazo: null,
  tevePrazoAntes: false,
  tipoClassificacaoPublicacaoCaq: null,
});
const formRules = reactive({
  haPrazo: [
    {
      message: 'É necessário informar se a publicação será verificada',
      required: true,
      type: 'boolean',
    },
  ],
  tipoClassificacaoPublicacaoCaq: [
    {
      message: 'Tipo é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

function obterCompromissoAnterior(compromissos)
{
  compromissos.sort((a, b) =>
  {
    return dayjs(a.dataInicio) - dayjs(b.dataInicio);
  });

  let anterior = null;
  compromissos.forEach((x) =>
  {
    if (x.codigo === props.compromisso.codigo)
    {
      return;
    }
    anterior = x;
  });

  return anterior;
}

function verificarSePodeEditarTipoClassificacaoPublicacaoCaq(compromissos)
{
  const compromissoAnterior = obterCompromissoAnterior(compromissos);

  // STATUS: DEVOLVIDO E TIPO: CONCLUIR_FLUXO
  if (
    compromissoAnterior.status === 2
    && compromissoAnterior.tipo === 71
  )
  {
    return true;
  }

  if (!compromissoAnterior.data?.tipoClassificacaoPublicacaoCaq)
  {
    return true;
  }

  return false;
}

function obterUltimoVerificarPublicacaoCapturadaConcluido(compromissos)
{
  // STATUS: CONCLUIDO E TIPO: VERIFICAR_PUBLICACAO_CAPTURADA
  const verificarPublicacaoCapturadaConcluidos = compromissos
    .filter(x => x.status === 3 && x.tipo === 117)
    .sort((a, b) => dayjs(b.dataConclusao) - dayjs(a.dataConclusao));

  if (verificarPublicacaoCapturadaConcluidos.length === 0)
  {
    return null;
  }

  return verificarPublicacaoCapturadaConcluidos[0];
}

function obterUltimoTipoClassificacaoPublicacaoCaq(compromissos)
{
  const ultimoVerificarPublicacaoCapturada
    = obterUltimoVerificarPublicacaoCapturadaConcluido(compromissos);

  if (ultimoVerificarPublicacaoCapturada === null)
  {
    return null;
  }

  return {
    label:
      ultimoVerificarPublicacaoCapturada.data
        .tipoClassificacaoPublicacaoCaqText,
    value:
      ultimoVerificarPublicacaoCapturada.data
        .tipoClassificacaoPublicacaoCaqValue,
  };
}

function verificarSeTevePrazoAntes(compromissos)
{
  const ultimoVerificarPublicacaoCapturada
    = obterUltimoVerificarPublicacaoCapturadaConcluido(compromissos);

  if (ultimoVerificarPublicacaoCapturada === null)
  {
    return false;
  }

  if (ultimoVerificarPublicacaoCapturada.data.haPrazo !== true)
  {
    return false;
  }

  return true;
}

async function uiCarregarFormAsync()
{
  await verificarPublicacaoCapturadaFormAsync({
    codigo: props.compromisso.codigo,
  });

  if (verificarPublicacaoCapturadaFormStatus.value === 'success')
  {
    const compromissos = [
      ...verificarPublicacaoCapturadaFormData.value.fluxo[0].compromissos,
    ];

    formModel.podeEditarTipo
      = verificarSePodeEditarTipoClassificacaoPublicacaoCaq(compromissos);
    formModel.tipoClassificacaoPublicacaoCaq
      = obterUltimoTipoClassificacaoPublicacaoCaq(compromissos);
    formModel.tevePrazoAntes = verificarSeTevePrazoAntes(compromissos);
  }
}

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await verificarPublicacaoCapturadaAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      dataCumprimento: formModel.dataCumprimento,
      haPrazo: formModel.haPrazo,
      observacao: formModel.observacao,
      prazo: formModel.prazo,
      tipoClassificacaoPublicacaoCaq:
        formModel.tipoClassificacaoPublicacaoCaq.value,
    });

    if (verificarPublicacaoCapturadaStatus.value === 'error')
    {
      notification.error({
        description: verificarPublicacaoCapturadaErr.value.description,
        duration: 5,
        message: verificarPublicacaoCapturadaErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
}

watch(
  () => formModel.haPrazo,
  (val) =>
  {
    if (val === true)
    {
      formRules.prazo = [
        { message: 'Novo vencimento é obrigatório', required: true },
      ];
    }
    else
    {
      formRules.prazo = null;
    }
  },
);

onMounted(async () =>
{
  await uiCarregarFormAsync();

  emit('loaded');
});
</script>

<template>
  <a-skeleton
    v-if="verificarPublicacaoCapturadaFormStatus === 'loading'"
    :loading="true"
    :active="true"
    :paragraph="{ rows: 8 }"
  />
  <ErrorResult
    v-else-if="verificarPublicacaoCapturadaFormStatus === 'error'"
    :tentar-novamente-fn="uiCarregarFormAsync"
  />
  <div v-else>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="verificarPublicacaoCapturadaStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-row
          v-if="verificarPublicacaoCapturadaStatus !== 'success'"
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item label="Escolha uma das opções abaixo" name="haPrazo">
                <a-radio-group v-model:value="formModel.haPrazo">
                  <a-radio-button :value="true">
                    Registrar prazo
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Registrar data de cumprimento
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="formModel.haPrazo === true"
                label="Prazo"
                name="prazo"
              >
                <a-date-picker
                  v-model:value="formModel.prazo"
                  format="DD/MM/YYYY HH:mm"
                  value-format="DD/MM/YYYY HH:mm"
                  placeholder="Escolha a data de vencimento"
                  :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
                  style="width: 300px"
                />
              </a-form-item>

              <a-form-item
                v-if="formModel.haPrazo === false && formModel.tevePrazoAntes"
                label="Data de cumprimento"
                name="dataCumprimento"
              >
                <a-date-picker
                  v-model:value="formModel.dataCumprimento"
                  format="DD/MM/YYYY HH:mm"
                  value-format="DD/MM/YYYY HH:mm"
                  placeholder="Escolha a data de cumprimento"
                  :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
                  style="width: 300px"
                />
              </a-form-item>

              <a-form-item label="Tipo" name="tipoClassificacaoPublicacaoCaq">
                <AjaxSelect
                  v-model:value="formModel.tipoClassificacaoPublicacaoCaq"
                  :disabled="!formModel.podeEditarTipo"
                  :url="`${API_URL}/ajax-select/tipo-classificacao-publicacao-caq`"
                  :value-is-number="true"
                />
              </a-form-item>

              <a-form-item label="Observação" name="observacao">
                <a-textarea v-model:value="formModel.observacao" :rows="4" />
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"

                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">
                    Envie os arquivos necessários
                  </p>
                </template>
              </Uploader>
            </a-form>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="
                    uploaderStatus === 'uploading'
                      || verificarPublicacaoCapturadaStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')">
                  Fechar
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
