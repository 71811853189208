<script setup lang="ts">
import { onMounted } from 'vue';
import { getGoogleDriveUrl } from '~/utils';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '25%' }"
    bordered
  >
    <a-descriptions-item
      label="Precisa de subsídio?"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.precisaSubsidio ? 'SIM' : 'NÃO' }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.precisaSubsidio"
      label="Emails"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.emails.join(', ') }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.precisaSubsidio"
      label="Documentos necessários"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.documentosNecessarios }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
