import axios from 'axios';
import { isArray, isNil, mergeWith } from 'lodash-es';
import numeral from 'numeral';

export function sleep(ms: number)
{
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isNilOrEmpty(s: any)
{
  if (isNil(s))
  {
    return true;
  }

  if (typeof s === 'string')
  {
    if (s.trim() === '')
    {
      return true;
    }
  }

  return false;
}

export function filtrarSelectOptions(input: any, option: any)
{
  const optionLabelReady = option.label
    ? option.label
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036F]/g, '')
    : '';

  const inputReady = input
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '');
  return optionLabelReady.includes(inputReady);
}

export function arrayToNestedObject(arr: any[], leafValue: any = null)
{
  let obj: any = {};
  const root = obj;

  for (let i = 0; i < arr.length; i++)
  {
    if (i === arr.length - 1)
    {
      obj[arr[i]] = leafValue;
    }
    else
    {
      obj[arr[i]] = {};
      obj = obj[arr[i]];
    }
  }

  return root;
}

function customizer(objValue: any, srcValue: any)
{
  if (isArray(objValue))
  {
    return objValue.concat(srcValue);
  }
}

export function mergeWithArrayConcat(a: any, b: any)
{
  return mergeWith(a, b, customizer);
}

export function error(e: any)
{
  // eslint-disable-next-line no-console
  console.log(e);
  const status = e?.response?.status;

  if (status === 403)
  {
    return {
      description: 'Solicite acesso ao administrador',
      error: 'Você não tem permissão',
      message: 'Você não tem permissão',
    };
  }

  return e?.response?.data?.error
    ? {
        description: e?.response?.data?.description,
        error: e?.response?.data?.error,
        message: e?.response?.data?.message,
      }
    : {
        description: 'Por favor entre em contato com o suporte',
        error: 'Tivemos um problema inesperado',
        message: 'Tivemos um problema inesperado',
      };
}

export function formatDinheiro(value: any, emptyPlaceholder = '-'): string
{
  if (value === null || value === undefined || typeof value !== 'number')
  {
    return emptyPlaceholder;
  }

  return `R$ ${numeral(value).format('0,0.00')}`;
}

export function findValue(object: any, key: string): any
{
  let value;
  Object.keys(object).some((k) =>
  {
    if (k === key)
    {
      value = object[k];
      return true;
    }
    if (object[k] && typeof object[k] === 'object')
    {
      value = findValue(object[k], key);
      return value !== undefined;
    }

    return false;
  });

  return value;
}

export async function dispatch(command: string, data: object, endpoint?: string)
{
  const _endpoint = endpoint ?? 'http://localhost:3000/command';
  const r = await axios.post(_endpoint, { data, type: command });
  return r;
}
