<script>
import { filtrarSelectOptions } from '~/utils';

export default {
  props: {
    value: {
      default: null,
    },
    emptyText: {
      type: String,
      default: 'Nenhum valor encontrado',
    },
    placeholder: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [], // [{ value: 1, label: 'Distribuída' }]
    },
    labelInValue: {
      type: Boolean,
      default: false,
    },
    allowClear: {
    type: Boolean,
    default: false,
  },
  },
  emits: ['update:value', 'change'],
  setup(props, { emit }) {
    const onChange = async (value) => {
      emit('update:value', value);
      emit('change', value);
    };

    return {
      filtrarSelectOptions,
      onChange,
    };
  },
};
</script>

<template>
  <a-select
    :value="value"
    :label-in-value="labelInValue"
    :placeholder="placeholder"
    :filter-option="filtrarSelectOptions"
    :mode="mode"
    :options="options"
    show-search
    style="width: 100%"
    :allow-clear="allowClear"
    @change="onChange"
  >
    <template #notFoundContent>
      <Empty :description="emptyText" />
    </template>
  </a-select>
</template>
