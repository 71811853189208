const PERFIL_ACESSO = {
  Administrador: 15,
  Advogado: 10,
  AlterarVencimentoCompromisso: 128,
  AutomatizacaoAlterarVencimentoEmLote: 126,
  AutomatizacaoConcluirFluxoEmLote: 124,
  AutomatizacaoCriarCompromissoCaqVistoriaEmLote: 131,
  AutomatizacaoCriarCompromissoPubAPartirPublicacaoEmLote: 129,
  AutomatizacaoDelegarEmLote: 125,
  AutomatizacaoSolicitarAtualizacaoGcpjUltimaReferenciaEmLote: 130,
  AutomatizacaoSolicitarVerificacaoPublicacaoEmLote: 113,
  Belem: 27,
  Caq: 28,
  Cda: 7,
  Cdi: 12,
  Consolidacao: 35,
  Contraria: 3,
  DelegarCompromisso: 127,
  Editor: 49,
  EditorContraria: 50,
  FluxoAdicionarBaseAtivaAdicionarPastaBaseAtiva: 135,
  FluxoRegistroEncerramentoRcbRegistrarEncerramentoRcb: 134,
  FluxoRemoverBaseAtivaRemoverPastaBaseAtiva: 136,
  Gca: 11,
  Gce: 115,
  Gcpj: 6,
  Gdp: 8,
  GdpPagarCustas: 48,
  Gdr: 117,
  Ge: 5,
  GerenteBelem: 33,
  GerenteCaq: 31,
  GerenteCda: 19,
  GerenteCdi: 18,
  GerenteConsolidacao: 36,
  GerenteContraria: 13,
  GerenteGca: 20,
  GerenteGcpj: 24,
  GerenteGdp: 23,
  GerenteGe: 22,
  GerenteGgp: 59,
  GerenteGrcLeste: 17,
  GerenteGrcOeste: 16,
  GerenteGrcp: 120,
  GerenteGrv: 32,
  GerentePortoVelho: 34,
  GerenteProtocolo: 21,
  GerenteTrabalhista: 14,
  Gestao: 25,
  Ggp: 58,
  GrcLeste: 2,
  GrcOeste: 1,
  Grcp: 119,
  Grv: 30,
  ImportarPlanilhaAlteracaoPasta: 137,
  PortoVelho: 26,
  Protocolo: 9,
  Pub: 29,
  ResponsavelAcre: 38,
  ResponsavelAptoParaBusca: 47,
  ResponsavelImoveisEDemaisCarteiras: 37,
  ResponsavelPastasManausLesteAcima200kNaoPrime: 45,
  ResponsavelPastasManausLestePrime: 46,
  ResponsavelPastasManausOesteAcima200kNaoPrime: 43,
  ResponsavelPastasManausOestePrime: 44,
  ResponsavelPastasPalmas: 42,
  ResponsavelPastasPortoVelhoCarteiraPrime: 41,
  ResponsavelPastasPortoVelhoVeiculosOuLeasing: 40,
  ResponsavelPastasRoraima: 39,
  Trabalhista: 4,
  AlterarPastaBotao: 157,
  ImportarStatusAtualizacoesGcpjOystr: 159,
  ExportarAtualizacoesGcpjOystr: 160,
  AlterarGerenteNegocialResponsavel: 138,
  ImportarNovasPastasPelaPlanilhaBradesco: 162,
  ExecutarRoboConsultaGcpj: 163,
  AlterarGerenteNegocialResponsavelEmLote: 166,
};

export default PERFIL_ACESSO;
