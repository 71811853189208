<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '30%' }" bordered>
    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.id)"
      label="Id"
      :span="3"
    >
      {{ props.compromisso.data.id }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.nome)"
      label="Nome"
      :span="3"
    >
      {{ props.compromisso.data.nome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.gcpj)"
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.numeroCnj)"
      label="Número CNJ"
      :span="3"
    >
      {{ formatCnj(props.compromisso.data.numeroCnj) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.numeroAntigo)"
      label="Número antigo"
      :span="3"
    >
      {{ props.compromisso.data.numeroAntigo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.tipoNatureza)"
      label="Natureza"
      :span="3"
      :content-style="{ textTransform: 'uppercase' }"
    >
      {{ props.compromisso.data.tipoNatureza }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.tipoProcedimento)"
      label="Procedimento"
      :span="3"
    >
      {{ props.compromisso.data.tipoProcedimento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.statusPasta)"
      label="Status"
      :span="3"
    >
      {{ props.compromisso.data.statusPasta }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.acaoNome)"
      label="Ação"
      :span="3"
    >
      {{ props.compromisso.data.acaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.estadoNome)"
      label="Estado"
      :span="3"
    >
      {{ props.compromisso.data.estadoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.cidadeNome)"
      label="Cidade"
      :span="3"
    >
      {{ props.compromisso.data.cidadeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.orgaoNome)"
      label="Órgão"
      :span="3"
    >
      {{ props.compromisso.data.orgaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.justicaNome)"
      label="Justiça"
      :span="3"
    >
      {{ props.compromisso.data.justicaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.instanciaNome)"
      label="Instância"
      :span="3"
    >
      {{ props.compromisso.data.instanciaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.classeNome)"
      label="Classe"
      :span="3"
    >
      {{ props.compromisso.data.classeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.assuntoNome)"
      label="Assunto"
      :span="3"
    >
      {{ props.compromisso.data.assuntoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.comarcaNome)"
      label="Comarca"
      :span="3"
    >
      {{ props.compromisso.data.comarcaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.vara)"
      label="Vara"
      :span="3"
    >
      {{ props.compromisso.data.vara }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.acordo)"
      label="Acordo"
      :span="3"
    >
      {{ props.compromisso.data.acordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.contabilizacaoAcordo)"
      label="Contabilização do acordo"
      :span="3"
    >
      {{ props.compromisso.data.contabilizacaoAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.aptoParaApreensao)"
      label="Apto para apreensão"
      :span="3"
    >
      {{ props.compromisso.data.aptoParaApreensao }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.inviabilidade)"
      label="Inviabilidade"
      :span="3"
    >
      {{ props.compromisso.data.inviabilidade }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.valorCausa)"
      label="Valor causa"
      :span="3"
    >
      {{ props.compromisso.data.valorCausa }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.custasIniciais)"
      label="Custas iniciais"
      :span="3"
    >
      {{ props.compromisso.data.custasIniciais }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.custasOficialJustica)"
      label="Custas oficial de justiça"
      :span="3"
    >
      {{ props.compromisso.data.custasOficialJustica }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.custasProtesto)"
      label="Custas protesto"
      :span="3"
    >
      {{ props.compromisso.data.custasProtesto }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.valorEnvolvido)"
      label="Valor envolvido"
      :span="3"
    >
      {{ props.compromisso.data.valorEnvolvido }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.valorTotalAcordo)"
      label="Valor total do acordo"
      :span="3"
    >
      {{ props.compromisso.data.valorTotalAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.valorEntradaAcordo)"
      label="Valor de entrada do acordo"
      :span="3"
    >
      {{ props.compromisso.data.valorEntradaAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.valorFinanciadoAcordo)"
      label="Valor financiado do acordo"
      :span="3"
    >
      {{ props.compromisso.data.valorFinanciadoAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.taxaJurosAcordo)"
      label="Taxa de juros do acordo (A.M.)"
      :span="3"
    >
      {{ props.compromisso.data.taxaJurosAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.atualizacaoValorCausa)"
      label="Atualização do valor do acordo"
      :span="3"
    >
      {{ props.compromisso.data.atualizacaoValorCausa }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.qtdeParcelasAcordo)"
      label="Quantidade de parcelas do acordo"
      :span="3"
    >
      {{ props.compromisso.data.qtdeParcelasAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.garantia)"
      label="Garantia"
      :span="3"
    >
      {{ props.compromisso.data.garantia }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.avalista)"
      label="Avalista"
      :span="3"
    >
      {{ props.compromisso.data.avalista }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.motivoEncerramento)"
      label="Motivo encerramento"
      :span="3"
    >
      {{ props.compromisso.data.motivoEncerramento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.volumetria)"
      label="Volumetria"
      :span="3"
    >
      {{ props.compromisso.data.volumetria }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.placa)"
      label="Placa"
      :span="3"
    >
      {{ props.compromisso.data.placa }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.chassiRenavan)"
      label="Placa"
      :span="3"
    >
      {{ props.compromisso.data.chassiRenavan }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.tipoAcordo)"
      label="Tipo de acordo"
      :span="3"
    >
      {{ props.compromisso.data.tipoAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.historicoDescumprimento)"
      label="Histórico de descumprimento"
      :span="3"
    >
      {{ props.compromisso.data.historicoDescumprimento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        !isNilOrEmpty(
          props.compromisso.data
            .arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
        )
      "
      label="Arquivamento provisório ou suspensão por ausência de bens penhoráveis"
      :span="3"
    >
      {{
        props.compromisso.data
          .arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.informacaoSobreObito)"
      label="Informação sobre óbito"
      :span="3"
    >
      {{ props.compromisso.data.informacaoSobreObito }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.contaFicticia)"
      label="Conta fictícia"
      :span="3"
    >
      {{ props.compromisso.data.contaFicticia }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.empresaBanco)"
      label="Empresa/Banco"
      :span="3"
    >
      {{ props.compromisso.data.empresaBanco }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.procuracao)"
      label="Procuração"
      :span="3"
    >
      {{ props.compromisso.data.procuracao }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.motivoResultado)"
      label="Motivo resultado"
      :span="3"
    >
      {{ props.compromisso.data.motivoResultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.resultado)"
      label="Resultado"
      :span="3"
    >
      {{ props.compromisso.data.resultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.negociacaoContratoHonorario)"
      label="Negociação de contrato e honorário"
      :span="3"
    >
      {{ props.compromisso.data.negociacaoContratoHonorario }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.novoNumeroCarteiraContrato)"
      label="Novo número de carteira e contrato"
      :span="3"
    >
      {{ props.compromisso.data.novoNumeroCarteiraContrato }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataCadastro)"
      label="Data cadastro"
      :span="3"
    >
      {{ props.compromisso.data.dataCadastro }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataDistribuicao)"
      label="Data distribuição"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataDistribuicao) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataAcordo)"
      label="Data acordo"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataLiminar)"
      label="Data liminar"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataLiminar) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataApreensao)"
      label="Data apreensão"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataApreensao) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataCitacao)"
      label="Data citação"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataCitacao) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataEncerramento)"
      label="Data encerramento"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataEncerramento) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataSentenca)"
      label="Data sentença"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataSentenca) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataBaixa)"
      label="Data baixa"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataBaixa) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataConversao)"
      label="Data conversão"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataConversao) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataRecepcao)"
      label="Data remessa"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataRecepcao) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataFatoGerador)"
      label="Data fato gerador"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataFatoGerador) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        !isNilOrEmpty(
          props.compromisso.data.dataVencimentoPrimeiraParcelaAcordo
        )
      "
      label="Data vencimento primeira parcela do acordo"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataVencimentoPrimeiraParcelaAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        !isNilOrEmpty(props.compromisso.data.dataVencimentoUltimaParcelaAcordo)
      "
      label="Data vencimento última parcela do acordo"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataVencimentoUltimaParcelaAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataDescumprimentoAcordo)"
      label="Data descumprimento do acordo"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataDescumprimentoAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        !isNilOrEmpty(props.compromisso.data.dataDeclaracaoIrrecuperabilidade)
      "
      label="Data de declaração de irrecuperabilidade"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataDeclaracaoIrrecuperabilidade) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataAtualizacaoValorCausa)"
      label="Data atualização do valor da causa"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataAtualizacaoValorCausa) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataResultado)"
      label="Data resultado"
      :span="3"
    >
      {{ onlyDate(props.compromisso.data.dataResultado) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.clientePrincipalNome)"
      label="Cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.clientePrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.posicaoClientePrincipal)"
      label="Posição cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.posicaoClientePrincipal }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.contrarioPrincipalNome)"
      label="Contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.responsavelNome)"
      label="Responsável"
      :span="3"
    >
      {{ props.compromisso.data.responsavelNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.agenciaNome)"
      label="Agência"
      :span="3"
    >
      {{ props.compromisso.data.agenciaNome }}
    </a-descriptions-item>
  </a-descriptions>
</template>
