<script setup>
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoData,
  err: incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoErr,
  runAsync: incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoAsync,
  status: incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/acordo-carteira-veiculos-realizado-antes-ajuizamento/incluir-acordo-carteira-veiculos-realizado-antes-ajuizamento`,
});

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const authStore = useAuthStore();

const uiSalvarAsync = async () => {
  jobStatus.value = 'loading';

  await incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoAsync({
    compromissoCodigos:
      props.compromisso !== null ? [props.compromisso.codigo] : null,
  });

  if (
    incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoStatus.value ===
    'error'
  ) {
    jobStatus.value = 'error';
    notification.error({
      description:
        incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoErr.value
          .description,
      duration: 5,
      message:
        incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoErr.value.message,
    });
    emit('error');
    return;
  }

  const jobCodigo =
    incluirAcordoCarteiraVeiculosRealizadoAntesAjuizamentoData.value;

  await jobPoolingAsync({ codigo: jobCodigo });
};

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <a-row>
    <a-col :xs="24" :md="24" :lg="24" :xl="24">
      <a-result
        v-if="jobStatus === 'success'"
        status="success"
        title="Registro realizado com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </template>
      </a-result>

      <a-row v-if="jobStatus !== 'success'" :gutter="24">
        <a-col :span="24">
          <a-row>
            <a-col :span="24">
              <a-alert type="warning">
                <template #message>
                  Caso você tenha incluído o acordo no controle, clique no botão
                  de confirmação
                </template>
              </a-alert>
            </a-col>
          </a-row>

          <a-row style="margin-top: 24px">
            <a-col :span="24">
              <a-row style="margin-bottom: 0">
                <a-col :span="24">
                  <a-button
                    :disabled="
                      compromisso.responsavel.codigo !== authStore.codigoUsuario
                    "
                    :loading="jobStatus === 'loading'"
                    type="primary"
                    style="margin-right: 8px; min-width: 100px"
                    @click="uiSalvarAsync"
                  >
                    Confirmar
                  </a-button>
                  <a-button @click="() => emit('close')"> Fechar </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
