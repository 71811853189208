<script setup>
const props = defineProps({
  columns: {
    default: () => [
      {
        key: 'numero',
        title: 'NÚMERO',
        dataIndex: 'numero',
        sorter: true,
        sortBy: ['numero'],
      },
      {
        key: 'nome',
        title: 'NOME',
        dataIndex: 'nome',
        sorter: true,
        defaultSortOrder: 'ascend',
        sortBy: ['nome'],
      },
      {
        key: 'regional',
        dataIndex: 'regionalText',
        title: 'REGIONAL',
      },
      {
        key: 'gerencia',
        title: 'GERÊNCIA',
      },
      {
        key: 'diretoria',
        title: 'DIRETORIA',
      },
      {
        key: 'porte',
        dataIndex: 'porteText',
        title: 'PORTE',
      },
      {
        key: 'estado',
        dataIndex: ['estado', 'sigla'],
        title: 'ESTADO',
      },
    ],
    type: Array,
  },
  disabledFilters: {
    default: () => [],
    type: Array,
  },
  graphqlVariables: {
    default: () => ({
      order: [{ nome: 'ASC' }],
    }),
    type: Object,
  },
});

const filters = [];

if (!props.disabledFilters.find((x) => x === 'numero')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais números',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => x.trim());

      return {
        where: {
          and: [
            {
              numero: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'numero',
    label: 'Número',
    queryKey: 'n',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'nome')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais nomes',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => x.trim());

      return {
        where: {
          and: [
            {
              nome: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'nome',
    label: 'Nomes',
    queryKey: 'nm',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'tiposRegionais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma regional encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais regionais',
      query: 'TIPOS_AGENCIA_REGIONAIS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              regional: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'tiposRegionais',
    label: 'Regionais',
    queryKey: 'r',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'tiposPortes')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum porte encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais portes',
      query: 'TIPOS_AGENCIA_PORTES_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              porte: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'tiposPortes',
    label: 'Portes',
    queryKey: 'p',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'gerenciasRegionais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma gerência regional encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais gerências',
      query: 'GERENCIAS_REGIONAIS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              gerenciaRegionalCodigo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'gerenciasRegionais',
    label: 'Gerência regional',
    queryKey: 'gr',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'diretoriasRegionais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma diretoria regional encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais diretorias',
      query: 'DIRETORIAS_REGIONAIS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              gerenciaRegional: {
                diretoriaRegionalCodigo: {
                  in: codigos,
                },
              },
            },
          ],
        },
      };
    },
    key: 'diretoriasRegionais',
    label: 'Diretoria regional',
    queryKey: 'dr',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'estados')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum estado encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais estados',
      query: 'ESTADOS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              estadoCodigo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'estados',
    label: 'Estado',
    queryKey: 'e',
    type: 'graphql-select',
  });
}
</script>
<template>
  <GraphqlDatatable
    query="AGENCIA_DATATABLE"
    :keep-history="false"
    :columns="props.columns"
    row-key="codigo"
    :filters="filters"
    :text-search="{
      placeholder: 'Busca rápida de pastas por número ou nome',
    }"
    :graphql-variables="props.graphqlVariables"
  >
    <template #leftTopActions>
      <slot name="leftTopActions"></slot>
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'numero'">
        <router-link :to="`agencia/${record.codigo}`">{{
          record.numero
        }}</router-link>
      </template>
      <template v-if="column.key === 'nome'">
        <router-link :to="`agencia/${record.codigo}`">{{
          record.nome
        }}</router-link>
      </template>
      <template v-if="column.key === 'gerencia'">
        {{ record.gerenciaRegional?.nome }}
      </template>
      <template v-if="column.key === 'diretoria'">
        {{ record.gerenciaRegional?.diretoriaRegional?.nome }}
      </template>
    </template>
  </GraphqlDatatable>
</template>
