<script setup>
import { onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { useGraphQL } from '~/composables/useGraphQL';
import { useSalvarPessoaEmail } from '~/composables/pessoas/useSalvarPessoaEmail';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    required: true,
  },
  pessoaEmailCodigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['success', 'close']);

const {
  status: pessoaEmailQueryStatus,
  data: pessoaEmailQueryData,
  runAsync: pessoaEmailQueryAsync,
} = useGraphQL({
  query: 'PessoaEmailQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const {
  status: salvarPessoaEmailStatus,
  err: salvarPessoaEmailErr,
  runAsync: salvarPessoaEmailAsync,
} = useSalvarPessoaEmail();

const formRef = ref(null);
const state = reactive({
  status: null,
  ehPrincipal: null,
  descricao: null,
  endereco: null,
  pessoaCodigo: null,
});
const formRules = reactive({
  ehPrincipal: [
    {
      required: true,
      message: 'Definir se o email é o principal é obrigatório',
    },
  ],
  descricao: [{ required: true, message: 'Descrição é obrigatória' }],
  endereco: [{ required: true, message: 'Endereço é obrigatório' }],
});

const carregarAsync = async () => {
  await pessoaEmailQueryAsync({
    codigo: props.pessoaEmailCodigo,
  });

  if (pessoaEmailQueryStatus.value === 'success') {
    state.ehPrincipal = pessoaEmailQueryData.value.pessoaEmail.ehPrincipal;
    state.descricao = pessoaEmailQueryData.value.pessoaEmail.descricao;
    state.endereco = pessoaEmailQueryData.value.pessoaEmail.email.endereco;
  }

  state.status = pessoaEmailQueryStatus.value;
};

const salvarAsync = async () => {
  formRef.value.validate().then(async () => {
    const cmd = {
      pessoaCodigo: props.pessoaCodigo,
      pessoaEmailCodigo: props.pessoaEmailCodigo,
      ehPrincipal: state.ehPrincipal,
      descricao: state.descricao,
      endereco: state.endereco,
    };

    await salvarPessoaEmailAsync(cmd);

    if (salvarPessoaEmailStatus.value === 'error') {
      notification.error({
        message: salvarPessoaEmailErr.value.message,
        description: salvarPessoaEmailErr.value.description,
        duration: 5,
      });
    }
  });
};

onMounted(async () => {
  state.status = 'loading';
  if (props.pessoaEmailCodigo) {
    await carregarAsync();
  } else {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          :loading="state.status === 'loading'"
          :paragraph="false"
          :active="true"
          :title="{ width: '65%' }"
        >
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            {{
              props.pessoaEmailCodigo !== null
                ? 'ATUALIZAR EMAIL'
                : 'ADICIONAR EMAIL'
            }}</span
          >
        </a-skeleton>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="state.status === 'error'"
          :tentar-novamente-fn="carregarAsync"
        />
        <div v-else-if="state.status === 'success'">
          <a-result
            v-if="salvarPessoaEmailStatus === 'success'"
            status="success"
            title="Email salvo com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('success')"> Fechar </a-button>
            </template>
          </a-result>
          <a-form
            v-else
            ref="formRef"
            layout="vertical"
            :model="state"
            :rules="formRules"
          >
            <a-form-item label="É o email principal?" name="ehPrincipal">
              <a-radio-group v-model:value="state.ehPrincipal">
                <a-radio-button :value="true">Sim</a-radio-button>
                <a-radio-button :value="false">Não</a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              label="Descrição"
              name="descricao"
              style="padding-bottom: 0"
            >
              <a-input v-model:value="state.descricao" />
            </a-form-item>

            <a-form-item
              label="Endereço"
              name="endereco"
              style="padding-bottom: 0"
            >
              <a-input v-model:value="state.endereco" />
            </a-form-item>

            <a-row style="margin-top: 24px">
              <a-col :span="16">
                <a-button
                  :loading="salvarPessoaEmailStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="salvarAsync"
                >
                  Salvar
                </a-button>
                <a-button
                  style="margin-right: 8px"
                  @click="() => emit('close')"
                >
                  Cancelar
                </a-button>
              </a-col>
              <a-col :span="8" style="text-align: right" />
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
