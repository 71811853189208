query pessoaDatatable(
    $text: String
    $where: PessoaModelFilterInput
    $order: [PessoaModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: pessoasOffsetPagination(
        text: $text
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            nome
            documento
        }
        totalCount
    }
}
