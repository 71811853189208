<template>
  <a-result
    status="404"
    title="404"
    sub-title="A página que você tentou acessar não existe."
  >
    <template #extra>
      <a-button type="primary" @click="$router.push('/login')">
        Ir para login
      </a-button>
    </template>
  </a-result>
</template>
