<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useDrawerStore } from '~/stores/drawer';
import { useObterCompromissosPorDia } from '~/composables/compromissos/useObterCompromissosPorDia';

const props = defineProps({
  vencimentoEm: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close']);

const {
  status: obterCompromissosPorDiaStatus,
  data: obterCompromissosPorDiaData,
  runAsync: obterCompromissosPorDiaAsync,
} = useObterCompromissosPorDia();

const drawerStore = useDrawerStore();
let drawerIndex = null;
let unsubscribe = null;

const carregarAsync = async () => {
  await obterCompromissosPorDiaAsync({
    dia: props.vencimentoEm.format('DD'),
    mes: props.vencimentoEm.format('MM'),
    ano: props.vencimentoEm.format('YYYY'),
  });
};

onMounted(async () => {
  await carregarAsync();

  unsubscribe = drawerStore.$subscribe(async (mutation, state) => {
    if (state.activeDrawer + 1 === drawerIndex) await carregarAsync();
  });
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});

const abrirCompromisso = (compromisso) => {
  drawerIndex = drawerStore.push({
    componentName: 'CompromissoView',
    params: { compromisso },
  });
};

const colunasTabela = [
  {
    title: 'COMPROMISSO',
    dataIndex: 'codigo',
    key: 'codigo',
    slots: {
      customRender: 'compromisso',
    },
  },
  {
    key: 'dataInicio',
    title: 'INICIADO EM',
    dataIndex: 'dataInicioText',
    slots: {
      customRender: 'datetime',
    },
  },
  {
    key: 'dataVencimento',
    title: 'VENCIMENTO EM',
    dataIndex: 'dataVencimentoText',
    slots: {
      customRender: 'datetime',
    },
  },
  {
    title: 'RESPONSÁVEL',
    dataIndex: 'codigo',
    key: 'codigo',
    slots: {
      customRender: 'responsavel',
    },
  },
];
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => $emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          COMPROMISSOS DO DIA {{ vencimentoEm.format('DD/MM/YYYY') }}</span
        >
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-spin :spinning="obterCompromissosPorDiaStatus === 'loading'">
        <div
          v-if="obterCompromissosPorDiaStatus !== 'success'"
          style="height: 200px"
        />
        <div v-if="obterCompromissosPorDiaStatus === 'success'">
          <a-table
            :columns="colunasTabela"
            :data-source="obterCompromissosPorDiaData.compromissos"
            row-key="codigo"
            bordered
          >
            <template #compromisso="{ record }">
              <a-row type="flex" align="middle">
                <a-col flex="auto">
                  <a-tooltip>
                    <template #title> Abrir compromisso </template>
                    <a-button
                      type="link"
                      class="titulo"
                      style="padding: 0; margin: 0; text-transform: uppercase"
                      size="small"
                      @click="() => abrirCompromisso(record)"
                    >
                      {{ record.tipoText }}
                      {{
                        !isNil(record.fluxo.pasta)
                          ? `/ ${record.fluxo.pasta.nome}`
                          : ''
                      }}
                    </a-button>
                  </a-tooltip>
                </a-col>
              </a-row>
            </template>
            <template #datetime="{ text }">
              {{ !isNil(text) ? text : '-' }}
            </template>
            <template #responsavel="{ record }">
              <a-tooltip v-if="record.responsavel !== null">
                <template #title> Ir para página do responsável </template>
                <a
                  :href="`/usuario/${record.responsavel.codigo}`"
                  style="text-transform: uppercase"
                  >{{ record.responsavel.nome }}</a
                >
              </a-tooltip>
            </template>
          </a-table>
        </div>
      </a-spin>
    </div>
  </div>
</template>
