<script setup>
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: enviarPlanilhaAtualizacaoDebitoAtualizadaData,
  err: enviarPlanilhaAtualizacaoDebitoAtualizadaErr,
  runAsync: enviarPlanilhaAtualizacaoDebitoAtualizadaAsync,
  status: enviarPlanilhaAtualizacaoDebitoAtualizadaStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const {
  err: devolverErr,
  runAsync: devolverAsync,
  status: devolverStatus,
} = useDevolverCompromisso();

const authStore = useAuthStore();
const devolverModalVisible = ref(false);
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  justificativaDevolucao: null,
  observacao: null,
  solicitarEnvioPlanilhaAtualizacaoDebitoNovamente: null,
});
const formRules = reactive({
  solicitarEnvioPlanilhaAtualizacaoDebitoNovamente: [
    {
      message: 'Solicitar ou não o envio da planilha novamente é obrigatório',
      required: true,
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await enviarPlanilhaAtualizacaoDebitoAtualizadaAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos: [props.compromisso.codigo],
      observacao: formModel.observacao,
      solicitarEnvioPlanilhaAtualizacaoDebitoNovamente:
        formModel.solicitarEnvioPlanilhaAtualizacaoDebitoNovamente,
    });

    if (enviarPlanilhaAtualizacaoDebitoAtualizadaStatus.value === 'error') {
      jobStatus.value = 'error';

      notification.error({
        description:
          enviarPlanilhaAtualizacaoDebitoAtualizadaErr.value.description,
        duration: 5,
        message: enviarPlanilhaAtualizacaoDebitoAtualizadaErr.value.message,
      });

      emit('error');
      return;
    }

    const jobCodigo = enviarPlanilhaAtualizacaoDebitoAtualizadaData.value;

    await jobPoolingAsync({ codigo: jobCodigo });

    emit('success');
  });
};

const uiDevolverAsync = async () => {
  await devolverAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverStatus.value === 'error') {
    notification.error({
      description: devolverErr.value.description,
      duration: 5,
      message: devolverErr.value.message,
    });
    emit('error');
    return;
  }

  devolverModalVisible.value = false;

  emit('success');
};

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="jobStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-row
          v-if="jobStatus !== 'success' && devolverStatus !== 'success'"
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item
                label="Escolha uma opção"
                name="solicitarEnvioPlanilhaAtualizacaoDebitoNovamente"
              >
                <a-radio-group
                  v-model:value="
                    formModel.solicitarEnvioPlanilhaAtualizacaoDebitoNovamente
                  "
                  button-style="solid"
                >
                  <a-radio-button :value="true"
                    >Solicitar novamente o envio dos dados de atualização de
                    débito</a-radio-button
                  >
                  <a-radio-button :value="false"
                    >Enviar planilha de atualização de débito
                    atualizada</a-radio-button
                  >
                </a-radio-group>
              </a-form-item>

              <a-form-item
                label="Observação"
                name="observacao"
                style="padding-bottom: 0"
              >
                <a-textarea v-model:value="formModel.observacao" :rows="4" />
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">Envie os arquivos necessários</p>
                </template>
              </Uploader>
            </a-form>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="jobStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
