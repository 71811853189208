<script setup>
import { DownOutlined, MenuOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import useBreakpoints from 'vue-next-breakpoints';

import PageMobileMenu from './PageMobileMenu.vue';
import { useDrawerStore } from '~/stores/drawer';
import { useAuthStore } from '~/stores/auth';
import router from '~/router';

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  menu: {
    type: String,
    default: '',
  },
});

const $auth = useAuthStore();
const $drawer = useDrawerStore();

const menuWidth = ref(0);
const bigMenuVisivel = ref(true);
const menuVisivel = ref(false);

const breakpoints = useBreakpoints({
  desktop: [768], // min-width: 768px
});

breakpoints.desktop.on('enter', () =>
{
  menuWidth.value = 180;
});

breakpoints.desktop.on('leave', () =>
{
  menuWidth.value = 0;
});

if (breakpoints.desktop.matches)
{
  menuWidth.value = 180;
}
else
{
  menuWidth.value = 0;
}

async function logout()
{
  await $auth.logout();
  router.push({ path: '/login' });
}

async function usuarioMenuOnClick({ key })
{
  if (key === 'logout')
  {
    await logout();
  }

  if (key === 'mudar-senha')
  {
    $drawer.push({
      componentName: 'MudarSenhaForm',
      params: {},
      width: 600,
    });
  }
}

function uiAtualizar()
{
  window.location.reload(true);
}
</script>

<template>
  <a-spin :spinning="props.loading">
    <a-layout class="page">
      <a-layout-header class="page-header">
        <a-row>
          <a-col :xs="4" :sm="4" :md="20" :lg="20">
            <a-tooltip placement="bottomLeft">
              <template #title>
                Mostrar ou esconder o menu lateral
              </template>
              <a-button
                size="small"
                style="margin-right: 16px"
                @click="() => (bigMenuVisivel = !bigMenuVisivel)"
              >
                <template #icon>
                  <MenuOutlined style="color: #d5d5d5" />
                </template>
              </a-button>
            </a-tooltip>
            <MenuOutlined
              class="trigger"
              @click="() => (menuVisivel = !menuVisivel)"
            />
            <a-space :size="1" align="center">
              <div>
                <img class="logo-img" src="/images/logo-122x122.png">
              </div>
              <div>
                <a class="logo-link" href="#"> EDSON ROSAS ADVOCACIA </a>
              </div>
            </a-space>
          </a-col>
          <a-col :xs="16" :sm="16" :md="0" :lg="0" style="text-align: center">
            <img
              class="logo-img-mobile"
              src="/images/logo-122x122.png"
            >
          </a-col>
          <a-col :xs="4" :sm="4" :md="4" :lg="4" style="text-align: right">
            <a-button
              v-if="$auth.iniciaisUsuario !== null"
              style="margin-right: 8px"
              @click="uiAtualizar"
            >
              Atualizar
            </a-button>
            <a-spin v-if="$auth.logoutStatus === 'loading'" size="small" />
            <a-dropdown
              v-if="
                $auth.iniciaisUsuario !== null
                  && $auth.logoutStatus !== 'loading'
              "
            >
              <span style="cursor: pointer"><a-avatar style="text-transform: uppercase">{{
                                              $auth.iniciaisUsuario
                                            }}</a-avatar>
                <DownOutlined /></span>
              <template #overlay>
                <a-menu @click="usuarioMenuOnClick">
                  <a-menu-item key="mudar-senha">
                    Mudar senha
                  </a-menu-item>
                  <a-menu-item key="logout">
                    Logout
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-layout-header>
      <a-layout>
        <a-layout-sider
          :collapsed="!bigMenuVisivel"
          :width="menuWidth"
          breakpoint="md"
          collapsed-width="0"
          :collapsible="true"
          :trigger="null"
        >
          <PageMenu :menu="props.menu" />
        </a-layout-sider>
        <a-layout-content class="page-content">
          <slot />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-spin>
  <PageMobileMenu v-model:visivel="menuVisivel" :menu="props.menu" />
</template>

<style lang="less">
@import '../assets/styles/base.less';

.ant-drawer .ant-drawer-body {
  padding: 0;
}

#app {
  & .ant-spin-nested-loading {
    height: 100%;

    & .ant-spin-container {
      height: 100%;
    }
  }
}

.page {
  height: 100%;

  & .logo-img {
    position: relative;
    top: 15px;
    width: 36px;
    height: auto;
    margin-right: 8px;
    margin-bottom: 4px;

    display: none;
    @media (min-width: @screen-md-min) {
      display: inline;
    }
  }

  & .logo-img-mobile {
    width: 42px;
    height: auto;
    margin-top: 3px;
    margin-bottom: 6px;

    display: inline;
    @media (min-width: @screen-md-min) {
      display: none;
    }
  }

  & .logo-link {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: @logo-color;
    font-size: 20px;

    display: none;
    @media (min-width: @screen-md-min) {
      display: inline;
    }
  }

  & .page-header {
    background-color: #fff;
    border-bottom: 1px solid @layout-border-color;
    z-index: 10;

    padding: 0 24px;
  }

  & .trigger {
    font-size: 20px;
    margin-top: 22px;
    cursor: pointer;
    transition: color 0.3s;

    display: inline-block;
    @media (min-width: @screen-md-min) {
      display: none;
    }

    &:hover {
      color: @primary-color;
    }
  }

  & .ant-layout {
    background-color: #fff;
  }

  & .ant-layout-sider {
    background-color: #fff;
  }

  & .page-content {
    background-color: #fff;
    height: 100%;
    padding: 24px;
  }
}
</style>
