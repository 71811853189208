<script setup>
import { reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import {
  useDelegarCompromisso,
  useDelegarCompromissoEmLote,
} from '~/composables/compromissos/useDelegarCompromisso';

const props = defineProps({
  compromissoCodigo: {
    type: String,
    default: null,
  },
  compromissosCodigos: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

const {
  status: delegarCompromissoStatus,
  err: delegarCompromissoErr,
  runAsync: delegarCompromissoAsync,
} = useDelegarCompromisso();

const {
  status: delegarCompromissoEmLoteStatus,
  err: delegarCompromissoEmLoteErr,
  runAsync: delegarCompromissoEmLoteAsync,
} = useDelegarCompromissoEmLote();

const formRef = ref(null);
const formModel = reactive({
  responsavel: null,
});
const formRules = reactive({
  responsavel: [
    {
      required: true,
      message: 'Responsável é obrigatório',
      type: 'object',
      trigger: 'change',
    },
  ],
});

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    const rawForm = {
      responsavelCodigo: formModel.responsavel.value,
      compromissoCodigo: props.compromissoCodigo,
      compromissosCodigos: props.compromissosCodigos,
    };

    if (props.compromissosCodigos.length > 0) {
      await delegarCompromissoEmLoteAsync(rawForm);
    } else {
      await delegarCompromissoAsync(rawForm);
    }

    if (
      delegarCompromissoStatus.value === 'error' ||
      delegarCompromissoEmLoteStatus.value === 'error'
    ) {
      const errorMessage =
        delegarCompromissoErr.value?.message ??
        delegarCompromissoEmLoteErr.value.message;

      notification.error({
        message: errorMessage,
        duration: 5,
      });
    }
  });
};
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          DELEGAR COMPROMISSO</span
        >
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div>
        <a-result
          v-if="
            delegarCompromissoStatus === 'success' ||
            delegarCompromissoEmLoteStatus === 'success'
          "
          status="success"
          title="Compromisso delegado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => $emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <div
          v-if="
            delegarCompromissoStatus !== 'success' &&
            delegarCompromissoEmLoteStatus !== 'success'
          "
        >
          <a-alert
            v-if="props.compromissosCodigos.length > 0"
            type="warning"
            message="Você irá realizar uma delegação em lote"
            show-icon
            style="margin-bottom: 24px"
          ></a-alert>

          <h3 style="margin-bottom: 24px">Selecione o novo responsável</h3>

          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
          >
            <a-form-item name="responsavel">
              <template #label>
                <slot name="formItemLabel"> Responsável </slot>
              </template>
              <GraphQlSelect
                v-model:value="formModel.responsavel"
                query="USUARIO_SELECT"
                :variables="{
                  where: {
                    ativo: {
                      eq: true,
                    },
                  },
                  order: [
                    {
                      nome: 'ASC',
                    },
                  ],
                }"
                placeholder="Selecione o responsável"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item style="margin-top: 24px; margin-bottom: 0px">
              <a-button
                :loading="
                  delegarCompromissoStatus === 'loading' ||
                  delegarCompromissoEmLoteStatus === 'loading'
                "
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click.prevent="uiSalvarAsync"
              >
                Salvar
              </a-button>
              <a-button @click="() => emit('close')"> Fechar </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
