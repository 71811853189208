<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('solicitar-cadastro-processo-contrario-form', {
  state: () => ({
    salvarStatus: null,
    uploaderStatus: null,
    error: null,
    gcpj: null,
    arquivos: [],
  }),
  actions: {
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          `${API_URL}/fluxo/cadastro-processo-contrario/solicitar-cadastro-processo-contrario`,
          {
            gcpj: this.gcpj,
            arquivos: this.arquivos,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
});

const $s = useStore();
const formRef = ref(null);
const uploaderRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  gcpj: [{ required: true, message: 'Número GCPJ é obrigatório' }],
});

async function salvar()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        class: 'er-notification',
        message: $s.error,
        duration: 5,
      });
    }
  });
}

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-result
          v-if="$s.salvarStatus === 'success'"
          status="success"
          title="Seu registro foi realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button type="primary" @click="() => $emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <div v-if="$s.salvarStatus !== 'success'">
          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
          >
            <a-form-item label="Número GCPJ" name="gcpj">
              <a-input v-model:value="formModel.gcpj" :maxlength="10" />
            </a-form-item>

            <h3 style="margin-bottom: 24px">
              Anexar arquivos
            </h3>

            <a-row>
              <a-col :span="24">
                <Uploader
                  ref="uploaderRef"
                  v-model:fileList="$s.arquivos"

                  @start="() => ($s.uploaderStatus = 'uploading')"
                  @end="() => ($s.uploaderStatus = 'done')"
                />
              </a-col>
            </a-row>

            <a-form-item style="margin-top: 24px; margin-bottom: 0px">
              <a-button
                :loading="
                  $s.uploaderStatus === 'uploading'
                    || $s.salvarStatus === 'loading'
                "
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click.prevent="salvar"
              >
                Salvar
              </a-button>
              <a-button @click="() => $emit('close')">
                Cancelar
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
