<script setup>
import { useCriarJob } from '~/composables/jobs/useCriarJob';
import { FileExcelOutlined, FileZipOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['close']);

const { runAsync: criarJob, status: criarJobStatus } = useCriarJob();

const arquivoXls = ref([]);
const arquivoZip = ref([]);
const xlsUploader = ref(null);
const xlsStatus = ref(null);
const xlsError = ref(false);
const zipUploader = ref(null);
const zipStatus = ref(null);
const zipError = ref(false);

const xlsBeforeUpload = (file) => {
  xlsError.value = false;

  const ext = file.name.split('.').pop().toLowerCase();
  if (ext !== 'xls' && ext !== 'xlsx') {
    xlsError.value = true;
    return false;
  }
  return true;
};

const zipBeforeUpload = (file) => {
  zipError.value = false;

  if (file.name.split('.').pop().toLowerCase() !== 'zip') {
    zipError.value = true;
    return false;
  }
  return true;
};

const importar = async () => {
  if (arquivoXls.value.length === 0) {
    notification.error({
      duration: 5,
      message: 'A planilha excel é obrigatória para a importação',
    });
    return;
  }

  await xlsUploader.value.upload();

  if (arquivoZip.value.length === 1) {
    await zipUploader.value.upload();
  }

  if (xlsStatus.value === 'cancelled' || zipStatus.value === 'cancelled') {
    xlsStatus.value = null;
    zipStatus.value = null;
    return;
  }

  await criarJob({
    parametros: {
      arquivoXls: arquivoXls.value[0],
      arquivoZip: arquivoZip.value.length > 0 ? arquivoZip.value[0] : null,
    },
    tipo: 3,
  });
};

watch(
  () => criarJobStatus.value,
  (val) => {
    if (val === 'error') {
      notification.error({
        duration: 5,
        message: 'Tivemos um problema inesperado ao tentar importar',
      });
    }
  }
);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle :closable="false" @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          IMPORTAR PROCESSOS REDISTRIBUÍDOS</span
        >
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-row>
        <a-col :span="24">
          <a-result
            v-if="criarJobStatus === 'success'"
            status="success"
            title="A sua importação está em processamento"
            sub-title="Você pode verificar o resultado na página de importações"
            style="margin-top: 24px"
          >
            <template #extra>
              <a-button @click="emit('close')"> Fechar </a-button>
            </template>
          </a-result>
          <a-row v-else :gutter="24">
            <a-col :span="24">
              <a-row>
                <a-col :span="18">
                  <h3 style="margin-bottom: 24px">Envie os arquivos</h3>
                </a-col>
              </a-row>

              <a-alert show-icon>
                <template #message>
                  <a href="/TEMPLATE_PROCESSOS_REDISTRIBUIDOS.xlsx"
                    >Clique aqui para baixar o EXCEL padrão para importação</a
                  >
                </template>
              </a-alert>

              <a-alert show-icon style="margin-top: 8px">
                <template #message>
                  <a href="/TEMPLATE_PROCESSOS_REDISTRIBUIDOS.zip"
                    >Clique aqui para baixar o ZIP padrão para importação</a
                  >
                </template>
              </a-alert>

              <a-row style="margin-top: 24px">
                <a-col span="24">
                  <a-alert
                    v-if="xlsError"
                    message="O arquivo deve possuir o formato .XLS ou .XLSX"
                    type="error"
                    style="margin-bottom: 24px"
                    closable
                    :after-close="() => (xlsError = false)"
                  />
                  <Uploader
                    ref="xlsUploader"
                    v-model:fileList="arquivoXls"
                    :only-one-file="true"
                    :before-upload="xlsBeforeUpload"
                    @start="() => (xlsStatus = 'uploading')"
                    @end="() => (xlsStatus = 'done')"
                  >
                    <template #description>
                      <p class="ant-upload-drag-icon">
                        <FileExcelOutlined />
                      </p>
                      <p class="ant-upload-text">
                        Clique ou arraste o arquivo EXCEL
                      </p>
                      <p class="ant-upload-hint">
                        Você pode baixar o padrão do EXCEL no link acima
                      </p>
                    </template>
                  </Uploader>
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-col span="24">
                  <a-alert
                    v-if="zipError"
                    message="O arquivo deve possuir o formato ZIP"
                    type="error"
                    style="margin-bottom: 24px"
                    closable
                    :after-close="() => (zipError = false)"
                  />
                  <Uploader
                    ref="zipUploader"
                    v-model:fileList="arquivoZip"
                    :only-one-file="true"
                    :before-upload="zipBeforeUpload"
                    :cancellable="true"
                    @start="() => (zipStatus = 'uploading')"
                    @end="() => (zipStatus = 'done')"
                    @cancel="() => (zipStatus = 'cancelled')"
                  >
                    <template #description>
                      <p class="ant-upload-drag-icon">
                        <FileZipOutlined />
                      </p>
                      <p class="ant-upload-text">
                        Clique ou arraste o arquivo ZIP
                      </p>
                      <p class="ant-upload-hint">
                        Você pode baixar o padrão do ZIP no link acima
                      </p>
                    </template>
                  </Uploader>
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-button
                  :loading="
                    xlsStatus === 'uploading' ||
                    zipStatus === 'uploading' ||
                    criarJobStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click.prevent="importar"
                >
                  Importar
                </a-button>
                <a-button
                  :disabled="
                    xlsStatus === 'uploading' ||
                    zipStatus === 'uploading' ||
                    criarJobStatus === 'loading'
                  "
                  @click="() => emit('close')"
                >
                  Fechar
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
