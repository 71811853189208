import axios from 'axios';
import { ref } from 'vue';
import { error } from '~/utils';

const API_URL = import.meta.env.VITE_API_URL;

export function useGraphQL(opts) {
  const { query, config } = opts;

  const status = ref(null);
  const err = ref(null);
  const response = ref(null);
  const data = ref(null);

  const runAsync = async (body) => {
    response.value = null;
    data.value = null;
    err.value = null;
    status.value = 'loading';

    const _config = {
      ...config,
      'Content-Type': 'application/json; charset=utf-8',
    };

    const _body = {
      id: query,
      variables: {
        ...body,
      },
    };

    try {
      const r = await axios.post(`${API_URL}/graphql`, _body, _config);

      if (r.data.errors) {
        throw 'GraphQL Error';
      }

      response.value = r;
      data.value = r.data.data;
      status.value = 'success';
    } catch (e) {
      status.value = 'error';
      err.value = error(e);
    }
  };

  return {
    status,
    err,
    response,
    data,
    runAsync,
  };
}
