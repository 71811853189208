<script setup>
import axios from 'axios';
import { defineStore } from 'pinia';
import { onMounted, onUnmounted, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import { devolverCompromisso } from '~/apis/devolver_compromisso';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore(
  'confirmar-juntada-pagamento-custas-ao-processo-form',
  {
    actions: {
      async devolver()
      {
        this.devolverStatus = 'loading';
        try
        {
          await devolverCompromisso({
            codigoCompromisso: this.compromisso.codigo,
            justificativaDevolucao: this.justificativaDevolucao,
          });
          this.devolverStatus = 'success';
        }
        catch (e)
        {
          this.devolverStatus = 'error';
          this.error = error(e).error;
        }
      },
      async salvar()
      {
        this.salvarStatus = 'loading';
        try
        {
          await axios.post(
            `${API_URL}/compromissos/confirmar-juntada-pagamento-custas-ao-processo`,
            {
              arquivos: this.arquivos,
              codigoCompromisso: this.compromisso.codigo,
            },
            {
              getAccessToken: true,
              timeout: 120000,
            },
          );
          this.salvarStatus = 'success';
        }
        catch (e)
        {
          this.salvarStatus = 'error';
          this.error = error(e).error;
        }
      },
    },
    state: () => ({
      arquivos: [],
      compromisso: null,
      devolverStatus: null,
      error: null,
      justificativaDevolucao: null,
      salvarStatus: null,
      uploaderStatus: null,
    }),
  },
);

const $auth = useAuthStore();
const $s = useStore();
const uploaderRef = ref(null);
const devolverModalVisible = ref(false);

async function salvar()
{
  await uploaderRef.value.upload();
  await $s.salvar();
  if ($s.salvarStatus === 'error')
  {
    notification.error({
      class: 'er-notification',
      duration: 5,
      message: $s.error,
    });
    emit('error');
    return;
  }
  emit('success');
}

async function devolver()
{
  await $s.devolver();
  if ($s.devolverStatus === 'error')
  {
    notification.error({
      class: 'er-notification',
      duration: 5,
      message: $s.error,
    });
    emit('error');
    return;
  }
  emit('success');
}

onMounted(() =>
{
  $s.compromisso = props.compromisso;
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <a-row>
    <a-col :xs="24" :md="24" :lg="24" :xl="24">
      <a-result
        v-if="$s.salvarStatus === 'success'"
        status="success"
        title="Registro realizado com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button type="primary" @click="() => $emit('close')">
            Fechar
          </a-button>
        </template>
      </a-result>

      <a-result
        v-if="$s.devolverStatus === 'success'"
        status="success"
        title="Compromisso devolvido com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button type="primary" @click="() => $emit('close')">
            Fechar
          </a-button>
        </template>
      </a-result>

      <a-row
        v-if="$s.salvarStatus !== 'success' && $s.devolverStatus !== 'success'"
        :gutter="24"
      >
        <a-col :span="24">
          <a-row>
            <a-col :span="24">
              <Uploader
                ref="uploaderRef"
                v-model:fileList="$s.arquivos"
                @start="() => ($s.uploaderStatus = 'uploading')"
                @end="() => ($s.uploaderStatus = 'done')"
              />
            </a-col>
          </a-row>

          <a-modal
            v-model:visible="devolverModalVisible"
            title="Justifique a devolução"
            cancel-text="Cancelar"
            :z-index="2000"
            :confirm-loading="$s.devolverStatus === 'loading'"
            :ok-button-props="{ disabled: !$s.justificativaDevolucao?.length }"
            @ok="devolver"
          >
            <a-textarea
              v-model:value="$s.justificativaDevolucao"
              :rows="4"
              placeholder="Escreva sua justificativa"
            />
          </a-modal>

          <a-row style="margin-top: 24px; margin-bottom: 0">
            <a-col :span="24">
              <a-button
                :disabled="
                  compromisso.responsavelCodigo !== $auth.codigoUsuario
                "
                :loading="
                  $s.uploaderStatus === 'uploading'
                    || $s.salvarStatus === 'loading'
                "
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click="salvar"
              >
                Salvar
              </a-button>
              <a-button
                :disabled="
                  compromisso.responsavelCodigo !== $auth.codigoUsuario
                "
                :loading="$s.devolverStatus === 'loading'"
                style="margin-right: 8px; min-width: 100px"
                @click="() => (devolverModalVisible = true)"
              >
                Devolver
              </a-button>
              <a-button @click="() => $emit('close')">
                Cancelar
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
