import { API_URL } from '~/utils';
import { useGet } from '../useGet';

export function useObterFluxo() {
  return useGet({
    url: `${API_URL}/fluxos/obter-fluxo`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
