<script setup>
import { SearchOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
</script>

<template>
  <Page menu="compromissos">
    <div>
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/compromissos">
                    Compromissos
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar compromissos
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->
          <CompromissoGqlDatatable :enable-row-selection="true" :show-text-search="true" />
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
