<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';

const $auth = useAuthStore();

onMounted(async () => {
  await $auth.logout();
});
</script>

<template>
  <a-row type="flex" style="height: 100%" align="middle">
    <a-col :span="24">
      <a-result
        status="403"
        title="Sua sessão expirou"
        sub-title="Entre novamente"
      >
        <template #extra>
          <a-button type="primary" @click="$router.push('/login')">
            Ir para login
          </a-button>
        </template>
      </a-result>
    </a-col>
  </a-row>
</template>
