<script setup>
import { reactive, ref } from 'vue';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import { useDrawerStore } from '~/stores/drawer';
import { useAuthStore } from '~/stores/auth';
import PERFIL_ACESSO from '~/perfilAcesso';

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const tableRef = ref(null);

function abrirSalvarPessoaFisica()
{
  drawerStore.push2({
    componentName: 'SalvarPessoaFisicaForm',
    width: 600,
    onSuccess: async () =>
    {
      await tableRef.value?.reloadAsync();
    },
  });
}

function abrirSalvarPessoaJuridica()
{
  drawerStore.push2({
    componentName: 'SalvarPessoaJuridicaForm',
    width: 600,
    onSuccess: async () =>
    {
      await tableRef.value?.reloadAsync();
    },
  });
}

function adicionarContatoOnClick({ key })
{
  if (key === 'pessoa-fisica')
  {
    abrirSalvarPessoaFisica();
  }

  if (key === 'pessoa-juridica')
  {
    abrirSalvarPessoaJuridica();
  }
}
</script>

<template>
  <Page menu="contatos">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/contatos">
                    Contatos
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar contatos
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container">
                <a-dropdown
                  v-if="
                    authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Editor)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Cdi)
                  "
                  :overlay-style="{ zIndex: 50 }"
                >
                  <a-button type="primary">
                    <template #icon>
                      <PlusOutlined style="font-size: 12px" />
                    </template>Adicionar contato
                  </a-button>
                  <template #overlay>
                    <a-menu @click="adicionarContatoOnClick">
                      <a-menu-item key="pessoa-fisica">
                        Pessoa física
                      </a-menu-item>
                      <a-menu-item key="pessoa-juridica">
                        Pessoa jurídica
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
          <!-- /Page header -->

          <div style="margin-top: 24px">
            <PessoaGqlDatatable />
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
