<script setup lang="ts">
import { ref } from 'vue';
import { CaretDownFilled, ExportOutlined, SettingOutlined } from '@ant-design/icons-vue';
import dayjs from 'dayjs';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import pastaDatatable from '~/graphql/pastaDatatable.graphql';
import tipoProcessoSelect from '~/graphql/tipoProcessoSelect.graphql';
import tipoPastaStatusSelect from '~/graphql/tipoPastaStatusSelect.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import pessoaSelect from '~/graphql/pessoaSelect.graphql';
import agenciaSelect from '~/graphql/agenciaSelect.graphql';
import tipoAgenciaRegionalSelect from '~/graphql/tipoAgenciaRegionalSelect.graphql';
import tipoAgenciaPorteSelect from '~/graphql/tipoAgenciaPorteSelect.graphql';
import referenciaGcpjAndamentoSelect from '~/graphql/referenciaGcpjAndamentoSelect.graphql';
import referenciaGcpjEncerramentoSelect from '~/graphql/referenciaGcpjEncerramentoSelect.graphql';
import { formatCnj, isNilOrEmpty, onlyNumbers } from '~/utils';
import tiposJobs from '~/constants/tipoJob';
import { useAuthStore } from '~/stores/auth';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useDrawerStore } from '~/stores/drawer';

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataAtualizacao: 'DESC' }] };
  },
  enableRowSelection: false,
});

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
  enableRowSelection?: boolean
}

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Código',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'nome'))
{
  filters.push({
    key: 'nome',
    label: 'Nome',
    path: 'nome',
    type: 'text-multiple',
    queryKey: 'n',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais nomes',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'gcpj'))
{
  filters.push({
    key: 'gcpj',
    label: 'GCPJ',
    path: 'gcpj',
    type: 'text-multiple',
    queryKey: 'g',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais GCPJs',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroCnj'))
{
  filters.push({
    key: 'numeroCnj',
    label: 'Número CNJ',
    path: 'numeroCnj',
    type: 'text-multiple',
    queryKey: 'nc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números CNJs',
    },
    parseValue: (value: any) =>
    {
      return value.split(',').map((x: any) => onlyNumbers(x.trim()));
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroAntigo'))
{
  filters.push({
    key: 'numeroAntigo',
    label: 'Número antigo',
    path: 'numeroAntigo',
    type: 'text-multiple',
    queryKey: 'na',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números antigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'tipoProcesso'))
{
  filters.push({
    key: 'tipoProcesso',
    label: 'Tipo de processo',
    path: 'tipoProcesso',
    type: 'gql-select',
    queryKey: 'tp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais tipos de processo',
      query: tipoProcessoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'status'))
{
  filters.push({
    key: 'status',
    label: 'Status',
    path: 'status',
    type: 'gql-select',
    queryKey: 's',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais status',
      query: tipoPastaStatusSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelProcessual'))
{
  filters.push({
    key: 'responsavelProcessual',
    label: 'Responsável processual',
    path: 'responsavelProcessualCodigo',
    type: 'gql-select',
    queryKey: 'rp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelNegocial'))
{
  filters.push({
    key: 'responsavelNegocial',
    label: 'Responsável negocial',
    path: 'responsavelNegocialCodigo',
    type: 'gql-select',
    queryKey: 'rn',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelAcordo'))
{
  filters.push({
    key: 'responsavelAcordo',
    label: 'Responsável acordo',
    path: 'responsavelAcordoCodigo',
    type: 'gql-select',
    queryKey: 'ra',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'gerenteNegocialResponsavel'))
{
  filters.push({
    key: 'gerenteNegocialResponsavel',
    label: 'Gerente negocial responsável',
    path: 'gerenteNegocialResponsavelCodigo',
    type: 'gql-select',
    queryKey: 'gn',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais gerentes',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'clientePrincipal'))
{
  filters.push({
    key: 'clientePrincipal',
    label: 'Cliente principal',
    path: 'clientePrincipalCodigo',
    type: 'gql-select',
    queryKey: 'clp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais pessoas',
      query: pessoaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'contrarioPrincipal'))
{
  filters.push({
    key: 'contrarioPrincipal',
    label: 'Contrário principal',
    path: 'contrarioPrincipalCodigo',
    type: 'gql-select',
    queryKey: 'cop',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais pessoas',
      query: pessoaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agencia'))
{
  filters.push({
    key: 'agencia',
    label: 'Agência',
    path: 'agencia2Codigo',
    type: 'gql-select',
    queryKey: 'ag',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais agências',
      query: agenciaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agenciaRegional'))
{
  filters.push({
    key: 'agenciaRegional',
    label: 'Agência / Regional',
    path: 'agencia2.regional',
    type: 'gql-select',
    queryKey: 'agr',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais regionais',
      query: tipoAgenciaRegionalSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agenciaPorte'))
{
  filters.push({
    key: 'agenciaPorte',
    label: 'Agência / Porte',
    path: 'agencia2.porte',
    type: 'gql-select',
    queryKey: 'agr',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais portes',
      query: tipoAgenciaPorteSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'ultimaAtualizacaoGcpjReferenciaAndamento'))
{
  filters.push({
    key: 'ultimaAtualizacaoGcpjReferenciaAndamentoCodigo',
    label: 'Última referência GCPJ de andamento',
    path: 'ultimaAtualizacaoGcpjReferenciaAndamentoCodigo',
    type: 'gql-select',
    queryKey: 'urga',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais referências de andamento',
      query: referenciaGcpjAndamentoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'ultimaAtualizacaoGcpjReferenciaEncerramento'))
{
  filters.push({
    key: 'ultimaAtualizacaoGcpjReferenciaEncerramentoCodigo',
    label: 'Última referência GCPJ de encerramento',
    path: 'ultimaAtualizacaoGcpjReferenciaEncerramentoCodigo',
    type: 'gql-select',
    queryKey: 'urge',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais referências de encerramento',
      query: referenciaGcpjEncerramentoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'tempoCadastroEmAnos'))
{
  filters.push({
    key: 'tempoCadastroEmAnos',
    label: 'Tempo de cadastro em anos',
    path: 'tempoCadastroEmAnos',
    type: 'text',
    queryKey: 'tca',
    inWhereClause: false,
    parseValue: (val: any) => Number.parseInt(val),
  });
}

if (!props.disabledFilters.find(x => x === 'tempoAjuizamentoEmAnos'))
{
  filters.push({
    key: 'tempoAjuizamentoEmAnos',
    label: 'Tempo de ajuizamento em anos',
    path: 'tempoAjuizamentoEmAnos',
    type: 'text',
    queryKey: 'taa',
    inWhereClause: false,
    parseValue: (val: any) => Number.parseInt(val),
  });
}

if (!props.disabledFilters.find(x => x === 'gcpjDesatualizado'))
{
  filters.push({
    key: 'gcpjDesatualizado',
    label: 'GCPJ desatualizado',
    path: 'gcpjDesatualizado',
    type: 'boolean',
    queryKey: 'gd',
    inWhereClause: false,
  });
}

if (!props.disabledFilters.find(x => x === 'semValorCausa'))
{
  filters.push({
    key: 'semValorCausa',
    label: 'Sem valor da causa',
    path: 'semValorCausa',
    type: 'boolean',
    queryKey: 'sv',
    inWhereClause: false,
  });
}

if (!props.disabledFilters.find(x => x === 'semConta'))
{
  filters.push({
    key: 'semConta',
    label: 'Sem conta',
    path: 'semConta',
    type: 'boolean',
    queryKey: 'sc',
    inWhereClause: false,
  });
}

if (!props.disabledFilters.find(x => x === 'semCarteiraContrato'))
{
  filters.push({
    key: 'semCarteiraContrato',
    label: 'Sem carteira/contrato',
    path: 'semCarteiraContrato',
    type: 'boolean',
    queryKey: 'scc',
    inWhereClause: false,
  });
}

if (!props.disabledFilters.find(x => x === 'solicitacaoAtualizacaoGcpjPendente'))
{
  filters.push({
    key: 'solicitacaoAtualizacaoGcpjPendente',
    label: 'Solicitação de atualização GCPJ pendente',
    path: 'solicitacaoAtualizacaoGcpjPendente',
    type: 'boolean',
    queryKey: 'sgp',
    inWhereClause: true,
  });
}

if (!props.disabledFilters.find(x => x === 'solicitacaoEncerramentoPendente'))
{
  filters.push({
    key: 'solicitacaoEncerramentoPendente',
    label: 'Solicitação de encerramento pendente',
    path: 'solicitacaoEncerramentoPendente',
    type: 'boolean',
    queryKey: 'sep',
    inWhereClause: true,
  });
}

const colunas = [
  {
    key: 'nome',
    title: 'NOME',
    width: '220px',
  },
  {
    key: 'status',
    title: 'STATUS',
  },
  {
    key: 'acao',
    title: 'AÇÃO',
  },
  {
    key: 'envolvidos',
    title: 'ENVOLVIDOS',
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
  },
  {
    key: 'jurisdicao',
    title: 'JURISDIÇÃO',
  },
] as GraphqlDatatableColumn[];

const pastasSelecionadas = ref<any[]>([]);

function onSelectChange(keys: any)
{
  pastasSelecionadas.value = [...keys];
}

function abrirPastasEmNovasAbas()
{
  const { origin } = window.location;

  pastasSelecionadas.value.forEach((x) =>
  {
    const url = `${origin}/pasta/${x}`;
    window.open(url, '_blank');
  });
}

function acoesPastasSelecionadasOnClick({ key }: any)
{
  if (key === 'alterar-responsavel-processual')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'AlterarResponsavelProcessualForm',
        componentProps: {
          pastaCodigos: pastasSelecionadas.value,
        },
        title: 'ALTERAR RESPONSÁVEL PROCESSUAL',
      },
      width: 600,
    });
  }
  if (key === 'alterar-responsavel-negocial')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'AlterarResponsavelNegocialForm',
        componentProps: {
          pastaCodigos: pastasSelecionadas.value,
        },
        title: 'ALTERAR RESPONSÁVEL NEGOCIAL',
      },
      width: 600,
    });
  }
  if (key === 'solicitar-atualizacao-gcpj-ultima-referencia')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'SolicitarAtualizacaoGcpjForm',
        componentProps: {
          andamento: 'REFERÊNCIA REPETIDA EM VIRTUDE DA AUSÊNCIA DE MOVIMENTAÇÃO PROCESSUAL COM NOVA REFERÊNCIA ESPECÍFICA',
          pastaCodigos: pastasSelecionadas.value,
          repetirUltimaReferencia: true,
        },
        title: 'SOLICITAR ATUALIZAÇÃO DE GCPJ DA ÚLTIMA REFERÊNCIA',
      },
      width: 600,
    });
  }
  if (key === 'criar-compromisso-caq-vistoria-em-lote')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'CriarCompromissoCaqForm',
        componentProps: {
          dataPrazo: `${dayjs().format('DD/MM/YYYY')} 23:59`,
          descricao: 'VERIFICAR ANDAMENTO PROCESSUAL',
          pastaCodigos: pastasSelecionadas.value,
          tipo: 121,
        },
        title: 'CRIAR COMPROMISSO CAQ - VISTORIA',
      },
      width: 600,
    });
  }
  if (key === 'adicionar-pasta-base-ativa')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'AdicionarPastaBaseAtivaForm',
        componentProps: {
          pastaCodigos: pastasSelecionadas.value,
        },
        title: 'ADICIONAR PASTA À BASE ATIVA',
      },
      width: 600,
    });
  }
  if (key === 'remover-pasta-base-ativa')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'RemoverPastaBaseAtivaForm',
        componentProps: {
          pastaCodigos: pastasSelecionadas.value,
        },
        title: 'REMOVER PASTA DA BASE ATIVA',
      },
      width: 600,
    });
  }

  if (key === 'alterar-gerente-negocial-responsavel')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm3',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'AlterarGerenteNegocialResponsavelForm',
        componentProps: {
          pastaCodigos: pastasSelecionadas.value,
        },
        title: 'ALTERAR GERENTE NEGOCIAL RESPONSÁVEL',
      },
      width: 600,
    });
  }
}

defineExpose({
  reloadAsync: async () =>
  {
    if (tableRef.value)
    {
      await tableRef.value?.reloadAsync();
    }
  },
});
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="pastaDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="props.keepHistory"
    :filters="filters"
    :text-search="{ placeholder: 'Busca rápida de pastas por nome, GCPJ, número CNJ, número antigo, status, tipo da ação, cliente principal, contrário principal, responsável processual ou negocial, nome da agência, regional e jurisdição' }"
    export-command="ExportarPastaDatatableCommand"
    :export-job-type="tiposJobs.ExportacaoPastas"
    row-key="codigo"
    :row-selection="props.enableRowSelection
      ? {
        selectedRowKeys: pastasSelecionadas,
        onChange: onSelectChange,
      }
      : null"
    :page-size-options="['10', '20', '30', '40', '50', '100', '500']"
  >
    <template #leftTopActions>
      <a-col
        v-if="pastasSelecionadas.length > 0"
        flex="100px"
        style="margin-left: 8px"
      >
        <a-button
          size="large"
          @click="abrirPastasEmNovasAbas"
        >
          <template #icon>
            <ExportOutlined />
          </template>
          Abrir em nova aba
        </a-button>
      </a-col>
      <a-col
        flex="100px"
      >
        <a-dropdown>
          <template #overlay>
            <a-menu @click="acoesPastasSelecionadasOnClick">
              <a-menu-item
                v-if="authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)"
                key="alterar-responsavel-processual"
              >
                Alterar responsável processual
              </a-menu-item>
              <a-menu-item
                v-if="authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)"
                key="alterar-responsavel-negocial"
              >
                Alterar responsável negocial
              </a-menu-item>
              <a-menu-item
                key="alterar-gerente-negocial-responsavel"
              >
                Alterar gerente negocial responsável
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.AutomatizacaoSolicitarAtualizacaoGcpjUltimaReferenciaEmLote,
                  )
                "
                key="solicitar-atualizacao-gcpj-ultima-referencia"
              >
                Solicitar atualização GCPJ da última referência
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.AutomatizacaoCriarCompromissoCaqVistoriaEmLote,
                  )
                "
                key="criar-compromisso-caq-vistoria-em-lote"
              >
                Criar compromisso CAQ (VISTORIA)
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.FluxoAdicionarBaseAtivaAdicionarPastaBaseAtiva,
                  )
                "
                key="adicionar-pasta-base-ativa"
              >
                Adicionar à base ativa
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.FluxoRemoverBaseAtivaRemoverPastaBaseAtiva,
                  )
                "
                key="remover-pasta-base-ativa"
              >
                Remover da base ativa
              </a-menu-item>
            </a-menu>
          </template>
          <a-button
            block
            size="large"
          >
            <template #icon>
              <SettingOutlined />
            </template>
            Ações <CaretDownFilled />
          </a-button>
        </a-dropdown>
      </a-col>
    </template>
    <template #nomeColumn="{ record, column }">
      <slot
        name="nomeColumn"
        :column="column"
        :record="record"
      >
        <a-row>
          <a-col :span="24">
            <a-row>
              <a-col>
                <a-tooltip>
                  <template #title>
                    Ir para página da pasta
                  </template>
                  <router-link :to="`/pasta/${record.codigo}`">
                    {{ record.nome }}
                  </router-link>
                </a-tooltip>
              </a-col>
            </a-row>
            <a-row>
              <a-col>
                <div style="margin-top: 4px">
                  <a-typography-text
                    v-if="record.numeroCnj"
                    style="color: #7d7c7c"
                    copyable
                  >
                    {{ formatCnj(record.numeroCnj) }}
                  </a-typography-text>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </slot>
    </template>
    <template #statusColumn="{ record }">
      {{ record.statusText?.toUpperCase() }}
    </template>
    <template #acaoColumn="{ record }">
      {{ record.acao?.nome.toUpperCase() }}
    </template>
    <template #envolvidosColumn="{ record }">
      <a-row
        v-if="
          isNilOrEmpty(record.clientePrincipal)
            && isNilOrEmpty(record.contrarioPrincipal)
        "
      >
        <a-col span="24">
          -
        </a-col>
      </a-row>
      <a-row v-else>
        <a-col span="24">
          <a-row>
            <a-col>
              <div v-if="!isNilOrEmpty(record.clientePrincipal)">
                <a-tooltip
                  v-if="
                    record.clientePrincipal.nome === 'BRADESCO S/A'
                      && !isNilOrEmpty(record.agencia)
                  "
                >
                  <template #title>
                    Ir para página da agência
                  </template>
                  <router-link
                    :to="`/agencia/${record.agencia.codigo}`"
                    style="margin-right: 8px"
                  >
                    <span>{{ record.agencia.numero }}</span>
                    <span
                      v-if="!isNilOrEmpty(record.agencia.nome)"
                      style="text-transform: uppercase"
                    >/{{ record.agencia.nome }}</span>
                    <span
                      v-if="!isNilOrEmpty(record.agencia.regionalText)"
                      style="text-transform: uppercase"
                    >
                      ({{
                        record.agencia.regionalText.toUpperCase()
                      }})</span>
                  </router-link>
                </a-tooltip>
                <a-tooltip v-else>
                  <template #title>
                    Ir para página do cliente
                  </template>
                  <router-link
                    :to="`/contato/${record.clientePrincipal.codigo}`"
                    style="margin-right: 8px"
                  >
                    {{ record.clientePrincipal.nome.toUpperCase() }}
                  </router-link>
                </a-tooltip>
                <a-tag style="color: #b3b1b1; font-size: 10px">
                  CLIENTE
                </a-tag>
              </div>
            </a-col>
          </a-row>
          <a-row style="margin-top: 4px">
            <a-col>
              <div v-if="!isNilOrEmpty(record.contrarioPrincipal)">
                <a-tooltip>
                  <template #title>
                    Ir para página do contrário
                  </template>
                  <router-link
                    :to="`/contato/${record.contrarioPrincipal.codigo}`"
                    style="margin-right: 8px"
                  >
                    {{ record.contrarioPrincipal.nome.toUpperCase() }}
                  </router-link>
                </a-tooltip>
                <a-tag style="color: #b3b1b1; font-size: 10px">
                  CONTRÁRIO
                </a-tag>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </template>
    <template #responsavelColumn="{ record }">
      <a-row v-if="!isNilOrEmpty(record.responsavelProcessual)">
        <a-col>
          <a-tooltip>
            <template #title>
              Ir para página do responsável
            </template>
            <router-link
              :to="`/usuario/${record.responsavelProcessual.codigo}`"
              style="text-transform: uppercase; margin-right: 8px"
            >
              {{ record.responsavelProcessual.nome.toUpperCase() }}
            </router-link>
            <a-tag style="color: #b3b1b1; font-size: 10px">
              PROCESSUAL
            </a-tag>
          </a-tooltip>
        </a-col>
      </a-row>
      <a-row v-if="!isNilOrEmpty(record.responsavelNegocial)">
        <a-col>
          <a-tooltip>
            <template #title>
              Ir para página do responsável
            </template>
            <router-link
              :to="`/usuario/${record.responsavelNegocial.codigo}`"
              style="text-transform: uppercase; margin-right: 8px"
            >
              {{ record.responsavelNegocial.nome.toUpperCase() }}
            </router-link>
            <a-tag style="color: #b3b1b1; font-size: 10px">
              NEGOCIAL
            </a-tag>
          </a-tooltip>
        </a-col>
      </a-row>
      <a-row v-if="!isNilOrEmpty(record.gerenteNegocialResponsavel)">
        <a-col>
          <a-tooltip>
            <template #title>
              Ir para página do gerente negocial
            </template>
            <router-link
              :to="`/usuario/${record.gerenteNegocialResponsavel.codigo}`"
              style="text-transform: uppercase; margin-right: 8px"
            >
              {{ record.gerenteNegocialResponsavel.nome.toUpperCase() }}
            </router-link>
            <a-tag style="color: #b3b1b1; font-size: 10px">
              GERENTE NEGOCIAL
            </a-tag>
          </a-tooltip>
        </a-col>
      </a-row>
    </template>
    <template #jurisdicaoColumn="{ record }">
      <span
        v-if="!isNilOrEmpty(record.estado)"
        style="text-transform: uppercase"
      >{{ record.comarca?.nome ?? record.cidade?.nome ?? '-' }} /
        {{ record.estado.sigla }}</span>
      <span v-else>-</span>
    </template>
  </GqlDatatable>
</template>
