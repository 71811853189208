<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useTotalAcordosUltimosMeses } from '~/composables/pastas/useTotalAcordosUltimosMeses';
import { useTotalPastasAtivasUltimosMeses } from '~/composables/pastas/useTotalPastasAtivasUltimosMeses';
import { useTotalPastasPorStatus } from '~/composables/pastas/useTotalPastasPorStatus';
import { useTotalValorAcordosNoMes } from '~/composables/pastas/useTotalValorAcordosNoMes';
import { useTotalValoresCausas } from '~/composables/pastas/useTotalValoresCausas';
import { useValoresCausasUltimosMeses } from '~/composables/pastas/useValoresCausasUltimosMeses';
import { useTotalPublicacoesUltimosDias } from '~/composables/publicacoes/useTotalPublicacoesUltimosDias';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { colors, formatCnj, formatDinheiro } from '~/utils';

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const {
  fetch: totalPastasAtivasUltimosMesesFetch,
  response: totalPastasAtivasUltimosMeses,
  status: totalPastasAtivasUltimosMesesStatus,
} = useTotalPastasAtivasUltimosMeses({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: valoresCausasUltimosMesesFetch,
  response: valoresCausasUltimosMeses,
  status: valoresCausasUltimosMesesStatus,
} = useValoresCausasUltimosMeses({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: totalValoresCausasFetch,
  response: totalValoresCausas,
  status: totalValoresCausasStatus,
} = useTotalValoresCausas({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: totalValorAcordosNoMesFetch,
  response: totalValorAcordosNoMes,
  status: totalValorAcordosNoMesStatus,
} = useTotalValorAcordosNoMes({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: totalAcordosUltimosMesesFetch,
  response: totalAcordosUltimosMeses,
  status: totalAcordosUltimosMesesStatus,
} = useTotalAcordosUltimosMeses({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: totalPublicacoesUltimosDiasFetch,
  response: totalPublicacoesUltimosDias,
  status: totalPublicacoesUltimosDiasStatus,
} = useTotalPublicacoesUltimosDias({
  codigoUsuario: authStore.codigoUsuario,
});

const {
  fetch: totalPastasPorStatusFetch,
  response: totalPastasPorStatus,
  status: totalPastasPorStatusStatus,
} = useTotalPastasPorStatus({
  codigoUsuario: authStore.codigoUsuario,
});

const totalPastasAtivasUltimosMesesOptions = ref(null);
const valoresCausasUltimosMesesOptions = ref(null);
const totalAcordosUltimosMesesOptions = ref(null);
const totalPublicacoesUltimosDiasOptions = ref(null);
const totalPastasPorStatusOptions = ref(null);
const drawerIndex = null;
let unsubscribe = null;

watch(
  () => totalPastasAtivasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasAtivasUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalPastasAtivasUltimosMeses.value.map(x => x.total),
            name: 'Pastas ativas',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalPastasAtivasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => valoresCausasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      valoresCausasUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: valoresCausasUltimosMeses.value.map(x => x.total),
            name: 'Valores causas',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: valoresCausasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
        yaxis: {
          labels: {
            formatter: (value) =>
            {
              return formatDinheiro(value);
            },
          },
        },
      };
    }
  },
);

watch(
  () => totalPublicacoesUltimosDiasStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPublicacoesUltimosDiasOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalPublicacoesUltimosDias.value.map(x => x.total),
            name: 'Publicações',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalPublicacoesUltimosDias.value.map(
            x => `${x.dia}/${x.mes}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalAcordosUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalAcordosUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalAcordosUltimosMeses.value.map(x => x.total),
            name: 'Acordos',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalAcordosUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalPastasPorStatusStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasPorStatusOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        colors,
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        series: [
          {
            data: totalPastasPorStatus.value.map(x => x.total),
            name: 'total de pastas',
          },
        ],
        xaxis: {
          categories: totalPastasPorStatus.value.map(x => x.status),
          labels: {
            show: false,
          },
        },
      };
    }
  },
);

async function carregar()
{
  await Promise.all([
    totalPastasAtivasUltimosMesesFetch(),
    valoresCausasUltimosMesesFetch(),
    totalValoresCausasFetch(),
    totalValorAcordosNoMesFetch(),
    totalAcordosUltimosMesesFetch(),
    totalPublicacoesUltimosDiasFetch(),
    totalPastasPorStatusFetch(),
  ]);
}

onMounted(async () =>
{
  unsubscribe = drawerStore.$subscribe(async (mutation, state) =>
  {
    if (state.activeDrawer + 1 === drawerIndex)
    {
      await carregar();
    }
  });

  await carregar();
});

onUnmounted(() =>
{
  if (unsubscribe)
  {
    unsubscribe();
  }
});

const compromissosGqlDatatableVars = {
  where: {
    and: [
      {
        responsavelCodigo: {
          in: [authStore.codigoUsuario],
        },
      },
    ],
  },
};

const pastasComPendenciasGqlDatatableVars = {
  where: {
    and: [
      {
        fluxos: {
          some: {
            compromissos: {
              some: {
                status: {
                  eq: 1,
                },
                responsavelCodigo: {
                  in: [authStore.codigoUsuario],
                },
              },
            },
          },
        },
      },
    ],
  },
};

const pastasPendenciasRef = ref(null);

function onPastaClick(record)
{
  drawerStore.push2({
    title: 'COMPROMISSOS PENDENTES DA PASTA',
    wrapperStyle: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    componentName: 'CompromissoGqlDatatable',
    params: {
      enableRowSelection: true,
      showStatusSearchBar: false,
      variables: {
        where: {
          and: [
            {
              responsavelCodigo: {
                eq: authStore.codigoUsuario,
              },
            },
            {
              status: {
                eq: 1,
              },
            },
            {
              fluxo: {
                pastaCodigo: {
                  eq: record.codigo,
                },
              },
            },
          ],
        },
      },
    },
    onClose: async () =>
    {
      await pastasPendenciasRef.value?.reloadAsync();
    },
  });
}
</script>

<template>
  <Page
    :loading="false"
    menu="area-trabalho"
  >
    <div
      class="area-trabalho-page"
      style="padding-bottom: 24px"
    >
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Área de trabalho
              </h1>
            </a-col>
            <a-col :span="12" />
          </a-row>

          <a-tabs
            style="margin-top: 8px"
            :destroy-inactive-tab-pane="true"
          >
            <a-tab-pane
              key="1"
              tab="Geral"
            >
              <h2 style="margin-top: 8px">
                Compromissos
              </h2>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="6">
                  <CompromissosEmAtrasoGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoHojeGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoProximosDiasGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosConcluidosGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
              </a-row>

              <h2 style="margin-top: 24px">
                Pastas
              </h2>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="6">
                  <PastasAtivasGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <PastasGcpjDesatualizadoGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <PastasAcordosNoMesGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
                <a-col :span="6">
                  <PublicacoesHojeGqlCard :usuario-codigo="[authStore.codigoUsuario]" />
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="12">
                  <ChartCard
                    :status="totalPastasAtivasUltimosMesesStatus"
                    :options="totalPastasAtivasUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">PASTAS ATIVAS</span>
                    </template>
                  </ChartCard>
                </a-col>
                <a-col :span="12">
                  <ChartCard
                    :status="valoresCausasUltimosMesesStatus"
                    :options="valoresCausasUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">VALORES DAS CAUSAS</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="12">
                  <StatsCard :status="totalValoresCausasStatus">
                    <template #title>
                      VALORES CAUSAS
                    </template>
                    <template #content>
                      {{ formatDinheiro(totalValoresCausas) }}
                    </template>
                  </StatsCard>
                </a-col>
                <a-col :span="12">
                  <StatsCard :status="totalValorAcordosNoMesStatus">
                    <template #title>
                      VALOR ACORDO NO MÊS
                    </template>
                    <template #content>
                      {{ formatDinheiro(totalValorAcordosNoMes) }}
                    </template>
                  </StatsCard>
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="12">
                  <ChartCard
                    :status="totalAcordosUltimosMesesStatus"
                    :options="totalAcordosUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">TOTAL ACORDOS</span>
                    </template>
                  </ChartCard>
                </a-col>
                <a-col :span="12">
                  <ChartCard
                    :status="totalPublicacoesUltimosDiasStatus"
                    :options="totalPublicacoesUltimosDiasOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">PUBLICAÇÕES</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>

              <a-row
                :gutter="24"
                style="margin-top: 24px"
              >
                <a-col :span="12">
                  <ChartCard
                    :status="totalPastasPorStatusStatus"
                    :options="totalPastasPorStatusOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">TOTAL PASTAS POR STATUS</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane
              key="2"
              tab="Compromissos"
            >
              <CompromissoGqlDatatable
                :enable-row-selection="true"
                :show-text-search="true"
                :variables="compromissosGqlDatatableVars"
              />
            </a-tab-pane>
            <a-tab-pane
              key="3"
              tab="Pastas com pendências"
            >
              <PastaGqlDatatable
                ref="pastasPendenciasRef"
                :variables="pastasComPendenciasGqlDatatableVars"
              >
                <template #nomeColumn="{ record }">
                  <a-row>
                    <a-col :span="24">
                      <a-row>
                        <a-col>
                          <a-tooltip>
                            <template #title>
                              Ver compromissos pendentes
                            </template>
                            <a
                              href="#"
                              @click.prevent="() => onPastaClick(record)"
                            >
                              {{ record.nome }}
                            </a>
                          </a-tooltip>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col>
                          <div style="margin-top: 4px">
                            <a-typography-text
                              v-if="record.numeroCnj"
                              style="color: #7d7c7c"
                              copyable
                            >
                              {{ formatCnj(record.numeroCnj) }}
                            </a-typography-text>
                          </div>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
              </PastaGqlDatatable>
            </a-tab-pane>
            <a-tab-pane
              key="4"
              tab="Conclusões de fluxos pendentes"
            >
              <CompromissoGqlDatatable
                :enable-row-selection="true"
                :show-status-search-bar="false"
                :variables="{
                  where: {
                    and: [
                      {
                        responsavelCodigo: {
                          eq: authStore.codigoUsuario,
                        },
                      },
                      {
                        status: {
                          eq: 1,
                        },
                      },
                      {
                        tipo: {
                          eq: 71,
                        },
                      },
                    ],
                  },
                }"
              />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
