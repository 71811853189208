<script setup>
import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import { useDrawerStore } from '~/stores/drawer';
import { useRemoverPessoaTelefone } from '~/composables/pessoas/useRemoverPessoaTelefone';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    required: true,
  },
  pessoasTelefones: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['pessoa-telefone-salvo', 'pessoa-telefone-removido']);

const drawerStore = useDrawerStore();

const {
  status: removerPessoaTelefoneStatus,
  runAsync: removerPessoaTelefoneAsync,
  err: removerPessoaTelefoneErr,
} = useRemoverPessoaTelefone();

function comparePessoaTelefone(a, b)
{
  if (a.ehPrincipal)
  {
    return -2;
  }
  if (a.ehPrincipal === b.ehPrincipal)
  {
    if (a.dataCriacaoEmTimestamp < b.dataCriacaoEmTimestamp)
    {
      return 1;
    }
    if (a.dataCriacaoEmTimestamp > b.dataCriacaoEmTimestamp)
    {
      return -1;
    }
    return 0;
  }
}

const state = reactive({
  pessoasTelefones: [...props.pessoasTelefones].sort(comparePessoaTelefone),
});

function abrirSalvarPessoaTelefoneForm(pessoaTelefoneCodigo = null)
{
  drawerStore.push2({
    componentName: 'SalvarPessoaTelefoneForm',
    width: 600,
    params: {
      pessoaCodigo: props.pessoaCodigo,
      pessoaTelefoneCodigo,
    },
    onSuccess: async () =>
    {
      emit('pessoa-telefone-salvo');
    },
  });
}

async function removerAsync(pessoaTelefoneCodigo)
{
  await removerPessoaTelefoneAsync({
    pessoaTelefoneCodigo,
  });

  if (removerPessoaTelefoneStatus.value === 'error')
  {
    notification.error({
      message: 'Tivemos um problema ao tentar remover',
    });
    return;
  }

  emit('pessoa-telefone-removido');
}
</script>

<template>
  <a-card title="Telefones" style="margin-top: 24px">
    <template #extra>
      <a-button @click="() => abrirSalvarPessoaTelefoneForm()">
        Novo telefone
      </a-button>
    </template>
    <a-spin :spinning="removerPessoaTelefoneStatus === 'loading'">
      <a-list item-layout="horizontal" :data-source="state.pessoasTelefones">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta :description="item.telefone.numero">
              <template #title>
                <a-tag v-if="item.ehPrincipal" color="green">
                  PRINCIPAL
                </a-tag>
                <a href="#">{{ item.descricao }}</a>
              </template>
            </a-list-item-meta>
            <template #actions>
              <a
                href="#"
                @click.prevent="
                  () => abrirSalvarPessoaTelefoneForm(item.codigo)
                "
              >Editar</a>
              <a href="#" @click.prevent="() => removerAsync(item.codigo)">Remover</a>
            </template>
          </a-list-item>
        </template>
      </a-list>
    </a-spin>
  </a-card>
</template>
