<script setup lang="ts">
import { computed, ref } from 'vue';
import { CaretDownFilled, SettingOutlined } from '@ant-design/icons-vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import andamentoDatatable from '~/graphql/andamentoDatatable.graphql';
import tiposSistemaTribunalSelect from '~/graphql/tiposSistemaTribunalSelect.graphql';
import { formatCnj, onlyNumbers } from '~/utils';
import { useDrawerStore } from '~/stores/drawer';
import tipoPerfil from '~/constants/tipoPerfil';
import { useAuthStore } from '~/stores/auth';

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataMovimentacao: 'DESC' }] };
  },
  enableRowSelection: true,
  enableTextSearch: true,
});

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
  enableRowSelection?: boolean
  enableTextSearch?: boolean
}

const drawerStore = useDrawerStore();
const authStore = useAuthStore();

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'numeroProcesso'))
{
  filters.push({
    key: 'numeroProcesso',
    label: 'Número do processo',
    path: 'numeroProcesso',
    type: 'text-multiple',
    queryKey: 'nc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números de processos',
    },
    parseValue: (value: any) =>
    {
      return value.split(',').map((x: any) => onlyNumbers(x.trim()));
    },
  });
}

if (!props.disabledFilters.find(x => x === 'sistemaTribunal'))
{
  filters.push({
    key: 'sistemaTribunal',
    label: 'Sistema de tribunal',
    path: 'sistemaTribunal',
    type: 'gql-select',
    queryKey: 's',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais sistemas de tribunal',
      query: tiposSistemaTribunalSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataMovimentacao'))
{
  filters.push({
    key: 'dataMovimentacao',
    label: 'Data de movimentação',
    path: 'dataMovimentacao',
    type: 'date',
    queryKey: 'dc',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

const colunas = [
  {
    key: 'numeroProcesso',
    title: 'NÚMERO DO PROCESSO',
    width: '210px',
  },
  {
    key: 'sistemaTribunalText',
    dataIndex: 'sistemaTribunalText',
    title: 'SISTEMA TRIBUNAL',
    width: '180px',
  },
  {
    dataIndex: 'dataMovimentacaoText',
    key: 'dataMovimentacaoText',
    title: 'DATA',
    defaultSortOrder: 'descend',
    sortBy: ['dataMovimentacao'],
    sorter: true,
    width: '150px',
  },
  {
    key: 'movimentacao',
    title: 'MOVIMENTAÇÃO',
  },
] as GraphqlDatatableColumn[];

const andamentosSelecionados = ref<any[]>([]);

function onSelectChange(keys: any)
{
  andamentosSelecionados.value = [...keys];
}

function abrirFluxoDoAndamento(andamentoCodigo: any)
{
  drawerStore.push2({
    componentName: 'FluxosView2',
    params: {
      andamentoCodigo,
    },
    onClose: async () =>
    {
      await tableRef.value?.reloadAsync();
    },
  });
}

defineExpose({
  reload: async () =>
  {
    if (tableRef.value)
    {
      await tableRef.value?.reloadAsync();
    }
  },
});

const mostrarAcoes = computed(() =>
{
  return (
    andamentosSelecionados.value.length > 0
    && authStore.temPerfilAcesso(tipoPerfil.SolicitarVerificacaoAndamentoManual)
  );
});

function acoesSelecionadosOnClick({ key }: any)
{
  if (key === 'solicitar-verificacao')
  {
    drawerStore.push2({
      componentName: 'SolicitarVerificacaoAndamentoCapturadoForm',
      params: {
        andamentoCodigos: andamentosSelecionados.value,
      },
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
    });
  }
}
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="andamentoDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="props.keepHistory"
    :filters="filters"
    :text-search="props.enableTextSearch ? { placeholder: 'Buscar por número de processo' } : null"
    row-key="codigo"
    :page-size-options="['10', '20', '30', '40', '50']"
    :row-selection="props.enableRowSelection
      ? {
        selectedRowKeys: andamentosSelecionados,
        onChange: onSelectChange,
      }
      : null"
  >
    <template #topActions>
      <a-col
        v-if="mostrarAcoes"
        flex="50px"
        style="margin-left: 4px"
      >
        <a-dropdown>
          <template #overlay>
            <a-menu @click="acoesSelecionadosOnClick">
              <a-menu-item key="solicitar-verificacao">
                Solicitar verificação
              </a-menu-item>
            </a-menu>
          </template>
          <a-button size="large">
            <template #icon>
              <SettingOutlined />
            </template> Ações
            <CaretDownFilled />
          </a-button>
        </a-dropdown>
      </a-col>
    </template>
    <template #numeroProcessoColumn="{ record }">
      <a
        href="#"
        style="text-transform: uppercase"
        @click.prevent="() => abrirFluxoDoAndamento(record.codigo)"
      >{{ formatCnj(record.numeroProcesso) }}</a>
    </template>
    <template #movimentacaoColumn="{ record }">
      <a-typography-paragraph
        :ellipsis="{ rows: 2, expandable: true }"
        :content="record.movimentacao"
        style="margin-bottom: 0; white-space: pre-wrap"
      />
    </template>
    <template #gerenteColumn="{ record }">
      {{ record.gerentes.map((x: any) => x.nome.toUpperCase()).join(', ') }}
    </template>
    <template #equipesColumn="{ record }">
      {{ record.equipes.map((x: any) => x.perfilText.toUpperCase()).join(', ') }}
    </template>
  </GqlDatatable>
</template>
