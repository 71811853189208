import { usePost } from '../usePost';

const API_URL = import.meta.env.VITE_API_URL;

export function useCriarJob() {
  return usePost({
    url: `${API_URL}/jobs/criar`,
    config: {
      timeout: 120000,
      getAccessToken: true,
    },
  });
}
