<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { onMounted, watch, ref } from 'vue';

const props = defineProps({
  query: {
    type: String,
    required: true,
  },
  variables: {
    type: Object,
    default: () => {},
  },
  columns: {
    type: Array,
    default: () => [],
  },
  rowKey: {
    type: String,
    default: null,
  },
  bordered: {
    type: Boolean,
    default: true,
  },
  pagination: {
    type: Object,
    default: () => null,
  },
  paginationType: {
    type: String,
    default: null,
  },
});

const { runAsync, status, data } = useGraphQL({
  query: props.query,
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const dataSource = ref([]);

onMounted(async () => {
  await runAsync(props.variables);
});

watch(
  () => props.variables,
  async () => {
    await runAsync(props.variables);

    if (status.value === 'success') {
      if (props.paginationType === null || paginationType === 'offset') {
        dataSource.value = data.value.items;
      }
    }
  }
);
</script>
<template>
  <a-table
    :loading="status === 'loading'"
    :columns="props.columns"
    :data-source="data?.items ?? []"
    :row-key="props.rowKey"
    :bordered="props.bordered"
    :pagination="props.pagination"
  >
    <ErrorResult v-if="status === 'error'" :tentar-novamente-fn="runAsync" />
    <template v-for="(_, name) in $slots" #[name]="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
  </a-table>
</template>
