<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, watch } from 'vue';
import gql from 'graphql-tag';
import { useTotalPastasAtivasUltimosMeses } from '~/composables/pastas/useTotalPastasAtivasUltimosMeses';
import { useValoresCausasUltimosMeses } from '~/composables/pastas/useValoresCausasUltimosMeses';
import { useTotalValoresCausas } from '~/composables/pastas/useTotalValoresCausas';
import { useTotalValorAcordosNoMes } from '~/composables/pastas/useTotalValorAcordosNoMes';
import { useTotalAcordosUltimosMeses } from '~/composables/pastas/useTotalAcordosUltimosMeses';
import { useTotalPublicacoesUltimosDias } from '~/composables/publicacoes/useTotalPublicacoesUltimosDias';
import { useTotalPastasPorStatus } from '~/composables/pastas/useTotalPastasPorStatus';
import { colors, formatDinheiro } from '~/utils';
import PERFIL_ACESSO from '~/perfilAcesso.js';
import PastasCadastradasNoMesGqlCard from '~/components/PastasCadastradasNoMesGqlCard.vue';
import PastasEncerradasNoMesGqlCard from '~/components/PastasEncerradasNoMesGqlCard.vue';

const {
  status: totalPastasAtivasUltimosMesesStatus,
  fetch: totalPastasAtivasUltimosMesesFetch,
  response: totalPastasAtivasUltimosMeses,
} = useTotalPastasAtivasUltimosMeses();

const {
  status: valoresCausasUltimosMesesStatus,
  fetch: valoresCausasUltimosMesesFetch,
  response: valoresCausasUltimosMeses,
} = useValoresCausasUltimosMeses();

const {
  status: totalValoresCausasStatus,
  fetch: totalValoresCausasFetch,
  response: totalValoresCausas,
} = useTotalValoresCausas();

const {
  status: totalValorAcordosNoMesStatus,
  fetch: totalValorAcordosNoMesFetch,
  response: totalValorAcordosNoMes,
} = useTotalValorAcordosNoMes();

const {
  status: totalAcordosUltimosMesesStatus,
  fetch: totalAcordosUltimosMesesFetch,
  response: totalAcordosUltimosMeses,
} = useTotalAcordosUltimosMeses();

const {
  status: totalPublicacoesUltimosDiasStatus,
  fetch: totalPublicacoesUltimosDiasFetch,
  response: totalPublicacoesUltimosDias,
} = useTotalPublicacoesUltimosDias();

const {
  status: totalPastasPorStatusStatus,
  fetch: totalPastasPorStatusFetch,
  response: totalPastasPorStatus,
} = useTotalPastasPorStatus();

const totalPastasAtivasUltimosMesesOptions = ref(null);
const valoresCausasUltimosMesesOptions = ref(null);
const totalAcordosUltimosMesesOptions = ref(null);
const totalPublicacoesUltimosDiasOptions = ref(null);
const totalPastasPorStatusOptions = ref(null);

watch(
  () => totalPastasAtivasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasAtivasUltimosMesesOptions.value = {
        series: [
          {
            name: 'Pastas ativas',
            data: totalPastasAtivasUltimosMeses.value.map(x => x.total),
          },
        ],
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          size: 6,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: totalPastasAtivasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => valoresCausasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      valoresCausasUltimosMesesOptions.value = {
        series: [
          {
            name: 'Valores causas',
            data: valoresCausasUltimosMeses.value.map(x => x.total),
          },
        ],
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          size: 6,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: valoresCausasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
        yaxis: {
          labels: {
            formatter: (value) =>
            {
              return formatDinheiro(value);
            },
          },
        },
      };
    }
  },
);

watch(
  () => totalAcordosUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalAcordosUltimosMesesOptions.value = {
        series: [
          {
            name: 'Acordos',
            data: totalAcordosUltimosMeses.value.map(x => x.total),
          },
        ],
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          size: 6,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: totalAcordosUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalPublicacoesUltimosDiasStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPublicacoesUltimosDiasOptions.value = {
        series: [
          {
            name: 'Publicações',
            data: totalPublicacoesUltimosDias.value.map(x => x.total),
          },
        ],
        chart: {
          type: 'line',
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        markers: {
          size: 6,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: totalPublicacoesUltimosDias.value.map(
            x => `${x.dia}/${x.mes}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalPastasPorStatusStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasPorStatusOptions.value = {
        chart: {
          type: 'bar',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        colors,
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        series: [
          {
            name: 'total de pastas',
            data: totalPastasPorStatus.value.map(x => x.total),
          },
        ],
        xaxis: {
          categories: totalPastasPorStatus.value.map(x => x.status),
          labels: {
            show: false,
          },
        },
      };
    }
  },
);

const pastasAtivasCarteiraNaoVeiculosQuery = gql` query {
    result: pastas (where: {
      status: {
        eq: 2
      }
      pastaPrincipalCodigo: {
        eq: null
      }
      carteirasContratos: {
        some: {
          vigente: {
            eq: true
          }
          carteira: {
            tipo: {
              neq: 4
            }
          }
        }
      }
      responsavelNegocialCodigo: {
        nin: [
          "d80d4c8e-e66d-4e85-af72-b3a1ecd3bcfc", 
          "6f205741-b454-4711-8fa4-8a0dd4a394c7", 
          "324a523e-9f6a-46cb-ab73-16bc286ffc63", 
          "01c27bf7-7367-4813-b237-138253c5689e", 
          null
        ]
      }
    }) {
      responsavelNegocial {
        nome
      }
    }
  }
`;

function postQueryFn(data)
{
  return data.map((x) =>
  {
    return { label: x.responsavelNegocial.nome };
  });
}

const pastasAtivasCarteiraVeiculosQuery = gql` query {
    result: pastas (where: {
      status: {
        eq: 2
      }
      pastaPrincipalCodigo: {
        eq: null
      }
      carteirasContratos: {
        some: {
          vigente: {
            eq: true
          }
          carteira: {
            tipo: {
              eq: 4
            }
          }
        }
      }
      responsavelNegocialCodigo: {
        nin: [
          "d80d4c8e-e66d-4e85-af72-b3a1ecd3bcfc", 
          "6f205741-b454-4711-8fa4-8a0dd4a394c7", 
          "324a523e-9f6a-46cb-ab73-16bc286ffc63", 
          "01c27bf7-7367-4813-b237-138253c5689e", 
          null
        ]
      }
    }) {
      responsavelNegocial {
        nome
      }
    }
  }
`;

const pastasAtivasSemCarteiraQuery = gql` query {
    result: pastas (where: {
      status: {
        eq: 2
      }
      pastaPrincipalCodigo: {
        eq: null
      }
      responsavelNegocialCodigo: {
        nin: [
          "d80d4c8e-e66d-4e85-af72-b3a1ecd3bcfc", 
          "6f205741-b454-4711-8fa4-8a0dd4a394c7", 
          "324a523e-9f6a-46cb-ab73-16bc286ffc63", 
          "01c27bf7-7367-4813-b237-138253c5689e", 
          null
        ]
      }
    }, semCarteiraContrato: true) {
      responsavelNegocial {
        nome
      }
    }
  }
`;

onMounted(async () =>
{
  await Promise.all([
    totalPastasAtivasUltimosMesesFetch(),
    valoresCausasUltimosMesesFetch(),
    totalValoresCausasFetch(),
    totalValorAcordosNoMesFetch(),
    totalAcordosUltimosMesesFetch(),
    totalPublicacoesUltimosDiasFetch(),
    totalPastasPorStatusFetch(),
  ]);
});
</script>

<template>
  <Page
    :loading="false"
    menu="visao-geral"
  >
    <div
      class="visao-geral-page"
      style="padding-bottom: 24px"
    >
      <a-row>
        <a-col :span="12">
          <h1>
            <UnorderedListOutlined style="color: #bebcbc; font-size: 18px" />
            Visão geral
          </h1>
        </a-col>
      </a-row>
      <a-tabs style="margin-top: 8px">
        <a-tab-pane
          key="1"
          tab="Pastas"
        >
          <a-row
            :gutter="24"
            style="margin-top: 8px"
          >
            <a-col :span="4">
              <PastasAtivasGqlCard />
            </a-col>
            <a-col :span="4">
              <PastasGcpjDesatualizadoGqlCard />
            </a-col>
            <a-col :span="4">
              <PastasAcordosNoMesGqlCard />
            </a-col>
            <a-col :span="4">
              <PastasCadastradasNoMesGqlCard />
            </a-col>
            <a-col :span="4">
              <PastasEncerradasNoMesGqlCard />
            </a-col>
            <a-col :span="4">
              <PublicacoesHojeGqlCard />
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <TotalPastasAtivasPorGerenciaChart />
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <GqlChart
                title="PASTAS ATIVAS (CARTEIRAS NÃO VEÍCULOS) - RESPONSÁVEIS NEGOCIAIS"
                :query="pastasAtivasCarteiraNaoVeiculosQuery"
                :post-query-fn="postQueryFn"
              />
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <GqlChart
                title="PASTAS ATIVAS (CARTEIRAS VEÍCULOS) - RESPONSÁVEIS NEGOCIAIS"
                :query="pastasAtivasCarteiraVeiculosQuery"
                :post-query-fn="postQueryFn"
              />
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <GqlChart
                title="PASTAS ATIVAS (SEM CARTEIRA) - RESPONSÁVEIS NEGOCIAIS"
                :query="pastasAtivasSemCarteiraQuery"
                :post-query-fn="postQueryFn"
              />
            </a-col>
          </a-row>
          <GraficosGerentesNegociais />
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <TotalPastasAtivasPorResponsavelChart
                :gerencia="PERFIL_ACESSO.Ggp"
                titulo="GGP - PASTAS ATIVAS"
              />
            </a-col>
          </a-row>

          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <TotalPastasAtivasPorResponsavelChart
                titulo="TOTAL PASTAS ATIVAS POR RESPONSÁVEL PROCESSUAL"
                :tipo-responsavel-pasta="1"
              />
            </a-col>
          </a-row>

          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <TotalPastasAtivasPorResponsavelChart
                titulo="TOTAL PASTAS ATIVAS POR RESPONSÁVEL NEGOCIAL"
                :tipo-responsavel-pasta="3"
              />
            </a-col>
          </a-row>

          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="12">
              <ChartCard
                :status="totalPastasAtivasUltimosMesesStatus"
                :options="totalPastasAtivasUltimosMesesOptions"
              >
                <template #title>
                  <span style="color: rgb(128 128 128); font-size: 16px">PASTAS ATIVAS</span>
                </template>
              </ChartCard>
            </a-col>
            <a-col :span="12">
              <ChartCard
                :status="valoresCausasUltimosMesesStatus"
                :options="valoresCausasUltimosMesesOptions"
              >
                <template #title>
                  <span style="color: rgb(128 128 128); font-size: 16px">VALORES DAS CAUSAS</span>
                </template>
              </ChartCard>
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="12">
              <StatsCard :status="totalValoresCausasStatus">
                <template #title>
                  VALORES CAUSAS
                </template>
                <template #content>
                  {{ formatDinheiro(totalValoresCausas) }}
                </template>
              </StatsCard>
            </a-col>
            <a-col :span="12">
              <StatsCard :status="totalValorAcordosNoMesStatus">
                <template #title>
                  VALOR ACORDO NO MÊS
                </template>
                <template #content>
                  {{ formatDinheiro(totalValorAcordosNoMes) }}
                </template>
              </StatsCard>
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="12">
              <ChartCard
                :status="totalAcordosUltimosMesesStatus"
                :options="totalAcordosUltimosMesesOptions"
              >
                <template #title>
                  <span style="color: rgb(128 128 128); font-size: 16px">TOTAL ACORDOS</span>
                </template>
              </ChartCard>
            </a-col>
            <a-col :span="12">
              <ChartCard
                :status="totalPublicacoesUltimosDiasStatus"
                :options="totalPublicacoesUltimosDiasOptions"
              >
                <template #title>
                  <span style="color: rgb(128 128 128); font-size: 16px">PUBLICAÇÕES</span>
                </template>
              </ChartCard>
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="12">
              <ChartCard
                :status="totalPastasPorStatusStatus"
                :options="totalPastasPorStatusOptions"
              >
                <template #title>
                  <span style="color: rgb(128 128 128); font-size: 16px">TOTAL PASTAS POR STATUS</span>
                </template>
              </ChartCard>
            </a-col>
            <a-col :span="12">
              <PastasAptosParaApreensaoPorRegionalChart />
            </a-col>
          </a-row>
          <a-row
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="12">
              <TotalAcordoNoMesPorResponsavelChart />
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane
          key="2"
          tab="Compromissos"
        >
          <CompromissoGqlDatatable :enable-row-selection="true" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </Page>
</template>
