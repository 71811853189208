<script setup>
import { usePost } from '~/composables/usePost';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { reactive, ref } from 'vue';

const props = defineProps({
  andamentoCodigos: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['loaded', 'close', 'success', 'error']);

const {
  err: solicitarVerificacaoAndamentoErr,
  runAsync: solicitarVerificacaoAndamentoAsync,
  status: solicitarVerificacaoAndamentoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/verificacao-andamento-capturado/solicitar-verificacao-andamento-capturado`,
});

const formRef = ref(null);
const state = reactive({
  responsavel: null,
});
const formRules = reactive({
  responsavel: [
    {
      message: 'Responsável é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    await solicitarVerificacaoAndamentoAsync({
      andamentoCodigos: props.andamentoCodigos,
      responsavelCodigo: state.responsavel?.value,
    });

    if (solicitarVerificacaoAndamentoStatus.value === 'error') {
      notification.error({
        duration: 0,
        message: solicitarVerificacaoAndamentoErr.value.message,
      });
    }
  });
};
</script>

<template>
  <div class="solicitar-verificacao-andamento-form">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span class="titulo"
          >SOLICITAR VERIFICAÇÃO DOS ANDAMENTOS</span
        ></template
      >
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-result
        v-if="solicitarVerificacaoAndamentoStatus === 'success'"
        status="success"
        title="Verificações solicitadas com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </template>
      </a-result>

      <div v-if="solicitarVerificacaoAndamentoStatus !== 'success'">
        <a-alert
          v-if="props.andamentoCodigos?.length > 1"
          type="warning"
          message="Você irá realizar uma alteração em lote"
          show-icon
          style="margin-bottom: 24px"
        ></a-alert>

        <a-form
          ref="formRef"
          layout="vertical"
          :model="state"
          :rules="formRules"
        >
          <a-form-item name="responsavel" label="Responsável">
            <GraphQlSelect
              v-model:value="state.responsavel"
              query="USUARIO_SELECT"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
            />
          </a-form-item>

          <a-form-item style="margin-top: 24px; margin-bottom: 0px">
            <a-button
              :loading="solicitarVerificacaoAndamentoStatus === 'loading'"
              type="primary"
              style="margin-right: 8px; min-width: 100px"
              @click.prevent="uiSalvarAsync"
            >
              Salvar
            </a-button>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<style lang="less">
.solicitar-verificacao-andamento-form {
  padding-bottom: 24px;

  & .titulo {
    font-size: 16px;
    font-weight: 500;
    line-height: 35px;
    text-transform: uppercase;
  }
}
</style>
