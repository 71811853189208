<script setup>
import { onUnmounted, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';

defineEmits(['close']);

const useStore = defineStore('mudar-senha-form', {
  state: () => ({ salvarStatus: null, error: null, senha: null }),
  actions: {
    async mudarSenha()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          `${API_URL}/autenticacao/mudar-senha`,
          { senha: this.senha },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.error = error(e).error;
        this.salvarStatus = 'error';
      }
    },
  },
});

const $s = useStore();
const formRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  senha: [{ required: true, message: 'Senha é obrigatória' }],
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});

async function salvar()
{
  await $s.mudarSenha();
  if ($s.salvarStatus === 'error')
  {
    notification.error({
      class: 'er-notification',
      message: $s.error,
      duration: 10.0,
    });
  }

  return true;
}
</script>

<template>
  <div style="padding-bottom: 24px">
    <drawer-title @close="() => $emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          MUDAR SENHA</span>
      </template>
    </drawer-title>
    <div style="padding: 0 24px">
      <a-result
        v-if="$s.salvarStatus === 'success'"
        status="success"
        title="Senha alterada com sucesso"
      >
        <template #extra>
          <a-button @click="() => $emit('close')">
            Fechar
          </a-button>
        </template>
      </a-result>
      <div v-if="$s.salvarStatus !== 'success'">
        <a-form
          ref="formRef"
          layout="vertical"
          :model="formModel"
          :rules="formRules"
          @finish="salvar"
        >
          <a-form-item
            label="Sua nova senha"
            name="senha"
            style="padding-bottom: 0"
          >
            <a-input-password v-model:value="$s.senha" />
          </a-form-item>

          <a-form-item>
            <a-button
              :loading="$s.salvarStatus === 'loading'"
              type="primary"
              html-type="submit"
              style="margin-right: 8px"
            >
              Salvar
            </a-button>
            <a-button style="margin-right: 8px" @click="() => $emit('close')">
              Cancelar
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
