<script setup>
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, reactive, ref, toRaw } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: aprovarSolicitacaoServicoCdiErr,
  runAsync: aprovarSolicitacaoServicoCdiAsync,
  status: aprovarSolicitacaoServicoCdiStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/cdi/aprovar-solicitacao-servico-cdi`,
});

const $auth = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  aprovado: null,
  arquivos: [],
  observacao: null,
});
const formRules = reactive({
  aprovado: [{ message: 'Aprovar ou não é obrigatório', required: true }],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    await uploaderRef.value.upload();

    await aprovarSolicitacaoServicoCdiAsync({
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      ...toRaw(formModel),
    });

    if (aprovarSolicitacaoServicoCdiStatus.value === 'error') {
      notification.error({
        description: aprovarSolicitacaoServicoCdiErr.value.description,
        duration: 15,
        message: aprovarSolicitacaoServicoCdiErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
};

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="aprovarSolicitacaoServicoCdiStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-row
          v-if="aprovarSolicitacaoServicoCdiStatus !== 'success'"
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item label="Aprovado?" name="aprovado">
                <a-radio-group v-model:value="formModel.aprovado">
                  <a-radio-button :value="true"> Sim </a-radio-button>
                  <a-radio-button :value="false"> Não </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item label="Observação" name="observacao">
                <a-textarea v-model:value="formModel.observacao" :rows="4" />
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">Envie os arquivos necessários</p>
                </template>
              </Uploader>
            </a-form>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== $auth.codigoUsuario
                  "
                  :loading="
                    uploaderStatus === 'uploading' ||
                    aprovarSolicitacaoServicoCdiStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>

                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
