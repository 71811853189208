<script setup lang="ts">
import gql from 'graphql-tag';

const gerentesNegociaisQuery = gql`
  query {
    usuarios(
    where: {
      pastasGerenciasNegociais: {
        some: {
          status: {
            eq: 2
          }
          pastaPrincipalCodigo: {
            eq: null
          }
          responsavelNegocialCodigo: {
            nin: [
              "d80d4c8e-e66d-4e85-af72-b3a1ecd3bcfc", 
              "6f205741-b454-4711-8fa4-8a0dd4a394c7", 
              "324a523e-9f6a-46cb-ab73-16bc286ffc63", 
              "01c27bf7-7367-4813-b237-138253c5689e", 
              null
            ]
          }
        }
      }
    }
  ) {
    codigo
    nome
  }
  }
`;

const pastasAtivasPorGerenteNegocialQuery = gql` query pastasAtivasPorGerenteNegocialQuery($gerenteNegocialResponsavel: String!) {
    result: pastas (where: {
      status: {
        eq: 2
      }
      pastaPrincipalCodigo: {
        eq: null
      }
      gerenteNegocialResponsavelCodigo: {
        eq: $gerenteNegocialResponsavel
      }
      responsavelNegocialCodigo: {
        nin: [
          "d80d4c8e-e66d-4e85-af72-b3a1ecd3bcfc",
          "6f205741-b454-4711-8fa4-8a0dd4a394c7",
          "324a523e-9f6a-46cb-ab73-16bc286ffc63",
          "01c27bf7-7367-4813-b237-138253c5689e",
          null
        ]
      }
    }) {
      responsavelNegocial {
        nome
      }
    }
  }
`;

function postQueryFn(data: any)
{
  return data.map((x: any) =>
  {
    return { label: x.responsavelNegocial.nome };
  });
}
</script>

<template>
  <ApolloQuery
    :query="gerentesNegociaisQuery"
  >
    <template #default="{ result: { data, error }, isLoading }">
      <a-skeleton
        :loading="isLoading === 1"
        active
      >
        <a-result
          v-if="error"
          status="warning"
          title="Problema inesperado"
          sub-title="Tivemos um problema ao tentar carregar os gráficos dos gerentes negociais"
        />
        <div
          v-else
        >
          <GqlChart
            v-for="usuario in data.usuarios"
            :key="usuario.codigo"
            :title="`${usuario.nome.toUpperCase()} / PASTAS ATIVAS`"
            :query="pastasAtivasPorGerenteNegocialQuery"
            :variables="{ gerenteNegocialResponsavel: usuario.codigo }"
            :post-query-fn="postQueryFn"
            style="margin-top: 24px"
          />
        </div>
      </a-skeleton>
    </template>
  </ApolloQuery>

  <!-- <a-skeleton
    :loading="loading"
    active
  >
    <div v-if="result">
      {{ result }}
      <GqlChartTotalPerObject
        v-for="usuario in result.usuarios"
        :key="usuario.codigo"
        :title="`${usuario.nome.toUpperCase()} / PASTAS ATIVAS`"
        :query="pastasAtivasCarteiraNaoVeiculosQuery"
        :variables="{ gerenteNegocialResponsavel: usuario.codigo }"
        :post-query-fn="postQueryFn"
      />
    </div>
  </a-skeleton> -->
</template>
