import { useGet } from '../useGet';
import { API_URL } from '~/utils';

export function useObterTotalSolicitacoesCdiPorUsuario() {
  return useGet({
    url: `${API_URL}/compromissos/obter-total-solicitacoes-cdi-por-usuario`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
