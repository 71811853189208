<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { API_URL } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import { usePost } from '~/composables/usePost';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
  dataPrazo: {
    default: null,
    type: String,
  },
  descricao: {
    default: null,
    type: String,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
  tipo: {
    default: null,
    type: Number,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: criarCompromissoCaqErr,
  runAsync: criarCompromissoCaqAsync,
  status: criarCompromissoCaqStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/compromisso-caq/criar-compromisso-caq`,
});

const pastaCodigos = computed(() =>
{
  if (props.codigoPasta !== null)
  {
    return [props.codigoPasta];
  }

  if (props.pastaCodigos !== null)
  {
    return props.pastaCodigos;
  }

  return null;
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  dataPrazo: props.dataPrazo,
  descricao: props.descricao,
  responsavel: null,
  tipo: props.tipo !== null ? { value: props.tipo } : null,
});
const formRules = reactive({
  dataPrazo: [{ message: 'Prazo é obrigatório', required: true }],
  descricao: [
    {
      message: 'Descrição é obrigatória',
      required: true,
    },
  ],
  responsavel: [
    {
      message: 'Responsável é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  tipo: [
    {
      message: 'Tipo é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);
function disabledDate(current)
{
  return (
    current.day() === 0
    || current.day() === 6
    || current <= dayjs().subtract(1, 'day').endOf('day')
  );
}

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();

    await criarCompromissoCaqAsync({
      arquivos: formModel.arquivos,
      codigoResponsavel: formModel.responsavel.value,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      dataPrazo: formModel.dataPrazo,
      descricao: formModel.descricao,
      pastaCodigos: pastaCodigos.value,
      tipo: formModel.tipo.value,
    });

    if (criarCompromissoCaqStatus.value === 'error')
    {
      notification.error({
        description: criarCompromissoCaqErr.value.description,
        duration: 5,
        message: criarCompromissoCaqErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
}

watch(
  x => formModel.tipo,
  (val) =>
  {
    // PRAZO FATAL
    if (val.value === 76)
    {
      formModel.dataPrazo = null;
    }
  },
);

onMounted(async () =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-result
      v-if="criarCompromissoCaqStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="criarCompromissoCaqStatus !== 'success'">
      <a-alert
        v-if="pastaCodigos?.length > 1"
        type="warning"
        message="Você irá realizar uma alteração em lote"
        show-icon
        style="margin-bottom: 24px"
      />

      <a-collapse
        v-if="props.codigoPasta !== null"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 72,
                },
                fluxo: {
                  pastaCodigo: {
                    in: [props.codigoPasta],
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
        style="margin-top: 16px"
      >
        <a-form-item v-if="props.tipo === null" label="Tipo" name="tipo">
          <AjaxSelect
            v-model:value="formModel.tipo"
            :url="`${API_URL}/ajax-select/tipo-compromisso-caq`"
            :value-is-number="true"
            placeholder="Selecione o tipo"
          />
        </a-form-item>

        <a-form-item
          v-if="props.descricao === null"
          label="Descrição"
          name="descricao"
          style="padding-bottom: 0"
        >
          <a-textarea v-model:value="formModel.descricao" :rows="4" />
        </a-form-item>

        <a-form-item label="Responsável" name="responsavel">
          <GraphQlSelect
            v-model:value="formModel.responsavel"
            query="USUARIO_SELECT"
            :variables="{
              where: {
                ativo: {
                  eq: true,
                },
              },
              order: [
                {
                  nome: 'ASC',
                },
              ],
            }"
            placeholder="Selecione o responsável"
            :allow-clear="true"
          />
        </a-form-item>

        <!-- 76 é o compromisso CaqPrazoFatal que tem um vencimento padrão -->
        <a-form-item
          v-if="props.dataPrazo === null && formModel.tipo?.value !== 76"
          label="Prazo"
          name="dataPrazo"
        >
          <a-date-picker
            v-model:value="formModel.dataPrazo"
            format="DD/MM/YYYY HH:mm"
            value-format="DD/MM/YYYY HH:mm"
            placeholder="Selecione uma data"
            style="width: 100%"
            :disabled-date="disabledDate"
            :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
          />
        </a-form-item>

        <a-alert
          v-if="formModel.tipo?.value === 76"
          message="O vencimento para o prazo fatal é hoje às 23:59:59"
          type="info"
          show-icon
          style="margin-bottom: 16px"
        />

        <Uploader
          ref="uploaderRef"
          v-model:fileList="formModel.arquivos"
          @start="() => (uploaderStatus = 'uploading')"
          @end="() => (uploaderStatus = 'done')"
        >
          <template #description>
            <p class="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p class="ant-upload-text">
              Clique aqui ou arraste os arquivos
            </p>
            <p class="ant-upload-hint">
              Envie os arquivos necessários
            </p>
          </template>
        </Uploader>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNil(props.compromisso)
                && props.compromisso.responsavel.codigo !== authStore.codigoUsuario
            "
            :loading="
              uploaderStatus === 'uploading'
                || criarCompromissoCaqStatus === 'loading'
            "
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
