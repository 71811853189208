<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="1"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      v-for="gcpj in props.compromisso.data.gcpjs "
      :key="gcpj"
      :span="3"
    >
      {{ gcpj }}
    </a-descriptions-item>
  </a-descriptions>
</template>
