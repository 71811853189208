<script setup>
import { values } from 'lodash-es';
import { ref } from 'vue';
import { tipoJobImportacao, tipoJobRelatorio } from '~/constants/tipoJob';
import tipoJobStatus from '~/constants/tipoJobStatus';

const props = defineProps({
  columns: {
    default: () => [
      {
        key: 'tipo',
        title: 'TIPO',
      },
      {
        key: 'status',
        title: 'STATUS',
      },
      {
        dataIndex: ['geradoPor', 'nome'],
        key: 'geradoPor',
        title: 'GERADO POR',
      },
      {
        dataIndex: 'dataCriacaoText',
        defaultSortOrder: 'descend',
        key: 'data',
        sortBy: ['dataCriacao'],
        sorter: true,
        title: 'DATA',
      },
    ],
    type: Array,
  },
  disabledFilters: {
    default: () => [],
    type: Array,
  },
  graphqlVariables: {
    default: () => ({}),
    type: Object,
  },
  reloadInterval: {
    default: () => null,
    type: Number,
  },
});

const filters = [];

if (!props.disabledFilters.find(x => x === 'tiposRelatorios'))
{
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum tipo encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais tipos',
      query: 'TIPOS_JOBS_FOR_SELECT',
      size: 'large',
      variables: {
        where: {
          value: {
            in: values(tipoJobRelatorio),
          },
        },
      },
    },
    graphqlQuery: (val) =>
    {
      const codigos = val.map(x => x.value);
      return {
        where: {
          and: [
            {
              tipo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'tiposRelatorios',
    label: 'Tipo relatório',
    queryKey: 'tr',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find(x => x === 'tiposImportacoes'))
{
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum tipo encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais tipos',
      query: 'TIPOS_JOBS_FOR_SELECT',
      size: 'large',
      variables: {
        where: {
          value: {
            in: values(tipoJobImportacao),
          },
        },
      },
    },
    graphqlQuery: (val) =>
    {
      const codigos = val.map(x => x.value);
      return {
        where: {
          and: [
            {
              tipo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'tiposImportacoes',
    label: 'Tipo importação',
    queryKey: 'ti',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find(x => x === 'data'))
{
  filters.push({
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      size: 'large',
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
    graphqlQuery: (val) =>
    {
      return {
        where: {
          and: [
            {
              dataCriacao: {
                gte: `${val[0]} 00:00:00`,
                lte: `${val[1]} 23:59:59`,
              },
            },
          ],
        },
      };
    },
    key: 'data',
    label: 'Data',
    queryKey: 'd',
    type: 'date',
  });
}

if (!props.disabledFilters.find(x => x === 'geradoPor'))
{
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum usuário encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais usuáruos',
      query: 'USUARIO_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) =>
    {
      const codigos = val.map(x => x.value);
      return {
        where: {
          and: [
            {
              geradoPorCodigo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'geradoPor',
    label: 'Gerado por',
    queryKey: 'gp',
    type: 'graphql-select',
  });
}

const graphqlDatatableRef = ref(null);

defineExpose({
  getDataSource: () =>
  {
    return graphqlDatatableRef.value.getDataSource();
  },
  reloadAsync: () =>
  {
    graphqlDatatableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <GraphqlDatatable
    ref="graphqlDatatableRef"
    query="JOB_DATA_TABLE"
    :keep-history="false"
    :columns="props.columns"
    row-key="codigo"
    :filters="filters"
    :graphql-variables="props.graphqlVariables"
    :reload-interval="props.reloadInterval"
  >
    <template #leftTopActions>
      <slot name="leftTopActions" />
    </template>
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'tipo'">
        <slot
          name="tipo"
          :record="record"
        >
          {{ record.tipoText }}
        </slot>
      </template>

      <template v-if="column.key === 'status'">
        <a-tag
          :color="
            record.status === tipoJobStatus.Processando
              ? 'processing'
              : record.status === tipoJobStatus.Sucesso
                ? 'success'
                : 'error'
          "
          style="text-transform: uppercase"
        >
          {{ record.statusText }}
        </a-tag>
      </template>
    </template>
  </GraphqlDatatable>
</template>
