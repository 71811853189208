<script setup lang="ts">
import { onMounted } from 'vue';
import { getGoogleDriveUrl } from '~/utils';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    bordered
  >
    <a-descriptions-item
      v-for="(mensagem, index) in props.compromisso.data.resultados"
      :key="mensagem"
      :span="3"
    >
      {{ index + 1 }} - {{ mensagem }}
    </a-descriptions-item>
  </a-descriptions>
  <a-descriptions
    :column="3"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
