<script setup>
import { useObterTotalFluxos } from '~/composables/compromissos/useObterTotalFluxos';
import { onMounted, onUnmounted } from 'vue';

const props = defineProps({
  titulo: {
    type: String,
    required: true,
  },
  status: {
    type: Number,
    default: 2,
  },
  tipos: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['click']);

const {
  status: obterTotalFluxosStatus,
  data: obterTotalFluxosData,
  runAsync: obterTotalFluxosAsync,
} = useObterTotalFluxos();

const obterTotalFluxos = async () => {
  await obterTotalFluxosAsync({
    tipos: props.tipos,
    status: props.status,
  });
};

onMounted(async () => {
  await obterTotalFluxos();
});

defineExpose({
  update: obterTotalFluxos,
});
</script>

<template>
  <StatsCard :status="obterTotalFluxosStatus">
    <template #title> {{ props.titulo }} </template>
    <template #content>
      <a href="#" @click.prevent="() => emit('click')">
        {{ obterTotalFluxosData?.total }}
      </a>
    </template>
  </StatsCard>
</template>
