import { usePost } from '../usePost';
import { API_URL } from '~/utils';

export function useDevolverCompromisso() {
  return usePost({
    url: `${API_URL}/compromissos/devolver-compromisso`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
