<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { formatCnj, formatDate } from '~/utils';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '25%' }" bordered>
    <a-descriptions-item label="Ação" :span="3">
      {{ props.compromisso.data.acaoNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Número CNJ" :span="3">
      {{ formatCnj(props.compromisso.data.numeroCnj, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataAjuizamento' in props.compromisso.data"
      label="Data de ajuizamento"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataAjuizamento,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item label="Vara" :span="3">
      {{ props.compromisso.data.vara }}
    </a-descriptions-item>

    <a-descriptions-item label="Responsável processual" :span="3">
      {{ props.compromisso.data.responsavelProcessualNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Responsável negocial" :span="3">
      {{ props.compromisso.data.responsavelNegocialNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Petição inicial" :span="3">
      <a
        target="_blank"
        :href="getGoogleDriveUrl(props.compromisso.data.peticaoInicialFileServiceId)"
      >
        {{ props.compromisso.data.peticaoInicialFileName }}
      </a>
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
          <a-tag v-if="arquivo.atributos" style="margin-left: 8px">
            {{
              arquivo.atributos.referenciaGcpjNome
            }}
          </a-tag>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
