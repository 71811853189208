<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { usePost } from '~/composables/usePost';
import router from '~/router';
import {
  arrayToNestedObject,
  formatDinheiro,
  isNilOrEmpty,
  mergeWithArrayConcat,
} from '~/utils';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { cloneDeep } from 'lodash-es';
import { onMounted, onUnmounted, reactive, ref, toRaw, watch } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  bordered: {
    default: true,
    type: Boolean,
  },
  columns: {
    default: () => [],
    type: Array,
  },
  exportJob: {
    default: () => null,
    type: Object,
  },
  filters: {
    default: () => [],
    type: Array,
  },
  graphqlVariables: {
    default: () => {},
    type: Object,
  },
  keepHistory: {
    default: true,
    type: Boolean,
  },
  pageSizeOptions: {
    default: () => ['10', '20', '30', '40', '50'],
    type: Array,
  },
  query: {
    required: true,
    type: String,
  },
  rowKey: {
    default: null,
    type: String,
  },
  rowSelection: {
    default: () => null,
    type: Object,
  },
  textSearch: {
    default: () => null,
    type: Object,
  },
  reloadInterval: {
    default: () => null,
    type: Number,
  },
});

const emit = defineEmits(['change', 'success']);

const { data, runAsync, status } = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: props.query,
});

const {
  data: jobQueryData,
  runAsync: jobQueryAsync,
  status: jobQueryStatus,
} = !isNilOrEmpty(props.exportJob?.url)
  ? useGraphQL({
      config: {
        getAccessToken: true,
        timeout: 120000,
      },
      query: 'JobQuery',
    })
  : { data: null, runAsync: async () => {}, status: null };

const {
  data: exportJobData,
  runAsync: exportJobAsync,
  status: exportJobStatus,
} = !isNilOrEmpty(props.exportJob?.url)
  ? usePost({
      config: {
        getAccessToken: true,
        timeout: 120000,
      },
      url: props.exportJob?.url,
    })
  : { data: null, runAsync: async () => {}, status: null };

const dataSource = ref([]);

const route = useRoute();

const paginationState = reactive({
  currentPage: 1,
  total: 0,
  totalPerPage: 10,
});

const state = reactive({
  exportJobLastResult: null,
  exportJobLoading: false,
  filtersIsOpened: false,
  text: null,
  totalUsedFilters: 0,
  usedFilters: {},
});

const filterState = reactive({});

const graphQlVars = ref(cloneDeep(props.graphqlVariables || {}));

const stateToQuery = () => {
  const q = {};

  if (!isNilOrEmpty(state.text)) {
    q.t = state.text;
  }

  props.filters.forEach((filter) => {
    if (
      filter.type === 'graphql-select' &&
      filterState[filter.key].length > 0
    ) {
      q[filter.queryKey] = JSON.stringify(
        filterState[filter.key].map((x) => ({ label: x.label, value: x.value }))
      );
    }

    if (filter.type === 'date' && filterState[filter.key].length > 0) {
      q[filter.queryKey] = JSON.stringify(filterState[filter.key]);
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key])) {
      q[filter.queryKey] = filterState[filter.key];
    }

    if (
      filter.type === 'text-multiple' &&
      !isNilOrEmpty(filterState[filter.key])
    ) {
      q[filter.queryKey] = filterState[filter.key];
    }

    if (
      filter.type === 'number' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      q[`${filter.queryKey}min`] = filterState[`${filter.key}Min`];
      q[`${filter.queryKey}max`] = filterState[`${filter.key}Max`];
    }

    if (
      filter.type === 'money' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      q[`${filter.queryKey}min`] = filterState[`${filter.key}Min`];
      q[`${filter.queryKey}max`] = filterState[`${filter.key}Max`];
    }
  });

  return q;
};

const initializaFilterState = () => {
  props.filters.forEach((filter) => {
    if (filter.type === 'graphql-select') {
      filterState[filter.key] = [];
    }

    if (filter.type === 'date') {
      filterState[filter.key] = [];
    }

    if (filter.type === 'text') {
      filterState[filter.key] = null;
    }

    if (filter.type === 'text-multiple') {
      filterState[filter.key] = null;
    }

    if (filter.type === 'number') {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }

    if (filter.type === 'money') {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }
  });
};

const queryToState = () => {
  const q = route.query || {};

  if (!isNilOrEmpty(q.t)) {
    state.text = q.t;
  }

  props.filters.forEach((filter) => {
    if (filter.type === 'graphql-select') {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? JSON.parse(q[filter.queryKey])
        : [];
    }

    if (filter.type === 'date') {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? JSON.parse(q[filter.queryKey])
        : [];
    }

    if (filter.type === 'text') {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? q[filter.queryKey]
        : null;
    }

    if (filter.type === 'number') {
      filterState[`${filter.key}Min`] = !isNilOrEmpty(
        q[`${filter.queryKey}min`]
      )
        ? JSON.parse(q[`${filter.queryKey}min`])
        : null;

      filterState[`${filter.key}Max`] = !isNilOrEmpty(
        q[`${filter.queryKey}max`]
      )
        ? JSON.parse(q[`${filter.queryKey}max`])
        : null;
    }

    if (filter.type === 'money') {
      filterState[`${filter.key}Min`] = !isNilOrEmpty(
        q[`${filter.queryKey}min`]
      )
        ? JSON.parse(q[`${filter.queryKey}min`])
        : null;

      filterState[`${filter.key}Max`] = !isNilOrEmpty(
        q[`${filter.queryKey}max`]
      )
        ? JSON.parse(q[`${filter.queryKey}max`])
        : null;
    }
  });
};

const updateGraphQLVariables = () => {
  let variables = cloneDeep(props.graphqlVariables || {});

  props.filters.forEach((filter) => {
    if (
      filter.type === 'graphql-select' &&
      filterState[filter.key]?.length > 0
    ) {
      variables = mergeWithArrayConcat(
        variables,
        filter.graphqlQuery(filterState[filter.key])
      );
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0) {
      variables = mergeWithArrayConcat(
        variables,
        filter.graphqlQuery(filterState[filter.key])
      );
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key])) {
      variables = mergeWithArrayConcat(
        variables,
        filter.graphqlQuery(filterState[filter.key])
      );
    }

    if (
      filter.type === 'money' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      variables = mergeWithArrayConcat(
        variables,
        filter.graphqlQuery([
          filterState[`${filter.key}Min`],
          filterState[`${filter.key}Max`],
        ])
      );
    }

    if (
      filter.type === 'number' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      if (filter.variable.find((x) => x === 'where')) {
        let gte = arrayToNestedObject(
          [...filter.variable, 'gte'],
          filterState[`${filter.key}Min`]
        );
        let lte = arrayToNestedObject(
          [...filter.variable, 'lte'],
          filterState[`${filter.key}Max`]
        );

        variables = mergeWithArrayConcat(variables, gte);
        variables = mergeWithArrayConcat(variables, lte);
      }
    }
  });

  if (!isNilOrEmpty(state.text)) {
    variables.text = state.text;
  }

  graphQlVars.value = variables;
};

const graphQLAsync = async () => {
  const vars = {
    ...graphQlVars.value,
    ...{
      skip:
        (paginationState.currentPage - 1) *
        parseInt(paginationState.totalPerPage, 10),
      take: parseInt(paginationState.totalPerPage, 10),
    },
  };

  await runAsync(vars);

  if (status.value === 'success') {
    dataSource.value = data.value.result.items;
    paginationState.total = data.value.result.totalCount;
  }

  if (status.value === 'error') {
    dataSource.value = [];
    paginationState.total = 0;
  }
};

const sortedInfoToGraphQLOrder = async (sInfo) => {
  graphQlVars.value = { ...graphQlVars.value, ...{ order: [] } };
  if (sInfo.column) {
    const sortBy = arrayToNestedObject(
      sInfo.column.sortBy,
      sInfo.order === 'descend' ? 'DESC' : 'ASC'
    );

    graphQlVars.value.order = [sortBy];
  }
};

const resetFilterState = () => {
  props.filters.forEach((filter) => {
    if (filter.type === 'graphql-select') {
      filterState[filter.key] = [];
    }

    if (filter.type === 'date') {
      filterState[filter.key] = [];
    }

    if (filter.type === 'text') {
      filterState[filter.key] = null;
    }

    if (filter.type === 'number') {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }

    if (filter.type === 'money') {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }
  });
};

const updateQueryString = () => {
  if (props.keepHistory) {
    router.push({
      path: route.path,
      query: stateToQuery(),
    });
  }
};

const onTableChange = async (pagination, filters, sorter) => {
  await sortedInfoToGraphQLOrder(sorter);
  emit('change', pagination, filters, sorter);
};

const onPaginationChange = async () => {
  await graphQLAsync();
};

const getDataSource = () => {
  return toRaw(dataSource.value);
};

const updateTotalUsedFilters = () => {
  let total = 0;
  props.filters.forEach((filter) => {
    if (
      filter.type === 'graphql-select' &&
      filterState[filter.key]?.length > 0
    ) {
      total += 1;
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0) {
      total += 1;
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key])) {
      total += 1;
    }

    if (
      filter.type === 'number' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      total += 1;
    }

    if (
      filter.type === 'money' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      total += 1;
    }
  });
  state.totalUsedFilters = total;
};

const updateUsedFilters = () => {
  const filtros = {};

  props.filters.forEach((filter) => {
    if (
      filter.type === 'graphql-select' &&
      filterState[filter.key]?.length > 0
    ) {
      filtros[filter.label] = filterState[filter.key]
        .map((x) => x.label)
        .join(', ');
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0) {
      filtros[filter.label] = `${filterState[filter.key][0]} - ${
        filterState[filter.key][1]
      }`;
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key])) {
      filtros[filter.label] = filterState[filter.key];
    }

    if (
      filter.type === 'number' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      const minStr = !isNilOrEmpty(filterState[`${filter.key}Min`])
        ? `> ${filterState[`${filter.key}Min`]}`
        : '';

      const maxStr = !isNilOrEmpty(filterState[`${filter.key}Max`])
        ? `< ${filterState[`${filter.key}Max`]}`
        : '';

      filtros[filter.label] =
        !isNilOrEmpty(minStr) && !isNilOrEmpty(maxStr)
          ? minStr + ' e ' + maxStr
          : minStr + maxStr;
    }

    if (
      filter.type === 'money' &&
      (!isNilOrEmpty(filterState[`${filter.key}Min`]) ||
        !isNilOrEmpty(filterState[`${filter.key}Max`]))
    ) {
      const minStr = !isNilOrEmpty(filterState[`${filter.key}Min`])
        ? `> R$ ${formatDinheiro(filterState[`${filter.key}Min`])}`
        : '';

      const maxStr = !isNilOrEmpty(filterState[`${filter.key}Max`])
        ? `< R$ ${formatDinheiro(filterState[`${filter.key}Max`])}`
        : '';

      filtros[filter.label] =
        !isNilOrEmpty(minStr) && !isNilOrEmpty(maxStr)
          ? minStr + ' e ' + maxStr
          : minStr + maxStr;
    }
  });

  state.usedFilters = filtros;
};

const onDrawerClose = () => {
  state.filtersIsOpened = false;
};

const updateTableDataAsync = async () => {
  paginationState.currentPage = 1;
  await graphQLAsync();
};

const updateState = () => {
  updateUsedFilters();
  updateTotalUsedFilters();
  updateGraphQLVariables();
};

const onDrawerSearchClick = () => {
  updateQueryString();
  updateState();
  state.filtersIsOpened = false;
};

const onDrawerResetClick = () => {
  resetFilterState();
};

const onResetClick = () => {
  resetFilterState();
  updateQueryString();
  updateState();
};

const onTextSearch = () => {
  updateQueryString();
  updateState();
};

const initializeState = () => {
  initializaFilterState();

  if (props.keepHistory) {
    queryToState();
  }

  updateState();
};

let jobQueryInterval;

const onExportJobClick = async () => {
  if (!isNilOrEmpty(props.exportJob?.url)) {
    return;
  }

  state.exportJobLoading = true;

  await exportJobAsync({
    text: graphQlVars.value.text,
    where: JSON.stringify(graphQlVars.value.where),
  });

  if (exportJobStatus.value === 'error') {
    notification.error({
      duration: 5,
      message: 'Tivemos um problema ao gerar o excel',
    });
  }

  const jobCodigo = exportJobData.value;

  jobQueryInterval = setInterval(async () => {
    await jobQueryAsync({
      codigo: jobCodigo,
    });

    if (jobQueryStatus.value === 'error') {
      notification.error({
        duration: 5,
        message: 'Tivemos um problema ao gerar o excel',
      });

      clearInterval(jobQueryInterval);
      state.exportarParaExcelLoading = false;
      return;
    }

    const job = jobQueryData.value.job;

    if (job.status === 'PROCESSANDO') {
      return;
    }

    if (job.status === 'FALHA') {
      notification.error({
        duration: 0,
        message: 'Tivemos um problema ao gerar o excel',
      });
    }

    if (job.status === 'SUCESSO') {
      notification.success({
        duration: 5,
        message: 'Excel gerado com sucesso',
      });

      state.ultimoJobExcelExportado = job;
    }

    clearInterval(jobQueryInterval);
    state.exportarParaExcelLoading = false;
  }, 5000);
};

initializeState();

const intervalId = ref(null);

watch(
  graphQlVars,
  async (x) => {
    await updateTableDataAsync();
  },
  { immediate: true }
);

onMounted(async () => {
  if (props.reloadInterval) {
    intervalId.value = setInterval(async () => {
      await graphQLAsync();
    }, props.reloadInterval);
  }
});

onUnmounted(() => {
  if (intervalId.value) {
    clearInterval(intervalId.value);
  }
});

defineExpose({
  getDataSource: getDataSource,
  reloadAsync: graphQLAsync,
});
</script>
<template>
  <a-row :gutter="8" style="margin-bottom: 16px" type="flex">
    <a-col flex="auto">
      <a-input-search
        v-if="props.textSearch"
        v-model:value="state.text"
        size="large"
        :placeholder="props.textSearch?.placeholder"
        :allow-clear="true"
        enter-button
        @search="onTextSearch"
      />
    </a-col>
    <slot name="leftTopActions"></slot>
    <a-col flex="50px">
      <a-button size="large" @click="graphQLAsync">
        <template #icon><ReloadOutlined /></template>Recarregar
      </a-button></a-col
    >
    <a-col v-if="props.filters?.length > 0" flex="50px">
      <a-badge :count="state.totalUsedFilters">
        <a-button size="large" @click="() => (state.filtersIsOpened = true)">
          <template #icon><FilterOutlined /></template>Filtros
        </a-button></a-badge
      ></a-col
    >
    <slot name="topActions"></slot>
  </a-row>
  <a-alert v-if="state.totalUsedFilters > 0" :show-icon="false" banner>
    <template #message>
      <a-row align="middle">
        <a-col :span="20"
          ><span style="font-weight: 600; margin-right: 4px"
            >Filtros usados <a-divider type="vertical"
          /></span>
          <span
            v-for="([key, value], index) in Object.entries(
              state.usedFilters
            ).filter(([k, v]) => !isNilOrEmpty(v))"
            :key="key"
          >
            <a-divider v-if="index > 0" type="vertical" />
            <span style="font-weight: 500">{{ key }}</span
            >: {{ value }}
          </span></a-col
        >
        <a-col :span="4" style="text-align: right">
          <a href="#" @click.prevent="onResetClick">Resetar filtros</a>
        </a-col>
      </a-row>
    </template>
  </a-alert>
  <a-table
    class="graphql-table"
    :loading="status === 'loading'"
    :columns="props.columns"
    :data-source="dataSource"
    :row-key="props.rowKey"
    :bordered="props.bordered"
    :pagination="false"
    :row-selection="props.rowSelection"
    @change="
      (pagination, filters, sorter) =>
        onTableChange(pagination, filters, sorter)
    "
  >
    <template #emptyText>
      <ErrorResult
        v-if="status === 'error'"
        :tentar-novamente-fn="() => graphQLAsync()"
      />
      <Empty v-else description="Nenhum resultado encontrado" />
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
    <template #footer>
      <a-row align="middle">
        <a-col :span="6">
          <span class="table-resultado-span"
            >Resultados: {{ paginationState.total }}
          </span></a-col
        >
        <a-col :span="18" style="text-align: right">
          <a-space v-if="!isNilOrEmpty(props.exportJob)">
            <a-alert
              v-if="paginationState.total >= 10000"
              type="warning"
              message="A busca gerou muitos resultados, e a exportação pode levar alguns minutos."
              show-icon
            ></a-alert>
            <a-button
              :loading="state.exportJobLoading"
              @click="onExportJobClick"
              >Exportar para excel</a-button
            >
            <a-button
              v-if="state.exportJobLastResult !== null"
              type="link"
              :href="
                getGoogleDriveUrl(
                  state.exportJobLastResult.resultadoJsonDocument.rootElement
                    .fileServiceId
                )
              "
              target="_blank"
            >
              Visualizar última exportação</a-button
            >
          </a-space>
        </a-col>
      </a-row>
    </template>
  </a-table>
  <a-row>
    <a-col
      :span="24"
      style="text-align: right; padding-right: 16px; padding-top: 16px"
    >
      <a-pagination
        v-model:current="paginationState.currentPage"
        v-model:page-size="paginationState.totalPerPage"
        :disabled="status === 'loading'"
        :show-size-changer="true"
        :page-size-options="props.pageSizeOptions"
        :total="paginationState.total"
        @show-size-change="
          () => {
            paginationState.currentPage = 1;
          }
        "
        @change="onPaginationChange"
    /></a-col>
  </a-row>
  <a-drawer
    :visible="state.filtersIsOpened"
    :destroy-on-close="true"
    :mask-closable="true"
    :closable="true"
    title="FILTROS"
    width="600"
    @close="onDrawerClose"
  >
    <div style="padding: 24px">
      <a-form layout="vertical">
        <a-form-item
          v-for="filter in props.filters"
          :key="filter.key"
          :label="filter.label"
        >
          <GraphQlSelect
            v-if="filter.type === 'graphql-select'"
            v-model:value="filterState[filter.key]"
            v-bind="filter.componentProps"
          ></GraphQlSelect>
          <a-range-picker
            v-if="filter.type === 'date'"
            v-model:value="filterState[filter.key]"
            v-bind="filter.componentProps"
          />
          <a-input
            v-if="filter.type === 'text'"
            v-model:value="filterState[filter.key]"
            v-bind="filter.componentProps"
          />
          <a-input-group v-if="filter.type === 'number'" compact>
            <a-input-number
              v-model:value="filterState[`${filter.key}Min`]"
              :controls="false"
              style="width: 47%"
              placeholder="Mínimo"
              v-bind="filter.componentProps"
            />
            <a-input
              style="
                width: 6%;
                border-left: 0;
                pointer-events: none;
                background-color: #fff;
              "
              placeholder="~"
              disabled
              v-bind="filter.componentProps"
            />
            <a-input-number
              v-model:value="filterState[`${filter.key}Max`]"
              :controls="false"
              style="width: 47%; border-left: 0"
              placeholder="Maximo"
              v-bind="filter.componentProps"
            />
          </a-input-group>
          <a-input-group v-if="filter.type === 'money'" compact>
            <InputMoney
              v-model="filterState[`${filter.key}Min`]"
              :money="{
                locale: 'pt-BR',
                currency: 'BRL',
                currencyDisplay: 'symbol',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: true,
              }"
              v-bind="filter.componentProps"
              style="width: 47%"
              placeholder="Mínimo"
            />
            <a-input
              style="
                width: 6%;
                border-left: 0;
                pointer-events: none;
                background-color: #fff;
              "
              placeholder="~"
              disabled
              v-bind="filter.componentProps"
            />
            <InputMoney
              v-model="filterState[`${filter.key}Max`]"
              :money="{
                locale: 'pt-BR',
                currency: 'BRL',
                currencyDisplay: 'symbol',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: true,
              }"
              v-bind="filter.componentProps"
              style="width: 47%; border-left: 0"
              placeholder="Maximo"
            />
          </a-input-group>
        </a-form-item>
      </a-form>
      <a-row>
        <a-col :span="24">
          <a-button
            :block="true"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="onDrawerSearchClick"
          >
            Buscar
          </a-button>
        </a-col>
      </a-row>
      <a-row style="margin-top: 8px">
        <a-col :span="24">
          <a-button
            :block="true"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="onDrawerResetClick"
          >
            Resetar
          </a-button>
        </a-col>
      </a-row>
    </div>
  </a-drawer>
</template>
<style lang="less">
.graphql-table {
  & .table-resultado-span {
    color: #9a9a9a;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
</style>
