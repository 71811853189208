<script setup>
import { onMounted } from 'vue';
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useDrawerStore } from '~/stores/drawer';
import { useAuthStore } from '~/stores/auth';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useGraphQL } from '~/composables/useGraphQL';

const drawerStore = useDrawerStore();
const authStore = useAuthStore();
const route = useRoute();
const { codigo } = route.params;

const {
  status: pessoaQueryStatus,
  data: pessoaQueryData,
  runAsync: pessoaQueryAsync,
} = useGraphQL({
  query: 'PessoaQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

function abrirSalvarPessoaDrawer()
{
  if (pessoaQueryData.value.pessoa.tipo === 'FISICA')
  {
    abrirSalvarPessoaFisicaDrawer();
  }
  else
  {
    abrirSalvarPessoaJuridicaDrawer();
  }
}

async function carregarAsync()
{
  await pessoaQueryAsync({
    codigo,
  });
}

function abrirSalvarPessoaFisicaDrawer()
{
  drawerStore.push2({
    componentName: 'SalvarPessoaFisicaForm',
    width: 600,
    params: {
      pessoaCodigo: codigo,
    },
    onSuccess: async () =>
    {
      await carregarAsync();
    },
  });
}

function abrirSalvarPessoaJuridicaDrawer()
{
  drawerStore.push2({
    componentName: 'SalvarPessoaJuridicaForm',
    width: 600,
    params: {
      pessoaCodigo: codigo,
    },
    onSuccess: async () =>
    {
      await carregarAsync();
    },
  });
}

onMounted(async () =>
{
  await carregarAsync();
});
</script>

<template>
  <Page menu="contatos">
    <div>
      <a-row>
        <a-col :xs="24">
          <div>
            <!-- Page header -->
            <a-row>
              <a-col :span="12">
                <a-breadcrumb>
                  <a-breadcrumb-item>
                    <router-link to="/contatos">
                      Contatos
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>Visualizar</a-breadcrumb-item>
                </a-breadcrumb>
                <a-skeleton
                  :loading="pessoaQueryStatus === 'loading'"
                  :active="true"
                  :paragraph="false"
                >
                  <h1 v-if="pessoaQueryStatus === 'success'">
                    <UnorderedListOutlined
                      style="color: #bebcbc; font-size: 18px"
                    />
                    {{ pessoaQueryData.pessoa.nome }}
                  </h1>
                </a-skeleton>
              </a-col>
              <a-col :span="12">
                <div
                  v-if="pessoaQueryStatus === 'success'"
                  class="page-header-button-container"
                >
                  <a-button
                    v-if="
                      authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
                        || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                        || authStore.temPerfilAcesso(PERFIL_ACESSO.Editor)
                        || authStore.temPerfilAcesso(PERFIL_ACESSO.Cdi)
                    "
                    @click.prevent="abrirSalvarPessoaDrawer"
                  >
                    Editar
                  </a-button>
                </div>
              </a-col>
            </a-row>
            <!-- /Page header -->

            <div style="margin-top: 24px">
              <div v-if="pessoaQueryStatus === 'loading'">
                <a-card>
                  <a-skeleton :loading="true" :active="true" />
                </a-card>
                <a-card style="margin-top: 24px">
                  <a-skeleton :loading="true" :active="true" />
                </a-card>
                <a-card style="margin-top: 24px">
                  <a-skeleton :loading="true" :active="true" />
                </a-card>
                <a-card style="margin-top: 24px">
                  <a-skeleton :loading="true" :active="true" />
                </a-card>
              </div>
              <div v-else>
                <ErrorResult v-if="pessoaQueryStatus === 'error'" />
                <Pessoa
                  v-else-if="pessoaQueryStatus === 'success'"
                  :pessoa="pessoaQueryData.pessoa"
                  @pessoa-email-salvo="carregarAsync"
                  @pessoa-email-removido="carregarAsync"
                  @pessoa-telefone-salvo="carregarAsync"
                  @pessoa-telefone-removido="carregarAsync"
                  @pessoa-endereco-salvo="carregarAsync"
                  @pessoa-endereco-removido="carregarAsync"
                />
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
