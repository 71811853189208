<script setup lang="ts">
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { nextTick, onMounted, ref } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { getGoogleDriveUrl, isNilOrEmpty } from '~/utils';
import enumSelect from '~/graphql/enumSelect.graphql';
import tipoModeloAjuizamentoSelect from '~/graphql/tipoModeloAjuizamentoSelect.graphql';
import solicitarAjuizamentoForm from '~/graphql/solicitarAjuizamentoForm.graphql';
import pessoaSelect from '~/graphql/pessoaComEnderecoEmailSelect.graphql';
import estadoSelect from '~/graphql/estadoSelect.graphql';
import comarcaSelect from '~/graphql/comarcaSelect.graphql';
import carteiraSelect from '~/graphql/carteiraSelect.graphql';
import agenciaSelect from '~/graphql/agenciaSelect.graphql';
import justicaSelect from '~/graphql/justicaSelect.graphql';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const authStore = useAuthStore();

enum TipoModeloAjuizamentoEnum
{
  ExecucaoSemGarantia = 1,
  ExecucaoComGarantia = 2,
  ExecucaoComAvalista = 3,
  AcaoMonitoriaAberturaConta = 4,
  AcaoMonitoriaAberturaContaAssinadoDigitalmente = 5,
  AcaoCobranca = 6,
  BuscaApreensaoArPositivo = 7,
  BuscaApreensaoArNegativoSemInstrumentoProtesto = 8,
}

const showUploadFiles = ref<boolean>(false);
const comarcaSelectRef = ref<any>(null);

async function onEstadoSelectChange(_: any)
{
  nextTick(async () =>
  {
    await comarcaSelectRef.value.reset();
    await comarcaSelectRef.value.clearItems();
  });
}

async function onJusticaSelectChange(_: any)
{
  nextTick(async () =>
  {
    await comarcaSelectRef.value.reset();
    await comarcaSelectRef.value.clearItems();
  });
}

function tipoModeloAjuizamentoOnChange(val: any, model: any)
{
  if (val)
  {
    showUploadFiles.value = true;

    if ([TipoModeloAjuizamentoEnum.AcaoCobranca].includes(val.value))
    {
      model.acaoCodigo = 10;
    }
    else if ([TipoModeloAjuizamentoEnum.BuscaApreensaoArNegativoSemInstrumentoProtesto, TipoModeloAjuizamentoEnum.BuscaApreensaoArPositivo].includes(val.value))
    {
      model.acaoCodigo = 54;
    }
    else if ([TipoModeloAjuizamentoEnum.ExecucaoComAvalista, TipoModeloAjuizamentoEnum.ExecucaoComGarantia, TipoModeloAjuizamentoEnum.ExecucaoSemGarantia].includes(val.value))
    {
      model.acaoCodigo = 14;
    }
    else if ([TipoModeloAjuizamentoEnum.AcaoMonitoriaAberturaConta, TipoModeloAjuizamentoEnum.AcaoMonitoriaAberturaContaAssinadoDigitalmente].includes(val.value))
    {
      model.acaoCodigo = 21;
    }
  }
  else
  {
    showUploadFiles.value = false;
  }
}

function createInitialModel(model: any): any
{
  const result: any = { ...model };

  result.compromissoCodigos = props.compromisso ? [props.compromisso.codigo] : null;

  if (model.contrarioPrincipalPapel)
  {
    result.contrarioPrincipalPapel = {
      label: model.contrarioPrincipalPapelText.toUpperCase(),
      value: model.contrarioPrincipalPapel,
    };
  }

  if (model.empresa)
  {
    result.empresa = {
      label: model.empresaText.toUpperCase(),
      value: model.empresa,
    };
  }

  return result;
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <GqlView
      :query="solicitarAjuizamentoForm"
      :variables="{
        pastaCodigo: props.compromisso.fluxo.pastaCodigo,
      }"
    >
      <template #success="{ data }">
        <CompromissoCommandForm2
          :command="compromisso.command2"
          :submit-button-disabled="!isNilOrEmpty(compromisso)
            && compromisso.responsavel.codigo !== authStore.codigoUsuario"
          :initial-model="createInitialModel((data as any)?.model)"
          @close="() => emit('close')"
          @submit-success="() => emit('success')"
        >
          <template #form="{ model }">
            <a-form-item
              name="tipoModeloAjuizamento"
              :rules="{
                message: 'Modelo de ajuizamento é obrigatório',
                required: true,
                trigger: 'change',
                type: 'object',
              }"
            >
              <template #label>
                <slot name="formItemLabel">
                  Modelo de ajuizamento
                </slot>
              </template>
              <GqlSelect
                v-model:value="model.tipoModeloAjuizamento"
                :query="tipoModeloAjuizamentoSelect"
                placeholder="Selecione o modelo de ajuizamento"
                :allow-clear="true"
                @change="(val) => tipoModeloAjuizamentoOnChange(val, model)"
              />
            </a-form-item>
            <template v-if="model.tipoModeloAjuizamento">
              <a-form-item
                name="contrarioPrincipal"
                :rules="{ required: true, message: 'Contrário principal é obrigatório', type: 'object' }"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Contrário
                  </slot>
                </template>
                <GqlSelect
                  v-model:value="model.contrarioPrincipal"
                  :query="pessoaSelect"
                  :allow-clear="true"
                  placeholder="Selecione o contrário"
                />
                <a-alert
                  v-if="model.contrarioPrincipal"
                  style="margin-top: 16px"
                  show-icon
                >
                  <template #message>
                    <a
                      :href="`/contato/${model.contrarioPrincipal.value}`"
                      target="_blank"
                    >Clique para conferir o endereço principal do contrário principal {{ model.contrarioPrincipal.label }} em uma nova janela</a>
                  </template>
                </a-alert>
              </a-form-item>

              <a-form-item
                label="Papel do contrário principal"
                name="contrarioPrincipalPapel"
                :rules="{ required: true, message: 'Papel do contrário principal é obrigatório', type: 'object' }"
              >
                <GqlSelect
                  v-model:value="model.contrarioPrincipalPapel"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPapelEnvolvidoEnum' }"
                  placeholder="Selecione o papel do contrário"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Litisconsortes"
                name="litisconsortes"
              >
                <GqlSelect
                  v-model:value="model.litisconsortes"
                  :query="pessoaSelect"
                  placeholder="Seleciona um ou mais litisconsortes"
                  :allow-clear="true"
                  mode="multiple"
                />

                <div
                  v-if="model.litisconsortes?.length > 0"
                  style="margin-top: 8px"
                >
                  <a-alert
                    v-for="litisconsorte in model.litisconsortes"
                    :key="litisconsorte.value"
                    show-icon
                    style="margin-top: 8px"
                  >
                    <template #message>
                      <a
                        :href="`/contato/${litisconsorte.value}`"
                        target="_blank"
                      >Clique para conferir o endereço principal do litisconsorte {{ litisconsorte.label }} em uma nova janela</a>
                    </template>
                  </a-alert>
                </div>
              </a-form-item>

              <a-form-item
                label="Empresa"
                name="empresa"
                :rules="{
                  message: 'Empresa é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <GqlSelect
                  v-model:value="model.empresa"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPastaEmpresaEnum' }"
                  placeholder="Selecione a empresa"
                />
              </a-form-item>

              <a-form-item
                label="Estado"
                name="estado"
                :rules="{
                  message: 'Estado é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <GqlSelect
                  v-model:value="model.estado"
                  :query="estadoSelect"
                  placeholder="Selecione o estado"
                  :allow-clear="true"
                  @change="onEstadoSelectChange"
                />
              </a-form-item>

              <a-form-item
                label="Justiça"
                name="justica"
                :rules="{
                  message: 'Justiça é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <GqlSelect
                  v-model:value="model.justica"
                  :query="justicaSelect"
                  placeholder="Selecione a justiça"
                  :allow-clear="true"
                  @change="onJusticaSelectChange"
                />
              </a-form-item>

              <a-form-item
                name="comarca"
                :rules="{
                  message: 'Comarca é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Comarca
                    <a-tooltip>
                      <template #title>
                        Para selecionar a comarca é necessário que sejam selecionados
                        o estado e a justiça (CNJ).
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="comarcaSelectRef"
                  v-model:value="model.comarca"
                  :query="comarcaSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      estadoCodigo: {
                        in: !isNilOrEmpty(model.estado)
                          ? [model.estado.value]
                          : [],
                      },
                      justicaCodigo: {
                        in: !isNilOrEmpty(model.justica)
                          ? [model.justica.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="
                    isNilOrEmpty(model.estado) || isNilOrEmpty(model.justica)
                  "
                  placeholder="Seleciona a comarca"
                />
              </a-form-item>

              <a-form-item
                label="Agência"
                name="agencia"
                :rules="{
                  message: 'Agência é obrigatória',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <GqlSelect
                  v-model:value="model.agencia"
                  :query="agenciaSelect"
                  placeholder="Selecione o estado"
                  :allow-clear="true"
                  @change="onEstadoSelectChange"
                />
              </a-form-item>

              <a-form-item
                label="Conta"
                name="conta"
                :rules="{ required: true, message: 'Conta é obrigatória' }"
              >
                <a-input
                  v-model:value="model.conta"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Valor da causa"
                name="valorCausa"
                :rules="{
                  message: 'Valor da causa é obrigatório',
                  required: true,
                  type: 'number',
                }"
              >
                <CurrencyInput
                  v-model="model.valorCausa"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                label="Carteira Vigente"
                name="carteira"
                :rules="{
                  message: 'Carteira é obrigatória',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <GqlSelect
                  v-model:value="model.carteira"
                  :query="carteiraSelect"
                  placeholder="Selecione uma carteira"
                />
              </a-form-item>

              <a-form-item
                label="Contrato Vigente"
                name="contrato"
                :rules="{ required: true, message: 'Contrato é obrigatória' }"
              >
                <a-input v-model:value="model.contrato" />
              </a-form-item>

              <a-form-item
                v-if="[
                  TipoModeloAjuizamentoEnum.ExecucaoComGarantia,
                  TipoModeloAjuizamentoEnum.BuscaApreensaoArPositivo,
                  TipoModeloAjuizamentoEnum.BuscaApreensaoArNegativoSemInstrumentoProtesto,
                ].includes(model.tipoModeloAjuizamento.value)"
                label="Garantias"
                name="garantias"
                :rules="{ required: true, message: 'Garantias é obrigatória' }"
              >
                <a-input
                  v-model:value="model.garantias"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="[TipoModeloAjuizamentoEnum.ExecucaoComAvalista].includes(model.tipoModeloAjuizamento.value)"
                label="Avalistas"
                name="avalistas"
                :rules="{
                  message: 'Avalista é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'array',
                }"
              >
                <GqlSelect
                  v-model:value="model.avalistas"
                  :query="pessoaSelect"
                  placeholder="Seleciona um ou mais avalistas"
                  :allow-clear="true"
                  mode="multiple"
                />

                <div
                  v-if="model.avalistas?.length > 0"
                  style="margin-top: 8px"
                >
                  <a-alert
                    v-for="avalista in model.avalistas"
                    :key="avalista.value"
                    show-icon
                    style="margin-top: 8px"
                  >
                    <template #message>
                      <a
                        :href="`/contato/${avalista.value}`"
                        target="_blank"
                      >Clique para conferir o endereço principal do avalista {{ avalista.label }} em uma nova janela</a>
                    </template>
                  </a-alert>
                </div>
              </a-form-item>

              <a-form-item
                v-if="[TipoModeloAjuizamentoEnum.BuscaApreensaoArNegativoSemInstrumentoProtesto].includes(model.tipoModeloAjuizamento.value)"
                name="depositarioFiel"
                :rules="{
                  message: 'Depositário fiel é obrigatório',
                  required: true,
                  trigger: 'change',
                  type: 'object',
                }"
              >
                <template #label>
                  <span>Depositário fiel</span>
                </template>
                <GqlSelect
                  v-model:value="model.depositarioFiel"
                  :query="pessoaSelect"
                  placeholder="Seleciona um depositário fiel"
                  :allow-clear="true"
                />

                <a-alert
                  v-if="model.depositarioFiel"
                  style="margin-top: 16px"
                  show-icon
                >
                  <template #message>
                    <a
                      :href="`/contato/${model.depositarioFiel.value}`"
                      target="_blank"
                    >Clique para conferir o endereço principal do depositário fiel {{ model.depositarioFiel.label }} em uma nova janela</a>
                  </template>
                </a-alert>
              </a-form-item>

              <a-form-item
                label="Valor do contrato"
                name="valorContrato"
                :rules="{
                  message: 'Valor do contrato é obrigatório',
                  required: true,
                  type: 'number',
                }"
              >
                <CurrencyInput
                  v-model="model.valorContrato"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                label="Valor da parcela do contrato original"
                name="valorParcelaContratoOriginal"
                :rules="{
                  message: 'Valor da parcela do contrato original é obrigatório',
                  required: true,
                  type: 'number',
                }"
              >
                <CurrencyInput
                  v-model="model.valorParcelaContratoOriginal"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="[TipoModeloAjuizamentoEnum.AcaoCobranca].includes(model.tipoModeloAjuizamento?.value)"
                label="Número da tela de operação"
                name="telaOperacao"
                :rules="{ required: true, message: 'Número da tela de operação é obrigatória' }"
              >
                <a-input
                  v-model:value="model.telaOperacao"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Plano"
                name="plano"
                :rules="{ required: true, message: 'Plano é obrigatório' }"
              >
                <a-input-number
                  v-model:value="model.plano"
                  :allow-clear="true"
                  style="width: 100%"
                  :controls="false"
                />
              </a-form-item>

              <a-form-item
                label="Data de celebração do contrato original"
                name="dataCelebracaoContratoOriginal"
                :rules="{
                  message: 'Data de celebração do contrato original é obrigatória',
                  required: true,
                }"
              >
                <a-date-picker
                  v-model:value="model.dataCelebracaoContratoOriginal"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Data do vencimento inicial da parcela do contrato original (data fato gerador)"
                name="dataVencimentoInicialParcelaContratoOriginal"
                :rules="{
                  message: 'Data do vencimento inicial da parcela do contrato original é obrigatória',
                  required: true,
                }"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoInicialParcelaContratoOriginal"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Data do vencimento final da parcela do contrato original"
                name="dataVencimentoFinalParcelaContratoOriginal"
                :rules="{
                  message: 'Data do vencimento final da parcela do contrato original é obrigatória',
                  required: true,
                }"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoFinalParcelaContratoOriginal"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                label="Data da primeira parcela vencida"
                name="dataPrimeiraParcelaVencida"
                :rules="{
                  message: 'Data da primeira parcela vencida é obrigatória',
                  required: true,
                }"
              >
                <a-date-picker
                  v-model:value="model.dataPrimeiraParcelaVencida"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="[TipoModeloAjuizamentoEnum.BuscaApreensaoArPositivo].includes(model.tipoModeloAjuizamento?.value)"
                label="Data de atualização do valor da causa"
                name="dataAtualizacaoValorCausa"
                :rules="{
                  message: 'Data de atualização do valor da causa é obrigatória',
                  required: true,
                }"
              >
                <a-date-picker
                  v-model:value="model.dataAtualizacaoValorCausa"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
            </template>
          </template>

          <template #submitSuccess="{ data: submitSuccessData }">
            <a-result
              status="success"
              title="Registro realizado com sucesso"
              sub-title="Agora você pode fechar essa janela"
            >
              <template #extra>
                <a-button @click="() => emit('close')">
                  Fechar
                </a-button>
                <p v-if="submitSuccessData.resultado.length === 1">
                  <a
                    :href="getGoogleDriveUrl(submitSuccessData.resultado[0].arquivoAjuizamentoId)"
                    target="_blank"
                  >
                    Baixar documento de ajuizamento
                  </a>
                </p>
              </template>
            </a-result>
          </template>
        </CompromissoCommandForm2>
      </template>
    </GqlView>
  </div>
</template>
