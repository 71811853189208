<script setup>
import dayjs from 'dayjs';
import { useAuthStore } from '~/stores/auth';
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '15%' }" bordered>
    <a-descriptions-item label="Tipo" :span="3">
      {{ props.compromisso.data.tipoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.responsavelNome"
      label="Responsável"
      :span="3"
    >
      {{ props.compromisso.data.responsavelNome }}
    </a-descriptions-item>

    <a-descriptions-item label="Descrição" :span="3">
      {{ props.compromisso.data.descricao }}
    </a-descriptions-item>

    <a-descriptions-item label="Prazo" :span="3">
      {{
        props.compromisso.data.dataPrazo
          ? dayjs
              .utc(props.compromisso.data.dataPrazo)
              .tz(authStore.timeZone)
              .format('DD/MM/YYYY HH:mm')
          : '-'
      }}
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
