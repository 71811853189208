<script>
export default {
  props: {
    visivel: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: String,
      required: true,
    },
  },
  emits: ['update:visivel'],
  setup(props, { emit })
  {
    const fecharDrawer = () =>
    {
      emit('update:visivel', false);
    };
    return {
      fecharDrawer,
    };
  },
};
</script>

<template>
  <a-drawer
    class="page-sidebar-menu"
    placement="left"
    :closable="false"
    :visible="visivel"
    :width="275"
    :destroy-on-close="true"
    :body-style="{ padding: '0' }"
    @close="fecharDrawer"
  >
    <div class="logo-container" style="text-align: center">
      <div><img class="logo-img" src="/images/logo-122x122.png"></div>
      <router-link class="logo" to="/">
        EDSON ROSAS ADVOCACIA
      </router-link>
    </div>
    <PageMenu :menu="menu" />
  </a-drawer>
</template>

<style lang="less">
@import '../assets/styles/base.less';

.page-sidebar-menu {
  & .logo-container {
    padding-top: 8px;
  }

  & .logo-img {
    width: 60px;
    height: auto;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  & .logo {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: @logo-color;
    font-size: 16px;
    font-weight: 500;
  }

  & h3 {
    display: inline-block;
  }

  & .page-menu {
    margin-top: 12px;
    background-color: @layout-sider-background;
  }
}
</style>
