<script setup lang="ts">
import { onMounted } from 'vue';

const props = defineProps({
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-alert
      v-if="props.pastaCodigos?.length > 1"
      type="warning"
      message="Você irá realizar uma alteração em lote"
      show-icon
      style="margin-bottom: 24px"
    />

    <a-alert
      type="info"
      message="Você irá remover as pastas selecionadas da base ativa"
      show-icon
      style="margin-bottom: 24px"
    />

    <CompromissoCommandForm2
      command="RemocaoPastaBaseAtiva.RemoverPastaBaseAtivaCommand"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
      }"
      :show-upload-files="false"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    />
  </div>
</template>
