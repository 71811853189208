<script setup>
import { onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { isNullOrEmpty } from '~/utils';
import { useGraphQL } from '~/composables/useGraphQL';
import { useSalvarPessoa } from '~/composables/pessoas/useSalvarPessoa';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['success', 'close']);

const {
  status: pessoaQueryStatus,
  data: pessoaQueryData,
  runAsync: pessoaQueryAsync,
} = useGraphQL({
  query: 'PessoaQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const {
  status: salvarPessoaStatus,
  runAsync: salvarPessoaAsync,
  err: salvarPessoaErr,
} = useSalvarPessoa();

const justificarAusenciaCpfModalVisible = ref(false);
const formRef = ref(null);
const state = reactive({
  status: null,
  pessoaCodigo: props.pessoaCodigo,
  tipo: 1,
  sexo: null,
  estadoCivil: null,
  nome: null,
  documento: null,
  rg: null,
  emissor: null,
  profissao: null,
  ctps: null,
  serie: null,
  nitPisPasep: null,
  justificativaAusenciaDocumento: null,
});
const formRules = reactive({
  nome: [{ required: true, message: 'Nome é obrigatório' }],
});

const carregarAsync = async () => {
  await pessoaQueryAsync({
    codigo: props.pessoaCodigo,
  });

  if (pessoaQueryStatus.value === 'success') {
    state.nome = pessoaQueryData.value.pessoa.nome;
    state.documento = pessoaQueryData.value.pessoa.documento;
    state.sexo = pessoaQueryData.value.pessoa.sexoValue;
    state.estadoCivil = pessoaQueryData.value.pessoa.estadoCivilValue;
    state.rg = pessoaQueryData.value.pessoa.rg;
    state.emissor = pessoaQueryData.value.pessoa.emissor;
    state.profissao = pessoaQueryData.value.pessoa.profissao;
    state.ctps = pessoaQueryData.value.pessoa.ctps;
    state.serie = pessoaQueryData.value.pessoa.serie;
    state.nitPisPasep = pessoaQueryData.value.pessoa.nitPisPasep;
    state.justificativaAusenciaDocumento =
      pessoaQueryData.value.pessoa.justificativaAusenciaDocumento;
  }

  state.status = pessoaQueryStatus.value;
};

const salvarAsync = async (fromModal = false) => {
  formRef.value.validate().then(async () => {
    if (!fromModal && isNullOrEmpty(state.documento)) {
      justificarAusenciaCpfModalVisible.value = true;
      return;
    }

    await salvarPessoaAsync({
      pessoaCodigo: state.pessoaCodigo,
      tipo: state.tipo,
      sexo: state.sexo,
      estadoCivil: state.estadoCivil,
      nome: state.nome,
      documento: state.documento,
      rg: state.rg,
      emissor: state.emissor,
      profissao: state.profissao,
      ctps: state.ctps,
      serie: state.serie,
      nitPisPasep: state.nitPisPasep,
      justificativaAusenciaDocumento: state.justificativaAusenciaDocumento,
    });

    if (salvarPessoaStatus.value === 'error') {
      notification.error({
        message: salvarPessoaErr.value.message,
        description: salvarPessoaErr.value.description,
        duration: 5,
      });
    }

    justificarAusenciaCpfModalVisible.value = false;
  });

  return true;
};

onMounted(async () => {
  state.status = 'loading';
  if (props.pessoaCodigo) {
    await carregarAsync();
  } else {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          :loading="state.status === 'loading'"
          :paragraph="false"
          :active="true"
          :title="{ width: '65%' }"
        >
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            {{
              props.pessoaCodigo === null
                ? 'ADICIONAR PESSOA FÍSICA'
                : `EDITAR PESSOA FÍSICA`
            }}
          </span>
        </a-skeleton>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="state.status === 'error'"
          :tentar-novamente-fn="carregarAsync"
        />
        <div v-else-if="state.status === 'success'">
          <a-result
            v-if="salvarPessoaStatus === 'success'"
            status="success"
            title="Pessoa física salva com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('success')"> Fechar </a-button>
            </template>
          </a-result>
          <div v-else>
            <a-form
              ref="formRef"
              layout="vertical"
              :model="state"
              :rules="formRules"
            >
              <a-form-item label="Nome" name="nome">
                <a-input v-model:value="state.nome" />
              </a-form-item>

              <a-form-item label="CPF" name="documento">
                <a-input
                  v-model:value="state.documento"
                  v-maska="'###.###.###-##'"
                />
              </a-form-item>

              <a-form-item label="Sexo" name="sexo">
                <a-select
                  v-model:value="state.sexo"
                  placeholder="Selecione o sexo"
                >
                  <a-select-option :value="0"> MASCULINO </a-select-option>
                  <a-select-option :value="1"> FEMININO </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="Estado civil" name="estadoCivil">
                <a-select
                  v-model:value="state.estadoCivil"
                  placeholder="Selecione o estado civil"
                >
                  <a-select-option :value="2"> SOLTEIRO </a-select-option>
                  <a-select-option :value="1"> CASADO </a-select-option>
                  <a-select-option :value="3"> DIVORCIADO </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="RG" name="rg">
                <a-input v-model:value="state.rg" />
              </a-form-item>

              <a-form-item label="Emissor" name="emissor">
                <a-input v-model:value="state.emissor" />
              </a-form-item>

              <a-form-item label="Profissao" name="profissao">
                <a-input v-model:value="state.profissao" />
              </a-form-item>

              <a-form-item label="Número da CTPS" name="ctps">
                <a-input v-model:value="state.ctps" />
              </a-form-item>

              <a-form-item label="Série" name="serie">
                <a-input v-model:value="state.serie" />
              </a-form-item>

              <a-form-item label="NIT/PIS/PASEP" name="nitPisPasep">
                <a-input v-model:value="state.nitPisPasep" />
              </a-form-item>

              <a-form-item>
                <a-button
                  :loading="salvarPessoaStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="salvarAsync"
                >
                  Salvar
                </a-button>
                <a-button
                  style="margin-right: 8px"
                  @click="() => emit('success')"
                >
                  Fechar
                </a-button>
              </a-form-item>
            </a-form>
            <a-modal
              v-model:visible="justificarAusenciaCpfModalVisible"
              title="Justifique a ausência do CPF (obrigatório)"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="salvarPessoaStatus === 'loading'"
              :ok-button-props="{
                disabled: !state.justificativaAusenciaDocumento?.length,
              }"
              @ok="() => salvarAsync(true)"
              @cancel="() => (state.justificativaAusenciaDocumento = null)"
            >
              <a-textarea
                v-model:value="state.justificativaAusenciaDocumento"
                :rows="4"
                placeholder="Escreva sua justificativa para ausência do CPF"
              />
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
