<script setup>
import { values } from 'lodash-es';
import { ref } from 'vue';
import { tipoJobImportacao } from '~/constants/tipoJob';
import tipoJobStatus from '~/constants/tipoJobStatus';
import { useDrawerStore } from '~/stores/drawer';
import { mergeWithArrayConcat } from '~/utils';

const props = defineProps({
  responsaveisCodigos: {
    default: () => null,
    type: Array,
  },
});

const drawerStore = useDrawerStore();

const graphqlVariables = ref({
  order: [{ dataCriacao: 'DESC' }],
  where: {
    and: [
      {
        tipo: {
          in: values(tipoJobImportacao),
        },
      },
    ],
  },
});

if (props.responsaveisCodigos?.length > 0)
{
  graphqlVariables.value = mergeWithArrayConcat(graphqlVariables.value, {
    where: {
      and: [
        {
          geradoPorCodigo: {
            in: props.responsaveisCodigos,
          },
        },
      ],
    },
  });
}

const graphqlDatatableRef = ref(null);

function abrirImportarProcessosView(job)
{
  drawerIndex = drawerStore.push2({
    componentName: 'ImportarProcessosView',
    params: {
      codigo: job.codigo,
    },
  });
}

defineExpose({
  getDataSource: () =>
  {
    return graphqlDatatableRef.value.getDataSource();
  },
  reloadAsync: () =>
  {
    graphqlDatatableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <JobGraphqlDatatable
    ref="graphqlDatatableRef"
    :graphql-variables="graphqlVariables"
    :disabled-filters="['tiposRelatorios']"
    :reload-interval="10000"
  >
    <template #leftTopActions>
      <slot name="leftTopActions" />
    </template>
    <template #tipo="{ record }">
      <a
        v-if="record.status === tipoJobStatus.Sucesso"
        target="_blank"
        style="text-transform: uppercase"
        @click.prevent="() => abrirImportarProcessosView(record)"
      >{{ record.tipoText }}</a>
      <span
        v-else
        style="text-transform: uppercase"
      >{{
        record.tipoText
      }}</span>
    </template>
  </JobGraphqlDatatable>
</template>
