<script setup>
import { useAuthStore } from '~/stores/auth';
import { formatDate, isNilOrEmpty } from '~/utils';
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['loaded']);

const authStore = useAuthStore();

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Opção" :span="3">
      {{
        props.compromisso.data
          .tipoVerificarNecessidadeCadastroPublicacaoCapturadaPubText
      }}
    </a-descriptions-item>

    <template
      v-if="
        props.compromisso.data
          .tipoVerificarNecessidadeCadastroPublicacaoCapturadaPub === 1
      "
    >
      <a-descriptions-item label="Pasta" :span="3">
        {{ props.compromisso.data.nome }}
      </a-descriptions-item>
      <a-descriptions-item label="Tipo" :span="3">
        {{ props.compromisso.data.tipoText }}
      </a-descriptions-item>
      <a-descriptions-item label="Tipo processo" :span="3">
        {{ props.compromisso.data.tipoProcessoText }}
      </a-descriptions-item>
      <a-descriptions-item label="Status" :span="3">
        {{ props.compromisso.data.statusText }}
      </a-descriptions-item>
      <a-descriptions-item label="GCPJ" :span="3">
        {{ props.compromisso.data.gcpj }}
      </a-descriptions-item>
      <a-descriptions-item label="Número CNJ" :span="3">
        {{ props.compromisso.data.numeroCnj }}
      </a-descriptions-item>
      <a-descriptions-item label="Conta" :span="3">
        {{ props.compromisso.data.conta }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="!isNilOrEmpty(props.compromisso.data.dataBaixa)"
        label="Data baixa"
        :span="3"
      >
        {{
          formatDate(
            props.compromisso.data.dataBaixa,
            authStore.timeZone,
            'DD/MM/YYYY'
          )
        }}
      </a-descriptions-item>
      <a-descriptions-item label="Motivo encerramento" :span="3">
        {{ props.compromisso.data.motivoEncerramento }}
      </a-descriptions-item>
      <a-descriptions-item label="Ação" :span="3">
        {{ props.compromisso.data.acaoNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Justiça" :span="3">
        {{ props.compromisso.data.justicaNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Orgão" :span="3">
        {{ props.compromisso.data.orgaoNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Instância" :span="3">
        {{ props.compromisso.data.instanciaNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Estado" :span="3">
        {{ props.compromisso.data.estadoNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Comarca" :span="3">
        {{ props.compromisso.data.comarcaNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Cliente principal" :span="3">
        {{ props.compromisso.data.clientePrincipalNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Cliente principal (papel)" :span="3">
        {{ props.compromisso.data.clientePrincipalPapelText }}
      </a-descriptions-item>
      <a-descriptions-item label="Contrário principal" :span="3">
        {{ props.compromisso.data.contrarioPrincipalNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Contrário principal (papel)" :span="3">
        {{ props.compromisso.data.contrarioPrincipalPapelText }}
      </a-descriptions-item>
      <a-descriptions-item label="Responsável processual" :span="3">
        {{ props.compromisso.data.responsavelProcessualNome }}
      </a-descriptions-item>
      <a-descriptions-item label="Responsável negocial" :span="3">
        {{ props.compromisso.data.responsavelNegocialNome }}
      </a-descriptions-item>

      <a-descriptions-item
        v-if="!isNilOrEmpty(props.compromisso.data.dataEncerramento)"
        label="Data encerramento"
        :span="3"
      >
        {{
          formatDate(
            props.compromisso.data.dataEncerramento,
            authStore.timeZone,
            'DD/MM/YYYY'
          )
        }}
      </a-descriptions-item>
    </template>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
