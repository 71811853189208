<script setup lang="ts">
import { mergeWithArrayConcat } from '~/utils';

interface Props
{
  usuarioCodigo?: string[]
  perfil?: number[]
}

const props = defineProps<Props>();

let gqlVariables: any = {};

const baseGqlVariables = {
  where: {
    status: {
      eq: 2,
    },
    pastaPrincipalCodigo: {
      eq: null,
    },
  },
};

if (props.usuarioCodigo && props.usuarioCodigo.length > 0)
{
  const filtro = {
    where: {
      or: [
        {
          responsavelNegocialCodigo: {
            in: props.usuarioCodigo,
          },
        },
        {
          responsavelProcessualCodigo: {
            in: props.usuarioCodigo,
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else if (props.perfil && props.perfil.length > 0)
{
  const filtro = {
    where: {
      or: [
        {
          responsavelNegocial: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.perfil,
                },
              },
            },
          },
        },
        {
          responsavelProcessual: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.perfil,
                },
              },
            },
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else
{
  gqlVariables = { ...baseGqlVariables };
}
</script>

<template>
  <PastaGqlStatsCard title="PASTAS ATIVAS" :variables="gqlVariables" />
</template>
