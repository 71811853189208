query pessoaComEnderecoEmailSelect {
    data: pessoas(order: [{nome: ASC}]) {
        value: codigo
        label: nome
        pessoasEmails {
            codigo
            descricao
            ehPrincipal
            email {
                endereco
            }
        }
        pessoasEnderecos {
            codigo
            ehPrincipal
            descricao            
            endereco {
                codigo
                logradouro
                numero
                complemento
                bairro
                cep
                cidade {
                    codigo
                    nome
                }
                estado {
                    codigo
                    nome
                }
            }
        }
    }
}