<script setup>
import { useCancelarFluxo } from '~/composables/fluxos/useCancelarFluxo';
import { useObterFluxo } from '~/composables/fluxos/useObterFluxo';
import { usePost } from '~/composables/usePost';
import EnumPerfil from '~/enums/EnumPerfil';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { API_URL } from '~/utils';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref } from 'vue';

const props = defineProps({
  codigoFluxo: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['close']);

const {
  data: obterFluxoData,
  runAsync: obterFluxoAsync,
  status: obterFluxoStatus,
} = useObterFluxo();

const {
  err: cancelarFluxoErr,
  runAsync: cancelarFluxoAsync,
  status: cancelarFluxoStatus,
} = useCancelarFluxo();

const {
  err: falharFluxoErr,
  runAsync: falharFluxoAsync,
  status: falharFluxoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/fluxos/falhar-fluxo`,
});

const formModel = reactive({
  justificativaCancelamento: null,
  justificativaFalha: null,
});

const authStore = useAuthStore();
const drawerStore = useDrawerStore();
let unsubscribe = null;
const cancelarFluxoModalVisible = ref(false);
const falharFluxoModalVisible = ref(false);
const colunas = [
  {
    key: 'pasta',
    title: 'PASTA',
  },
  {
    key: 'numeroCnj',
    title: 'NÚMERO CNJ',
  },
  {
    key: 'responsavelProcessual',
    title: 'RESPONSÁVEL PROCESSUAL',
  },
  {
    key: 'responsavelNegocial',
    title: 'RESPONSÁVEL NEGOCIAL',
  },
  {
    key: 'contrario',
    title: 'CONTRÁRIO',
  },
];
const colunasNumeroProcesso = [
  {
    key: 'numeroProcesso',
    title: 'NÚMERO PROCESSO',
  },
];

const abrirCompromisso = (compromisso) => {
  drawerStore.push({
    componentName: 'CompromissoView',
    params: {
      compromisso,
      fluxo: obterFluxoData.fluxo,
      mostrarBotaoVerFluxo: false,
    },
  });
};

const abrirDelegarCompromisso = (compromisso) => {
  drawerStore.push({
    componentName: 'DelegarCompromissoForm',
    params: { compromissoCodigo: compromisso.codigo },
  });
};

const abrirAlterarVencimentoCompromissoDrawer = (compromisso) => {
  drawerStore.push({
    componentName: 'AlterarVencimentoCompromissoForm',
    params: { compromissoCodigo: compromisso.codigo },
  });
};

const textoDataConclusao = (compromisso) => {
  if (compromisso.status === 2) return 'Devolvido em';

  if (compromisso.status === 3) return 'Concluído em';

  if (compromisso.status === 4) return 'Cancelado em';

  if (compromisso.status === 6) return 'Não atendido em';

  return '';
};

const uiObterFluxoAsync = async () => {
  await obterFluxoAsync({
    codigoFluxo: props.codigoFluxo,
  });
};

const uiCancelarFluxoAsync = async () => {
  await cancelarFluxoAsync({
    fluxoCodigos: [props.codigoFluxo],
    justificativa: formModel.justificativaCancelamento,
  });

  cancelarFluxoModalVisible.value = false;
  formModel.justificativaCancelamento = null;

  if (cancelarFluxoStatus.value === 'error') {
    notification.error({
      duration: 5,
      message: cancelarFluxoErr.value.error,
    });
    return;
  }

  notification.success({
    duration: 5,
    message: 'Fluxo cancelado com sucesso',
  });

  await uiObterFluxoAsync();
};

const uiFalharFluxoAsync = async () => {
  await falharFluxoAsync({
    fluxoCodigos: [props.codigoFluxo],
    justificativa: formModel.justificativaFalha,
  });

  falharFluxoModalVisible.value = false;
  formModel.justificativaFalha = null;

  if (falharFluxoStatus.value === 'error') {
    notification.error({
      duration: 5,
      message: falharFluxoErr.value.error,
    });
    return;
  }

  notification.success({
    duration: 5,
    message: 'Fluxo definido como não atendido com sucesso',
  });

  await uiObterFluxoAsync();
};

onMounted(async () => {
  await uiObterFluxoAsync();
  unsubscribe = drawerStore.$subscribe(async (mutation, state) => {
    if (
      state.lastAction === 'close' &&
      drawerStore.currentComponent === 'FluxoView'
    ) {
      await uiObterFluxoAsync();
    }
  });
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>

<template>
  <div class="fluxo-view" style="padding-bottom: 24px">
    <DrawerTitle @close="() => $emit('close')">
      <template #title>
        <a-skeleton
          v-if="obterFluxoStatus === 'loading'"
          :loading="true"
          :paragraph="false"
          :active="true"
          :title="{ width: '75%' }"
        />
        <span
          v-if="obterFluxoStatus === 'success'"
          style="
            font-size: 16px;
            font-weight: 500;
            line-height: 35px;
            text-transform: uppercase;
          "
        >
          FLUXO - {{ obterFluxoData.fluxo.tipoText }}</span
        >
        <a-typography-text
          v-if="obterFluxoStatus === 'success'"
          :copyable="{
            text: obterFluxoData.fluxo.codigo,
          }"
        >
          <template #copyableTooltip="{ copied }">
            <span v-if="!copied" key="copy-tooltip">Copiar código</span>
            <span v-else key="copied-tooltip">Copiado!</span>
          </template>
        </a-typography-text>
        <a-button
          v-if="
            obterFluxoStatus === 'success' &&
            obterFluxoData.fluxo.status === 2 &&
            authStore.temPerfilAcesso(EnumPerfil.CancelarFluxo)
          "
          style="margin-left: 8px"
          @click="() => (cancelarFluxoModalVisible = true)"
        >
          Cancelar
        </a-button>

        <a-button
          v-if="
            obterFluxoStatus === 'success' &&
            obterFluxoData.fluxo.status === 2 &&
            obterFluxoData.fluxo.tipo === 34 &&
            authStore.temPerfilAcesso(EnumPerfil.FalharFluxo)
          "
          style="margin-left: 8px"
          @click="() => (falharFluxoModalVisible = true)"
        >
          Não atendido
        </a-button>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-skeleton
        v-if="obterFluxoStatus === 'loading'"
        :loading="true"
        :active="true"
        :paragraph="{ rows: 12 }"
      />
      <ErrorResult
        v-if="obterFluxoStatus === 'error'"
        :tentar-novamente-fn="uiObterFluxoAsync"
      />
      <div v-if="obterFluxoStatus === 'success'">
        <a-modal
          v-model:visible="cancelarFluxoModalVisible"
          title="Justifique o cancelamento do fluxo"
          cancel-text="Fechar"
          ok-text="Cancelar fluxo"
          :z-index="2000"
          :confirm-loading="cancelarFluxoStatus === 'loading'"
          :ok-button-props="{
            disabled: !formModel.justificativaCancelamento?.length,
          }"
          ok-type="danger"
          @ok="uiCancelarFluxoAsync"
          @cancel="() => (formModel.justificativaCancelamento = null)"
        >
          <a-textarea
            v-model:value="formModel.justificativaCancelamento"
            :rows="4"
            placeholder="Escreva sua justificativa para o cancelamento"
          />
        </a-modal>

        <a-modal
          v-model:visible="falharFluxoModalVisible"
          title="Justifique a falha do fluxo"
          cancel-text="Fechar"
          ok-text="Falhar fluxo"
          :z-index="2000"
          :confirm-loading="falharFluxoStatus === 'loading'"
          :ok-button-props="{
            disabled: !formModel.justificativaFalha?.length,
          }"
          ok-type="danger"
          @ok="uiFalharFluxoAsync"
          @cancel="() => (formModel.justificativaFalha = null)"
        >
          <a-textarea
            v-model:value="formModel.justificativaFalha"
            :rows="4"
            placeholder="Escreva sua justificativa para a falha"
          />
        </a-modal>

        <a-alert
          v-if="obterFluxoData.fluxo.status === 4"
          type="error"
          style="margin-bottom: 16px"
          show-icon
        >
          <template #message>
            FLUXO CANCELADO POR
            <b>{{ obterFluxoData.fluxo.canceladoPor.nome }}</b>
          </template>
          <template
            v-if="!isNilOrEmpty(obterFluxoData.fluxo.justificativaCancelamento)"
            #description
          >
            <span
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              "
            >
              {{ obterFluxoData.fluxo.justificativaCancelamento }}
            </span>
          </template>
        </a-alert>

        <a-alert
          v-if="obterFluxoData.fluxo.status === 5"
          type="error"
          style="margin-bottom: 16px"
          show-icon
        >
          <template #message>
            FLUXO DEFINIDO COMO NÃO ATENDIDO POR
            <b>{{ obterFluxoData.fluxo.falhadoPor.nome }}</b>
          </template>
          <template
            v-if="!isNilOrEmpty(obterFluxoData.fluxo.justificativaFalha)"
            #description
          >
            <span
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              "
            >
              {{ obterFluxoData.fluxo.justificativaFalha }}
            </span>
          </template>
        </a-alert>

        <a-table
          v-if="obterFluxoData.fluxo.pasta"
          :data-source="[obterFluxoData.fluxo.pasta]"
          :columns="colunas"
          :pagination="false"
          style="margin-bottom: 12px"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'pasta'">
              <a-tooltip placement="top">
                <template #title> Ir para página da pasta </template>
                <a :href="`/pasta/${record.codigo}`">
                  {{ record.nome }}
                </a>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'numeroCnj'">
              {{ formatCnj(record.numeroCnj) }}
            </template>
            <template v-if="column.key === 'responsavelProcessual'">
              {{ record.responsavelProcessual?.nome }}
            </template>
            <template v-if="column.key === 'responsavelNegocial'">
              {{ record.responsavelNegocial?.nome }}
            </template>
            <template v-if="column.key === 'contrario'">
              {{ record.contrarioPrincipal?.nome }}
            </template>
          </template>
        </a-table>

        <a-table
          v-if="
            !obterFluxoData.fluxo.pasta &&
            obterFluxoData.fluxo.publicacaoNumeroProcesso
          "
          :data-source="[obterFluxoData.fluxo.publicacaoNumeroProcesso]"
          :columns="colunasNumeroProcesso"
          :pagination="false"
          style="margin-bottom: 12px"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'numeroProcesso'">
              {{ formatCnj(record.numeroProcesso) }}
            </template>
          </template>
        </a-table>

        <h3>Histórico de compromissos do fluxo</h3>

        <a-card style="margin-top: 16px">
          <a-timeline
            :pending="obterFluxoData.fluxo.status === 2"
            :reverse="true"
          >
            <a-timeline-item style="padding-bottom: 0; height: 20px" />
            <a-timeline-item
              v-for="compromisso in obterFluxoData.fluxo.compromissos"
              :key="compromisso.codigo"
            >
              <template #dot>
                <a-tooltip>
                  <template #title>
                    {{ compromisso.statusText }}
                  </template>
                  <CheckCircleOutlined
                    v-if="compromisso.status === 3"
                    style="font-size: 16px; color: #52c41a"
                  />
                  <ExclamationCircleOutlined
                    v-if="compromisso.status === 2"
                    style="font-size: 16px; color: #df9a45"
                  />
                  <CloseCircleOutlined
                    v-if="compromisso.status === 4"
                    style="font-size: 16px; color: #df4c45"
                  />
                  <ClockCircleOutlined
                    v-if="compromisso.status === 1"
                    style="font-size: 16px"
                  />
                  <StopOutlined
                    v-if="compromisso.status === 6"
                    style="font-size: 16px; color: #df4c45"
                  />
                </a-tooltip>
              </template>
              <a-row>
                <a-col :span="24">
                  <a-tooltip>
                    <template #title> Ver detalhes do compromisso </template>
                    <span
                      ><a
                        href="#"
                        style="text-transform: uppercase"
                        @click.prevent="() => abrirCompromisso(compromisso)"
                        >{{ compromisso.titulo }}
                        {{ compromisso.status === 2 ? '(DEVOLVIDO)' : '' }}</a
                      ></span
                    >
                  </a-tooltip>
                  <a-typography-text
                    :copyable="{
                      text: compromisso.codigo,
                    }"
                  >
                    <template #copyableTooltip="{ copied }">
                      <span v-if="!copied" key="copy-tooltip"
                        >Copiar código</span
                      >
                      <span v-else key="copied-tooltip">Copiado!</span>
                    </template>
                  </a-typography-text>
                  <span v-if="compromisso.status === 1">
                    <a-button
                      v-if="
                        authStore.temPerfilAcesso(
                          PERFIL_ACESSO.DelegarCompromisso
                        )
                      "
                      size="small"
                      style="margin: 0 8px"
                      @click="() => abrirDelegarCompromisso(compromisso)"
                      >Delegar</a-button
                    >
                    <a-button
                      v-if="
                        authStore.temPerfilAcesso(
                          PERFIL_ACESSO.AlterarVencimentoCompromisso
                        )
                      "
                      size="small"
                      @click="
                        () =>
                          abrirAlterarVencimentoCompromissoDrawer(compromisso)
                      "
                      >Alterar vencimento</a-button
                    >
                  </span>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <span class="span-key">RESPONSÁVEL: </span>
                  <span class="span-value">{{
                    compromisso.responsavel.nome
                  }}</span>

                  <br />
                  <template v-if="compromisso.status === 2">
                    <span class="span-key">DEVOLVIDO POR: </span>
                    <span class="span-value">{{
                      compromisso.devolvidoPor.nome
                    }}</span>
                    <br />
                  </template>
                  <span class="span-key">Iniciado em: </span>
                  <span class="span-value">{{
                    compromisso.dataInicioText
                  }}</span>
                  <a-divider type="vertical" />
                  <span class="span-key">Vencimento em: </span>
                  <span class="span-value">{{
                    compromisso.dataVencimentoText
                  }}</span>
                  <br />
                  <template v-if="compromisso.status > 1">
                    <span class="span-key"
                      >{{ textoDataConclusao(compromisso) }}:
                    </span>
                    <span class="span-value">{{
                      compromisso.dataConclusaoText !== null
                        ? compromisso.dataConclusaoText
                        : '-'
                    }}</span>
                  </template>
                </a-col>
              </a-row>
            </a-timeline-item>
          </a-timeline>
        </a-card>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.fluxo-view {
  & .ant-skeleton-content .ant-skeleton-title {
    margin-top: 10px;
  }
}
</style>
