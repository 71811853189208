<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-alert
    message="GCPJ atualizado com sucesso"
    type="success"
    show-icon
  />
  <a-descriptions
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      label="Observação"
      :span="3"
    >
      {{ props.compromisso.data?.observacao }}
    </a-descriptions-item>
  </a-descriptions>
</template>
