<script setup>
import * as api from '~/apis/uploader';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';

const props = defineProps({
  beforeUpload: {
    default: () => true,
    type: Function,
  },
  bytesPerChunk: {
    default: () => 25600,
    type: Number,
  },
  cancellable: {
    default: false,
    type: Boolean,
  },
  chunkMerger: {
    default: api.merge,
    type: Function,
  },
  chunkSender: {
    default: api.upload,
    type: Function,
  },
  fileList: {
    default: () => [],
    type: Array,
  },
  multiple: {
    default: true,
    type: Boolean,
  },
  onlyOneFile: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits([
  'update:fileList',
  'start',
  'end',
  'cancel',
  'success',
]);

const files = ref([]);
const isUploading = ref(false);
const isCancelled = ref(false);

const cancelUpload = ref(false);

const toFileWithoutBytes = (fileWithBytes) => ({
  name: fileWithBytes.name,
  size: fileWithBytes.size,
  type: fileWithBytes.type,
  uid: fileWithBytes.uid,
});

const handleBeforeUpload = (file) => {
  if (!props.beforeUpload(toFileWithoutBytes(file))) return false;

  if (props.onlyOneFile) files.value = [file];
  else files.value.push(file);

  emit(
    'update:fileList',
    files.value.map((f) => toFileWithoutBytes(f))
  );

  return false;
};

const handleRemove = (file) => {
  files.value = files.value.filter((a) => a.uid !== file.uid);
  emit(
    'update:fileList',
    files.value.map((f) => toFileWithoutBytes(f))
  );
};

const totalChunksPerFile = (file) =>
  file.size % props.bytesPerChunk === 0
    ? file.size / props.bytesPerChunk
    : Math.floor(file.size / props.bytesPerChunk) + 1;

const totalChunks = ref(0);
const uploadedChunks = ref(0);
const progress = computed(() => {
  if (uploadedChunks.value === 0 || totalChunks.value === 0) return 0;

  return parseInt(((uploadedChunks.value - 1) / totalChunks.value) * 100, 10);
});

const uploadFile = async (file) => {
  const totalChunksFile = totalChunksPerFile(file);
  let inicioByte = 0;
  let fimByte = props.bytesPerChunk;
  let numeroChunk = 0;
  let chunk = null;

  while (numeroChunk < totalChunksFile) {
    if (cancelUpload.value) {
      return;
    }

    chunk = file.slice(inicioByte, fimByte);
    numeroChunk += 1;
    uploadedChunks.value += 1;

    await props.chunkSender(file, numeroChunk, chunk);

    inicioByte = fimByte;
    fimByte = inicioByte + props.bytesPerChunk;
  }

  if (cancelUpload.value) {
    return;
  }

  await props.chunkMerger(toFileWithoutBytes(file), totalChunksFile);
};

const upload = async () => {
  cancelUpload.value = false;

  if (files.value.length === 0) return;

  totalChunks.value = 0;
  uploadedChunks.value = 0;

  for (let i = 0; i < files.value.length; i += 1) {
    if (cancelUpload.value) {
      break;
    }
    totalChunks.value += totalChunksPerFile(files.value[i]);
  }

  emit('start');
  isUploading.value = true;

  for (let i = 0; i < files.value.length; i += 1) {
    if (cancelUpload.value) {
      break;
    }
    await uploadFile(files.value[i]);
  }

  uploadedChunks.value += 1;

  isUploading.value = false;
  if (cancelUpload.value) {
    emit('cancel');
    return;
  }

  emit('end');
  emit('success');
};

const cancel = () => {
  cancelUpload.value = true;
  isCancelled.value = true;
};

const clear = () => {
  files.value = [];
  uploadedChunks.value = 0;
  totalChunks.value = 0;
  emit('update:fileList', files.value);
};

defineExpose({ clear, upload });
</script>

<template>
  <div>
    <a-upload-dragger
      :file-list="files"
      :multiple="multiple"
      :before-upload="handleBeforeUpload"
      @remove="handleRemove"
    >
      <slot name="description">
        <p class="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p class="ant-upload-text">Clique aqui ou arraste os arquivos</p>
        <p class="ant-upload-hint">Envie os arquivos necessários</p>
      </slot>
    </a-upload-dragger>
    <a-row
      v-if="(isUploading || progress > 0) && !cancelUpload && files.length > 0"
      type="flex"
    >
      <a-col flex="auto"><a-progress :percent="progress" /></a-col>
      <a-col v-if="props.cancellable" flex="100px"
        ><a-button size="small" @click="cancel">Cancelar</a-button></a-col
      >
    </a-row>
  </div>
</template>
