<script setup>
import { useAuthStore } from '~/stores/auth';
import {
  deepIntParaTextoComDuasOpcoes,
  deepIntParaTextoComQuatroOpcoes,
  deepIntParaTextoComTresOpcoes,
  isNilOrEmpty,
} from '~/utils';
import dayjs from 'dayjs';
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '25%' }" bordered>
    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.nome)"
      label="Nome"
      :span="3"
    >
      {{ props.compromisso.data.nome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.pastaPrincipalAlterado)"
      label="Pasta principal"
      :span="3"
    >
      {{ props.compromisso.data.pastaPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.tipoText)"
      label="Tipo"
      :span="3"
    >
      {{ props.compromisso.data.tipoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.tipoProcessoText)"
      label="Tipo processo"
      :span="3"
    >
      {{ props.compromisso.data.tipoProcessoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.statusText)"
      label="Status"
      :span="3"
    >
      {{ props.compromisso.data.statusText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.empresaText)"
      label="Empresa"
      :span="3"
    >
      {{ props.compromisso.data.empresaText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.responsavelProcessualNome)"
      label="Responsável processual"
      :span="3"
    >
      {{ props.compromisso.data.responsavelProcessualNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.responsavelNegocialNome)"
      label="Responsável negocial"
      :span="3"
    >
      {{ props.compromisso.data.responsavelNegocialNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorEnvolvido)"
      label="Valor envolvido"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorEnvolvido) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataRecepcaoAlterado"
      label="Data remessa"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataRecepcao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.clientePrincipalNome)"
      label="Cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.clientePrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.clientePrincipalPapelText)"
      label="Papel cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.clientePrincipalPapelText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.contrarioPrincipalNome)"
      label="Contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.contrarioPrincipalPapelText)"
      label="Papel contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalPapelText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.gcpj)"
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.gcpjAtivo)"
      label="Status do GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpjAtivo ? 'ATIVO' : 'ENCERRADO' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.dejur)"
      label="Departamento jurídico"
      :span="3"
    >
      {{ props.compromisso.data.dejur }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.empresaBanco)"
      label="Empresa/Banco"
      :span="3"
    >
      {{ props.compromisso.data.empresaBanco }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.agenciaNome)"
      label="Agência"
      :span="3"
    >
      {{ props.compromisso.data.agenciaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.conta)"
      label="Conta"
      :span="3"
    >
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.contaFicticia)"
      label="Conta fictícia"
      :span="3"
    >
      {{ props.compromisso.data.contaFicticia }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.estadoNome)"
      label="Estado"
      :span="3"
    >
      {{ props.compromisso.data.estadoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.cidadeNome)"
      label="Cidade"
      :span="3"
    >
      {{ props.compromisso.data.cidadeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataFatoGeradorAlterado"
      label="Data fato gerador"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataFatoGerador)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.garantias)"
      label="Garantias"
      :span="3"
    >
      {{ props.compromisso.data.garantias }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.avalistas)"
      label="Avalistas"
      :span="3"
    >
      {{ props.compromisso.data.avalistas }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.advogadoBanco)"
      label="Advogado do banco"
      :span="3"
    >
      {{ props.compromisso.data.advogadoBanco }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorContrato)"
      label="Valor do contrato"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorContrato) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.informacaoSobreObito)"
      label="Informações sobre óbito"
      :span="3"
    >
      {{ props.compromisso.data.informacaoSobreObito }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataDeclaracaoIrrecuperabilidadeAlterado"
      label="Data de declaração de irrecuperabilidade"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataDeclaracaoIrrecuperabilidade)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataEncerramentoAlterado"
      label="Data de encerramento"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataEncerramento)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.motivoEncerramento)"
      label="Motivo encerramento"
      :span="3"
    >
      {{ props.compromisso.data.motivoEncerramento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.numeroCnj)"
      label="Número CNJ"
      :span="3"
    >
      {{ formatCnj(props.compromisso.data.numeroCnj) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.numeroAntigo)"
      label="Número antigo"
      :span="3"
    >
      {{ props.compromisso.data.numeroAntigo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorCausa)"
      label="Valor da causa"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorCausa) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.naturezaText)"
      label="Natureza"
      :span="3"
    >
      {{ props.compromisso.data.naturezaText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.procedimentoText)"
      label="Procedimento"
      :span="3"
    >
      {{ props.compromisso.data.procedimentoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.acaoNome)"
      label="Ação"
      :span="3"
    >
      {{ props.compromisso.data.acaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.orgaoNome)"
      label="Órgão"
      :span="3"
    >
      {{ props.compromisso.data.orgaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.justicaNome)"
      label="Justiça"
      :span="3"
    >
      {{ props.compromisso.data.justicaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.instanciaNome)"
      label="Instância"
      :span="3"
    >
      {{ props.compromisso.data.instanciaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.classeNome)"
      label="Classe"
      :span="3"
    >
      {{ props.compromisso.data.classeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.assuntoNome)"
      label="Assunto"
      :span="3"
    >
      {{ props.compromisso.data.assuntoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.vara)"
      label="Vara"
      :span="3"
    >
      {{ props.compromisso.data.vara }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.comarcaNome)"
      label="Comarca"
      :span="3"
    >
      {{ props.compromisso.data.comarcaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.atualizacaoValorCausa)"
      label="Atualização do valor da causa"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.atualizacaoValorCausa) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataAtualizacaoValorCausaAlterado"
      label="Data atualização valor da causa"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataAtualizacaoValorCausa)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.motivoNaoAjuizamentoText)"
      label="Motivo do não ajuizamento"
      :span="3"
    >
      {{ props.compromisso.data.motivoNaoAjuizamentoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataAjuizamento"
      label="Data de ajuizamento"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataAjuizamento)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.custasProtesto)"
      label="Custas protesto"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasProtesto) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.custasIniciais)"
      label="Custas iniciais"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasIniciais) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.custasOficialJustica)"
      label="Custas oficial de justiça"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasOficialJustica) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataHabilitacaoAlterado"
      label="Data de habilitação"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataHabilitacao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.procuracaoFls)"
      label="Procuração Fls"
      :span="3"
    >
      {{ props.compromisso.data.procuracaoFls }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        !isNil(
          props.compromisso.data
            .arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
        )
      "
      label="Arquivamento provisório"
      :span="3"
    >
      {{
        props.compromisso.data
          .arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataSuspensaoAusenciaBensPenhoraveis"
      label="Data suspensão por ausência de bens penhoráveis"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataSuspensaoAusenciaBensPenhoraveis)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataLiminarAlterado"
      label="Data de liminar"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataLiminar)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataCitacaoAlterado"
      label="Data de citação"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataCitacao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataConversao"
      label="Data solicitação conversão em execução"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataConversao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNilOrEmpty(props.compromisso.data.dataSolicitacaoConversao)"
      label="Data conversão em execução"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataSolicitacaoConversao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataSentencaAlterado"
      label="Data de sentença"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataSentenca)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataBaixaAlterado"
      label="Data de baixa"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataBaixa)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataResultadoAlterado"
      label="Data resultado"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataResultado)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.motivoResultado)"
      label="Motivo resultado"
      :span="3"
    >
      {{ props.compromisso.data.motivoResultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.resultado)"
      label="Resultado"
      :span="3"
    >
      {{ props.compromisso.data.resultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.revelia)"
      label="Revelia?"
      :span="3"
    >
      {{ props.compromisso.data.revelia ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataApreensaoAlterado"
      label="Data de apreensão"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataApreensao)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.ehAptoParaApreensao)"
      label="Apto para apreensão?"
      :span="3"
    >
      {{ props.compromisso.data.ehAptoParaApreensao ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.ehInviavel)"
      label="Inviável?"
      :span="3"
    >
      {{ props.compromisso.data.ehInviavel ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.placa)"
      label="Placa"
      :span="3"
    >
      {{ props.compromisso.data.placa }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.chassiRenavan)"
      label="Chassi/Renavan"
      :span="3"
    >
      {{ props.compromisso.data.chassiRenavan }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.teveAcordo)"
      label="Teve acordo?"
      :span="3"
    >
      {{ props.compromisso.data.teveAcordo ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.responsavelAcordoNome)"
      label="Responsável pelo acordo/apreensão"
      :span="3"
    >
      {{ props.compromisso.data.responsavelAcordoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.tipoAcordo)"
      label="Tipo de acordo"
      :span="3"
    >
      {{ props.compromisso.data.tipoAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataAcordoAlterado"
      label="Data de acordo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataAcordo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.quantidadeParcelas)"
      label="Quantidade de parcelas"
      :span="3"
    >
      {{ props.compromisso.data.quantidadeParcelas }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorTotalAcordo)"
      label="Valor total do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorTotalAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorEntradaAcordo)"
      label="Valor entrada do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorEntradaAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorFinanciadoAcordo)"
      label="Valor financiado do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorFinanciadoAcordo) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.taxaJurosAcordo)"
      label="Taxa de juros do acordo (A.M.)"
      :span="3"
    >
      {{ props.compromisso.data.taxaJurosAcordo }}%
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataVencimentoPrimeiraParcelaAcordoAlterado"
      label="Data vencimento primeira parcela acordo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataVencimentoPrimeiraParcelaAcordo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataVencimentoUltimaParcelaAcordoAlterado"
      label="Data vencimento última parcela acordo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataVencimentoUltimaParcelaAcordo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.acordoEstaSendoCumprido)"
      label="Acordo está sendo cumprido?"
      :span="3"
    >
      {{ props.compromisso.data.acordoEstaSendoCumprido ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataDescumprimentoAcordoAlterado"
      label="Data descumprimento acordo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataDescumprimentoAcordo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.historicoDescumprimento)"
      label="Histórico de descumprimento"
      :span="3"
    >
      {{ props.compromisso.data.historicoDescumprimento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.teveContabilizacaoAcordo)"
      label="Teve contabilização do acordo?"
      :span="3"
    >
      {{ props.compromisso.data.teveContabilizacaoAcordo ? 'Sim' : 'Não' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.volumetria)"
      label="Volumetria"
      :span="3"
    >
      {{ props.compromisso.data.volumetria }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.plano)"
      label="Plano"
      :span="3"
    >
      {{ props.compromisso.data.plano }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorParcelaContratoOriginal)"
      label="Valor da parcela do contrato inicial"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorParcelaContratoOriginal) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataVencimentoInicial"
      label="Data vencimento inicial"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataVencimentoInicial)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.motivoEmenda)"
      label="Motivo emenda"
      :span="3"
    >
      {{ props.compromisso.data.motivoEmenda }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.liminarDeferida)"
      label="Liminar deferida"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(props.compromisso.data.liminarDeferida)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataRespostaLiminar"
      label="Data resposta liminar"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataRespostaLiminar)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.motivoNaoDeferimentoLiminar)"
      label="Motivo não deferimento da liminar"
      :span="3"
    >
      {{ props.compromisso.data.motivoNaoDeferimentoLiminar }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.agravoInstrumento)"
      label="Agravo de instrumento"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(props.compromisso.data.agravoInstrumento)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.mandadoApreensaoExpedido)"
      label="Mandado de apreensão expedido"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(
          props.compromisso.data.mandadoApreensaoExpedido
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataMandadoApreensaoExpedido"
      label="Data mandado apreensão expedido"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataMandadoApreensaoExpedido)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.liberacaoVendaVeiculo)"
      label="Liberação venda do veículo"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(
          props.compromisso.data.liberacaoVendaVeiculo
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="props.compromisso.data.dataLiberacaoVendaVeiculo"
      label="Data liberação venda veículo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataLiberacaoVendaVeiculo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.acaoMandadoNegativo)"
      label="Ação mandando negativo"
      :span="3"
    >
      {{ props.compromisso.data.acaoMandadoNegativo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.bemLocalizado)"
      label="Bem localizado"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(props.compromisso.data.bemLocalizado)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.acaoBemNaoLocalizado)"
      label="Ação bem não localizado"
      :span="3"
    >
      {{ props.compromisso.data.acaoBemNaoLocalizado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.ultimoEvento)"
      label="Último evento"
      :span="3"
    >
      {{ props.compromisso.data.ultimoEvento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.deposito)"
      label="Depósito"
      :span="3"
    >
      {{ deepIntParaTextoComTresOpcoes(props.compromisso.data.deposito) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorDeposito)"
      label="Valor depósito"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorDeposito) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorLevantadoPeloBanco)"
      label="Valor levantado pelo banco"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorLevantadoPeloBanco) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorLevantadoPelaParte)"
      label="Valor levantado pela parte"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorLevantadoPelaParte) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.penhora)"
      label="Penhora"
      :span="3"
    >
      {{ deepIntParaTextoComTresOpcoes(props.compromisso.data.penhora) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.tipoPenhora)"
      label="Tipo penhora"
      :span="3"
    >
      {{ props.compromisso.data.tipoPenhora }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isNil(props.compromisso.data.valorPenhora)"
      label="Valor penhora"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorPenhora) }}
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
  <div
    v-if="props.compromisso.data.carteirasContratos?.length > 0"
    style="margin-top: 24px"
  >
    <CarteiraContratoManager
      :value="props.compromisso.data.carteirasContratos"
      :editable="false"
    />
  </div>
</template>
