<script setup>
import { ref } from 'vue';

const props = defineProps({
  componentName: {
    type: String,
    required: true,
  },
  pasta: {
    type: Object,
    default: null,
  },
  codigosPastas: {
    type: Array,
    default: () => null,
  },
  title: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);

const isLoaded = ref(false);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          {{ title }}
        </span>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <component
        :is="componentName"
        :pasta="props.pasta"
        :codigos-pastas="props.codigosPastas"
        @loaded="() => (isLoaded = true)"
        @close="() => emit('close')"
      />
    </div>
  </div>
</template>
