<script setup>
import { onMounted, onUnmounted, toRaw } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { useDrawerStore } from '~/stores/drawer';
import { API_URL } from '~/utils';

const emit = defineEmits(['close']);

async function buscar(cmd) {
  const response = await axios.post(`${API_URL}/pessoas/buscar`, cmd, {
    getAccessToken: true,
    timeout: 120000,
  });
  return response.data;
}

const useStore = defineStore('selecionar-pessoa', {
  state: () => ({
    error: null,
    buscarStatus: null,
    contatos: [],
    pagina: 1,
    totalPorPagina: 10,
    texto: null,
    total: 0,
  }),
  actions: {
    async buscar() {
      this.buscarStatus = 'loading';
      try {
        const response = await buscar({
          pagina: this.pagina,
          totalPorPagina: this.totalPorPagina,
          texto: this.texto,
        });
        this.contatos = response.pessoas;
        this.total = response.total;
        this.buscarStatus = 'success';
      } catch (e) {
        this.buscarStatus = 'error';
      }
    },
  },
});

const $drawer = useDrawerStore();
const $s = useStore();
let drawerIndex = null;
let unsubscribe = null;

const colunasTabela = [
  {
    title: 'NOME/RAZÃO SOCIAL',
    dataIndex: 'nome',
    key: 'nome',
    slots: {
      customRender: 'nome',
    },
  },
  {
    title: 'CPF/CNPJ',
    dataIndex: 'documento',
    key: 'documento',
    slots: {
      customRender: 'documento',
    },
  },
];

const selecionar = (pessoa) => {
  $drawer.drawerReturn = toRaw(pessoa);
  emit('close');
};

const abrirSalvarPessoaFisica = () => {
  drawerIndex = $drawer.push({
    componentName: 'SalvarPessoaFisicaForm',
    params: {},
  });
};

const abrirSalvarPessoaJuridica = () => {
  drawerIndex = $drawer.push({
    componentName: 'SalvarPessoaJuridicaForm',
    params: {},
  });
};

const onNovoClick = ({ key }) => {
  unsubscribe = $drawer.$subscribe(async (mutation, state) => {
    if (state.activeDrawer + 1 === drawerIndex) {
      await $s.buscar();
      if (unsubscribe) {
        unsubscribe();
      }
    }
  });

  if (key === 'pessoa-fisica') abrirSalvarPessoaFisica();

  if (key === 'pessoa-juridica') abrirSalvarPessoaJuridica();
};

const onInputSearch = async () => {
  $s.pagina = 1;
  await $s.buscar();
};

onMounted(async () => {
  await $s.buscar({ pagina: 1 });
});

onUnmounted(() => {
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <a-row justify="center">
    <a-col :xs="24" :md="24" :lg="24" :xl="24">
      <DrawerTitle @close="() => $emit('close')">
        <template #title>
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            SELECIONE UM CONTATO OU</span
          >
          <a-dropdown>
            <a-button style="margin-left: 8px">
              Crie um novo <DownOutlined />
            </a-button>

            <template #overlay>
              <a-menu @click="onNovoClick">
                <a-menu-item key="pessoa-fisica"> Pessoa física </a-menu-item>
                <a-menu-item key="pessoa-juridica">
                  Pessoa jurídica
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </DrawerTitle>

      <div style="margin: 0 24px">
        <a-spin :spinning="$s.buscarStatus === 'loading'">
          <a-input-search
            v-model:value="$s.texto"
            enter-button
            placeholder="Buscar contatos por nome"
            size="large"
            style="margin-bottom: 24px"
            @search="onInputSearch"
          />
          <a-table
            :columns="colunasTabela"
            :data-source="$s.contatos"
            row-key="codigo"
            :pagination="false"
          >
            <template #footer>
              <span
                style="
                  color: #9a9a9a;
                  font-size: 12px;
                  letter-spacing: 2px;
                  text-transform: uppercase;
                "
                >Resultados: {{ $s.total }}
              </span>
            </template>
            <template #nome="{ record }">
              <a
                style="text-transform: uppercase"
                @click.prevent="() => selecionar(record)"
                >{{ record.nome }}</a
              >
            </template>

            <template #documento="{ record }">
              {{ formatCpfOuCnpj(record.documento) ?? '-' }}
            </template>
          </a-table>
          <a-row style="margin: 16px 0; padding: 0">
            <a-col span="24" style="text-align: right">
              <a-pagination
                v-model:current="$s.pagina"
                v-model:page-size="$s.totalPorPagina"
                :show-size-changer="true"
                :page-size-options="['10', '20', '30', '40', '50']"
                :total="$s.total"
                @show-size-change="
                  () => {
                    $s.pagina = 1;
                  }
                "
                @change="() => $s.buscar()"
              />
            </a-col>
          </a-row>
        </a-spin>
      </div>
    </a-col>
  </a-row>
</template>
