<script setup>
const props = defineProps({
  texto: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['close']);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          PUBLICAÇÃO
        </span>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-typography-paragraph>
        <pre>{{ props.texto }}</pre>
      </a-typography-paragraph>
    </div>
  </div>
</template>
