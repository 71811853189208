import { usePost } from '../usePost';
import { API_URL } from '~/utils';

export function useDelegarCompromisso() {
  return usePost({
    url: `${API_URL}/compromissos/delegar-compromisso`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}

export function useDelegarCompromissoEmLote() {
  return usePost({
    url: `${API_URL}/compromissos/delegar-compromisso/lote`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
