<script setup lang="ts">
import dayjs from 'dayjs';
import { useAuthStore } from '~/stores/auth';
import { mergeWithArrayConcat } from '~/utils';

interface Props
{
  usuarioCodigo?: string[]
  perfil?: number[]
}

const props = defineProps<Props>();
const authStore = useAuthStore();

let gqlVariables: any = {};

const dataVencimento = dayjs().format('DD/MM/YYYY');

const dataMovimentoInicial = dayjs(`${dataVencimento} 00:00:00`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();
const dataMovimentoFinal = dayjs(`${dataVencimento} 23:59:59`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();

const baseGqlVariables = {
  where: {
    and: [
      {
        publicacao: {
          dataMovimento: {
            gte: dataMovimentoInicial,
          },
        },
      },
      {
        publicacao: {
          dataMovimento: {
            lte: dataMovimentoFinal,
          },
        },
      },
    ],
  },
};
if (props.usuarioCodigo && props.usuarioCodigo.length > 0)
{
  const filtro = {
    where: {
      and: [
        {
          pastasPublicacoesNumerosProcessos: {
            some: {
              pasta: {
                or: [
                  {
                    responsavelNegocialCodigo: {
                      in: props.usuarioCodigo,
                    },
                  },
                  {
                    responsavelProcessualCodigo: {
                      in: props.usuarioCodigo,
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else if (props.perfil && props.perfil.length > 0)
{
  const filtro = {
    where: {
      and: [
        {
          pastasPublicacoesNumerosProcessos: {
            some: {
              pasta: {
                or: [
                  {
                    responsavelNegocial: {
                      perfisAcesso: {
                        some: {
                          perfil: {
                            in: props.perfil,
                          },
                        },
                      },
                    },
                  },
                  {
                    responsavelProcessual: {
                      perfisAcesso: {
                        some: {
                          perfil: {
                            in: props.perfil,
                          },
                        },
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else
{
  gqlVariables = { ...baseGqlVariables };
}
</script>

<template>
  <PublicacaoNumeroProcessoGqlStatsCard title="PUBLICAÇÕES HOJE" :variables="gqlVariables" />
</template>
