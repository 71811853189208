<script setup>
import { useCancelarFluxo } from '~/composables/fluxos/useCancelarFluxo';
import { useObterFluxos } from '~/composables/fluxos/useObterFluxos';
import EnumPerfil from '~/enums/EnumPerfil';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { isNilOrEmpty } from '~/utils';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref } from 'vue';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  codigoPublicacaoNumeroProcesso: {
    default: null,
    type: String,
  },
  tiposParaNaoMostrar: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['close']);

const {
  data: obterFluxosData,
  runAsync: obterFluxosAsync,
  status: obterFluxosStatus,
} = useObterFluxos();

const {
  err: cancelarFluxoErr,
  runAsync: cancelarFluxoAsync,
  status: cancelarFluxoStatus,
} = useCancelarFluxo();

const formModel = reactive({
  codigoFluxoParaCancelar: null,
  justificativaCancelamento: null,
});
const $drawer = useDrawerStore();
const authStore = useAuthStore();
const cancelarFluxoModalVisible = ref(false);
const colunas = [
  {
    key: 'pasta',
    title: 'PASTA',
  },
  {
    key: 'numeroCnj',
    title: 'NÚMERO CNJ',
  },
  {
    key: 'responsavelProcessual',
    title: 'RESPONSÁVEL PROCESSUAL',
  },
  {
    key: 'responsavelNegocial',
    title: 'RESPONSÁVEL NEGOCIAL',
  },
  {
    key: 'contrario',
    title: 'CONTRÁRIO',
  },
];
let unsubscribe = null;

const abrirFluxoDrawer = (codigo) => {
  $drawer.push({
    componentName: 'FluxoView',
    params: { codigoFluxo: codigo },
  });
};

const abrirCancelarFluxoModal = (codigoFluxo) => {
  formModel.codigoFluxoParaCancelar = codigoFluxo;
  cancelarFluxoModalVisible.value = true;
};

const uiObterFluxosAsync = async () => {
  await obterFluxosAsync({
    codigoPasta: props.codigoPasta,
    codigoPublicacaoNumeroProcesso: props.codigoPublicacaoNumeroProcesso,
  });
};

const uiCancelarFluxoAsync = async () => {
  await cancelarFluxoAsync({
    fluxoCodigos: [formModel.codigoFluxoParaCancelar],
    justificativa: formModel.justificativaCancelamento,
  });

  cancelarFluxoModalVisible.value = false;
  formModel.justificativaCancelamento = null;

  if (cancelarFluxoStatus.value === 'error') {
    notification.error({
      duration: 5,
      message: cancelarFluxoErr.value.error,
    });
    return;
  }

  notification.success({
    duration: 5,
    message: 'Fluxo cancelado com sucesso',
  });

  await uiObterFluxosAsync();
};

onMounted(async () => {
  await uiObterFluxosAsync();

  unsubscribe = $drawer.$subscribe(async (mutation, state) => {
    if (
      state.lastAction === 'close' &&
      $drawer.currentComponent === 'FluxosView'
    ) {
      await uiObterFluxosAsync();
    }
  });
});

onUnmounted(() => {
  if (unsubscribe) {
    unsubscribe();
  }
});
</script>

<template>
  <div class="fluxos-view" style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          v-if="obterFluxosStatus === 'loading'"
          :loading="true"
          :paragraph="false"
          :active="true"
          :title="{ width: '75%' }"
        />
        <span
          v-if="obterFluxosStatus === 'success'"
          style="font-size: 16px; font-weight: 500; line-height: 35px"
        >
          FLUXOS</span
        >
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-skeleton
        v-if="obterFluxosStatus === 'loading'"
        :loading="true"
        :active="true"
        :paragraph="{ rows: 12 }"
      />
      <ErrorResult
        v-else-if="obterFluxosStatus === 'error'"
        :tentar-novamente-fn="uiObterFluxosAsync"
      />
      <div v-else-if="obterFluxosStatus === 'success'">
        <a-modal
          v-model:visible="cancelarFluxoModalVisible"
          title="Justifique o cancelamento do fluxo"
          cancel-text="Fechar"
          ok-text="Cancelar fluxo"
          :z-index="2000"
          :confirm-loading="cancelarFluxoStatus === 'loading'"
          :ok-button-props="{
            disabled: !formModel.justificativaCancelamento?.length,
          }"
          ok-type="danger"
          @ok="uiCancelarFluxoAsync"
          @cancel="() => (formModel.justificativaCancelamento = null)"
        >
          <a-textarea
            v-model:value="formModel.justificativaCancelamento"
            :rows="4"
            placeholder="Escreva sua justificativa para o cancelamento"
          />
        </a-modal>

        <a-table
          v-if="obterFluxosData?.pasta"
          :data-source="[obterFluxosData?.pasta]"
          :columns="colunas"
          :pagination="false"
          style="margin-bottom: 12px"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'pasta'">
              <a-tooltip placement="top">
                <template #title> Ir para página da pasta </template>
                <a :href="`/pasta/${record.codigo}`">
                  {{ record.nome }}
                </a>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'numeroCnj'">
              {{ formatCnj(record.numeroCnj) }}
            </template>
            <template v-if="column.key === 'responsavelProcessual'">
              {{ record.responsavelProcessual?.nome }}
            </template>
            <template v-if="column.key === 'responsavelNegocial'">
              {{ record.responsavelNegocial?.nome }}
            </template>
            <template v-if="column.key === 'contrario'">
              {{ record.contrarioPrincipal?.nome }}
            </template>
          </template>
        </a-table>

        <a-descriptions
          v-if="obterFluxosData?.publicacaoNumeroProcesso"
          :column="3"
          :label-style="{ width: '20%' }"
          bordered
          style="margin-bottom: 12px"
        >
          <a-descriptions-item label="Código" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.codigo }}
            <a-typography-text
              :copyable="{
                text: obterFluxosData.publicacaoNumeroProcesso.codigo,
              }"
            >
              <template #copyableTooltip="{ copied }">
                <span v-if="!copied" key="copy-tooltip">Copiar</span>
                <span v-else key="copied-tooltip">Copiado!</span>
              </template>
            </a-typography-text>
          </a-descriptions-item>
          <a-descriptions-item label="Número do processo" :span="3">
            {{
              formatCnj(obterFluxosData.publicacaoNumeroProcesso.numeroProcesso)
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Diário" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.diario }}
          </a-descriptions-item>
          <a-descriptions-item label="Caderno" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.caderno }}
          </a-descriptions-item>
          <a-descriptions-item label="Seção" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.secao }}
          </a-descriptions-item>
          <a-descriptions-item label="Tipo" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.tipo }}
          </a-descriptions-item>
          <a-descriptions-item label="Página" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.pagina }}
          </a-descriptions-item>
          <a-descriptions-item label="Data da disponibilização" :span="3">
            {{
              obterFluxosData.publicacaoNumeroProcesso.publicacao
                .dataMovimentoText
            }}
          </a-descriptions-item>
          <a-descriptions-item label="Conteúdo" :span="3">
            {{ obterFluxosData.publicacaoNumeroProcesso.publicacao.conteudo }}
          </a-descriptions-item>
        </a-descriptions>

        <h3>Histórico de fluxos</h3>

        <a-card style="margin-top: 16px">
          <Empty
            v-if="obterFluxosData.fluxos.length === 0"
            description="Nenhum fluxo encontrado"
          />
          <a-row v-else justify="center">
            <a-col :span="24">
              <a-timeline :reverse="true" style="margin-top: 16px">
                <a-timeline-item style="padding: 0; height: 20px">
                  <br />
                </a-timeline-item>
                <a-timeline-item
                  v-for="(fluxo, index) in obterFluxosData.fluxos.filter(
                    (x) => !tiposParaNaoMostrar.includes(x.tipo)
                  )"
                  :key="fluxo.codigo"
                >
                  <template #dot>
                    <a-tooltip>
                      <template #title>
                        {{ fluxo.statusText }}
                      </template>
                      <ClockCircleOutlined
                        v-if="fluxo.status === 2"
                        style="font-size: 16px"
                      />
                      <CheckCircleOutlined
                        v-if="fluxo.status === 3"
                        style="font-size: 16px; color: #52c41a"
                      />
                      <CloseCircleOutlined
                        v-if="fluxo.status === 4"
                        style="font-size: 16px; color: #df4c45"
                      />
                      <StopOutlined
                        v-if="fluxo.status === 5"
                        style="font-size: 16px; color: #df4c45"
                      />
                    </a-tooltip>
                  </template>
                  <a-row>
                    <a-col :span="24">
                      <a-tooltip>
                        <template #title> Ver compromissos do fluxo </template>
                        <span>
                          <a
                            href="#"
                            :style="
                              fluxo.status === 4
                                ? {
                                    textDecorationLine: 'line-through',
                                    color: 'grey',
                                    textTransform: 'uppercase',
                                  }
                                : { textTransform: 'uppercase' }
                            "
                            @click.prevent="
                              () => abrirFluxoDrawer(fluxo.codigo)
                            "
                            >{{ fluxo.tipoText }}
                          </a>

                          <span style="font-weight: 500; color: #858383">
                            ({{ index + 1 }})</span
                          >

                          <span v-if="fluxo.status === 4">
                            (CANCELADO POR: <b>{{ fluxo.canceladoPor.nome }}</b
                            >)
                          </span>
                          <span v-if="fluxo.status === 5">
                            (DEFINIDO COMO NÃO ATENDIDO POR:
                            <b>{{ fluxo.falhadoPor.nome }}</b
                            >)
                          </span>
                        </span>
                      </a-tooltip>
                      <span v-if="fluxo.status === 2">
                        <a-button
                          v-if="
                            authStore.temPerfilAcesso(EnumPerfil.CancelarFluxo)
                          "
                          size="small"
                          style="margin: 0 8px"
                          @click="() => abrirCancelarFluxoModal(fluxo.codigo)"
                          >Cancelar</a-button
                        >
                      </span>
                      <a-alert
                        v-if="
                          fluxo.status === 4 &&
                          !isNilOrEmpty(fluxo.justificativaCancelamento)
                        "
                        type="error"
                        style="margin: 8px 0"
                      >
                        <template #message>
                          <span
                            style="
                              word-wrap: break-word;
                              white-space: pre-wrap;
                              word-break: break-word;
                            "
                            >{{ fluxo.justificativaCancelamento }}</span
                          >
                        </template>
                      </a-alert>
                      <br v-else />
                      <span class="span-key">INICIADO POR: </span>
                      <span class="span-value">{{
                        fluxo.iniciadoPor.nome
                      }}</span>
                      <br />
                      <span class="span-key">Iniciado em: </span>
                      <span class="span-value">{{ fluxo.dataInicioText }}</span>
                      <div v-if="fluxo.status > 2">
                        <span class="span-key"
                          >{{ fluxo.statusText }} em:
                        </span>
                        <span
                          v-if="fluxo.dataConclusaoText !== null"
                          class="span-value"
                          >{{ fluxo.dataConclusaoText }}</span
                        >
                        <span v-else class="span-value">-</span>
                      </div>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24" />
                  </a-row>
                </a-timeline-item>
              </a-timeline>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.fluxos-view {
  & .ant-skeleton-content .ant-skeleton-title {
    margin-top: 10px;
  }
}
</style>
