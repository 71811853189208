<script setup lang="ts">
import dayjs from 'dayjs';
import { useAuthStore } from '~/stores/auth';
import { mergeWithArrayConcat } from '~/utils';

interface Props
{
  usuarioCodigo?: string[]
  perfil?: number[]
}

const props = defineProps<Props>();
const authStore = useAuthStore();

let gqlVariables: any = {};

const inicioMes = dayjs().startOf('month').format('DD/MM/YYYY');
const finalMes = dayjs().endOf('month').format('DD/MM/YYYY');

const dataEncerramentoInicial = dayjs(`${inicioMes} 00:00:00`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();
const dataEncerramentoFinal = dayjs(`${finalMes} 23:59:59`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();

const baseGqlVariables = {
  where: {
    and: [
      {
        dataEncerramento: {
          gte: dataEncerramentoInicial,
        },
      },
      {
        dataEncerramento: {
          lte: dataEncerramentoFinal,
        },
      },
      {
        pastaPrincipalCodigo: { eq: null },
      },
    ],
  },
};

if (props.usuarioCodigo && props.usuarioCodigo.length > 0)
{
  const filtro = {
    where: {
      or: [
        {
          responsavelNegocialCodigo: {
            in: props.usuarioCodigo,
          },
        },
        {
          responsavelProcessualCodigo: {
            in: props.usuarioCodigo,
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else if (props.perfil && props.perfil.length > 0)
{
  const filtro = {
    where: {
      or: [
        {
          responsavelNegocial: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.perfil,
                },
              },
            },
          },
        },
        {
          responsavelProcessual: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.perfil,
                },
              },
            },
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else
{
  gqlVariables = { ...baseGqlVariables };
}
</script>

<template>
  <PastaGqlStatsCard title="PASTAS ENCERRADAS NO MÊS" :variables="gqlVariables" />
</template>
