<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty } from '~/utils';

interface Props
{
  compromisso?: any
  pastaCodigos?: string[]
}

const props = defineProps<Props>();
const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const authStore = useAuthStore();

// const nenhumArquivoAnexado = ref<boolean>(false);
// const temProblemaEmDocumentos = ref<boolean>(false);

onMounted(() =>
{
  emit('loaded');
});

// function adicionarDocumento(model: any)
// {
//   nenhumArquivoAnexado.value = false;
//   temProblemaEmDocumentos.value = false;

//   if (!model.arquivosForm)
//   {
//     model.arquivosForm = [];
//   }

//   model.arquivosForm.push({ ref: null, tipo: null, arquivo: null });
// }

// function removerDocumento(model: any, index: number)
// {
//   nenhumArquivoAnexado.value = false;
//   temProblemaEmDocumentos.value = false;

//   model.arquivosForm.splice(index, 1);
// }

// function addUploadComponentRef(model: any, el: any, index: number)
// {
//   if (model.arquivosForm.length > index)
//   {
//     model.arquivosForm[index].ref = el;
//   }
// }

const uploadFilesGcpj = ref<any>(null);

async function preSubmit(model: any)
{
  // nenhumArquivoAnexado.value = false;
  // temProblemaEmDocumentos.value = false;

  // const arquivos = model.arquivosForm ?? [];

  // if (arquivos.length === 0)
  // {
  //   nenhumArquivoAnexado.value = true;
  //   return false;
  // }

  // if (arquivos
  //   .some((x: any) => x.tipo === null || x.arquivo === null))
  // {
  //   temProblemaEmDocumentos.value = true;
  //   return false;
  // }

  // for (let i = 0; i < arquivos.length; i++)
  // {
  //   const tipo = arquivos[i].tipo;
  //   const arquivo = arquivos[i].arquivo;
  //   const uref = arquivos[i].ref;

  //   if (uref !== null && tipo !== null && arquivo !== null)
  //   {
  //     await uref.upload();
  //   }
  // }

  // model.arquivos = arquivos
  //   .filter((x: any) => x.tipo !== null && x.arquivo !== null)
  //   .map((x: any) => ({
  //     ...x.arquivo,
  //     ...{
  //       attributes: {
  //         referenciaGcpjCodigo: x.tipo.value,
  //         referenciaGcpjNome: x.tipo.label,
  //       },
  //     },
  //   }));

  const uploadResult = await uploadFilesGcpj.value.upload();

  if (!uploadResult.ok)
  {
    return false;
  }

  model.arquivos = uploadResult.value;

  return true;
}
</script>

<template>
  <div>
    <CompromissoCommandForm2
      command="AnexacaoDocumentosAoGcpj.AnexarDocumentosAoGcpjCommand"
      :show-upload-files="false"
      :submit-button-disabled="!isNilOrEmpty(compromisso)
        && compromisso.responsavel.codigo !== authStore.codigoUsuario"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
        compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : [],
        arquivosForm: [],
      }"
      :pre-submit="preSubmit"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <UploadFilesGcpj
          ref="uploadFilesGcpj"
          v-model="model.arquivosForm"
          :required="true"
        />
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
