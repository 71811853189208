<script setup>
import { onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { useGraphQL } from '~/composables/useGraphQL';
import { API_URL } from '~/utils';
import { usePost } from '~/composables/usePost';

const props = defineProps({
  codigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);

const {
  status: agenciaQueryStatus,
  data: agenciaQueryData,
  runAsync: agenciaQueryAsync,
} = useGraphQL({
  query: 'AgenciaQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const apiUrl = props.codigo === null
  ? `${API_URL}/pastas/criar-agencia`
  : `${API_URL}/pastas/editar-agencia`;

const {
  status: salvarAgenciaStatus,
  runAsync: salvarAgenciaAsync,
  err: salvarAgenciaErr,
} = usePost({
  url: apiUrl,
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const formRef = ref(null);
const state = reactive({
  status: 'loading',
  numero: null,
  nome: null,
  porte: null,
  regional: null,
  gerenciaRegional: null,
  estado: null,
});

const formRules = reactive({
  numero: [
    {
      required: true,
      message: 'Número é obrigatório',
    },
  ],
  nome: [
    {
      required: true,
      message: 'Nome é obrigatório',
    },
  ],
});

const uiAgenciaQueryAsync = async () => {
  state.status = 'loading';

  await agenciaQueryAsync({
    codigo: props.codigo,
  });

  if (agenciaQueryStatus.value === 'success') {
    const agencia = agenciaQueryData.value.agencia;
    state.numero = agencia.numero;
    state.nome = agencia.nome;
    state.regional =
      agencia.regionalValue !== null
        ? {
            label: agencia.regionalText,
            value: agencia.regionalValue,
          }
        : null;
    state.porte =
      agencia.porteValue !== null
        ? {
            label: agencia.porteText,
            value: agencia.porteValue,
          }
        : null;
    state.gerenciaRegional =
      agencia.gerenciaRegional !== null
        ? {
            label: agencia.gerenciaRegional.nome,
            value: agencia.gerenciaRegional.codigo,
          }
        : null;
    state.estado =
      agencia.estado !== null
        ? {
            label: agencia.estado.nome.toUpperCase(),
            value: agencia.estado.codigo,
          }
        : null;
  }

  state.status = agenciaQueryStatus.value;
};

const onSalvarClickAsync = async () => {
  formRef.value.validate().then(async () => {
    const cmd = {
      codigo: props.codigo,
      numero: state.numero,
      nome: state.nome,
      regional: state.regional?.value,
      porte: state.porte?.value,
      gerenciaRegionalCodigo: state.gerenciaRegional?.value ?? null,
      estadoCodigo: state.estado?.value ?? null,
    };

    await salvarAgenciaAsync(cmd);

    if (salvarAgenciaStatus.value === 'error') {
      notification.error({
        message: salvarAgenciaErr.value.message,
        description: salvarAgenciaErr.value.description,
        duration: 5,
      });
    }
  });
};

onMounted(async () => {
  if (props.codigo) {
    await uiAgenciaQueryAsync();
  } else {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          {{ props.codigo === null ? 'ADICIONAR AGÊNCIA' : `EDITAR AGÊNCIA` }}
        </span>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <ErrorResult v-else-if="state.status === 'error'" />
      <div v-else>
        <a-result
          v-if="salvarAgenciaStatus === 'success'"
          status="success"
          title="Agência salva com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => $emit('close')"> Fechar </a-button>
          </template>
        </a-result>
        <div v-else>
          <h3 style="margin-bottom: 24px">Informe os dados da agência</h3>
          <a-form
            ref="formRef"
            layout="vertical"
            :model="state"
            :rules="formRules"
          >
            <a-form-item label="Número" name="numero">
              <a-input v-model:value="state.numero" />
            </a-form-item>

            <a-form-item label="Nome" name="nome">
              <a-input v-model:value="state.nome" />
            </a-form-item>

            <a-form-item label="Regional">
              <GraphQlSelect
                v-model:value="state.regional"
                :variables="{
                  name: 'TipoRegionalAgencia',
                }"
                query="EnumAsSelectItem"
                placeholder="Selecione a regional"
              />
            </a-form-item>

            <a-form-item label="Porte">
              <GraphQlSelect
                v-model:value="state.porte"
                :variables="{
                  name: 'TipoPorteAgencia',
                }"
                query="EnumAsSelectItem"
                placeholder="Selecione o porte"
              />
            </a-form-item>

            <a-form-item label="Gerência">
              <GraphQlSelect
                v-model:value="state.gerenciaRegional"
                query="GerenciasRegionaisAsSelectItemQuery"
                :variables="{
                  order: [
                    {
                      nome: 'ASC',
                    },
                  ],
                }"
                placeholder="Selecione a gerência"
              />
            </a-form-item>

            <a-form-item label="Estados">
              <GraphQlSelect
                v-model:value="state.estado"
                query="ESTADOS_SELECT"
                :variables="{
                  order: [
                    {
                      nome: 'ASC',
                    },
                  ],
                }"
                placeholder="Selecione o estado"
              />
            </a-form-item>

            <a-form-item style="margin-top: 24px">
              <a-button
                :loading="salvarAgenciaStatus === 'loading'"
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click="onSalvarClickAsync"
              >
                Salvar
              </a-button>
              <a-button @click="() => emit('close')"> Fechar </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
