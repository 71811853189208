<script setup>
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { usePost } from '~/composables/usePost';
import EnumTipoCompromisso from '~/enums/EnumTipoCompromisso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { API_URL, desabilitarDatasPraTras } from '~/utils';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { onMounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: cumprirErr,
  runAsync: cumprirAsync,
  status: cumprirStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const {
  err: devolverErr,
  runAsync: devolverAsync,
  status: devolverStatus,
} = useDevolverCompromisso();

const drawerStore = useDrawerStore();
const $auth = useAuthStore();
const devolverModalVisible = ref(false);
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  justificativaDevolucao: null,
  justificativaParaNaoCumprimento: null,
  novoVencimento: null,
  observacao: null,
  seraCumprido: null,
});
const formRules = reactive({
  seraCumprido: [
    {
      message: 'É necessário informar se o compromisso será cumprido',
      required: true,
      type: 'boolean',
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);
const tiposCompromissosQueTemAtalhos = ref([
  EnumTipoCompromisso.CaqGcpjDesatualizado,
]);

const uiSolicitarAtualizacaoGcpj = () => {
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarAtualizacaoGcpjForm',
      componentProps: {
        encaminharParaEquipeGcpj: true,
        pastaCodigos: [props.compromisso.fluxo.pastaCodigo],
      },
      title: 'SOLICITAR ATUALIZAÇÃO GCPJ',
    },
  });
};

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    await uploaderRef.value.upload();
    await cumprirAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      justificativaParaNaoCumprimento:
        formModel.justificativaParaNaoCumprimento,
      novoVencimento: formModel.novoVencimento,
      observacao: formModel.observacao,
      seraCumprido: formModel.seraCumprido,
    });

    if (cumprirStatus.value === 'error') {
      notification.error({
        description: cumprirErr.value.description,
        duration: 5,
        message: cumprirErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
};

const uiDevolverAsync = async () => {
  await devolverAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverStatus.value === 'error') {
    notification.error({
      description: devolverErr.value.description,
      duration: 5,
      message: devolverErr.value.message,
    });
    emit('error');
    return;
  }

  devolverModalVisible.value = false;

  emit('success');
};

watch(
  () => formModel.seraCumprido,
  (val) => {
    if (val) {
      formRules.observacao = [
        { message: 'Observação é obrigatória', required: true },
      ];
      formRules.novoVencimento = null;
      formRules.justificativaParaNaoCumprimento = null;
    } else {
      formRules.observacao = null;
      formRules.novoVencimento = [
        { message: 'Novo vencimento é obrigatório', required: true },
      ];
      formRules.justificativaParaNaoCumprimento = [
        { message: 'Justificativa é obrigatória', required: true },
      ];
    }
  }
);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="cumprirStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-row
          v-if="cumprirStatus !== 'success' && devolverStatus !== 'success'"
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item
                label="Escolha uma das opções abaixo"
                name="seraCumprido"
              >
                <a-radio-group v-model:value="formModel.seraCumprido">
                  <a-radio-button :value="true"> Cumprir agora </a-radio-button>
                  <a-radio-button :value="false"> Reagendar </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="formModel.seraCumprido === true"
                label="Observação"
                name="observacao"
              >
                <a-textarea v-model:value="formModel.observacao" :rows="4" />
              </a-form-item>

              <a-form-item
                v-if="formModel.seraCumprido === false"
                label="Novo vencimento"
                name="novoVencimento"
              >
                <a-date-picker
                  v-model:value="formModel.novoVencimento"
                  format="DD/MM/YYYY HH:mm"
                  value-format="DD/MM/YYYY HH:mm"
                  placeholder="Escolha a data de vencimento"
                  :disabled-date="desabilitarDatasPraTras"
                  :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
                  style="width: 300px"
                />
              </a-form-item>

              <a-form-item
                v-if="formModel.seraCumprido === false"
                label="Justificativa"
                name="justificativaParaNaoCumprimento"
              >
                <a-textarea
                  v-model:value="formModel.justificativaParaNaoCumprimento"
                  :rows="4"
                />
              </a-form-item>

              <a-form-item
                v-if="
                  tiposCompromissosQueTemAtalhos.indexOf(
                    compromisso.tipoValue
                  ) >= 0
                "
                label="Atalhos"
              >
                <a-space>
                  <a-button
                    v-if="
                      compromisso.tipoValue ===
                      EnumTipoCompromisso.CaqGcpjDesatualizado
                    "
                    @click="uiSolicitarAtualizacaoGcpj"
                    >Atualizar GCPJ</a-button
                  >
                </a-space>
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">Envie os arquivos necessários</p>
                </template>
              </Uploader>
            </a-form>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== $auth.codigoUsuario
                  "
                  :loading="
                    uploaderStatus === 'uploading' ||
                    cumprirStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <!--                <a-button-->
                <!--                  :disabled="!($auth.ehGerente || $auth.ehGestor)"-->
                <!--                  :loading="devolverStatus === 'loading'"-->
                <!--                  style="margin-right: 8px; min-width: 100px"-->
                <!--                  @click="() => (devolverModalVisible = true)"-->
                <!--                >-->
                <!--                  Devolver-->
                <!--                </a-button>-->
                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
