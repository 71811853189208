import { defineStore } from 'pinia';

export const useDrawerStore = defineStore('drawer', {
  state: () => ({
    currentId: 0,
    activeDrawerId: null,
    activeDrawer: -1,
    drawers: [],
    lastClosedDrawer: null,
    lastAction: null,
    drawerReturn: null,
  }),
  getters: {
    currentComponent: state =>
      state.drawers.length > 0
        ? state.drawers[state.drawers.length - 1].componentName
        : null,
  },
  actions: {
    push(drawer)
    {
      this.drawers.push(drawer);
      this.activeDrawer += 1;
      this.lastAction = 'open';
      return this.activeDrawer;
    },
    push2(drawer)
    {
      this.currentId += 1;
      const newId = this.currentId;
      this.drawers.push({
        id: newId,
        ...drawer,
      });
      this.activeDrawerId = newId;
      this.activeDrawer += 1;
      this.lastAction = 'open';
      return newId;
    },
    success()
    {
      this.lastClosedDrawer = this.drawers.pop();
      this.activeDrawer -= 1;
      this.lastAction = 'close';
      if (typeof this.lastClosedDrawer.onSuccess === 'function')
      {
        this.lastClosedDrawer.onSuccess();
      }
    },
    pop()
    {
      this.lastClosedDrawer = this.drawers.pop();
      this.activeDrawer -= 1;
      this.lastAction = 'close';
      if (typeof this.lastClosedDrawer?.onClose === 'function')
      {
        this.lastClosedDrawer.onClose();
      }
    },
    closeAllWithoutCallbacks()
    {
      do
      {
        this.drawers.pop();
      } while (this.drawers.length > 0);
    },
  },
});
