<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <a-card class="statistic-card">
    <a-skeleton :loading="props.loading" :title="false" :paragraph="{ rows: 2, width: ['75%', '100%'] }">
      <a-row align="middle">
        <a-col span="24">
          <div class="ant-statistic">
            <div class="ant-statistic-content" style="text-align: center; font-size: 42px; font-weight: 500; color: #5d5d5d">
              <slot name="content" />
            </div>
            <div class="ant-statistic-title" style="text-align: center; font-size: 11px; font-weight: bold; text-transform: uppercase; color: rgb(182 181 181); letter-spacing: 1px">
              <slot name="title" />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-skeleton>
  </a-card>
</template>

<style lang="less">
.statistic-card {
  & .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
  & .ant-result {
    padding: 0;

    & .ant-result-icon {
      margin-bottom: 8px;
    }

    & .ant-result-icon > .anticon {
      font-size: 51px;
    }

    & .ant-result-title {
      font-size: 16px;
      font-weight: 500;
      color: #727272;
    }
  }
}
</style>
