<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-alert
    message="Email enviado ao banco com sucesso"
    type="success"
    show-icon
  />
</template>
