<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useReenviarLinkCriacaoSenha } from '~/composables/autenticacao/useReenviarLinkCriacaoSenha';
import { useGraphQL } from '~/composables/useGraphQL';
import { usePost } from '~/composables/usePost';
import tipoPerfil from '~/constants/tipoPerfil';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { API_URL } from '~/utils';

const route = useRoute();
const authStore = useAuthStore();
const drawerStore = useDrawerStore();
const { codigo } = route.params;
let drawerId = null;
let unsubscribe = null;

const {
  data: usuarioQueryData,
  runAsync: usuarioQueryAsync,
  status: usuarioQueryStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'UsuarioQuery',
});

const {
  err: bloquearUsuarioErr,
  runAsync: bloquearUsuarioAsync,
  status: bloquearUsuarioStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/usuarios/bloquear`,
});

const {
  err: desbloquearUsuarioErr,
  runAsync: desbloquearUsuarioAsync,
  status: desbloquearUsuarioStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/usuarios/desbloquear`,
});

const {
  err: reenviarLinkCriacaoSenhaErr,
  reenviar: reenviarLinkCriacaoSenha,
  status: reenviarLinkCriacaoSenhaStatus,
} = useReenviarLinkCriacaoSenha({
  codigoUsuario: codigo,
});

function abrirSalvarUsuarioDrawer()
{
  drawerId = drawerStore.push2({
    componentName: 'SalvarUsuarioForm',
    params: {
      codigo,
    },
    width: 600,
  });
}

async function uiUsuarioQueryAsync()
{
  await usuarioQueryAsync({
    codigo,
  });
}

async function uiBloquearUsuarioAsync()
{
  await bloquearUsuarioAsync({
    codigoUsuario: codigo,
  });
}

async function uiDesbloquearUsuarioAsync()
{
  await desbloquearUsuarioAsync({
    codigoUsuario: codigo,
  });
}

watch(
  () => bloquearUsuarioStatus.value,
  (val) =>
  {
    if (val === 'error')
    {
      notification.error({
        duration: 10,
        message: bloquearUsuarioErr.value.message,
      });
    }
    else if (val === 'success')
    {
      notification.success({
        duration: 5,
        message: 'Usuário bloqueado com sucesso',
      });
      usuarioQueryData.value.usuario.ativo = false;
    }
  },
);

watch(
  () => desbloquearUsuarioStatus.value,
  (val) =>
  {
    if (val === 'error')
    {
      notification.error({
        duration: 10,
        message: desbloquearUsuarioErr.value.message,
      });
    }
    else if (val === 'success')
    {
      notification.success({
        duration: 5,
        message: 'Usuário desbloqueado com sucesso',
      });
      usuarioQueryData.value.usuario.ativo = true;
    }
  },
);

watch(
  () => reenviarLinkCriacaoSenhaStatus.value,
  (val) =>
  {
    if (val === 'error')
    {
      notification.error({
        description: reenviarLinkCriacaoSenhaErr.description,
        duration: 10,
        message: reenviarLinkCriacaoSenhaErr.message,
      });
    }
    else if (val === 'success')
    {
      notification.success({
        duration: 5,
        message: 'Link para criação de senha reenviado com sucesso',
      });
    }
  },
);

onMounted(async () =>
{
  await uiUsuarioQueryAsync();
  unsubscribe = drawerStore.$subscribe(async (mutation, state) =>
  {
    if (
      state.lastAction === 'close'
      && state.lastClosedDrawer.id === drawerId
    )
    {
      await uiUsuarioQueryAsync();
    }
  });
});

onUnmounted(() =>
{
  if (unsubscribe)
  {
    unsubscribe();
  }
});
</script>

<template>
  <Page menu="usuarios">
    <div class="visualizar-usuario">
      <a-row>
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/usuarios">
                    Usuários
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Visualizar</a-breadcrumb-item>
              </a-breadcrumb>
              <a-skeleton
                :loading="usuarioQueryStatus === 'loading'"
                :active="true"
                :paragraph="false"
              >
                <h1 v-if="usuarioQueryStatus === 'success'">
                  <UnorderedListOutlined
                    style="color: #bebcbc; font-size: 18px"
                  />
                  {{ usuarioQueryData.usuario.nome.toUpperCase() }}
                </h1>
              </a-skeleton>
            </a-col>
            <a-col :span="12">
              <div
                v-if="usuarioQueryStatus === 'success'"
                class="page-header-button-container"
              >
                <a-button
                  v-if="
                    authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                  "
                  style="margin-right: 8px"
                  @click="() => abrirSalvarUsuarioDrawer()"
                >
                  Editar
                </a-button>
                <a-button
                  v-if="
                    (authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo))
                      && usuarioQueryData.usuario.ativo
                      && !usuarioQueryData.usuario.jaCriouSenha
                  "
                  :loading="reenviarLinkCriacaoSenhaStatus === 'loading'"
                  style="margin-right: 8px"
                  @click="reenviarLinkCriacaoSenha"
                >
                  Reenviar link para criar senha
                </a-button>
                <a-button
                  v-if="
                    authStore.temPerfilAcesso(
                      tipoPerfil.BloquearDesbloquearUsuario,
                    ) && usuarioQueryData.usuario.ativo
                  "
                  :loading="bloquearUsuarioStatus === 'loading'"
                  danger
                  @click="uiBloquearUsuarioAsync"
                >
                  Bloquear
                </a-button>
                <a-button
                  v-else-if="
                    authStore.temPerfilAcesso(
                      tipoPerfil.BloquearDesbloquearUsuario,
                    )
                  "
                  :loading="desbloquearUsuarioStatus === 'loading'"
                  @click="uiDesbloquearUsuarioAsync"
                >
                  Desbloquear
                </a-button>
              </div>
            </a-col>
          </a-row>
          <!-- /Page header -->

          <div style="margin-top: 24px">
            <div v-if="usuarioQueryStatus === 'loading'">
              <a-card>
                <a-skeleton
                  :loading="true"
                  :active="true"
                  :paragraph="{ rows: 6 }"
                />
              </a-card>
            </div>
            <ErrorResult v-else-if="usuarioQueryStatus === 'error'" />
            <div v-else-if="usuarioQueryStatus === 'success'">
              <a-descriptions
                :column="1"
                :label-style="{ width: '15%' }"
                bordered
                style="margin-top: 24px"
              >
                <a-descriptions-item
                  label="Email"
                  :content-style="{ width: '90%' }"
                >
                  {{ usuarioQueryData.usuario.email }}
                </a-descriptions-item>
                <a-descriptions-item label="Status">
                  <span v-if="usuarioQueryData.usuario.ativo">ATIVO</span>
                  <span v-else>BLOQUEADO</span>
                </a-descriptions-item>
                <a-descriptions-item label="Nome">
                  <span style="text-transform: uppercase">{{
                    usuarioQueryData.usuario.nome
                  }}</span>
                </a-descriptions-item>

                <a-descriptions-item label="CPF">
                  {{ formatCpfOuCnpj(usuarioQueryData.usuario.cpf) }}
                </a-descriptions-item>

                <a-descriptions-item label="Timezone">
                  {{ usuarioQueryData.usuario.timezoneText }}
                </a-descriptions-item>

                <a-descriptions-item
                  label="Perfis de acesso"
                  :content-style="{ 'flex-wrap': 'wrap' }"
                >
                  <a-tag
                    v-for="perfilAcesso in usuarioQueryData.usuario
                      .perfisAcesso ?? []"
                    :key="perfilAcesso.perfil"
                    style="color: #b3b1b1; margin: 4px"
                  >
                    {{ perfilAcesso.perfilText }}
                  </a-tag>
                </a-descriptions-item>
                <a-descriptions-item label="Criado por">
                  <router-link
                    v-if="!isNil(usuarioQueryData.usuario.criadoPor)"
                    :to="`/usuario/${usuarioQueryData.usuario.criadoPor.codigo}`"
                  >
                    {{ usuarioQueryData.usuario.criadoPor.nome }}
                  </router-link>
                  <span v-else>SISTEMA</span>
                </a-descriptions-item>
                <a-descriptions-item label="Data criação">
                  {{ usuarioQueryData.usuario.dataCriacaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Acessou em">
                  <span>{{ usuarioQueryData.usuario.dataAcessoText }}</span>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
