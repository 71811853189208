<script setup>
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';
import {
  deepIntParaTextoComDuasOpcoes,
  deepIntParaTextoComQuatroOpcoes,
  deepIntParaTextoComTresOpcoes,
  formatCnj,
  formatDate,
  formatDinheiro,
  isNilOrEmpty,
} from '~/utils';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
  fluxo: {
    type: Object,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '25%' }"
    bordered
  >
    <a-descriptions-item
      v-if="'nome' in props.compromisso.data"
      label="Nome"
      :span="3"
    >
      {{ props.compromisso.data.nome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'pastaPrincipalNome' in props.compromisso.data"
      label="Pasta principal"
      :span="3"
    >
      {{ props.compromisso.data.pastaPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'tipoText' in props.compromisso.data"
      label="Tipo"
      :span="3"
    >
      {{ props.compromisso.data.tipoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'tipoProcessoText' in props.compromisso.data"
      label="Tipo processo"
      :span="3"
    >
      {{ props.compromisso.data.tipoProcessoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'statusText' in props.compromisso.data"
      label="Status"
      :span="3"
    >
      {{ props.compromisso.data.statusText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'empresaText' in props.compromisso.data"
      label="Empresa"
      :span="3"
    >
      {{ props.compromisso.data.empresaText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'responsavelProcessualNome' in props.compromisso.data"
      label="Responsável processual"
      :span="3"
    >
      {{ props.compromisso.data.responsavelProcessualNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'responsavelNegocialNome' in props.compromisso.data"
      label="Responsável negocial"
      :span="3"
    >
      {{ props.compromisso.data.responsavelNegocialNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'gerenteNegocialResponsavelNome' in props.compromisso.data"
      label="Gerente negocial responsável"
      :span="3"
    >
      {{ props.compromisso.data.gerenteNegocialResponsavelNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorEnvolvido' in props.compromisso.data"
      label="Valor envolvido"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorEnvolvido, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataRecepcao' in props.compromisso.data"
      label="Data remessa"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataRecepcao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'clientePrincipalNome' in props.compromisso.data"
      label="Cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.clientePrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'clientePrincipalPapelText' in props.compromisso.data"
      label="Papel cliente principal"
      :span="3"
    >
      {{ props.compromisso.data.clientePrincipalPapelText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'contrarioPrincipalNome' in props.compromisso.data"
      label="Contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'contrarioPrincipalPapelText' in props.compromisso.data"
      label="Papel contrário principal"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalPapelText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'gcpj' in props.compromisso.data"
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'gcpjAtivo' in props.compromisso.data"
      label="Status do GCPJ"
      :span="3"
    >
      {{
        props.compromisso.data.gcpjAtivo === true
          ? 'ATIVO'
          : props.compromisso.data.gcpjAtivo === false
            ? 'ENCERRADO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dejur' in props.compromisso.data"
      label="Departamento jurídico"
      :span="3"
    >
      {{ props.compromisso.data.dejur }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'empresaBanco' in props.compromisso.data"
      label="Empresa/Banco"
      :span="3"
    >
      {{ props.compromisso.data.empresaBanco }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'agenciaNome' in props.compromisso.data"
      label="Agência"
      :span="3"
    >
      {{ props.compromisso.data.agenciaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'conta' in props.compromisso.data"
      label="Conta"
      :span="3"
    >
      {{ props.compromisso.data.conta }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'contaFicticia' in props.compromisso.data"
      label="Conta fictícia"
      :span="3"
    >
      {{ props.compromisso.data.contaFicticia }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'estadoNome' in props.compromisso.data"
      label="Estado"
      :span="3"
    >
      {{ props.compromisso.data.estadoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'cidadeNome' in props.compromisso.data"
      label="Cidade"
      :span="3"
    >
      {{ props.compromisso.data.cidadeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataFatoGerador' in props.compromisso.data"
      label="Data fato gerador"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataFatoGerador,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'garantias' in props.compromisso.data"
      label="Garantias"
      :span="3"
    >
      {{ props.compromisso.data.garantias }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'avalistas' in props.compromisso.data"
      label="Avalistas"
      :span="3"
    >
      {{ props.compromisso.data.avalistas }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'advogadoBanco' in props.compromisso.data"
      label="Advogado do banco"
      :span="3"
    >
      {{ props.compromisso.data.advogadoBanco }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorContrato' in props.compromisso.data"
      label="Valor do contrato"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorContrato) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'informacaoSobreObito' in props.compromisso.data"
      label="Informações sobre óbito"
      :span="3"
    >
      {{ props.compromisso.data.informacaoSobreObito }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataDeclaracaoIrrecuperabilidade' in props.compromisso.data"
      label="Data de declaração de irrecuperabilidade"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataDeclaracaoIrrecuperabilidade,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataEncerramento' in props.compromisso.data"
      label="Data de encerramento"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataEncerramento,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'motivoEncerramento' in props.compromisso.data"
      label="Motivo encerramento"
      :span="3"
    >
      {{ props.compromisso.data.motivoEncerramento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'depositarioFielNome' in props.compromisso.data"
      label="Depositário fiel"
      :span="3"
    >
      {{ props.compromisso.data.depositarioFielNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'telaOperacao' in props.compromisso.data"
      label="Tela de operação"
      :span="3"
    >
      {{ props.compromisso.data.telaOperacao }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataCelebracaoContratoOriginal' in props.compromisso.data"
      label="Data de celebração do contrato original"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataCelebracaoContratoOriginal,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataVencimentoFinalParcelaContratoOriginal' in props.compromisso.data"
      label="Data do vencimento final da parcela do contrato original"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataVencimentoFinalParcelaContratoOriginal,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataPrimeiraParcelaVencida' in props.compromisso.data"
      label="Data da primeira parcela vencida"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataPrimeiraParcelaVencida,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'numeroCnj' in props.compromisso.data"
      label="Número CNJ"
      :span="3"
    >
      {{ formatCnj(props.compromisso.data.numeroCnj, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'numeroAntigo' in props.compromisso.data"
      label="Número antigo"
      :span="3"
    >
      {{ props.compromisso.data.numeroAntigo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorCausa' in props.compromisso.data"
      label="Valor da causa"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorCausa, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'naturezaText' in props.compromisso.data"
      label="Natureza"
      :span="3"
    >
      {{ props.compromisso.data.naturezaText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'procedimentoText' in props.compromisso.data"
      label="Procedimento"
      :span="3"
    >
      {{ props.compromisso.data.procedimentoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'acaoNome' in props.compromisso.data"
      label="Ação"
      :span="3"
    >
      {{ props.compromisso.data.acaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'orgaoNome' in props.compromisso.data"
      label="Órgão"
      :span="3"
    >
      {{ props.compromisso.data.orgaoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'justicaNome' in props.compromisso.data"
      label="Justiça"
      :span="3"
    >
      {{ props.compromisso.data.justicaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'instanciaNome' in props.compromisso.data"
      label="Instância"
      :span="3"
    >
      {{ props.compromisso.data.instanciaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'classeNome' in props.compromisso.data"
      label="Classe"
      :span="3"
    >
      {{ props.compromisso.data.classeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'assuntoNome' in props.compromisso.data"
      label="Assunto"
      :span="3"
    >
      {{ props.compromisso.data.assuntoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'vara' in props.compromisso.data"
      label="Vara"
      :span="3"
    >
      {{ props.compromisso.data.vara }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'comarcaNome' in props.compromisso.data"
      label="Comarca"
      :span="3"
    >
      {{ props.compromisso.data.comarcaNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'atualizacaoValorCausa' in props.compromisso.data"
      label="Atualização do valor da causa"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.atualizacaoValorCausa, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataAtualizacaoValorCausa' in props.compromisso.data"
      label="Data atualização valor da causa"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataAtualizacaoValorCausa,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'motivoNaoAjuizamentoText' in props.compromisso.data"
      label="Motivo do não ajuizamento"
      :span="3"
    >
      {{ props.compromisso.data.motivoNaoAjuizamentoText }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataAjuizamento' in props.compromisso.data"
      label="Data de ajuizamento"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataAjuizamento,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'custasProtesto' in props.compromisso.data"
      label="Custas protesto"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasProtesto, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'custasIniciais' in props.compromisso.data"
      label="Custas iniciais"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasIniciais, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'custasOficialJustica' in props.compromisso.data"
      label="Custas oficial de justiça"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.custasOficialJustica, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataHabilitacao' in props.compromisso.data"
      label="Data de habilitação"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataHabilitacao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'procuracaoFls' in props.compromisso.data"
      label="Procuração Fls"
      :span="3"
    >
      {{ props.compromisso.data.procuracaoFls }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'arquivamentoProvisorio' in props.compromisso.data"
      label="Arquivamento provisório"
      :span="3"
    >
      {{ props.compromisso.data.arquivamentoProvisorio }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataSuspensaoAusenciaBensPenhoraveis' in props.compromisso.data"
      label="Data suspensão por ausência de bens penhoráveis"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataSuspensaoAusenciaBensPenhoraveis,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataLiminar' in props.compromisso.data"
      label="Data de liminar"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataLiminar,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataCitacao' in props.compromisso.data"
      label="Data de citação"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataCitacao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'solicitacaoConversaoExecucao' in props.compromisso.data"
      label="Solicitação de conversão em execução"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(
          props.compromisso.data.solicitacaoConversaoExecucao,
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataSolicitacaoConversaoExecucao' in props.compromisso.data"
      label="Data solicitação conversão em execução"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataSolicitacaoConversaoExecucao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataConversaoExecucao' in props.compromisso.data"
      label="Data conversão em execução"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataConversaoExecucao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataSentenca' in props.compromisso.data"
      label="Data de sentença"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataSentenca,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataBaixa' in props.compromisso.data"
      label="Data de baixa"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataBaixa,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataResultado' in props.compromisso.data"
      label="Data resultado"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataResultado,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'motivoResultado' in props.compromisso.data"
      label="Motivo resultado"
      :span="3"
    >
      {{ props.compromisso.data.motivoResultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'resultado' in props.compromisso.data"
      label="Resultado"
      :span="3"
    >
      {{ props.compromisso.data.resultado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'revelia' in props.compromisso.data"
      label="Revelia?"
      :span="3"
    >
      {{
        props.compromisso.data.revelia === true
          ? 'SIM'
          : props.compromisso.data.revelia === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'fundamentoSentenca' in props.compromisso.data"
      label="Fundamento da sentença"
      :span="3"
    >
      {{ props.compromisso.data.fundamentoSentenca }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataApreensao' in props.compromisso.data"
      label="Data de apreensão"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataApreensao,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'aptoApreensao' in props.compromisso.data"
      label="Apto para apreensão?"
      :span="3"
    >
      {{
        props.compromisso.data.aptoApreensao === true
          ? 'SIM'
          : props.compromisso.data.aptoApreensao === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'inviavel' in props.compromisso.data"
      label="Inviável?"
      :span="3"
    >
      {{
        props.compromisso.data.inviavel === true
          ? 'SIM'
          : props.compromisso.data.inviavel === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'apreensaoVeiculo' in props.compromisso.data"
      label="Apreensão veículo"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(props.compromisso.data.apreensaoVeiculo)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'placa' in props.compromisso.data"
      label="Placa"
      :span="3"
    >
      {{ props.compromisso.data.placa }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'chassiRenavan' in props.compromisso.data"
      label="Chassi/Renavan"
      :span="3"
    >
      {{ props.compromisso.data.chassiRenavan }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'teveAcordo' in props.compromisso.data"
      label="Teve acordo?"
      :span="3"
    >
      {{
        props.compromisso.data.teveAcordo === true
          ? 'SIM'
          : props.compromisso.data.teveAcordo === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'responsavelAcordoNome' in props.compromisso.data"
      label="Responsável pelo acordo/apreensão"
      :span="3"
    >
      {{ props.compromisso.data.responsavelAcordoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'tipoAcordo' in props.compromisso.data"
      label="Tipo de acordo"
      :span="3"
    >
      {{ props.compromisso.data.tipoAcordo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataAcordo' in props.compromisso.data"
      label="Data de acordo"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataAcordo,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'quantidadeParcelas' in props.compromisso.data"
      label="Quantidade de parcelas"
      :span="3"
    >
      {{ props.compromisso.data.quantidadeParcelas }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorTotalAcordo' in props.compromisso.data"
      label="Valor total do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorTotalAcordo, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorEntradaAcordo' in props.compromisso.data"
      label="Valor entrada do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorEntradaAcordo, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorFinanciadoAcordo' in props.compromisso.data"
      label="Valor financiado do acordo"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorFinanciadoAcordo, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'taxaJurosAcordo' in props.compromisso.data"
      label="Taxa de juros do acordo (A.M.)"
      :span="3"
    >
      {{
        props.compromisso.data.taxaJurosAcordo !== null
          ? `${props.compromisso.data.taxaJurosAcordo}%`
          : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataVencimentoPrimeiraParcelaAcordo' in props.compromisso.data"
      label="Data vencimento primeira parcela acordo"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataVencimentoPrimeiraParcelaAcordo,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataVencimentoUltimaParcelaAcordo' in props.compromisso.data"
      label="Data vencimento última parcela acordo"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataVencimentoUltimaParcelaAcordo,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'acordoEstaSendoCumprido' in props.compromisso.data"
      label="Acordo está sendo cumprido?"
      :span="3"
    >
      {{
        props.compromisso.data.acordoEstaSendoCumprido === true
          ? 'SIM'
          : props.compromisso.data.acordoEstaSendoCumprido === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataDescumprimentoAcordo' in props.compromisso.data"
      label="Data descumprimento acordo"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataDescumprimentoAcordo,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'historicoDescumprimento' in props.compromisso.data"
      label="Histórico de descumprimento"
      :span="3"
    >
      {{ props.compromisso.data.historicoDescumprimento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'teveContabilizacaoAcordo' in props.compromisso.data"
      label="Teve contabilização do acordo?"
      :span="3"
    >
      {{
        props.compromisso.data.teveContabilizacaoAcordo === true
          ? 'SIM'
          : props.compromisso.data.teveContabilizacaoAcordo === false
            ? 'NÃO'
            : ''
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'volumetria' in props.compromisso.data"
      label="Volumetria"
      :span="3"
    >
      {{ props.compromisso.data.volumetria }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'plano' in props.compromisso.data"
      label="Plano"
      :span="3"
    >
      {{ props.compromisso.data.plano }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorParcelaContratoOriginal' in props.compromisso.data"
      label="Valor da parcela do contrato inicial"
      :span="3"
    >
      {{
        formatDinheiro(props.compromisso.data.valorParcelaContratoOriginal, '')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataVencimentoInicial' in props.compromisso.data"
      label="Data vencimento inicial"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataVencimentoInicial,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'motivoEmenda' in props.compromisso.data"
      label="Motivo emenda"
      :span="3"
    >
      {{ props.compromisso.data.motivoEmenda }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'liminarDeferida' in props.compromisso.data"
      label="Liminar deferida"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(props.compromisso.data.liminarDeferida)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataRespostaLiminar' in props.compromisso.data"
      label="Data resposta liminar"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataRespostaLiminar,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'motivoNaoDeferimentoLiminar' in props.compromisso.data"
      label="Motivo não deferimento da liminar"
      :span="3"
    >
      {{ props.compromisso.data.motivoNaoDeferimentoLiminar }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'agravoInstrumento' in props.compromisso.data"
      label="Agravo de instrumento"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(props.compromisso.data.agravoInstrumento)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'mandadoApreensaoExpedido' in props.compromisso.data"
      label="Mandado de apreensão expedido"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(
          props.compromisso.data.mandadoApreensaoExpedido,
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataMandadoApreensaoExpedido' in props.compromisso.data"
      label="Data mandado apreensão expedido"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataMandadoApreensaoExpedido,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'liberacaoVendaVeiculo' in props.compromisso.data"
      label="Liberação venda do veículo"
      :span="3"
    >
      {{
        deepIntParaTextoComTresOpcoes(
          props.compromisso.data.liberacaoVendaVeiculo,
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'dataLiberacaoVendaVeiculo' in props.compromisso.data"
      label="Data liberação venda veículo"
      :span="3"
    >
      {{
        formatDate(
          props.compromisso.data.dataLiberacaoVendaVeiculo,
          authStore.timeZone,
          'DD/MM/YYYY',
        )
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'acaoMandadoNegativo' in props.compromisso.data"
      label="Ação mandando negativo"
      :span="3"
    >
      {{ props.compromisso.data.acaoMandadoNegativo }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'bemLocalizado' in props.compromisso.data"
      label="Bem localizado"
      :span="3"
    >
      {{
        deepIntParaTextoComQuatroOpcoes(props.compromisso.data.bemLocalizado)
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'acaoBemNaoLocalizado' in props.compromisso.data"
      label="Ação bem não localizado"
      :span="3"
    >
      {{ props.compromisso.data.acaoBemNaoLocalizado }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'ultimoEvento' in props.compromisso.data"
      label="Último evento"
      :span="3"
    >
      {{ props.compromisso.data.ultimoEvento }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'deposito' in props.compromisso.data"
      label="Depósito"
      :span="3"
    >
      {{ deepIntParaTextoComTresOpcoes(props.compromisso.data.deposito) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorDeposito' in props.compromisso.data"
      label="Valor depósito"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorDeposito, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorLevantadoPeloBanco' in props.compromisso.data"
      label="Valor levantado pelo banco"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorLevantadoPeloBanco, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorLevantadoPelaParte' in props.compromisso.data"
      label="Valor levantado pela parte"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorLevantadoPelaParte, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'penhora' in props.compromisso.data"
      label="Penhora"
      :span="3"
    >
      {{ deepIntParaTextoComTresOpcoes(props.compromisso.data.penhora) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'tipoPenhora' in props.compromisso.data"
      label="Tipo penhora"
      :span="3"
    >
      {{ props.compromisso.data.tipoPenhora }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'valorPenhora' in props.compromisso.data"
      label="Valor penhora"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorPenhora, '') }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'substabelecido' in props.compromisso.data"
      label="Substabelecido"
      :span="3"
    >
      {{ deepIntParaTextoComDuasOpcoes(props.compromisso.data.substabelecido) }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalDescricao' in props.compromisso.data
      "
      label="Contrário principal endereço / Descrição"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalDescricao }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalLogradouro'
          in props.compromisso.data
      "
      label="Contrário principal endereço / Logradouro"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalLogradouro }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalNumero' in props.compromisso.data
      "
      label="Contrário principal endereço / Número"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalNumero }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalComplemento'
          in props.compromisso.data
      "
      label="Contrário principal endereço / Complemento"
      :span="3"
    >
      {{
        props.compromisso.data.contrarioPrincipalEnderecoPrincipalComplemento
      }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalBairro' in props.compromisso.data
      "
      label="Contrário principal endereço / Bairro"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalBairro }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'contrarioPrincipalEnderecoPrincipalCep' in props.compromisso.data"
      label="Contrário principal endereço / CEP"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalCep }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalEstadoNome'
          in props.compromisso.data
      "
      label="Contrário principal endereço / Estado"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalEstadoNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="
        'contrarioPrincipalEnderecoPrincipalCidadeNome'
          in props.compromisso.data
      "
      label="Contrário principal endereço / Cidade"
      :span="3"
    >
      {{ props.compromisso.data.contrarioPrincipalEnderecoPrincipalCidadeNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'carteiraContratoCarteiraNome' in props.compromisso.data"
      label="Carteira Contrato / Carteira"
      :span="3"
    >
      {{ props.compromisso.data.carteiraContratoCarteiraNome }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'carteiraContratoContrato' in props.compromisso.data"
      label="Carteira Contrato / Contrato"
      :span="3"
    >
      {{ props.compromisso.data.carteiraContratoContrato }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'carteiraContratoVigente' in props.compromisso.data"
      label="Carteira Contrato / Vigente"
      :span="3"
    >
      {{ props.compromisso.data.carteiraContratoVigente ? 'SIM' : 'NÃO' }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="'carteiraContratoOriginal' in props.compromisso.data"
      label="Carteira Contrato / Original"
      :span="3"
    >
      {{ props.compromisso.data.carteiraContratoOriginal ? 'SIM' : 'NÃO' }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
  <div
    v-if="'carteirasContratos' in props.compromisso.data"
    style="margin-top: 24px"
  >
    <CarteiraContratoManager
      :value="props.compromisso.data.carteirasContratos"
      :editable="false"
    />
  </div>
</template>
