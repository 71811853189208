<script setup lang="ts">
import { mergeWithArrayConcat } from '~/utils';

interface Props
{
  usuarioCodigo?: string[]
  perfil?: number[]
}

const props = defineProps<Props>();

let gqlVariables: any = {};

const baseGqlVariables = {
  status: 5,
};

if (props.usuarioCodigo && props.usuarioCodigo.length > 0)
{
  const filtro = {
    where: {
      and: [
        {
          responsavelCodigo: {
            in: props.usuarioCodigo,
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else if (props.perfil && props.perfil.length > 0)
{
  const filtro = {
    where: {
      and: [
        {
          responsavel: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.perfil,
                },
              },
            },
          },
        },
      ],
    },
  };

  gqlVariables = mergeWithArrayConcat(baseGqlVariables, filtro);
}
else
{
  gqlVariables = { ...baseGqlVariables };
}
</script>

<template>
  <CompromissoGqlStatsCard title="CONCLUÍDOS" :variables="gqlVariables" :datatable-show-status-search-bar="false" />
</template>
