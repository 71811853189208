<script setup lang="ts">
import { onMounted } from 'vue';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';

const props = defineProps({
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-alert
      v-if="props.pastaCodigos?.length > 1"
      type="warning"
      message="Você irá realizar uma alteração em lote"
      show-icon
      style="margin-bottom: 24px"
    />

    <CompromissoCommandForm2
      command="AlteracaoResponsavelProcessual.AlterarResponsavelProcessualCommand"
      :show-upload-files="false"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Responsável"
          name="responsavel"
          :rules="[
            {
              message: 'Responsável é obrigatório',
              required: true,
              trigger: 'change',
              type: 'object',
            },
          ]"
        >
          <GqlSelect
            v-model:value="model.responsavel"
            :query="usuarioSelect"
            placeholder="Selecione o responsável"
            :variables="{
              where: {
                ativo: {
                  eq: true,
                },
              },
              order: [
                {
                  nome: 'ASC',
                },
              ],
            }"
          />
        </a-form-item>
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
