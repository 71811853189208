<script setup>
import { reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { notification } from 'ant-design-vue';
import {
  useAlterarVencimentoCompromisso,
  useAlterarVencimentoCompromissoEmLote,
} from '~/composables/compromissos/useAlterarVencimentoCompromisso';

const props = defineProps({
  compromissoCodigo: {
    type: String,
    default: null,
  },
  compromissosCodigos: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['close']);

const {
  status: alterarVencimentoCompromissoStatus,
  err: alterarVencimentoCompromissoErr,
  runAsync: alterarVencimentoCompromissoAsync,
} = useAlterarVencimentoCompromisso();

const {
  status: alterarVencimentoCompromissoEmLoteStatus,
  err: alterarVencimentoCompromissoEmLoteErr,
  runAsync: alterarVencimentoCompromissoEmLoteAsync,
} = useAlterarVencimentoCompromissoEmLote();

const formRef = ref(null);
const formModel = reactive({
  vencimento: null,
});
const formRules = reactive({
  vencimento: [{ required: true, message: 'Vencimento é obrigatório' }],
});
const disabledDate = (current) => {
  return (
    current &&
    (current.day() === 0 ||
      current.day() === 6 ||
      current < dayjs().subtract(1, 'day').endOf('day'))
  );
};

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    const rawForm = {
      vencimento: formModel.vencimento,
      compromissoCodigo: props.compromissoCodigo,
      compromissosCodigos: props.compromissosCodigos,
    };

    if (props.compromissosCodigos.length > 0) {
      await alterarVencimentoCompromissoEmLoteAsync(rawForm);
    } else {
      await alterarVencimentoCompromissoAsync(rawForm);
    }

    if (
      alterarVencimentoCompromissoStatus.value === 'error' ||
      alterarVencimentoCompromissoEmLoteStatus.value === 'error'
    ) {
      const errorMessage =
        alterarVencimentoCompromissoErr.value?.message ??
        alterarVencimentoCompromissoEmLoteErr.value.message;

      notification.error({
        message: errorMessage,
        duration: 5,
      });
    }
  });
};
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          ALTERAR VENCIMENTO DO COMPROMISSO</span
        >
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-result
        v-if="
          alterarVencimentoCompromissoStatus === 'success' ||
          alterarVencimentoCompromissoEmLoteStatus === 'success'
        "
        status="success"
        title="Vencimento alterado com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </template>
      </a-result>
      <div
        v-if="
          alterarVencimentoCompromissoStatus !== 'success' &&
          alterarVencimentoCompromissoEmLoteStatus !== 'success'
        "
      >
        <a-alert
          v-if="props.compromissosCodigos.length > 0"
          type="warning"
          message="Você irá realizar uma delegação em lote"
          show-icon
          style="margin-bottom: 24px"
        ></a-alert>

        <h3 style="margin-bottom: 24px">Selecione o novo vencimento</h3>

        <a-form
          ref="formRef"
          layout="vertical"
          :model="formModel"
          :rules="formRules"
        >
          <a-form-item label="Vencimento" name="vencimento">
            <a-date-picker
              v-model:value="formModel.vencimento"
              format="DD/MM/YYYY HH:mm"
              value-format="DD/MM/YYYY HH:mm"
              placeholder="Escolha a data de vencimento"
              :disabled-date="disabledDate"
              :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
              style="width: 300px"
            />
          </a-form-item>

          <a-form-item style="margin-top: 24px; margin-bottom: 0px">
            <a-button
              :loading="
                alterarVencimentoCompromissoStatus === 'loading' ||
                alterarVencimentoCompromissoEmLoteStatus === 'loading'
              "
              type="primary"
              style="margin-right: 8px; min-width: 100px"
              @click.prevent="uiSalvarAsync"
            >
              Salvar
            </a-button>
            <a-button @click="() => emit('close')"> Cancelar </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
