import axios from 'axios';
import { ref } from 'vue';
import { error } from '~/utils';

export function usePost(opts: any)
{
  const { url, onlyLast, config } = opts;

  const status = ref<string | null>(null);
  const err = ref<any>(null);
  const response = ref<any>(null);
  const data = ref<any>(null);
  const controller = ref<any>(null);

  const runAsync = async (body: any) =>
  {
    response.value = null;
    data.value = null;
    err.value = null;
    status.value = 'loading';

    const _config = {
      ...config,
    };

    if (onlyLast)
    {
      if (controller.value)
      {
        controller.value.abort();
      }

      controller.value = new AbortController();
      _config.signal = controller.value.signal;
    }

    try
    {
      const r = await axios.post(url, body ?? {}, _config);
      response.value = r;
      data.value = r.data;
      status.value = 'success';
    }
    catch (e)
    {
      if (!axios.isCancel(e))
      {
        status.value = 'error';
        err.value = error(e);
      }
    }
  };

  const cancel = () =>
  {
    if (controller.value)
    {
      controller.value.abort();
    }
  };

  return {
    status,
    err,
    response,
    data,
    cancel,
    runAsync,
  };
}
