<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { countBy, orderBy, toPairs } from 'lodash-es';
import { computed, onMounted, ref } from 'vue';
import { colors } from '~/utils';

interface Props
{
  title: string
  query: DocumentNode
  postQueryFn?: (data: any) => any[]
  labelFn?: (data: any) => string
  variables?: any
}

const props = defineProps<Props>();

const status = ref<string | undefined>(undefined);

const { result, loading, load, error } = useLazyQuery(
  props.query,
  props.variables,
  {
    fetchPolicy: 'no-cache',
  },
);

const chartOptions = computed(() =>
{
  if (loading.value)
  {
    return {};
  }

  if (error.value)
  {
    return {};
  }

  if (!result.value)
  {
    return {};
  }

  const values = result.value.result;

  const countingArray = props.postQueryFn
    ? props.postQueryFn(values)
    : values;

  const dict = countBy(countingArray, (x: any) => x.label);
  const arr = toPairs(dict).map(x => ({ label: x[0], total: x[1] }));
  const orderedArr = orderBy(arr, x => x.total, 'desc');
  const labels = orderedArr.map(x => x.label);
  const totals = orderedArr.map(x => x.total);

  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors,
    dataLabels: {
      enabled: true,
      formatter: (_: any, opts: any) =>
      {
        return totals[opts.dataPointIndex];
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    series: [
      {
        name: 'total de pastas',
        data: totals,
      },
    ],
    xaxis: {
      categories: labels,
      labels: {
        show: false,
      },
    },
    yaxis: {
      // logarithmic: true,
    },
  };
});

onMounted(async () =>
{
  status.value = 'loading';
  await load();
  if (error.value)
  {
    status.value = 'error';
  }
  else
  {
    status.value = 'success';
  }
});
</script>

<template>
  <ChartCard
    :status="status"
    :options="chartOptions"
  >
    <template #title>
      <span style="color: rgb(128 128 128); font-size: 16px">{{
        props.title
      }}</span>
    </template>
  </ChartCard>
</template>
