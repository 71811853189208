<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { onMounted } from 'vue';

interface Props
{
  title: string
  query: DocumentNode
  variables?: any
  clickable?: boolean
}

const props = defineProps<Props>();
const emit = defineEmits(['click']);

const { result, loading, refetch, load, error } = useLazyQuery(
  props.query,
  props.variables,
  {
    fetchPolicy: 'no-cache',
  },
);

onMounted(async () =>
{
  await load();
});

defineExpose({
  reload: refetch,
});
</script>

<template>
  <div class="stats-card">
    <a-card class="statistic-card">
      <a-skeleton
        v-if="loading"
        :active="true"
        :title="false"
        :paragraph="{ rows: 2, width: ['75%', '100%'] }"
      />
      <Empty
        v-else-if="error"
        description="Nenhum dado encontrado"
        style="margin: 0"
      />
      <a-row v-else align="middle">
        <a-col span="24">
          <div class="ant-statistic">
            <div
              class="ant-statistic-content"
              style="
                text-align: center;
                font-size: 42px;
                font-weight: 500;
                color: #5d5d5d;
              "
            >
              <a v-if="clickable" href="#" @click.prevent="() => emit('click')">
                {{ result?.data?.totalCount }}
              </a>
              <span v-else>{{ result?.data?.totalCount }}</span>
            </div>
            <div
              class="ant-statistic-title"
              style="
                text-align: center;
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
                color: rgb(182 181 181);
                letter-spacing: 1px;
              "
            >
              {{ title }}
            </div>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<style>
.stats-card {
  height: 100%;
  .stats-card .ant-card {
    height: 100%;
    .stats-card .ant-card .ant-card-body {
      height: 100%;
      .stats-card .ant-card .ant-card-body .ant-row {
        height: 100%;
      }
    }
  }
  .stats-card .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
  .stats-card .ant-result {
    padding: 0;

    .stats-card .ant-result .ant-result-icon {
      margin-bottom: 8px;
    }

    .stats-card .ant-result .ant-result-icon > .anticon {
      font-size: 51px;
    }

    .stats-card .ant-result .ant-result-title {
      font-size: 16px;
      font-weight: 500;
      color: #727272;
    }
  }
}
</style>
