<script setup>
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import {
  computed,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from 'vue';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL, isNilOrEmpty } from '~/utils';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['loaded', 'close', 'success', 'error']);

const {
  data: verificarNecessidadeCadastroPublicacaoCapturadaPubData,
  err: verificarNecessidadeCadastroPublicacaoCapturadaPubErr,
  runAsync: verificarNecessidadeCadastroPublicacaoCapturadaPubAsync,
  status: verificarNecessidadeCadastroPublicacaoCapturadaPubStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/verificacao-necessidade-cadastro-publicacao-capturada/verificar-necessidade-cadastro-publicacao-capturada-pub`,
});

const authStore = useAuthStore();
const formRef = ref(null);
const comarcaSelectRef = ref(null);
const instanciasSelectRef = ref(null);
const state = reactive({
  acao: null,
  arquivos: [],
  clientePrincipal: null,
  clientePrincipalPapel: null,
  comarca: null,
  conta: null,
  contrarioPrincipal: null,
  contrarioPrincipalPapel: null,
  dataEncerramento: null,
  estado: null,
  gcpj: null,
  instancia: null,
  justica: null,
  motivoEncerramento: null,
  nome: null,
  orgao: null,
  responsavelNegocial: null,
  responsavelProcessual: null,
  status: null,
  tipo: null,
  tipoProcesso: null,
  tipoverificarNecessidadeCadastroPublicacaoCapturadaPub: null,
});
const formRules = computed(() =>
{
  return {
    acao: [{ message: 'Ação é obrigatória', required: true, type: 'object' }],
    clientePrincipal: [
      {
        message: 'Cliente principal é obrigatório',
        required: true,
        type: 'object',
      },
    ],
    clientePrincipalPapel: [
      {
        message: 'Papel do cliente principal é obrigatório',
        required: true,
      },
    ],
    comarca: [
      { message: 'Comarca é obrigatória', required: true, type: 'object' },
    ],
    conta: [{ message: 'Conta é obrigatória', required: true }],
    contrarioPrincipal: [
      {
        message: 'Contrário principal é obrigatório',
        required: true,
        type: 'object',
      },
    ],
    contrarioPrincipalPapel: [
      {
        message: 'Papel do contrário principal é obrigatório',
        required: true,
      },
    ],
    estado: [
      { message: 'Estado é obrigatório', required: true, type: 'object' },
    ],
    gcpj: [{ message: 'GCPJ é obrigatório', required: true }],
    instancia: [
      { message: 'Instância é obrigatória', required: true, type: 'object' },
    ],
    justica: [{ message: 'Justiça é obrigatória', required: true }],
    nome: [{ message: 'Pasta é obrigatória', required: true }],
    orgao: [{ message: 'Orgão é obrigatório', required: true, type: 'object' }],
    responsavelNegocial: [
      {
        message: 'Responsável negocial é obrigatório',
        required: true,
        type: 'object',
      },
    ],
    responsavelProcessual: [
      {
        message: 'Responsável processual é obrigatório',
        required: true,
        type: 'object',
      },
    ],
    status: [{ message: 'Status é obrigatório', required: true }],
    tipo: [{ message: 'Tipo é obrigatório', required: true }],
    tipoProcesso: [
      { message: 'Tipo do processo é obrigatório', required: true },
    ],
    tipoverificarNecessidadeCadastroPublicacaoCapturadaPub: [
      { message: 'Opção é obrigatória', required: true },
    ],
  };
});

const uploaderRef = ref(null);
const uploaderStatus = ref(null);
const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

async function onEstadoSelectChange(val)
{
  nextTick(async () =>
  {
    if (val)
    {
      await comarcaSelectRef.value?.reloadAsync();
    }
    else
    {
      comarcaSelectRef.value.reset();
    }
  });
}

async function onJusticaSelectChange(val)
{
  nextTick(async () =>
  {
    if (val)
    {
      await comarcaSelectRef.value?.reloadAsync();
      await instanciasSelectRef.value?.reloadAsync();
    }
    else
    {
      comarcaSelectRef.value.reset();
      instanciasSelectRef.value.reset();
    }
  });
}

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await verificarNecessidadeCadastroPublicacaoCapturadaPubAsync({
      acaoCodigo: state.acao?.value,
      arquivos: state.arquivos,
      clientePrincipalCodigo: state.clientePrincipal?.value,
      clientePrincipalPapel: state.clientePrincipalPapel,
      comarcaCodigo: state.comarca?.value,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      conta: state.conta,
      contrarioPrincipalCodigo: state.contrarioPrincipal?.value,
      contrarioPrincipalPapel: state.contrarioPrincipalPapel,
      dataBaixa: state.dataBaixa,
      dataEncerramento: state.dataEncerramento,
      estadoCodigo: state.estado?.value,
      gcpj: state.gcpj,
      instanciaCodigo: state.instancia?.value,
      justicaCodigo: state.justica,
      motivoEncerramento: state.motivoEncerramento,
      nome: state.nome,
      orgaoCodigo: state.orgao?.value,
      responsavelNegocialCodigo: state.responsavelNegocial?.value,
      responsavelProcessualCodigo: state.responsavelProcessual?.value,
      status: state.status,
      tipo: state.tipo,
      tipoProcesso: state.tipoProcesso,
      tipoverificarNecessidadeCadastroPublicacaoCapturadaPub:
        state.tipoverificarNecessidadeCadastroPublicacaoCapturadaPub,
    });

    if (
      verificarNecessidadeCadastroPublicacaoCapturadaPubStatus.value === 'error'
    )
    {
      jobStatus.value = 'error';
      notification.error({
        description:
          verificarNecessidadeCadastroPublicacaoCapturadaPubErr.value
            .description,
        duration: 5,
        message:
          verificarNecessidadeCadastroPublicacaoCapturadaPubErr.value.message,
      });
      emit('error');
      return;
    }

    const jobCodigo
      = verificarNecessidadeCadastroPublicacaoCapturadaPubData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
}

watch(jobPoolingStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage
        ?? 'Tivemos um problema inesperado',
    });
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-result
      v-if="jobStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => $emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="jobStatus !== 'success'">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="state"
        :rules="formRules"
      >
        <a-form-item
          label="Escolha uma das opções abaixo"
          name="tipoverificarNecessidadeCadastroPublicacaoCapturadaPub"
        >
          <a-radio-group
            v-model:value="
              state.tipoverificarNecessidadeCadastroPublicacaoCapturadaPub
            "
          >
            <a-radio-button :value="1">
              Cadastrar pasta
            </a-radio-button>
            <a-radio-button :value="2">
              Não precisa de cadastro
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <div
          v-if="
            state.tipoverificarNecessidadeCadastroPublicacaoCapturadaPub === 1
          "
        >
          <a-form-item
            label="Pasta (identificador único)"
            name="nome"
          >
            <a-input v-model:value="state.nome" />
          </a-form-item>
          <a-form-item
            label="Tipo"
            name="tipo"
          >
            <TipoEnumSelect
              v-model:value="state.tipo"
              placeholder="Selecione o tipo da pasta"
              :options="[
                { value: 1, label: 'DISTRIBUÍDA' },
                { value: 2, label: 'REDISTRIBUÍDA' },
                { value: 3, label: 'TRABALHISTA' },
                { value: 4, label: 'CONTRÁRIA' },
                { value: 5, label: 'IMPORTADA NOVAJUS' },
                { value: 6, label: 'AVULSO' },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="Tipo do processo"
            name="tipoProcesso"
          >
            <TipoEnumSelect
              v-model:value="state.tipoProcesso"
              placeholder="Selecione o tipo do processo"
              :options="[
                { value: 1, label: 'PROCESSO' },
                { value: 2, label: 'RECURSO' },
                { value: 3, label: 'INCIDENTE' },
                { value: 4, label: 'DEFESA' },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="Status"
            name="status"
          >
            <TipoEnumSelect
              v-model:value="state.status"
              placeholder="Selecione o status da pasta"
              :options="[
                { value: 2, label: 'ATIVO' },
                { value: 4, label: 'SUSPENSO' },
                { value: 7, label: 'BAIXADO' },
                { value: 8, label: 'ARQUIVADO' },
              ]"
            />
          </a-form-item>
          <a-form-item
            label="GCPJ"
            name="gcpj"
          >
            <a-input
              v-model:value="state.gcpj"
              :maxlength="10"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Conta"
            name="conta"
          >
            <a-input
              v-model:value="state.conta"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Estado"
            name="estado"
          >
            <GraphQlSelect
              v-model:value="state.estado"
              query="ESTADOS_SELECT"
              placeholder="Selecione o estado"
              :allow-clear="true"
              @change="onEstadoSelectChange"
            />
          </a-form-item>

          <a-form-item
            label="Data encerramento"
            name="dataEncerramento"
          >
            <a-date-picker
              v-model:value="state.dataEncerramento"
              format="DD/MM/YYYY"
              value-format="DD/MM/YYYY"
              placeholder="Selecione uma data"
              style="width: 100%"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Motivo encerramento"
            name="motivoEncerramento"
          >
            <a-input
              v-model:value="state.motivoEncerramento"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Ação"
            name="acao"
          >
            <GraphQlSelect
              v-model:value="state.acao"
              query="ACOES_SELECT"
              placeholder="Seleciona a ação"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item
            label="Órgão"
            name="orgao"
          >
            <GraphQlSelect
              v-model:value="state.orgao"
              query="ORGAOS_SELECT"
              placeholder="Selecione o órgão"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item
            label="Justiça"
            name="justica"
          >
            <TipoEnumSelect
              v-model:value="state.justica"
              placeholder="Selecione a justica"
              :options="[
                { value: 15, label: 'JUSTIÇA DO TRABALHO' },
                { value: 17, label: 'JUSTIÇA ELEITORAL' },
                { value: 13, label: 'JUSTIÇA ESTADUAL' },
                { value: 14, label: 'JUSTIÇA FEDERAL' },
                { value: 16, label: 'JUSTIÇA MILITAR' },
                { value: 18, label: 'SUPERIORES' },
              ]"
              :allow-clear="true"
              @change="onJusticaSelectChange"
            />
          </a-form-item>

          <a-form-item name="instancia">
            <template #label>
              <slot name="formItemLabel">
                Instância
                <a-tooltip>
                  <template #title>
                    Para selecionar a instância é necessário que seja
                    selecionada a justiça (CNJ).
                  </template>
                  <QuestionCircleOutlined
                    style="margin-left: 4px; cursor: pointer"
                  />
                </a-tooltip>
              </slot>
            </template>
            <GraphQlSelect
              ref="instanciasSelectRef"
              v-model:value="state.instancia"
              query="INSTANCIAS_SELECT"
              :allow-clear="true"
              :variables="{
                where: {
                  justicaCodigo: {
                    in: !isNilOrEmpty(state.justica)
                      ? [state.justica]
                      : [],
                  },
                },
              }"
              :disabled="isNilOrEmpty(state.justica)"
              placeholder="Seleciona a instância"
            />
          </a-form-item>
          <a-form-item name="comarca">
            <template #label>
              <slot name="formItemLabel">
                Comarca
                <a-tooltip>
                  <template #title>
                    Para selecionar a comarca é necessário que sejam
                    selecionados o estado e a justiça (CNJ).
                  </template>
                  <QuestionCircleOutlined
                    style="margin-left: 4px; cursor: pointer"
                  />
                </a-tooltip>
              </slot>
            </template>
            <GraphQlSelect
              ref="comarcaSelectRef"
              v-model:value="state.comarca"
              query="COMARCAS_SELECT"
              :allow-clear="true"
              :variables="{
                where: {
                  estadoCodigo: {
                    in: !isNilOrEmpty(state.estado)
                      ? [state.estado.value]
                      : [],
                  },
                  justicaCodigo: {
                    in: !isNilOrEmpty(state.justica)
                      ? [state.justica]
                      : [],
                  },
                },
              }"
              :disabled="
                isNilOrEmpty(state.estado) || isNilOrEmpty(state.justica)
              "
              placeholder="Seleciona a comarca"
            />
          </a-form-item>
          <a-form-item name="clientePrincipal">
            <template #label>
              <slot name="formItemLabel">
                Cliente
              </slot>
            </template>
            <AjaxSelect
              v-model:value="state.clientePrincipal"
              :url="`${API_URL}/ajax-select/pessoas`"
              placeholder="Selecione o cliente"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item
            label="Papel do cliente principal"
            name="clientePrincipalPapel"
          >
            <TipoEnumSelect
              v-model:value="state.clientePrincipalPapel"
              placeholder="Selecione o papel do cliente principal"
              :options="[
                { value: 1, label: 'AUTOR' },
                { value: 2, label: 'RÉU' },
                { value: 3, label: 'APELANTE' },
                { value: 4, label: 'APELADO' },
                { value: 5, label: 'AGRAVANTE' },
                { value: 6, label: 'AGRAVADO' },
                { value: 7, label: 'EMBARGANTE' },
                { value: 8, label: 'EMBARGADO' },
              ]"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item name="contrarioPrincipal">
            <template #label>
              <slot name="formItemLabel">
                Contrário
              </slot>
            </template>
            <AjaxSelect
              v-model:value="state.contrarioPrincipal"
              :url="`${API_URL}/ajax-select/pessoas`"
              placeholder="Selecione o contrário"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item
            label="Papel do contrário principal"
            name="contrarioPrincipalPapel"
          >
            <TipoEnumSelect
              v-model:value="state.contrarioPrincipalPapel"
              placeholder="Selecione o papel do contrário principal"
              :allow-clear="true"
              :options="[
                { value: 1, label: 'AUTOR' },
                { value: 2, label: 'RÉU' },
                { value: 3, label: 'APELANTE' },
                { value: 4, label: 'APELADO' },
                { value: 5, label: 'AGRAVANTE' },
                { value: 6, label: 'AGRAVADO' },
                { value: 7, label: 'EMBARGANTE' },
                { value: 8, label: 'EMBARGADO' },
              ]"
            />
          </a-form-item>
          <a-form-item name="responsavelProcessual">
            <template #label>
              <slot name="formItemLabel">
                Responsável processual
              </slot>
            </template>
            <GraphQlSelect
              v-model:value="state.responsavelProcessual"
              query="USUARIO_SELECT"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
              :allow-clear="true"
            />
          </a-form-item>

          <a-form-item name="responsavelNegocial">
            <template #label>
              <slot name="formItemLabel">
                Responsável negocial
              </slot>
            </template>
            <GraphQlSelect
              v-model:value="state.responsavelNegocial"
              query="USUARIO_SELECT"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
              :allow-clear="true"
            />
          </a-form-item>
          <a-form-item
            label="Data baixa"
            name="dataBaixa"
          >
            <a-date-picker
              v-model:value="state.dataBaixa"
              format="DD/MM/YYYY"
              value-format="DD/MM/YYYY"
              placeholder="Selecione uma data"
              style="width: 100%"
              :allow-clear="true"
            />
          </a-form-item>
        </div>

        <h3 style="margin-bottom: 24px">
          Anexar arquivos
        </h3>

        <a-row>
          <a-col :span="24">
            <Uploader
              ref="uploaderRef"
              v-model:fileList="state.arquivos"
              @start="() => (uploaderStatus = 'uploading')"
              @end="() => (uploaderStatus = 'done')"
            />
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              props.compromisso?.responsavelCodigo !== authStore.codigoUsuario
            "
            :loading="jobStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
