import tipoJobStatus from '~/constants/tipoJobStatus';
import { ref } from 'vue';

import { useGraphQL } from './useGraphQL';

export function useGraphQLWithPooling(opts) {
  const { data, runAsync, status } = useGraphQL(opts);

  const poolingStatus = ref('loading');
  const poolingData = ref(null);
  let isActive = true;

  const runAsyncPooling = async (body) => {
    if (!isActive) {
      return;
    }

    await runAsync(body);

    if (status.value === 'success') {
      if (data.value?.job?.status === tipoJobStatus.Processando) {
        setTimeout(async () => {
          await runAsyncPooling(body);
        }, opts.interval || 2000);
      }

      if (data.value?.job?.status === tipoJobStatus.Sucesso) {
        poolingStatus.value = 'success';
        poolingData.value = data.value;
      }

      if (data.value?.job?.status === tipoJobStatus.Falha) {
        poolingStatus.value = 'error';
        poolingData.value = data.value;
      }
    } else {
      poolingStatus.value = 'error';
    }
  };

  const startAsync = async (body) => {
    poolingStatus.value = 'loading';
    poolingData.value = null;

    await runAsyncPooling(body);
  };

  const cancel = () => {
    isActive = false;
  };

  return {
    cancel: cancel,
    data: poolingData,
    runAsync: startAsync,
    status: poolingStatus,
  };
}
