import axios from 'axios';
import { ref } from 'vue';
import { error } from '~/utils';

const API_URL = import.meta.env.VITE_API_URL;

export function useAdicionarComentario() {
  const status = ref(null);
  const err = ref(null);
  const response = ref(null);
  const data = ref(null);

  async function run(cmd) {
    status.value = 'loading';
    try {
      const r = await axios.post(`${API_URL}/pastas/adicionar-comentario`, cmd, {
        timeout: 120000,
        getAccessToken: true,
      });
      status.value = 'success';
      response.value = r;
      data.value = r.data;
    }
    catch (e) {
      status.value = 'error';
      err.value = error(e);
    }
  }

  return {
    status,
    err,
    response,
    data,
    run,
  };
}

// export function useAdicionarComentario() {
//   const teste = async (cmd) => {
//     await sleep(5000);
//     return axios.post(`${API_URL}/pastas/adicionar-comentarioxx`, cmd, {
//       timeout: 120000,
//       getAccessToken: true,
//     });
//   };

//   return useRequest(teste, { manual: true });
// }
