<script setup>
import { isNilOrEmpty } from '~/utils';
import { reactive, ref, toRaw, watch } from 'vue';

const props = defineProps({
  hiddenElements: {
    default: () => [],
    type: Array,
  },
  initialProps: {
    default: () => {},
    type: Object,
  },
  perfil: {
    default: null,
    type: Number,
  },
});
const emit = defineEmits(['change']);

const usuarioPorPerfilParams = ref('');

if (!isNilOrEmpty(props.perfil)) {
  usuarioPorPerfilParams.value = `&perfis=${props.perfil}`;
}

const filtrosModel = reactive({
  agencias: props.initialProps?.agencias ?? [],
  envolvidos: props.initialProps?.envolvidos ?? [],
  gcpj: props.initialProps?.gcpj ?? null,
  numeroAntigo: props.initialProps?.numeroAntigo ?? null,
  numeroCnj: props.initialProps?.numeroCnj ?? null,
  regionais: props.initialProps?.regionais ?? [],
  responsaveis: props.initialProps?.responsaveis ?? [],
  responsaveisAcordos: props.initialProps?.responsaveisAcordos ?? [],
  responsaveisNegociais: props.initialProps?.responsaveisNegociais ?? [],
  status: props.initialProps?.status ?? [],
  tempoAjuizamentoEmAnos: props.initialProps?.tempoAjuizamentoEmAnos,
  tempoCadastroEmAnos: props.initialProps?.tempoCadastroEmAnos,
  tiposProcessos: props.initialProps?.tiposProcessos ?? [],
});

const reset = () => {
  if (props.hiddenElements.indexOf('responsavelProcessual') < 0) {
    filtrosModel.responsaveis = [];
  }

  if (props.hiddenElements.indexOf('responsavelNegocial') < 0) {
    filtrosModel.responsaveisNegociais = [];
  }

  if (props.hiddenElements.indexOf('responsavelAcordo') < 0) {
    filtrosModel.responsaveisAcordos = [];
  }

  filtrosModel.gcpj = null;
  filtrosModel.numeroCnj = null;
  filtrosModel.numeroAntigo = null;
  filtrosModel.status = [];
  filtrosModel.tiposProcessos = [];
  filtrosModel.regionais = [];
  filtrosModel.envolvidos = [];
  filtrosModel.agencias = [];
  filtrosModel.tempoCadastroEmAnos = null;
  filtrosModel.tempoAjuizamentoEmAnos = null;
  emit('change', toRaw(filtrosModel));
};

const getRaw = () => {
  return toRaw(filtrosModel);
};

const get = () => {
  return toRaw(filtrosModel);
};

watch(filtrosModel, (val) => {
  emit('change', toRaw(val));
});

defineExpose({
  get,
  getRaw,
  reset,
});
</script>

<template>
  <!-- Filtros -->
  <div style="padding: 0 24px">
    <a-form layout="vertical">
      <a-form-item label="GCPJ">
        <a-input
          v-model:value="filtrosModel.gcpj"
          placeholder="Digite um ou mais GCPJs"
        />
      </a-form-item>

      <a-form-item label="Número CNJ">
        <a-input
          v-model:value="filtrosModel.numeroCnj"
          class="numero-cnj"
          placeholder="Digite um ou mais números CNJs"
        />
      </a-form-item>

      <a-form-item label="Número antigo">
        <a-input
          v-model:value="filtrosModel.numeroAntigo"
          placeholder="Digite um ou mais números antigos"
        />
      </a-form-item>

      <a-form-item label="Tipos do processo">
        <TipoEnumSelect
          v-model:value="filtrosModel.tiposProcessos"
          placeholder="Selecione um ou mais tipos de processo"
          mode="multiple"
          :label-in-value="true"
          :options="[
            { value: 1, label: 'Processo' },
            { value: 2, label: 'Recurso' },
            { value: 3, label: 'Incidente' },
            { value: 4, label: 'Defesa' },
          ]"
        />
      </a-form-item>

      <a-form-item label="Status">
        <TipoEnumSelect
          v-model:value="filtrosModel.status"
          placeholder="Selecione um ou mais status"
          mode="multiple"
          :label-in-value="true"
          :options="[
            { value: 2, label: 'Ativo' },
            { value: 4, label: 'Suspenso' },
            { value: 7, label: 'Baixado' },
            { value: 8, label: 'Arquivado' },
          ]"
        />
      </a-form-item>

      <a-form-item label="Regionais">
        <TipoEnumSelect
          v-model:value="filtrosModel.regionais"
          placeholder="Selecione uma ou mais regionais"
          mode="multiple"
          :label-in-value="true"
          :options="[
            { value: 1, label: 'Manaus Leste' },
            { value: 2, label: 'Manaus Oeste' },
            { value: 3, label: 'Porto Velho' },
            { value: 4, label: 'Belém' },
            { value: 5, label: 'Palmas' },
            { value: 6, label: 'Marabá' },
            { value: 7, label: 'Genérica' },
          ]"
        />
      </a-form-item>

      <a-form-item
        v-if="props.hiddenElements.indexOf('responsavelProcessual') < 0"
      >
        <template #label>
          <slot name="formItemLabel"> Responsáveis processuais </slot>
        </template>
        <AjaxSelect
          v-model:value="filtrosModel.responsaveis"
          mode="multiple"
          :url="`${API_URL}/ajax-select/usuarios-por-perfis?ativo=false${usuarioPorPerfilParams}`"
          placeholder="Selecione um ou mais responsáveis"
        />
      </a-form-item>

      <a-form-item
        v-if="props.hiddenElements.indexOf('responsavelNegocial') < 0"
      >
        <template #label>
          <slot name="formItemLabel"> Responsáveis negociais </slot>
        </template>
        <AjaxSelect
          v-model:value="filtrosModel.responsaveisNegociais"
          mode="multiple"
          :url="`${API_URL}/ajax-select/usuarios-por-perfis?ativo=false${usuarioPorPerfilParams}`"
          placeholder="Selecione um ou mais responsáveis"
        />
      </a-form-item>

      <a-form-item v-if="props.hiddenElements.indexOf('responsavelAcordo') < 0">
        <template #label>
          <slot name="formItemLabel"> Responsáveis acordo </slot>
        </template>
        <AjaxSelect
          v-model:value="filtrosModel.responsaveisAcordos"
          mode="multiple"
          :url="`${API_URL}/ajax-select/usuarios-por-perfis?ativo=false${usuarioPorPerfilParams}`"
          placeholder="Selecione um ou mais responsáveis"
        />
      </a-form-item>

      <a-form-item>
        <template #label>
          <slot name="formItemLabel"> Envolvidos </slot>
        </template>
        <AjaxSelect
          v-model:value="filtrosModel.envolvidos"
          mode="multiple"
          :url="`${API_URL}/ajax-select/pessoas`"
          placeholder="Selecione um ou mais envolvidos"
        />
      </a-form-item>

      <a-form-item>
        <template #label>
          <slot name="formItemLabel"> Agências </slot>
        </template>
        <GraphQlSelect
          v-model:value="filtrosModel.agencias"
          query="AgenciasAsSelectItem"
          mode="multiple"
          placeholder="Selecione uma ou mais agências"
        />
      </a-form-item>

      <a-form-item>
        <template #label>
          <slot name="formItemLabel"> Tempo de cadastro em anos </slot>
        </template>
        <a-input-number
          v-model:value="filtrosModel.tempoCadastroEmAnos"
          style="width: 100%"
          :min="0"
          :controls="false"
        />
      </a-form-item>

      <a-form-item>
        <template #label>
          <slot name="formItemLabel"> Tempo de ajuizamento em anos </slot>
        </template>
        <a-input-number
          v-model:value="filtrosModel.tempoAjuizamentoEmAnos"
          style="width: 100%"
          :min="0"
          :controls="false"
        />
      </a-form-item>
    </a-form>
  </div>
  <!-- / Filtros -->
</template>
