import axios from 'axios';
import { createRouter, createWebHistory } from 'vue-router';
import { nextTick } from 'vue';
import BuscarAgenciasPage from '~/pages/agencias/buscar/index.vue';
import VisualizarAgenciaPage from '~/pages/agencias/visualizar/index.vue';
import AgendaPage from '~/pages/agenda/index.vue';
import BuscarAndamentosPage from '~/pages/andamentos/buscar/index.vue';
import AreaTrabalhoPage from '~/pages/area-trabalho/index.vue';
import AtualizacoesGcpjsPage from '~/pages/atualizacoes-gcpjs/index.vue';
import CriarSenhaPage from '~/pages/autenticacao/criar-senha/index.vue';
import EsqueciSenhaPage from '~/pages/autenticacao/esqueci-senha/index.vue';
import LoginPage from '~/pages/autenticacao/login/index.vue';
import ResetarSenhaPage from '~/pages/autenticacao/resetar-senha/index.vue';
import SessaoExpiradaPage from '~/pages/autenticacao/sessao-expirada/index.vue';
import ConcluirFluxoEmLotePage from '~/pages/automatizacoes/concluir-fluxo-em-lote/index.vue';
import CompromissosPage from '~/pages/compromissos/index.vue';
import BuscarContatosPage from '~/pages/contatos/buscar/index.vue';
import VisualizarContatoPage from '~/pages/contatos/visualizar/index.vue';
import DevelopPage from '~/pages/develop/index.vue';
import FluxosPage from '~/pages/fluxos/index.vue';
import GestaoBelemPage from '~/pages/gestao/belem/index.vue';
import GestaoCaqPage from '~/pages/gestao/caq/index.vue';
import GestaoCdaPage from '~/pages/gestao/cda/index.vue';
import CdiPage from '~/pages/gestao/cdi/index.vue';
import ConsolidacaoPage from '~/pages/gestao/consolidacao/index.vue';
import GestaoContrariaPage from '~/pages/gestao/contraria/index.vue';
import GestaoGcaPage from '~/pages/gestao/gca/index.vue';
import GestaoGcpjPage from '~/pages/gestao/gcpj/index.vue';
import GestaoGdpPage from '~/pages/gestao/gdp/index.vue';
import GestaoGePage from '~/pages/gestao/ge/index.vue';
import GgpPage from '~/pages/gestao/ggp/index.vue';
import GrcpPage from '~/pages/gestao/grcp/index.vue';
import GrvPage from '~/pages/gestao/grv/index.vue';
import GestaoPortoVelhoPage from '~/pages/gestao/porto-velho/index.vue';
import GestaoProtocoloPage from '~/pages/gestao/protocolo/index.vue';
import PubPage from '~/pages/gestao/pub/index.vue';
import GestaoTrabalhistaPage from '~/pages/gestao/trabalhista/index.vue';
import BuscarImportacoesPage from '~/pages/importacoes/buscar/index.vue';
import NotFoundPage from '~/pages/not-found/index.vue';
import BuscarPastasPage from '~/pages/pastas/buscar/index.vue';
import VisualizarPastaPage from '~/pages/pastas/visualizar/index.vue';
import BuscarPublicacoesPage from '~/pages/publicacoes/buscar/index.vue';
import BuscarRelatoriosPage from '~/pages/relatorios/buscar/index.vue';
import BuscarUsuariosPage from '~/pages/usuarios/buscar/index.vue';
import VisualizarUsuarioPage from '~/pages/usuarios/visualizar/index.vue';
import VisaoGeralPage from '~/pages/visao-geral/index.vue';
import NovasImportacoesPage from '~/pages/novas-importacoes/buscar/index.vue';
import RoboConsultaGcpjPage from '~/pages/robo-consulta-gcpj/index.vue';
import RoboEnviarDocumentosGcpjPage from '~/pages/robo-enviar-documentos-gcpj/index.vue';
import RoboAtualizacaoGcpj from '~/pages/robo-atualizacao-gcpj/index.vue';
import RobosPage from '~/pages/robos/index.vue';
import { useAuthStore } from '~/stores/auth';

const routes = [
  {
    component: AreaTrabalhoPage,
    meta: {
      requiresAuth: true,
    },
    name: 'AreaTrabalhoPage',
    path: '/',
  },

  {
    component: DevelopPage,
    name: 'DevelopPage',
    path: '/develop',
  },

  {
    component: AgendaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'AgendaPage',
    path: '/agenda',
  },

  {
    component: ConcluirFluxoEmLotePage,
    meta: {
      requiresAuth: true,
    },
    name: 'ConcluirFluxoEmLotePage',
    path: '/automatizacoes/concluir-fluxo-em-lote',
  },

  {
    component: GestaoProtocoloPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoProtocoloPage',
    path: '/gestao/protocolo',
  },
  {
    component: GestaoCdaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoCdaPage',
    path: '/gestao/cda',
  },
  {
    component: GestaoGcaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoGcaPage',
    path: '/gestao/gca',
  },
  {
    component: GestaoGcpjPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoGcpjPage',
    path: '/gestao/gcpj',
  },
  {
    component: GestaoGdpPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoGdpPage',
    path: '/gestao/gdp',
  },
  {
    component: GrcpPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GrcpPage',
    path: '/gestao/grcp',
  },
  {
    component: GestaoPortoVelhoPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoPortoVelhoPage',
    path: '/gestao/porto-velho',
  },
  {
    component: GrvPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GrvPage',
    path: '/gestao/grv',
  },
  {
    component: GestaoCaqPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoCaqPage',
    path: '/gestao/caq',
  },
  {
    component: GestaoGePage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoGePage',
    path: '/gestao/ge',
  },
  {
    component: GestaoBelemPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoBelemPage',
    path: '/gestao/belem',
  },
  {
    component: GestaoContrariaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoContrariaPage',
    path: '/gestao/contraria',
  },
  {
    component: GestaoTrabalhistaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GestaoTrabalhistaPage',
    path: '/gestao/trabalhista',
  },
  {
    component: ConsolidacaoPage,
    meta: {
      requiresAuth: true,
    },
    name: 'ConsolidacaoPage',
    path: '/gestao/consolidacao',
  },
  {
    component: CdiPage,
    meta: {
      requiresAuth: true,
    },
    name: 'CdiPage',
    path: '/gestao/cdi',
  },
  {
    component: GgpPage,
    meta: {
      requiresAuth: true,
    },
    name: 'GgpPage',
    path: '/gestao/ggp',
  },
  {
    component: PubPage,
    meta: {
      requiresAuth: true,
    },
    name: 'PubPage',
    path: '/gestao/pub',
  },

  {
    component: VisaoGeralPage,
    meta: {
      requiresAuth: true,
    },
    name: 'VisaoGeralPage',
    path: '/visao-geral',
  },

  {
    component: BuscarUsuariosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarUsuariosPage',
    path: '/usuarios',
  },
  {
    component: VisualizarUsuarioPage,
    meta: {
      requiresAuth: true,
    },
    name: 'VisualizarUsuarioPage',
    path: '/usuario/:codigo',
  },
  {
    component: LoginPage,
    meta: {
      guest: true,
    },
    name: 'LoginPage',
    path: '/login',
  },
  {
    component: SessaoExpiradaPage,
    meta: {
      guest: true,
    },
    name: 'SessaoExpiradaPage',
    path: '/sessao-expirada',
  },
  {
    component: CriarSenhaPage,
    meta: {
      guest: true,
    },
    name: 'CriarSenhaPage',
    path: '/usuarios/criar-senha',
  },
  {
    component: EsqueciSenhaPage,
    meta: {
      guest: true,
    },
    name: 'EsqueciSenhaPage',
    path: '/usuarios/esqueci-senha',
  },
  {
    component: ResetarSenhaPage,
    meta: {
      guest: true,
    },
    name: 'ResetarSenhaPage',
    path: '/usuarios/resetar-senha',
  },

  {
    component: BuscarContatosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarContatosPage',
    path: '/contatos',
  },
  {
    component: VisualizarContatoPage,
    meta: {
      requiresAuth: true,
    },
    name: 'VisualizarContatoPage',
    path: '/contato/:codigo',
  },

  {
    component: BuscarPastasPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarPastasPage',
    path: '/pastas',
  },
  {
    component: VisualizarPastaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'VisualizarPastaPage',
    path: '/pasta/:codigo',
  },

  {
    component: BuscarAgenciasPage,
    meta: {
      requiresAuth: true,
      title: 'Buscar agências',
    },
    name: 'BuscarAgenciasPage',
    path: '/agencias',
  },
  {
    component: VisualizarAgenciaPage,
    meta: {
      requiresAuth: true,
    },
    name: 'VisualizarAgenciaPage',
    path: '/agencia/:codigo',
  },

  {
    component: BuscarPublicacoesPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarPublicacoesPage',
    path: '/publicacoes',
  },

  {
    component: BuscarAndamentosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarAndamentosPage',
    path: '/andamentos',
  },

  {
    component: AtualizacoesGcpjsPage,
    meta: {
      requiresAuth: true,
    },
    name: 'AtualizacoesGcpjsPage',
    path: '/atualizacoes-gcpjs',
  },

  {
    component: BuscarRelatoriosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarRelatoriosPage',
    path: '/relatorios',
  },

  {
    component: BuscarImportacoesPage,
    meta: {
      requiresAuth: true,
    },
    name: 'BuscarImportacoesPage',
    path: '/importacoes',
  },

  {
    component: CompromissosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'CompromissosPage',
    path: '/compromissos',
  },

  {
    component: FluxosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'FluxosPage',
    path: '/fluxos',
  },

  {
    component: NovasImportacoesPage,
    meta: {
      requiresAuth: true,
    },
    name: 'NovasImportacoesPage',
    path: '/novas-importacoes',
  },

  {
    component: RoboConsultaGcpjPage,
    meta: {
      requiresAuth: true,
    },
    name: 'RoboConsultaGcpjPage',
    path: '/robo/consultar-gcpj',
  },

  {
    component: RoboEnviarDocumentosGcpjPage,
    meta: {
      requiresAuth: true,
    },
    name: 'RoboEnviarDocumentosGcpjPage',
    path: '/robo/enviar-documentos-gcpj',
  },

  {
    component: RoboAtualizacaoGcpj,
    meta: {
      requiresAuth: true,
    },
    name: 'RoboAtualizacaoGcpj',
    path: '/robo/atualizacao-gcpj',
  },

  {
    component: RobosPage,
    meta: {
      requiresAuth: true,
    },
    name: 'RobosPage',
    path: '/robos',
  },

  {
    component: NotFoundPage,
    path: '/:pathMatch(.*)',
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

let primeiroAcesso = true;
let controller = null;

router.beforeEach(async (to) =>
{
  if (controller !== null)
  {
    controller.abort();
  }

  controller = new AbortController();

  const $auth = useAuthStore();

  if (primeiroAcesso)
  {
    primeiroAcesso = false;
    await $auth.refreshToken();
    if ($auth.accessToken !== null)
    {
      $auth.iniciarTimeoutRefreshToken();
    }
  }

  if (to.meta.requiresAuth && $auth.accessToken !== null)
  {
    return true;
  }

  if (to.meta.requiresAuth && $auth.accessToken === null)
  {
    return {
      path: '/login',
    };
  }

  if (to.meta.guest && $auth.accessToken !== null)
  {
    return {
      path: '/',
    };
  }

  return true;
});

router.afterEach((to, _, __) =>
{
  document.title = to.meta.title || 'Edson Rosas Advocacia';
});

axios.interceptors.request.use(async (config) =>
{
  const $auth = useAuthStore();
  // console.log(
  //   `[interceptors:request] ${config.url} [${
  //     config.getAccessToken ? 'withAccessToken' : ''
  //   }] `
  // );

  if (controller !== null)
  {
    config.signal = controller.signal;
  }

  if (!config.getAccessToken)
  {
    return config;
  }

  config.headers.Authorization = `Bearer ${$auth.accessToken}`;

  return config;
});
