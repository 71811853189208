<script setup lang="ts">
import { SaveOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import type { NotificationArgsProps } from 'ant-design-vue/es/notification';
import { isEqual } from 'lodash-es';
import { reactive, ref, toRaw, watch } from 'vue';
import { useJobCommand } from '~/composables/useJobCommand';
import { TipoJobEnum } from '~/enums/tipoJobEnum';

export type PreSubmit = (data: any) => Promise<boolean>;
export type PostSubmit = (status: any, data: any) => Promise<void>;
export type NotificationOnSubmitSuccessFn = (data: any, notification: any) => NotificationArgsProps;

interface Props
{
  command: string
  jobType?: TipoJobEnum
  initialModel?: object
  notificationOnSubmitSuccess?: NotificationArgsProps
  notificationOnSubmitSuccessFn?: NotificationOnSubmitSuccessFn
  preSubmit?: PreSubmit
  postSubmit?: PostSubmit
  showJobProgress?: boolean
  disabledSubmitButton?: boolean
  submitButtonBlock?: boolean
  submitButtonText?: string
  submitButtonLoadingText?: string
}

const props = withDefaults(defineProps<Props>(), {
  jobType: TipoJobEnum.Command,
  submitButtonBlock: true,
  submitButtonText: 'Salvar',
});

const emit = defineEmits(['success', 'error']);

const { status, jobCommand, data, err, progressData } = useJobCommand();
const commandStatus = status;
const command = jobCommand;
const commandData = data;
const commandErr = err;
const formRef = ref<any>();
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
const originalModel: any = { ...props.initialModel };
const model = reactive<any>(props.initialModel ?? {});
let resultModel: any = {};
const submitButtonText = ref<string>(props.submitButtonText);

async function submit()
{
  await formRef.value.validate();

  submitStatusRef.value = 'loading';
  submitButtonText.value = props.submitButtonLoadingText ?? props.submitButtonText;

  if (props.preSubmit)
  {
    const doContinue = await props.preSubmit(model);

    if (!doContinue)
    {
      submitStatusRef.value = null;
      submitButtonText.value = props.submitButtonText;
      return;
    }
  }
  resultModel = { ...toRaw(model) };
  const commandData: any = {};
  Object.keys(resultModel).forEach((key: string) =>
  {
    if (!(key in originalModel))
    {
      commandData[key] = {
        changed: true,
        value: resultModel[key],
      };
    }
    else
    {
      const changed = !isEqual(resultModel[key], originalModel[key]);
      commandData[key] = {
        changed,
        value: resultModel[key],
      };
    }
  });

  await command(props.command, commandData, props.jobType);
}

watch(commandStatus, async (val: string) =>
{
  if (val === 'success')
  {
    submitButtonText.value = props.submitButtonText;
    submitStatusRef.value = 'success';
    if (props.notificationOnSubmitSuccess)
    {
      notification.success(props.notificationOnSubmitSuccess);
    }
    if (props.notificationOnSubmitSuccessFn)
    {
      notification.success(props.notificationOnSubmitSuccessFn(commandData.value, notification));
    }
  }
  if (val === 'error')
  {
    if (commandErr.value?.resultado?.status === 400)
    {
      notification.error({
        message: commandErr.value.resultado.message,
        duration: 10,
      });
    }
    else
    {
      notification.error({
        message: 'Tivemos um erro inesperado',
        description: 'Favor entrar em contato com o suporte',
        duration: 10,
      });
    }
    submitButtonText.value = props.submitButtonText;
    submitStatusRef.value = 'error';
  }

  if ((val === 'success' || val === 'error') && props.postSubmit)
  {
    await props.postSubmit(val, model);
  }

  if (val === 'success')
  {
    emit('success');
  }

  if (val === 'error')
  {
    emit('error');
  }
});

defineExpose({
  model,
});
</script>

<template>
  <a-form
    v-if="commandStatus !== 'success' || props.notificationOnSubmitSuccess || props.notificationOnSubmitSuccessFn"
    ref="formRef"
    :model="model"
    name="basic"
    autocomplete="off"
    layout="vertical"
  >
    <slot
      name="form"
      :model="model"
    />
    <a-form-item>
      <slot
        name="submitButton"
        :command-status="commandStatus"
        :submit="submit"
      >
        <a-button
          :block="props.submitButtonBlock"
          type="primary"
          :loading="commandStatus === 'loading'"
          size="large"
          :disabled="props.disabledSubmitButton"
          @click.prevent="submit"
        >
          <template #icon>
            <slot name="submitButtonIcon">
              <SaveOutlined />
            </slot>
          </template>
          {{ submitButtonText }}
        </a-button>
      </slot>
    </a-form-item>
  </a-form>

  <slot
    v-if="!props.notificationOnSubmitSuccess && !props.notificationOnSubmitSuccessFn && commandStatus === 'success'"
    name="submitSuccess"
    :data="commandData"
  />

  <slot
    v-if="props.showJobProgress"
    name="jobProgress"
    :data="progressData"
    :submit-status="submitStatusRef"
  >
    <a-progress
      v-if="submitStatusRef === 'loading' || submitStatusRef === 'success' && commandStatus === 'loading'"
      :percent="progressData?.resultado?.progress ?? 0.0"
    />
  </slot>

  <slot
    name="extraBottom"
    :submit-status="submitStatusRef"
  />
</template>
