<script setup lang="ts">
import {
  CloudUploadOutlined,
  DownOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue';
import { ref } from 'vue';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';
import { useDrawerStore } from '~/stores/drawer';

const drawerStore = useDrawerStore();
const compromissoGqlDatatableRef = ref<any>(null);

const compromissoTiposImportacao: number[] = [159];

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

async function importarMenuClick({ key }: any)
{
  drawerStore.push2({
    componentName: key,
    params: {},
    width: 1000,
    onClose: async () =>
    {
      await compromissoGqlDatatableRef.value?.reload();
    },
  });
}
</script>

<template>
  <Page menu="novas-importacoes">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/novas-importacoes">
                    Importações
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar importações
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container">
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="importarMenuClick">
                      <a-menu-item key="ImportarNovasPastasPlanilhaBradescoForm">
                        Importar novas pastas pela planilha Bradesco
                      </a-menu-item>
                    </a-menu>
                  </template>
                  <a-button size="large">
                    <template #icon>
                      <CloudUploadOutlined />
                    </template>
                    Importar
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
          <!-- /Page header -->
          <CompromissoGqlDatatable
            ref="compromissoGqlDatatableRef"
            :show-status-search-bar="false"
            :show-filter="false"
            :columns="colunas"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  in: compromissoTiposImportacao,
                },
              },
            }"
          />
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
