<script setup>
import { useCriarJob } from '~/composables/jobs/useCriarJob';
import { FileExcelOutlined, FileZipOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { ref, watch } from 'vue';

const emit = defineEmits(['close']);

const { runAsync: criarJob, status: criarJobStatus } = useCriarJob();

const arquivoXls = ref([]);
const xlsUploader = ref(null);
const xlsStatus = ref(null);
const xlsError = ref(false);

const xlsBeforeUpload = (file) => {
  xlsError.value = false;

  const ext = file.name.split('.').pop().toLowerCase();
  if (ext !== 'xls' && ext !== 'xlsx') {
    xlsError.value = true;
    return false;
  }
  return true;
};

const importar = async () => {
  if (arquivoXls.value.length === 0) {
    notification.error({
      duration: 5,
      message: 'A planilha excel é obrigatória para a importação',
    });
    return;
  }

  await xlsUploader.value.upload();

  if (xlsStatus.value === 'cancelled') {
    xlsStatus.value = null;
    return;
  }

  await criarJob({
    parametros: {
      arquivoXls: arquivoXls.value[0],
    },
    tipo: 46,
  });
};

watch(
  () => criarJobStatus.value,
  (val) => {
    if (val === 'error') {
      notification.error({
        duration: 5,
        message: 'Tivemos um problema inesperado ao tentar importar',
      });
    }
  }
);

const activeKey = ref(null);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle :closable="false" @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          IMPORTAR PLANILHA PARA ALTERAÇÃO DE PASTA</span
        >
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-row>
        <a-col :span="24">
          <a-result
            v-if="criarJobStatus === 'success'"
            status="success"
            title="A sua importação está em processamento"
            sub-title="Você pode verificar o resultado na página de importações"
            style="margin-top: 24px"
          >
            <template #extra>
              <a-button @click="emit('close')"> Fechar </a-button>
            </template>
          </a-result>
          <a-row v-else :gutter="24">
            <a-col :span="24">
              <a-row style="margin-top: 24px">
                <a-col span="24">
                  <a-alert
                    v-if="xlsError"
                    message="O arquivo deve possuir o formato .XLS ou .XLSX"
                    type="error"
                    style="margin-bottom: 24px"
                    closable
                    :after-close="() => (xlsError = false)"
                  />

                  <a-collapse v-model:activeKey="activeKey">
                    <a-collapse-panel
                      key="1"
                      header="GUIA DE COMO PREENCHER OS CAMPOS DA PLANILHA"
                      style="margin-bottom: 24px"
                    >
                      <div>
                        <h4>Codigo (varchar) - obrigatório</h4>
                        <p>
                          Código da pasta a ser alterada. É o único campo
                          obrigatório
                        </p>
                      </div>
                      <div>
                        <h4>Nome (varchar)</h4>
                        <p>
                          Esse campo for informado não pode ser vazio e nem
                          repetido
                        </p>
                      </div>
                      <div>
                        <h4>PastaPrincipalCodigo (varchar)</h4>
                        <p>Código da pasta principal</p>
                      </div>
                      <div>
                        <h4>Tipo (int)</h4>
                        <p>Esse campo for informado ele não pode ser vazio</p>
                        <ul>
                          <li>1 - Distribuído</li>
                          <li>2 - Redistribuído</li>
                          <li>3 - Trabalhista</li>
                          <li>4 - Contrária</li>
                          <li>5 - Importada Novajus</li>
                          <li>6 - Avulso</li>
                        </ul>
                      </div>
                      <div>
                        <h4>TipoProcesso (int)</h4>
                        <p>Esse campo for informado ele não pode ser vazio</p>
                        <ul>
                          <li>1 - Processo</li>
                          <li>2 - Recurso</li>
                          <li>3 - Incidente</li>
                          <li>4 - Defesa</li>
                        </ul>
                      </div>
                      <div>
                        <h4>Status (int)</h4>
                        <p>Esse campo for informado ele não pode ser vazio</p>
                        <ul>
                          <li>1 - Ativo</li>
                          <li>4 - Suspenso</li>
                          <li>7 - Baixado</li>
                          <li>8 - Arquivado</li>
                        </ul>
                      </div>
                      <div>
                        <h4>Empresa (int)</h4>
                        <ul>
                          <li>0 - Banco Bradesco</li>
                          <li>1 - Cartões</li>
                          <li>2 - BBF Veículos</li>
                          <li>3 - BBF Consignado</li>
                          <li>4 - Consórcios</li>
                        </ul>
                      </div>
                      <div>
                        <h4>ResponsavelProcessualCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>ResponsavelNegocialCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>GerenteNegocialResponsavelCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>ValorEnvolvido (decimal)</h4>
                      </div>
                      <div>
                        <h4>DataRemessa (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>ClientePrincipalCodigo (varchar)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ClientePrincipalPapel
                        </p>
                      </div>
                      <div>
                        <h4>ClientePrincipalPapel (varchar)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ClientePrincipalCodigo
                        </p>
                      </div>
                      <div>
                        <h4>ContrarioPrincipalCodigo (varchar)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ContrarioPrincipalPapel
                        </p>
                      </div>
                      <div>
                        <h4>ContrarioPrincipalPapel (varchar)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ContrarioPrincipalCodigo
                        </p>
                      </div>
                      <div>
                        <h4>Gcpj (varchar)</h4>
                      </div>
                      <div>
                        <h4>GcpjAtivo (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>Dejur (varchar)</h4>
                      </div>
                      <div>
                        <h4>EmpresaBanco (varchar)</h4>
                      </div>
                      <div>
                        <h4>AgenciaCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>Conta (varchar)</h4>
                      </div>
                      <div>
                        <h4>ContaFicticia (varchar)</h4>
                      </div>
                      <div>
                        <h4>EstadoCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>CidadeCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>DataFatoGerador (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>Garantias (varchar)</h4>
                      </div>
                      <div>
                        <h4>Avalistas (varchar)</h4>
                      </div>
                      <div>
                        <h4>AdvogadoBanco (varchar)</h4>
                      </div>
                      <div>
                        <h4>ValorContrato (decimal)</h4>
                      </div>
                      <div>
                        <h4>InformacaoSobreObito (varchar)</h4>
                      </div>
                      <div>
                        <h4>DataDeclaracaoIrrecuperabilidade (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataEncerramento (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>MotivoEncerramento (varchar)</h4>
                      </div>
                      <div>
                        <h4>DepositarioFielCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>NumeroCnj (varchar)</h4>
                      </div>
                      <div>
                        <h4>NumeroAntigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>ValorCausa (decimal)</h4>
                      </div>
                      <div>
                        <h4>Natureza (int)</h4>
                        <ul>
                          <li>1 - Ambiental</li>
                          <li>2 - Cível</li>
                          <li>3 - Constitucional</li>
                          <li>4 - Penal</li>
                          <li>5 - Previdenciária</li>
                          <li>6 - Trabalhista</li>
                          <li>7 - Tributária</li>
                          <li>8 - Administrativo</li>
                          <li>9 - Criminal</li>
                          <li>10 - Indenização</li>
                        </ul>
                      </div>
                      <div>
                        <h4>Procedimento (int)</h4>
                        <ul>
                          <li>1 - Especial</li>
                          <li>2 - Sumário</li>
                          <li>3 - Sumarissimo</li>
                          <li>4 - Administrativo</li>
                          <li>5 - Ordinário</li>
                        </ul>
                      </div>
                      <div>
                        <h4>AcaoCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>OrgaoCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>JusticaCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>InstanciaCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>ClasseCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>AssuntoCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>Vara (varchar)</h4>
                      </div>
                      <div>
                        <h4>ComarcaCodigo (int)</h4>
                      </div>
                      <div>
                        <h4>AtualizacaoValorCausa (decimal)</h4>
                      </div>
                      <div>
                        <h4>DataAtualizacaoValorCausa (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>MotivoNaoAjuizamento (int)</h4>
                        <ul>
                          <li>1 - Acordo em andamento</li>
                          <li>2 - Ajuizamento sobrestado</li>
                          <li>3 - Apto para ajuizamento</li>
                          <li>4 - Cálculo pendente</li>
                          <li>5 - Documento pendente</li>
                          <li>
                            6 - Gcpj ajuizado. Aguardando atualização da caixa
                            infoprocessuais
                          </li>
                          <li>7 - Gcpj não está na base do escritório</li>
                          <li>8 - Notificação pendente</li>
                          <li>9 - Protesto pendente</li>
                          <li>10 - Solicitado encerramento</li>
                          <li>11 - Tela pendente</li>
                        </ul>
                      </div>
                      <div>
                        <h4>DataAjuizamento (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>CustasProtesto (decimal)</h4>
                      </div>
                      <div>
                        <h4>CustasIniciais (decimal)</h4>
                      </div>
                      <div>
                        <h4>CustasOficialJustica (decimal)</h4>
                      </div>
                      <div>
                        <h4>DataHabilitacao (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>ProcuracaoFls (varchar)</h4>
                      </div>
                      <div>
                        <h4>ArquivamentoProvisorio (varchar)</h4>
                      </div>
                      <div>
                        <h4>DataSuspensaoAusenciaBensPenhoraveis (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataLiminar (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataCitacao (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>SolicitacaoConversaoExecucao (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>DataSolicitacaoConversaoExecucao (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataConversaoExecucao (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataSentenca (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataBaixa (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataResultado (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>MotivoResultado (varchar)</h4>
                      </div>
                      <div>
                        <h4>Resultado (varchar)</h4>
                      </div>
                      <div>
                        <h4>Revelia (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>FundamentoSentenca (varchar)</h4>
                      </div>
                      <div>
                        <h4>DataApreensao (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>AptoApreensao (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>Inviavel (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>Placa (varchar)</h4>
                      </div>
                      <div>
                        <h4>ChassiRenavan (varchar)</h4>
                      </div>
                      <div>
                        <h4>TeveAcordo (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>ResponsavelAcordoCodigo (varchar)</h4>
                      </div>
                      <div>
                        <h4>TipoAcordo (varchar)</h4>
                      </div>
                      <div>
                        <h4>DataAcordo (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>QuantidadeParcelas (varchar)</h4>
                      </div>
                      <div>
                        <h4>ValorTotalAcordo (decimal)</h4>
                      </div>
                      <div>
                        <h4>ValorEntradaAcordo (decimal)</h4>
                      </div>
                      <div>
                        <h4>ValorFinanciadoAcordo (decimal)</h4>
                      </div>
                      <div>
                        <h4>TaxaJurosAcordo (decimal)</h4>
                      </div>
                      <div>
                        <h4>DataVencimentoPrimeiraParcelaAcordo (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>DataVencimentoUltimaParcelaAcordo (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>AcordoEstaSendoCumprido (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>DataDescumprimentoAcordo (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>HistoricoDescumprimento (varchar)</h4>
                      </div>
                      <div>
                        <h4>TeveContabilizacaoAcordo (boleano)</h4>
                        <p>true ou false</p>
                      </div>
                      <div>
                        <h4>Volumetria (varchar)</h4>
                      </div>
                      <div>
                        <h4>Plano (int)</h4>
                      </div>
                      <div>
                        <h4>ValorParcelaContratoOriginal (decimal)</h4>
                      </div>
                      <div>
                        <h4>DataVencimentoInicial (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>MotivoEmenda (varchar)</h4>
                      </div>
                      <div>
                        <h4>LiminarDeferida (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - AGUARDANDO</li>
                          <li>3 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>DataRespostaLiminar (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>MotivoNaoDeferimentoLiminar (varchar)</h4>
                      </div>
                      <div>
                        <h4>AgravoInstrumento (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>MandadoApreensaoExpedido (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - AGUARDANDO</li>
                          <li>3 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>DataMandadoApreensaoExpedido (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>LiberacaoVendaVeiculo (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>DataLiberacaoVendaVeiculo (varchar)</h4>
                        <p>Exemplo de data: 02/02/2024</p>
                      </div>
                      <div>
                        <h4>AcaoMandadoNegativo (varchar)</h4>
                      </div>
                      <div>
                        <h4>BemLocalizado (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - AGUARDANDO</li>
                          <li>3 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>AcaoBemNaoLocalizado (varchar)</h4>
                      </div>
                      <div>
                        <h4>UltimoEvento (varchar)</h4>
                      </div>
                      <div>
                        <h4>Deposito (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>ValorDeposito (decimal)</h4>
                      </div>
                      <div>
                        <h4>ValorLevantadoPeloBanco (decimal)</h4>
                      </div>
                      <div>
                        <h4>ValorLevantadoPelaParte (decimal)</h4>
                      </div>
                      <div>
                        <h4>Penhora (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                          <li>2 - NÃO APLICÁVEL</li>
                        </ul>
                      </div>
                      <div>
                        <h4>TipoPenhora (varchar)</h4>
                      </div>
                      <div>
                        <h4>ValorPenhora (decimal)</h4>
                      </div>
                      <div>
                        <h4>Substabelecido (int)</h4>
                        <ul>
                          <li>0 - NÃO</li>
                          <li>1 - SIM</li>
                        </ul>
                      </div>
                      <div>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalDescricao (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                          (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar o campo
                          ContrarioPrincipalEnderecoPrincipalDescricao
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalNumero (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalComplemento
                          (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalBairro (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalCep (varchar)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalEstadoCodigo (int)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>
                          ContrarioPrincipalEnderecoPrincipalCidadeCodigo (int)
                        </h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos ContrarioPrincipalEnderecoPrincipalDescricao e
                          ContrarioPrincipalEnderecoPrincipalLogradouro
                        </p>
                        <h4>CarteiraContratoCarteiraCodigo (string)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos CarteiraContratoContrato,
                          CarteiraContratoVigente e CarteiraContratoOriginal
                        </p>
                        <h4>CarteiraContratoContrato (string)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos CarteiraContratoCarteiraCodigo,
                          CarteiraContratoVigente e CarteiraContratoOriginal
                        </p>
                        <h4>CarteiraContratoVigente (true ou false)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos CarteiraContratoCarteiraCodigo,
                          CarteiraContratoContrato e CarteiraContratoOriginal
                        </p>
                        <h4>CarteiraContratoOriginal (true ou false)</h4>
                        <p>
                          Ao informar esse campo, é obrigatório informar os
                          campos CarteiraContratoCarteiraCodigo,
                          CarteiraContratoContrato e CarteiraContratoVigente
                        </p>
                      </div>
                    </a-collapse-panel>
                  </a-collapse>

                  <Uploader
                    ref="xlsUploader"
                    v-model:fileList="arquivoXls"
                    :only-one-file="true"
                    :before-upload="xlsBeforeUpload"
                    @start="() => (xlsStatus = 'uploading')"
                    @end="() => (xlsStatus = 'done')"
                  >
                    <template #description>
                      <p class="ant-upload-drag-icon">
                        <FileExcelOutlined />
                      </p>
                      <p class="ant-upload-text">
                        Clique ou arraste o arquivo EXCEL
                      </p>
                      <p class="ant-upload-hint">
                        Você pode baixar o padrão do EXCEL no link acima
                      </p>
                    </template>
                  </Uploader>
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-button
                  :loading="
                    xlsStatus === 'uploading' || criarJobStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click.prevent="importar"
                >
                  Importar
                </a-button>
                <a-button
                  :disabled="
                    xlsStatus === 'uploading' || criarJobStatus === 'loading'
                  "
                  @click="() => emit('close')"
                >
                  Fechar
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
