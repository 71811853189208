<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { formatDate, formatDinheiro, getGoogleDriveUrl } from '~/utils';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

enum TipoModeloAjuizamentoEnum
{
  ExecucaoSemGarantia = 1,
  ExecucaoComGarantia = 2,
  ExecucaoComAvalista = 3,
  AcaoMonitoriaAberturaConta = 4,
  AcaoMonitoriaAberturaContaAssinadoDigitalmente = 5,
  AcaoCobranca = 6,
  BuscaApreensaoArPositivo = 7,
  BuscaApreensaoArNegativoSemInstrumentoProtesto = 8,
}

const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '25%' }" bordered>
    <a-descriptions-item label="Modelo de ajuizamento" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.tipoModeloAjuizamentoText }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Ação" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.acaoNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Contrário principal" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.contrarioPrincipalNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Papel do contrário principal" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.papelContrarioPrincipalText }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Litisconsortes" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.litisconsortesText }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Empresa" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.empresaText }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Estado" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.estadoNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Justiça" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.justicaNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Comarca" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.comarcaNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Agência" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.agenciaNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Conta" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.conta }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Valor causa" :span="3">
      <span style="text-transform: uppercase;">{{ formatDinheiro(props.compromisso.data.valorCausa) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Carteira" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.carteiraNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Contrato" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.contrato }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="[
        TipoModeloAjuizamentoEnum.ExecucaoComGarantia,
        TipoModeloAjuizamentoEnum.BuscaApreensaoArPositivo,
        TipoModeloAjuizamentoEnum.BuscaApreensaoArNegativoSemInstrumentoProtesto,
      ].includes(props.compromisso.data.tipoModeloAjuizamento)" label="Garantias" :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.garantias }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="[
        TipoModeloAjuizamentoEnum.ExecucaoComAvalista,
      ].includes(props.compromisso.data.tipoModeloAjuizamento)" label="Avalistas" :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.avalistasText }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="[
        TipoModeloAjuizamentoEnum.BuscaApreensaoArNegativoSemInstrumentoProtesto,
      ].includes(props.compromisso.data.tipoModeloAjuizamento)" label="Avalistas" :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.depositarioFielNome }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Valor do contrato" :span="3">
      <span style="text-transform: uppercase;">{{ formatDinheiro(props.compromisso.data.valorContrato) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Valor da parcela do contrato original" :span="3">
      <span style="text-transform: uppercase;">{{ formatDinheiro(props.compromisso.data.valorParcelaContratoOriginal) }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="[
        TipoModeloAjuizamentoEnum.AcaoCobranca,
      ].includes(props.compromisso.data.tipoModeloAjuizamento)" label="Número da tela de operação" :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.telaOperacao }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Plano" :span="3">
      <span style="text-transform: uppercase;">{{ props.compromisso.data.plano }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Data de celebração do contrato original" :span="3">
      <span style="text-transform: uppercase;">{{ formatDate(props.compromisso.data.dataCelebracaoContratoOriginal, authStore.timeZone) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Data do vencimento inicial da parcela do contrato original (Data fato gerador)" :span="3">
      <span style="text-transform: uppercase;">{{ formatDate(props.compromisso.data.dataVencimentoInicialParcelaContratoOriginal, authStore.timeZone) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Data do vencimento final da parcela do contrato original" :span="3">
      <span style="text-transform: uppercase;">{{ formatDate(props.compromisso.data.dataVencimentoFinalParcelaContratoOriginal, authStore.timeZone) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Data da primeira parcela vencida" :span="3">
      <span style="text-transform: uppercase;">{{ formatDate(props.compromisso.data.dataPrimeiraParcelaVencida, authStore.timeZone) }}</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="[
        TipoModeloAjuizamentoEnum.BuscaApreensaoArPositivo,
      ].includes(props.compromisso.data.tipoModeloAjuizamento)" label="Data de atualização do valor da causa" :span="3"
    >
      <span style="text-transform: uppercase;">{{ formatDate(props.compromisso.data.dataAtualizacaoValorCausa, authStore.timeZone) }}</span>
    </a-descriptions-item>

    <a-descriptions-item label="Arquivo gerado" :span="3">
      <a
        target="_blank"
        :href="getGoogleDriveUrl(props.compromisso.data.arquivoAjuizamentoId)"
      >CLIQUE PARA BAIXAR</a>
    </a-descriptions-item>

    <a-descriptions-item label="Arquivos" :span="3">
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a target="_blank" :href="getGoogleDriveUrl(arquivo.fileServiceId)">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
