query PESQUISA_BENS_CDI_DATATABLE(
    $tiposServicosCdi: [Int!]
    $where: FluxoModelFilterInput
    $order: [FluxoModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: fluxosOffsetPagination(
        tiposServicosCdi: $tiposServicosCdi
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            tipo
            tipoText
            status
            statusText
            dataInicio
            dataInicioText
            dataConclusao
            dataConclusaoText
            iniciadoPor {
                nome
            }
            pasta {
                codigo
                nome
            }
            compromissos {
                tipo
                status
                statusText
                dataInicio
                dataInicioText
                data
                responsavel {
                    nome
                }
            }
        }
        totalCount
    }
}
