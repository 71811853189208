<script setup lang="ts">
import { ref } from 'vue';
import totalCompromissosGqlQuery from '~/graphql/totalCompromissos.graphql';
import { useDrawerStore } from '~/stores/drawer.js';

interface Props
{
  variables?: any
  title: string
  datatableShowStatusSearchBar: boolean
}

const props = withDefaults(defineProps<Props>(), {
  datatableShowStatusSearchBar: true,
});

const gqlStatsCardRef = ref<any>(null);

const drawerStore = useDrawerStore();

function openDrawer()
{
  drawerStore.push2({
    componentName: 'CompromissoGqlDatatable',
    onClose: async () =>
    {
      await gqlStatsCardRef.value?.reload();
    },
    params: {
      enableRowSelection: true,
      ...{
        variables: { ...props.variables, ...{ order: [{ dataInicio: 'DESC' }] } },
        keepHistory: false,
        showStatusSearchBar: props.datatableShowStatusSearchBar,
      },
    },
    title: props.title,
    wrapperStyle: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  });
}
</script>

<template>
  <GqlStatsCard
    ref="gqlStatsCardRef"
    :title="props.title"
    :query="totalCompromissosGqlQuery"
    :variables="props.variables"
    :clickable="true"
    @click="openDrawer"
  />
</template>
