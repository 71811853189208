<script setup>
import { formatCnj, isNilOrEmpty, onlyNumbers } from '~/utils';

const props = defineProps({
  columns: {
    default: () => [
      {
        key: 'nome',
        title: 'NOME',
      },
      {
        key: 'status',
        title: 'STATUS',
      },
      {
        key: 'acao',
        title: 'AÇÃO',
      },
      {
        key: 'envolvidos',
        title: 'ENVOLVIDOS',
      },
      {
        key: 'responsavel',
        title: 'RESPONSÁVEL',
      },
      {
        key: 'jurisdicao',
        title: 'JURISDIÇÃO',
      },
    ],
    type: Array,
  },
  disabledFilters: {
    default: () => [],
    type: Array,
  },
  graphqlVariables: {
    default: () => {},
    type: Object,
  },
});

const filters = [];

if (!props.disabledFilters.find((x) => x === 'nome')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais nomes',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => x.trim());

      return {
        where: {
          and: [
            {
              nome: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'nome',
    label: 'Nome',
    queryKey: 'n',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'gcpj')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais GCPJs',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => x.trim());

      return {
        where: {
          and: [
            {
              gcpj: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'gcpj',
    label: 'GCPJ',
    queryKey: 'g',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'numeroCnj')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais números CNJs',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => onlyNumbers(x.trim()));

      return {
        where: {
          and: [
            {
              numeroCnj: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'numeroCnj',
    label: 'Número CNJ',
    queryKey: 'nc',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'numeroAntigo')) {
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais números antigos',
      size: 'large',
    },
    graphqlQuery: (val) => {
      let splittedValue = val.split(',').map((x) => x.trim());

      return {
        where: {
          and: [
            {
              numeroAntigo: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'numeroAntigo',
    label: 'Número antigo',
    queryKey: 'na',
    type: 'text',
  });
}

if (!props.disabledFilters.find((x) => x === 'tiposProcessos')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum tipo encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais tipos',
      query: 'TIPOS_PROCESSOS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              tipoProcesso: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'tiposProcessos',
    label: 'Tipos do processo',
    queryKey: 'tp',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'responsaveisProcessuais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum responsável encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais responsáveis',
      query: 'USUARIO_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const usuarioCodigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              responsaveis: {
                some: {
                  and: [
                    {
                      usuarioCodigo: {
                        in: usuarioCodigos,
                      },
                    },
                    {
                      tipo: {
                        eq: 1,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      };
    },
    key: 'responsaveisProcessuais',
    label: 'Responsável processual',
    queryKey: 'rp',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'responsaveisNegociais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum responsável encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais responsáveis',
      query: 'USUARIO_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const usuarioCodigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              responsaveis: {
                some: {
                  and: [
                    {
                      usuarioCodigo: {
                        in: usuarioCodigos,
                      },
                    },
                    {
                      tipo: {
                        eq: 3,
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      };
    },
    key: 'responsaveisNegociais',
    label: 'Responsável negocial',
    queryKey: 'rn',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'responsaveisAcordos')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum responsável encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais responsáveis',
      query: 'USUARIO_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const usuarioCodigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              responsavelAcordoCodigo: {
                in: usuarioCodigos,
              },
            },
          ],
        },
      };
    },
    key: 'responsaveisAcordos',
    label: 'Responsável acordo',
    queryKey: 'ra',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'envolvidos')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum envolvido encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais envolvidos',
      query: 'PessoasAsSelectItemQuery',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const envolvidoCodigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              envolvidos: {
                some: {
                  pessoaCodigo: {
                    in: envolvidoCodigos,
                  },
                },
              },
            },
          ],
        },
      };
    },
    key: 'envolvidos',
    label: 'Envolvidos',
    queryKey: 'e',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'agencias')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma agência encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais agências',
      query: 'AgenciasAsSelectItem',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              agencia2Codigo: {
                in: codigos,
              },
            },
          ],
        },
      };
    },
    key: 'agencias',
    label: 'Agências',
    queryKey: 'a',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'agenciasRegionais')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma regional encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais regional',
      query: 'TIPOS_AGENCIA_REGIONAIS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              agencia2: {
                regional: {
                  in: codigos,
                },
              },
            },
          ],
        },
      };
    },
    key: 'agenciasRegionais',
    label: 'Agência / Regionais',
    queryKey: 'ar',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'agenciasPortes')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhum porte encontrado',
      mode: 'multiple',
      placeholder: 'Selecione um ou mais portes',
      query: 'TIPOS_AGENCIA_PORTES_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              agencia2: {
                porte: {
                  in: codigos,
                },
              },
            },
          ],
        },
      };
    },
    key: 'agenciasPortes',
    label: 'Agência / Portes',
    queryKey: 'ap',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'agenciasGerencias')) {
  filters.push({
    componentProps: {
      allowClear: true,
      emptyMessage: 'Nenhuma gerência encontrada',
      mode: 'multiple',
      placeholder: 'Selecione uma ou mais gerências',
      query: 'GERENCIAS_REGIONAIS_FOR_SELECT',
      size: 'large',
    },
    graphqlQuery: (val) => {
      const codigos = val.map((x) => x.value);
      return {
        where: {
          and: [
            {
              agencia2: {
                gerenciaRegionalCodigo: {
                  in: codigos,
                },
              },
            },
          ],
        },
      };
    },
    key: 'agenciasGerencias',
    label: 'Agência / Gerências',
    queryKey: 'ag',
    type: 'graphql-select',
  });
}

if (!props.disabledFilters.find((x) => x === 'dataAjuizamento')) {
  filters.push({
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Data ajuizamento inicial', 'Data ajuizamento final'],
      size: 'large',
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
    graphqlQuery: (val) => {
      return {
        where: {
          and: [
            {
              dataDistribuicao: {
                gte: `${val[0]} 00:00:00`,
                lte: `${val[1]} 23:59:59`,
              },
            },
          ],
        },
      };
    },
    key: 'dataAjuizamento',
    label: 'Data de ajuizamento',
    queryKey: 'da',
    type: 'date',
  });
}

if (!props.disabledFilters.find((x) => x === 'dataCadastro')) {
  filters.push({
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Data cadastro inicial', 'Data cadastro final'],
      size: 'large',
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
    graphqlQuery: (val) => {
      return {
        where: {
          and: [
            {
              dataCriacao: {
                gte: `${val[0]} 00:00:00`,
                lte: `${val[1]} 23:59:59`,
              },
            },
          ],
        },
      };
    },
    key: 'dataCadastro',
    label: 'Data de cadastro',
    queryKey: 'dc',
    type: 'date',
  });
}

if (!props.disabledFilters.find((x) => x === 'valorContrato')) {
  filters.push({
    componentProps: {
      size: 'large',
    },
    graphqlQuery: (val) => {
      const o = { where: { and: [] } };

      if (val[0]) {
        o.where.and.push({ valorContrato: { gte: val[0] } });
      }

      if (val[1]) {
        o.where.and.push({ valorContrato: { lte: val[1] } });
      }

      return o;
    },
    key: 'valorContrato',
    label: 'Valor contrato',
    queryKey: 'vc',
    type: 'money',
  });
}
</script>
<template>
  <GraphqlDatatable
    query="PASTA_DATA_TABLE"
    :keep-history="false"
    :columns="props.columns"
    row-key="codigo"
    :filters="filters"
    :text-search="{
      placeholder:
        'Busca rápida de pastas por nome, GCPJ, número CNJ, número antigo, status, tipo da ação, cliente principal, contrário principal, responsável processual ou negocial, nome da agência, regional e jurisdição',
    }"
    :graphql-variables="props.graphqlVariables"
  >
    <template #bodyCell="{ column, record }">
      <slot name="bodyCell" :column="column" :record="record">
        <template v-if="column.key === 'nome'">
          <a-row>
            <a-col :span="24">
              <a-row>
                <a-col>
                  <a-tooltip>
                    <template #title> Ir para página da pasta </template>
                    <router-link :to="`/pasta/${record.codigo}`">
                      {{ record.nome }}
                    </router-link>
                  </a-tooltip>
                </a-col>
              </a-row>
              <a-row>
                <a-col>
                  <div style="margin-top: 4px">
                    <a-typography-text
                      v-if="record.numeroCnj"
                      style="color: #7d7c7c"
                      copyable
                    >
                      {{ formatCnj(record.numeroCnj) }}
                    </a-typography-text>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </template>
        <template v-if="column.key === 'status'">
          {{ record.statusText?.toUpperCase() }}
        </template>
        <template v-if="column.key === 'acao'">
          {{ record.acao?.nome.toUpperCase() }}
        </template>
        <template v-if="column.key === 'envolvidos'">
          <a-row
            v-if="
              isNilOrEmpty(record.clientePrincipal) &&
              isNilOrEmpty(record.contrarioPrincipal)
            "
          >
            <a-col span="24"> - </a-col>
          </a-row>
          <a-row v-else>
            <a-col span="24">
              <a-row>
                <a-col>
                  <div v-if="!isNilOrEmpty(record.clientePrincipal)">
                    <a-tooltip
                      v-if="
                        record.clientePrincipal.nome === 'BRADESCO S/A' &&
                        !isNilOrEmpty(record.agencia)
                      "
                    >
                      <template #title> Ir para página da agência </template>
                      <router-link
                        :to="`/agencia/${record.agencia.codigo}`"
                        style="margin-right: 8px"
                      >
                        <span>{{ record.agencia.numero }}</span>
                        <span
                          v-if="!isNilOrEmpty(record.agencia.nome)"
                          style="text-transform: uppercase"
                          >/{{ record.agencia.nome }}</span
                        >
                        <span
                          v-if="!isNilOrEmpty(record.agencia.regionalText)"
                          style="text-transform: uppercase"
                        >
                          ({{
                            record.agencia.regionalText.toUpperCase()
                          }})</span
                        >
                      </router-link>
                    </a-tooltip>
                    <a-tooltip v-else>
                      <template #title> Ir para página do cliente </template>
                      <router-link
                        :to="`/contato/${record.clientePrincipal.codigo}`"
                        style="margin-right: 8px"
                      >
                        {{ record.clientePrincipal.nome.toUpperCase() }}
                      </router-link>
                    </a-tooltip>
                    <a-tag style="color: #b3b1b1; font-size: 10px">
                      CLIENTE
                    </a-tag>
                  </div>
                </a-col>
              </a-row>
              <a-row style="margin-top: 4px">
                <a-col>
                  <div v-if="!isNilOrEmpty(record.contrarioPrincipal)">
                    <a-tooltip>
                      <template #title> Ir para página do contrário </template>
                      <router-link
                        :to="`/contato/${record.contrarioPrincipal.codigo}`"
                        style="margin-right: 8px"
                      >
                        {{ record.contrarioPrincipal.nome.toUpperCase() }}
                      </router-link>
                    </a-tooltip>
                    <a-tag style="color: #b3b1b1; font-size: 10px">
                      CONTRÁRIO
                    </a-tag>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </template>
        <template v-if="column.key === 'responsavel'">
          <a-row v-if="!isNilOrEmpty(record.responsavelProcessual)">
            <a-col>
              <a-tooltip>
                <template #title> Ir para página do responsável </template>
                <router-link
                  :to="`/usuario/${record.responsavelProcessual.codigo}`"
                  style="text-transform: uppercase; margin-right: 8px"
                >
                  {{ record.responsavelProcessual.nome.toUpperCase() }}
                </router-link>
                <a-tag style="color: #b3b1b1; font-size: 10px">
                  PROCESSUAL
                </a-tag>
              </a-tooltip>
            </a-col>
          </a-row>
          <a-row v-if="!isNilOrEmpty(record.responsavelNegocial)">
            <a-col>
              <a-tooltip>
                <template #title> Ir para página do responsável </template>
                <router-link
                  :to="`/usuario/${record.responsavelNegocial.codigo}`"
                  style="text-transform: uppercase; margin-right: 8px"
                >
                  {{ record.responsavelNegocial.nome.toUpperCase() }}
                </router-link>
                <a-tag style="color: #b3b1b1; font-size: 10px">
                  NEGOCIAL
                </a-tag>
              </a-tooltip>
            </a-col>
          </a-row>
        </template>
        <template v-if="column.key === 'jurisdicao'">
          <span
            v-if="!isNilOrEmpty(record.estado)"
            style="text-transform: uppercase"
            >{{ record.comarca?.nome ?? record.cidade?.nome ?? '-' }} /
            {{ record.estado.sigla }}</span
          >
          <span v-else>-</span>
        </template>
      </slot>
    </template>
  </GraphqlDatatable>
</template>
