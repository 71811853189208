<script setup>
import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import { useRemoverPessoaEmail } from '~/composables/pessoas/useRemoverPessoaEmail';

import { useDrawerStore } from '~/stores/drawer';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    required: true,
  },
  pessoasEmails: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['pessoa-email-salvo', 'pessoa-email-removido']);

const drawerStore = useDrawerStore();

const {
  status: removerPessoaEmailStatus,
  runAsync: removerPessoaEmailAsync,
  err: removerPessoaEmailErr,
} = useRemoverPessoaEmail();

function comparePessoaEmail(a, b)
{
  if (a.ehPrincipal)
  {
    return -2;
  }
  if (a.ehPrincipal === b.ehPrincipal)
  {
    if (a.dataCriacaoEmTimestamp < b.dataCriacaoEmTimestamp)
    {
      return 1;
    }
    if (a.dataCriacaoEmTimestamp > b.dataCriacaoEmTimestamp)
    {
      return -1;
    }
    return 0;
  }
}

const state = reactive({
  pessoasEmails: [...props.pessoasEmails].sort(comparePessoaEmail),
});

function abrirSalvarPessoaEmailForm(pessoaEmailCodigo = null)
{
  drawerStore.push2({
    componentName: 'SalvarPessoaEmailForm',
    width: 600,
    params: {
      pessoaCodigo: props.pessoaCodigo,
      pessoaEmailCodigo,
    },
    onSuccess: async () =>
    {
      emit('pessoa-email-salvo');
    },
  });
}

async function removerAsync(pessoaEmailCodigo)
{
  await removerPessoaEmailAsync({
    pessoaEmailCodigo,
  });

  if (removerPessoaEmailStatus.value === 'error')
  {
    notification.error({
      message: 'Tivemos um problema ao tentar remover',
    });
    return;
  }

  emit('pessoa-email-removido');
}
</script>

<template>
  <a-card title="Emails" style="margin-top: 24px">
    <template #extra>
      <a-button @click="() => abrirSalvarPessoaEmailForm()">
        Novo email
      </a-button>
    </template>
    <a-spin :spinning="removerPessoaEmailStatus === 'loading'">
      <a-list item-layout="horizontal" :data-source="state.pessoasEmails">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta :description="item.email.endereco">
              <template #title>
                <a-tag v-if="item.ehPrincipal" color="green">
                  PRINCIPAL
                </a-tag>
                <a href="#">{{ item.descricao }}</a>
              </template>
            </a-list-item-meta>
            <template #actions>
              <a
                href="#"
                @click.prevent="() => abrirSalvarPessoaEmailForm(item.codigo)"
              >Editar</a>
              <a href="#" @click.prevent="() => removerAsync(item.codigo)">Remover</a>
            </template>
          </a-list-item>
        </template>
      </a-list>
    </a-spin>
  </a-card>
</template>
