<script setup>
const props = defineProps({
  response: {
    type: Object,
    default: null,
  },
});

const colunasTabelaPublicacao = [
  {
    title: 'DATA MOVIMENTO',
    dataIndex: 'dataMovimentoText',
    key: 'dataMovimento',
    width: 200,
  },
  {
    title: 'CONTEÚDO',
    dataIndex: 'conteudo',
    key: 'conteudo',
    slots: {
      customRender: 'conteudo',
    },
  },
];

const colunasTabelaAndamentos = [
  {
    title: 'DATA MOVIMENTO',
    dataIndex: 'dataMovimentoText',
    key: 'dataMovimento',
    width: 200,
  },
  {
    title: 'MOVIMENTAÇÃO',
    dataIndex: 'movimentacao',
    key: 'movimentacao',
    slots: {
      customRender: 'conteudo',
    },
  },
];
</script>

<template>
  <a-row>
    <a-col :span="24">
      <div style="padding: 16px 0">
        <a-row style="padding-left: 24px">
          <a-col><h2>Resumo</h2></a-col>
        </a-row>
        <a-divider style="margin-top: 16px" />
      </div>

      <div>
        <div style="padding-left: 24px">
          <a-row>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  GCPJ
                </div>
                <div class="ant-statistic-content">
                  <a-typography-text
                    v-if="response.pasta.gcpj"
                    copyable
                  >
                    {{ response.pasta.gcpj }}
                  </a-typography-text>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Carteira/Contrato (vigente)
                </div>
                <div class="ant-statistic-content">
                  <a-typography-text
                    v-if="!isNil(response.pasta.carteiraContratoVigente)"
                    copyable
                    style="text-transform: uppercase"
                  >
                    {{
                      `${response.pasta.carteiraContratoVigente.carteira.label} /
                      ${response.pasta.carteiraContratoVigente.contrato}`
                    }}
                  </a-typography-text>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Cliente principal
                </div>
                <div class="ant-statistic-content">
                  <a-tooltip v-if="!isNil(response.pasta.clientePrincipal)">
                    <template #title>
                      Ir para página do cliente
                    </template>
                    <router-link
                      :to="`/contato/${response.pasta.clientePrincipal.codigo}`"
                      style="text-transform: uppercase"
                    >
                      {{ response.pasta.clientePrincipal.nome }}
                    </router-link>
                    <a-tag style="margin-left: 8px; color: #b3b1b1">
                      {{ response.pasta.clientePrincipalPapelText }}
                    </a-tag>
                  </a-tooltip>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Agência
                </div>
                <div class="ant-statistic-content">
                  <a-typography-link
                    v-if="!isNil(response.pasta.agencia)"
                    :href="`/agencia/${response.pasta.agencia.codigo}`"
                    copyable
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.agencia.label }}
                  </a-typography-link>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row
            :gutter="8"
            style="margin-top: 24px"
          >
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Conta
                </div>
                <div class="ant-statistic-content">
                  <a-typography-text
                    v-if="response.pasta.conta"
                    copyable
                  >
                    {{ response.pasta.conta }}
                  </a-typography-text>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Ação
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.acao)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.acao.nome }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Valor da causa
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.valorCausa)"
                    style="text-transform: uppercase"
                  >
                    {{ formatDinheiro(response.pasta.valorCausa) }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Comarca
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.comarca)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.comarca.nome }}
                    {{
                      !isNil(response.pasta.estado)
                        ? `/ ${response.pasta.estado.sigla}`
                        : ''
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row
            :gutter="8"
            style="margin-top: 24px"
          >
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Órgão
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.orgao)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.orgao.nome }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Vara
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.vara)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.vara }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Número antigo
                </div>
                <div class="ant-statistic-content">
                  <a-typography-text
                    v-if="response.pasta.numeroAntigo"
                    copyable
                  >
                    {{ response.pasta.numeroAntigo }}
                  </a-typography-text>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Responsável pelo acordo/apreensão
                </div>
                <div class="ant-statistic-content">
                  <a-tooltip v-if="!isNil(response.pasta.responsavelAcordo)">
                    <template #title>
                      Ir para página do responsável pelo acordo/apreensão
                    </template>
                    <router-link
                      :to="`/usuario/${response.pasta.responsavelAcordo.codigo}`"
                      style="text-transform: uppercase"
                    >
                      {{ response.pasta.responsavelAcordo.nome }}
                    </router-link>
                  </a-tooltip>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row
            :gutter="8"
            style="margin-top: 24px"
          >
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Status do GCPJ
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.gcpjAtivo)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.gcpjAtivo ? 'ATIVO' : 'ENCERRADO' }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Data remessa
                </div>
                <div class="ant-statistic-content">
                  <span v-if="response.pasta.dataRemessa">
                    {{ response.pasta.dataRemessaText }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Data ajuizamento
                </div>
                <div class="ant-statistic-content">
                  <span v-if="response.pasta.dataAjuizamento">
                    {{ response.pasta.dataAjuizamentoText }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Data cadastro
                </div>
                <div class="ant-statistic-content">
                  <span v-if="response.pasta.dataCadastro">
                    {{ response.pasta.dataCadastroText }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
          </a-row>
          <a-row
            :gutter="8"
            style="margin-top: 24px"
          >
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Data da última atualização de GCPJ
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.dataUltimaAtualizacaoGcpjText)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.dataUltimaAtualizacaoGcpjText }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
            <a-col :span="6">
              <div class="ant-statistic">
                <div class="ant-statistic-title">
                  Referência da última atualização de GCPJ
                </div>
                <div class="ant-statistic-content">
                  <span
                    v-if="!isNil(response.pasta.ultimaAtualizacaoGcpjReferenciaNome)"
                    style="text-transform: uppercase"
                  >
                    {{ response.pasta.ultimaAtualizacaoGcpjReferenciaNome }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <a-row style="margin-top: 24px">
          <a-col :span="24">
            <a-card
              title="Últimas publicações"
              :head-style="{ padding: '0 24px 0 24px' }"
              :body-style="{ padding: '24px 24px 0 24px' }"
              :bordered="false"
            >
              <a-table
                :columns="colunasTabelaPublicacao"
                :data-source="response.publicacoes"
                :pagination="false"
                row-key="codigo"
                bordered
              >
                <template #conteudo="{ record }">
                  <a-typography-paragraph
                    :ellipsis="{ rows: 2, expandable: true }"
                    :content="record.conteudo"
                    style="margin-bottom: 0"
                  />
                </template>
              </a-table>
            </a-card>
          </a-col>
        </a-row>
        <a-row style="margin-top: 24px">
          <a-col :span="24">
            <a-card
              title="Últimos andamentos"
              :head-style="{ padding: '0 24px 0 24px' }"
              :body-style="{ padding: '24px' }"
              :bordered="false"
            >
              <a-table
                :columns="colunasTabelaAndamentos"
                :data-source="response.andamentos"
                :pagination="false"
                row-key="codigo"
                bordered
              >
                <template #conteudo="{ record }">
                  <a-typography-paragraph
                    :ellipsis="{ rows: 1, expandable: true }"
                    :content="record.movimentacao"
                    style="margin-bottom: 0"
                  />
                </template>
              </a-table>
            </a-card>
          </a-col>
        </a-row>
      </div>
    </a-col>
  </a-row>
</template>
