<script setup>
import { toRaw } from 'vue';
import { EditOutlined } from '@ant-design/icons-vue';
import { isNil } from 'lodash-es';
import { useDrawerStore } from '~/stores/drawer';

const props = defineProps({
  value: {
    type: Object,
    default: null,
  },
  placeholder: {
    type: String,
    default: 'Selecione um contato',
  },
});
const emit = defineEmits(['update:value', 'change']);
const $drawer = useDrawerStore();

const onChange = async (value) => {
  emit('update:value', value);
  emit('change', value);
};

const abrirSelecionarPessoaViewDrawer = () => {
  const drawerIndex = $drawer.push({
    componentName: 'SelecionarPessoaView',
    params: {},
  });

  let unsubscribe = $drawer.$subscribe(async (mutation, state) => {
    if (state.activeDrawer + 1 === drawerIndex) {
      if (isNil($drawer.drawerReturn)) return;

      const pessoa = toRaw($drawer.drawerReturn);
      onChange({
        key: pessoa.codigo,
        value: pessoa.codigo,
        label: `${pessoa.nome}`,
      });

      if (unsubscribe) {
        unsubscribe();
      }
      unsubscribe = null;
      $drawer.drawerReturn = null;
    }
  });
};
</script>

<template>
  <div style="height: 32px">
    <a-row>
      <a-col span="24">
        <a-tooltip>
          <template #title> Selecione ou crie um novo contato </template>
          <div
            class="ant-input"
            style="cursor: pointer"
            @click="abrirSelecionarPessoaViewDrawer"
          >
            <a-row type="flex">
              <a-col flex="auto">
                <span v-if="!isNil(props.value?.key)">{{
                  props.value.label
                }}</span>
                <span v-else style="color: rgba(0, 0, 0, 0.25)">{{
                  placeholder
                }}</span>
              </a-col>
              <a-col flex="16px">
                <EditOutlined
                  style="margin-left: 4px; color: rgba(0, 0, 0, 0.25)"
                />
              </a-col>
            </a-row>
          </div>
        </a-tooltip>
      </a-col>
    </a-row>
  </div>
</template>
