query solicitarAjuizamentoForm(
  $pastaCodigo: String
  $compromissoCodigo: String
) {
  model: alterarPastaForm(pastaCodigo: $pastaCodigo, compromissoCodigo: $compromissoCodigo) {
    codigo    
    contrarioPrincipalPapelCodigo: contrarioPrincipalPapel
    contrarioPrincipalPapelText
    plano
    valorCausa
    valorParcelaContratoOriginal
    valorContrato
    garantias    
    telaOperacao
    contrato
    conta
    dataCelebracaoContratoOriginal
    dataVencimentoInicialParcelaContratoOriginal: dataVencimentoInicial
    dataVencimentoFinalParcelaContratoOriginal
    dataPrimeiraParcelaVencida
    dataAtualizacaoValorCausa
    empresaCodigo: empresa
    empresaText
    estado {
      value: codigo
      label: nome
    }
    justica: justica {
      value: codigo
      label: nome
    }
    comarca {
      value: codigo
      label: nome
    }
    carteira {
      value: codigo
      tipo
      nome
      label
    }
    carteirasContratos {
      codigo
      carteira {
        codigo
        tipo
        nome
        label
      }
      contrato
      vigente
      original
    }
    depositarioFiel {
      value: codigo
      label: nome
    }
    avalistas: avalistas2 {
      value: codigo
      label: nome
    }
    litisconsortes {
      value: codigo
      label: nome
    }
    contrarioPrincipal {
      value: codigo
      label: nome
    }
    agencia: agencia2 {
      value: codigo
      label: nome
    }
  }
}
