<script setup>
const props = defineProps({
  text: String,
  required: () => true,
});
</script>

<template>
  <span style="text-transform: uppercase">{{
    !isNilOrEmpty(props.text) ? props.text : '-'
  }}</span>
</template>
