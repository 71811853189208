export const tipoJobRelatorio = {
  RelatorioPastasComGcpjDesatualizado: 1,
  RelatorioPastasAtivas: 4,
  RelatorioTodasPastas: 5,
  RelatorioCarteiraContratoPorPasta: 6,
  RelatorioUltimaAtualizacaoGcpjPorPasta: 7,
  RelatorioUltimosAndamentosPorPasta: 8,
  RelatorioUltimasPublicacoesPorPasta: 9,
  RelatorioUltimosComentariosPorPasta: 10,
  RelatorioPastasSemValorCausa: 11,
  RelatorioPastasSemConta: 12,
  RelatorioPastasSemCarteiraContrato: 13,
  RelatorioPastasFiltradas: 14,
  RelatorioPastasCaq: 15,
  RelatorioUsuariosAtivos: 16,
  RelatorioCaqCompromissos: 17,
  RelatorioMinhasPastas: 18,
  RelatorioDocumentosAnexados: 19,
  RelatorioFluxoAtualizacaoGcpj: 20,
  RelatorioFluxoCompromissoPub: 21,
  RelatorioFluxoAlteracaoPasta: 22,
  RelatorioFluxoCompromissoCaq: 23,
  RelatorioRegistrosDelegacao: 24,
  RelatorioFluxoVerificarPublicacaoCapturada: 25,
  RelatorioContatos: 26,
  RelatorioClienteRcb: 27,
  RelatorioClienteRcbTelefone: 28,
  RelatorioClienteRcbEmail: 29,
  RelatorioClienteRcbEndereco: 30,
  RelatorioDividaRcb: 31,
  RelatorioDividaRcbGarantiasAutos: 32,
  RelatorioDividaRcbProcessosJuridicos: 33,
  RelatorioDividaRcbAvalistas: 34,
  RelatorioCompromissosExportado: 35,
  RelatorioFluxoCdi: 36,
  RelatorioAtualizacoesGcpj: 37,
  RelatorioIntegracaoDeepCenter: 38,
  RelatorioTodosAndamentos: 39,
  RelatorioTodasPublicacoes: 40,
  RelatorioTodosComentarios: 41,
  RelatorioFluxoEncerramento: 42,
  RelatorioFluxoOcorrenciaAtendimento: 43,
  RelatorioPastas: 47,
  RelatorioIntegracaoDiscador: 48,
  RelatorioPessoaEndereco: 49,
  ExportacaoAtualizacoesGcpjOystr: 50,
  ExportacaoPastas: 53,
};

export const tipoJobImportacao = {
  ImportacaoProcessosDistribuidos: 2,
  ImportacaoProcessosRedistribuidos: 3,
  ImportarPlanilhaAlteracaoPastas: 46,
  ImportacaoPlanilhaStatusAtualizacoesGcpjOystr: 51,
};

const tiposJobs = {
  ...tipoJobRelatorio,
  ...tipoJobImportacao,
  AutomatizacaoConcluirFluxoEmLote: 44,
  Compromisso: 45,
  Command: 52,
};

export default tiposJobs;
