<script setup>
const props = defineProps({
  response: {
    type: Object,
    required: true,
  },
});

const colunasTabela = [
  {
    title: 'PASTA',
    dataIndex: 'nome',
    key: 'nome',
    slots: {
      customRender: 'nome',
    },
  },
];
</script>

<template>
  <div style="padding: 16px 0">
    <a-row style="padding-left: 24px">
      <a-col><h2>Pastas vinculadas</h2></a-col>
    </a-row>
    <a-divider style="margin-top: 16px" />
    <div style="padding: 0 24px">
      <a-table
        :columns="colunasTabela"
        :data-source="props.response.pastas"
        row-key="codigo"
        bordered
      >
        <template #nome="{ record }">
          <a-tooltip>
            <template #title>
              Ir para página da pasta
            </template>
            <router-link
              :to="`/pasta/${record.codigo}`"
              style="text-transform: uppercase"
            >
              {{ record.nome }}
            </router-link>
          </a-tooltip>
        </template>
      </a-table>
    </div>
  </div>
</template>
