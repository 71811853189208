<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import PERFIL_ACESSO from '~/perfilAcesso';

const activeTab = ref('geral');

async function onTabChange(val)
{
  activeTab.value = val;
}

const gqlDatatableVars = {
  where: {
    and: [
      {
        responsavel: {
          perfisAcesso: {
            some: {
              perfil: {
                in: [PERFIL_ACESSO.Pub],
              },
            },
          },
        },
      },
    ],
  },
};
</script>

<template>
  <Page menu="pub">
    <div class="pub-page" style="padding-bottom: 24px">
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                PUB
              </h1>
            </a-col>
            <a-col :span="12" />
          </a-row>

          <a-tabs
            v-model:activeTab="activeTab"
            :destroy-inactive-tab-pane="true"
            @change="onTabChange"
          >
            <a-tab-pane key="geral" tab="Geral">
              <h2 style="margin-top: 8px">
                Compromissos
              </h2>

              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="6">
                  <CompromissosEmAtrasoGqlCard :perfil="[PERFIL_ACESSO.Pub]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoHojeGqlCard :perfil="[PERFIL_ACESSO.Pub]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoProximosDiasGqlCard :perfil="[PERFIL_ACESSO.Pub]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosConcluidosGqlCard :perfil="[PERFIL_ACESSO.Pub]" />
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-col :span="24">
                  <UsuariosCompromissosStackChart :perfil="PERFIL_ACESSO.Pub" />
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="compromissos" tab="Compromissos">
              <CompromissoGqlDatatable :enable-row-selection="true" :variables="gqlDatatableVars" />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
