<script setup>
const props = defineProps({
  status: {
    type: String,
    default: 'loading',
  },
});
</script>

<template>
  <div class="stats-card">
    <a-card class="statistic-card">
      <a-skeleton
        v-if="props.status === 'loading'"
        :active="true"
        :title="false"
        :paragraph="{ rows: 2, width: ['75%', '100%'] }"
      />
      <Empty
        v-else-if="props.status === 'error'"
        description="Nenhum dado encontrado"
        style="margin: 0"
      />
      <a-row v-else align="middle">
        <a-col span="24">
          <div class="ant-statistic">
            <div
              class="ant-statistic-content"
              style="
                text-align: center;
                font-weight: 500;
                color: #5d5d5d;
              "
            >
              <slot name="content" />
            </div>
            <div
              class="ant-statistic-title"
              style="
                text-align: center;
                font-size: 11px;
                font-weight: bold;
                text-transform: uppercase;
                color: rgb(182 181 181);
                letter-spacing: 1px;
              "
            >
              <slot name="title" />
            </div>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<style lang="less">
.stats-card .ant-statistic .ant-statistic-content {
  font-size: 42px;
}

.stats-card {
  height: 100%;
  & .ant-card {
    height: 100%;
    & .ant-card-body {
      height: 100%;
      & .ant-row {
        height: 100%;
      }
    }
  }
  & .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
  & .ant-result {
    padding: 0;

    & .ant-result-icon {
      margin-bottom: 8px;
    }

    & .ant-result-icon > .anticon {
      font-size: 51px;
    }

    & .ant-result-title {
      font-size: 16px;
      font-weight: 500;
      color: #727272;
    }
  }
}
</style>
