<script setup>
import { onMounted } from 'vue';
const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
  fluxo: {
    default: null,
    type: Object,
  },
});
const emit = defineEmits(['loaded']);
onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Código" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.codigo }}
      <a-typography-text
        :copyable="{
          text: props.fluxo.publicacaoNumeroProcesso.codigo,
        }"
      >
        <template #copyableTooltip="{ copied }">
          <span v-if="!copied" key="copy-tooltip">Copiar</span>
          <span v-else key="copied-tooltip">Copiado!</span>
        </template>
      </a-typography-text>
    </a-descriptions-item>
    <a-descriptions-item label="Diário" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.diario }}
    </a-descriptions-item>
    <a-descriptions-item label="Caderno" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.caderno }}
    </a-descriptions-item>
    <a-descriptions-item label="Seção" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.secao }}
    </a-descriptions-item>
    <a-descriptions-item label="Tipo" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.tipo }}
    </a-descriptions-item>
    <a-descriptions-item label="Página" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.pagina }}
    </a-descriptions-item>
    <a-descriptions-item label="Data da publicação" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.dataMovimentoText }}
    </a-descriptions-item>
    <a-descriptions-item label="Conteúdo" :span="3">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.conteudo }}
    </a-descriptions-item>
  </a-descriptions>
</template>
