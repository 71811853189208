<script setup lang="ts">
import { ReloadOutlined } from '@ant-design/icons-vue';
import type { DocumentNode } from 'graphql';
import { ref } from 'vue';

interface Props
{
  query: DocumentNode
  variables?: any
  initialModel?: object
  pollInterval?: number
  update?: any
  skip?: boolean
}

const props = defineProps<Props>();
const apolloQueryRef = ref<any>(null);
const firstLoading = ref<boolean>(true);

async function tentarNovamente(apolloQuery: any)
{
  await apolloQuery.refresh();
}

function onResult()
{
  firstLoading.value = false;
}

function onError()
{
  if (apolloQueryRef.value)
  {
    const apolloQuery = apolloQueryRef.value.getApolloQuery();
    apolloQuery.stopPolling();
  }
}

defineExpose({
  getApolloQuery: () =>
  {
    return apolloQueryRef.value.getApolloQuery();
  },
});
</script>

<template>
  <ApolloQuery
    v-if="!props.skip"
    ref="apolloQueryRef"
    fetch-policy="no-cache"
    :query="props.query"
    :variables="props.variables"
    :poll-interval="props.pollInterval"
    :update="update"
    @result="onResult"
    @error="onError"
  >
    <template #default="{ result: { data, error, loading }, query: smartQuery }">
      <slot
        v-if="firstLoading || (loading && !props.pollInterval)"
        name="loading"
      >
        <a-flex
          justify="center"
          style="margin-top: 24px"
        >
          <a-spin />
        </a-flex>
      </slot>

      <slot
        v-if="error"
        name="error"
        :error="error"
        :query="smartQuery"
      >
        <a-result
          status="500"
          title="Tivemos um problema inesperado"
          sub-title="Entre em contato com o suporte"
        >
          <template #extra>
            <a-button
              type="primary"
              @click="() => tentarNovamente(smartQuery)"
            >
              <template #icon>
                <ReloadOutlined />
              </template>
              Tentar novamente
            </a-button>
          </template>
        </a-result>
      </slot>

      <slot
        v-else-if="data"
        name="success"
        :data="data"
        :query="smartQuery"
      />
    </template>
  </ApolloQuery>
  <template v-else>
    <slot
      name="success"
      :data="null"
      :query="null"
    />
  </template>
</template>
