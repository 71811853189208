<script setup>
import { watchEffect } from 'vue';
import { useObterAuditsEnvolvidosNovajus } from '~/composables/pastas/useObterAuditsEnvolvidosNovajus';

const props = defineProps({
  codigoPasta: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const {
  status: obterAuditsEnvolvidosNovajusStatus,
  runAsync: obterAuditsEnvolvidosNovajusAsync,
  data: obterAuditsEnvolvidosNovajusData,
} = useObterAuditsEnvolvidosNovajus();

watchEffect(async () =>
{
  if (!props.isSelected)
  {
    return;
  }

  await obterAuditsEnvolvidosNovajusAsync({ codigoPasta: props.codigoPasta });
});
</script>

<template>
  <div style="padding: 16px 0">
    <a-row style="padding-left: 24px">
      <a-col><h2>Histórico envolvidos do Novajus</h2></a-col>
    </a-row>
    <a-divider style="margin-top: 16px" />
    <div style="padding: 0 24px">
      <a-skeleton
        v-if="obterAuditsEnvolvidosNovajusStatus === 'loading'"
        :active="true"
        :paragraph="{ rows: 12 }"
      />
      <div v-if="obterAuditsEnvolvidosNovajusStatus === 'success'">
        <a-card
          v-if="
            obterAuditsEnvolvidosNovajusData.auditsEnvolvidosNovajus.length
              === 0
          "
        >
          <Empty
            description="Nenhum dado encontrado"
            style="margin-top: 24px"
          />
        </a-card>
        <a-row
          v-for="(
            item, index
          ) in obterAuditsEnvolvidosNovajusData.auditsEnvolvidosNovajus"
          v-else
          :key="index"
          style="margin-bottom: 24px"
        >
          <a-col :span="24">
            <p style="font-weight: 500; font-size: 16px; margin-bottom: 0">
              {{ item.titulo }}
            </p>
            <p>
              O usuário <b>{{ item.usuario }}</b> alterou este registro em
              <b>{{ item.dataCriacao }}</b>
            </p>
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="item.html" />
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.webgrid {
  border: 1px solid #f2f2f2;
  border-collapse: collapse;
  width: 100%;
  word-wrap: break-word;
  & thead {
    background-color: #fafafa;
    padding: 6px 5px;
    text-align: left;

    & th {
      position: static !important;
      padding: 0 10px;
      white-space: nowrap;
      height: 45px;
      vertical-align: middle;
      font-size: 16px;
      color: #505050;
      border: 1px solid #ededed;
      background-color: #fafafa !important;
    }
  }

  & .webgrid-row-style {
    border-right: 1px solid #d6e8ff;
    height: 30px;
    background-color: #fff;
  }

  & .webgrid-alternating-row {
    background-color: #fbfbfb;
    height: 30px;
  }

  & td {
    border: 1px solid #ededed;
    height: 45px;
    vertical-align: middle;
    padding: 0 10px;
    font-size: 14px;
    color: #505050;
    cursor: default;
  }

  & .new-value {
    color: green;
  }

  & .diff-delete {
    text-decoration: line-through;
  }
}
</style>
