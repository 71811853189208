<script>
import { reactive, ref, toRaw } from 'vue';
import { API_URL } from '~/utils';

export default {
  props: {
    carteiraContrato: {
      type: Object,
      default: null,
    },
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const urlAjaxSelectCarteiras = ref(`${API_URL}/ajax-select/carteiras`);

    const formRef = ref(null);
    const formModel = reactive({
      carteira: props.carteiraContrato?.carteira.codigo,
      contrato: props.carteiraContrato?.contrato,
    });
    const formRules = reactive({
      carteira: [
        {
          required: true,
          message: 'Carteira é obrigatória',
        },
      ],
      contrato: [
        {
          required: true,
          message: 'Contrato é obrigatório',
        },
      ],
    });

    const salvar = async () => {
      formRef.value.validate().then(async () => {
        const carteira = toRaw(formModel.carteira);
        const contrato = toRaw(formModel.contrato);
        emit('save', {
          carteira,
          contrato,
        });
      });

      return true;
    };

    return {
      urlAjaxSelectCarteiras,
      formRef,
      formModel,
      formRules,
      salvar,
    };
  },
};
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => $emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          Adicionar Contrato/Carteira
        </span>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
      >
        <a-form-item label="Carteira" name="carteira" style="padding-bottom: 0">
          <AjaxSelect
            v-model:value="formModel.carteira"
            :url="urlAjaxSelectCarteiras"
            placeholder="Selecione uma carteira"
          />
        </a-form-item>

        <a-form-item label="Contrato" name="contrato" style="padding-bottom: 0">
          <a-input v-model:value="formModel.contrato" />
        </a-form-item>

        <a-row style="margin-top: 24px">
          <a-col :span="24">
            <a-button
              type="primary"
              style="margin-right: 8px; min-width: 100px"
              @click="salvar"
            >
              Salvar
            </a-button>
            <a-button style="margin-right: 8px" @click="() => $emit('close')">
              Fechar
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </div>
</template>
