<script setup>
import axios from 'axios';
import { create as maskCreate } from 'maska';
import { defineStore } from 'pinia';
import qs from 'qs';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';

const props = defineProps({
  codigoPasta: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('solicitar-cadastro-processo-vinculado-form', {
  actions: {
    async carregar()
    {
      this.carregarStatus = 'loading';
      try
      {
        const response = (
          await axios.get(
            `${API_URL}/compromissos/solicitar-cadastro-processo-vinculado/form`,
            {
              getAccessToken: true,
              params: {
                codigoPasta: props.codigoPasta,
              },
              paramsSerializer: params => qs.stringify(params),
              timeout: 120000,
            },
          )
        ).data;
        this.nome = response.nome;
        this.carregarStatus = 'success';
      }
      catch (e)
      {
        this.carregarStatus = 'error';
        this.error = error(e).error;
      }
    },
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        const response = await axios.post(
          `${API_URL}/fluxo/cadastro-processo-vinculado/solicitar-cadastro-processo-vinculado`,
          {
            arquivos: this.arquivos,
            codigoPasta: props.codigoPasta,
            nome: this.nome,
            numeroCnj: this.numeroCnj,
            tipoProcesso: this.tipoProcesso,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.codigoNovaPasta = response.data;
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
  state: () => ({
    arquivos: [],
    carregarStatus: null,
    codigoNovaPasta: null,
    error: null,
    nome: null,
    numeroCnj: null,
    salvarStatus: null,
    tipoProcesso: null,
    uploaderStatus: null,
  }),
});

const $s = useStore();
const formRef = ref(null);
const uploaderRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  nome: [{ message: 'Nome é obrigatório', required: true }],
  numeroCnj: [{ message: 'Número CNJ é obrigatório', required: true }],
  tipoProcesso: [
    {
      message: 'Tipo do processo é obrigatório',
      required: true,
      trigger: 'change',
      type: 'number',
    },
  ],
});

async function salvar()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        class: 'er-notification',
        duration: 5,
        message: $s.error,
      });
    }
  });
}

let mask = null;
onMounted(async () =>
{
  await $s.carregar();

  mask = maskCreate('.numero-cnj', {
    mask: '#######-##.####.#.##.####',
  });

  emit('loaded');
});

onUnmounted(() =>
{
  if (mask)
  {
    mask.destroy();
  }
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <a-skeleton
    v-if="$s.carregarStatus === 'loading'"
    :loading="true"
    :active="true"
    :paragraph="{ rows: 12 }"
  />
  <ErrorResult
    v-else-if="$s.carregarStatus === 'error'"
    :tentar-novamente-fn="() => $s.carregar()"
  />
  <div v-else>
    <a-row>
      <a-col :span="24">
        <a-result
          v-if="$s.salvarStatus === 'success'"
          status="success"
          title="Seu registro foi realizado com sucesso"
          sub-title="Agora você pode acessar a nova pasta criada ou fechar essa janela"
        >
          <template #extra>
            <a :href="`/pasta/${$s.codigoNovaPasta}`">
              <a-button>Acesse a nova pasta</a-button></a>
            <a-button @click="() => $emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <div v-if="$s.salvarStatus !== 'success'">
          <h3 style="margin-bottom: 24px">
            Informe os dados
          </h3>

          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
          >
            <a-form-item label="Tipo processo vinculado" name="tipoProcesso">
              <TipoEnumSelect
                v-model:value="formModel.tipoProcesso"
                placeholder="Selecione o tipo de processo vinculado"
                :options="[
                  { value: 2, label: 'Recurso' },
                  { value: 3, label: 'Incidente' },
                  { value: 4, label: 'Defesa' },
                ]"
              />
            </a-form-item>

            <a-form-item label="Nome" name="nome">
              <a-input v-model:value="formModel.nome" />
            </a-form-item>

            <a-form-item label="Número CNJ" name="numeroCnj">
              <a-input v-model:value="formModel.numeroCnj" class="numero-cnj" />
            </a-form-item>

            <h3 style="margin-bottom: 24px">
              Anexar arquivos
            </h3>

            <a-row>
              <a-col :span="24">
                <Uploader
                  ref="uploaderRef"
                  v-model:fileList="$s.arquivos"

                  @start="() => ($s.uploaderStatus = 'uploading')"
                  @end="() => ($s.uploaderStatus = 'done')"
                />
              </a-col>
            </a-row>

            <a-form-item style="margin-top: 24px; margin-bottom: 0px">
              <a-button
                :loading="
                  $s.uploaderStatus === 'uploading'
                    || $s.salvarStatus === 'loading'
                "
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click.prevent="salvar"
              >
                Salvar
              </a-button>
              <a-button @click="() => $emit('close')">
                Fechar
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
