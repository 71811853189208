<script setup lang="ts">
import { onMounted, ref } from 'vue';
import tiposOcorrenciasAtendimentos from '~/graphql/tiposOcorrenciasAtendimentos.graphql';

const props = defineProps({
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const tipoOcorrenciaAtendimentoQuando = ref<string | null>(null);

function onTipoOcorrenciaAtendimentoChange(record: any)
{
  tipoOcorrenciaAtendimentoQuando.value = record.option.quando;
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-alert
      v-if="props.pastaCodigos?.length > 1"
      type="warning"
      message="Você irá realizar uma alteração em lote"
      show-icon
      style="margin-bottom: 24px"
    />

    <CompromissoCommandForm2
      command="OcorrenciaAtendimento.RegistrarOcorrenciaAtendimentoCommand"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Ocorrência de atendimento"
          name="tipoOcorrenciaAtendimento"
        >
          <GqlSelect
            v-model:value="model.tipoOcorrenciaAtendimento"
            :query="tiposOcorrenciasAtendimentos"
            placeholder="Seleciona a ocorrência"
            @change="onTipoOcorrenciaAtendimentoChange"
          />
        </a-form-item>

        <a-alert
          v-if="tipoOcorrenciaAtendimentoQuando !== null"
          :message="tipoOcorrenciaAtendimentoQuando"
          type="warning"
          show-icon
          style="margin-bottom: 24px"
        />

        <a-form-item
          label="Observação"
          name="observacao"
          style="padding-bottom: 0"
        >
          <a-textarea
            v-model:value="model.observacao"
            :rows="4"
          />
        </a-form-item>
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
