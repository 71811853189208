<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
} from '~/components/GqlDatatable.vue';
import arquivoDatatable from '~/graphql/arquivoDatatable.graphql';
import { getGoogleDriveUrl } from '~/utils';

interface Props
{
  disabledFilters?: string[]
  variables?: any
}

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataCriacao: 'DESC' }] };
  },
});

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});

const colunas = [
  {
    key: 'documento',
    title: 'DOCUMENTO',
    width: 220,
  },
  {
    key: 'tipo',
    title: 'TIPO',
    width: 220,
  },
  {
    key: 'enviadoPor',
    title: 'ENVIADO POR',
    width: 170,
  },
  {
    dataIndex: 'dataCriacaoText',
    key: 'dataCriacaoText',
    sortBy: ['dataCriacao'],
    sorter: true,
    title: 'ENVIADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
] as GraphqlDatatableColumn[];
</script>

<template>
  <GqlDatatable :query="arquivoDatatable" :columns="colunas" :variables="gqlVariables" :keep-history="false">
    <template #documentoColumn="{ record }">
      <a
        :href="`${getGoogleDriveUrl(record.fileServiceId)}`"
        target="_blank"
        style="text-transform: uppercase"
      >{{ record.nome }}</a>
    </template>
    <template #tipoColumn="{ record }">
      <span style="text-transform: uppercase">
        {{ record.compromisso.fluxo.tipoText }} / {{ record.compromisso.tipoText }}
      </span>
    </template>
    <template #enviadoPorColumn="{ record }">
      <span style="text-transform: uppercase">
        {{ record.criadoPor.nome }}
      </span>
    </template>
  </GqlDatatable>
</template>
