<script>
import { Empty } from 'ant-design-vue';

export default {
  props: {
    description: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
};
</script>

<template>
  <a-empty
    :image="simpleImage"
    :description="isNil(description) ? undefined : description"
  >
    <template v-if="isNil(description)" #description>
      <slot name="description" />
    </template>
  </a-empty>
</template>
