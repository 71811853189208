<script setup lang="ts">
import { onMounted } from 'vue';
import { formatDinheiro, getGoogleDriveUrl } from '~/utils';

interface Props
{
  compromisso?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-alert
    v-if="props.compromisso.data.erro"
    type="error"
    message="Não foi possível obter os dados do processo"
    show-icon
  />
  <a-descriptions
    v-else
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      label="GCPJ"
      :span="3"
    >
      {{ props.compromisso.data.gcpj }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Comarca"
      :span="3"
    >
      {{ props.compromisso.data.comarca }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Empresa"
      :span="3"
    >
      {{ props.compromisso.data.empresa }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Dep. Envolvida"
      :span="3"
    >
      {{ props.compromisso.data.depEnvolvida }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Nome ação"
      :span="3"
    >
      {{ props.compromisso.data.nomeAcao }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Tipo ação"
      :span="3"
    >
      {{ props.compromisso.data.tipoAcao }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Número do processo"
      :span="3"
    >
      {{ props.compromisso.data.numeroProcesso }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Tribunal"
      :span="3"
    >
      {{ props.compromisso.data.tribunal }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Órgão julgador"
      :span="3"
    >
      {{ props.compromisso.data.orgaoJulgador }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Valor da causa"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorCausa) }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Valor da causa atualizado"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valorCausaAtualizada) }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Data fato gerador"
      :span="3"
    >
      {{ props.compromisso.data.dataFatoGerador }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Data de distribuição ao órgão julgador"
      :span="3"
    >
      {{ props.compromisso.data.dataDistribuicaoOrgaoJulgador }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Data movimento"
      :span="3"
    >
      {{ props.compromisso.data.dataMovimento }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Data cadastro"
      :span="3"
    >
      {{ props.compromisso.data.dataCadastro }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Envolvidos"
      :span="3"
    >
      <span v-if="props.compromisso.data.envolvidos?.length === 0">Nenhum envolvido</span>
      <ul v-else>
        <li
          v-for="envolvido in props.compromisso.data.envolvidos"
          :key="envolvido.documento"
        >
          {{ envolvido.nome }} - {{ envolvido.documento }} ({{ envolvido.tipo }})
        </li>
      </ul>
    </a-descriptions-item>
    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.data.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
