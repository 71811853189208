import axios from 'axios';
import qs from 'qs';
import { ref } from 'vue';
import { error } from '~/utils';

const API_URL = import.meta.env.VITE_API_URL;

export function useTotalValoresCausas(query) {
  const status = ref('loading');
  const err = ref(null);
  const response = ref(null);

  async function fetch() {
    status.value = 'loading';
    try {
      const r = await axios.get(`${API_URL}/pastas/obter-total-valores-causas`, {
        params: query,
        paramsSerializer: params => qs.stringify(params),
        timeout: 120000,
        getAccessToken: true,
      });
      status.value = 'success';
      response.value = r.data.total;
    }
    catch (e) {
      status.value = 'error';
      err.value = error(e);
    }
  }

  return {
    status,
    err,
    response,
    fetch,
  };
}
