<script setup>
import { DownOutlined, ProfileOutlined } from '@ant-design/icons-vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import AuditsEnvolvidosNovajus from './AuditsEnvolvidosNovajus.vue';
import CarteirasContratos from './CarteirasContratos.vue';
import Comentarios from './Comentarios.vue';
import Detalhes from './Detalhes.vue';
import PastasVinculadas from './PastasVinculadas.vue';
import Resumo from './Resumo.vue';
import { useStore } from './store';
import { useObterCarteirasContratos } from '~/composables/pastas/useObterCarteirasContratos';
import { useObterPastasVinculadasPorPasta } from '~/composables/pastas/useObterPastasVinculadasPorPasta';
import { useObterResumoPasta } from '~/composables/pastas/useObterResumoPasta';
import { useGraphQL } from '~/composables/useGraphQL';
import tipoFluxo from '~/constants/tipoFluxo';
import tipoFluxoStatus from '~/constants/tipoFluxoStatus';
import EnumPerfil from '~/enums/EnumPerfil';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';

const route = useRoute();
const drawerStore = useDrawerStore();
const $auth = useAuthStore();
const $s = useStore();
const { codigo } = route.params;
const tabAtiva = ref('resumo');
let unsubscribe = null;

const {
  data: pastaCabecalhoData,
  runAsync: pastaCabecalhoAsync,
  status: pastaCabecalhoStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'PASTA_CABECALHO',
});

const {
  obter: obterResumoPasta,
  response: obterResumoPastaResponse,
  status: obterResumoPastaStatus,
} = useObterResumoPasta({
  codigoPasta: codigo,
});

const {
  obter: obterCarteirasContratos,
  response: obterCarteirasContratosResponse,
  status: obterCarteirasContratosStatus,
} = useObterCarteirasContratos({
  codigoPasta: codigo,
});

const {
  obter: obterPastasVinculadasPorPasta,
  response: obterPastasVinculadasPorPastaResponse,
  status: obterPastasVinculadasPorPastaStatus,
} = useObterPastasVinculadasPorPasta({
  codigoPasta: codigo,
});

const solicitacaoEncerramentoPendente = computed(() =>
{
  if (pastaCabecalhoData?.value?.pasta === null)
  {
    return false;
  }

  return pastaCabecalhoData.value.pasta.fluxos.some(
    x =>
      x.tipoValue === tipoFluxo.Encerramento
      && x.statusValue === tipoFluxoStatus.EmAndamento,
  );
});

async function onTabChange(activeKey)
{
  if (activeKey === 'resumo')
  {
    await obterResumoPasta();
  }

  if (activeKey === 'carteiras-contratos')
  {
    await obterCarteirasContratos();
  }

  if (activeKey === 'pastas-vinculadas')
  {
    await obterPastasVinculadasPorPasta();
  }
}

function abrirFluxosDrawer(codigo)
{
  drawerStore.push({
    componentName: 'FluxosView',
    params: {
      codigoPasta: codigo,
      tiposParaNaoMostrar: [38],
    },
  });
}

const larguraForm = 1000;

function uiAbrirAlterarPastaForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'AlterarPastaForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'ALTERAR PASTA',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarAtualizacaoGcpjForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarAtualizacaoGcpjForm',
      componentProps: {
        encaminharParaEquipeGcpj: true,
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR ATUALIZAÇÃO GCPJ',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarAtualizacaoGcpjSemEncaminharParaEquipeGcpjForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarAtualizacaoGcpjForm',
      componentProps: {
        encaminharParaEquipeGcpj: false,
        pastaCodigos: [codigo],
      },
      title: 'ATUALIZAR GCPJ',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarAlteracaoPastaForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarAlteracaoPastaForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR ALTERAÇÃO PASTA',
    },
    width: larguraForm,
  });
}

function uiAbrirCadastrarPastaVinculadaForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'SolicitarCadastroProcessoVinculadoForm',
      title: 'SOLICITAR CADASTRO DE PROCESSO VINCULADO',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarPagarCustasForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'SolicitarPagamentoCustasForm',
      title: 'SOLICITAR PAGAMENTO DE CUSTAS',
    },
    width: larguraForm,
  });
}

function uiAbrirCriarCompromissoResponsavelForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'CriarCompromissoResponsavelForm',
      title: 'CRIAR COMPROMISSO PARA RESPONSÁVEL',
    },
    width: larguraForm,
  });
}

function uiAbrirEnviarArquivosFormForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'EnviarArquivosForm',
      title: 'ENVIAR ARQUIVOS',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarServicoCdiForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'SolicitarServicoCdiForm',
      title: 'SOLICITAR PESQUISA AO CDI',
    },
    width: larguraForm,
  });
}

function uiAbrirRegistrarAndamentoProcessualForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'RegistrarAndamentoProcessualForm',
      title: 'REGISTRAR ANDAMENTO PROCESSUAL',
    },
    width: larguraForm,
  });
}

function uiAbrirRegistrarPesquisaBensForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'RegistrarPesquisaBensForm',
      title: 'REGISTRAR PESQUISA DE BENS',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarEncerramentoForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarEncerramentoForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR ENCERRAMENTO',
    },
    width: larguraForm,
  });
}

function uiAbrirCriarCompromissoCaqForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'CriarCompromissoCaqForm',
      title: 'CRIAR COMPROMISSO CAQ',
    },
    width: larguraForm,
  });
}

function uiAbrirCriarCompromissoPubForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      codigoPasta: codigo,
      componentName: 'CriarCompromissoPubForm',
      title: 'CRIAR COMPROMISSO PUB',
    },
    width: larguraForm,
  });
}

function uiAbrirEnviarPlanilhaAtualizacaoDebitoForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    onClose: async () =>
    {
    },
    params: {
      componentName: 'EnviarPlanilhaAtualizacaoDebitoForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'ENVIAR PLANILHA DE ATUALIZAÇÃO DE DÉBITO',
    },
    width: larguraForm,
  });
}

function uiAbrirIncluirAcordoGcpjForm()
{
  drawerStore.push({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'IncluirAcordoGcpjForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'INCLUIR ACORDO NO GCPJ',
    },
    width: larguraForm,
  });
}

function uiAbrirRegistrarOcorrenciaAtendimentoForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'RegistrarOcorrenciaAtendimentoForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'REGISTRAR OCORRÊNCIA DE ATENDIMENTO',
    },
    width: larguraForm,
  });
}

function uiAbrirRegistrarPesquisaCdiForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'RegistrarPesquisaCdiForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'REGISTRAR PESQUISA CDI',
    },
    width: larguraForm,
  });
}

function uiAbrirRegistrarEncerramentoRcbForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'RegistrarEncerramentoRcbForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'REGISTRAR ENCERRAMENTO RCB',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarTransferenciaPastaParaArquivoMortoForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarTransferenciaPastaParaArquivoMortoForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR TRANSFERÊNCIA DA PASTA PARA O ARQUIVO MORTO',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarTransferenciaResponsavelProcessualDaPastaParaOutraAreaForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName:
          'SolicitarTransferenciaResponsavelProcessualDaPastaParaOutraAreaForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title:
          'SOLICITAR TRANSFERÊNCIA RESPONSÁVEL PROCESSUAL DA PASTA PARA OUTRA ÁREA',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarTransferenciaResponsavelNegocialDaPastaParaOutraAreaForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName:
          'SolicitarTransferenciaResponsavelNegocialDaPastaParaOutraAreaForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title:
          'SOLICITAR TRANSFERÊNCIA RESPONSÁVEL NEGOCIAL DA PASTA PARA OUTRA ÁREA',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarAcompanhamentoPagamentoHonorariosForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'SolicitarAcompanhamentoPagamentoHonorariosForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR ACOMPANHAMENTO DE PAGAMENTO DE HONORÁRIOS',
    },
    width: larguraForm,
  });
}

function uiAbrirCriarCompromissoAvulsoForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm2',
    params: {
      componentName: 'CriarCompromissoAvulsoForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'CRIAR COMPROMISSO AVULSO',
    },
    width: larguraForm,
  });
}

function uiAbrirSolicitarAjuizamentoForm()
{
  drawerStore.push2({
    componentName: 'CompromissoForm3',
    params: {
      componentName: 'SolicitarSubsidioForm',
      componentProps: {
        pastaCodigos: [codigo],
      },
      title: 'SOLICITAR SUBSÍDIO',
    },
    width: larguraForm,
  });
}

function onAcoesClicked(data)
{
  if (data.key === 'solicitar-alteracao-pasta')
  {
    uiAbrirSolicitarAlteracaoPastaForm();
  }

  if (data.key === 'solicitar-atualizacao-gcpj')
  {
    uiAbrirSolicitarAtualizacaoGcpjForm();
  }

  if (data.key === 'solicitar-pagamento-custas')
  {
    uiAbrirSolicitarPagarCustasForm();
  }

  if (data.key === 'criar-compromisso-responsavel')
  {
    uiAbrirCriarCompromissoResponsavelForm();
  }

  if (data.key === 'solicitar-servico-cdi')
  {
    uiAbrirSolicitarServicoCdiForm();
  }

  if (data.key === 'registrar-andamento-processual')
  {
    uiAbrirRegistrarAndamentoProcessualForm();
  }

  if (data.key === 'registrar-pesquisa-bens')
  {
    uiAbrirRegistrarPesquisaBensForm();
  }

  if (data.key === 'solicitar-encerramento')
  {
    uiAbrirSolicitarEncerramentoForm();
  }

  if (data.key === 'criar-compromisso-caq')
  {
    uiAbrirCriarCompromissoCaqForm();
  }

  if (data.key === 'criar-compromisso-pub')
  {
    uiAbrirCriarCompromissoPubForm();
  }

  if (data.key === 'enviar-planilha-atualizacao-debito')
  {
    uiAbrirEnviarPlanilhaAtualizacaoDebitoForm();
  }

  if (data.key === 'incluir-acordo-gcpj')
  {
    uiAbrirIncluirAcordoGcpjForm();
  }

  if (data.key === 'registrar-ocorrencia-atendimento')
  {
    uiAbrirRegistrarOcorrenciaAtendimentoForm();
  }

  if (data.key === 'registrar-pesquisa-cdi')
  {
    uiAbrirRegistrarPesquisaCdiForm();
  }

  if (data.key === 'registrar-encerramento-rcb')
  {
    uiAbrirRegistrarEncerramentoRcbForm();
  }

  if (data.key === 'solicitar-transferencia-pasta-para-arquivo-morto')
  {
    uiAbrirSolicitarTransferenciaPastaParaArquivoMortoForm();
  }

  if (
    data.key
    === 'solicitar-transferencia-responsavel-processual-da-pasta-para-outra-area'
  )
  {
    uiAbrirSolicitarTransferenciaResponsavelProcessualDaPastaParaOutraAreaForm();
  }

  if (
    data.key
    === 'solicitar-transferencia-responsavel-negocial-da-pasta-para-outra-area'
  )
  {
    uiAbrirSolicitarTransferenciaResponsavelNegocialDaPastaParaOutraAreaForm();
  }

  if (data.key === 'solicitar-acompanhamento-pagamento-honorarios')
  {
    uiAbrirSolicitarAcompanhamentoPagamentoHonorariosForm();
  }

  if (data.key === 'criar-compromisso-avulso')
  {
    uiAbrirCriarCompromissoAvulsoForm();
  }

  if (data.key === 'solicitar-ajuizamento')
  {
    uiAbrirSolicitarAjuizamentoForm();
  }

  if (data.key === 'anexar-documentos-ao-gcpj')
  {
    abrirForm('AnexarDocumentosAoGcpjForm', codigo);
  }
}

function abrirCompromisso(codigo)
{
  drawerStore.push2({
    componentName: 'CompromissoView',
    params: { compromisso: { codigo } },
  });
}

function abrirForm(formName, pastaCodigo)
{
  drawerStore.push2({
    componentName: 'CompromissoForm3',
    params: {
      componentName: formName,
      componentProps: {
        pastaCodigos: [pastaCodigo],
      },
      title: 'ANEXAR DOCUMENTOS AO GCPJ',
    },
  });
}

onMounted(async () =>
{
  await Promise.all([
    pastaCabecalhoAsync({ codigo }),
    obterResumoPasta(),
  ]);

  if (pastaCabecalhoStatus.value === 'success')
  {
    document.title = pastaCabecalhoData.value.pasta.nome;
  }

  unsubscribe = drawerStore.$subscribe(async (mutation, state) =>
  {
    if (state.activeDrawer === -1)
    {
      await Promise.all([
        pastaCabecalhoAsync({ codigo }),
        onTabChange(tabAtiva.value),
      ]);
    }
  });
});

onUnmounted(() =>
{
  if (unsubscribe)
  {
    unsubscribe();
  }
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <Page menu="pastas">
    <div class="visualizar-pasta-page">
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
        >
          <a-row align="bottom">
            <a-col :span="18">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/pastas">
                    Pastas
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Visualizar</a-breadcrumb-item>
              </a-breadcrumb>

              <a-row align="middle">
                <a-col span="24">
                  <a-skeleton
                    :loading="pastaCabecalhoStatus === 'loading'"
                    :active="true"
                    :paragraph="false"
                    :title="{ width: '35%' }"
                  >
                    <h2
                      v-if="pastaCabecalhoStatus === 'success'"
                      style="margin-top: 4px; margin-right: 8px"
                    >
                      <ProfileOutlined
                        style="color: #bebcbc; font-size: 18px"
                      />
                      {{ pastaCabecalhoData.pasta.nome }}
                      <span style="margin-left: 8px; text-transform: uppercase">
                        <a-tag>{{
                          !isNil(pastaCabecalhoData.pasta.tipoProcesso)
                            ? pastaCabecalhoData.pasta.tipoProcessoText
                            : 'PROCESSO'
                        }}</a-tag>
                        <a-tag>
                          {{ pastaCabecalhoData.pasta.tipoText }}
                        </a-tag>
                        <a-tag>{{ pastaCabecalhoData.pasta.statusText }}</a-tag>
                        <a-tag v-if="pastaCabecalhoData.pasta.revelia === true">REVELIA</a-tag>
                        <a-tag
                          v-if="
                            pastaCabecalhoData.pasta.motivoNaoAjuizamentoText
                          "
                          style="text-transform: uppercase"
                        >{{
                          pastaCabecalhoData.pasta.motivoNaoAjuizamentoText
                        }}</a-tag>
                        <a-tag v-if="solicitacaoEncerramentoPendente === true">ENCERRAMENTO PENDENTE</a-tag>
                      </span>
                    </h2>
                  </a-skeleton>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="6">
              <div
                v-if="pastaCabecalhoStatus === 'success'"
                class="page-header-button-container"
              >
                <a-button
                  style="margin-right: 8px"
                  @click="
                    () => abrirFluxosDrawer(pastaCabecalhoData.pasta.codigo)
                  "
                >
                  Fluxos / Linha do tempo
                </a-button>
                <a-dropdown>
                  <a-button style="margin-right: 8px">
                    Registros <DownOutlined />
                  </a-button>
                  <template #overlay>
                    <a-menu @click="onAcoesClicked">
                      <a-menu-item key="registrar-andamento-processual">
                        Registrar andamento processual
                      </a-menu-item>
                      <a-menu-item key="registrar-pesquisa-bens">
                        Registrar pesquisa de bens
                      </a-menu-item>
                      <a-menu-item key="registrar-ocorrencia-atendimento">
                        Registrar ocorrência de atendimento
                      </a-menu-item>
                      <a-menu-item key="registrar-pesquisa-cdi">
                        Registrar pesquisa CDI
                      </a-menu-item>
                      <a-menu-item
                        v-if="
                          $auth.temPerfilAcesso(
                            PERFIL_ACESSO.FluxoRegistroEncerramentoRcbRegistrarEncerramentoRcb,
                          )
                        "
                        key="registrar-encerramento-rcb"
                      >
                        Registrar encerramento RCB
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
                <a-dropdown>
                  <a-button> Solicitações <DownOutlined /> </a-button>
                  <template #overlay>
                    <a-menu @click="onAcoesClicked">
                      <a-menu-item key="solicitar-alteracao-pasta">
                        Alterar pasta
                      </a-menu-item>
                      <a-menu-item key="solicitar-atualizacao-gcpj">
                        Atualizar GCPJ
                      </a-menu-item>
                      <a-menu-item key="solicitar-pagamento-custas">
                        Pagar custas
                      </a-menu-item>
                      <a-menu-item key="solicitar-servico-cdi">
                        Pesquisa ao CDI
                      </a-menu-item>
                      <a-menu-item key="solicitar-encerramento">
                        Encerramento
                      </a-menu-item>
                      <a-menu-item
                        v-if="!$auth.temPerfilAcesso(PERFIL_ACESSO.Pub)"
                        key="criar-compromisso-responsavel"
                      >
                        Compromisso para responsável
                      </a-menu-item>
                      <a-menu-item
                        v-if="$auth.temPerfilAcesso(PERFIL_ACESSO.Caq)"
                        key="criar-compromisso-caq"
                      >
                        Compromisso CAQ
                      </a-menu-item>
                      <a-menu-item
                        v-if="
                          $auth.temPerfilAcesso(PERFIL_ACESSO.Pub)
                            || $auth.ehGerente
                            || $auth.ehGestor
                        "
                        key="criar-compromisso-pub"
                      >
                        Compromisso PUB
                      </a-menu-item>
                      <a-menu-item key="enviar-planilha-atualizacao-debito">
                        Enviar planilha de atualização de débito
                      </a-menu-item>
                      <a-menu-item key="incluir-acordo-gcpj">
                        Incluir acordo no GCPJ
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-transferencia-pasta-para-arquivo-morto"
                      >
                        Transferência para arquivo morto
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-transferencia-responsavel-processual-da-pasta-para-outra-area"
                      >
                        Transferência responsável processual para outra área
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-transferencia-responsavel-negocial-da-pasta-para-outra-area"
                      >
                        Transferência responsável negocial para outra área
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-acompanhamento-pagamento-honorarios"
                      >
                        Acompanhamento de pagamento de honorários
                      </a-menu-item>
                      <a-menu-item key="criar-compromisso-avulso">
                        Compromisso avulso
                      </a-menu-item>
                      <a-menu-item key="solicitar-ajuizamento">
                        Ajuizamento
                      </a-menu-item>
                      <a-menu-item key="anexar-documentos-ao-gcpj">
                        Anexar documentos ao GCPJ
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>

          <a-row
            v-if="
              $auth.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                || $auth.temPerfilAcesso(PERFIL_ACESSO.Editor)
                || $auth.temPerfilAcesso(PERFIL_ACESSO.EditorContraria)
                || $auth.ehGestor
                || $auth.temPerfilAcesso(EnumPerfil.AlterarPasta)
            "
            style="margin-top: 8px"
          >
            <a-col :span="24">
              <a-button
                v-if="$auth.temPerfilAcesso(PERFIL_ACESSO.AlterarPastaBotao)"
                style="margin-right: 8px"
                @click="uiAbrirAlterarPastaForm"
              >
                Alterar pasta
              </a-button>
              <a-button
                v-if="
                  $auth.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                    || $auth.temPerfilAcesso(PERFIL_ACESSO.Editor)
                    || $auth.ehGestor
                "
                style="margin-right: 8px"
                @click="uiAbrirCadastrarPastaVinculadaForm"
              >
                Cadastrar pasta vinculada
              </a-button>
              <a-button
                v-if="
                  $auth.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                    || $auth.temPerfilAcesso(PERFIL_ACESSO.Editor)
                    || $auth.temPerfilAcesso(PERFIL_ACESSO.EditorContraria)
                    || $auth.ehGestor
                "
                style="margin-right: 8px"
                @click="uiAbrirEnviarArquivosFormForm"
              >
                Enviar arquivos
              </a-button>
              <a-button
                v-if="
                  $auth.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                    || $auth.temPerfilAcesso(PERFIL_ACESSO.Editor)
                    || $auth.temPerfilAcesso(PERFIL_ACESSO.EditorContraria)
                    || $auth.ehGestor
                "
                style="margin-right: 8px"
                @click="
                  uiAbrirSolicitarAtualizacaoGcpjSemEncaminharParaEquipeGcpjForm
                "
              >
                Atualizar GCPJ
              </a-button>
            </a-col>
          </a-row>

          <a-row
            :gutter="8"
            style="margin-top: 24px"
          >
            <a-col :span="4">
              <StatsCard :status="pastaCabecalhoStatus">
                <template #title>
                  NÚMERO CNJ
                </template>
                <template #content>
                  <a-typography-text
                    v-if="pastaCabecalhoData?.pasta?.numeroCnj"
                    copyable
                    style="font-size: 20px; color: rgb(93, 93, 93)"
                  >
                    {{ formatCnj(pastaCabecalhoData.pasta.numeroCnj) }}
                  </a-typography-text>
                  <span
                    v-else
                    style="font-size: 20px; color: rgb(93, 93, 93)"
                  >-</span>
                </template>
              </StatsCard>
            </a-col>
            <a-col :span="5">
              <StatsCard :status="pastaCabecalhoStatus">
                <template #title>
                  RESPONSÁVEL PROCESSUAL
                </template>
                <template #content>
                  <a-typography-link
                    v-if="pastaCabecalhoData?.pasta?.responsavelProcessual"
                    :href="`/usuario/${pastaCabecalhoData.pasta.responsavelProcessual.codigo}`"
                    copyable
                    style="text-transform: uppercase;  font-size: 20px"
                  >
                    {{ pastaCabecalhoData.pasta.responsavelProcessual.nome }}
                  </a-typography-link>
                  <span
                    v-else
                    style="font-size: 20px"
                  >-</span>
                </template>
              </StatsCard>
            </a-col>
            <a-col :span="5">
              <StatsCard :status="pastaCabecalhoStatus">
                <template #title>
                  RESPONSÁVEL NEGOCIAL
                </template>
                <template #content>
                  <a-typography-link
                    v-if="pastaCabecalhoData?.pasta?.responsavelNegocial"
                    :href="`/usuario/${pastaCabecalhoData.pasta.responsavelNegocial.codigo}`"
                    copyable
                    style="text-transform: uppercase; font-size: 20px;"
                  >
                    {{ pastaCabecalhoData.pasta.responsavelNegocial.nome }}
                  </a-typography-link>
                  <span
                    v-else
                    style="font-size: 20px"
                  >-</span>
                </template>
              </StatsCard>
            </a-col>
            <a-col :span="5">
              <StatsCard :status="pastaCabecalhoStatus">
                <template #title>
                  GERENTE NEGOCIAL RESPONSÁVEL
                </template>
                <template #content>
                  <a-typography-link
                    v-if="pastaCabecalhoData?.pasta?.gerenteNegocialResponsavel"
                    :href="`/usuario/${pastaCabecalhoData.pasta.gerenteNegocialResponsavel.codigo}`"
                    copyable
                    style="text-transform: uppercase; font-size: 20px;"
                  >
                    {{ pastaCabecalhoData.pasta.gerenteNegocialResponsavel.nome }}
                  </a-typography-link>
                  <span
                    v-else
                    style="font-size: 20px"
                  >-</span>
                </template>
              </StatsCard>
            </a-col>
            <a-col :span="5">
              <StatsCard :status="pastaCabecalhoStatus">
                <template #title>
                  CONTRÁRIO PRINCIPAL
                </template>
                <template #content>
                  <a-typography-link
                    v-if="pastaCabecalhoData?.pasta?.contrarioPrincipal"
                    :href="`/contato/${pastaCabecalhoData.pasta.contrarioPrincipal.codigo}`"
                    copyable
                    style="text-transform: uppercase; font-size: 20px;"
                  >
                    {{ pastaCabecalhoData.pasta.contrarioPrincipal.nome }}
                  </a-typography-link>
                  <span
                    v-else
                    style="font-size: 20px"
                  >-</span>
                </template>
              </StatsCard>
            </a-col>
          </a-row>

          <a-alert
            v-if="pastaCabecalhoData?.pasta?.pastaPrincipal"
            type="info"
            style="margin-top: 24px"
            show-icon
          >
            <template #message>
              Esse
              <strong style="text-transform: lowercase">{{
                pastaCabecalhoData.pasta.tipoProcessoText
              }}</strong>
              está vinculado à pasta
              <a-tooltip>
                <template #title>
                  Ir para página da pasta principal
                </template>
                <router-link
                  :to="`/pasta/${pastaCabecalhoData.pasta.pastaPrincipal.codigo}`"
                >
                  {{ pastaCabecalhoData.pasta.pastaPrincipal?.nome }}
                </router-link>
              </a-tooltip>
            </template>
          </a-alert>

          <a-card
            style="margin-top: 24px"
            :body-style="{ padding: '0' }"
          >
            <a-tabs
              v-model:activeKey="tabAtiva"
              :animated="false"
              tab-position="left"
              :destroy-inactive-tab-pane="true"
              @change="onTabChange"
            >
              <a-tab-pane
                key="resumo"
                tab="Resumo"
                style="padding: 0"
              >
                <div
                  v-if="obterResumoPastaStatus === 'loading'"
                  style="padding: 0 24px"
                >
                  <a-skeleton
                    :loading="true"
                    :active="true"
                    :paragraph="{ rows: 12 }"
                  />
                </div>
                <div v-else>
                  <ErrorResult v-if="obterResumoPastaStatus === 'error'" />
                  <Resumo
                    v-else
                    :response="obterResumoPastaResponse"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="detalhes"
                tab="Detalhes"
                style="padding: 0"
              >
                <Detalhes />
              </a-tab-pane>
              <a-tab-pane
                key="carteiras-contratos"
                tab="Carteiras/Contratos"
                style="padding: 0"
              >
                <div
                  v-if="obterCarteirasContratosStatus === 'loading'"
                  style="padding: 0 24px"
                >
                  <a-skeleton
                    :loading="true"
                    :active="true"
                    :paragraph="{ rows: 12 }"
                  />
                </div>
                <div v-else>
                  <ErrorResult
                    v-if="obterCarteirasContratosStatus === 'error'"
                    :tentar-novamente-fn="obterCarteirasContratos"
                  />
                  <CarteirasContratos
                    v-else
                    :response="obterCarteirasContratosResponse"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="publicacoes"
                tab="Publicações"
                style="padding: 0"
              >
                <div style="padding: 24px">
                  <PublicacaoNumeroProcessoGraphqlDatatable
                    :graphql-variables="{
                      order: [{ publicacao: { dataMovimento: 'DESC' } }],
                      pastaCodigo: route.params.codigo,
                    }"
                    :text-search="null"
                    :disabled-filters="['*']"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="andamentos"
                tab="Andamentos"
                style="padding: 0"
              >
                <div style="padding: 24px">
                  <AndamentoGqlDatatable
                    :enable-text-search="false"
                    :disabled-filters="['numeroProcesso', 'sistemaTribunal']"
                    :variables="{
                      where: {
                        and: [
                          {
                            pastasAndamentos: {
                              some: {
                                pastaCodigo: {
                                  eq: route.params.codigo,
                                },
                              },
                            },
                          },
                        ],
                      },
                      order: [{ dataMovimentacao: 'DESC' }],
                    }"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="documentos"
                tab="Documentos"
                style="padding: 24px"
              >
                <ArquivoGqlDatatable :variables="{ where: { compromisso: { fluxo: { pastaCodigo: { eq: route.params.codigo } } } } }" />
              </a-tab-pane>
              <a-tab-pane
                key="atualizacoes-gcpj"
                tab="Atualizações GCPJ"
                style="padding: 24px"
              >
                <AtualizacaoGcpjGqlDatatable
                  :variables="{ where: { fluxo: { pastaCodigo: { eq: route.params.codigo } } }, order: [{ anterior: { dataInicio: 'DESC' } }] }"
                  :disabled-filters="['pasta']"
                />
              </a-tab-pane>
              <a-tab-pane
                key="pesquisa-bens"
                tab="Pesquisa de bens"
                style="padding: 24px"
              >
                <h3 style="margin: 24px 0">
                  Registro de pesquisa de bens
                </h3>
                <CompromissoGqlDatatable
                  :enable-row-selection="true"
                  :show-status-search-bar="false"
                  :show-dates-filter="false"
                  :show-filter="false"
                  :variables="{
                    where: {
                      tipo: {
                        eq: 34,
                      },
                      status: {
                        eq: 3,
                      },
                      fluxo: {
                        pastaCodigo: {
                          eq: route.params.codigo,
                        },
                      },
                    },
                  }"
                >
                  <template #compromissoColumn="{ record }">
                    <a
                      href="#"
                      style="text-transform: uppercase"
                      @click.prevent="() => abrirCompromisso(record.codigo)"
                    >{{ record.data.tipoPesquisaBensText }}</a>
                  </template>
                </CompromissoGqlDatatable>
                <h3 style="margin: 24px 0">
                  Pesquisas de bens do CDI
                </h3>
                <PesquisaBensCdiGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="custas"
                tab="Custas"
                style="padding: 24px"
              >
                <CustasGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="cdi"
                tab="CDI"
                style="padding: 24px"
              >
                <h3 style="margin-bottom: 16px">
                  Solicitações de pesquisa de CDI
                </h3>
                <CdiGqlDatatable :pasta-codigo="route.params.codigo" />

                <h3 style="margin-bottom: 16px">
                  Registros de pesquisa de CDI
                </h3>
                <RegistroCdiGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="caq"
                tab="CAQ"
                style="padding: 24px"
              >
                <CaqGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="pub"
                tab="PUB"
                style="padding: 24px"
              >
                <PubGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="planilha-atualizada-debito"
                tab="Planilha atualizada de débito"
                style="padding: 24px"
              >
                <PlanilhaAtualizacaoDebitoGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="ocorrenciasAtendimentos"
                tab="Ocorrências de atendimentos"
                style="padding: 24px"
              >
                <OcorrenciasAtendimentoGqlDatatable :pasta-codigo="route.params.codigo" />
              </a-tab-pane>
              <a-tab-pane
                key="compromissos"
                tab="Compromissos"
                style="padding: 24px"
              >
                <CompromissoGqlDatatable
                  :enable-row-selection="true"
                  :variables="{
                    where: {
                      fluxo: {
                        pastaCodigo: {
                          eq: route.params.codigo,
                        },
                      },
                    },
                    order: [{ dataInicio: 'DESC' }],
                  }"
                />
              </a-tab-pane>
              <a-tab-pane
                key="comentarios"
                tab="Comentários"
                style="padding: 0"
              >
                <Comentarios
                  :codigo-pasta="route.params.codigo"
                  :is-selected="tabAtiva === 'comentarios'"
                />
              </a-tab-pane>

              <a-tab-pane
                key="pastas-vinculadas"
                tab="Pastas vinculadas"
                style="padding: 0"
              >
                <div
                  v-if="obterPastasVinculadasPorPastaStatus === 'loading'"
                  style="padding: 0 24px"
                >
                  <a-skeleton
                    :loading="true"
                    :active="true"
                    :paragraph="{ rows: 12 }"
                  />
                </div>
                <div v-else>
                  <ErrorResult
                    v-if="obterPastasVinculadasPorPastaStatus === 'error'"
                    :tentar-novamente-fn="obterPastasVinculadasPorPasta"
                  />
                  <PastasVinculadas
                    v-else
                    :response="obterPastasVinculadasPorPastaResponse"
                  />
                </div>
              </a-tab-pane>
              <a-tab-pane
                key="audits-envolvidos-novajus"
                tab="Histórico de envolvidos do Novajus"
                style="padding: 0"
              >
                <AuditsEnvolvidosNovajus
                  :codigo-pasta="codigo"
                  :is-selected="tabAtiva === 'audits-envolvidos-novajus'"
                />
              </a-tab-pane>
            </a-tabs>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>

<style lang="less">
.visualizar-pasta-page {
  & .ant-tabs-nav {
    margin-top: 16px;
  }
}

.visualizar-pasta-page .ant-statistic .ant-statistic-content {
  font-size: 20px;
}
</style>
