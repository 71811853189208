query confirmarAjuizamentoForm(
  $pastaCodigo: String
  $compromissoCodigo: String
) {
  model: alterarPastaForm(pastaCodigo: $pastaCodigo, compromissoCodigo: $compromissoCodigo) {
    codigo    
    numeroCnj
    vara
    dataAjuizamento: dataDistribuicao
    acao {
      value: codigo
      label: nome
    }
    responsavelProcessual {
      value: codigo
      label: nome
    }
    responsavelNegocial {
      value: codigo
      label: nome
    }
  }
}
