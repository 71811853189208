<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { onMounted, onUnmounted, ref } from 'vue';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useDrawerStore } from '~/stores/drawer';
import fluxosEspecificosDatatable from '~/graphql/fluxosEspecificosDatatable.graphql';

const drawerStore = useDrawerStore();
const totalFluxosEmAndamento = ref(null);
const totalFluxosConcluidos = ref(null);
const totalFluxosCancelados = ref(null);
const activeTab = ref('geral');

function abrirFluxos(status)
{
  drawerStore.push2({
    componentName: 'CdiGqlDatatable',
    title: 'FLUXOS CDI',
    wrapperStyle: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    params: {
      status,
    },
    onClose: async () =>
    {
      await Promise.all([
        totalFluxosEmAndamento.value.update(),
        totalFluxosConcluidos.value.update(),
        totalFluxosCancelados.value.update(),
      ]);
    },
  });
}

const gqlDatatableVars = {
  where: {
    and: [
      {
        responsavel: {
          perfisAcesso: {
            some: {
              perfil: {
                in: [PERFIL_ACESSO.Cdi],
              },
            },
          },
        },
      },
    ],
  },
};
</script>

<template>
  <Page menu="cdi">
    <div class="cdi-page" style="padding-bottom: 24px">
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                CDI
              </h1>
            </a-col>
            <a-col :span="12" />
          </a-row>

          <a-tabs v-model:activeTab="activeTab">
            <a-tab-pane key="geral" tab="Geral">
              <h2 style="margin-top: 8px">
                Compromissos
              </h2>
              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="6">
                  <CompromissosEmAtrasoGqlCard :perfil="[PERFIL_ACESSO.Cdi]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoHojeGqlCard :perfil="[PERFIL_ACESSO.Cdi]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoProximosDiasGqlCard :perfil="[PERFIL_ACESSO.Cdi]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosConcluidosGqlCard :perfil="[PERFIL_ACESSO.Cdi]" />
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-col :span="24">
                  <UsuariosCompromissosStackChart :perfil="PERFIL_ACESSO.Cdi" />
                </a-col>
              </a-row>

              <h2 style="margin-top: 24px">
                Fluxos
              </h2>
              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="8">
                  <TotalFluxosStats
                    ref="totalFluxosEmAndamento"
                    titulo="EM ANDAMENTO"
                    :status="2"
                    :tipos="[14]"
                    @click="() => abrirFluxos(2)"
                  />
                </a-col>
                <a-col :span="8">
                  <TotalFluxosStats
                    ref="totalFluxosConcluidos"
                    titulo="CONCLUÍDOS"
                    :status="3"
                    :tipos="[14]"
                    @click="() => abrirFluxos(3)"
                  />
                </a-col>
                <a-col :span="8">
                  <TotalFluxosStats
                    ref="totalFluxosCancelados"
                    titulo="CANCELADOS"
                    :status="4"
                    :tipos="[14]"
                    @click="() => abrirFluxos(4)"
                  />
                </a-col>
              </a-row>

              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="12">
                  <TotalSolicitacoesCdiPorTipoChart />
                </a-col>
                <a-col :span="12">
                  <TotalSolicitacoesCdiPorUsuarioChart />
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="compromissos" tab="Compromissos">
              <CompromissoGqlDatatable :enable-row-selection="true":variables="gqlDatatableVars" />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
