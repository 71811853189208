<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import atualizacaoGcpjDatatable from '~/graphql/atualizacaoGcpjDatatable.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import referenciaGcpjAndamentoSelect from '~/graphql/referenciaGcpjAndamentoSelect.graphql';
import referenciaGcpjEncerramentoSelect from '~/graphql/referenciaGcpjEncerramentoSelect.graphql';
import pastaSelect from '~/graphql/pastaSelect.graphql';
import { useDrawerStore } from '~/stores/drawer';

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ anterior: { dataInicio: 'DESC' } }] };
  },
  enableRowSelection: false,
});

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
  enableRowSelection?: boolean
}

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'referenciaGcpjAndamento'))
{
  filters.push({
    key: 'referenciaGcpjAndamento',
    label: 'Referência GCPJ de andamento',
    path: 'referenciasGcpjsAndamentos',
    type: 'gql-select',
    queryKey: 'ra',
    inWhereClause: false,
    componentProps: {
      placeholder: 'Selecione uma ou mais referências',
      query: referenciaGcpjAndamentoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'referenciaGcpjEncerramento'))
{
  filters.push({
    key: 'referenciaGcpjEncerramento',
    label: 'Referência GCPJ de encerramento',
    path: 'referenciasGcpjsEncerramentos',
    type: 'gql-select',
    queryKey: 're',
    inWhereClause: false,
    componentProps: {
      placeholder: 'Selecione uma ou mais referências',
      query: referenciaGcpjEncerramentoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'pasta'))
{
  filters.push({
    key: 'pasta',
    label: 'Pasta',
    path: 'fluxo.pasta.codigo',
    type: 'gql-select',
    queryKey: 'p',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais pastas',
      query: pastaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'solicitadoPor'))
{
  filters.push({
    key: 'solicitadoPor',
    label: 'Solicitado por',
    path: 'anterior.responsavel.codigo',
    type: 'gql-select',
    queryKey: 'sp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais usuários',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'atualizadoPor'))
{
  filters.push({
    key: 'atualizadoPor',
    label: 'Atualizado por',
    path: 'responsavel.codigo',
    type: 'gql-select',
    queryKey: 'ap',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais usuários',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataSolicitacao'))
{
  filters.push({
    key: 'dataSolicitacao',
    label: 'Data de solicitação',
    path: 'anterior.dataInicio',
    type: 'date',
    queryKey: 'ds',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataAtualizacao'))
{
  filters.push({
    key: 'dataAtualizacao',
    label: 'Data de atualização',
    path: 'dataConclusao',
    type: 'date',
    queryKey: 'da',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

const colunas = [
  {
    key: 'referenciaGcpj',
    title: 'REFERÊNCIA',
    width: '150px',
  },
  {
    key: 'pasta',
    title: 'PASTA',
    width: '120px',
  },
  {
    key: 'solicitadoPor',
    title: 'SOLICITADO POR',
    dataIndex: ['anterior', 'responsavel', 'nome'],
    width: 120,
  },
  {
    key: 'dataInicio',
    title: 'DATA SOLICITAÇÃO',
    dataIndex: 'dataInicioText',
    width: 120,
    sorter: true,
    sortBy: ['anterior', 'dataInicio'],
    defaultSortOrder: 'descend',
  },
  {
    key: 'atualizadoPor',
    title: 'ATUALIZADO POR',
    dataIndex: ['responsavel', 'nome'],
    width: 120,
  },
  {
    key: 'dataConclusao',
    title: 'DATA ATUALIZAÇÃO',
    dataIndex: 'dataConclusaoText',
    width: 120,
    sorter: true,
    sortBy: ['dataConclusao'],
  },
] as GraphqlDatatableColumn[];

const drawerStore = useDrawerStore();

function abrirCompromisso(compromissoCodigo: string)
{
  drawerStore.push2({
    componentName: 'CompromissoView',
    onClose: async () =>
    {
      if (tableRef.value)
      {
        await tableRef.value?.reloadAsync();
      }
    },
    params: { compromisso: { codigo: compromissoCodigo } },
  });
}

defineExpose({
  reloadAsync: async () =>
  {
    await tableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="atualizacaoGcpjDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="props.keepHistory"
    :filters="filters"
    row-key="codigo"
  >
    <template #referenciaGcpjColumn="{ record }">
      <a
        href="#"
        style="text-transform: uppercase"
        @click.prevent="abrirCompromisso(record.codigo)"
      >{{ record.anterior.data.referenciaGcpjNome }}</a>
    </template>
    <template #pastaColumn="{ record }">
      <router-link :to="`/pasta/${record.fluxo.pasta.codigo}`">
        {{ record.fluxo.pasta.nome }}
      </router-link>
    </template>
  </GqlDatatable>
</template>
