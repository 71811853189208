<template>
  <a-layout class="page-layout">
    <a-layout-header v-if="$slots.header" class="header">
      <slot name="header" />
    </a-layout-header>
    <a-layout style="height: 100%">
      <a-layout-sider v-if="$slots.sider" class="sider" width="200">
        <slot name="sider" />
      </a-layout-sider>
      <a-layout>
        <a-layout-content
          :style="{ background: '#fff', padding: '0', margin: 0 }"
        >
          <a-row v-if="$slots.title" align="middle" style="padding-top: 12px; padding-bottom: 12px; padding-left: 24px; padding-right: 24px">
            <a-col :span="12">
              <h3 style="margin: 0">
                <slot name="title" />
              </h3>
            </a-col>
            <a-col :span="12" />
          </a-row>
          <a-divider v-if="$slots.title" style="margin: 0" />
          <div style="padding: 24px">
            <slot name="content" />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<style scoped>
.page-layout {
  height: 100%;
}

.page-layout .ant-layout-header {
  background-color: white;
  border-bottom: 1px solid #ededed;
  padding: 0 24px;
}

.page-layout .sider {
  background: #fff;
  border-right: 1px solid #eee;
  height: 100%;
}
</style>
