import { defineStore } from 'pinia';
import axios from 'axios';
import { API_URL } from '~/utils';

export const useStore = defineStore('pastas-visualizar', {
  state: () => ({
    pageStatus: 'loading',
    carregarTabStatus: null,
    comentarStatus: null,
    codigoPasta: null,
    tabAtiva: 'resumo',
    codigo: null,
    pasta: null,
    documentos: [],
    compromissos: [],
    pesquisasBens: [],
    custas: [],
    acordos: [],
    solicitacoesEmissaoBoletoAtualizacaoQuitacao: [],
    solicitacoesEmissaoBoletoHonorarios: [],
    atualizacoesGcpj: [],
    pastasVinculadas: [],
    resumoPublicacoes: [],
    resumoAndamentos: [],
    publicacoes: [],
    comentarios: [],
    andamentos: [],
    solicitacoesCdi: [],
    comentario: null,
  }),
  getters: {
    isLoading: state => state.pageStatus === 'loading' || state.carregarTabStatus === 'loading',
    isPageError: state => state.pageStatus === 'error',
    isPageSuccess: state => state.pageStatus === 'success',
  },
  actions: {
    async pageLoad()
    {
      this.pageStatus = 'loading';
      await this.carregarResumo();
      this.pageStatus = this.carregarTabStatus;
    },
    async carregarResumo()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(`${API_URL}/pastas/obter-resumo`, {
          params: {
            codigo: this.codigo,
          },
          timeout: 120000,
          getAccessToken: true,
        })).data;
        this.pasta = response.pasta;
        this.resumoPublicacoes = response.publicacoes;
        this.resumoAndamentos = response.andamentos;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarDetalhes()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(`${API_URL}/pastas/visualizar`, {
          params: {
            codigo: this.codigo,
          },
          timeout: 120000,
          getAccessToken: true,
        })).data;
        this.pasta = response.pasta;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarDocumentos()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-documentos-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.documentos = response.documentos;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarPublicacoes()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-publicacoes-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.publicacoes = response.publicacoes;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarAndamentos()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (
          await axios.get(
            `${API_URL}/pastas/obter-andamentos-por-pasta`,
            {
              params: {
                codigo: this.codigo,
              },
              timeout: 120000,
              getAccessToken: true,
            },
          )
        ).data;
        this.andamentos = response.andamentos;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarSolicitacoesCdi()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-solicitacoes-cdi-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.solicitacoesCdi = response.solicitacoesCdi;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarCompromissos()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (
          await axios.get(
            `${API_URL}/pastas/obter-compromissos-por-pasta`,
            {
              params: {
                codigo: this.codigo,
              },
              timeout: 120000,
              getAccessToken: true,
            },
          )
        ).data;
        this.compromissos = response.compromissos;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarPesquisasBens()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-pesquisas-bens-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.pesquisasBens = response.pesquisasBens;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarCustas()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(`${API_URL}/pastas/obter-custas-por-pasta`, {
          params: {
            codigo: this.codigo,
          },
          timeout: 120000,
          getAccessToken: true,
        })).data;
        this.custas = response.custas;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarAcordos()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-acordos-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.acordos = response.acordos;
        this.solicitacoesEmissaoBoletoAtualizacaoQuitacao
          = response.solicitacoesEmissaoBoletoAtualizacaoQuitacao;
        this.solicitacoesEmissaoBoletoHonorarios
          = response.solicitacoesEmissaoBoletoHonorarios;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarAtualizacoesGcpj()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-atualizacoes-gcpj-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.atualizacoesGcpj = response.atualizacoesGcpj;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarPastasVinculadas()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-pastas-vinculadas-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.pastasVinculadas = response.pastas;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async carregarComentarios()
    {
      this.carregarTabStatus = 'loading';
      try
      {
        const response = (await axios.get(
          `${API_URL}/pastas/obter-comentarios-por-pasta`,
          {
            params: {
              codigo: this.codigo,
            },
            timeout: 120000,
            getAccessToken: true,
          },
        )).data;
        this.comentarios = response.comentarios;
        this.carregarTabStatus = 'success';
      }
      catch (e)
      {
        this.carregarTabStatus = 'error';
      }
    },
    async adicionarComentario()
    {
      this.comentarStatus = 'loading';
      try
      {
        await axios.post(`${API_URL}/pastas/adicionar-comentario`, {
          codigo: this.codigo,
          texto: this.comentario,
        }, {
          timeout: 120000,
          getAccessToken: true,
        });
        this.comentarStatus = 'success';
        this.comentario = null;
      }
      catch (e)
      {
        this.comentarStatus = 'error';
      }
    },
  },
});
