query alterarPastaForm(
  $pastaCodigo: String
  $compromissoCodigo: String
) {
  model: alterarPastaForm(pastaCodigo: $pastaCodigo, compromissoCodigo: $compromissoCodigo) {
    codigo    
    nome
    pastaPrincipalCodigo
    pastaPrincipal {
      value: codigo
      label: nome
    }
    tipoCodigo: tipo
    tipoText
    tipoProcessoCodigo: tipoProcesso
    tipoProcessoText
    statusCodigo: status
    statusText
    empresaCodigo: empresa
    empresaText
    responsavelProcessualCodigo
    responsavelProcessual {
      value: codigo
      label: nome
    }
    responsavelNegocialCodigo
    responsavelNegocial {
      value: codigo
      label: nome
    }
    gerenteNegocialResponsavelCodigo
    gerenteNegocialResponsavel {
      value: codigo
      label: nome
    }
    valorEnvolvido
    valorEstoque
    dataRemessa
    dataCadastroGcpj
    clientePrincipalCodigo
    clientePrincipal {
      label: nome
      value: codigo
    }
    clientePrincipalPapelCodigo: clientePrincipalPapel
    clientePrincipalPapelText
    contrarioPrincipalCodigo
    contrarioPrincipal {
      label: nome
      value: codigo
    }
    contrarioPrincipalPapelCodigo: contrarioPrincipalPapel
    contrarioPrincipalPapelText
    gcpj
    gcpjAtivo
    dejur
    empresaBanco
    agenciaCodigo: agencia2Codigo
    agencia: agencia2 {
      label
      value: codigo
      nome
      numero
      regional
      porte
      estado {
        sigla
      }
    }
    conta
    contaFicticia
    estadoCodigo
    estado {
      label: nome
      value: codigo
    }
    cidadeCodigo
    cidade {
      label: nome
      value: codigo
    }
    dataFatoGerador
    garantias
    avalistas: avalistas2 {
      label: nome
      value: codigo
    }
    advogadoBanco
    valorContrato
    informacaoSobreObito
    dataDeclaracaoIrrecuperabilidade
    dataEncerramento
    motivoEncerramento
    depositarioFielCodigo
    depositarioFiel {
      label: nome
      value: codigo
    }
    telaOperacao
    dataCelebracaoContratoOriginal
    dataVencimentoFinalParcelaContratoOriginal
    dataPrimeiraParcelaVencida
    numeroCnj
    numeroAntigo
    valorCausa
    naturezaCodigo: natureza
    naturezaText
    procedimentoCodigo: procedimento
    procedimentoText
    acaoCodigo
    acao {
      label: nome
      value: codigo
    }
    orgaoCodigo
    orgao {
      label: nome
      value: codigo
    }
    justicaCodigo
    justica {
      label: nome
      value: codigo
    }
    instanciaCodigo
    instancia {
      label: nome
      value: codigo
    }
    classeCodigo
    classe {
      label: nome
      value: codigo
    }
    assuntoCodigo
    assunto {
      label: nome
      value: codigo
    }
    vara
    comarcaCodigo
    comarca {
      label: nome
      value: codigo
    }
    atualizacaoValorCausa
    dataAtualizacaoValorCausa
    motivoNaoAjuizamentoCodigo: motivoNaoAjuizamento
    motivoNaoAjuizamentoText
    dataDistribuicao
    custasIniciais
    custasProtesto
    custasOficialJustica
    dataHabilitacao
    procuracaoFls
    arquivamentoProvisorio: arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
    dataSuspensaoAusenciaBensPenhoraveis
    dataLiminar
    dataCitacao
    solicitacaoConversaoExecucao
    dataSolicitacaoConversaoExecucao: dataSolicitacaoConversao
    dataConversaoExecucao: dataConversao
    dataSentenca
    dataBaixa
    dataResultado
    motivoResultado
    resultado
    revelia
    fundamentoSentenca
    dataApreensao
    aptoApreensao: ehAptoParaApreensao
    inviavel: ehInviavel
    apreensaoVeiculo
    placa
    chassiRenavan
    teveAcordo
    responsavelAcordoCodigo
    responsavelAcordo {
      label: nome
      value: codigo
    }
    tipoAcordo
    dataAcordo
    quantidadeParcelas
    valorTotalAcordo
    valorEntradaAcordo
    valorFinanciadoAcordo
    taxaJurosAcordo
    dataVencimentoPrimeiraParcelaAcordo
    dataVencimentoUltimaParcelaAcordo
    acordoEstaSendoCumprido
    dataDescumprimentoAcordo
    historicoDescumprimento
    teveContabilizacaoAcordo
    volumetria
    plano
    valorParcelaContratoOriginal
    dataVencimentoInicial
    motivoEmenda
    liminarDeferida
    dataRespostaLiminar
    motivoNaoDeferimentoLiminar
    agravoInstrumento
    mandadoApreensaoExpedido
    dataMandadoApreensaoExpedido
    liberacaoVendaVeiculo
    dataLiberacaoVendaVeiculo
    acaoMandadoNegativo
    bemLocalizado
    acaoBemNaoLocalizado
    ultimoEvento
    deposito
    valorDeposito
    valorLevantadoPeloBanco
    valorLevantadoPelaParte
    penhora
    tipoPenhora
    valorPenhora
    substabelecido
    carteirasContratos {
      codigo
      carteira {
        codigo
        tipo
        nome
        label
      }
      contrato
      vigente
      original
    }
  }
}
