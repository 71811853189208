<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { create as maskCreate } from 'maska';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty, onlyAlphanumeric } from '~/utils';
import confirmarAjuizamentoForm from '~/graphql/confirmarAjuizamentoForm.graphql';
import acaoSelect from '~/graphql/acaoSelect.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const authStore = useAuthStore();

let mask: any = null;

const numeroCnjInputRef = ref<any>(null);
const peticaoInicialRef = ref<any>(null);

async function preSubmit(model: any)
{
  await peticaoInicialRef.value.upload();

  model.numeroCnj = onlyAlphanumeric(model.numeroCnj);

  return true;
}

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  if (mask)
  {
    mask.destroy();
  }
});

watch(numeroCnjInputRef, (val) =>
{
  if (val)
  {
    mask = maskCreate('.numero-cnj', {
      mask: '#######-##.####.#.##.####',
    });
  }
});
</script>

<template>
  <div>
    <GqlView
      :query="confirmarAjuizamentoForm"
      :variables="{
        pastaCodigo: props.compromisso.fluxo.pastaCodigo,
      }"
    >
      <template #success="{ data }">
        <CompromissoCommandForm2
          :command="compromisso.command2"
          :submit-button-disabled="!isNilOrEmpty(compromisso)
            && compromisso.responsavel.codigo !== authStore.codigoUsuario"
          :initial-model="{
            compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
            ...(data as any)?.model,
          }"
          :pre-submit="preSubmit"
          @close="() => emit('close')"
          @submit-success="() => emit('success')"
        >
          <template #form="{ model }">
            <a-form-item
              label="Data de ajuizamento"
              name="dataAjuizamento"
              :rules="{
                message: 'Data de ajuizamento',
                required: true,
              }"
            >
              <a-date-picker
                v-model:value="model.dataAjuizamento"
                format="DD/MM/YYYY"
                value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                placeholder="Selecione uma data"
                style="width: 100%"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item
              label="Número CNJ"
              name="numeroCnj"
              :rules="{ required: true, message: 'Número CNJ é obrigatório' }"
            >
              <a-input
                ref="numeroCnjInputRef"
                v-model:value="model.numeroCnj"
                :allow-clear="true"
                class="numero-cnj"
              />
            </a-form-item>

            <a-form-item
              label="Vara"
              name="vara"
              :rules="{ required: true, message: 'Vara é obrigatória' }"
            >
              <a-input
                v-model:value="model.vara"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item
              label="Ação"
              name="acao"
              :rules="{
                message: 'Ação é obrigatória',
                required: true,
                trigger: 'change',
                type: 'object',
              }"
            >
              <GqlSelect
                v-model:value="model.acao"
                :query="acaoSelect"
                placeholder="Selecione a ação"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item
              label="Responsável processual"
              name="responsavelProcessual"
              :rules="{
                message: 'Responsável processual é obrigatório',
                required: true,
                trigger: 'change',
                type: 'object',
              }"
            >
              <GqlSelect
                v-model:value="model.responsavelProcessual"
                :query="usuarioSelect"
                placeholder="Selecione o responsável"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item
              label="Responsável negocial"
              name="responsavelNegocial"
              :rules="{
                message: 'Responsável negocial é obrigatório',
                required: true,
                trigger: 'change',
                type: 'object',
              }"
            >
              <GqlSelect
                v-model:value="model.responsavelNegocial"
                :query="usuarioSelect"
                placeholder="Selecione o responsável"
                :allow-clear="true"
              />
            </a-form-item>

            <a-form-item
              label="Petição inicial"
              name="peticaoInicial"
              :rules="{
                message: 'Petição inicial é obrigatória',
                required: true,
                trigger: 'change',
                type: 'object',
              }"
            >
              <UploadFile
                ref="peticaoInicialRef"
                v-model:file="model.peticaoInicial"
              />
            </a-form-item>
          </template>
        </CompromissoCommandForm2>
      </template>
    </GqlView>
  </div>
</template>
