import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash-es';
import Maska from 'maska';
import numeral from 'numeral';
import { createPinia } from 'pinia';
import { createApp, h, provide } from 'vue';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, concat } from '@apollo/client/core';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueApolloComponents from '@vue/apollo-components';
import { createApolloProvider } from '@vue/apollo-option';
import { Notifier } from '@airbrake/browser';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import {
  API_URL,
  dashedToUppercase,
  dateFormatOr,
  fText,
  formatCep,
  formatCnj,
  formatCpfOuCnpj,
  formatDinheiro,
  formatNumero,
  formatTelefone,
  getGoogleDriveUrl,
  isNilOrEmpty,
  onlyDate,
  simOuNao,
} from './utils';
import { useAuthStore } from './stores/auth';

const authMiddleware = new ApolloLink((operation, forward) =>
{
  const authStore = useAuthStore();
  const token = authStore.accessToken;
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
  return forward(operation);
});

const roundTripLink = new ApolloLink((operation, forward) =>
{
  return forward(operation);
});

const httpLink = new HttpLink({ uri: `${API_URL}/graphql` });

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    roundTripLink,
    authMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

dayjs.extend(utc);
dayjs.extend(timezone);

numeral.register('locale', 'pt-br', {
  abbreviations: {
    billion: 'b',
    million: 'milhões',
    thousand: 'mil',
    trillion: 't',
  },
  currency: {
    symbol: 'R$',
  },
  delimiters: {
    decimal: ',',
    thousands: '.',
  },
  ordinal()
  {
    return 'º';
  },
});
numeral.locale('pt-br');

const erMixin = {
  data: () => ({ API_URL }),
  methods: {
    dateFormatOr,
    fText,
    formatCep,
    formatCnj,
    formatCpfOuCnpj,
    formatDinheiro,
    formatNumero,
    formatTelefone,
    getGoogleDriveUrl,
    isNil,
    isNilOrEmpty,
    onlyDate,
    simOuNao,
  },
};

const pinia = createPinia();
const app = createApp({
  setup()
  {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
});
app.config.productionTip = false;
app.use(apolloProvider);
app.use(VueApolloComponents);
app.use(pinia);
app.use(router);
app.use(Maska);

if (import.meta.env.VITE_ENVIRONMENT === 'production')
{
  const airbrake = new Notifier({
    environment: 'production',
    projectId: 591008,
    projectKey: 'a3c03262587bd74d4d1f253522b5b215',
  });

  app.config.errorHandler = function (err, _vm, info)
  {
    airbrake.notify({
      error: err,
      params: { info },
    });
  };

  Sentry.init({
    app,
    dsn: 'https://20fcb64429224015beffd2a0e81f84fa@o1334121.ingest.us.sentry.io/6600286',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [`https://${API_URL}`],

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const components = import.meta.glob('./components/**/*.vue', { eager: true });
Object.entries(components).forEach(async ([path, definition]) =>
{
  const pathArray = path.split('/');
  const fileName = pathArray.pop();
  const lastDirectoryname = pathArray.pop();

  const componentName
      = fileName === 'index.vue'
        ? dashedToUppercase(lastDirectoryname)
        : fileName.replace(/\.\w+$/, '');

  app.component(componentName, definition.default);
});

app.mixin(erMixin);
app.mount('#app');
