<script setup>
import { useObterJob } from '~/composables/jobs/useObterJob';
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from '@ant-design/icons-vue';
import { onMounted } from 'vue';

const props = defineProps(['codigo']);

const emit = defineEmits(['close']);

const {
  obter: obterJob,
  response: obterJobResponse,
  status: obterJobStatus,
} = useObterJob({ codigoJob: props.codigo });

onMounted(async () => {
  await obterJob();
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => $emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          RESULTADO IMPORTAÇÃO</span
        >
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <div v-if="obterJobStatus === 'loading'">
        <a-card>
          <a-skeleton :loading="true" :active="true" />
        </a-card>
        <a-card style="margin-top: 24px">
          <a-skeleton :loading="true" :active="true" />
        </a-card>
      </div>
      <ErrorResult v-else-if="obterJobStatus === 'error'" />

      <div v-else-if="obterJobResponse.job.tipo === 46">
        <a-alert
          v-if="obterJobResponse.job.status === 2"
          type="success"
          message="Importação realizada com sucesso"
          show-icon
        ></a-alert>
        <a-alert
          v-if="obterJobResponse.job.status === 3"
          type="error"
          message="Tivemos algum problema na importação da planilha"
          show-icon
        ></a-alert>
      </div>
      <div
        v-else-if="
          obterJobResponse.job.tipo === 2 || obterJobResponse.job.tipo === 3
        "
      >
        <h3>Processos que tiveram problemas ao serem importados</h3>

        {{ obterJobResponse.job }}

        <a-list
          style="margin-top: 24px"
          bordered
          item-layout="vertical"
          :data-source="
            obterJobResponse.job.resultado.items.filter((x) => x.status === 2)
          "
          :locale="{
            emptyText: 'Nenhum processo teve problema para ser importado',
          }"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <a-row>
                <a-col :span="24">
                  <ExclamationCircleTwoTone two-tone-color="orange" />
                  <span style="margin-left: 8px"
                    >GCPJ: <strong>{{ item.gcpj }}</strong></span
                  >
                  <a-divider type="vertical" />
                  <span style="text-transform: uppercase"
                    >Mensagem: <strong>{{ item.message }}</strong></span
                  >
                </a-col>
              </a-row>
            </a-list-item>
          </template>
        </a-list>

        <h3 style="margin-top: 24px">
          Processos que foram importados com sucesso
        </h3>

        <a-list
          style="margin-top: 24px"
          bordered
          item-layout="vertical"
          :data-source="
            obterJobResponse.job.resultado.items.filter((x) => x.status === 1)
          "
          :locale="{
            emptyText: 'Nenhum processo foi importado com sucesso',
          }"
        >
          <template #renderItem="{ item }">
            <a-list-item>
              <a-row>
                <a-col :span="24">
                  <CheckCircleTwoTone two-tone-color="#52c41a" />
                  <a-tooltip>
                    <template #title> Ir para pasta </template>
                    <span style="margin-left: 8px">Pasta:</span>
                    <a
                      class="sub-titulo-value"
                      :href="`/pasta/${item.pasta.codigo}`"
                      style="margin-left: 8px"
                      >{{ item.gcpj }}</a
                    >
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip>
                    <template #title> Ir para contrário </template>
                    <span style="margin-left: 8px">Contrário:</span>
                    <a
                      class="sub-titulo-value"
                      :href="`/contato/${item.pasta.contrarioCodigo}`"
                      style="margin-left: 8px"
                      >{{ item.pasta.contrarioNome }}
                    </a>
                  </a-tooltip>
                </a-col>
              </a-row>
            </a-list-item>
          </template>
        </a-list>
      </div>
      <!-- <a-spin :spinning="store.carregarStatus === 'loading'">
        <div
          v-if="store.carregarStatus !== 'success'"
          style="height: 200px"
        />
        <div v-if="store.carregarStatus === 'success'">

        </div>
      </a-spin> -->
    </div>
  </div>
</template>
