query andamentoDatatable(
  $text: String
  $where: AndamentoModelFilterInput
  $order: [AndamentoModelSortInput!]
  $skip: Int
  $take: Int
) {
  data: andamentosOffsetPagination(
    text: $text
    where: $where
    order: $order
    skip: $skip
    take: $take
  ) {
    items {
      codigo
      numeroProcesso
      sistemaTribunal
      sistemaTribunalText
      movimentacao
      dataMovimentacao
      dataMovimentacaoText
    }
    totalCount
  }
}


