query usuarioDatatable(
  $text: String
  $where: UsuarioModelFilterInput
  $order: [UsuarioModelSortInput!]
  $skip: Int
  $take: Int
) {
  data: usuariosOffsetPagination(
    text: $text
    where: $where
    order: $order
    skip: $skip
    take: $take
  ) {
    items {
      codigo
      nome
      ativo
      dataAcesso
      dataAcessoText
      perfisAcesso {
        perfil
      }
      equipes {
        perfil
        perfilText 
      }
      gerentes {
        nome
      }
    }
    totalCount
  }
}
