import { defineStore } from 'pinia';
import axios from 'axios';
import { API_URL, error } from '~/utils';
import { useAuthStore } from '~/stores/auth';

export async function login(cmd) {
  const response = await axios.post(`${API_URL}/autenticacao/login`, cmd, {
    withCredentials: true,
    timeout: 120000,
  });
  return response.data;
}

export const useStore = defineStore('login', {
  state: () => ({
    status: null,
    error: null,
    email: null,
    senha: null,
  }),
  actions: {
    async login() {
      const $auth = useAuthStore();
      this.status = 'loading';
      try {
        const response = await login({
          email: this.email,
          senha: this.senha,
        });
        $auth.accessToken = response.accessToken;
        $auth.defUsuario({
          codigo: response.codigoUsuario,
          nome: response.nomeUsuario,
          perfisAcesso: response.perfisAcesso,
          ehAdvogado: response.ehAdvogado,
          ehGestor: response.ehGestor,
          ehGerente: response.ehGerente,
          timeZone: response.timeZone,
        });
        $auth.iniciarTimeoutRefreshToken();
        this.status = 'success';
      } catch (e) {
        this.error = error(e).error;
        this.status = 'error';
      }
    },
  },
});
