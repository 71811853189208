import { API_URL } from '~/utils';
import { usePost } from '../usePost';

export function useCancelarFluxo() {
  return usePost({
    url: `${API_URL}/fluxos/cancelar-fluxo`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
