<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { onMounted, onUnmounted, reactive, ref, toRaw, watch } from 'vue';
import { notification } from 'ant-design-vue';
import type { NotificationArgsProps } from 'ant-design-vue/es/notification';
import { CloudUploadOutlined, SaveOutlined } from '@ant-design/icons-vue';
import { isEqual } from 'lodash-es';
import { useJobCompromissoCommand } from '~/composables/useJobCompromissoCommand';

export type PostQueryProcessData = (data: any) => void;
export type PreSubmitProcessData = (data: any) => any;
export type OnMounted = (data: any) => void;
export type OnUnmounted = (data: any) => void;

interface Props
{
  query?: DocumentNode
  queryParams?: any
  command: string
  initialModel?: object
  notificationOnSubmitSuccess?: NotificationArgsProps
  pastaCodigos?: string[]
  compromissoCodigos?: string[]
  publicacaoNumeroProcessoCodigos?: string[]
  andamentoCodigos?: string[]
  postQueryProcessData?: PostQueryProcessData
  preSubmitProcessData?: PreSubmitProcessData
  onMounted?: OnMounted
  onUnmounted?: OnUnmounted
  showUploadFiles?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showUploadFiles: true,
});
const emit = defineEmits(['close']);

const { status, jobCompromissoCommand, err, data } = useJobCompromissoCommand();

let queryLoad: Function;
let queryRefetch: Function;
let queryResult: any;
if (props.query)
{
  const { load, refetch, result } = useLazyQuery(props.query, props.queryParams, { errorPolicy: 'none', fetchPolicy: 'no-cache' });
  queryLoad = load;
  queryRefetch = refetch;
  queryResult = result;
}

const formRef = ref<any>();
const uploaderStatus = ref<any>(null);
const uploaderRef = ref<any>(null);
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
const queryStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
let originalModel: any = {};
const model = reactive<any>(props.initialModel ?? {});

async function loadQuery()
{
  if (props.query)
  {
    try
    {
      queryStatusRef.value = 'loading';
      await (queryLoad() || queryRefetch());

      if (props.postQueryProcessData)
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
        await props.postQueryProcessData(model);
      }
      else
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
      }

      originalModel = { ...toRaw(model) };

      queryStatusRef.value = 'success';
    }
    catch (e)
    {
      console.log(e);
      queryStatusRef.value = 'error';
    }
  }
  else
  {
    queryStatusRef.value = 'success';
  }
}

async function submit()
{
  await formRef.value.validate();

  submitStatusRef.value = 'loading';

  if (uploaderRef.value)
  {
    await uploaderRef.value.upload();
  }

  const commandData: any = {
    pastaCodigos: props.pastaCodigos,
    compromissoCodigos: props.compromissoCodigos,
    publicacaoNumeroProcessoCodigos: props.publicacaoNumeroProcessoCodigos,
    andamentoCodigos: props.andamentoCodigos,
  };

  const r = props.preSubmitProcessData
    ? await props.preSubmitProcessData(model)
    : {};

  const resultModel = { ...toRaw(model), ...r };

  Object.keys(resultModel).forEach((key: string) =>
  {
    if (key === 'arquivos')
    {
      commandData[key] = resultModel[key];
    }
    else if (!(key in originalModel))
    {
      commandData[key] = {
        changed: true,
        value: resultModel[key],
      };
    }
    else
    {
      const changed = !isEqual(resultModel[key], originalModel[key]);
      commandData[key] = {
        changed,
        value: resultModel[key],
      };
    }
  });

  await jobCompromissoCommand(props.command, commandData);
}

onMounted(async () =>
{
  await loadQuery();

  if (props.onMounted)
  {
    await props.onMounted(model);
  }
});

onUnmounted(async () =>
{
  if (props.onUnmounted)
  {
    await props.onUnmounted(model);
  }
});

watch(status, (val) =>
{
  if (val === 'success')
  {
    submitStatusRef.value = 'success';

    if (props.notificationOnSubmitSuccess)
    {
      notification.success(props.notificationOnSubmitSuccess);
    }
  }

  if (val === 'error')
  {
    if (err.value?.status === 3 && err.value.resultadoJsonDocument.status === 400)
    {
      notification.error({
        message: err.value.resultadoJsonDocument.message,
        duration: 10,
      });
    }
    else
    {
      notification.error({
        message: 'Tivemos um erro inesperado',
        description: 'Favor entrar em contato com o suporte',
        duration: 10,
      });
    }

    submitStatusRef.value = 'error';
  }
});

defineExpose({
  model,
});
</script>

<template>
  <a-skeleton v-if="queryStatusRef === 'loading'" active :paragraph="{ rows: 6 }" />
  <a-result v-else-if="queryStatusRef === 'error'" status="500" title="Tivemos um erro inesperado" sub-title="Favor entrar em contato com o suporte">
    <template #extra>
      <a-button @click="loadQuery">
        Tentar novamente
      </a-button>
    </template>
  </a-result>
  <a-form
    v-else-if="queryStatusRef === 'success' && status !== 'success' || props.notificationOnSubmitSuccess"
    ref="formRef"
    :model="model"
    name="basic"
    autocomplete="off"
    layout="vertical"
  >
    <slot name="form" :model="model" />

    <Uploader
      v-if="props.showUploadFiles"
      ref="uploaderRef"
      v-model:fileList="model.arquivos"
      style="margin-top: 24px"
      @start="() => (uploaderStatus = 'uploading')"
      @end="() => (uploaderStatus = 'done')"
    >
      <template #description>
        <p class="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
        <p class="ant-upload-text">
          Clique aqui ou arraste os arquivos
        </p>
        <p class="ant-upload-hint">
          Envie os arquivos necessários
        </p>
      </template>
    </Uploader>

    <a-form-item style="margin-top: 24px">
      <slot name="submitButton" :command-status="status" :submit="submit">
        <a-button type="primary" :loading="submitStatusRef === 'loading'" size="large" @click.prevent="submit">
          <template #icon>
            <SaveOutlined />
          </template>
          Salvar
        </a-button>
      </slot>
    </a-form-item>
  </a-form>

  <a-result
    v-if="status === 'success'"
    status="success"
    title="Registro realizado com sucesso"
    sub-title="Agora você pode fechar essa janela"
  >
    <template #extra>
      <a-button @click="() => emit('close')">
        Fechar
      </a-button>
      <slot name="onSubmitSuccessExtra" :data="data" />
    </template>
  </a-result>
</template>
