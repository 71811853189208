<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, watch } from 'vue';
import { useTotalAcordosUltimosMeses } from '~/composables/pastas/useTotalAcordosUltimosMeses';
import { useTotalPastasAtivasUltimosMeses } from '~/composables/pastas/useTotalPastasAtivasUltimosMeses';
import { useTotalPastasPorResponsavel } from '~/composables/pastas/useTotalPastasPorResponsavel';
import { useTotalPastasPorStatus } from '~/composables/pastas/useTotalPastasPorStatus';
import { useTotalValorAcordosNoMes } from '~/composables/pastas/useTotalValorAcordosNoMes';
import { useTotalValoresCausas } from '~/composables/pastas/useTotalValoresCausas';
import { useValoresCausasUltimosMeses } from '~/composables/pastas/useValoresCausasUltimosMeses';
import { useTotalPublicacoesUltimosDias } from '~/composables/publicacoes/useTotalPublicacoesUltimosDias';
import PERFIL_ACESSO from '~/perfilAcesso';
import { colors, formatDinheiro } from '~/utils';

const activeTab = ref('pastas');
const PERFIL_GGP = 58;

const {
  fetch: totalPastasAtivasUltimosMesesFetch,
  response: totalPastasAtivasUltimosMeses,
  status: totalPastasAtivasUltimosMesesStatus,
} = useTotalPastasAtivasUltimosMeses({
  gerencia: PERFIL_GGP,
});

const {
  fetch: valoresCausasUltimosMesesFetch,
  response: valoresCausasUltimosMeses,
  status: valoresCausasUltimosMesesStatus,
} = useValoresCausasUltimosMeses({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalValoresCausasFetch,
  response: totalValoresCausas,
  status: totalValoresCausasStatus,
} = useTotalValoresCausas({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalValorAcordosNoMesFetch,
  response: totalValorAcordosNoMes,
  status: totalValorAcordosNoMesStatus,
} = useTotalValorAcordosNoMes({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalAcordosUltimosMesesFetch,
  response: totalAcordosUltimosMeses,
  status: totalAcordosUltimosMesesStatus,
} = useTotalAcordosUltimosMeses({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalPublicacoesUltimosDiasFetch,
  response: totalPublicacoesUltimosDias,
  status: totalPublicacoesUltimosDiasStatus,
} = useTotalPublicacoesUltimosDias({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalPastasPorStatusFetch,
  response: totalPastasPorStatus,
  status: totalPastasPorStatusStatus,
} = useTotalPastasPorStatus({
  gerencia: PERFIL_GGP,
});

const {
  fetch: totalPastasPorResponsavelFetch,
  response: totalPastasPorResponsavel,
  status: totalPastasPorResponsavelStatus,
} = useTotalPastasPorResponsavel({
  gerencia: PERFIL_GGP,
});

const totalPastasAtivasUltimosMesesOptions = ref(null);
const valoresCausasUltimosMesesOptions = ref(null);
const totalAcordosUltimosMesesOptions = ref(null);
const totalPublicacoesUltimosDiasOptions = ref(null);
const totalPastasPorStatusOptions = ref(null);
const totalPastasPorResponsavelOptions = ref(null);

watch(
  () => totalPastasAtivasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasAtivasUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalPastasAtivasUltimosMeses.value.map(x => x.total),
            name: 'Pastas ativas',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalPastasAtivasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => valoresCausasUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      valoresCausasUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: valoresCausasUltimosMeses.value.map(x => x.total),
            name: 'Valores causas',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: valoresCausasUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
        yaxis: {
          labels: {
            formatter: (value) =>
            {
              return formatDinheiro(value);
            },
          },
        },
      };
    }
  },
);

watch(
  () => totalAcordosUltimosMesesStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalAcordosUltimosMesesOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalAcordosUltimosMeses.value.map(x => x.total),
            name: 'Acordos',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalAcordosUltimosMeses.value.map(
            x => `${x.mes}/${x.ano}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalPublicacoesUltimosDiasStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPublicacoesUltimosDiasOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'line',
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        series: [
          {
            data: totalPublicacoesUltimosDias.value.map(x => x.total),
            name: 'Publicações',
          },
        ],
        stroke: {
          curve: 'straight',
        },
        xaxis: {
          categories: totalPublicacoesUltimosDias.value.map(
            x => `${x.dia}/${x.mes}`,
          ),
        },
      };
    }
  },
);

watch(
  () => totalPastasPorStatusStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasPorStatusOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        colors,
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        series: [
          {
            data: totalPastasPorStatus.value.map(x => x.total),
            name: 'total de pastas',
          },
        ],
        xaxis: {
          categories: totalPastasPorStatus.value.map(x => x.status),
          labels: {
            show: false,
          },
        },
      };
    }
  },
);

watch(
  () => totalPastasPorResponsavelStatus.value,
  (val) =>
  {
    if (val === 'success')
    {
      totalPastasPorResponsavelOptions.value = {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          type: 'bar',
          zoom: {
            enabled: false,
          },
        },
        colors,
        plotOptions: {
          bar: {
            distributed: true,
          },
        },
        series: [
          {
            data: totalPastasPorResponsavel.value.map(x => x.total),
            name: 'total de pastas',
          },
        ],
        xaxis: {
          categories: totalPastasPorResponsavel.value.map(x => x.nome),
          labels: {
            show: false,
          },
        },
      };
    }
  },
);

async function loadPastasTabPane()
{
  await Promise.all([
    totalPastasAtivasUltimosMesesFetch(),
    valoresCausasUltimosMesesFetch(),
  ]);

  await Promise.all([
    totalValoresCausasFetch(),
    totalValorAcordosNoMesFetch(),
    totalAcordosUltimosMesesFetch(),
    totalPublicacoesUltimosDiasFetch(),
    totalPastasPorStatusFetch(),
    totalPastasPorResponsavelFetch(),
  ]);
}

async function onTabChange(val)
{
  activeTab.value = val;
  if (val === 'pastas')
  {
    await loadPastasTabPane();
  }
}

onMounted(async () =>
{
  await loadPastasTabPane();
});

const gqlDatatableVars = {
  where: {
    and: [
      {
        responsavel: {
          perfisAcesso: {
            some: {
              perfil: {
                in: [PERFIL_ACESSO.Ggp],
              },
            },
          },
        },
      },
    ],
  },
};
</script>

<template>
  <Page menu="ggp">
    <div class="ggp-page" style="padding-bottom: 24px">
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                GGP
              </h1>
            </a-col>
            <a-col :span="12" />
          </a-row>

          <a-tabs
            v-model:activeTab="activeTab"
            :destroy-inactive-tab-pane="true"
            @change="onTabChange"
          >
            <a-tab-pane key="pastas" tab="Geral" :force-render="true">
              <h2 style="margin-top: 8px">
                Compromissos
              </h2>

              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="6">
                  <CompromissosEmAtrasoGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoHojeGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosVencimentoProximosDiasGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <CompromissosConcluidosGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-col :span="24">
                  <UsuariosCompromissosStackChart :perfil="PERFIL_ACESSO.Ggp" />
                </a-col>
              </a-row>

              <h2 style="margin-top: 24px">
                Pastas
              </h2>

              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="6">
                  <PastasAtivasGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <PastasGcpjDesatualizadoGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <PastasAcordosNoMesGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
                <a-col :span="6">
                  <PublicacoesHojeGqlCard :perfil="[PERFIL_ACESSO.Ggp]" />
                </a-col>
              </a-row>

              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="12">
                  <ChartCard
                    :status="totalPastasAtivasUltimosMesesStatus"
                    :options="totalPastasAtivasUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">PASTAS ATIVAS</span>
                    </template>
                  </ChartCard>
                </a-col>
                <a-col :span="12">
                  <ChartCard
                    :status="valoresCausasUltimosMesesStatus"
                    :options="valoresCausasUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">VALORES DAS CAUSAS</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>
              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="12">
                  <StatsCard :status="totalValoresCausasStatus">
                    <template #title>
                      VALORES CAUSAS
                    </template>
                    <template #content>
                      {{ formatDinheiro(totalValoresCausas) }}
                    </template>
                  </StatsCard>
                </a-col>
                <a-col :span="12">
                  <StatsCard :status="totalValorAcordosNoMesStatus">
                    <template #title>
                      VALOR ACORDO NO MÊS
                    </template>
                    <template #content>
                      {{ formatDinheiro(totalValorAcordosNoMes) }}
                    </template>
                  </StatsCard>
                </a-col>
              </a-row>
              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="12">
                  <ChartCard
                    :status="totalAcordosUltimosMesesStatus"
                    :options="totalAcordosUltimosMesesOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">TOTAL ACORDOS</span>
                    </template>
                  </ChartCard>
                </a-col>
                <a-col :span="12">
                  <ChartCard
                    :status="totalPublicacoesUltimosDiasStatus"
                    :options="totalPublicacoesUltimosDiasOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">PUBLICAÇÕES</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>
              <a-row :gutter="24" style="margin-top: 24px">
                <a-col :span="12">
                  <ChartCard
                    :status="totalPastasPorStatusStatus"
                    :options="totalPastasPorStatusOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">TOTAL PASTAS POR STATUS</span>
                    </template>
                  </ChartCard>
                </a-col>
                <a-col :span="12">
                  <ChartCard
                    :status="totalPastasPorResponsavelStatus"
                    :options="totalPastasPorResponsavelOptions"
                  >
                    <template #title>
                      <span style="color: rgb(128 128 128); font-size: 16px">TOTAL ATIVAS PASTAS POR RESPONSÁVEL</span>
                    </template>
                  </ChartCard>
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="compromissos" tab="Compromissos">
              <CompromissoGqlDatatable :enable-row-selection="true" :variables="gqlDatatableVars" />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
