<script setup lang="ts">
import { ref } from 'vue';
import type { NotificationArgsProps } from 'ant-design-vue/es/notification';
import { CloseOutlined, CloudUploadOutlined, SaveOutlined } from '@ant-design/icons-vue';
import type { PostSubmit, PreSubmit } from './JobCommandForm.vue';

interface Props
{
  command: string
  initialModel?: object
  notificationOnSubmitSuccess?: NotificationArgsProps
  preSubmit?: PreSubmit
  postSubmit?: PostSubmit
  showUploadFiles?: boolean
  showJobProgress?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showUploadFiles: true,
});

const emit = defineEmits(['submitSuccess', 'submitError', 'close']);

const uploaderStatus = ref<any>(null);
const uploaderRef = ref<any>(null);
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);

async function preSubmit(model: any)
{
  submitStatusRef.value = 'loading';

  const canContinue = props.preSubmit
    ? await props.preSubmit(model)
    : true;

  if (!canContinue)
  {
    submitStatusRef.value = 'error';
    return false;
  }

  if (uploaderRef.value)
  {
    await uploaderRef.value.upload();
  }

  return true;
}

async function postSubmit(status: any, model: any)
{
  submitStatusRef.value = status;

  if (props.postSubmit)
  {
    await props.postSubmit(status, model);
  }
}

const jobCommandFormRef = ref<any>(null);

defineExpose({
  get model()
  {
    return jobCommandFormRef.value?.model;
  },
});
</script>

<template>
  <JobCommandForm
    ref="jobCommandFormRef"
    :command="props.command"
    :initial-model="props.initialModel"
    :notification-on-submit-success="props.notificationOnSubmitSuccess"
    :show-job-progress="props.showJobProgress"
    :pre-submit="preSubmit"
    :post-submit="postSubmit"
    @success="() => emit('submitSuccess')"
    @error="() => emit('submitError')"
  >
    <template #form="{ model }">
      <slot
        name="form"
        :model="model"
      />
      <Uploader
        v-if="props.showUploadFiles"
        ref="uploaderRef"
        v-model:fileList="model.arquivos"
        style="margin-top: 24px; margin-bottom: 24px"
        @start="() => (uploaderStatus = 'uploading')"
        @end="() => (uploaderStatus = 'done')"
      >
        <template #description>
          <p class="ant-upload-drag-icon">
            <CloudUploadOutlined />
          </p>
          <p class="ant-upload-text">
            Clique aqui ou arraste os arquivos
          </p>
          <p class="ant-upload-hint">
            Envie os arquivos necessários
          </p>
        </template>
      </Uploader>
    </template>

    <template #submitSuccess="{ data }">
      <slot
        name="submitSuccess"
        :data="data"
      >
        <a-result
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>
      </slot>
    </template>

    <template #submitButton="{ submit }">
      <slot
        name="submitButton"
        :submit="submit"
      >
        <a-button
          :loading="submitStatusRef === 'loading'"
          type="primary"
          size="large"
          @click.prevent="submit"
        >
          <template #icon>
            <SaveOutlined />
          </template>
          Salvar
        </a-button>
      </slot>
      <slot name="closeButton">
        <a-button
          size="large"
          style="margin-left: 8px"
          @click="() => emit('close')"
        >
          <template #icon>
            <CloseOutlined />
          </template>
          Fechar
        </a-button>
      </slot>
    </template>
    <template #extraBottom="{ submitStatus }">
      <slot
        name="extraBottom"
        :submit-status="submitStatus"
      />
    </template>
  </JobCommandForm>
</template>
