<script setup>
import {
  CloseOutlined,
  FileAddOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { useJobCompromissoCommand } from '~/composables/useJobCompromissoCommand';
import { useAuthStore } from '~/stores/auth';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  status: jobCompromissoCommandStatus,
  jobCompromissoCommand,
  err: jobCompromissoCommandErr,
  cancel: jobCompromissoCommandCancel,
} = useJobCompromissoCommand();

const {
  err: devolverCompromissoErr,
  runAsync: devolverCompromissoAsync,
  status: devolverCompromissoStatus,
} = useDevolverCompromisso();

const arquivos = ref([]);
const jobStatus = ref(null);
const authStore = useAuthStore();
const devolverModalVisible = ref(false);
const formRef = ref(null);
const formModel = reactive({
  andamento: null,
  arquivos: [],
  justificativaDevolucao: null,
  referencia: null,
});
const formRules = reactive({
  andamento: [{ message: 'Andamento é obrigatório', required: true }],
  referencia: [
    {
      message: 'Referência é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});

async function uiSalvarAsync()
{
  if (arquivos.value.some(x => x.tipo === null || x.arquivo === null))
  {
    return;
  }

  formRef.value.validate().then(async () =>
  {
    jobStatus.value = 'loading';

    for (let i = 0; i < arquivos.value.length; i++)
    {
      const tipo = arquivos.value[i].tipo;
      const arquivo = arquivos.value[i].arquivo;
      const uref = arquivos.value[i].ref;
      if (uref !== null && tipo !== null && arquivo !== null)
      {
        await uref.upload();
      }
    }

    await jobCompromissoCommand('AtenderEncerramentoCompromissoCommand', {
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      pastaCodigos: props.pastaCodigos,
      referencia: formModel.referencia?.value,
      andamento: formModel.andamento,
      arquivos: arquivos.value
        .filter(x => x.tipo !== null && x.arquivo !== null)
        .map(x => ({
          ...x.arquivo,
          ...{
            attributes: {
              referenciaGcpjCodigo: x.tipo.value,
              referenciaGcpjNome: x.tipo.label,
            },
          },
        })),
    });
  });
}

async function uiDevolverAsync()
{
  await devolverCompromissoAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverCompromissoStatus.value === 'error')
  {
    notification.error({
      description: devolverCompromissoErr.value.description,
      duration: 5,
      message: devolverCompromissoErr.value.message,
    });
    emit('error');
    return;
  }
  emit('success');
}

watch(jobCompromissoCommandStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    if (jobCompromissoCommandErr.value?.resultadoJsonDocument?.status === 400)
    {
      notification.error({
        duration: 5,
        message: jobCompromissoCommandErr.value.resultadoJsonDocument.message,
      });
    }
    else
    {
      notification.error({
        duration: 5,
        message: 'Tivemos um problema inesperado',
      });
    }
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobCompromissoCommandCancel();
});

function adicionarDocumento()
{
  arquivos.value.push({ ref: null, tipo: null, arquivo: null });
}

function removerDocumento(index)
{
  arquivos.value.splice(index, 1);
}

function addRef(el, index)
{
  if (arquivos.value.length > index)
  {
    arquivos.value[index].ref = el;
  }
}

const temProblemaEmDocumentos = computed(() =>
{
  return arquivos.value.some(x => x.tipo === null || x.arquivo === null);
});
</script>

<template>
  <div>
    <a-row>
      <a-col
        :xs="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <a-result
          v-if="jobStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverCompromissoStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-row
          v-if="
            jobStatus !== 'success' && devolverCompromissoStatus !== 'success'
          "
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item
                label="Referência"
                name="referencia"
              >
                <GraphQlSelect
                  v-model:value="formModel.referencia"
                  query="REFERENCIA_GCPJ_ENCERRAMENTO_SELECT"
                  placeholder="Seleciona a referência do GCPJ"
                />
              </a-form-item>

              <a-form-item
                label="Andamento"
                name="andamento"
                style="padding-bottom: 0"
              >
                <a-textarea
                  v-model:value="formModel.andamento"
                  :rows="4"
                />
              </a-form-item>

              <a-button
                block
                @click="adicionarDocumento"
              >
                <template #icon>
                  <FileAddOutlined />
                </template>Adicionar novo
                documento
              </a-button>

              <a-card
                v-for="(arquivo, index) in arquivos"
                :key="index"
                :body-style="{ padding: '16px' }"
                style="margin-top: 16px"
              >
                <a-row
                  type="flex"
                  :gutter="8"
                  align="middle"
                >
                  <a-col flex="32px">
                    <a-button
                      danger
                      size="small"
                      @click="() => removerDocumento(index)"
                    >
                      <template #icon>
                        <CloseOutlined />
                      </template>
                    </a-button>
                  </a-col>
                  <a-col flex="auto">
                    <GraphQlSelect
                      v-model:value="arquivo.tipo"
                      query="REFERENCIA_GCPJ_DOCUMENTO_SELECT"
                      placeholder="Seleciona a referência do GCPJ"
                      style="width: 100%"
                    />
                  </a-col>
                  <a-col flex="500px">
                    <UploadFile
                      :ref="(el) => addRef(el, index)"
                      v-model:file="arquivo.arquivo"
                    />
                  </a-col>
                </a-row>
              </a-card>

              <a-alert
                v-if="temProblemaEmDocumentos"
                message="Existem documentos sem referência ou sem arquivo"
                type="warning"
                show-icon
                style="margin-top: 16px"
              />
            </a-form>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverCompromissoStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="jobStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')">
                  Fechar
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
