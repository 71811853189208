<script setup lang="ts">
import { CloseCircleFilled, FileAddOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { notification } from 'ant-design-vue';
import referenciaGcpjDocumentoSelect from '~/graphql/referenciaGcpjDocumentoSelect.graphql';

interface Props
{
  required?: boolean
}

const props = withDefaults(defineProps<Props>(), { required: false	});
const model = defineModel<any[]>();

const nenhumArquivoAnexado = ref<boolean>(false);
const temProblemaEmDocumentos = ref<boolean>(false);

function adicionarDocumento()
{
  nenhumArquivoAnexado.value = false;
  temProblemaEmDocumentos.value = false;

  if (!model.value)
  {
    model.value = [];
  }

  model.value.push({ ref: null, tipo: null, arquivo: null });
}

function removerDocumento(index: number)
{
  nenhumArquivoAnexado.value = false;
  temProblemaEmDocumentos.value = false;

  model.value!.splice(index, 1);
}

function addUploadComponentRef(el: any, index: number)
{
  if (model.value!.length > index)
  {
    model.value![index].ref = el;
  }
}

async function upload()
{
  nenhumArquivoAnexado.value = false;
  temProblemaEmDocumentos.value = false;

  const arquivos = model.value ?? [];

  if (props.required && arquivos.length === 0)
  {
    nenhumArquivoAnexado.value = true;
    return { ok: false, value: undefined };
  }

  if (arquivos
    .some((x: any) => x.tipo === null || x.arquivo === null))
  {
    temProblemaEmDocumentos.value = true;
    return { ok: false, value: undefined };
  }

  for (let i = 0; i < arquivos.length; i++)
  {
    const tipo = arquivos[i].tipo;
    const arquivo = arquivos[i].arquivo;
    const uref = arquivos[i].ref;

    if (uref !== null && tipo !== null && arquivo !== null)
    {
      await uref.upload();
    }
  }

  return { ok: true, value: arquivos
    .filter((x: any) => x.tipo !== null && x.arquivo !== null)
    .map((x: any) => ({
      ...x.arquivo,
      ...{
        attributes: {
          referenciaGcpjCodigo: x.tipo.value,
          referenciaGcpjNome: x.tipo.label,
        },
      },
    })) };
}

function beforeFileAdded(file: any)
{
  const fileMaxSize = 20 * 1024 * 1024;

  if (file.size > fileMaxSize)
  {
    notification.error({ message: 'O arquivo excede o tamanho máximo permitido de 20MB' });
    return false;
  }

  const fileNameMaxSize = 30;

  if (file.name.length > fileNameMaxSize)
  {
    notification.error({ message: 'O nome do arquivo excede 30 caracteres' });
    return false;
  }

  return true;
}

defineExpose({
  upload,
});
</script>

<template>
  <a-button
    block
    style="margin-bottom: 24px"
    size="large"
    @click="() => adicionarDocumento()"
  >
    <template #icon>
      <FileAddOutlined />
    </template>Adicionar novo
    documento
  </a-button>

  <a-row
    v-for="(arquivo, index) in model"
    :key="index"
    type="flex"
    :gutter="8"
    align="middle"
    style="margin-bottom: 24px;"
  >
    <a-col flex="400px">
      <GqlSelect
        v-model:value="arquivo.tipo"
        :query="referenciaGcpjDocumentoSelect"
        placeholder="Seleciona a referência do GCPJ"
        style="width: 100%"
      />
    </a-col>
    <a-col flex="auto">
      <UploadFile
        :ref="(el) => addUploadComponentRef(el, index)"
        v-model:file="arquivo.arquivo"
        :before-file-added="beforeFileAdded"
      />
    </a-col>
    <a-col flex="32px">
      <a-button
        danger
        size="small"
        style="border: 0"
        @click="() => removerDocumento(index)"
      >
        <template #icon>
          <CloseCircleFilled />
        </template>
      </a-button>
    </a-col>
    <a-divider
      v-if="model?.length && model.length > 1"
      style="margin-top: 24px; margin-bottom: 0px; width: 50%"
      dashed
    />
  </a-row>

  <a-alert
    v-if="nenhumArquivoAnexado"
    message="Nenhum arquivo foi anexado"
    type="warning"
    show-icon
    style="margin-bottom: 24px"
  />

  <a-alert
    v-if="temProblemaEmDocumentos"
    message="Existem documentos sem referência ou sem arquivo anexado"
    type="warning"
    show-icon
    style="margin-bottom: 24px"
  />
</template>
