<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios';
import { notification } from 'ant-design-vue';
import { useAuthStore } from '~/stores/auth';
import { API_URL, error } from '~/utils';

const props = defineProps({
  pasta: {
    type: Object,
    default: null,
  },
  compromisso: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('solicitar-alteracao-responsavel-principal-form', {
  state: () => ({
    salvarStatus: null,
    error: null,
    pasta: null,
    compromisso: null,
    descricao: null,
  }),
  actions: {
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          `${API_URL}/compromissos/solicitar-alteracao-responsavel-principal`,
          {
            codigoPasta: this.pasta?.codigo ?? null,
            codigoCompromisso: this.compromisso?.codigo ?? null,
            descricao: this.descricao,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
});

const $auth = useAuthStore();
const $s = useStore();
const formRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  descricao: [{ required: true, message: 'Descrição é obrigatório' }],
});

async function salvar()
{
  formRef.value.validate().then(async () =>
  {
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        class: 'er-notification',
        message: $s.error,
        duration: 5,
      });
      emit('error');
      return;
    }
    emit('success');
  });
}

onMounted(async () =>
{
  $s.pasta = props.pasta;
  $s.compromisso = props.compromisso;
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <div>
    <a-result
      v-if="$s.salvarStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button type="primary" @click="() => $emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="$s.salvarStatus !== 'success'">
      <h3 style="margin-bottom: 24px">
        Descreva o motivo para a solicitação do novo responsável principal
      </h3>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
      >
        <a-form-item label="Descrição" name="descricao">
          <a-textarea v-model:value="formModel.descricao" :rows="4" />
        </a-form-item>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNilOrEmpty(compromisso)
                && compromisso.responsavelCodigo !== $auth.codigoUsuario
            "
            :loading="$s.salvarStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="salvar"
          >
            Salvar
          </a-button>
          <a-button @click="() => $emit('close')">
            Cancelar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
