<script setup>
import { computed, reactive, ref, toRaw } from 'vue';
import { API_URL, formatCnj, isNilOrEmpty } from '~/utils';

const props = defineProps({
  filtroResponsaveis: {
    type: Boolean,
    default: true,
  },
  perfil: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(['buscar', 'fechar']);

const urlAjaxSelectResponsaveis = !isNilOrEmpty(props.perfil)
  ? `${API_URL}/ajax-select/usuarios-por-perfis?perfis=${props.perfil}`
  : `${API_URL}/ajax-select/usuarios-por-perfis`;

const filtrosVisivel = ref(false);
const filtrosModel = reactive({
  nome: null,
  gcpj: null,
  numeroCnj: null,
  estados: [],
  responsaveis: [],
  tiposFluxos: [],
  tiposCompromissos: [],
  dataConclusaoRange: null,
});

const filtrosBuscadosModel = reactive({
  nome: null,
  gcpj: null,
  numeroCnj: null,
  estados: [],
  responsaveis: [],
  tiposFluxos: [],
  tiposCompromissos: [],
  dataConclusaoRange: null,
});

const qtdeFiltrosUsados = computed(() =>
{
  let total = 0;

  if (!isNilOrEmpty(filtrosBuscadosModel.nome))
  {
    total += 1;
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.gcpj))
  {
    total += 1;
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.numeroCnj))
  {
    total += 1;
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.estados)
    && filtrosBuscadosModel.estados.length > 0
  )
  {
    total += 1;
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.responsaveis)
    && filtrosBuscadosModel.responsaveis.length > 0
  )
  {
    total += 1;
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.tiposFluxos)
    && filtrosBuscadosModel.tiposFluxos.length > 0
  )
  {
    total += 1;
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.tiposCompromissos)
    && filtrosBuscadosModel.tiposCompromissos.length > 0
  )
  {
    total += 1;
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.dataConclusaoRange))
  {
    total += 1;
  }

  return total;
});

const filtrosUsados = computed(() =>
{
  const filtros = {};

  if (!isNilOrEmpty(filtrosBuscadosModel.nome))
  {
    filtros.Nome = filtrosBuscadosModel.nome;
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.gcpj))
  {
    filtros.GCPJ = filtrosBuscadosModel.gcpj;
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.numeroCnj))
  {
    filtros['Número CNJ'] = formatCnj(filtrosBuscadosModel.numeroCnj);
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.estados)
    && filtrosBuscadosModel.estados.length > 0
  )
  {
    filtros.Estados = filtrosBuscadosModel.estados
      .map(x => x.label)
      .join(', ');
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.responsaveis)
    && filtrosBuscadosModel.responsaveis.length > 0
  )
  {
    filtros['Responsáveis'] = filtrosBuscadosModel.responsaveis
      .map(x => x.label)
      .join(', ');
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.tiposFluxos)
    && filtrosBuscadosModel.tiposFluxos.length > 0
  )
  {
    filtros.Fluxos = filtrosBuscadosModel.tiposFluxos
      .map(x => x.label)
      .join(', ');
  }

  if (
    !isNilOrEmpty(filtrosBuscadosModel.tiposCompromissos)
    && filtrosBuscadosModel.tiposCompromissos.length > 0
  )
  {
    filtros.Compromissos = filtrosBuscadosModel.tiposCompromissos
      .map(x => x.label)
      .join(', ');
  }

  if (!isNilOrEmpty(filtrosBuscadosModel.dataConclusaoRange))
  {
    filtros[
      'Data conclusão'
    ] = `${filtrosBuscadosModel.dataConclusaoRange[0]} - ${filtrosBuscadosModel.dataConclusaoRange[1]}`;
  }

  return filtros;
});

function resetar()
{
  filtrosModel.nome = null;
  filtrosModel.gcpj = null;
  filtrosModel.numeroCnj = null;
  filtrosModel.estados = [];
  filtrosModel.responsaveis = [];
  filtrosModel.tiposFluxos = [];
  filtrosModel.tiposCompromissos = [];
  filtrosModel.dataConclusaoRange = null;
}

function buscar()
{
  filtrosBuscadosModel.nome = filtrosModel.nome;
  filtrosBuscadosModel.gcpj = filtrosModel.gcpj;
  filtrosBuscadosModel.numeroCnj = filtrosModel.numeroCnj;
  filtrosBuscadosModel.estados = filtrosModel.estados;
  filtrosBuscadosModel.responsaveis = filtrosModel.responsaveis;
  filtrosBuscadosModel.tiposFluxos = filtrosModel.tiposFluxos;
  filtrosBuscadosModel.tiposCompromissos = filtrosModel.tiposCompromissos;
  filtrosBuscadosModel.dataConclusaoRange = filtrosModel.dataConclusaoRange;

  const dataFiltros = {};

  if (!isNilOrEmpty(filtrosBuscadosModel.dataConclusaoRange))
  {
    dataFiltros.dataConclusaoInicio
      = filtrosBuscadosModel.dataConclusaoRange[0];
    dataFiltros.dataConclusaoFim = filtrosBuscadosModel.dataConclusaoRange[1];
  }

  const filtros = {
    ...toRaw(filtrosBuscadosModel),
    ...dataFiltros,
    ...{
      estados: filtrosBuscadosModel.estados.map(x => x.value),
      responsaveis: filtrosBuscadosModel.responsaveis.map(x => x.value),
      tiposFluxos: filtrosBuscadosModel.tiposFluxos.map(x => x.value),
      tiposCompromissos: filtrosBuscadosModel.tiposCompromissos.map(
        x => x.value,
      ),
    },
  };
  const filtrosUsadosRaw = toRaw(filtrosUsados.value);

  emit('buscar', filtros, filtrosUsadosRaw);

  filtrosVisivel.value = false;
}

function fechar()
{
  emit('fechar');
  filtrosVisivel.value = false;
}
</script>

<template>
  <a-drawer
    v-model:visible="filtrosVisivel"
    :destroy-on-close="false"
    :mask-closable="true"
    :closable="false"
    width="600"
  >
    <div style="padding-bottom: 24px">
      <DrawerTitle @close="fechar">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span
                style="font-size: 16px; font-weight: 500; line-height: 35px"
              >
                FILTROS</span>
            </a-col>
            <a-col :span="12" style="text-align: right">
              <a-button style="margin-left: 24px" @click="resetar">
                Resetar
              </a-button>
            </a-col>
          </a-row>
        </template>
      </DrawerTitle>
      <div style="padding: 0 24px">
        <a-form layout="vertical">
          <a-form-item label="Nome">
            <a-input
              v-model:value="filtrosModel.nome"
              placeholder="Digite um ou mais nomes"
            />
          </a-form-item>

          <a-form-item label="GCPJ">
            <a-input
              v-model:value="filtrosModel.gcpj"
              placeholder="Digite um ou mais GCPJs"
            />
          </a-form-item>

          <a-form-item label="Número CNJ">
            <a-input
              v-model:value="filtrosModel.numeroCnj"
              class="numero-cnj"
              placeholder="Digite um ou mais números CNJs"
            />
          </a-form-item>

          <a-form-item label="Estado">
            <TipoEnumSelect
              v-model:value="filtrosModel.estados"
              placeholder="Selecione o estado"
              mode="multiple"
              :label-in-value="true"
              :options="[
                { value: 1, label: 'ACRE' },
                { value: 2, label: 'ALAGOAS' },
                { value: 4, label: 'AMAPÁ' },
                { value: 3, label: 'AMAZONAS' },
                { value: 5, label: 'BAHIA' },
                { value: 6, label: 'CEARÁ' },
                { value: 7, label: 'DISTRITO FEDERAL' },
                { value: 8, label: 'ESPÍRITO SANTO' },
                { value: 9, label: 'GOIÁS' },
                { value: 10, label: 'MARANHÃO' },
                { value: 13, label: 'MATO GROSSO' },
                { value: 12, label: 'MATO GROSSO DO SUL' },
                { value: 11, label: 'MINAS GERAIS' },
                { value: 14, label: 'PARÁ' },
                { value: 15, label: 'PARAÍBA' },
                { value: 18, label: 'PARANÁ' },
                { value: 16, label: 'PERNAMBUCO' },
                { value: 17, label: 'PIAUÍ' },
                { value: 19, label: 'RIO DE JANEIRO' },
                { value: 23, label: 'RIO GRANDE DO SUL' },
                { value: 20, label: 'RIO GRANDE DO NORTE' },
                { value: 21, label: 'RONDÔNIA' },
                { value: 22, label: 'RORAIMA' },
                { value: 24, label: 'SANTA CATARINA' },
                { value: 26, label: 'SÃO PAULO' },
                { value: 25, label: 'SERGIPE' },
                { value: 27, label: 'TOCANTIS' },
              ]"
            />
          </a-form-item>

          <a-form-item v-if="props.filtroResponsaveis" label="Responsáveis">
            <AjaxSelect
              v-model:value="filtrosModel.responsaveis"
              :url="urlAjaxSelectResponsaveis"
              mode="multiple"
              placeholder="Responsável pelo compromisso"
            />
          </a-form-item>

          <a-form-item label="Fluxos">
            <AjaxSelect
              v-model:value="filtrosModel.tiposFluxos"
              :url="`${API_URL}/ajax-select/tipo-fluxo`"
              :value-is-number="true"
              mode="multiple"
              placeholder="Fluxos"
            />
          </a-form-item>

          <a-form-item label="Compromissos">
            <AjaxSelect
              v-model:value="filtrosModel.tiposCompromissos"
              :url="`${API_URL}/ajax-select/tipo-compromisso`"
              :value-is-number="true"
              mode="multiple"
              placeholder="Compromissos"
            />
          </a-form-item>

          <a-form-item label="Data conclusão">
            <a-range-picker
              v-model:value="filtrosModel.dataConclusaoRange"
              format="DD/MM/YYYY"
              value-format="DD/MM/YYYY"
              style="width: 100%"
              size="large"
            />
          </a-form-item>
        </a-form>
        <a-col :span="24">
          <a-button
            :block="true"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="buscar"
          >
            Buscar
          </a-button>
        </a-col>
      </div>
    </div>
  </a-drawer>
  <a-button
    type="primary"
    size="large"
    block
    style="width: 160px; margin-right: 8px"
    @click="() => (filtrosVisivel = true)"
  >
    {{
      qtdeFiltrosUsados === 0
        ? 'Mais filtros'
        : `Mais filtros (${qtdeFiltrosUsados})`
    }}
  </a-button>
</template>
