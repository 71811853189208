<script>
import { watch } from 'vue';
import useCurrencyInput from 'vue-currency-input';

export default {
  name: 'CurrencyInput',
  props: {
    modelValue: Number,
    options: Object,
  },
  setup(props) {
    const { formattedValue, inputRef, setValue } = useCurrencyInput(
      props.options
    );

    watch(
      () => props.modelValue, // Vue 2: props.value
      (value) => {
        setValue(value);
      }
    );

    return { formattedValue, inputRef };
  },
};
</script>

<template>
  <a-input ref="inputRef" :value="formattedValue" />
</template>
