<script setup>
import { usePost } from '~/composables/usePost';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import {
  CloudUploadOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { reactive, ref } from 'vue';

const authStore = useAuthStore();

const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);
const salvarStatus = ref(null);

const {
  err: concluirFluxoEmLoteErr,
  runAsync: concluirFluxoEmLoteAsync,
  status: concluirFluxoEmLoteStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/automatizacoes/concluir-fluxo-em-lote`,
});

const uiSalvarAsync = async () => {
  if (formModel.arquivos.length !== 1) {
    notification.error({
      duration: 5,
      message: 'Deve-se enviar somente um arquivo excel',
    });
    return;
  }

  salvarStatus.value = 'loading';
  await uploaderRef.value.upload();
  await concluirFluxoEmLoteAsync({
    arquivos: formModel.arquivos,
  });

  salvarStatus.value = concluirFluxoEmLoteStatus.value;
  uploaderRef.value.clear();

  if (concluirFluxoEmLoteStatus.value === 'error') {
    notification.error({
      description: concluirFluxoEmLoteErr.value.description,
      duration: 5,
      message: concluirFluxoEmLoteErr.value.message,
    });
    return;
  }
};
</script>

<template>
  <Page menu="concluir-fluxo-em-lote">
    <a-result
      v-if="
        !authStore.temPerfilAcesso(
          PERFIL_ACESSO.AutomatizacaoConcluirFluxoEmLote
        )
      "
      status="403"
      title="403"
      sub-title="Você não tem autorização para acessar essa página"
    >
      <template #extra>
        <a-button type="primary" @click="$router.push('/')"
          >Voltar para página principal</a-button
        >
      </template>
    </a-result>
    <div v-else>
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Concluir fluxos
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->

          <a-result
            v-if="salvarStatus === 'success'"
            status="success"
            title="Conclusão em lote solicitada com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => (salvarStatus = null)">
                Enviar outro arquivo
              </a-button>
            </template>
          </a-result>

          <a-row
            v-if="salvarStatus !== 'success'"
            :gutter="24"
            style="margin-top: 24px"
          >
            <a-col :span="24">
              <a-form ref="formRef" layout="vertical">
                <Uploader
                  ref="uploaderRef"
                  v-model:fileList="formModel.arquivos"
                  @start="() => (uploaderStatus = 'uploading')"
                  @end="() => (uploaderStatus = 'done')"
                >
                  <template #description>
                    <p class="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p class="ant-upload-text">
                      Clique aqui ou arraste os arquivos
                    </p>
                    <p class="ant-upload-hint">Envie os arquivos necessários</p>
                  </template>
                </Uploader>
              </a-form>

              <a-row style="margin-top: 24px; margin-bottom: 0">
                <a-col :span="24">
                  <a-button
                    :loading="salvarStatus === 'loading'"
                    type="primary"
                    style="margin-right: 8px; min-width: 100px"
                    @click="uiSalvarAsync"
                  >
                    Salvar
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
