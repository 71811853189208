<script setup>
import { CheckOutlined, DownOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

const props = defineProps({
  drawerWidth: {
    default: 600,
    type: Number,
  },
  drawerZIndex: {
    default: 1000,
    type: Number,
  },
  editable: {
    default: true,
    type: Boolean,
  },
  value: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['update:value', 'change']);

const drawerVisible = ref(false);

function onChange(newValue)
{
  emit('update:value', newValue);
  emit('change', newValue);
}

function abrirDrawer()
{
  drawerVisible.value = true;
}

function onClose()
{
  drawerVisible.value = false;
}

function onSave(formValue)
{
  drawerVisible.value = false;

  const index = props.value.findIndex(
    x =>
      x.carteira.codigo === formValue.carteira.value
      && x.contrato === formValue.contrato,
  );

  if (index >= 0)
  {
    return;
  }

  const novoCarteiraContrato = {
    carteira: {
      codigo: formValue.carteira.value,
      label: formValue.carteira.label,
    },
    contrato: formValue.contrato,
    original: false,
    vigente: false,
  };
  const newValue = [...props.value];
  newValue.push(novoCarteiraContrato);
  onChange(newValue);
}

const columns = ref([
  {
    key: 'carteiraContrato',
    slots: {
      customRender: 'carteiraContrato',
    },
    title: 'CARTEIRA/CONTRATO',
  },
  {
    dataIndex: 'vigente',
    key: 'vigente',
    slots: {
      customRender: 'boolean',
    },
    title: 'VIGENTE',
  },
  {
    dataIndex: 'original',
    key: 'original',
    slots: {
      customRender: 'boolean',
    },
    title: 'ORIGINAL',
  },
]);

if (props.editable)
{
  columns.value.push({
    key: 'action',
    slots: {
      customRender: 'action',
    },
    title: 'AÇÕES',
  });
}

function marcarComoVigente(carteiraContrato)
{
  const novoArray = [...props.value];

  const elem = novoArray.find(
    x =>
      x.carteira.codigo === carteiraContrato.carteira.codigo
      && x.contrato === carteiraContrato.contrato,
  );

  if (elem.vigente)
  {
    return;
  }

  elem.vigente = true;

  onChange(novoArray);
}

function marcarComoOriginal(carteiraContrato)
{
  const novoArray = [...props.value];

  const elem = novoArray.find(
    x =>
      x.carteira.codigo === carteiraContrato.carteira.codigo
      && x.contrato === carteiraContrato.contrato,
  );

  if (elem.original)
  {
    return;
  }

  elem.original = true;

  onChange(novoArray);
}

function desmarcarComoOriginal(carteiraContrato)
{
  const novoArray = [...props.value];

  const elem = novoArray.find(
    x =>
      x.carteira.codigo === carteiraContrato.carteira.codigo
      && x.contrato === carteiraContrato.contrato,
  );

  elem.original = false;

  onChange(novoArray);
}

function desmarcarComoVigente(carteiraContrato)
{
  const novoArray = [...props.value];

  const elem = novoArray.find(
    x =>
      x.carteira.codigo === carteiraContrato.carteira.codigo
      && x.contrato === carteiraContrato.contrato,
  );

  elem.vigente = false;

  onChange(novoArray);
}

function remover(carteiraContrato)
{
  const novoArray = props.value.filter(
    x =>
      !(
        x.carteira.codigo === carteiraContrato.carteira.codigo
        && x.contrato === carteiraContrato.contrato
      ),
  );
  onChange(novoArray);
}
</script>

<template>
  <a-row>
    <a-col span="24">
      <a-table
        :row-key="(_, index) => index"
        :data-source="value"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <template #carteiraContrato="{ record }">
          {{
            `${
              record.carteiraLabel?.toUpperCase()
              ?? record.carteira.label.toUpperCase()
            }/${record.contrato}`
          }}
        </template>
        <template #boolean="{ text }">
          <CheckOutlined v-if="text" style="margin-left: 24px" />
        </template>
        <template #action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              Ações
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a @click.prevent="() => marcarComoVigente(record)">Marcar como vigente</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click.prevent="() => desmarcarComoVigente(record)">Desmarcar como vigente</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click.prevent="() => marcarComoOriginal(record)">Marcar como original</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click.prevent="() => desmarcarComoOriginal(record)">Desmarcar como original</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click.prevent="() => remover(record)">Remover</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
        <template v-if="editable" #footer>
          <a-row justify="end">
            <a-col :col="24">
              <a-button @click="abrirDrawer">
                Adicionar
              </a-button>
            </a-col>
          </a-row>
        </template>
      </a-table>
    </a-col>
  </a-row>
  <a-drawer
    v-model:visible="drawerVisible"
    :destroy-on-close="true"
    :mask-closable="true"
    :closable="false"
    :width="drawerWidth"
  >
    <SalvarCarteiraContratoForm @close="onClose" @save="onSave" />
  </a-drawer>
</template>
