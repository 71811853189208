<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { onMounted, reactive, ref, toRaw } from 'vue';

export type PostQueryProcessData = (data: any) => void;

interface Props
{
  menu: string
  query?: DocumentNode
  queryParams?: any
  initialModel?: object
  postQueryProcessData?: PostQueryProcessData
}

const props = defineProps<Props>();
const emit = defineEmits(['querySuccess']);
const model = reactive<any>(props.initialModel ?? {});
let queryLoad: Function;
let queryRefetch: Function;
let queryResult: any;
if (props.query)
{
  const { load, refetch, result } = useLazyQuery(props.query, props.queryParams, { errorPolicy: 'none', fetchPolicy: 'no-cache' });
  queryLoad = load;
  queryRefetch = refetch;
  queryResult = result;
}

const queryStatusRef = ref<'loading' | 'success' | 'error' | null>(null);

async function loadQuery()
{
  if (props.query)
  {
    try
    {
      queryStatusRef.value = 'loading';
      await (queryLoad() || queryRefetch());

      if (props.postQueryProcessData)
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
        props.postQueryProcessData(model);
      }
      else if (queryResult.value.model)
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
      }

      emit('querySuccess', { ...toRaw(model) });

      queryStatusRef.value = 'success';
    }
    catch
    {
      queryStatusRef.value = 'error';
    }
  }
  else
  {
    queryStatusRef.value = 'success';
  }
}

onMounted(async () =>
{
  await loadQuery();
});

defineExpose({
  model,
});
</script>

<template>
  <Page
    :menu="props.menu"
  >
    <a-skeleton
      v-if="queryStatusRef === 'loading'"
      :active="true"
    />
    <slot
      v-if="queryStatusRef === 'success'"
      :model="model"
    />
  </Page>
</template>
