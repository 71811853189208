<script setup>
import { merge } from 'lodash-es';
import { ref } from 'vue';

const props = defineProps({
  responsaveisCodigos: {
    default: () => null,
    type: Array,
  },
  tiposPerfis: {
    default: () => null,
    type: Array,
  },
});

const columns = [
  {
    key: 'nome',
    title: 'NOME',
  },

  {
    key: 'envolvidos',
    title: 'ENVOLVIDOS',
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
  },
  {
    key: 'jurisdicao',
    title: 'JURISDIÇÃO',
  },
];

const graphqlVariables = ref({ gcpjDesatualizado: true });

if (props.responsaveisCodigos?.length > 0) {
  graphqlVariables.value = merge(graphqlVariables.value, {
    where: {
      and: [
        {
          responsaveis: {
            some: {
              and: [
                {
                  usuarioCodigo: {
                    in: props.responsaveisCodigos,
                  },
                },
              ],
            },
          },
        },
      ],
    },
  });
}

if (props.tiposPerfis?.length > 0) {
  graphqlVariables.value = merge(graphqlVariables.value, {
    where: {
      responsaveis: {
        some: {
          usuario: {
            perfisAcesso: {
              some: {
                perfil: {
                  in: props.tiposPerfis,
                },
              },
            },
          },
        },
      },
    },
  });
}
</script>
<template>
  <PastaGraphqlDatatable
    :columns="columns"
    :graphql-variables="graphqlVariables"
  ></PastaGraphqlDatatable>
</template>
