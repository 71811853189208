import axios from 'axios';
import { API_URL } from '~/utils';

export async function devolverCompromisso(cmd) {
  const response = await axios.post(
    `${API_URL}/compromissos/devolver-compromisso`,
    cmd,
    {
      getAccessToken: true,
      timeout: 120000,
    },
  );
  return response.data;
}
