<script setup>
import { usePost } from '~/composables/usePost';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { reactive, ref } from 'vue';

const props = defineProps({
  publicacaoNumeroProcessoCodigos: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: criarCompromissoPubErr,
  runAsync: criarCompromissoPubAsync,
  status: criarCompromissoPubStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/fluxo/compromisso-pub/criar-compromisso-pub-a-partir-publicacao-em-lote`,
});

const state = reactive({
  arquivos: [],
  responsavel: null,
  tipo: null,
});

const formRef = ref(null);
const formRules = reactive({
  responsavel: [
    {
      message: 'Responsável é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  tipo: [
    {
      message: 'Tipo do compromisso é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
// const uploaderRef = ref(null);
const uploaderStatus = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    // await uploaderRef.value.upload();

    await criarCompromissoPubAsync({
      arquivos: state.arquivos,
      publicacaoNumeroProcessoCodigos: props.publicacaoNumeroProcessoCodigos,
      responsavelCodigo: state.responsavel?.value,
      tipo: state.tipo?.value,
    });

    if (criarCompromissoPubStatus.value === 'error') {
      notification.error({
        duration: 0,
        message: criarCompromissoPubErr.value.message,
      });
    }
  });
};
</script>

<template>
  <div class="criar-compromisso-pub-a-partir-publicacao-em-lote-form">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          CRIAR COMPROMISSO PUB</span
        >
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-result
        v-if="criarCompromissoPubStatus === 'success'"
        status="success"
        title="Registro realizado com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </template>
      </a-result>

      <div v-if="criarCompromissoPubStatus !== 'success'">
        <a-alert
          type="warning"
          message="Você irá realizar uma alteração em lote"
          show-icon
          style="margin-bottom: 24px"
        ></a-alert>

        <a-form
          ref="formRef"
          layout="vertical"
          :model="state"
          :rules="formRules"
          style="margin-top: 16px"
        >
          <a-form-item label="Tipo" name="tipo">
            <AjaxSelect
              v-model:value="state.tipo"
              :url="`${API_URL}/ajax-select/tipo-compromisso-pub`"
              :value-is-number="true"
            />
          </a-form-item>

          <a-form-item name="responsavel">
            <template #label>
              <slot name="formItemLabel"> Responsável </slot>
            </template>
            <GraphQlSelect
              v-model:value="state.responsavel"
              query="USUARIO_SELECT"
              :variables="{
                where: {
                  ativo: {
                    eq: true,
                  },
                },
                order: [
                  {
                    nome: 'ASC',
                  },
                ],
              }"
              placeholder="Selecione o responsável"
              :allow-clear="true"
            />
          </a-form-item>

          <!-- <h3 style="margin-bottom: 24px">Anexar arquivos</h3>

          <a-row>
            <a-col :span="24">
              <Uploader
                ref="uploaderRef"
                v-model:fileList="state.arquivos"
                
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              />
            </a-col>
          </a-row> -->

          <a-form-item style="margin-top: 24px; margin-bottom: 0px">
            <a-button
              :loading="
                uploaderStatus === 'uploading' ||
                criarCompromissoPubStatus === 'loading'
              "
              type="primary"
              style="margin-right: 8px; min-width: 100px"
              @click.prevent="uiSalvarAsync"
            >
              Salvar
            </a-button>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
