<script setup>
import { onMounted } from 'vue';
import {
  deepIntParaTextoComDuasOpcoes,
  deepIntParaTextoComQuatroOpcoes,
  deepIntParaTextoComTresOpcoes,
  formatCnj,
  formatDate,
  formatDinheiro,
  isNilOrEmpty,
} from '~/utils';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
  fluxo: {
    type: Object,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <AlterarPastaViewV2
    v-if="'v' in props.compromisso.data"
    :compromisso="props.compromisso"
  />
  <AlterarPastaViewV1
    v-else
    :compromisso="props.compromisso"
  />
</template>
