<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { LockOutlined, MailOutlined } from '@ant-design/icons-vue';
import { useStore } from './store';
import router from '~/router';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';

const drawerStore = useDrawerStore();
const $auth = useAuthStore();
const $s = useStore();
const formRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  email: [{ required: true, message: 'Email é obrigatório' }],
  senha: [{ required: true, message: 'Senha é obrigatória' }],
});

async function login()
{
  await $s.login();

  if ($s.status === 'success')
  {
    if ($auth.ehGestor || $auth.ehGerente)
    {
      router.push({
        path: '/visao-geral',
      });
    }
    else
    {
      router.push({
        path: '/',
      });
    }
  }
}

onMounted(() =>
{
  drawerStore.closeAllWithoutCallbacks();
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <a-layout class="login-layout">
    <a-layout-content>
      <div class="login-page">
        <a-card class="login-card">
          <a-alert
            v-if="$s.status === 'error'"
            style="margin-bottom: 32px"
            type="error"
            :message="$s.error"
          />

          <div class="logo-container">
            <img src="/images/logo-122x122.png">
          </div>

          <div style="text-align: center">
            <h2 class="titulo">
              Entrar
            </h2>
          </div>

          <p class="subtitulo">
            Ir para o escritório
          </p>

          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
            :hide-required-mark="true"
            @finish="login"
          >
            <a-form-item label="Email" name="email" :wrapper-col="{ span: 24 }">
              <a-input v-model:value="formModel.email" size="large">
                <template #prefix>
                  <MailOutlined style="color: #d9d9d9" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item label="Senha" name="senha" :wrapper-col="{ span: 24 }">
              <a-input-password
                v-model:value="formModel.senha"
                size="large"
                type="password"
              >
                <template #prefix>
                  <LockOutlined style="color: #d9d9d9" />
                </template>
              </a-input-password>
            </a-form-item>
            <a-row style="padding-top: 8px">
              <a-col :span="24">
                <a-button
                  :loading="$s.status === 'loading'"
                  :block="true"
                  size="large"
                  type="primary"
                  html-type="submit"
                >
                  Entrar
                </a-button>
              </a-col>
            </a-row>
            <a-row style="margin-top: 24px" type="flex" justify="end">
              <a-col>
                <router-link to="/usuarios/esqueci-senha">
                  Esqueci minha senha
                </router-link>
              </a-col>
            </a-row>
          </a-form>
        </a-card>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<style lang="less">
@import '../../../assets/styles/base.less';
.login-layout {
  &.ant-layout {
    height: 100%;
  }
}

.login-page {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & .ant-alert-message {
    font-weight: 600;
  }

  & .login-card.ant-card {
    padding-top: 48px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 56px;

    border: 0;
    width: 480px;
    @media (min-width: @screen-md-min) {
      border: 1px solid #f0f0f0;
      width: 550px;
    }

    & .ant-card-body {
      padding: 0;
    }

    & img {
      width: 96px;
      height: auto;
    }

    & .logo-container {
      margin-bottom: 24px;
      text-align: center;
    }

    & .titulo {
      color: @logo-black;
      margin-bottom: 0;

      font-size: 30px;
      @media (min-width: @screen-md-min) {
        font-size: 36px;
      }
    }

    & .subtitulo {
      margin-bottom: 32px;
      text-align: center;
      font-size: 14px;
    }

    & label {
      font-size: 16px;
    }

    & .ant-input-affix-wrapper .ant-input-prefix {
      margin-right: 12px;
    }
  }
}
</style>
