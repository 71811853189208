<script setup>
import axios from 'axios';
import { defineStore } from 'pinia';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('solicitar-cadastro-processo-distribuido-form', {
  actions: {
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          `${API_URL}/fluxo/cadastro-processo-distribuido/solicitar-cadastro-processo-distribuido`,
          {
            advogadoBanco: this.advogadoBanco,
            arquivos: this.arquivos,
            carteira: this.carteira.value,
            codigoAgencia: this.agencia?.value ?? null,
            codigoCliente: this.cliente?.value ?? null,
            conta: this.conta,
            contrato: this.contrato,
            dataRemessa: this.dataRemessa,
            dejur: this.dejur,
            gcpj: this.gcpj,
            valorContrato: this.valorContrato,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
  state: () => ({
    advogadoBanco: null,
    agencia: null,
    arquivos: [],
    carteira: null,
    cliente: null,
    conta: null,
    contrato: null,
    dataRemessa: null,
    dejur: null,
    error: null,
    gcpj: null,
    salvarStatus: null,
    uploaderStatus: null,
    valorContrato: null,
  }),
});

const $s = useStore();
const formRef = ref(null);
const uploaderRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  agencia: [
    {
      message: 'Agência é obrigatória',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  carteira: [
    {
      message: 'Carteira é obrigatória',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  cliente: [
    {
      message: 'Cliente é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  conta: [{ message: 'Conta é obrigatória', required: true }],
  contrato: [{ message: 'Contrato é obrigatório', required: true }],
  gcpj: [{ message: 'Número GCPJ é obrigatório', required: true }],
  valorContrato: [
    {
      message: 'Valor do contrato é obrigatório',
      required: true,
      trigger: 'blur',
      type: 'number',
    },
  ],
});

async function salvar()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        class: 'er-notification',
        duration: 5,
        message: $s.error,
      });
    }
  });
}

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-result
          v-if="$s.salvarStatus === 'success'"
          status="success"
          title="Seu registro foi realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => $emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <div v-if="$s.salvarStatus !== 'success'">
          <h3 style="margin-bottom: 24px">
            Informe os dados
          </h3>

          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
          >
            <a-form-item label="Número GCPJ" name="gcpj">
              <a-input v-model:value="formModel.gcpj" :maxlength="10" />
            </a-form-item>

            <a-form-item label="Agência" name="agencia">
              <GraphQlSelect
                v-model:value="formModel.agencia"
                query="AgenciasAsSelectItem"
                placeholder="Selecione a agência"
              />
            </a-form-item>

            <a-form-item label="Conta" name="conta">
              <a-input v-model:value="formModel.conta" />
            </a-form-item>
            <a-form-item label="Carteira" name="carteira">
              <AjaxSelect
                v-model:value="formModel.carteira"
                :url="`${API_URL}/ajax-select/carteiras`"
                placeholder="Selecione uma carteira"
              />
            </a-form-item>
            <a-form-item label="Contrato" name="contrato">
              <a-input v-model:value="formModel.contrato" />
            </a-form-item>

            <a-form-item name="cliente">
              <template #label>
                <slot name="formItemLabel">
                  Cliente
                </slot>
              </template>
              <AjaxSelect
                v-model:value="formModel.cliente"
                :url="`${API_URL}/ajax-select/pessoas`"
                placeholder="Selecione o cliente"
              />
            </a-form-item>

            <a-form-item label="Valor do contrato" name="valorContrato">
              <CurrencyInput
                v-model="formModel.valorContrato"
                :options="{
                  locale: 'pt-BR',
                  currency: 'BRL',
                  currencyDisplay: 'symbol',
                  hideCurrencySymbolOnFocus: false,
                  hideGroupingSeparatorOnFocus: false,
                  hideNegligibleDecimalDigitsOnFocus: false,
                  autoDecimalDigits: true,
                }"
              />
            </a-form-item>

            <a-form-item label="Data remessa" name="dataRemessa">
              <a-date-picker
                v-model:value="formModel.dataRemessa"
                format="DD/MM/YYYY"
                value-format="DD/MM/YYYY"
                placeholder="Selecione uma data"
                style="width: 100%"
              />
            </a-form-item>

            <a-form-item label="Departamento jurídico" name="dejur">
              <a-input v-model:value="formModel.dejur" />
            </a-form-item>

            <a-form-item label="Advogado do banco" name="advogadoBanco">
              <a-input v-model:value="formModel.advogadoBanco" />
            </a-form-item>

            <h3 style="margin-bottom: 24px">
              Anexar arquivos
            </h3>

            <a-row>
              <a-col :span="24">
                <Uploader
                  ref="uploaderRef"
                  v-model:fileList="$s.arquivos"

                  @start="() => ($s.uploaderStatus = 'uploading')"
                  @end="() => ($s.uploaderStatus = 'done')"
                />
              </a-col>
            </a-row>

            <a-form-item style="margin-top: 24px; margin-bottom: 0px">
              <a-button
                :loading="
                  $s.uploaderStatus === 'uploading'
                    || $s.salvarStatus === 'loading'
                "
                type="primary"
                style="margin-right: 8px; min-width: 100px"
                @click.prevent="salvar"
              >
                Salvar
              </a-button>
              <a-button @click="() => $emit('close')">
                Cancelar
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
