<script setup>
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: criarCompromissoAvulsoData,
  err: criarCompromissoAvulsoErr,
  runAsync: criarCompromissoAvulsoAsync,
  status: criarCompromissoAvulsoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/compromisso-avulso/criar-compromisso-avulso`,
});

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  dataPrazo: null,
  descricao: null,
  responsavel: null,
  arquivos: [],
});
const formRules = reactive({
  dataPrazo: [{ message: 'Prazo é obrigatório', required: true }],
  descricao: [
    {
      message: 'Descrição é obrigatória',
      required: true,
    },
  ],
  responsavel: [
    {
      message: 'Responsável é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
const uploaderRef = ref(null);
const uploaderStatus = ref(null);
function disabledDate(current)
{
  return (
    current.day() === 0
    || current.day() === 6
    || current <= dayjs().subtract(1, 'day').endOf('day')
  );
}

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await criarCompromissoAvulsoAsync({
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      pastaCodigos: props.pastaCodigos,
      responsavelCodigo: formModel.responsavel.value,
      descricao: formModel.descricao,
      dataPrazo: formModel.dataPrazo,
      arquivos: formModel.arquivos,
    });

    if (criarCompromissoAvulsoStatus.value === 'error')
    {
      jobStatus.value = 'error';
      notification.error({
        description: criarCompromissoAvulsoErr.value.description,
        duration: 5,
        message: criarCompromissoAvulsoErr.value.message,
      });
      emit('error');
      return;
    }

    const jobCodigo = criarCompromissoAvulsoData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
}

watch(jobPoolingStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage
        ?? 'Tivemos um problema inesperado',
    });
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-result
      v-if="jobStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="jobStatus !== 'success'">
      <a-alert
        v-if="pastaCodigos?.length > 1"
        type="warning"
        message="Você irá realizar uma alteração em lote"
        show-icon
        style="margin-bottom: 24px"
      />

      <a-collapse
        v-if="props.pastaCodigos?.length === 1"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 150,
                },
                fluxo: {
                  pastaCodigo: {
                    in: props.pastaCodigos,
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
        style="margin-top: 16px"
      >
        <a-form-item label="Responsável" name="responsavel">
          <GraphQlSelect
            v-model:value="formModel.responsavel"
            query="USUARIO_SELECT"
            :variables="{
              where: {
                ativo: {
                  eq: true,
                },
              },
              order: [
                {
                  nome: 'ASC',
                },
              ],
            }"
            placeholder="Selecione responsável"
            :allow-clear="true"
          />
        </a-form-item>

        <a-form-item
          label="Descrição"
          name="descricao"
          style="padding-bottom: 0"
        >
          <a-textarea v-model:value="formModel.descricao" :rows="4" />
        </a-form-item>

        <a-form-item label="Prazo" name="dataPrazo">
          <a-date-picker
            v-model:value="formModel.dataPrazo"
            format="DD/MM/YYYY HH:mm"
            value-format="DD/MM/YYYY HH:mm"
            placeholder="Selecione uma data"
            style="width: 100%"
            :disabled-date="disabledDate"
            :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
          />
        </a-form-item>

        <h3 style="margin-bottom: 24px">
          Anexar arquivos
        </h3>

        <a-row>
          <a-col :span="24">
            <Uploader
              ref="uploaderRef"
              v-model:fileList="formModel.arquivos"
              @start="() => (uploaderStatus = 'uploading')"
              @end="() => (uploaderStatus = 'done')"
            />
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNilOrEmpty(compromisso)
                && compromisso.responsavel.codigo !== authStore.codigoUsuario
            "
            :loading="jobStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
