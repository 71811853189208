<script setup>
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: juntarPlanilhaAtualizacaoDebitoAoProcessoData,
  err: juntarPlanilhaAtualizacaoDebitoAoProcessoErr,
  runAsync: juntarPlanilhaAtualizacaoDebitoAoProcessoAsync,
  status: juntarPlanilhaAtualizacaoDebitoAoProcessoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
});
const formRules = reactive({});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();
    await juntarPlanilhaAtualizacaoDebitoAoProcessoAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos: [props.compromisso.codigo],
    });

    if (juntarPlanilhaAtualizacaoDebitoAoProcessoStatus.value === 'error') {
      jobStatus.value = 'error';

      notification.error({
        description:
          juntarPlanilhaAtualizacaoDebitoAoProcessoErr.value.description,
        duration: 5,
        message: juntarPlanilhaAtualizacaoDebitoAoProcessoErr.value.message,
      });

      emit('error');
      return;
    }

    const jobCodigo = juntarPlanilhaAtualizacaoDebitoAoProcessoData.value;

    await jobPoolingAsync({ codigo: jobCodigo });

    emit('success');
  });
};

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="jobStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-row v-if="jobStatus !== 'success'" :gutter="24">
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">Envie os arquivos necessários</p>
                </template>
              </Uploader>
            </a-form>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="jobStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
