query agenciaSelect {
    data: agencias(order: [{nome: ASC}]) {
        value: codigo
        numero
        nome
        regional
        estado {
            sigla
        }
        porte
        label
    }
}