<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { reactive, ref, toRaw, watch } from 'vue';
const props = defineProps({
  bordered: {
    default: true,
    type: Boolean,
  },
  columns: {
    default: () => [],
    type: Array,
  },
  includes: {
    default: () => [],
    type: Array,
  },
  pageSizeOptions: {
    default: () => ['10', '20', '30', '40', '50'],
    type: Array,
  },
  query: {
    required: true,
    type: String,
  },
  rowKey: {
    default: null,
    type: String,
  },
  rowSelection: {
    default: () => null,
    type: Object,
  },
  variables: {
    default: () => {},
    type: Object,
  },
});
const emit = defineEmits(['change', 'success']);
const { data, runAsync, status } = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: props.query,
});
const dataSource = ref([]);
const paginationState = reactive({
  pagina: 1,
  total: 0,
  totalPorPagina: 10,
});

const uiRunAsync = async () => {
  const vars = {
    ...props.variables,
    ...{
      skip:
        (paginationState.pagina - 1) *
        parseInt(paginationState.totalPorPagina, 10),
      take: parseInt(paginationState.totalPorPagina, 10),
    },
  };

  if (props.includes?.length > 0) {
    vars.includes = [...props.includes];
  }

  await runAsync(vars);
  if (status.value === 'success') {
    dataSource.value = data.value.result.items;
    paginationState.total = data.value.result.totalCount;
  }
  if (status.value === 'error') {
    dataSource.value = [];
    paginationState.total = 0;
  }
};

const onTableChange = async (pagination, filters, sorter) => {
  emit('change', pagination, filters, sorter);
};

const onPaginationChange = async () => {
  await uiRunAsync();
};

const getDataSource = () => {
  return toRaw(dataSource.value);
};

watch(
  props.variables,
  async () => {
    paginationState.pagina = 1;
    await uiRunAsync();
  },
  { immediate: true }
);

defineExpose({
  getDataSource: getDataSource,
  reloadAsync: uiRunAsync,
});
</script>
<template>
  <a-table
    class="graphql-table"
    :loading="status === 'loading'"
    :columns="props.columns"
    :data-source="dataSource"
    :row-key="props.rowKey"
    :bordered="props.bordered"
    :pagination="false"
    :row-selection="props.rowSelection"
    @change="
      (pagination, filters, sorter) =>
        onTableChange(pagination, filters, sorter)
    "
  >
    <template #emptyText>
      <ErrorResult
        v-if="status === 'error'"
        :tentar-novamente-fn="() => uiRunAsync()"
      />
      <Empty v-else description="Nenhum resultado encontrado" />
    </template>
    <template v-for="(_, name) in $slots" #[name]="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
    <template #footer>
      <a-row align="middle">
        <a-col :span="6">
          <span class="table-resultado-span"
            >Resultados: {{ paginationState.total }}
          </span></a-col
        >
        <a-col :span="18" style="text-align: right">
          <slot name="footer-extra" :total="paginationState.total"></slot
        ></a-col>
      </a-row>
    </template>
  </a-table>
  <a-row>
    <a-col
      :span="24"
      style="text-align: right; padding-right: 16px; padding-top: 16px"
    >
      <a-pagination
        v-model:current="paginationState.pagina"
        v-model:page-size="paginationState.totalPorPagina"
        :disabled="status === 'loading'"
        :show-size-changer="true"
        :page-size-options="props.pageSizeOptions"
        :total="paginationState.total"
        @show-size-change="
          () => {
            paginationState.pagina = 1;
          }
        "
        @change="onPaginationChange"
    /></a-col>
  </a-row>
</template>
<style lang="less">
.graphql-table {
  & .table-resultado-span {
    color: #9a9a9a;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
</style>
