<script setup>
import { watch } from 'vue';
import useCurrencyInput from 'vue-currency-input';

const props = defineProps({
  componentProps: {
    default: () => {},
    type: Object,
  },
  modelValue: {
    default: null,
    type: Number,
  },
  money: {
    default: () => {},
    type: Object,
  },
});

const { formattedValue, inputRef, setValue } = useCurrencyInput(props.money);

watch(
  () => props.modelValue, // Vue 2: props.value
  (value) => {
    setValue(value);
  }
);
</script>

<template>
  <a-input ref="inputRef" :value="formattedValue" v-bind="componentProps" />
</template>
