query arquivoDatatable(
    $where: ArquivoModelFilterInput
    $order: [ArquivoModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: arquivosOffsetPagination(
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            nome
            fileServiceId
            dataCriacao
            dataCriacaoText
            compromisso {
                tipo
                tipoText
                fluxo {
                    tipo
                    tipoText
                }
            }
            criadoPor {
                nome
            }
        }
        totalCount
    }
}
