<script setup>
import { notification } from 'ant-design-vue';
import axios from 'axios';
import { defineStore } from 'pinia';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { API_URL, error } from '~/utils';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('enviar-arquivos-form', {
  actions: {
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          props.compromisso?.url
            ? `${API_URL}${props.compromisso.url}`
            : `${API_URL}/fluxo/anexar-arquivos/enviar-arquivos`,
          {
            arquivos: this.arquivos,
            compromissoCodigos:
              props.compromisso !== null ? [props.compromisso.codigo] : null,
            pastaCodigos:
              props.codigoPasta !== null ? [props.codigoPasta] : null,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
  state: () => ({
    arquivos: [],
    error: null,
    salvarStatus: null,
    uploaderStatus: null,
  }),
});

const $s = useStore();
const formRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({});
const uploaderRef = ref(null);

async function salvar()
{
  if ($s.arquivos.length === 0)
  {
    notification.error({
      duration: 5,
      message: 'Favor anexar pelo menos um arquivo',
    });
    return;
  }

  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        duration: 5,
        message: $s.error,
      });
      emit('error');
      return;
    }
    emit('success');
  });
}

onMounted(async () =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <div>
    <a-result
      v-if="$s.salvarStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => $emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="$s.salvarStatus !== 'success'">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
      >
        <a-row>
          <a-col :span="24">
            <Uploader
              ref="uploaderRef"
              v-model:fileList="$s.arquivos"
              @start="() => ($s.uploaderStatus = 'uploading')"
              @end="() => ($s.uploaderStatus = 'done')"
            />
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :loading="
              $s.salvarStatus === 'loading' || $s.uploaderStatus === 'uploading'
            "
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click="salvar"
          >
            Salvar
          </a-button>
          <a-button @click="() => $emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
