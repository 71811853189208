<script setup>
import { DownOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { computed, ref } from 'vue';
import tipoPerfil from '~/constants/tipoPerfil';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { formatCnj, onlyNumbers } from '~/utils';

const props = defineProps({
  columns: {
    default: () => [
      {
        dataIndex: 'numeroProcesso',
        key: 'numeroProcesso',
        title: 'NÚMERO DO PROCESSO',
        width: 250,
      },
      {
        dataIndex: ['publicacao', 'dataMovimentoText'],
        key: 'dataMovimento',
        sortBy: ['publicacao', 'dataMovimento'],
        sorter: true,
        title: 'DATA DISPONIBILIZAÇÃO',
        width: 220,
      },
      {
        dataIndex: ['publicacao', 'diario'],
        key: 'diario',
        title: 'DIÁRIO',
        width: 250,
      },
      {
        dataIndex: ['publicacao', 'caderno'],
        key: 'caderno',
        title: 'CADERNO',
        width: 250,
      },
      {
        dataIndex: ['publicacao', 'pagina'],
        key: 'pagina',
        title: 'PÁGINA',
        width: 50,
      },
      {
        key: 'conteudo',
        title: 'CONTEÚDO',
      },
    ],
    type: Array,
  },
  disabledFilters: {
    default: () => [],
    type: Array,
  },
  graphqlVariables: {
    default: () => ({
      order: [{ publicacao: { dataMovimento: 'DESC' } }],
    }),
    type: Object,
  },
  textSearch: {
    type: Object,
    default: () => ({
      placeholder: 'Busca por número de processo',
    }),
  },
});

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const filters = [];

if (
  !props.disabledFilters.find(x => x === 'codigo')
  && !props.disabledFilters.find(x => x === '*')
)
{
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
      size: 'large',
    },
    graphqlQuery: (val) =>
    {
      const splittedValue = val.split(',').map(x => onlyNumbers(x.trim()));

      return {
        where: {
          and: [
            {
              codigo: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'codigo',
    label: 'Códigos',
    queryKey: 'n',
    type: 'text',
  });
}

if (
  !props.disabledFilters.find(x => x === 'numeroProcesso')
  && !props.disabledFilters.find(x => x === '*')
)
{
  filters.push({
    componentProps: {
      placeholder: 'Digite um ou mais números de processos',
      size: 'large',
    },
    graphqlQuery: (val) =>
    {
      const splittedValue = val.split(',').map(x => onlyNumbers(x.trim()));

      return {
        where: {
          and: [
            {
              numeroProcesso: {
                in: splittedValue,
              },
            },
          ],
        },
      };
    },
    key: 'numeroProcesso',
    label: 'Número de processo',
    queryKey: 'n',
    type: 'text',
  });
}

if (
  !props.disabledFilters.find(x => x === 'dataMovimento')
  && !props.disabledFilters.find(x => x === '*')
)
{
  filters.push({
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      size: 'large',
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
    graphqlQuery: (val) =>
    {
      return {
        where: {
          and: [
            {
              publicacao: {
                dataMovimento: {
                  gte: `${val[0]} 00:00:00`,
                  lte: `${val[1]} 23:59:59`,
                },
              },
            },
          ],
        },
      };
    },
    key: 'dataMovimento',
    label: 'Data de movimentação',
    queryKey: 'd',
    type: 'date',
  });
}

const tableRef = ref(null);
const selectedRowKeys = ref([]);

async function acoesSelecionadosOnClick({ key })
{
  if (key === 'solicitar-verificacao')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'SolicitarVerificacaoPublicacaoCapturadaForm',
        componentProps: {
          publicacaoNumeroProcessoCodigos: [...selectedRowKeys.value],
        },
        title: 'SOLICITAR VERIFICAÇÃO DA PUBLICAÇÃO',
      },
      width: 600,
    });
  }
  if (key === 'criar-compromisso-pub')
  {
    drawerStore.push2({
      componentName: 'CompromissoForm2',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        componentName: 'CriarCompromissoPubForm',
        componentProps: {
          publicacaoNumeroProcessoCodigos: [...selectedRowKeys.value],
        },
        title: 'CRIAR COMPROMISSO PUB',
      },
      width: 600,
    });
  }
}

function abrirFluxo(publicacaoNumeroProcessoCodigo)
{
  drawerStore.push2({
    componentName: 'FluxosView',
    params: {
      codigoPublicacaoNumeroProcesso: publicacaoNumeroProcessoCodigo,
    },
  });
}

function abrirPublicacaoTexto(publicacao)
{
  drawerStore.push({
    componentName: 'PublicacaoTextoView',
    params: {
      texto: publicacao.conteudo,
    },
  });
}

const rowSelection = {
  onChange: (_selectedRowKeys) =>
  {
    selectedRowKeys.value = _selectedRowKeys;
  },
};

const mostrarAcoes = computed(() =>
{
  return (
    selectedRowKeys.value.length > 0
    && (authStore.temPerfilAcesso(
      tipoPerfil.AutomatizacaoSolicitarVerificacaoPublicacaoEmLote,
    )
    || authStore.temPerfilAcesso(
      tipoPerfil.AutomatizacaoCriarCompromissoPubAPartirPublicacaoEmLote,
    ))
  );
});
</script>

<template>
  <GraphqlDatatable
    ref="tableRef"
    query="PUBLICACAO_NUMERO_PROCESSO_DATATABLE"
    :keep-history="false"
    :columns="props.columns"
    row-key="codigo"
    :filters="filters"
    :text-search="props.textSearch"
    :graphql-variables="props.graphqlVariables"
    :row-selection="rowSelection"
  >
    <template #topActions>
      <a-col
        v-if="mostrarAcoes"
        flex="50px"
        style="margin-left: 4px"
      >
        <a-dropdown>
          <template #overlay>
            <a-menu @click="acoesSelecionadosOnClick">
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    tipoPerfil.AutomatizacaoSolicitarVerificacaoPublicacaoEmLote,
                  )
                "
                key="solicitar-verificacao"
              >
                Solicitar verificação
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    tipoPerfil.AutomatizacaoCriarCompromissoPubAPartirPublicacaoEmLote,
                  )
                "
                key="criar-compromisso-pub"
              >
                Criar compromisso pub
              </a-menu-item>
            </a-menu>
          </template>
          <a-button size="large">
            <template #icon>
              <SettingOutlined />
            </template> Ações
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </a-col>
    </template>
    <template #bodyCell="{ column, record }">
      <slot
        name="bodyCell"
        :column="column"
        :record="record"
      >
        <template v-if="column.key === 'numeroProcesso'">
          <a
            href="#"
            style="text-transform: uppercase"
            @click.prevent="() => abrirFluxo(record.codigo)"
          >{{ formatCnj(record.numeroProcesso) }}</a>
        </template>
        <template v-if="column.key === 'dataMovimento'">
          <span style="text-transform: uppercase">{{
            record.publicacao.dataMovimentoText
          }}</span>
        </template>
        <template v-if="column.key === 'conteudo'">
          <a-typography-paragraph
            :ellipsis="{ rows: 2, expandable: true }"
            :content="record.publicacao?.conteudo"
            style="
              margin-bottom: 0;
              white-space: pre-wrap;
              color: #389393;
              cursor: pointer;
            "
            @click="
              () => {
                abrirPublicacaoTexto(record.publicacao);
              }
            "
          />
        </template>
      </slot>
    </template>
  </GraphqlDatatable>
</template>
