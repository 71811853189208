<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-alert
    type="warning"
    message="Favor reenviar ao GCPJ o arquivo abaixo"
    style="margin-bottom: 24px"
    show-icon
  />

  <a-descriptions
    :column="3"
    :label-style="{ width: '25%' }"
    bordered
  >
    <a-descriptions-item
      label="Arquivo"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.arquivo }}</span>
    </a-descriptions-item>
    <a-descriptions-item
      label="Erro"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.erro }}</span>
    </a-descriptions-item>
  </a-descriptions>
</template>
