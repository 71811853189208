<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '25%' }"
    bordered
  >
    <a-descriptions-item
      label="Foi atendido?"
      :span="3"
    >
      <span style="text-transform: uppercase;">{{ props.compromisso.data.foiAtendido ? 'SIM' : 'NÃO' }}</span>
    </a-descriptions-item>
  </a-descriptions>
</template>
