<script setup>
import { onMounted, ref } from 'vue';
import dayjs from 'dayjs';
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { useObterTotalCompromissosEmAndamentoPorDiaNoMes } from '~/composables/compromissos/useObterTotalCompromissosEmAndamentoPorDiaNoMes';

const {
  status: obterTotalCompromissosEmAndamentoPorDiaNoMesStatus,
  response: obterTotalCompromissosEmAndamentoPorDiaNoMesResponse,
  obter: obterTotalCompromissosEmAndamentoPorDiaNoMes,
} = useObterTotalCompromissosEmAndamentoPorDiaNoMes();

const dataSelecionada = ref(dayjs());
const authStore = useAuthStore();
const drawerStore = useDrawerStore();

function obterMesAno(dataEmDayJs)
{
  return {
    mes: dataEmDayJs.month() + 1,
    ano: dataEmDayJs.year(),
  };
}

async function onCalendarChange(data)
{
  await obterTotalCompromissosEmAndamentoPorDiaNoMes(obterMesAno(data));
}

function abrirCompromissosPorDia(vencimentoEm)
{
  const dataVencimento = vencimentoEm.format('DD/MM/YYYY');

  const dataVencimentoInicial = dayjs(`${dataVencimento} 00:00:00`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();
  const dataVencimentoFinal = dayjs(`${dataVencimento} 23:59:59`, 'DD/MM/YYYY HH:mm:ss').tz(authStore.timeZone).utc().format();

  drawerStore.push2({
    title: `COMPROMISSOS DO DIA  ${dataVencimento}`,
    wrapperStyle: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
    componentName: 'CompromissoGqlDatatable',
    params: {
      enableRowSelection: true,
      showStatusSearchBar: false,
      showDatesFilter: false,
      showFilter: false,
      variables: {
        order: [{ dataInicio: 'DESC' }],
        where: {
          status: {
            eq: 1,
          },
          responsavelCodigo: {
            eq: authStore.codigoUsuario,
          },
          and: [{
            dataVencimento: {
              lte: dataVencimentoFinal,
            },
          }, {
            dataVencimento: {
              gte: dataVencimentoInicial,
            },
          }],
        },
      },
    },
    onClose: async () =>
    {
      await obterTotalCompromissosEmAndamentoPorDiaNoMes(
        obterMesAno(dataSelecionada.value),
      );
    },
  });
}

onMounted(async () =>
{
  await obterTotalCompromissosEmAndamentoPorDiaNoMes(
    obterMesAno(dataSelecionada.value),
  );
});
</script>

<template>
  <Page menu="agenda">
    <div class="agenda-page">
      <a-row justify="center">
        <a-col :xs="24" :md="24" :lg="24" :xl="24">
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Sua agenda
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->
          <!-- <div v-if="obterTotalCompromissosEmAndamentoPorDiaNoMesStatus === 'loading'" style="margin-top: 24px">
            <a-skeleton :loading="true" :paragraph="{ rows: 8 }" />
          </div> -->
          <ErrorResult
            v-if="
              obterTotalCompromissosEmAndamentoPorDiaNoMesStatus === 'error'
            "
          />
          <a-spin
            v-else
            :spinning="
              obterTotalCompromissosEmAndamentoPorDiaNoMesStatus === 'loading'
            "
          >
            <a-calendar
              v-model:value="dataSelecionada"
              :locale="{
                lang: { locale: 'pt_BR', month: 'Mês', year: 'Ano' },
              }"
              mode="month"
              @panel-change="onCalendarChange"
            >
              <template #dateCellRender="{ current: value }">
                <ul
                  v-if="
                    obterTotalCompromissosEmAndamentoPorDiaNoMesResponse?.totalCompromissosPorDia?.hasOwnProperty(
                      value.format('DD/MM/YYYY'),
                    )
                  "
                  class="events"
                >
                  <li>
                    <a-tooltip>
                      <template #title>
                        Você possui
                        {{
                          obterTotalCompromissosEmAndamentoPorDiaNoMesResponse
                            .totalCompromissosPorDia[value.format('DD/MM/YYYY')]
                        }}
                        compromissos em andamento
                      </template>
                      <a-tag
                        color="#108ee9"
                        style="
                          color: white;
                          min-width: 100%;
                          cursor: pointer;
                          margin: 0;
                          margin-bottom: 2px;
                        "
                        @click.prevent="() => abrirCompromissosPorDia(value)"
                      >
                        {{
                          obterTotalCompromissosEmAndamentoPorDiaNoMesResponse
                            .totalCompromissosPorDia[value.format('DD/MM/YYYY')]
                        }}
                        COMPROMISSOS
                      </a-tag>
                    </a-tooltip>
                  </li>
                </ul>
              </template>
            </a-calendar>
          </a-spin>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>

<style lang="less">
.agenda-page {
  & .ant-radio-group.ant-radio-group-outline.ant-picker-calendar-mode-switch {
    display: none;
  }

  // &
  //   .ant-fullcalendar-fullscreen
  //   .ant-radio-group.ant-radio-group-outline.ant-radio-group-default {
  //   display: none;
  // }

  // & .ant-fullcalendar-value {
  //   &:hover {
  //     cursor: default;
  //     background: transparent;
  //   }
  // }

  // & .ant-fullcalendar-fullscreen .ant-fullcalendar-cell .ant-fullcalendar-date {
  //   &:hover {
  //     cursor: default;
  //     background: transparent;
  //   }

  //   &:active {
  //     background: transparent;
  //   }
  // }

  // &
  //   .ant-fullcalendar-fullscreen
  //   .ant-fullcalendar-cell.ant-fullcalendar-selected-day
  //   .ant-fullcalendar-date {
  //   background: transparent;
  //   &:hover {
  //     cursor: default;
  //     background: transparent;
  //   }

  //   &:active {
  //     background: transparent;
  //   }
  // }

  & .events {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  & .events .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}
</style>
