<script setup>
import { onMounted, reactive, ref } from 'vue';
import dayjs from 'dayjs';
import { useGraphQL } from '~/composables/useGraphQL';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
  mostrarBotaoVerFluxo: {
    default: true,
    type: Boolean,
  },
});

const emit = defineEmits(['close']);

const {
  data: obterCompromissoViewData,
  runAsync: obterCompromissoViewAsync,
  status: obterCompromissoViewStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'COMPROMISSO_VIEW',
});

const authStore = useAuthStore();
const drawerStore = useDrawerStore();
const activeKeys = ref([]);
const isFormDone = ref(false);
const state = reactive({
  compromisso: null,
  fluxo: null,
  status: 'loading',
});
const colunas = [
  {
    key: 'pasta',
    title: 'PASTA',
  },
  {
    key: 'numeroCnj',
    title: 'NÚMERO CNJ',
  },
  {
    key: 'responsavelProcessual',
    title: 'RESPONSÁVEL PROCESSUAL',
  },
  {
    key: 'responsavelNegocial',
    title: 'RESPONSÁVEL NEGOCIAL',
  },
  {
    key: 'contrario',
    title: 'CONTRÁRIO',
  },
];
const colunasCompromisso = [
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    title: 'INICIADO EM',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    title: 'VENCIMENTO EM',
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    title: 'CONCLUÍDO EM',
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
  },
];
const colunasCompromissoForm = [
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    title: 'INICIADO EM',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    title: 'VENCIMENTO EM',
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
  },
];
const colunasNumeroProcesso = [
  {
    key: 'numeroProcesso',
    title: 'NÚMERO PROCESSO',
  },
];

const publicacaoNumeroProcessoCollapseActiveKey = ref(null);

async function uiObterCompromissoViewAsync()
{
  state.status = 'loading';

  await obterCompromissoViewAsync({
    compromissoCodigo: props.compromisso.codigo,
  });

  if (obterCompromissoViewStatus.value === 'success')
  {
    state.fluxo = obterCompromissoViewData.value.result;
    state.compromisso = state.fluxo.compromissos.find(
      x => x.codigo === props.compromisso.codigo,
    );
  }

  state.status = obterCompromissoViewStatus.value;
}

function abrirFluxo(codigo)
{
  drawerStore.push2({
    componentName: 'FluxoView',
    onClose: async () =>
    {
      await uiObterCompromissoViewAsync();
    },
    params: { codigoFluxo: codigo },
  });
}

function abrirDelegarCompromisso(compromisso)
{
  drawerStore.push2({
    componentName: 'DelegarCompromissoForm',
    onClose: async () =>
    {
      await uiObterCompromissoViewAsync();
    },
    params: { compromissoCodigo: compromisso.codigo },
  });
}

function abrirAlterarVencimentoCompromisso(compromisso)
{
  drawerStore.push2({
    componentName: 'AlterarVencimentoCompromissoForm',
    onClose: async () =>
    {
      await uiObterCompromissoViewAsync();
    },
    params: { compromissoCodigo: compromisso.codigo },
  });
}

function expandirTodosCompromissos()
{
  state.fluxo.compromissos.forEach((x) =>
  {
    activeKeys.value.push(x.codigo);
  });
}

function contrairTodosCompromissos()
{
  activeKeys.value = [];
}

onMounted(async () =>
{
  await uiObterCompromissoViewAsync();
  activeKeys.value = [props.compromisso.codigo];
});
</script>

<template>
  <div
    class="compromisso-view"
    style="
      padding-bottom: 24px;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
    "
  >
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          v-if="state.status === 'loading'"
          :loading="true"
          :paragraph="false"
          :active="true"
          :title="{ width: '75%' }"
        />
        <div v-else-if="state.status === 'success'">
          <span
            style="
              font-size: 16px;
              font-weight: 500;
              line-height: 35px;
              text-transform: uppercase;
            "
          >
            {{ state.compromisso.tipoText.toUpperCase() }}
          </span>
          <template v-if="!isFormDone">
            <a-button
              v-if="mostrarBotaoVerFluxo"
              style="margin-left: 8px"
              @click="() => abrirFluxo(state.fluxo.codigo)"
            >
              Ver fluxo
            </a-button>
            <template v-if="state.compromisso.status === 1">
              <a-button
                v-if="
                  authStore.temPerfilAcesso(PERFIL_ACESSO.DelegarCompromisso)
                "
                style="margin: 0 8px"
                @click="() => abrirDelegarCompromisso(state.compromisso)"
              >
                Delegar
              </a-button>
              <a-button
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.AlterarVencimentoCompromisso,
                  )
                "
                @click="
                  () => abrirAlterarVencimentoCompromisso(state.compromisso)
                "
              >
                Alterar vencimento
              </a-button>
            </template>
          </template>
        </div>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <a-skeleton
        v-if="state.status === 'loading'"
        :loading="true"
        :active="true"
        :paragraph="{ rows: 12 }"
      />
      <ErrorResult
        v-else-if="state.status === 'error'"
        :tentar-novamente-fn="uiObterCompromissoViewAsync"
      />
      <div v-if="state.status === 'success'">
        <a-alert
          v-if="state.fluxo.status === 4"
          type="error"
          style="margin-bottom: 16px"
          show-icon
        >
          <template #message>
            O FLUXO DESSE COMPROMISSO FOI CANCELADO POR
            <b>{{ state.fluxo.canceladoPor.nome }}</b>
          </template>
          <template
            v-if="!isNilOrEmpty(state.fluxo.justificativaCancelamento)"
            #description
          >
            <span
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              "
            >
              {{ state.fluxo.justificativaCancelamento }}
            </span>
          </template>
        </a-alert>

        <a-alert
          v-if="state.fluxo.status === 5"
          type="error"
          style="margin-bottom: 16px"
          show-icon
        >
          <template #message>
            O FLUXO DESSE COMPROMISSO FOI DEFINIDO COMO NÃO ATENDIDO POR
            <b>{{ state.fluxo.falhadoPor.nome }}</b>
          </template>
          <template
            v-if="!isNilOrEmpty(state.fluxo.justificativaFalha)"
            #description
          >
            <span
              style="
                word-wrap: break-word;
                white-space: pre-wrap;
                word-break: break-word;
              "
            >
              {{ state.fluxo.justificativaFalha }}
            </span>
          </template>
        </a-alert>

        <a-table
          v-if="state.fluxo.pasta && !isFormDone"
          :data-source="[state.fluxo.pasta]"
          :columns="colunas"
          :pagination="false"
          style="margin-bottom: 12px"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'pasta'">
              <a-tooltip placement="top">
                <template #title>
                  Ir para página da pasta
                </template>
                <a :href="`/pasta/${record.codigo}`">
                  {{ record.nome }}
                </a>
              </a-tooltip>
            </template>
            <template v-if="column.key === 'numeroCnj'">
              {{ formatCnj(record.numeroCnj) }}
            </template>
            <template v-if="column.key === 'responsavelProcessual'">
              {{ record.responsavelProcessual?.nome }}
            </template>
            <template v-if="column.key === 'responsavelNegocial'">
              {{ record.responsavelNegocial?.nome }}
            </template>
            <template v-if="column.key === 'contrario'">
              {{ record.contrarioPrincipal?.nome }}
            </template>
          </template>
        </a-table>

        <a-table
          v-if="
            !state.fluxo.pasta
              && !isFormDone
              && state.fluxo.publicacaoNumeroProcesso
          "
          :data-source="[state.fluxo.publicacaoNumeroProcesso]"
          :columns="colunasNumeroProcesso"
          :pagination="false"
          style="margin-bottom: 12px"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'numeroProcesso'">
              {{ formatCnj(record.numeroProcesso) }}
            </template>
          </template>
        </a-table>

        <a-collapse
          v-if="state.fluxo.publicacaoNumeroProcesso"
          v-model:activeKey="publicacaoNumeroProcessoCollapseActiveKey"
          style="margin-bottom: 12px"
        >
          <a-collapse-panel key="1" header="PUBLICAÇÃO">
            <PublicacaoView
              :publicacao-numero-processo="state.fluxo.publicacaoNumeroProcesso"
            />
          </a-collapse-panel>
        </a-collapse>

        <a-row style="margin-bottom: 8px">
          <a-col span="24" style="text-align: right">
            <a-space>
              <a-button @click="expandirTodosCompromissos">
                Expandir todos
              </a-button>
              <a-button @click="contrairTodosCompromissos">
                Contrair todos
              </a-button>
            </a-space>
          </a-col>
        </a-row>

        <a-collapse
          v-if="state.fluxo.compromissos.length > 0"
          v-model:activeKey="activeKeys"
        >
          <!-- USAR ISSO DEPOIS NO LOOP && (x.codigo === state.compromisso.codigo || dayjs(x.dataConclusao).diff(state.compromisso.dataInicio) <= 0) -->
          <a-collapse-panel
            v-for="compromissoNoLoop in state.fluxo.compromissos.filter(
              (x) => x.status !== 1,
            )"
            :key="compromissoNoLoop.codigo"
          >
            <template #header>
              <span>{{
                `${compromissoNoLoop.tipoText.toUpperCase()} ${
                  compromissoNoLoop.status === 2
                    ? '(DEVOLVIDO)'
                    : compromissoNoLoop.status === 4
                      ? `(CANCELADO POR ${state.fluxo.canceladoPor.nome})`
                      : compromissoNoLoop.status === 6
                        ? `(DEFINIDO COMO NÃO ATENDIDO POR ${state.fluxo.falhadoPor.nome})`
                        : ''
                }`
              }}</span>
            </template>

            <template #extra>
              <span
                v-if="
                  compromissoNoLoop.status === 2
                    || compromissoNoLoop.status === 3
                "
              >{{
                 `${
                   compromissoNoLoop.dataConclusaoText
                 } ${compromissoNoLoop.responsavel.nome?.toUpperCase()}`
               }}
                <a-tag
                  v-if="compromissoNoLoop.responsavel.estaDeFerias"
                  color="rgb(255 127 107)"
                >FÉRIAS</a-tag></span>
              <span v-if="compromissoNoLoop.status === 4">{{
                `${
                  compromissoNoLoop.dataConclusaoText
                } ${state.fluxo.canceladoPor.nome.toUpperCase()}`
              }}</span>
            </template>

            <a-table
              :data-source="[compromissoNoLoop]"
              :columns="colunasCompromisso"
              :pagination="false"
              style="margin-bottom: 12px"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'responsavel'">
                  {{ record.responsavel.nome }}
                  <a-tag
                    v-if="record.responsavel.estaDeFerias"
                    color="rgb(255 127 107)"
                  >
                    FÉRIAS
                  </a-tag>
                </template>
              </template>
            </a-table>

            <template v-if="compromissoNoLoop.status === 2">
              <a-alert type="warning">
                <template #message>
                  Esse compromisso foi devolvido ao passo anterior
                </template>
              </a-alert>
              <a-row style="margin-top: 24px">
                <a-col :span="24">
                  <a-descriptions
                    :column="3"
                    :label-style="{ width: '30%' }"
                    bordered
                  >
                    <a-descriptions-item
                      label="Justificativa da devolução"
                      :span="3"
                    >
                      {{ compromissoNoLoop.justificativaDevolucao }}
                    </a-descriptions-item>
                  </a-descriptions>
                </a-col>
              </a-row>
            </template>
            <template v-if="compromissoNoLoop.status === 4">
              <a-alert type="warning">
                <template #message>
                  Esse compromisso foi cancelado junto com o seu fluxo
                </template>
              </a-alert>
            </template>

            <template v-if="compromissoNoLoop.status === 3">
              <component
                :is="`${compromissoNoLoop.componentName}View`"
                :compromisso="compromissoNoLoop"
                :fluxo="state.fluxo"
              />
            </template>
          </a-collapse-panel>
        </a-collapse>

        <div
          v-if="state.compromisso.status === 1"
          style="margin-top: 24px"
        >
          <a-table
            v-if="!isFormDone"
            :data-source="[state.compromisso]"
            :columns="colunasCompromissoForm"
            :pagination="false"
            style="margin-bottom: 12px"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'responsavel'">
                {{ record.responsavel.nome }}
              </template>
            </template>
          </a-table>
          <component
            :is="`${state.compromisso.componentName}Form`"
            :compromisso="state.compromisso"
            :fluxo="state.fluxo"
            @close="() => emit('close')"
            @success="() => (isFormDone = true)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.compromisso-view {
  & .ant-skeleton-content .ant-skeleton-title {
    margin-top: 10px;
  }
}
.ant-descriptions-item-content > span {
  text-transform: uppercase;
}
</style>
