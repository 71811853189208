<script setup lang="ts">
import { onMounted } from 'vue';

interface Props
{
  compromisso: any
}

const props = defineProps<Props>();

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
    style="margin-top: 24px"
  >
    <a-descriptions-item
      label="Resultados"
      :span="3"
    >
      <span v-if="props.compromisso.data?.resultados?.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="resultado in props.compromisso.data.resultados"
          :key="resultado"
        >
          {{ resultado }}
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
