<script setup>
import { formatCnj } from '~/utils';

const props = defineProps({
  publicacaoNumeroProcesso: {
    required: true,
    type: Object,
  },
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Código" :span="3">
      {{ publicacaoNumeroProcesso.codigo }}
      <a-typography-text
        :copyable="{
          text: publicacaoNumeroProcesso.codigo,
        }"
      >
        <template #copyableTooltip="{ copied }">
          <span v-if="!copied" key="copy-tooltip">Copiar</span>
          <span v-else key="copied-tooltip">Copiado!</span>
        </template>
      </a-typography-text>
    </a-descriptions-item>
    <a-descriptions-item label="Número processo" :span="3">
      {{ formatCnj(publicacaoNumeroProcesso.numeroProcesso) }}
    </a-descriptions-item>
    <a-descriptions-item label="Diário" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.diario }}
    </a-descriptions-item>
    <a-descriptions-item label="Caderno" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.caderno }}
    </a-descriptions-item>
    <a-descriptions-item label="Seção" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.secao }}
    </a-descriptions-item>
    <a-descriptions-item label="Tipo" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.tipo }}
    </a-descriptions-item>
    <a-descriptions-item label="Página" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.pagina }}
    </a-descriptions-item>
    <a-descriptions-item label="Data da publicação" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.dataMovimentoText }}
    </a-descriptions-item>
    <a-descriptions-item label="Conteúdo" :span="3">
      {{ publicacaoNumeroProcesso.publicacao.conteudo }}
    </a-descriptions-item>
  </a-descriptions>
</template>
