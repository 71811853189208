<script setup>
import { notification } from 'ant-design-vue';
import { onMounted, reactive, ref } from 'vue';
import { useSalvarPessoaEndereco } from '~/composables/pessoas/useSalvarPessoaEndereco';
import { useGraphQL } from '~/composables/useGraphQL';

const props = defineProps({
  ehPrincipal: {
    default: null,
    type: Boolean,
  },
  pessoaCodigo: {
    required: true,
    type: String,
  },
  pessoaEnderecoCodigo: {
    default: null,
    type: String,
  },
});

const emit = defineEmits(['success', 'close']);

const {
  data: pessoaEnderecoQueryData,
  runAsync: pessoaEnderecoQueryAsync,
  status: pessoaEnderecoQueryStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'PessoaEnderecoQuery',
});

const {
  err: salvarPessoaEnderecoErr,
  runAsync: salvarPessoaEnderecoAsync,
  status: salvarPessoaEnderecoStatus,
} = useSalvarPessoaEndereco();

const cidadeSelectRef = ref(null);
const formRef = ref(null);
const state = reactive({
  bairro: null,
  cep: null,
  cidade: null,
  complemento: null,
  descricao: null,
  ehPrincipal: props.ehPrincipal,
  estado: null,
  logradouro: null,
  numero: null,
  pessoaCodigo: null,
  status: null,
});
const formRules = reactive({
  cidade: [
    {
      message: 'Cidade é obrigatória',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  descricao: [{ message: 'Descrição é obrigatória', required: true }],
  ehPrincipal: [
    {
      message: 'Definir se o endereço é o principal é obrigatório',
      required: true,
    },
  ],
  estado: [
    {
      message: 'Estado é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  logradouro: [{ message: 'Logradouro é obrigatório', required: true }],
});

async function carregarAsync()
{
  await pessoaEnderecoQueryAsync({
    codigo: props.pessoaEnderecoCodigo,
  });

  if (pessoaEnderecoQueryStatus.value === 'success')
  {
    const pessoaEndereco = pessoaEnderecoQueryData.value.pessoaEndereco;
    state.ehPrincipal = pessoaEndereco.ehPrincipal;
    state.descricao = pessoaEndereco.descricao;
    state.logradouro = pessoaEndereco.endereco.logradouro;
    state.estado = {
      key: pessoaEndereco.endereco.estadoCodigo,
      label: pessoaEndereco.endereco.estado.nome.toUpperCase(),
      value: pessoaEndereco.endereco.estadoCodigo,
    };
    state.cidade = {
      key: pessoaEndereco.endereco.cidadeCodigo,
      label: pessoaEndereco.endereco.cidade.nome.toUpperCase(),
      value: pessoaEndereco.endereco.cidadeCodigo,
    };
    state.numero = pessoaEndereco.endereco.numero;
    state.complemento = pessoaEndereco.endereco.complemento;
    state.bairro = pessoaEndereco.endereco.bairro;
    state.cep = pessoaEndereco.endereco.cep;
  }

  state.status = pessoaEnderecoQueryStatus.value;
}

async function salvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    const cmd = {
      bairro: state.bairro,
      cep: state.cep,
      cidadeCodigo: state.cidade?.value,
      complemento: state.complemento,
      descricao: state.descricao,
      ehPrincipal: state.ehPrincipal,
      estadoCodigo: state.estado?.value,
      logradouro: state.logradouro,
      numero: state.numero,
      pessoaCodigo: props.pessoaCodigo,
      pessoaEnderecoCodigo: props.pessoaEnderecoCodigo,
    };

    await salvarPessoaEnderecoAsync(cmd);

    if (salvarPessoaEnderecoStatus.value === 'error')
    {
      notification.error({
        description: salvarPessoaEnderecoErr.value.description,
        duration: 5,
        message: salvarPessoaEnderecoErr.value.message,
      });
    }
  });
}

function onEstadoChange()
{
  state.cidade = null;
  cidadeSelectRef.value.clearItems();
}

onMounted(async () =>
{
  state.status = 'loading';
  if (props.pessoaEnderecoCodigo)
  {
    await carregarAsync();
  }
  else
  {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          :loading="state.status === 'loading'"
          :paragraph="false"
          :active="true"
          :title="{ width: '65%' }"
        >
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            {{
              props.pessoaEnderecoCodigo !== null
                ? 'ATUALIZAR ENDEREÇO'
                : 'ADICIONAR ENDEREÇO'
            }}</span>
        </a-skeleton>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="state.status === 'error'"
          :tentar-novamente-fn="carregarAsync"
        />
        <div v-else-if="state.status === 'success'">
          <a-result
            v-if="salvarPessoaEnderecoStatus === 'success'"
            status="success"
            title="Endereço salvo com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('success')">
                Fechar
              </a-button>
            </template>
          </a-result>
          <a-form
            v-else
            ref="formRef"
            layout="vertical"
            :model="state"
            :rules="formRules"
          >
            <a-form-item label="É o endereço principal?" name="ehPrincipal">
              <a-radio-group v-model:value="state.ehPrincipal">
                <a-radio-button :value="true">
                  Sim
                </a-radio-button>
                <a-radio-button :value="false">
                  Não
                </a-radio-button>
              </a-radio-group>
            </a-form-item>

            <a-form-item
              label="Descrição"
              name="descricao"
              style="padding-bottom: 0"
            >
              <a-input v-model:value="state.descricao" />
            </a-form-item>

            <a-form-item
              label="Logradouro"
              name="logradouro"
              style="padding-bottom: 0"
            >
              <a-input v-model:value="state.logradouro" />
            </a-form-item>

            <a-form-item label="Número" name="numero" style="padding-bottom: 0">
              <a-input v-model:value="state.numero" />
            </a-form-item>

            <a-form-item
              label="Complemento"
              name="complemento"
              style="padding-bottom: 0"
            >
              <a-input v-model:value="state.complemento" />
            </a-form-item>

            <a-form-item label="Bairro" name="bairro" style="padding-bottom: 0">
              <a-input v-model:value="state.bairro" />
            </a-form-item>

            <a-form-item label="Cep" name="cep" style="padding-bottom: 0">
              <a-input v-model:value="state.cep" v-maska="'#####-###'" />
            </a-form-item>

            <a-form-item label="Estado" name="estado">
              <GraphQlSelect
                v-model:value="state.estado"
                query="ESTADOS_SELECT"
                :variables="{
                  order: [
                    {
                      nome: 'ASC',
                    },
                  ],
                }"
                placeholder="Selecione o estado"
                @change="onEstadoChange"
              />
            </a-form-item>

            <a-form-item label="Cidade" name="cidade">
              <GraphQlSelect
                ref="cidadeSelectRef"
                v-model:value="state.cidade"
                :disabled="state.estado === null"
                query="CIDADES_SELECT"
                :variables="{
                  where: {
                    estadoCodigo: {
                      in: !isNilOrEmpty(state.estado)
                        ? [state.estado.value]
                        : [],
                    },
                  },
                }"
                placeholder="Selecione a cidade"
              />
            </a-form-item>

            <a-row style="margin-top: 24px">
              <a-col :span="16">
                <a-button
                  :loading="salvarPessoaEnderecoStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="salvarAsync"
                >
                  Salvar
                </a-button>
                <a-button
                  style="margin-right: 8px"
                  @click="() => emit('close')"
                >
                  Cancelar
                </a-button>
              </a-col>
              <a-col :span="8" style="text-align: right" />
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
