<script setup lang="ts">
import { reactive, ref, watch } from 'vue';
import { merge } from 'lodash-es';
import { CaretDownFilled, SettingOutlined } from '@ant-design/icons-vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import compromissoDatatable from '~/graphql/compromissoDatatable.graphql';
import { useDrawerStore } from '~/stores/drawer';
import tipoCompromissoSelect from '~/graphql/tipoCompromissoSelect.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import tipoFluxoSelect from '~/graphql/tipoFluxoSelect.graphql';
import estadoSelect from '~/graphql/estadoSelect.graphql';
import { isNilOrEmpty } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import PERFIL_ACESSO from '~/perfilAcesso';
import tiposJobs from '~/constants/tipoJob';

interface Props
{
  disabledFilters?: string[]
  variables?: any
  showStatusSearchBar?: boolean
  showDatesFilter?: boolean
  showFilter?: boolean
  showReloadButton?: boolean
  showTextSearch?: boolean
  columns?: GraphqlDatatableColumn[]
  enableRowSelection?: boolean
  totalPerPage?: number
}

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataInicio: 'DESC' }] };
  },
  showStatusSearchBar: true,
  showFilter: true,
  showDatesFilter: true,
  showReload: true,
  enableRowSelection: false,
  showTextSearch: false,
  totalPerPage: 10,
});

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);
const state = reactive<any>({
  status: null,
  dataInicioRange: null,
  dataVencimentoRange: null,
});

const filters = [] as GraphqlDatatableFilter[];

if (props.showFilter && !props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Códigos de compromissos',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'tipo'))
{
  filters.push({
    key: 'tipo',
    label: 'Tipos de compromisso',
    path: 'tipo',
    type: 'gql-select',
    queryKey: 'tc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais tipos de compromisso',
      query: tipoCompromissoSelect,
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'dataInicio'))
{
  filters.push({
    key: 'dataInicio',
    label: 'Data de inicio',
    path: 'dataInicio',
    type: 'date',
    queryKey: 'di',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'dataConclusao'))
{
  filters.push({
    key: 'dataConclusao',
    label: 'Data de conclusão',
    path: 'dataConclusao',
    type: 'date',
    queryKey: 'dc',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'fluxoDataInicio'))
{
  filters.push({
    key: 'fluxoDataInicio',
    label: 'Fluxo / Data de início',
    path: 'fluxo.dataInicio',
    type: 'date',
    queryKey: 'fdi',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'fluxoDataConclusao'))
{
  filters.push({
    key: 'fluxoDataConclusao',
    label: 'Fluxo / Data de conclusão',
    path: 'fluxo.dataConclusao',
    type: 'date',
    queryKey: 'fdc',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'responsavel'))
{
  filters.push({
    key: 'responsavel',
    label: 'Responsável pelo compromisso',
    path: 'responsavelCodigo',
    type: 'gql-select',
    queryKey: 'r',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'tipoFluxo'))
{
  filters.push({
    key: 'tipoFluxo',
    label: 'Tipos de fluxo',
    path: 'fluxo.tipo',
    type: 'gql-select',
    queryKey: 'tf',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais tipos de fluxo',
      query: tipoFluxoSelect,
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'fluxoCodigo'))
{
  filters.push({
    key: 'fluxoCodigo',
    label: 'Códigos de fluxos',
    path: 'fluxo.codigo',
    type: 'text-multiple',
    queryKey: 'fc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos de fluxos',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'pastaNome'))
{
  filters.push({
    key: 'pastaNome',
    label: 'Nomes de pastas',
    path: 'fluxo.pasta.nome',
    type: 'text-multiple',
    queryKey: 'pn',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais nomes',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'pastaGcpj'))
{
  filters.push({
    key: 'pastaGcpj',
    label: 'GCPJs de pastas',
    path: 'fluxo.pasta.gcpj',
    type: 'text-multiple',
    queryKey: 'pg',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais GCPJs',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'pastaNumeroCnj'))
{
  filters.push({
    key: 'pastaNumeroCnj',
    label: 'Número CNJ de pastas',
    path: 'fluxo.pasta.numeroCnj',
    type: 'text-multiple',
    queryKey: 'pncnj',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números CNJ',
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'responsavelProcessual'))
{
  filters.push({
    key: 'responsavelProcessual',
    label: 'Responsável processual da pasta',
    path: 'fluxo.pasta.responsaveis.some.usuarioCodigo',
    type: 'gql-select',
    queryKey: 'rp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (props.showFilter && !props.disabledFilters.find(x => x === 'estado'))
{
  filters.push({
    key: 'estado',
    label: 'Estado',
    path: 'fluxo.pasta.estadoCodigo',
    type: 'gql-select',
    queryKey: 'e',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais estados',
      query: estadoSelect,
    },
  });
}

const colunas = props.columns ?? [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    key: 'pasta',
    title: 'PASTA',
    width: 120,
  },
  {
    key: 'contrario',
    title: 'CONTRÁRIO',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

function abrirCompromisso(compromissoCodigo: string)
{
  drawerStore.push2({
    componentName: 'CompromissoView',
    onClose: async () =>
    {
      if (tableRef.value)
      {
        await tableRef.value?.reloadAsync();
      }
    },
    params: { compromisso: { codigo: compromissoCodigo } },
  });
}

const compromissosSelecionados = ref<any[]>([]);

function onSelectChange(keys: any[])
{
  compromissosSelecionados.value = [...keys];
}

function acoesCompromissosSelecionadosOnClick({ key }: any)
{
  if (key === 'delegar')
  {
    drawerStore.push2({
      componentName: 'DelegarCompromissoForm',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        compromissosCodigos: compromissosSelecionados.value,
      },
      width: 600,
    });
  }
  if (key === 'alterar-vencimento')
  {
    drawerStore.push2({
      componentName: 'AlterarVencimentoCompromissoForm',
      onClose: async () =>
      {
        await tableRef.value?.reloadAsync();
      },
      params: {
        compromissosCodigos: compromissosSelecionados.value,
      },
      width: 600,
    });
  }
}

watch(state, (val) =>
{
  const stateFilters: any = {
    where: {},
  };

  if (val.status)
  {
    stateFilters.status = val.status;
  }

  if (val.dataInicioRange?.length > 0)
  {
    stateFilters.where.dataInicio = {
      gte: `${state.dataInicioRange[0]} 00:00:00`,
      lte: `${state.dataInicioRange[1]} 23:59:59`,
    };
  }
  else
  {
    delete stateFilters.where.dataInicio;
  }

  if (val.dataVencimentoRange?.length > 0)
  {
    stateFilters.where.dataVencimento = {
      gte: `${state.dataVencimentoRange[0]} 00:00:00`,
      lte: `${state.dataVencimentoRange[1]} 23:59:59`,
    };
  }
  else
  {
    delete stateFilters.where.dataVencimento;
  }

  const vars: any = {};
  merge(vars, stateFilters, props.variables);

  gqlVariables.value = { ...vars };
});

defineExpose({
  reload: async () =>
  {
    await tableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <a-row
    v-if="props.showStatusSearchBar"
    style="margin-bottom: 16px"
  >
    <a-col
      :span="24"
      style="text-align: right"
    >
      <a-space
        style="text-align: left"
        size="middle"
      >
        <a-radio-group
          v-model:value="state.status"
          size="large"
        >
          <a-radio-button :value="null">
            Todos
          </a-radio-button>
          <a-radio-button :value="1">
            Atrasado
          </a-radio-button>
          <a-radio-button :value="2">
            Vencimento hoje
          </a-radio-button>
          <a-radio-button :value="3">
            Vencimento nos próximos dias
          </a-radio-button>
          <a-radio-button :value="7">
            Em andamento
          </a-radio-button>
          <a-radio-button :value="4">
            Devolvido
          </a-radio-button>
          <a-radio-button :value="5">
            Concluído
          </a-radio-button>
          <a-radio-button :value="6">
            Cancelado
          </a-radio-button>
        </a-radio-group>
      </a-space>
    </a-col>
  </a-row>
  <GqlDatatable
    ref="tableRef"
    :query="compromissoDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :filters="filters"
    :keep-history="false"
    :show-reload-button="props.showReloadButton"
    :text-search="props.showTextSearch ? { placeholder: 'Busca rápida de compromissos por atributos das pastas (nome, GCPJ, número CNJ, número antigo, status, tipo da ação, cliente principal, contrário principal, responsável processual ou negocial, nome da agência, regional e jurisdição)' } : null"
    row-key="codigo"
    :row-selection="props.enableRowSelection
      ? {
        selectedRowKeys: compromissosSelecionados,
        onChange: onSelectChange,
      }
      : null"
    export-command="ExportarCompromissoDatatableCommand"
    :export-job-type="tiposJobs.RelatorioCompromissosExportado"
    :total-per-page="props.totalPerPage"
  >
    <template
      v-if="props.showDatesFilter"
      #leftTopActions
    >
      <a-col flex="350px">
        <a-range-picker
          v-model:value="state.dataInicioRange"
          :placeholder="['Data solicitação inicial', 'Data solicitiação final']"
          format="DD/MM/YYYY"
          value-format="DD/MM/YYYY"
          size="large"
        />
      </a-col>
      <a-col flex="350px">
        <a-range-picker
          v-model:value="state.dataVencimentoRange"
          :placeholder="['Data vencimento inicial', 'Data vencimento final']"
          format="DD/MM/YYYY"
          value-format="DD/MM/YYYY"
          size="large"
        />
      </a-col>
      <a-col
        v-if="
          compromissosSelecionados.length > 0
            && (authStore.temPerfilAcesso(PERFIL_ACESSO.AutomatizacaoDelegarEmLote)
              || authStore.temPerfilAcesso(
                PERFIL_ACESSO.AutomatizacaoAlterarVencimentoEmLote,
              ))
        "
        flex="100px"
      >
        <a-dropdown>
          <template #overlay>
            <a-menu @click="acoesCompromissosSelecionadosOnClick">
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.AutomatizacaoDelegarEmLote,
                  )
                "
                key="delegar"
              >
                Delegar
              </a-menu-item>
              <a-menu-item
                v-if="
                  authStore.temPerfilAcesso(
                    PERFIL_ACESSO.AutomatizacaoAlterarVencimentoEmLote,
                  )
                "
                key="alterar-vencimento"
              >
                Alterar vencimento
              </a-menu-item>
            </a-menu>
          </template>
          <a-button
            block
            size="large"
          >
            <template #icon>
              <SettingOutlined />
            </template>
            Ações <CaretDownFilled />
          </a-button>
        </a-dropdown>
      </a-col>
    </template>

    <template #compromissoColumn="{ record, column }">
      <slot
        name="compromissoColumn"
        :column="column"
        :record="record"
      >
        <a
          href="#"
          style="text-transform: uppercase"
          @click.prevent="abrirCompromisso(record.codigo)"
        >{{ record.tipoText }}</a>
      </slot>
    </template>
    <template #responsavelColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.responsavel?.nome }}
      </span>
    </template>
    <template #pastaColumn="{ record }">
      <router-link
        v-if="!isNilOrEmpty(record.fluxo.pasta)"
        :to="`/pasta/${record.fluxo.pasta.codigo}`"
      >
        {{ record.fluxo.pasta.nome }}
      </router-link>
    </template>
    <template #contrarioColumn="{ record }">
      <span v-if="!isNilOrEmpty(record.fluxo?.pasta?.contrarioPrincipal)">{{ record.fluxo.pasta.contrarioPrincipal?.nome }}</span>
    </template>
  </GqlDatatable>
</template>
