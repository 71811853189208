<script setup lang="ts">
import { UploadOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import * as api from '~/apis/uploader';

interface Props
{
  file: any
  beforeFileAdded?: any
}

const props = defineProps<Props>();

const emit = defineEmits(['update:file']);

const fileList = ref<any>([]);

function toFileWithoutBytes(fileWithBytes: any)
{
  return {
    name: fileWithBytes.name,
    size: fileWithBytes.size,
    type: fileWithBytes.type,
    uid: fileWithBytes.uid,
  };
}

function handleRemove()
{}

function beforeUpload(file: any)
{
  const beforeFileAddedResult = props.beforeFileAdded
    ? props.beforeFileAdded(file)
    : true;

  if (!beforeFileAddedResult)
  {
    return false;
  }

  fileList.value = [file];
  emit('update:file', toFileWithoutBytes(fileList.value[0]));
  return false;
}

const bytesPerChunk = 25600;

function totalChunksPerFile(file: any)
{
  return file.size % bytesPerChunk === 0
    ? file.size / bytesPerChunk
    : Math.floor(file.size / bytesPerChunk) + 1;
}

async function uploadFile()
{
  const file = fileList.value[0];
  const totalChunksFile = totalChunksPerFile(file);
  let inicioByte = 0;
  let fimByte = bytesPerChunk;
  let numeroChunk = 0;
  let chunk = null;

  while (numeroChunk < totalChunksFile)
  {
    chunk = file.slice(inicioByte, fimByte);
    numeroChunk += 1;

    await api.upload(file, numeroChunk, chunk);

    inicioByte = fimByte;
    fimByte = inicioByte + bytesPerChunk;
  }

  await api.merge(toFileWithoutBytes(file), totalChunksFile);
}

defineExpose({ upload: uploadFile });
</script>

<template>
  <a-upload
    :before-upload="beforeUpload"
    :show-upload-list="false"
    @remove="handleRemove"
  >
    <a-button style="height: 32px; line-height: 18px">
      <template #icon>
        <UploadOutlined />
      </template>
      Selecione um arquivo
    </a-button>
  </a-upload>
  <span
    v-if="fileList.length > 0"
    style="margin-left: 8px"
  >{{
    fileList[0].name
  }}</span>
</template>
