<script setup lang="ts">
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import { notification } from 'ant-design-vue';
import type { NotificationArgsProps } from 'ant-design-vue/es/notification';
import { SaveOutlined } from '@ant-design/icons-vue';
import { isEqual } from 'lodash-es';
import { useCommand } from '~/composables/useCommand';
import { useJobCommand } from '~/composables/useJobCommand';

export type PostQueryProcessData = (data: any) => void;
export type PreSubmitProcessData = (data: any) => void;

interface Props
{
  query?: DocumentNode
  queryParams?: any
  command: string
  commandConfig?: any
  initialModel?: object
  notificationOnSubmitSuccess?: NotificationArgsProps
  isJob?: boolean
  showJobProgress?: boolean
  submitButtonBlock?: boolean
  submitButtonText?: string
  postQueryProcessData?: PostQueryProcessData
  preSubmitProcessData?: PreSubmitProcessData
}

const props = withDefaults(defineProps<Props>(), {
  submitButtonBlock: true,
  showJobProgress: false,
  submitButtonText: 'Salvar',
});

const emit = defineEmits(['submitSuccess', 'submitError']);

let commandStatus: any;
let command: any;
let commandProgressData: any;
let commandErr: any;

if (!props.isJob)
{
  const { status, command: executeCommand, err } = useCommand({ config: props.commandConfig });
  commandStatus = status;
  command = executeCommand;
  commandErr = err;
}
else
{
  const { status, jobCommand, err, progressData } = useJobCommand();
  commandStatus = status;
  command = jobCommand;
  commandProgressData = progressData;
  commandErr = err;
}

let queryLoad: Function;
let queryRefetch: Function;
let queryResult: any;
if (props.query)
{
  const { load, refetch, result } = useLazyQuery(props.query, props.queryParams, { errorPolicy: 'none', fetchPolicy: 'no-cache' });
  queryLoad = load;
  queryRefetch = refetch;
  queryResult = result;
}

const formRef = ref<any>();
const submitStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
const queryStatusRef = ref<'loading' | 'success' | 'error' | null>(null);
let originalModel: any = {};
let resultModel: any = {};
const model = reactive<any>(props.initialModel ?? {});

async function loadQuery()
{
  if (props.query)
  {
    try
    {
      queryStatusRef.value = 'loading';
      await (queryLoad() || queryRefetch());

      if (props.postQueryProcessData)
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
        props.postQueryProcessData(model);
      }
      else
      {
        Object.keys(queryResult.value.model).forEach((key: string) => model[key] = queryResult.value.model[key]);
      }

      originalModel = { ...toRaw(model) };

      queryStatusRef.value = 'success';
    }
    catch
    {
      queryStatusRef.value = 'error';
    }
  }
  else
  {
    queryStatusRef.value = 'success';
  }
}

async function submit()
{
  await formRef.value.validate();

  submitStatusRef.value = 'loading';

  if (props.preSubmitProcessData)
  {
    props.preSubmitProcessData(model);
  }

  resultModel = { ...toRaw(model) };

  const commandData: any = {};

  Object.keys(resultModel).forEach((key: string) =>
  {
    if (!(key in originalModel))
    {
      commandData[key] = {
        changed: true,
        value: resultModel[key],
      };
    }
    else
    {
      const changed = !isEqual(resultModel[key], originalModel[key]);
      commandData[key] = {
        changed,
        value: resultModel[key],
      };
    }
  });

  await command(props.command, commandData);
}

onMounted(async () =>
{
  await loadQuery();
});

watch(commandStatus, (val: string) =>
{
  if (val === 'success')
  {
    submitStatusRef.value = 'success';

    if (props.notificationOnSubmitSuccess)
    {
      notification.success(props.notificationOnSubmitSuccess);
    }

    emit('submitSuccess');
  }

  if (val === 'error')
  {
    const isBadRequest = !props.isJob
      ? commandErr.value?.status === 400
      : commandErr.value?.resultado?.status === 400;

    if (isBadRequest)
    {
      const message = !props.isJob
        ? commandErr.value?.message
        : commandErr.value?.resultado?.message;

      notification.error({
        message,
        duration: 10,
      });
    }
    else
    {
      notification.error({
        message: 'Tivemos um erro inesperado',
        description: 'Favor entrar em contato com o suporte',
        duration: 10,
      });
    }

    submitStatusRef.value = 'error';

    emit('submitError');
  }
});

defineExpose({
  model,
});
</script>

<template>
  <a-skeleton
    v-if="queryStatusRef === 'loading'"
    active
    :paragraph="{ rows: 6 }"
  />
  <a-result
    v-else-if="queryStatusRef === 'error'"
    status="500"
    title="Tivemos um erro inesperado"
    sub-title="Favor entrar em contato com o suporte"
  >
    <template #extra>
      <a-button @click="loadQuery">
        Tentar novamente
      </a-button>
    </template>
  </a-result>
  <a-form
    v-else-if="queryStatusRef === 'success' && submitStatusRef !== 'success' || props.notificationOnSubmitSuccess"
    ref="formRef"
    :model="model"
    name="basic"
    autocomplete="off"
    layout="vertical"
  >
    <slot
      name="form"
      :model="model"
    />
    <a-form-item style="margin-top: 24px">
      <slot
        name="submitButton"
        :command-status="submitStatusRef"
        :submit="submit"
      >
        <a-button
          :block="props.submitButtonBlock"
          type="primary"
          :loading="submitStatusRef === 'loading'"
          size="large"
          @click.prevent="submit"
        >
          <template #icon>
            <slot name="submitButtonIcon">
              <SaveOutlined />
            </slot>
          </template>
          {{ props.submitButtonText }}
        </a-button>
      </slot>
    </a-form-item>
  </a-form>

  <slot
    v-if="!props.notificationOnSubmitSuccess && queryStatusRef === 'success' && submitStatusRef === 'success'"
    name="submitSuccess"
  />

  <slot
    v-if="props.showJobProgress"
    name="jobProgress"
    :data="commandProgressData"
    :submit-status="submitStatusRef"
  >
    <a-progress
      v-if="submitStatusRef === 'loading' || submitStatusRef === 'success'"
      :percent="commandProgressData?.resultado?.progress ?? 0.0"
    />
  </slot>
</template>
