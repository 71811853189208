<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { filtrarSelectOptions } from '~/utils';
import { onMounted, onUnmounted, reactive, toRaw } from 'vue';

const props = defineProps({
  allowClear: {
    default: false,
    type: Boolean,
  },
  containerStyle: {
    default: null,
    type: Object,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  emptyMessage: {
    default: 'Nenhum dado encontrado',
    type: String,
  },
  mode: {
    default: null,
    type: String,
  },
  placeholder: {
    default: 'Selecione...',
    type: String,
  },
  query: {
    required: true,
    type: String,
  },
  size: {
    default: null,
    type: String,
  },
  value: {
    default: null,
    type: Object,
  },
  valueIsNumber: {
    default: false,
    type: Boolean,
  },
  variables: {
    default: () => {},
    type: Object,
  },
});

const emit = defineEmits(['update:value', 'change']);

const { data, runAsync, status } = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: props.query,
});

const state = reactive({
  items: [],
  status: null,
});

const uiRunAsync = async (variables) => {
  state.status = 'loading';

  await runAsync(variables);

  if (status.value === 'success') {
    state.items = data.value.items.map((item) => {
      const value = item.value;
      return {
        ...item,
        ...{ label: item.label.toUpperCase() },
      };
    });
  }

  state.status = 'success';
};

const reloadAsync = async () => {
  await uiRunAsync(props.variables);
  emit('update:value', null);
  emit('change', null, toRaw(state.items));
};

const reset = async () => {
  state.items = [];
  emit('update:value', null);
  emit('change', null, toRaw(state.items));
};

const onChange = async (value) => {
  const val = value === undefined ? null : value;
  emit('update:value', val);
  emit('change', val, toRaw(state.items));
};

const onFocus = async () => {
  if (state.items.length === 0) {
    await uiRunAsync(props.variables);
  }
};

const clearItems = () => {
  state.items = [];
};

const getItems = () => {
  return toRaw(state.items);
};

onMounted(() => {
  state.items = [];
});

onUnmounted(() => {
  state.items = [];
});

defineExpose({
  clearItems,
  getItems,
  reloadAsync,
  reset,
});
</script>

<template>
  <div :style="props.containerStyle">
    <a-spin :spinning="state.status === 'loading'" size="small">
      <a-select
        :allow-clear="props.allowClear"
        :disabled="props.disabled"
        :value="props.value"
        :label-in-value="true"
        :placeholder="props.placeholder"
        :filter-option="filtrarSelectOptions"
        :options="state.items"
        :dropdown-class-name="
          state.status === 'loading' ? 'ant-select-dropdown-loading' : ''
        "
        :mode="props.mode"
        :default-active-first-option="false"
        :size="props.size"
        show-search
        style="width: 100%"
        @change="onChange"
        @focus="onFocus"
      >
        <template v-if="state.status !== 'loading'" #notFoundContent>
          <Empty :description="emptyMessage" />
        </template>
      </a-select>
    </a-spin>
  </div>
</template>
