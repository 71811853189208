<script setup>
import { UnorderedListOutlined } from '@ant-design/icons-vue';
</script>

<template>
  <Page menu="fluxos">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/compromissos">
                    Fluxos
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar fluxos
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->
          <FluxoGqlDatatable />
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
