<script setup>
import { onBeforeUpdate, onUnmounted, onUpdated, ref } from 'vue';
import ApexCharts from 'apexcharts';

const props = defineProps({
  status: {
    type: String,
    default: 'loading',
  },
  options: {
    type: Object,
    default: null,
  },
});

const chartRef = ref(null);
let chart = null;

onUpdated(() => {
  if (props.options === null) return;

  if (props.options?.xaxis?.categories?.length === 0) return;

  if (props.status === 'success' && props.options !== null) {
    chart = new ApexCharts(chartRef.value, props.options);
    chart.render();
  }
});

onBeforeUpdate(() => {
  if (chart) {
    chart.destroy();
    chart = null;
  }

  chartRef.value = null;
});

onUnmounted(() => {
  if (chart) chart.destroy();
});
</script>

<template>
  <a-card>
    <template #title>
      <slot name="title" />
    </template>
    <a-skeleton :loading="props.status === 'loading'">
      <a-result
        v-if="props.status === 'error'"
        status="warning"
        title="Tivemos um problema inesperado"
      />
      <div ref="chartRef" />
      <Empty
        v-if="props.options?.xaxis?.categories?.length === 0"
        description="Nenhum dado encontrado"
      />
    </a-skeleton>
  </a-card>
</template>
