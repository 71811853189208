<script setup>
import { getGoogleDriveUrl } from '~/utils';
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
  fluxo: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item :span="3" label="Diário">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.diario }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Caderno">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.caderno }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Seção">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.secao }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Tipo">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.tipo }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Página">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.pagina }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Data da disponibilização">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.dataMovimentoText }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Conteúdo">
      {{ props.fluxo.publicacaoNumeroProcesso.publicacao.conteudo }}
    </a-descriptions-item>
    <a-descriptions-item :span="3" label="Arquivos">
      <span v-if="props.compromisso.arquivos.length === 0"
        >Nenhum arquivo enviado</span
      >
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a :href="getGoogleDriveUrl(arquivo.fileServiceId)" target="_blank">{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
