<script setup>
import { values } from 'lodash-es';
import { ref } from 'vue';
import { tipoJobRelatorio } from '~/constants/tipoJob';
import tipoJobStatus from '~/constants/tipoJobStatus';
import { getGoogleDriveUrl, mergeWithArrayConcat } from '~/utils';

const props = defineProps({
  responsaveisCodigos: {
    default: () => null,
    type: Array,
  },
});

const disabledFilters = ref(['tiposImportacoes']);

const graphqlVariables = ref({
  order: [{ dataCriacao: 'DESC' }],
  where: {
    and: [
      {
        tipo: {
          in: values(tipoJobRelatorio),
        },
      },
    ],
  },
});

if (props.responsaveisCodigos?.length > 0)
{
  disabledFilters.value.push('geradoPor');
  graphqlVariables.value = mergeWithArrayConcat(graphqlVariables.value, {
    where: {
      and: [
        {
          geradoPorCodigo: {
            in: props.responsaveisCodigos,
          },
        },
      ],
    },
  });
}

const graphqlDatatableRef = ref(null);

defineExpose({
  getDataSource: () =>
  {
    return graphqlDatatableRef.value.getDataSource();
  },
  reloadAsync: () =>
  {
    graphqlDatatableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <JobGraphqlDatatable
    ref="graphqlDatatableRef"
    :graphql-variables="graphqlVariables"
    :disabled-filters="disabledFilters"
    :reload-interval="10000"
  >
    <template #leftTopActions>
      <slot name="leftTopActions" />
    </template>
    <template #tipo="{ record }">
      <a
        v-if="
          record.status === tipoJobStatus.Sucesso
            && record.resultado?.fileServiceId
        "
        target="_blank"
        :href="getGoogleDriveUrl(record.resultado.fileServiceId)"
        style="text-transform: uppercase"
      >{{ record.tipoText }}</a>
      <span
        v-else
        style="text-transform: uppercase"
      >{{
        record.tipoText
      }}</span>
    </template>
  </JobGraphqlDatatable>
</template>
