<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { GraphqlDatatableColumn } from './GqlDatatable.vue';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty } from '~/utils';
import referenciaGcpjAndamentoSelect from '~/graphql/referenciaGcpjAndamentoSelect.graphql';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const submitStatus = ref<any>(null);
const mostrarHistoricoSolicitacoes = ref<any>('h');
const uploadFilesGcpj = ref<any>(null);

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const authStore = useAuthStore();

async function preSubmit(model: any)
{
  const uploadResult = await uploadFilesGcpj.value?.upload();

  if (!uploadResult.ok)
  {
    return false;
  }

  model.arquivos = uploadResult.value;

  return true;
}

async function postSubmit()
{
  submitStatus.value = 'success';
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <div v-if="submitStatus !== 'success'">
      <a-collapse
        v-if="props.pastaCodigos?.length === 1"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel
          key="h"
          header="HISTÓRICO DE SOLICITAÇÕES"
        >
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 111,
                },
                fluxo: {
                  pastaCodigo: {
                    in: props.pastaCodigos,
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>
    </div>

    <div style="margin-top: 16px" />

    <CompromissoCommandForm2
      command="AcordoGcpj.IncluirAcordoGcpjCommand"
      :pre-submit="preSubmit"
      :post-submit="postSubmit"
      :show-upload-files="false"
      :submit-button-disabled="!isNilOrEmpty(compromisso)
        && compromisso.responsavel.codigo !== authStore.codigoUsuario"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
        compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
        arquivosForm: [],
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Referência"
          name="referenciaGcpj"
          :rules="[
            {
              message: 'Referência é obrigatório',
              required: true,
              trigger: 'change',
              type: 'object',
            },
          ]"
        >
          <GqlSelect
            v-model:value="model.referenciaGcpj"
            :query="referenciaGcpjAndamentoSelect"
            placeholder="Seleciona a referência do GCPJ"
            :variables="{
              where: {
                codigo: {
                  in: [
                    '08dc91f9-12aa-effb-70a8-d3d0e0a50000',
                    '08dc91f9-137e-b829-70a8-d3d0e0a50000',
                    '08dc91f9-1381-2997-70a8-d3d0e0a50000',
                    '08dc91f9-1390-61ac-70a8-d3d0e0a50000',
                    '08dc91f9-138f-e733-70a8-d3d0e0a50000',
                  ],
                },
              },
            }"
          />
        </a-form-item>

        <a-form-item
          label="Andamento"
          name="andamento"
          style="padding-bottom: 0"
          :rules="[{ message: 'Andamento é obrigatório', required: true }]"
        >
          <a-textarea
            v-model:value="model.andamento"
            :rows="4"
          />
        </a-form-item>

        <UploadFilesGcpj
          ref="uploadFilesGcpj"
          v-model="model.arquivosForm"
        />

        <div style="margin-top: 24px" />
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
