<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useGraphQL } from '~/composables/useGraphQL';
import {
  deepIntParaTextoComDuasOpcoes,
  deepIntParaTextoComQuatroOpcoes,
  deepIntParaTextoComTresOpcoes,
  deepRegiao,
  isNilOrEmpty,
} from '~/utils';

const props = defineProps({
  response: {
    default: null,
    type: Object,
  },
});
const route = useRoute();
const pastaCodigo = route.params.codigo;

const {
  data: pastaQueryData,
  runAsync: pastaQueryAsync,
  status: pastaQueryStatus,
} = useGraphQL({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'PASTA_DETALHES',
});

const collapsesAtivos = ref([
  'dadosPasta',
  'clientePrincipal',
  'dadosProcessoJudicial',
  'dadosAjuizamento',
  'andamentoProcessual',
  'andamentoApreensao',
  'dadosVeiculo',
  'dadosAcordo',
  'dadosCrmDeepCenter',
]);

onMounted(async () =>
{
  await pastaQueryAsync({ codigo: pastaCodigo });
});
</script>

<template>
  <a-row>
    <a-col :span="24">
      <div style="padding: 16px 0">
        <a-row style="padding-left: 24px">
          <a-col><h2>Detalhes</h2></a-col>
        </a-row>
        <a-divider style="margin-top: 16px" />
        <div
          v-if="pastaQueryStatus === 'loading'"
          style="padding: 0 24px"
        >
          <a-skeleton
            :loading="true"
            :active="true"
            :paragraph="{ rows: 12 }"
          />
        </div>
        <div
          v-if="pastaQueryStatus === 'success'"
          style="padding: 0 24px"
        >
          <a-collapse v-model:activeKey="collapsesAtivos">
            <a-collapse-panel
              key="dadosPasta"
              header="DADOS DA PASTA"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Nome">
                  {{ pastaQueryData.pasta.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Pasta principal">
                  {{ pastaQueryData.pasta.pastaPrincipal?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Tipo">
                  {{ pastaQueryData.pasta.tipoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Tipo processo">
                  {{ pastaQueryData.pasta.tipoProcessoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Status">
                  {{ pastaQueryData.pasta.statusText }}
                </a-descriptions-item>
                <a-descriptions-item label="Empresa">
                  {{ pastaQueryData.pasta.empresaText }}
                </a-descriptions-item>
                <a-descriptions-item label="Responsável processual">
                  <a-tooltip
                    v-if="
                      !isNilOrEmpty(pastaQueryData.pasta.responsavelProcessual)
                    "
                  >
                    <template #title>
                      Ir para página do responsável processual
                    </template>
                    <a
                      :href="`/usuario/${pastaQueryData.pasta.responsavelProcessual.Codigo}`"
                    >{{ pastaQueryData.pasta.responsavelProcessual.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>

                <a-descriptions-item label="Responsável negocial">
                  <a-tooltip
                    v-if="
                      !isNilOrEmpty(pastaQueryData.pasta.responsavelNegocial)
                    "
                  >
                    <template #title>
                      Ir para página do responsável negocial
                    </template>
                    <a
                      :href="`/usuario/${pastaQueryData.pasta.responsavelNegocial.codigo}`"
                    >{{ pastaQueryData.pasta.responsavelNegocial.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>
                <a-descriptions-item label="Gerente negocial responsável">
                  <a-tooltip
                    v-if="
                      !isNilOrEmpty(
                        pastaQueryData.pasta.gerenteNegocialResponsavel,
                      )
                    "
                  >
                    <template #title>
                      Ir para página do gerente negocial responsável
                    </template>
                    <a
                      :href="`/usuario/${pastaQueryData.pasta.gerenteNegocialResponsavel.codigo}`"
                    >{{
                      pastaQueryData.pasta.gerenteNegocialResponsavel.nome
                    }}</a>
                  </a-tooltip>
                </a-descriptions-item>
                <a-descriptions-item label="Valor envolvido">
                  {{ formatDinheiro(pastaQueryData.pasta.valorEnvolvido) }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor estoque">
                  {{ formatDinheiro(pastaQueryData.pasta.valorEstoque) }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de remessa">
                  {{ pastaQueryData.pasta.dataRemessaText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data atualização">
                  {{ pastaQueryData.pasta.dataAtualizacaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data cadastro">
                  {{ pastaQueryData.pasta.dataCriacaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data cadastro no GCPJ">
                  {{ pastaQueryData.pasta.dataCadastroGcpjText }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="clientePrincipal"
              header="CLIENTE PRINCIPAL"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Cliente principal">
                  <a-tooltip v-if="pastaQueryData.pasta.clientePrincipal">
                    <template #title>
                      Ir para página do cliente principal
                    </template>
                    <a
                      :href="`/contato/${pastaQueryData.pasta.clientePrincipal.codigo}`"
                    >{{ pastaQueryData.pasta.clientePrincipal.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>

                <a-descriptions-item label="Papel do cliente principal">
                  {{ pastaQueryData.pasta.clientePrincipalPapelText }}
                </a-descriptions-item>

                <a-descriptions-item label="Contrário principal">
                  <a-tooltip v-if="pastaQueryData.pasta.contrarioPrincipal">
                    <template #title>
                      Ir para página do contrário principal
                    </template>
                    <a
                      :href="`/contato/${pastaQueryData.pasta.contrarioPrincipal.codigo}`"
                    >{{ pastaQueryData.pasta.contrarioPrincipal.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>

                <a-descriptions-item label="Papel do contrário principal">
                  {{ pastaQueryData.pasta.contrarioPrincipalPapelText }}
                </a-descriptions-item>

                <a-descriptions-item label="GCPJ">
                  {{ pastaQueryData.pasta.gcpj }}
                </a-descriptions-item>
                <a-descriptions-item label="Status do GCPJ">
                  <span v-if="!isNilOrEmpty(pastaQueryData.pasta.gcpjAtivo)">
                    {{ pastaQueryData.pasta.gcpjAtivo ? 'ATIVO' : 'ENCERRADO' }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Departamento jurídico">
                  {{ pastaQueryData.pasta.dejur }}
                </a-descriptions-item>
                <a-descriptions-item label="Empresa/Banco">
                  {{ pastaQueryData.pasta.empresaBanco }}
                </a-descriptions-item>
                <a-descriptions-item label="Agência">
                  <span v-if="pastaQueryData.pasta.agencia2">
                    {{ pastaQueryData.pasta.agencia2.label }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Conta">
                  {{ pastaQueryData.pasta.conta }}
                </a-descriptions-item>
                <a-descriptions-item label="Conta fictícia">
                  {{ pastaQueryData.pasta.contaFicticia }}
                </a-descriptions-item>
                <a-descriptions-item label="Cidade">
                  {{ pastaQueryData.pasta.cidade?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Estado">
                  {{ pastaQueryData.pasta.estado?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Data fato gerador">
                  {{ pastaQueryData.pasta.dataFatoGeradorText }}
                </a-descriptions-item>
                <a-descriptions-item label="Garantias">
                  {{ pastaQueryData.pasta.garantias }}
                </a-descriptions-item>
                <a-descriptions-item label="Avalistas">
                  {{
                    pastaQueryData.pasta.avalistas
                      ?.map((x) => x.nome)
                      .join(', ')
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Advogado do banco">
                  {{ pastaQueryData.pasta.advogadoBanco }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor do contrato">
                  {{ formatDinheiro(pastaQueryData.pasta.valorContrato) }}
                </a-descriptions-item>
                <a-descriptions-item label="Informações sobre óbito">
                  {{ pastaQueryData.pasta.informacaoSobreObito }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Data de declaração de irrecuperabilidade"
                >
                  {{
                    pastaQueryData.pasta.dataDeclaracaoIrrecuperabilidadeText
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de encerramento">
                  {{ pastaQueryData.pasta.dataEncerramentoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Motivo encerramento">
                  {{ pastaQueryData.pasta.motivoEncerramento }}
                </a-descriptions-item>
                <a-descriptions-item label="Depositário fiel">
                  <a-tooltip v-if="pastaQueryData.pasta.depositarioFiel">
                    <template #title>
                      Ir para página do depositário fiel
                    </template>
                    <a
                      :href="`/contato/${pastaQueryData.pasta.depositarioFiel.codigo}`"
                    >{{ pastaQueryData.pasta.depositarioFiel.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>
                <a-descriptions-item label="Tela de operação">
                  {{ pastaQueryData.pasta.telaOperacao }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de celebração do contrato original">
                  {{ pastaQueryData.pasta.dataCelebracaoContratoOriginalText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data do vencimento final da parcela do contrato original">
                  {{ pastaQueryData.pasta.dataVencimentoFinalParcelaContratoOriginalText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data da primeira parcela vencida">
                  {{ pastaQueryData.pasta.dataPrimeiraParcelaVencidaText }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosProcessoJudicial"
              header="DADOS DO PROCESSO JUDICIAL"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Número CNJ">
                  {{ formatCnj(pastaQueryData.pasta.numeroCnj) }}
                </a-descriptions-item>
                <a-descriptions-item label="Número antigo">
                  {{ pastaQueryData.pasta.numeroAntigo }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor da causa">
                  {{ formatDinheiro(pastaQueryData.pasta.valorCausa) }}
                </a-descriptions-item>
                <a-descriptions-item label="Natureza">
                  {{ pastaQueryData.pasta.naturezaText }}
                </a-descriptions-item>
                <a-descriptions-item label="Procedimento">
                  {{ pastaQueryData.pasta.procedimentoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Ação">
                  {{ pastaQueryData.pasta.acao?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Órgão">
                  {{ pastaQueryData.pasta.orgao?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Justiça">
                  {{ pastaQueryData.pasta.justica?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Instância">
                  {{ pastaQueryData.pasta.instancia?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Classe">
                  {{ pastaQueryData.pasta.classe?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Assunto">
                  {{ pastaQueryData.pasta.assunto?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Vara">
                  {{ pastaQueryData.pasta.vara }}
                </a-descriptions-item>
                <a-descriptions-item label="Comarca">
                  {{ pastaQueryData.pasta.comarca?.nome }}
                </a-descriptions-item>
                <a-descriptions-item label="Atualização do valor da causa">
                  {{
                    formatDinheiro(pastaQueryData.pasta.atualizacaoValorCausa)
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Data atualização valor da causa">
                  {{ pastaQueryData.pasta.dataAtualizacaoValorCausaText }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosAjuizamento"
              header="DADOS DO AJUIZAMENTO"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Motivo do não ajuizamento">
                  {{ pastaQueryData.pasta.motivoNaoAjuizamentoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de ajuizamento">
                  {{ pastaQueryData.pasta.dataDistribuicaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Custas protesto">
                  {{ formatDinheiro(pastaQueryData.pasta.custasProtesto) }}
                </a-descriptions-item>
                <a-descriptions-item label="Custas iniciais">
                  {{ formatDinheiro(pastaQueryData.pasta.custasIniciais) }}
                </a-descriptions-item>
                <a-descriptions-item label="Custas oficial de justiça">
                  {{
                    formatDinheiro(pastaQueryData.pasta.custasOficialJustica)
                  }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="andamentoProcessual"
              header="ANDAMENTO PROCESSUAL"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Data de habilitação">
                  {{ pastaQueryData.pasta.dataHabilitacaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Procuração Fls">
                  {{ pastaQueryData.pasta.procuracaoFls }}
                </a-descriptions-item>
                <a-descriptions-item label="Arquivamento provisório">
                  {{
                    pastaQueryData.pasta
                      .arquivamentoProvisorioOuSuspensaoPorAusenciaDeBensPenhoraveis
                  }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Data suspensão por ausência de bens penhoráveis "
                >
                  {{
                    pastaQueryData.pasta
                      .dataSuspensaoAusenciaBensPenhoraveisText
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de liminar">
                  {{ pastaQueryData.pasta.dataLiminarText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de citação">
                  {{ pastaQueryData.pasta.dataCitacaoText }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Data solicitação conversão em execução"
                >
                  {{ pastaQueryData.pasta.dataSolicitacaoConversaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data conversão em execução">
                  {{ pastaQueryData.pasta.dataConversaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de sentença">
                  {{ pastaQueryData.pasta.dataSentencaText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de baixa">
                  {{ pastaQueryData.pasta.dataBaixaText }}
                </a-descriptions-item>
                <a-descriptions-item label="Data resultado">
                  {{ pastaQueryData.pasta.dataResultadoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Motivo resultado">
                  {{ pastaQueryData.pasta.motivoResultado }}
                </a-descriptions-item>
                <a-descriptions-item label="Resultado">
                  {{ pastaQueryData.pasta.resultado }}
                </a-descriptions-item>
                <a-descriptions-item label="Revelia">
                  {{ pastaQueryData.pasta.revelia ? 'SIM' : 'NÃO' }}
                </a-descriptions-item>
                <a-descriptions-item label="Fundamento da sentença">
                  {{ pastaQueryData.pasta.fundamentoSentenca }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="andamentoApreensao"
              header="ANDAMENTO DA APREENSÃO"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Data de apreensão">
                  {{ pastaQueryData.pasta.dataApreensaoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Apto para apreensão?">
                  <span
                    v-if="
                      !isNilOrEmpty(pastaQueryData.pasta.ehAptoParaApreensao)
                    "
                  >
                    {{
                      pastaQueryData.pasta.ehAptoParaApreensao ? 'Sim' : 'Não'
                    }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Inviável?">
                  <span v-if="!isNilOrEmpty(pastaQueryData.pasta.ehInviavel)">
                    {{ pastaQueryData.pasta.ehInviavel ? 'Sim' : 'Não' }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Apreensão veículo">
                  {{
                    deepIntParaTextoComTresOpcoes(
                      pastaQueryData.pasta.apreensaoVeiculo,
                    )
                  }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosVeiculo"
              header="DADOS DO VEÍCULO"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Placa">
                  {{ pastaQueryData.pasta.placa }}
                </a-descriptions-item>

                <a-descriptions-item label="Chassi/Renavan">
                  {{ pastaQueryData.pasta.chassiRenavan }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosAcordo"
              header="DADOS DO ACORDO"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Teve acordo?">
                  <span v-if="!isNilOrEmpty(pastaQueryData.pasta.teveAcordo)">
                    {{ pastaQueryData.pasta.teveAcordo ? 'Sim' : 'Não' }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Responsável pelo acordo/apreensão">
                  <a-tooltip v-if="pastaQueryData.pasta.responsavelAcordo">
                    <template #title>
                      Ir para página do responsável pelo acordo/apreensão
                    </template>
                    <a
                      :href="`/usuario/${pastaQueryData.pasta.responsavelAcordo.codigo}`"
                    >{{ pastaQueryData.pasta.responsavelAcordo.nome }}</a>
                  </a-tooltip>
                </a-descriptions-item>
                <a-descriptions-item label="Tipo de acordo">
                  {{ pastaQueryData.pasta.tipoAcordo }}
                </a-descriptions-item>
                <a-descriptions-item label="Data de acordo">
                  {{ pastaQueryData.pasta.dataAcordoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Quantidade de parcelas">
                  {{ pastaQueryData.pasta.quantidadeParcelas }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor total do acordo">
                  {{ formatDinheiro(pastaQueryData.pasta.valorTotalAcordo) }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor entrada do acordo">
                  {{ formatDinheiro(pastaQueryData.pasta.valorEntradaAcordo) }}
                </a-descriptions-item>
                <a-descriptions-item label="Valor financiado do acordo">
                  {{
                    formatDinheiro(pastaQueryData.pasta.valorFinanciadoAcordo)
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Taxa de juros do acordo (A.M.)">
                  {{
                    !isNilOrEmpty(pastaQueryData.pasta.taxaJurosAcordo)
                      ? `${pastaQueryData.pasta.taxaJurosAcordo}%`
                      : ''
                  }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Data vencimento primeira parcela acordo"
                >
                  {{
                    pastaQueryData.pasta.dataVencimentoPrimeiraParcelaAcordoText
                  }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Data vencimento última parcela acordo"
                >
                  {{
                    pastaQueryData.pasta.dataVencimentoUltimaParcelaAcordoText
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Acordo está sendo cumprido?">
                  <span
                    v-if="
                      !isNilOrEmpty(
                        pastaQueryData.pasta.acordoEstaSendoCumprido,
                      )
                    "
                  >
                    {{
                      pastaQueryData.pasta.acordoEstaSendoCumprido
                        ? 'Sim'
                        : 'Não'
                    }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Data descumprimento acordo">
                  {{ pastaQueryData.pasta.dataDescumprimentoAcordoText }}
                </a-descriptions-item>
                <a-descriptions-item label="Histórico de descumprimento">
                  {{ pastaQueryData.pasta.historicoDescumprimento }}
                </a-descriptions-item>
                <a-descriptions-item label="Teve contabilização do acordo?">
                  <span
                    v-if="
                      !isNilOrEmpty(
                        pastaQueryData.pasta.teveContabilizacaoAcordo,
                      )
                    "
                  >
                    {{
                      pastaQueryData.pasta.teveContabilizacaoAcordo
                        ? 'Sim'
                        : 'Não'
                    }}
                  </span>
                </a-descriptions-item>
                <a-descriptions-item label="Volumetria">
                  {{ pastaQueryData.pasta.volumetria }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosCrmDeepCenter"
              header="DADOS CRM DEEPCENTER"
            >
              <a-descriptions
                :column="1"
                bordered
                :label-style="{ width: '20%' }"
              >
                <a-descriptions-item label="Plano">
                  {{ pastaQueryData.pasta.plano }}
                </a-descriptions-item>
                <a-descriptions-item
                  label="Valor da parcela do contrato inicial"
                >
                  {{
                    formatDinheiro(
                      pastaQueryData.pasta.valorParcelaContratoOriginal,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="Data vencimento inicial">
                  {{ pastaQueryData.pasta.dataVencimentoInicialText }}
                </a-descriptions-item>
                <a-descriptions-item label="UF">
                  {{ pastaQueryData.pasta.estado?.sigla }}
                </a-descriptions-item>
                <a-descriptions-item label="AJUIZADO">
                  {{
                    deepIntParaTextoComDuasOpcoes(
                      pastaQueryData.pasta.ehAjuizado ? 1 : 0,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="ID_MOTIVO_NAO_AJUIZAMENTO">
                  {{ pastaQueryData.pasta.ehAjuizado !== null ? 2 : 1 }}
                </a-descriptions-item>
                <a-descriptions-item label="MOTIVO_NAO_AJUIZAMENTO">
                  {{ pastaQueryData.pasta.motivoNaoAjuizamentoParaDeep }}
                </a-descriptions-item>
                <a-descriptions-item label="REGIAO">
                  {{ deepRegiao(pastaQueryData.pasta.regiaoDeep) }}
                </a-descriptions-item>
                <a-descriptions-item label="EMENDA">
                  {{
                    deepIntParaTextoComDuasOpcoes(
                      pastaQueryData.pasta.emendaDeep,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="MOTIVO_EMENDA">
                  {{ pastaQueryData.pasta.motivoEmenda }}
                </a-descriptions-item>
                <a-descriptions-item label="LIMINAR_DEFERIDA">
                  {{
                    deepIntParaTextoComQuatroOpcoes(
                      pastaQueryData.pasta.liminarDeferida,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="DATA_RESPOSTA_LIMINAR">
                  {{ pastaQueryData.pasta.dataRespostaLiminarText }}
                </a-descriptions-item>
                <a-descriptions-item label="MOTIVO_NAO_DEFERIMENTO_LIMINAR">
                  {{ pastaQueryData.pasta.motivoNaoDeferimentoLiminar }}
                </a-descriptions-item>
                <a-descriptions-item label="AGRAVO_INSTRUMENTO">
                  {{
                    deepIntParaTextoComTresOpcoes(
                      pastaQueryData.pasta.agravoInstrumento,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="MANDADO_DE_APREENSAO_EXPEDIDO">
                  {{
                    deepIntParaTextoComQuatroOpcoes(
                      pastaQueryData.pasta.mandadoApreensaoExpedido,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="DATA_DA_EXPEDICAO">
                  {{ pastaQueryData.pasta.dataMandadoApreensaoExpedidoText }}
                </a-descriptions-item>
                <a-descriptions-item label="APREENSAO_VEICULO">
                  {{
                    deepIntParaTextoComTresOpcoes(
                      pastaQueryData.pasta.apreensaoVeiculo,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="LIBERACAO_VENDA">
                  {{
                    deepIntParaTextoComTresOpcoes(
                      pastaQueryData.pasta.liberacaoVendaVeiculo,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="DATA_LIBERACAO">
                  {{ pastaQueryData.pasta.dataLiberacaoVendaVeiculoText }}
                </a-descriptions-item>
                <a-descriptions-item label="ACAO_MANDADO_NEGATIVO">
                  {{ pastaQueryData.pasta.acaoMandadoNegativo }}
                </a-descriptions-item>
                <a-descriptions-item label="BEM_LOCALIZADO">
                  {{
                    deepIntParaTextoComDuasOpcoes(
                      pastaQueryData.pasta.bemLocalizado,
                    )
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="ACAO_NAO_LOCALIZADO">
                  {{ pastaQueryData.pasta.acaoBemNaoLocalizado }}
                </a-descriptions-item>
                <a-descriptions-item label="SOLICITACAO_CONVERSAO_EXECUCAO">
                  {{
                    deepIntParaTextoComTresOpcoes(
                      pastaQueryData.pasta.solicitacaoConversaoExecucao ?? 0,
                    )
                  }}
                </a-descriptions-item>

                <a-descriptions-item label="ULTIMO_EVENTO">
                  {{ pastaQueryData.pasta.ultimoEvento }}
                </a-descriptions-item>
                <a-descriptions-item label="DEPOSITO">
                  {{
                    deepIntParaTextoComTresOpcoes(pastaQueryData.pasta.deposito)
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="VALOR_DEPOSITO">
                  {{ pastaQueryData.pasta.valorDeposito }}
                </a-descriptions-item>
                <a-descriptions-item label="VALOR_LEVANTADO_PELO_BANCO">
                  {{ pastaQueryData.pasta.valorLevantadoPeloBanco }}
                </a-descriptions-item>
                <a-descriptions-item label="VALOR_LEVANTADO_PELA_PARTE">
                  {{ pastaQueryData.pasta.valorLevantadoPelaParte }}
                </a-descriptions-item>
                <a-descriptions-item label="PENHORA">
                  {{
                    deepIntParaTextoComTresOpcoes(pastaQueryData.pasta.penhora)
                  }}
                </a-descriptions-item>
                <a-descriptions-item label="TIPO_PENHORA">
                  {{ pastaQueryData.pasta.tipoPenhora }}
                </a-descriptions-item>
                <a-descriptions-item label="VALOR_PENHORA">
                  {{ pastaQueryData.pasta.valorPenhora }}
                </a-descriptions-item>
                <a-descriptions-item label="SUBSTABELECIDO">
                  {{
                    deepIntParaTextoComDuasOpcoes(
                      pastaQueryData.pasta.substabelecido ?? 0,
                    )
                  }}
                </a-descriptions-item>
              </a-descriptions>
            </a-collapse-panel>
          </a-collapse>
          <a-descriptions
            :column="1"
            bordered
            :label-style="{ width: '20%' }"
          />
        </div>
      </div>
    </a-col>
  </a-row>
</template>
