import { ref } from 'vue';
import { useLazyQuery } from '@vue/apollo-composable';
import { usePost } from '~/composables/usePost';
import { API_URL } from '~/utils';
import jobQuery from '~/graphql/job.graphql';
import type { TipoJobEnum } from '~/enums/tipoJobEnum';

export function useJobCommand(opts?: any)
{
  const url = opts?.path
    ? `${API_URL}/${opts.path}`
    : `${API_URL}/job-command`;

  const { runAsync: executePost, status: jobCommandStatus, data: jobCommandData } = usePost({
    url,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });

  const { load, start, stop, onResult, onError } = useLazyQuery(jobQuery, {}, { pollInterval: opts?.interval || 2000 });

  const poolingStatus = ref<any>(null);
  const poolingProgressData = ref<any>(null);
  const poolingData = ref<any>(null);
  const poolingError = ref<any>(null);

  onResult((queryResult) =>
  {
    if (!queryResult.loading && queryResult.data.model.status === 1)
    {
      poolingProgressData.value = queryResult.data.model;
    }

    if (!queryResult.loading && queryResult.data.model.status === 2)
    {
      poolingProgressData.value = queryResult.data.model;
      stop();
      poolingStatus.value = 'success';
      poolingData.value = queryResult.data.model;
    }

    if (!queryResult.loading && queryResult.data.model.status === 3)
    {
      poolingProgressData.value = queryResult.data.model;
      stop();
      poolingStatus.value = 'error';
      poolingData.value = queryResult.data.model;
      poolingError.value = queryResult.data.model;
    }
  });

  onError(() =>
  {
    poolingStatus.value = 'error';
    poolingData.value = null;
    poolingError.value = null;
  });

  const jobCommand = async (commandType: string, commandData: any, jobType: TipoJobEnum) =>
  {
    poolingStatus.value = 'loading';
    poolingData.value = null;

    await executePost({ commandType, commandData, jobType });

    if (jobCommandStatus.value === 'success')
    {
      await load(null, {
        where: {
          codigo: {
            eq: jobCommandData.value.jobId,
          },
        },
      });

      start();
    }

    if (jobCommandStatus.value === 'error')
    {
      poolingStatus.value = 'error';
    }
  };

  const cancel = () =>
  {
    stop();
  };

  return {
    cancel,
    data: poolingData,
    progressData: poolingProgressData,
    jobCommand,
    status: poolingStatus,
    err: poolingError,
  };
}
