import { defineStore } from 'pinia';
import axios from 'axios';
import { API_URL, error } from '~/utils';

async function esqueciSenha(cmd) {
  const response = await axios.post(
    `${API_URL}/autenticacao/esqueci-senha`,
    cmd,
    {
      withCredentials: true,
      timeout: 120000,
    },
  );
  return response.data;
}

export const useStore = defineStore('esqueci-senha', {
  state: () => ({
    status: null,
    error: null,
    email: null,
  }),
  actions: {
    async esqueciSenha() {
      this.status = 'loading';
      try {
        await esqueciSenha({
          email: this.email,
        });
        this.status = 'success';
      }
      catch (e) {
        this.error = error(e).error;
        this.status = 'error';
      }
    },
  },
});
