import { useGet } from '../useGet';
import { API_URL } from '~/utils';

export function useObterTotalSolicitacoesCdiPorTipo() {
  return useGet({
    url: `${API_URL}/compromissos/obter-total-solicitacoes-cdi-por-tipo`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
