<script setup>
import dayjs from 'dayjs';
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['loaded']);
const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <a-descriptions
    :column="3"
    :label-style="{ width: '15%' }"
    bordered
  >
    <a-descriptions-item
      label="Tipo"
      :span="3"
    >
      {{ props.compromisso.data.tipoCustasText }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Valor"
      :span="3"
    >
      {{ formatDinheiro(props.compromisso.data.valor) }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Prazo"
      :span="3"
    >
      {{
        dayjs
          .utc(props.compromisso.data.dataPrazo)
          .tz(authStore.timeZone)
          .format('DD/MM/YYYY')
      }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Observação"
      :span="3"
    >
      {{ props.compromisso.data?.observacao }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Arquivos"
      :span="3"
    >
      <span v-if="props.compromisso.arquivos.length === 0">Nenhum arquivo enviado</span>
      <ul v-else>
        <li
          v-for="arquivo in props.compromisso.arquivos"
          :key="arquivo.fileServiceId"
        >
          <a
            target="_blank"
            :href="getGoogleDriveUrl(arquivo.fileServiceId)"
          >{{
            arquivo.nome
          }}</a>
        </li>
      </ul>
    </a-descriptions-item>
  </a-descriptions>
</template>
