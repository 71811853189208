<script setup>
import { usePost } from '~/composables/usePost';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { reactive, ref } from 'vue';

const props = defineProps({
  publicacaoNumeroProcessoCodigos: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: solicitarVerificacaoPublicacaoCapturadaErr,
  runAsync: solicitarVerificacaoPublicacaoCapturadaAsync,
  status: solicitarVerificacaoPublicacaoCapturadaStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/fluxo/verificacao-publicacao-capturada/solicitar-verificacao-publicacao-capturada`,
});

const state = reactive({
  arquivos: [],
});

const formRef = ref(null);
const formRules = reactive({});
const uploaderRef = ref(null);
const uploaderStatus = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    await uploaderRef.value.upload();

    await solicitarVerificacaoPublicacaoCapturadaAsync({
      arquivos: state.arquivos,
      publicacaoNumeroProcessoCodigos: props.publicacaoNumeroProcessoCodigos,
    });

    if (solicitarVerificacaoPublicacaoCapturadaStatus.value === 'error') {
      notification.error({
        duration: 0,
        message: solicitarVerificacaoPublicacaoCapturadaErr.value.message,
      });
    }
  });
};
</script>

<template>
  <a-result
    v-if="solicitarVerificacaoPublicacaoCapturadaStatus === 'success'"
    status="success"
    sub-title="Agora você pode fechar essa janela"
    title="Registro realizado com sucesso"
  >
    <template #extra>
      <a-button @click="() => emit('close')"> Fechar </a-button>
    </template>
  </a-result>

  <div v-if="solicitarVerificacaoPublicacaoCapturadaStatus !== 'success'">
    <a-alert
      message="Você irá realizar uma alteração em lote"
      show-icon
      style="margin-bottom: 24px"
      type="warning"
    ></a-alert>

    <a-form
      ref="formRef"
      :model="state"
      :rules="formRules"
      layout="vertical"
      style="margin-top: 16px"
    >
      <h3 style="margin-bottom: 24px">Anexar arquivos</h3>

      <a-row>
        <a-col :span="24">
          <Uploader
            ref="uploaderRef"
            v-model:fileList="state.arquivos"
            
            @end="() => (uploaderStatus = 'done')"
            @start="() => (uploaderStatus = 'uploading')"
          />
        </a-col>
      </a-row>

      <a-form-item style="margin-top: 24px; margin-bottom: 0px">
        <a-button
          :loading="
            uploaderStatus === 'uploading' ||
            solicitarVerificacaoPublicacaoCapturadaStatus === 'loading'
          "
          style="margin-right: 8px; min-width: 100px"
          type="primary"
          @click.prevent="uiSalvarAsync"
        >
          Salvar
        </a-button>
        <a-button @click="() => emit('close')"> Fechar </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
