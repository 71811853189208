<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { isNil } from 'lodash-es';
import { onMounted, reactive, ref, toRaw, watch } from 'vue';
import { API_URL } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import { usePost } from '~/composables/usePost';
import tipoPerfil from '~/constants/tipoPerfil';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: solicitarServicoCdiErr,
  runAsync: solicitarServicoCdiAsync,
  status: solicitarServicoCdiStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/cdi/solicitar-servico-cdi`,
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  descricao: null,
  gerente: null,
  servico: null,
});
const formRules = reactive({
  descricao: [
    {
      message: 'Descrição é obrigatória',
      required: true,
    },
  ],
  servico: [
    {
      message: 'Serviço é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();

    const rawFormModel = {
      ...toRaw(formModel),
      ...{
        gerenteCodigo: formModel.gerente?.value,
        servico: formModel.servico.value,
      },
    };

    await solicitarServicoCdiAsync({
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      pastaCodigos: props.codigoPasta !== null ? [props.codigoPasta] : null,
      ...rawFormModel,
    });

    if (solicitarServicoCdiStatus.value === 'error')
    {
      notification.error({
        description: solicitarServicoCdiErr.value.description,
        duration: 5,
        message: solicitarServicoCdiErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
}

watch(
  () => formModel.servico,
  (val) =>
  {
    if (!authStore.temPerfilAcesso(tipoPerfil.NaoPrecisaAprovacaoSolicitacoesAoCdi) && (val.value === 4 || val.value === 3))
    {
      formRules.gerente = [
        {
          message: 'Gerente é obrigatório',
          required: true,
          trigger: 'change',
          type: 'object',
        },
      ];
    }
    else
    {
      formRules.gerente = null;
    }
  },
);

onMounted(async () =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-result
      v-if="solicitarServicoCdiStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="solicitarServicoCdiStatus !== 'success'">
      <a-collapse
        v-if="props.codigoPasta !== null"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel
          key="h"
          header="HISTÓRICO DE SOLICITAÇÕES"
        >
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 29,
                },
                fluxo: {
                  pastaCodigo: {
                    in: [props.codigoPasta],
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>
      <a-alert
        show-icon
        style="margin-top: 16px; margin-bottom: 16px"
      >
        <template #message>
          <a
            target="_blank"
            href="/MANUAL_CDI.pdf"
          >Clique aqui para baixar o manual do CDI</a>
        </template>
      </a-alert>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
      >
        <a-form-item
          label="Serviço"
          name="servico"
        >
          <AjaxSelect
            v-model:value="formModel.servico"
            :url="`${API_URL}/ajax-select/tipo-servico-cdi`"
            :value-is-number="true"
            placeholder="Selecione um serviço do CDI"
          />
        </a-form-item>

        <a-form-item
          v-if="
            !authStore.temPerfilAcesso(tipoPerfil.NaoPrecisaAprovacaoSolicitacoesAoCdi) && formModel.servico?.value === 4 || formModel.servico?.value === 3
          "
          name="gerente"
        >
          <template #label>
            <slot name="formItemLabel">
              Gerente
            </slot>
          </template>
          <AjaxSelect
            v-model:value="formModel.gerente"
            :url="`${API_URL}/ajax-select/gerentes-por-perfis`"
            placeholder="Selecione o gerente"
          />
        </a-form-item>

        <a-alert
          v-if="formModel.servico?.value === 9"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME DEVEDOR/CNPJ</b></li>
              <li><b>AVALISTA/CPF</b></li>
              <li>
                OBS: CONFORME DITO, ENVIE SOMENTE ESSES DADOS,
                <span style="color: red">NÃO ACEITAMOS</span>
                APENAS NUMERO DE GCPJ, OU SOMENTE NUMERO PROCESSUAL.
              </li>
              <li>
                APÓS CDI REALIZAR BUSCAS DE CONTATOS, SERÁ ENCAMINHADO AO CALL
                CENTER ONDE O MESMO IRÁ REALIZAR UM FILTRO DOS CONTATOS E
                ENCAMINHARÁ AO ADVOGADO SOMENTE O CONTATO POSITIVO (<b>INCLUINDO O NÚMERO QUE SOMENTE CHAMAR</b>).
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 1"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME DEVEDOR/CNPJ</b></li>
              <li><b>AVALISTA/CPF</b></li>
              <li><b>NUMERO GCPJ</b></li>
              <li>
                CONFORME RESULTADOS DE ENDEREÇOS DADOS EM NOSSOS SISTEMAS,
                ENCAMINHAMOS AO ADVOGADO OS ENDEREÇOS LOCALIZADOS, E SE
                NECESSÁRIO IDA DE NOSSO LOCALIZADOR A ALGUM ESPECÍFICO,POR
                GENTILEZA SOLICITAR.
              </li>
              <li style="color: red">
                SOLICITAÇÕES QUE POSSUEM <b>PRAZOS URGENTES</b>, POR FAVOR
                <b>DESCREVER NO CAMPO</b>
              </li>
              <li>
                QUANDO HÁ RETORNO DO RELATÓRIO DE LOCALIZAÇÃO, SENDO ELE
                POSITIVO OU NÃO, O MESMO É ENVIADO AO NESTE FLUXO.
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 2"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME DEVEDOR/CPF</b></li>
              <li>
                NESSE TIPO DE SOLICITAÇÃO ENVIAMOS AO ADVG, DADOS NECESSÁRIOS
                PARA OS MESMOS REALIZAREM PESQUISA SIEL.
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 3"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME DEVEDOR/CPF</b></li>
              <li><b>NUMERO GCPJ</b></li>
              <li><b>COMARCA DO PROCESSO</b></li>
              <li>
                <b>EM CASO POSITIVO</b>: APÓS CONFIRMAÇÃO DE FALECIMENTO,
                VERIFICAMOS DADOS PARA PESQUISA EM CARTÓRIO, E SOLICITAMOS CÓPIA
                DE TAL CERTIDÃO.(PRAZO DE RETORNO : 7 DIAS ÚTEIS* EM MANAUS,
                DEMAIS COMARCAS CONSULTAR PRAZO DE RETORNO CONOSCO.)
              </li>
              <li style="color: red">
                SOLICITAÇÕES QUE POSSUEM <b>PRAZOS URGENTES</b>, POR FAVOR
                <b>DESCREVER NO CAMPO</b>
              </li>
              <li>
                EM CASO NEGATIVO: QUANDO CONFIRMAMOS A MORTE MAS NÃO LOCALIZADOS
                DADOS DE CARTÓRIO, ENVIAMOS AO ADVOGADO CONTATO DE FAMILIARES
                PARA O MESMO SOLICITAR TAL DOCUMENTAÇÃO, HAVENDO CASOS DE NOSSO
                LOCALIZADOR IR ATÉ ENDEREÇO DO FAMILIAR E CONSEGUIR CÓPIA DE
                CERTIDÃO.
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 4"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME</b></li>
              <li><b>CPF OU CNPJ</b></li>
              <li><b>GCPJ</b></li>
              <li><b>NUMERO DO PROCESSO</b></li>
              <li><b>VALOR DA CAUSA</b></li>
              <li style="color: red">
                <b>QUANDO A PESQUISA DE IMÓVEL FOR EM OUTRA CIDADE POR GENTILEZA
                  INFORMAR NO CAMPO</b>.<br>
                SOLICITAÇÕES QUE POSSUEM <b>PRAZOS URGENTES</b>, POR FAVOR
                <b>DESCREVER NO CAMPO</b>
              </li>
              <li>
                A PESQUISA DE IMÓVEIS É REALIZADA EM TODOS OS CARTÓRIOS EM NOME
                DE DEVEDOR PRINCIPAL E AVALISTAS (QUANDO SOLICITADO). É
                NECESSÁRIO O PREENCHIMENTO COMPLETO DE TODOS OS DADOS, PARA
                PROSSEGUIMENTO DE TAL SOLICITAÇÃO.
              </li>
              <li>
                OBS: QUANDO HOUVER CASO DE SOLICITAÇÕES, URGENTE, SOLICITADA POR
                <b>JUIZ COM PRAZO</b>, OU POR SOLICITAÇÃO DE GERENTE BANCO PARA
                FECHAR ACORDO, <b>INFORMAR NO EMAIL</b> PARA DARMOS PRIORIDADE.
              </li>
              <li>PRAZO Comum: 15 DIAS*</li>
              <li>*PODE SE ESTENDER CASO IMPREVISTOS.</li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 5"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>NOME DEVEDOR /CPF/CNPJ</b></li>
              <li>
                A PESQUISA É FEITA PARA VERIFICAÇÃO DE POSSÍVEIS VEÍCULOS EM
                NOME DO DEVEDOR ,PARA ANDAMENTO PROCESSUAL;PENHORA, ENTRE OUTRAS
                FINALIDADES PROCESSUAIS .
              </li>
              <li style="color: red">
                SOLICITAÇÕES QUE POSSUEM PRAZOS URGENTES, POR FAVOR DESCREVER NO
                EMAIL
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 6"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>CIDADE/ESTADO</b></li>
              <li><b>NOME DEVEDOR/CNPJ</b></li>
              <li><b>AVALISTA/CPF</b></li>
              <li>
                DESCREVER QUAL FINALIDADE DO ADVG CORRESPONDENTE , PARA
                AUDIENCIA;PROTOCOLAR PROCESSO;AGILIZAR EXPEDIÇÃO DE MANDADO,
                ENTRE OUTROS.
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 7"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>CIDADE/ESTADO</b></li>
              <li>DESCREVER FINALIDADE DO CORRESPONDENTE NA DEVIDA COMARCA.</li>
              <li>
                LOCALIZAÇÃO VEÍCULO/DEVEDOR/IMÓVEL
                <ul>
                  <li>NOME DEVEDOR/CNPJ, AVALISTA/CPF</li>
                  <li>
                    CASO FOR LOCALIZAÇÃO DE VEÍCULO: INSERIR DADOS DO VEÍCULO,
                    PLACA, RENAVAM, CHASSI,MODELO, MARCA
                  </li>
                  <li>
                    LOCALIZAÇÃO. IMÓVEL : ENVIAR ENDEREÇO ,CASOS PARA PENHORA,
                    ONDE HÁ NECESSIDADE DE TIRAR FOTO , VERIFICAR ESTADO DO
                    IMÓVEL)
                  </li>
                </ul>
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 8"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li>DEVEDOR/CNPJ</li>
              <li>AVALISTA/CPF</li>
              <li>
                DESCREVER QUAL INFORMAÇÃO ESPECÍFICA DESEJA, INSERIR TODAS
                INFORMAÇÕES DO VEÍCULO QUE TIVER DISPONÍVEL NO CONTRATO
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 10"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>GCPJ</b></li>
              <li><b>NUMERO DO PROCESSO</b></li>
              <li><b>DATA DE ÓBITO (SE HOUVER)</b></li>
              <li><b>NOME DO DEVEDOR/CPF</b></li>
              <li>
                PESQUISAS DE INVENTÁRIO EXTRAJUDICIAL ,
                <b style="color: red">COM CERTIDÃO</b> SERÃO APENAS EMITIDAS SE
                CASO FOR SOLICITADO PELO BANCO,OU POR JUIZ, SE FOR APENAS PARA
                CARÁTER INFORMATIVO, A CONSULTA SERÁ REALIZADA POR LIGAÇÕES AOS
                CARTÓRIOS/EMAIL.
              </li>
              <li style="color: red">
                SOLICITAÇÕES QUE POSSUEM <b>PRAZOS URGENTES</b>, POR FAVOR
                <b>DESCREVER NO CAMPO</b>
              </li>
            </ul>
          </template>
        </a-alert>

        <a-alert
          v-if="formModel.servico?.value === 11"
          type="warning"
          style="margin-top: 8px; margin-bottom: 16px"
        >
          <template #message>
            <h4>DIGITAR NO CAMPO ABAIXO</h4>
            <ul>
              <li><b>GCPJ</b></li>
              <li><b>NUMERO DO PROCESSO</b></li>
              <li><b>DATA DE ÓBITO (SE HOUVER)</b></li>
              <li><b>NOME DO DEVEDOR/CPF</b></li>
              <li style="color: red">
                <b>QUANDO A PESQUISA FOR EM OUTRA CIDADE PFVR INFORMAR NO
                  CAMPO.</b>
              </li>
              <li>DESCREVER OBSERVAÇÕES SOBRE A SOLICITAÇÃO</li>
              <li>PRAZO: 10 DIAS*</li>
              <li>*CASO IMPREVISTOS PRAZO PODE SE ESTENDER.</li>
            </ul>
          </template>
        </a-alert>

        <a-form-item
          label="Descrição"
          name="descricao"
          style="padding-bottom: 0"
        >
          <a-textarea
            v-model:value="formModel.descricao"
            :rows="4"
          />
        </a-form-item>

        <Uploader
          ref="uploaderRef"
          v-model:fileList="formModel.arquivos"

          @start="() => (uploaderStatus = 'uploading')"
          @end="() => (uploaderStatus = 'done')"
        >
          <template #description>
            <p class="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p class="ant-upload-text">
              Clique aqui ou arraste os arquivos
            </p>
            <p class="ant-upload-hint">
              Envie os arquivos necessários
            </p>
          </template>
        </Uploader>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNil(props.compromisso)
                && props.compromisso.responsavel.codigo !== authStore.codigoUsuario
            "
            :loading="
              uploaderStatus === 'uploading'
                || solicitarServicoCdiStatus === 'loading'
            "
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
