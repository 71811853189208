<script setup>
import { useRemoverPessoaEndereco } from '~/composables/pessoas/useRemoverPessoaEndereco';
import { useDrawerStore } from '~/stores/drawer';
import { isNilOrEmpty } from '~/utils';
import { notification } from 'ant-design-vue';
import { isNil } from 'lodash-es';
import { reactive } from 'vue';

const props = defineProps({
  pessoaCodigo: {
    required: true,
    type: String,
  },
  pessoasEnderecos: {
    default: () => [],
    type: Array,
  },
});

const emit = defineEmits(['pessoa-endereco-salvo', 'pessoa-endereco-removido']);

const drawerStore = useDrawerStore();

const {
  err: removerPessoaEnderecoErr,
  runAsync: removerPessoaEnderecoAsync,
  status: removerPessoaEnderecoStatus,
} = useRemoverPessoaEndereco();

const comparePessoaEndereco = (a, b) => {
  if (a.ehPrincipal) {
    return -2;
  }
  if (a.ehPrincipal === b.ehPrincipal) {
    if (a.dataCriacaoEmTimestamp < b.dataCriacaoEmTimestamp) {
      return 1;
    }
    if (a.dataCriacaoEmTimestamp > b.dataCriacaoEmTimestamp) {
      return -1;
    }
    return 0;
  }
};

const state = reactive({
  pessoasEnderecos: [...props.pessoasEnderecos].sort(comparePessoaEndereco),
});

const abrirSalvarPessoaEnderecoForm = (pessoaEnderecoCodigo = null) => {
  drawerStore.push2({
    componentName: 'SalvarPessoaEnderecoForm',
    onSuccess: async () => {
      emit('pessoa-endereco-salvo');
    },
    params: {
      pessoaCodigo: props.pessoaCodigo,
      pessoaEnderecoCodigo: pessoaEnderecoCodigo,
    },
    width: 600,
  });
};

const removerAsync = async (pessoaEnderecoCodigo) => {
  await removerPessoaEnderecoAsync({
    pessoaEnderecoCodigo: pessoaEnderecoCodigo,
  });

  if (removerPessoaEnderecoStatus.value === 'error') {
    notification.error({
      message: 'Tivemos um problema ao tentar remover',
    });
    return;
  }

  emit('pessoa-endereco-removido');
};
</script>
<template>
  <a-card title="Endereços" style="margin-top: 24px">
    <template #extra>
      <a-button @click="() => abrirSalvarPessoaEnderecoForm()">
        Novo endereço
      </a-button>
    </template>
    <a-spin :spinning="removerPessoaEnderecoStatus === 'loading'">
      <a-list item-layout="horizontal" :data-source="state.pessoasEnderecos">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta>
              <template #title>
                <a-tag v-if="item.ehPrincipal" color="green">PRINCIPAL</a-tag>
                <a href="#">{{ item.descricao.toUpperCase() }}</a>
              </template>
              <template #description>
                {{ item.endereco.logradouro.toUpperCase() }}
                <span v-if="!isNilOrEmpty(item.endereco.numero)"
                  >, {{ item.endereco.numero.toUpperCase() }}</span
                >
                <span v-if="!isNilOrEmpty(item.endereco.complemento)"
                  >, {{ item.endereco.complemento.toUpperCase() }}</span
                >
                <span v-if="!isNilOrEmpty(item.endereco.bairro)"
                  >, {{ item.endereco.bairro.toUpperCase() }}</span
                >
                <span>, {{ item.endereco.cidade.nome.toUpperCase() }}</span>
                <span>, {{ item.endereco.estado.sigla }}</span>
                <span v-if="!isNil(item.endereco.cep)">
                  CEP: {{ item.endereco.cep }}</span
                >
              </template>
            </a-list-item-meta>
            <template #actions>
              <a
                href="#"
                @click.prevent="
                  () => abrirSalvarPessoaEnderecoForm(item.codigo)
                "
                >Editar</a
              >
              <a href="#" @click.prevent="() => removerAsync(item.codigo)"
                >Remover</a
              >
            </template>
          </a-list-item>
        </template>
      </a-list>
    </a-spin>
  </a-card>
</template>
