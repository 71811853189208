<script setup>
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { computed, onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['loaded', 'close', 'success', 'error']);

const {
  data: verificarNecessidadeCadastroPublicacaoCapturadaData,
  err: verificarNecessidadeCadastroPublicacaoCapturadaErr,
  runAsync: verificarNecessidadeCadastroPublicacaoCapturadaAsync,
  status: verificarNecessidadeCadastroPublicacaoCapturadaStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/verificacao-necessidade-cadastro-publicacao-capturada/verificar-necessidade-cadastro-publicacao-capturada`,
});

const authStore = useAuthStore();
const formRef = ref(null);
const state = reactive({
  arquivos: [],
  pasta: null,
  tipoVerificarNecessidadeCadastroPublicacaoCapturada: null,
});
const formRules = computed(() => {
  return {
    pasta: [
      {
        message: 'Pasta é obrigatória',
        required:
          state.tipoVerificarNecessidadeCadastroPublicacaoCapturada === 1,
        type: 'object',
      },
    ],
    tipoVerificarNecessidadeCadastroPublicacaoCapturada: [
      { message: 'Opção é obrigatória', required: true },
    ],
  };
});

const uploaderRef = ref(null);
const uploaderStatus = ref(null);
const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await verificarNecessidadeCadastroPublicacaoCapturadaAsync({
      arquivos: state.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      pastaCodigo: state.pasta?.value,
      tipoVerificarNecessidadeCadastroPublicacaoCapturada:
        state.tipoVerificarNecessidadeCadastroPublicacaoCapturada,
    });

    if (
      verificarNecessidadeCadastroPublicacaoCapturadaStatus.value === 'error'
    ) {
      jobStatus.value = 'error';
      notification.error({
        description:
          verificarNecessidadeCadastroPublicacaoCapturadaErr.value.description,
        duration: 5,
        message:
          verificarNecessidadeCadastroPublicacaoCapturadaErr.value.message,
      });
      emit('error');
      return;
    }

    const jobCodigo = verificarNecessidadeCadastroPublicacaoCapturadaData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
};

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-result
      v-if="jobStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => $emit('close')"> Fechar </a-button>
      </template>
    </a-result>

    <div v-if="jobStatus !== 'success'">
      <a-form ref="formRef" layout="vertical" :model="state" :rules="formRules">
        <a-form-item
          label="Escolha uma das opções abaixo"
          name="tipoVerificarNecessidadeCadastroPublicacaoCapturada"
        >
          <a-radio-group
            v-model:value="
              state.tipoVerificarNecessidadeCadastroPublicacaoCapturada
            "
          >
            <a-radio-button :value="1"> Atualizar pasta </a-radio-button>
            <a-radio-button :value="2">
              Solicitar verificação de cadastro para o PUB
            </a-radio-button>
            <a-radio-button :value="3">
              Não precisa de cadastro
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          v-if="state.tipoVerificarNecessidadeCadastroPublicacaoCapturada === 1"
          label="Pasta"
          name="pasta"
        >
          <AjaxSelect
            v-model:value="state.pasta"
            :url="`${API_URL}/ajax-select/pastas`"
            :allow-clear="true"
            placeholder="Selecione a pasta"
          />
        </a-form-item>

        <h3 style="margin-bottom: 24px">Anexar arquivos</h3>

        <a-row>
          <a-col :span="24">
            <Uploader
              ref="uploaderRef"
              v-model:fileList="state.arquivos"
              @start="() => (uploaderStatus = 'uploading')"
              @end="() => (uploaderStatus = 'done')"
            />
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              props.compromisso?.responsavelCodigo !== authStore.codigoUsuario
            "
            :loading="jobStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
