query pastaDatatable(
    $gcpjDesatualizado: Boolean
    $semValorCausa: Boolean
    $semConta: Boolean
    $semCarteiraContrato: Boolean
    $text: String
    $tempoCadastroEmAnos: Int
    $tempoAjuizamentoEmAnos: Int
    $where: PastaModelFilterInput
    $order: [PastaModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: pastasOffsetPagination(
        gcpjDesatualizado: $gcpjDesatualizado
        semValorCausa: $semValorCausa
        semConta: $semConta
        semCarteiraContrato: $semCarteiraContrato
        text: $text
        tempoCadastroEmAnos: $tempoCadastroEmAnos
        tempoAjuizamentoEmAnos: $tempoAjuizamentoEmAnos
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            nome
            numeroCnj
            status
            statusText
            acao {
                codigo
                nome
            }
            clientePrincipal {
                codigo
                nome
            }
            contrarioPrincipal {
                codigo
                nome
            }
            agencia: agencia2 {
                codigo
                numero
                nome
                porte
                porteText
                regional
                regionalText
                estado {
                    codigo
                    sigla
                }
                label
            }
            responsavelProcessual {
                codigo
                nome
            }
            responsavelNegocial {
                codigo
                nome
            }
            gerenteNegocialResponsavel {
                codigo
                nome
            }
            estado {
                codigo
                sigla
                nome
            }
            cidade {
                codigo
                nome
            }
            comarca {
                codigo
                nome
            }
        }
        totalCount
    }
}
