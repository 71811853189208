<script lang="ts" setup>
import { theme } from 'ant-design-vue';
import { DownOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import type { MenuInfo } from 'ant-design-vue/es/menu/src/interface';
import { useAuthenticationStore } from '~/stores/authenticationStore';
import router from '~/router';

const { useToken } = theme;
const { token } = useToken();

const authenticationStore = useAuthenticationStore();
const logoutLoadingRef = ref<boolean>(false);

function atualizar()
{
  window.location.reload();
}

async function logout()
{
  logoutLoadingRef.value = true;
  await authenticationStore.logout();
  router.push('/login');
  logoutLoadingRef.value = false;
}

async function handleClick(e: MenuInfo)
{
  if (e.key === 'logout')
  {
    await logout();
  }
};
</script>

<template>
  <a-row class="page-header">
    <a-col :span="16">
      <div class="logo">
        <span class="logo-primary-text" :style="{ color: token.colorPrimary } ">TEMPLATE</span>
      </div>
    </a-col>
    <a-col :span="8" style="text-align: right">
      <a-space>
        <a-button @click="atualizar">
          <template #icon>
            <ReloadOutlined />
          </template>
          Atualizar
        </a-button>
        <a-dropdown v-if="authenticationStore.user" trigger="click">
          <template #overlay>
            <a-menu @click="handleClick">
              <a-menu-item key="logout">
                Logout
              </a-menu-item>
            </a-menu>
          </template>
          <a-button :loading="logoutLoadingRef">
            <template #icon>
              <UserOutlined />
            </template>
            {{ authenticationStore.user?.id }}
            <DownOutlined />
          </a-button>
        </a-dropdown>
      </a-space>
    </a-col>
  </a-row>
</template>

<style scope>
.page-header .logo {
  float: left;
}

.page-header .logo .logo-primary-text {
  font-size: 25px;
  font-weight: bold;
}

.page-header .logo img {
  height: 32px;
  margin-right: 4px;
  position: relative;
  top: 4px;
}

.page-header .logo .logo-secondary-text {
  font-size: 24px;
  font-weight: 500;
}
</style>
