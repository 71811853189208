<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';
import { useDrawerStore } from '~/stores/drawer';
import fluxosEspecificosDatatable from '~/graphql/fluxosEspecificosDatatable.graphql';

interface Props
{
  pastaCodigo?: string
}

const props = defineProps<Props>();

const drawerStore = useDrawerStore();

const columns = [
  {
    key: 'tipo',
    title: 'TIPO',
    width: 220,
  },
  {
    key: 'status',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'prazo',
    title: 'PRAZO',
    width: 120,
  },
  {
    key: 'solicitadoPor',
    title: 'SOLICITADO POR',
    width: 120,
  },
  {
    key: 'iniciadoEm',
    title: 'INICIADO EM',
    width: 120,
  },
  {
    key: 'responsavelPendente',
    title: 'RESPONSÁVEL PENDENTE',
    width: 120,
  },
  {
    key: 'concluidoEm',
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

function obterUltimoCompromisso(compromissos: [any], tipo: number, status: number)
{
  const compromissosFiltradosPorTipo = compromissos.filter(x => x.tipo === tipo && x.status === status);
  compromissosFiltradosPorTipo.sort((a, b) =>
  {
    const dateA = dayjs(a.dataInicio);
    const dateB = dayjs(b.dataInicio);
    return dateA.diff(dateB);
  });
  return compromissosFiltradosPorTipo[compromissosFiltradosPorTipo.length - 1];
}

function obterUltimoCompromissoEmAndamento(compromissos: [any])
{
  const compromissosFiltradosPorTipo = compromissos.filter(x => x.status === 1);
  compromissosFiltradosPorTipo.sort((a, b) =>
  {
    const dateA = dayjs(a.dataInicio);
    const dateB = dayjs(b.dataInicio);
    return dateA.diff(dateB);
  });
  return compromissosFiltradosPorTipo[compromissosFiltradosPorTipo.length - 1];
}

const gqlDatatable = ref<any>(null);

function abrirFluxo(codigoFluxo: string)
{
  drawerStore.push({
    componentName: 'FluxoView',
    params: { codigoFluxo },
    onClose: () =>
    {
      gqlDatatable.value?.reloadAsync();
    },
  });
}
</script>

<template>
  <GqlDatatable
    ref="gqlDatatable"
    :query="fluxosEspecificosDatatable"
    :columns="columns"
    :variables="{
      order: [{ dataInicio: 'DESC' }],
      where: {
        tipo: {
          eq: 34,
        },
        pastaCodigo: {
          eq: props.pastaCodigo,
        },
      },
    }"
  >
    <template #tipoColumn="{ record }">
      <a
        href="#"
        style="text-transform: uppercase"
        @click.prevent="() => abrirFluxo(record.codigo)"
      >{{ obterUltimoCompromisso(record.compromissos, 72, 3).data.tipoText }}</a>
    </template>
    <template #statusColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.statusText }}</span>
    </template>
    <template #prazoColumn="{ record }">
      <span>{{ dayjs(obterUltimoCompromisso(record.compromissos, 72, 3).data.dataPrazo).format("DD/MM/YYYY") }}</span>
    </template>
    <template #solicitadoPorColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.iniciadoPor.nome }}</span>
    </template>
    <template #iniciadoEmColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.dataInicioText }}</span>
    </template>
    <template #responsavelPendenteColumn="{ record }">
      <span>{{ obterUltimoCompromissoEmAndamento(record.compromissos)?.responsavel.nome }}</span>
    </template>
    <template #concluidoEmColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.dataConclusaoText }}</span>
    </template>
  </GqlDatatable>
</template>
