<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Novo responsável" :span="3">
      {{ props.compromisso.data.responsavelNovoNome }}
    </a-descriptions-item>
    <a-descriptions-item label="Responsável antigo" :span="3">
      {{ props.compromisso.data.responsavelAntigoNome }}
    </a-descriptions-item>
  </a-descriptions>
</template>
