<script setup>
const props = defineProps({
  pessoa: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits([
  'pessoa-email-salvo',
  'pessoa-email-removido',
  'pessoa-telefone-salvo',
  'pessoa-telefone-removido',
  'pessoa-endereco-salvo',
  'pessoa-endereco-removido',
]);
</script>
<template>
  <PessoaFisica
    v-if="props.pessoa.tipo === 'FISICA'"
    :pessoa="props.pessoa"
  ></PessoaFisica>
  <PessoaJuridica
    v-if="props.pessoa.tipo === 'JURIDICA'"
    :pessoa="props.pessoa"
  ></PessoaJuridica>
  <PessoaEnderecoList
    :pessoa-codigo="props.pessoa.codigo"
    :pessoas-enderecos="props.pessoa.pessoasEnderecos"
    @pessoa-endereco-salvo="emit('pessoa-endereco-salvo')"
    @pessoa-endereco-removido="emit('pessoa-endereco-removido')"
  ></PessoaEnderecoList>
  <PessoaTelefoneList
    :pessoa-codigo="props.pessoa.codigo"
    :pessoas-telefones="props.pessoa.pessoasTelefones"
    @pessoa-telefone-salvo="emit('pessoa-telefone-salvo')"
    @pessoa-telefone-removido="emit('pessoa-telefone-removido')"
  ></PessoaTelefoneList>
  <PessoaEmailList
    :pessoa-codigo="props.pessoa.codigo"
    :pessoas-emails="props.pessoa.pessoasEmails"
    @pessoa-email-salvo="emit('pessoa-email-salvo')"
    @pessoa-email-removido="emit('pessoa-email-removido')"
  ></PessoaEmailList>
</template>
