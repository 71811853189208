<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { isNil } from 'lodash-es';
import { onMounted, reactive, ref, toRaw } from 'vue';
import { API_URL } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import { usePost } from '~/composables/usePost';

const props = defineProps({
  codigoPasta: {
    required: true,
    type: String,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: registrarAndamentoProcessualErr,
  runAsync: registrarAndamentoProcessualAsync,
  status: registrarAndamentoProcessualStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/andamento-processual/registrar-andamento-processual`,
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const $auth = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  descricao: null,
});
const formRules = reactive({
  descricao: [
    {
      message: 'Descrição é obrigatória',
      required: true,
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();

    await registrarAndamentoProcessualAsync({
      codigoPasta: props.codigoPasta,
      ...toRaw(formModel),
    });

    if (registrarAndamentoProcessualStatus.value === 'error')
    {
      notification.error({
        description: registrarAndamentoProcessualErr.value.description,
        duration: 5,
        message: registrarAndamentoProcessualErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
}

onMounted(async () =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-result
      v-if="registrarAndamentoProcessualStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="registrarAndamentoProcessualStatus !== 'success'">
      <a-collapse
        v-if="props.codigoPasta !== null"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 63,
                },
                fluxo: {
                  pastaCodigo: {
                    in: [props.codigoPasta],
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
        style="margin-top: 16px"
      >
        <a-form-item
          label="Descrição"
          name="descricao"
          style="padding-bottom: 0"
        >
          <a-textarea v-model:value="formModel.descricao" :rows="4" />
        </a-form-item>

        <Uploader
          ref="uploaderRef"
          v-model:fileList="formModel.arquivos"
          @start="() => (uploaderStatus = 'uploading')"
          @end="() => (uploaderStatus = 'done')"
        >
          <template #description>
            <p class="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p class="ant-upload-text">
              Clique aqui ou arraste os arquivos
            </p>
            <p class="ant-upload-hint">
              Envie os arquivos necessários
            </p>
          </template>
        </Uploader>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNil(props.compromisso)
                && props.compromisso.responsavel.codigo !== $auth.codigoUsuario
            "
            :loading="
              uploaderStatus === 'uploading'
                || registrarAndamentoProcessualStatus === 'loading'
            "
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Fechar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
