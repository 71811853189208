<script setup>
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: transferenciaPastaParaArquivoMortoNaoAprovadaData,
  err: transferenciaPastaParaArquivoMortoNaoAprovadaErr,
  runAsync: transferenciaPastaParaArquivoMortoNaoAprovadaAsync,
  status: transferenciaPastaParaArquivoMortoNaoAprovadaStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: null,
  gerente: null,
  observacao: null,
  solicitarNovamente: null,
});
const formRules = reactive({
  solicitarNovamente: [
    {
      message: 'Essa opção é obrigatória',
      required: true,
      trigger: 'change',
    },
  ],
});

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const uploaderRef = ref(null);
const uploaderStatus = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await transferenciaPastaParaArquivoMortoNaoAprovadaAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      gerenteCodigo: formModel.gerente?.value,
      observacao: formModel.observacao,
      observacao: formModel.observacao,
      solicitarNovamente: formModel.solicitarNovamente,
    });

    if (transferenciaPastaParaArquivoMortoNaoAprovadaStatus.value === 'error') {
      jobStatus.value = 'error';

      notification.error({
        description:
          transferenciaPastaParaArquivoMortoNaoAprovadaErr.value.description,
        duration: 5,
        message: transferenciaPastaParaArquivoMortoNaoAprovadaErr.value.message,
      });

      emit('error');
      return;
    }

    const jobCodigo = transferenciaPastaParaArquivoMortoNaoAprovadaData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
};

watch(
  () => formModel.solicitarNovamente,
  (val) => {
    if (val) {
      formRules.gerente = [
        {
          message: 'Gerente aprovador é obrigatório',
          required: true,
          trigger: 'change',
          type: 'object',
        },
      ];
    } else {
      formRules.gerente = null;
    }
  }
);

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <a-row>
    <a-col :xs="24" :md="24" :lg="24" :xl="24">
      <a-result
        v-if="jobStatus === 'success'"
        status="success"
        title="Registro realizado com sucesso"
        sub-title="Agora você pode fechar essa janela"
      >
        <template #extra>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </template>
      </a-result>

      <a-row v-if="jobStatus !== 'success'" :gutter="24">
        <a-col :span="24">
          <a-row>
            <a-col :span="24">
              <a-form
                ref="formRef"
                layout="vertical"
                :model="formModel"
                :rules="formRules"
              >
                <a-form-item
                  label="Solicitar novamente?"
                  name="solicitarNovamente"
                >
                  <a-radio-group v-model:value="formModel.solicitarNovamente">
                    <a-radio-button :value="true"> Sim </a-radio-button>
                    <a-radio-button :value="false"> Não </a-radio-button>
                  </a-radio-group>
                </a-form-item>

                <template v-if="formModel.solicitarNovamente">
                  <a-form-item name="gerente" label="Gerente aprovador">
                    <GraphQlSelect
                      v-model:value="formModel.gerente"
                      query="GERENTES_FOR_SELECT"
                      placeholder="Selecione o gerente aprovador"
                      :allow-clear="true"
                    />
                  </a-form-item>

                  <a-form-item label="Observação" name="observacao">
                    <a-textarea
                      v-model:value="formModel.observacao"
                      :rows="4"
                    />
                  </a-form-item>
                </template>
              </a-form>

              <h3 style="margin-bottom: 14px">Anexar arquivos</h3>

              <a-row>
                <a-col :span="24">
                  <Uploader
                    ref="uploaderRef"
                    v-model:fileList="formModel.arquivos"
                    @start="() => (uploaderStatus = 'uploading')"
                    @end="() => (uploaderStatus = 'done')"
                  />
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px; margin-bottom: 0">
                <a-col :span="24">
                  <a-button
                    :loading="jobStatus === 'loading'"
                    :disabled="
                      compromisso.responsavel.codigo !== authStore.codigoUsuario
                    "
                    type="primary"
                    style="margin-right: 8px; min-width: 100px"
                    @click="uiSalvarAsync"
                  >
                    Salvar
                  </a-button>
                  <a-button @click="() => emit('close')"> Fechar </a-button>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
