query publicacaoNumeroProcessoDatatable(
    $where: PublicacaoNumeroProcessoModelFilterInput
    $order: [PublicacaoNumeroProcessoModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: publicacoesNumeroProcessosOffsetPagination(
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo
            numeroProcesso
            publicacao {
                diario
                dataMovimento
                dataMovimentoText
                caderno
                pagina
                conteudo
            }
        }
        totalCount
    }
}
