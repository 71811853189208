<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { API_URL } from '~/utils';
import { useAuthStore } from '~/stores/auth';
import { usePost } from '~/composables/usePost';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: enviarPlanilhaAtualizacaoDebitoData,
  err: enviarPlanilhaAtualizacaoDebitoErr,
  runAsync: enviarPlanilhaAtualizacaoDebitoAsync,
  status: enviarPlanilhaAtualizacaoDebitoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: props.compromisso?.url
    ? `${API_URL}${props.compromisso.url}`
    : `${API_URL}/fluxo/planilha-atualizacao-debito/enviar-planilha-atualizacao-debito`,
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  dataPrazo: null,
  observacao: null,
  tipo: null,
});
const formRules = reactive({
  dataPrazo: [
    {
      message: 'Prazo é obrigatório',
      required: true,
    },
  ],
  tipo: [
    {
      message: 'Tipo é obrigatório',
      required: true,
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);
function disabledDate(current)
{
  return (
    current.day() === 0
    || current.day() === 6
    || current <= dayjs().subtract(1, 'day').endOf('day')
  );
}
const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await enviarPlanilhaAtualizacaoDebitoAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      dataPrazo: formModel.dataPrazo,
      observacao: formModel.observacao,
      pastaCodigos: props.pastaCodigos,
      tipo: formModel.tipo,
    });

    if (enviarPlanilhaAtualizacaoDebitoStatus.value === 'error')
    {
      jobStatus.value = 'error';

      notification.error({
        description: enviarPlanilhaAtualizacaoDebitoErr.value.description,
        duration: 5,
        message: enviarPlanilhaAtualizacaoDebitoErr.value.message,
      });

      emit('error');
      return;
    }

    const jobCodigo = enviarPlanilhaAtualizacaoDebitoData.value;

    await jobPoolingAsync({ codigo: jobCodigo });

    emit('success');
  });
}

watch(jobPoolingStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage
        ?? 'Tivemos um problema inesperado',
    });
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-result
      v-if="jobStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </template>
    </a-result>

    <div v-if="jobStatus !== 'success'">
      <a-collapse
        v-if="props.pastaCodigos?.length === 1"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 50,
                },
                fluxo: {
                  pastaCodigo: {
                    in: props.pastaCodigos,
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>

      <a-alert show-icon style="margin-top: 16px">
        <template #message>
          <a href="/TEMPLATE_PLANILHA_ACORDO_DESCUMPRIDO.xlsx">Clique aqui para baixar o EXCEL padrão para acordo descumprido</a>
        </template>
      </a-alert>

      <a-alert show-icon style="margin-top: 8px">
        <template #message>
          <a href="/TEMPLATE_PLANILHA_ATUALIZACAO_DO_VALOR_DA_CAUSA.xlsx">Clique aqui para baixar o EXCEL padrão para atualização do valor da
            causa</a>
        </template>
      </a-alert>

      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
        style="margin-top: 16px"
      >
        <a-form-item label="Tipo" name="tipo">
          <a-radio-group v-model:value="formModel.tipo" button-style="solid">
            <a-radio-button :value="1">
              Contrato descumprido
            </a-radio-button>
            <a-radio-button :value="2">
              Atualização do valor da causa
            </a-radio-button>
            <a-radio-button :value="3">
              Atualização realizada pelo escritório
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Prazo" name="dataPrazo">
          <a-date-picker
            v-model:value="formModel.dataPrazo"
            :disabled-date="disabledDate"
            format="DD/MM/YYYY"
            value-format="DD/MM/YYYY"
            placeholder="Selecione uma data"
            style="width: 100%"
          />
        </a-form-item>

        <a-form-item
          label="Observação"
          name="observacao"
          style="padding-bottom: 0"
        >
          <a-textarea v-model:value="formModel.observacao" :rows="4" />
        </a-form-item>

        <Uploader
          ref="uploaderRef"
          v-model:fileList="formModel.arquivos"
          @start="() => (uploaderStatus = 'uploading')"
          @end="() => (uploaderStatus = 'done')"
        >
          <template #description>
            <p class="ant-upload-drag-icon">
              <CloudUploadOutlined />
            </p>
            <p class="ant-upload-text">
              Clique aqui ou arraste a planilha de atualização de débito
            </p>
            <p class="ant-upload-hint">
              Envie a planilha de atualização de débito
            </p>
          </template>
        </Uploader>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNil(props.compromisso)
                && props.compromisso.responsavel.codigo !== authStore.codigoUsuario
            "
            :loading="jobStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')">
            Cancelar
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
