<script setup lang="ts">
import { onMounted } from 'vue';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty } from '~/utils';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const authStore = useAuthStore();

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <CompromissoCommandForm2
      :command="compromisso.command2"
      :show-upload-files="false"
      :submit-button-disabled="!isNilOrEmpty(compromisso)
        && compromisso.responsavel.codigo !== authStore.codigoUsuario"
      :initial-model="{
        compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Observação"
          name="observacao"
        >
          <a-textarea
            v-model:value="model.observacao"
            :rows="4"
          />
        </a-form-item>
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
