import { useGet } from '../useGet';

const API_URL = import.meta.env.VITE_API_URL;

export function useObterTotalAcordosNoMesPorResponsavel() {
  return useGet({
    url: `${API_URL}/pastas/obter-total-acordos-no-mes-por-responsavel`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
    onlyLast: true,
  });
}
