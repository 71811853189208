<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { GraphqlDatatableColumn } from './GqlDatatable.vue';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty } from '~/utils';
import referenciaGcpjAndamentoSelect from '~/graphql/referenciaGcpjAndamentoSelect.graphql';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const submitStatus = ref<any>(null);
const mostrarHistoricoSolicitacoes = ref<any>('h');

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const authStore = useAuthStore();

async function postSubmit()
{
  submitStatus.value = 'success';
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <div v-if="submitStatus !== 'success'">
      <a-collapse
        v-if="props.pastaCodigos?.length === 1"
        v-model:activeKey="mostrarHistoricoSolicitacoes"
      >
        <a-collapse-panel
          key="h"
          header="HISTÓRICO DE SOLICITAÇÕES"
        >
          <CompromissoGqlDatatable
            :columns="colunas"
            :show-dates-filter="false"
            :show-reload-button="false"
            :show-status-search-bar="false"
            :show-filter="false"
            :variables="{
              order: [{ dataInicio: 'DESC' }],
              where: {
                tipo: {
                  eq: 161,
                },
                fluxo: {
                  pastaCodigo: {
                    in: props.pastaCodigos,
                  },
                },
              },
            }"
          />
        </a-collapse-panel>
      </a-collapse>
    </div>

    <div style="margin-top: 16px" />

    <CompromissoCommandForm2
      command="Ajuizamento.SolicitarSubsidioCommand"
      :post-submit="postSubmit"
      :submit-button-disabled="!isNilOrEmpty(compromisso)
        && compromisso.responsavel.codigo !== authStore.codigoUsuario"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
        compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          label="Precisa de subsídio?"
          name="precisaSubsidio"
          :rules="{ required: true, message: 'Esse item é obrigatório' }"
        >
          <a-radio-group
            v-model:value="model.precisaSubsidio"
          >
            <a-radio-button :value="true">
              Sim
            </a-radio-button>
            <a-radio-button :value="false">
              Não
            </a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item
          v-if="model.precisaSubsidio"
          label="Referência GCPJ"
          name="referenciaGcpj"
          :rules="{ required: true, message: 'Referência GCPJ é obrigatória' }"
        >
          <GqlSelect
            v-model:value="model.referenciaGcpj"
            placeholder="Escolha a referência GCPJ"
            :query="referenciaGcpjAndamentoSelect"
            :allow-clear="true"
          />
        </a-form-item>

        <a-form-item
          v-if="model.precisaSubsidio"
          label="Emails"
          name="emails"
          :rules="{ required: true, message: 'Emails é obrigatório' }"
        >
          <a-select
            v-model:value="model.emails"
            mode="tags"
            style="width: 100%"
            :token-separators="[',', ' ']"
            placeholder="Digite os emails"
            :open="false"
          />
        </a-form-item>

        <a-form-item
          v-if="model.precisaSubsidio"
          label="Documentos necessários"
          name="documentosNecessarios"
          :rules="{ required: true, message: 'Documentos necessários é obrigatório' }"
        >
          <a-input v-model:value="model.documentosNecessarios" />
        </a-form-item>

        <a-alert
          v-if="model.precisaSubsidio"
          type="warning"
          message="Uma cópia do email será enviada para: ronaldo.g.silva@bradesco.com.br, gerenciageral@edsonrosasadvocacia.com.br, caq@edsonrosasadvocacia.com.br e thais@edsonrosasadvocacia.com.br"
          show-icon
        />

        <div style="margin-top: 16px" />
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
