<script setup>
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: verificarEncerramentoGcpjData,
  err: verificarEncerramentoGcpjErr,
  runAsync: verificarEncerramentoGcpjAsync,
  status: verificarEncerramentoGcpjStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const {
  err: devolverCompromissoErr,
  runAsync: devolverCompromissoAsync,
  status: devolverCompromissoStatus,
} = useDevolverCompromisso();

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const authStore = useAuthStore();
const devolverModalVisible = ref(false);
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  descricao: null,
  estaEncerrado: null,
  justificativaDevolucao: null,
});
const formRules = reactive({
  descricao: [{ message: 'Descrição é obrigatória', required: true }],
  estaEncerrado: [
    {
      message: 'Informar se está encerrado ou não é obrigatório',
      required: true,
    },
  ],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await verificarEncerramentoGcpjAsync({
      arquivos: formModel.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      descricao: formModel.descricao,
      estaEncerrado: formModel.estaEncerrado,
    });

    if (verificarEncerramentoGcpjStatus.value === 'error')
    {
      jobStatus.value = 'error';
      notification.error({
        description: verificarEncerramentoGcpjErr.value.description,
        duration: 5,
        message: verificarEncerramentoGcpjErr.value.message,
      });
      emit('error');
      return;
    }

    const jobCodigo = verificarEncerramentoGcpjData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
}

async function uiDevolverAsync()
{
  await devolverCompromissoAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverCompromissoStatus.value === 'error')
  {
    notification.error({
      description: devolverCompromissoErr.value.description,
      duration: 5,
      message: devolverCompromissoErr.value.message,
    });
    emit('error');
    return;
  }
  emit('success');
}

function estaEncerradoChange()
{
  formModel.descricao = null;
}

watch(jobPoolingStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage
        ?? 'Tivemos um problema inesperado',
    });
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-row>
      <a-col
        :xs="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <a-result
          v-if="jobStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverCompromissoStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-row
          v-if="
            jobStatus !== 'success' && devolverCompromissoStatus !== 'success'
          "
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item
                label="Pasta foi encerrada no GCPJ?"
                name="estaEncerrado"
              >
                <a-radio-group
                  v-model:value="formModel.estaEncerrado"
                  @change="estaEncerradoChange"
                >
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="formModel.estaEncerrado === false"
                label="Descrição"
                name="descricao"
                style="padding-bottom: 0"
              >
                <a-textarea
                  v-model:value="formModel.descricao"
                  :rows="4"
                />
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">
                    Envie os arquivos necessários
                  </p>
                </template>
              </Uploader>
            </a-form>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverCompromissoStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="jobStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')">
                  Fechar
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
