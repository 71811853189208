<script setup>
import { reactive, ref, watch, watchEffect } from 'vue';
import { notification } from 'ant-design-vue';
import { UserOutlined } from '@ant-design/icons-vue';
import { useObterComentariosPorPasta } from '~/composables/pastas/useObterComentariosPorPasta';
import ErrorResult from '~/components/ErrorResult.vue';
import { useAdicionarComentario } from '~/composables/pastas/useAdicionarComentario';

const props = defineProps({
  codigoPasta: {
    type: String,
    required: true,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
});

const {
  status: obterComentariosPorPastaStatus,
  run: obterComentariosPorPasta,
  data: obterComentariosPorPastaData,
} = useObterComentariosPorPasta();

const { status: adicionarComentarioStatus, run: adicionarComentario }
  = useAdicionarComentario();

const formRef = ref(null);
const formModel = reactive({
  texto: null,
});
const formRules = reactive({
  texto: [{ required: true, message: 'Comentário é obrigatório' }],
});

async function comentar()
{
  formRef.value.validate().then(async () =>
  {
    await adicionarComentario({
      codigoPasta: props.codigoPasta,
      texto: formModel.texto,
    });
  });
}

watchEffect(async () =>
{
  if (!props.isSelected)
  {
    return;
  }

  await obterComentariosPorPasta({ codigoPasta: props.codigoPasta });
});

watch(
  () => adicionarComentarioStatus.value,
  async (val) =>
  {
    if (val === 'success')
    {
      await obterComentariosPorPasta({ codigoPasta: props.codigoPasta });
      formModel.texto = '';
    }
    else if (val === 'error')
    {
      notification.error({
        message: 'Tivemos um problema ao comentar',
        duration: 5.0,
      });
    }
  },
);
</script>

<template>
  <div style="padding: 16px 0">
    <a-row style="padding-left: 24px">
      <a-col><h2>Comentários</h2></a-col>
    </a-row>
    <a-divider style="margin-top: 16px" />
    <div style="padding: 0 24px">
      <a-comment>
        <template #avatar>
          <a-avatar>
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </template>
        <template #content>
          <a-form
            ref="formRef"
            layout="vertical"
            :model="formModel"
            :rules="formRules"
            :hide-required-mark="true"
          >
            <a-form-item name="comentario">
              <a-textarea v-model:value="formModel.texto" :rows="2" />
            </a-form-item>
            <a-form-item>
              <a-button
                :loading="adicionarComentarioStatus === 'loading'"
                type="primary"
                @click="comentar"
              >
                Comentar
              </a-button>
            </a-form-item>
          </a-form>
        </template>
      </a-comment>
      <a-card>
        <a-skeleton
          v-if="obterComentariosPorPastaStatus === 'loading'"
          :loading="true"
          :active="true"
          :paragraph="{ rows: 8 }"
        />
        <ErrorResult v-else-if="obterComentariosPorPastaStatus === 'error'" />
        <div v-else>
          <Empty
            v-if="obterComentariosPorPastaData?.comentarios?.length === 0"
            description="Nenhum comentário"
          />
          <div v-else>
            <a-comment
              v-for="comentario in obterComentariosPorPastaData?.comentarios
                || []"
              :key="comentario.codigo"
            >
              <template #author>
                <a-tooltip v-if="!isNil(comentario.criadoPorCodigo)">
                  <template #title>
                    Ir para página do usuário
                  </template>

                  <router-link :to="`/usuario/${comentario.criadoPorCodigo}`">
                    {{ comentario.criadoPorNome }}
                  </router-link>
                </a-tooltip>
              </template>
              <template #avatar>
                <a-avatar size="small">
                  <template #icon>
                    <UserOutlined />
                  </template>
                </a-avatar>
              </template>
              <template #content>
                <p>
                  {{ comentario.texto }}
                </p>
              </template>
              <template #datetime>
                <span>{{ comentario.dataCriacao }}</span>
              </template>
            </a-comment>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>
