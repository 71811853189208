<script setup>
import axios from 'axios';
import { defineStore } from 'pinia';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { API_URL, error } from '~/utils';
import { useAuthStore } from '~/stores/auth';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  codigosPastas: {
    default: () => null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const useStore = defineStore('solicitar-pagamento-custas', {
  actions: {
    async salvar()
    {
      this.salvarStatus = 'loading';
      try
      {
        await axios.post(
          props.compromisso?.url
            ? `${API_URL}${props.compromisso.url}`
            : `${API_URL}/fluxo/custas/solicitar-pagamento-custas`,
          {
            arquivos: this.arquivos,
            codigoCompromisso: this.compromisso?.codigo ?? null,
            codigoPasta: props.codigoPasta,
            dataPrazo: this.dataPrazo,
            observacao: this.observacao,
            tipoCustas: this.tipoCustas.value,
            valor: this.valor,
          },
          {
            getAccessToken: true,
            timeout: 120000,
          },
        );
        this.salvarStatus = 'success';
      }
      catch (e)
      {
        this.salvarStatus = 'error';
        this.error = error(e).error;
      }
    },
  },
  state: () => ({
    arquivos: [],
    compromisso: null,
    dataPrazo: null,
    error: null,
    observacao: null,
    salvarStatus: null,
    tipoCustas: null,
    uploaderStatus: null,
    valor: null,
  }),
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];
const $auth = useAuthStore();
const $s = useStore();
const formRef = ref(null);
const formModel = $s.$state;
const formRules = reactive({
  dataPrazo: [{ message: 'Prazo é obrigatório', required: true }],
  tipoCustas: [{ message: 'Tipo da custas é obrigatório', required: true }],
  valor: [
    {
      message: 'Valor é obrigatório',
      required: true,
      trigger: 'blur',
      type: 'number',
    },
  ],
});
const uploaderRef = ref(null);

async function salvar()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();
    await $s.salvar();
    if ($s.salvarStatus === 'error')
    {
      notification.error({
        duration: 5,
        message: $s.error,
      });
      emit('error');
      return;
    }
    emit('success');
  });
}

onMounted(() =>
{
  $s.compromisso = props.compromisso;
  emit('loaded');
});

onUnmounted(() =>
{
  $s.$reset();
  $s.$dispose();
});
</script>

<template>
  <a-result
    v-if="$s.salvarStatus === 'success'"
    status="success"
    title="Registro realizado com sucesso"
    sub-title="Agora você pode fechar essa janela"
  >
    <template #extra>
      <a-button @click="() => $emit('close')">
        Fechar
      </a-button>
    </template>
  </a-result>

  <div v-if="$s.salvarStatus !== 'success'">
    <a-collapse
      v-if="props.codigoPasta !== null"
      v-model:activeKey="mostrarHistoricoSolicitacoes"
    >
      <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
        <CompromissoGqlDatatable
          :columns="colunas"
          :show-dates-filter="false"
          :show-reload-button="false"
          :show-status-search-bar="false"
          :show-filter="false"
          :variables="{
            order: [{ dataInicio: 'DESC' }],
            where: {
              tipo: {
                eq: 5,
              },
              fluxo: {
                pastaCodigo: {
                  in: [props.codigoPasta],
                },
              },
            },
          }"
        />
      </a-collapse-panel>
    </a-collapse>

    <a-form
      ref="formRef"
      layout="vertical"
      :model="formModel"
      :rules="formRules"
      style="margin-top: 16px"
    >
      <a-form-item label="Tipo" name="tipoCustas">
        <AjaxSelect
          v-model:value="formModel.tipoCustas"
          :url="`${API_URL}/ajax-select/tipo-custas`"
          :value-is-number="true"
        />
      </a-form-item>

      <a-form-item label="Valor" name="valor">
        <CurrencyInput
          v-model="formModel.valor"
          :options="{
            locale: 'pt-BR',
            currency: 'BRL',
            currencyDisplay: 'symbol',
            hideCurrencySymbolOnFocus: false,
            hideGroupingSeparatorOnFocus: false,
            hideNegligibleDecimalDigitsOnFocus: false,
            autoDecimalDigits: true,
          }"
        />
      </a-form-item>

      <a-form-item label="Prazo" name="dataPrazo">
        <a-date-picker
          v-model:value="formModel.dataPrazo"
          format="DD/MM/YYYY"
          value-format="DD/MM/YYYY"
          placeholder="Selecione uma data"
          style="width: 100%"
        />
      </a-form-item>

      <a-form-item label="Observação" name="observacao">
        <a-textarea v-model:value="formModel.observacao" :rows="4" />
      </a-form-item>

      <h3 style="margin-bottom: 24px">
        Anexar arquivos
      </h3>

      <a-row>
        <a-col :span="24">
          <Uploader
            ref="uploaderRef"
            v-model:fileList="$s.arquivos"

            @start="() => ($s.uploaderStatus = 'uploading')"
            @end="() => ($s.uploaderStatus = 'done')"
          />
        </a-col>
      </a-row>

      <a-form-item style="margin-top: 24px; margin-bottom: 0px">
        <a-button
          :disabled="
            !isNilOrEmpty(compromisso)
              && compromisso.responsavel.codigo !== $auth.codigoUsuario
          "
          :loading="
            $s.uploaderStatus === 'uploading' || $s.salvarStatus === 'loading'
          "
          type="primary"
          style="margin-right: 8px; min-width: 100px"
          @click.prevent="salvar"
        >
          Salvar
        </a-button>
        <a-button @click="() => $emit('close')">
          Fechar
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
