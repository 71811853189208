<script setup>
import { onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { isNullOrEmpty } from '~/utils';
import { useSalvarPessoa } from '~/composables/pessoas/useSalvarPessoa';
import { useGraphQL } from '~/composables/useGraphQL';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['success', 'close']);

const {
  status: pessoaQueryStatus,
  data: pessoaQueryData,
  runAsync: pessoaQueryAsync,
} = useGraphQL({
  query: 'PessoaQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const {
  status: salvarPessoaStatus,
  runAsync: salvarPessoaAsync,
  err: salvarPessoaErr,
} = useSalvarPessoa();

const justificarAusenciaCnpjModalVisible = ref(false);
const formRef = ref(null);
const state = reactive({
  status: null,
  pessoaCodigo: props.pessoaCodigo,
  tipo: 2,
  nome: null,
  nomeFantasia: null,
  documento: null,
  inscricaoEstadual: null,
  inscricaoMunicipal: null,
  justificativaAusenciaDocumento: null,
});
const formRules = reactive({
  nome: [{ required: true, message: 'Razão social é obrigatório' }],
});

const carregarAsync = async () => {
  await pessoaQueryAsync({
    codigo: props.pessoaCodigo,
  });

  if (pessoaQueryStatus.value === 'success') {
    state.nome = pessoaQueryData.value.pessoa.nome;
    state.nomeFantasia = pessoaQueryData.value.pessoa.nomeFantasia;
    state.documento = pessoaQueryData.value.pessoa.documento;
    state.inscricaoEstadual = pessoaQueryData.value.pessoa.inscricaoEstadual;
    state.inscricaoMunicipal = pessoaQueryData.value.pessoa.inscricaoMunicipal;
    state.justificativaAusenciaDocumento =
      pessoaQueryData.value.pessoa.justificativaAusenciaDocumento;
  }

  state.status = pessoaQueryStatus.value;
};

const salvarAsync = async (fromModal = false) => {
  formRef.value.validate().then(async () => {
    if (!fromModal && isNullOrEmpty(state.documento)) {
      justificarAusenciaCnpjModalVisible.value = true;
      return;
    }

    await salvarPessoaAsync({
      pessoaCodigo: state.pessoaCodigo,
      tipo: state.tipo,
      nome: state.nome,
      nomeFantasia: state.nomeFantasia,
      documento: state.documento,
      inscricaoEstadual: state.inscricaoEstadual,
      inscricaoMunicipal: state.inscricaoMunicipal,
      justificativaAusenciaDocumento: state.justificativaAusenciaDocumento,
    });

    if (salvarPessoaStatus.value === 'error') {
      notification.error({
        message: salvarPessoaErr.value.message,
        description: salvarPessoaErr.value.description,
        duration: 5,
      });
    }

    justificarAusenciaCnpjModalVisible.value = false;
  });

  return true;
};

onMounted(async () => {
  state.status = 'loading';
  if (props.pessoaCodigo) {
    await carregarAsync();
  } else {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          {{
            props.pessoaCodigo === null
              ? 'ADICIONAR PESSOA JURÍDICA'
              : `EDITAR PESSOA JURÍDICA`
          }}
        </span>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="state.status === 'error'"
          :tentar-novamente-fn="carregarAsync"
        />
        <div v-else-if="state.status === 'success'">
          <a-result
            v-if="salvarPessoaStatus === 'success'"
            status="success"
            title="Pessoa jurídica salva com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('success')"> Fechar </a-button>
            </template>
          </a-result>
          <div v-else>
            <a-form
              ref="formRef"
              layout="vertical"
              :model="state"
              :rules="formRules"
            >
              <a-form-item label="Razão social" name="nome">
                <a-input v-model:value="state.nome" />
              </a-form-item>

              <a-form-item label="CNPJ" name="documento">
                <a-input
                  v-model:value="state.documento"
                  v-maska="'##.###.###/####-##'"
                />
              </a-form-item>

              <a-form-item label="Nome fantasia" name="nomeFantasia">
                <a-input v-model:value="state.nomeFantasia" />
              </a-form-item>

              <a-row :gutter="8">
                <a-col span="24">
                  <a-form-item
                    label="Inscrição estadual"
                    name="inscricaoEstadual"
                  >
                    <a-input v-model:value="state.inscricaoEstadual" />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-row :gutter="8">
                <a-col span="24">
                  <a-form-item
                    label="Inscrição municipal"
                    name="inscricaoMunicipal"
                  >
                    <a-input v-model:value="state.inscricaoMunicipal" />
                  </a-form-item>
                </a-col>
              </a-row>

              <a-form-item>
                <a-button
                  :loading="salvarPessoaStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="() => salvarAsync()"
                >
                  Salvar
                </a-button>
                <a-button
                  style="margin-right: 8px"
                  @click="() => emit('close')"
                >
                  Fechar
                </a-button>
              </a-form-item>
            </a-form>
            <a-modal
              v-model:visible="justificarAusenciaCnpjModalVisible"
              title="Justifique a ausência do CNPJ (obrigatório)"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="state.salvarStatus === 'loading'"
              :ok-button-props="{
                disabled: !state.justificativaAusenciaDocumento?.length,
              }"
              @ok="() => salvarAsync(true)"
              @cancel="() => (state.justificativaAusenciaDocumento = null)"
            >
              <a-textarea
                v-model:value="state.justificativaAusenciaDocumento"
                :rows="4"
                placeholder="Escreva sua justificativa para ausência do CNPJ"
              />
            </a-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
