<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import PERFIL_ACESSO from '~/perfilAcesso';

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const tableRef = ref(null);

function abrirSalvarUsuarioDrawer()
{
  drawerStore.push2({
    componentName: 'SalvarUsuarioForm',
    params: {},
    width: 600,
    onClose: async () =>
    {
      await tableRef.value?.reload();
    },
  });
}
</script>

<template>
  <Page menu="usuarios">
    <div class="usuario-page">
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/usuarios">
                    Usuários
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar usuários
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container">
                <a-button
                  v-if="
                    authStore.temPerfilAcesso(PERFIL_ACESSO.Administrador)
                      || authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                  "
                  type="primary"
                  size="large"
                  @click="() => abrirSalvarUsuarioDrawer()"
                >
                  <template #icon>
                    <PlusOutlined />
                  </template>Adicionar usuário
                </a-button>
              </div>
            </a-col>
          </a-row>
          <!-- /Page header -->

          <UsuarioGqlDatatable
            ref="tableRef"
            style="margin-top: 24px"
          />
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
