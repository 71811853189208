import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

export async function refreshToken() {
  const response = await axios.post(
    `${API_URL}/autenticacao/refresh-token`,
    {},
    {
      withCredentials: true,
      timeout: 120000,
    },
  );
  return response.data;
}
