<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import { formatCnj, onlyNumbers } from '~/utils';
import publicacaoNumeroProcessoDatatable from '~/graphql/publicacaoNumeroProcessoDatatable.graphql';

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataMovimento: 'DESC' }] };
  },
});

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Código',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroPRocesso'))
{
  filters.push({
    key: 'numeroProcesso',
    label: 'Número de processo',
    path: 'numeroProcesso',
    type: 'text-multiple',
    queryKey: 'n',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números de processo',
    },
    parseValue: (value: any) =>
    {
      return value.split(',').map((x: any) => onlyNumbers(x.trim()));
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataMovimento'))
{
  filters.push({
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      size: 'large',
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
    key: 'dataMovimento',
    label: 'Data de movimentação',
    path: 'publicacao.dataMovimento',
    inWhereClause: true,
    queryKey: 'd',
    type: 'date',
  });
}

const colunas = [
  {
    dataIndex: 'numeroProcesso',
    key: 'numeroProcesso',
    title: 'NÚMERO DO PROCESSO',
    width: 250,
  },
  {
    dataIndex: ['publicacao', 'dataMovimentoText'],
    key: 'dataMovimento',
    sortBy: ['publicacao', 'dataMovimento'],
    sorter: true,
    title: 'DATA DISPONIBILIZAÇÃO',
    width: 220,
  },
  {
    dataIndex: ['publicacao', 'diario'],
    key: 'diario',
    title: 'DIÁRIO',
    width: 250,
  },
  {
    dataIndex: ['publicacao', 'caderno'],
    key: 'caderno',
    title: 'CADERNO',
    width: 250,
  },
  {
    dataIndex: ['publicacao', 'pagina'],
    key: 'pagina',
    title: 'PÁGINA',
    width: 50,
  },
  {
    key: 'conteudo',
    title: 'CONTEÚDO',
  },
] as GraphqlDatatableColumn[];
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="publicacaoNumeroProcessoDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="props.keepHistory"
    :filters="filters"
  >
    <template #bodyCell="{ column, record }">
      <slot name="bodyCell" :column="column" :record="record">
        <template v-if="column.key === 'numeroProcesso'">
          <a
            href="#"
            style="text-transform: uppercase"
          >{{ formatCnj(record.numeroProcesso) }}</a>
        </template>
        <template v-if="column.key === 'dataMovimento'">
          <span style="text-transform: uppercase">{{
            record.publicacao.dataMovimentoText
          }}</span>
        </template>
        <template v-if="column.key === 'conteudo'">
          <a-typography-paragraph
            :ellipsis="{ rows: 2, expandable: true }"
            :content="record.publicacao?.conteudo"
            style="
              margin-bottom: 0;
              white-space: pre-wrap;
              color: #389393;
              cursor: pointer;
            "
          />
        </template>
      </slot>
    </template>
  </GqlDatatable>
</template>
