<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <SolicitarAjuizamentoViewV2
    v-if="'versao' in props.compromisso.data"
    :compromisso="props.compromisso"
  />
  <SolicitarAjuizamentoViewV1 v-else :compromisso="props.compromisso" />
</template>
