<script setup>
import { onMounted, onUnmounted, ref } from 'vue';
import axios from 'axios';
import { filtrarSelectOptions } from '~/utils';

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  value: {
    type: Object,
    default: null,
  },
  placeholder: {
    type: String,
    default: 'Selecione...',
  },
  mode: {
    type: String,
    default: null,
  },
  emptyMessage: {
    type: String,
    default: 'Nenhum dado encontrado',
  },
  valueIsNumber: {
    type: Boolean,
    default: false,
  },
  allowClear: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:value', 'change']);

const items = ref([]);
const carregarStatus = ref(null);

const obter = async () => {
  carregarStatus.value = 'loading';
  try {
    const response = await axios.get(props.url, {
      timeout: 120000,
      getAccessToken: true,
    });

    items.value = response.data.items.map((item) => {
      const value = props.valueIsNumber ? parseInt(item.value, 10) : item.value;
      return {
        value,
        label: item.label.toUpperCase(),
      };
    });
  } finally {
    carregarStatus.value = 'success';
  }
};

const onChange = async (option, value) => {
  const val = value === undefined ? null : value;
  emit('update:value', val);
  emit('change', val);
};

const onFocus = async () => {
  if (items.value.length === 0) {
    await obter();
  }
};

onMounted(() => {
  items.value = [];
});

onUnmounted(() => {
  items.value = [];
});
</script>

<template>
  <div>
    <a-spin :spinning="carregarStatus === 'loading'" size="small">
      <a-select
        :disabled="props.disabled"
        :allow-clear="props.allowClear"
        :value="value"
        :label-in-value="true"
        :placeholder="placeholder"
        :filter-option="filtrarSelectOptions"
        :options="items"
        :dropdown-class-name="
          carregarStatus === 'loading' ? 'ant-select-dropdown-loading' : ''
        "
        :mode="mode"
        :default-active-first-option="false"
        show-search
        style="width: 100%"
        @change="onChange"
        @focus="onFocus"
      >
        <template v-if="carregarStatus !== 'loading'" #notFoundContent>
          <Empty :description="emptyMessage" />
        </template>
      </a-select>
    </a-spin>
  </div>
</template>
