<script setup>
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: reiterarEncerramentoData,
  err: reiterarEncerramentoErr,
  runAsync: reiterarEncerramentoAsync,
  status: reiterarEncerramentoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const {
  err: devolverCompromissoErr,
  runAsync: devolverCompromissoAsync,
  status: devolverCompromissoStatus,
} = useDevolverCompromisso();

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const authStore = useAuthStore();
const devolverModalVisible = ref(false);
const formModel = reactive({
  justificativaDevolucao: null,
});

async function uiSalvarAsync()
{
  jobStatus.value = 'loading';

  await reiterarEncerramentoAsync({
    compromissoCodigos:
      props.compromisso !== null ? [props.compromisso.codigo] : null,
  });

  if (reiterarEncerramentoStatus.value === 'error')
  {
    jobStatus.value = 'error';
    notification.error({
      description: reiterarEncerramentoErr.value.description,
      duration: 5,
      message: reiterarEncerramentoErr.value.message,
    });
    emit('error');
    return;
  }

  const jobCodigo = reiterarEncerramentoData.value;

  await jobPoolingAsync({ codigo: jobCodigo });
}

async function uiDevolverAsync()
{
  await devolverCompromissoAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverCompromissoStatus.value === 'error')
  {
    notification.error({
      description: devolverCompromissoErr.value.description,
      duration: 5,
      message: devolverCompromissoErr.value.message,
    });
    emit('error');
    return;
  }
  emit('success');
}

watch(jobPoolingStatus, (val) =>
{
  jobStatus.value = val;
  if (val === 'error')
  {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage
        ?? 'Tivemos um problema inesperado',
    });
  }
  if (val === 'success')
  {
    emit('success');
  }
});

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-row>
      <a-col
        :xs="24"
        :md="24"
        :lg="24"
        :xl="24"
      >
        <a-result
          v-if="jobStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverCompromissoStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')">
              Fechar
            </a-button>
          </template>
        </a-result>

        <a-row
          v-if="
            jobStatus !== 'success' && devolverCompromissoStatus !== 'success'
          "
          :gutter="24"
        >
          <a-col :span="24">
            <a-row>
              <a-col :span="24">
                <a-alert type="warning">
                  <template #message>
                    Caso você tenha reiterado a atualização do sistema GCPJ,
                    clique no botão de confirmação
                  </template>
                </a-alert>
              </a-col>
            </a-row>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverCompromissoStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== authStore.codigoUsuario
                  "
                  :loading="jobStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Confirmar
                </a-button>
                <a-button @click="() => emit('close')">
                  Fechar
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
