<script setup>
import { formatCpfOuCnpj, isNilOrEmpty } from '~/utils';

const props = defineProps({
  pessoa: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <div>
    <a-descriptions
      bordered
      style="margin-top: 24px"
      :label-style="{ width: '10%' }"
      :column="1"
    >
      <a-descriptions-item label="Razão social">
        {{ props.pessoa.nome }}
      </a-descriptions-item>
      <a-descriptions-item label="CNPJ">
        {{ formatCpfOuCnpj(props.pessoa.documento) }}
      </a-descriptions-item>

      <a-descriptions-item label="Nome fantasia">
        {{ props.pessoa.nomeFantasia }}
      </a-descriptions-item>
      <a-descriptions-item label="Inscrição estadual">
        {{ props.pessoa.inscricaoEstadual }}
      </a-descriptions-item>
      <a-descriptions-item label="Inscrição municipal">
        {{ props.pessoa.inscricaoMunicipal }}
      </a-descriptions-item>

      <a-descriptions-item label="Criado por">
        <a-tooltip v-if="!isNilOrEmpty(props.pessoa.criadoPor)">
          <template #title> Ir para página do criador </template>
          <router-link
            :to="`/usuario/${props.pessoa.criadoPor.codigo}`"
            style="text-transform: uppercase"
          >
            {{ props.pessoa.criadoPor.nome }}
          </router-link>
        </a-tooltip>
        <span v-else>SISTEMA</span>
      </a-descriptions-item>
      <a-descriptions-item label="Data criação">
        {{ props.pessoa.dataCriacaoText }}
      </a-descriptions-item>
      <a-descriptions-item
        v-if="!isNilOrEmpty(props.pessoa.justificativaAusenciaDocumento)"
        label="Justificativa para ausência do CNPJ"
      >
        {{ props.pessoa.justificativaAusenciaDocumento }}
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>
