<script setup>
import { onMounted, reactive } from 'vue';
import { UnorderedListOutlined } from '@ant-design/icons-vue';
import { useRoute } from 'vue-router';
import { useDrawerStore } from '~/stores/drawer';
import { useAuthStore } from '~/stores/auth';
import { useGraphQL } from '~/composables/useGraphQL';
import EnumPerfil from '~/enums/EnumPerfil';

const drawerStore = useDrawerStore();
const authStore = useAuthStore();
const route = useRoute();
const { codigo } = route.params;

const {
  status: agenciaQueryStatus,
  data: agenciaQueryData,
  runAsync: agenciaQueryAsync,
} = useGraphQL({
  query: 'AgenciaQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const state = reactive({
  status: 'loading',
  agencia: null,
});

const uiAgenciaQueryAsync = async () => {
  state.status = 'loading';

  await agenciaQueryAsync({
    codigo: codigo,
  });

  if (agenciaQueryStatus.value === 'success') {
    state.agencia = agenciaQueryData.value.agencia;
  }

  document.title = `Agência: ${state.agencia.numero}/${state.agencia.nome}`;

  state.status = agenciaQueryStatus.value;
};

const abrirSalvarAgenciaDrawer = () => {
  drawerStore.push2({
    componentName: 'SalvarAgenciaForm',
    params: { codigo: codigo },
    width: 600,
    onClose: async () => {
      await uiAgenciaQueryAsync();
    },
  });
};

onMounted(async () => {
  await uiAgenciaQueryAsync();
});
</script>

<template>
  <Page menu="agencias">
    <div>
      <a-row>
        <a-col :xs="24">
          <div>
            <!-- Page header -->
            <a-row>
              <a-col :span="12">
                <a-breadcrumb>
                  <a-breadcrumb-item>
                    <router-link to="/agencias"> Agências </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>Visualizar</a-breadcrumb-item>
                </a-breadcrumb>
                <a-skeleton
                  :loading="state.status === 'loading'"
                  :active="true"
                  :paragraph="false"
                >
                  <h1 v-if="state.status === 'success'">
                    <UnorderedListOutlined
                      style="color: #bebcbc; font-size: 18px"
                    />
                    {{ state.agencia.numero }} / {{ state.agencia.nome }}
                  </h1>
                </a-skeleton>
              </a-col>
              <a-col :span="12">
                <div
                  v-if="state.status === 'success'"
                  class="page-header-button-container"
                >
                  <a-button
                    v-if="authStore.temPerfilAcesso(EnumPerfil.EditarAgencia)"
                    style="margin-right: 8px"
                    @click="() => abrirSalvarAgenciaDrawer()"
                  >
                    Editar
                  </a-button>
                </div>
              </a-col>
            </a-row>
            <!-- /Page header -->
            <div style="margin-top: 24px">
              <div v-if="state.status === 'loading'">
                <a-card>
                  <a-skeleton :loading="true" :active="true" />
                </a-card>
              </div>
              <div v-else>
                <ErrorResult v-if="state.status === 'error'" />
                <div v-else>
                  <a-descriptions
                    :label-style="{ width: '10%' }"
                    :column="1"
                    bordered
                  >
                    <a-descriptions-item label="Número">
                      {{ state.agencia.numero }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Nome">
                      {{ state.agencia.nome }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Regional">
                      {{ state.agencia.regionalText }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Porte">
                      {{ state.agencia.porteText }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Gerência">
                      {{ state.agencia.gerenciaRegional?.nome }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Diretoria">
                      {{
                        state.agencia.gerenciaRegional?.diretoriaRegional.nome
                      }}
                    </a-descriptions-item>
                    <a-descriptions-item label="Estado">
                      {{ state.agencia.estado?.nome }}
                    </a-descriptions-item>
                  </a-descriptions>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
