<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import usuarioDatatable from '~/graphql/usuarioDatatable.graphql';
import tipoPerfilSelect from '~/graphql/tipoPerfilSelect.graphql';

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ nome: 'ASC' }] };
  },
  enableRowSelection: false,
});

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
  enableRowSelection?: boolean
}

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Código',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'perfisAcesso'))
{
  filters.push({
    key: 'perfisAcesso',
    label: 'Perfis de acesso',
    path: 'perfisAcesso.some.perfil',
    type: 'gql-select',
    queryKey: 'pa',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais perfis de acesso',
      query: tipoPerfilSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'ativo'))
{
  filters.push({
    key: 'ativo',
    label: 'Ativo',
    path: 'ativo',
    type: 'boolean',
    queryKey: 'a',
    inWhereClause: true,
  });
}

const colunas = [
  {
    key: 'nome',
    title: 'USUÁRIO',
    dataIndex: 'nome',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    key: 'status',
    title: 'STATUS',
  },
  {
    key: 'gerente',
    title: 'GERENTE',
  },
  {
    key: 'equipes',
    title: 'EQUIPE',
  },
  {
    key: 'dataAcesso',
    title: 'ÚLTIMO ACESSO',
    dataIndex: 'dataAcessoText',
    sorter: true,
  },
] as GraphqlDatatableColumn[];

defineExpose({
  reload: async () =>
  {
    if (tableRef.value)
    {
      await tableRef.value?.reloadAsync();
    }
  },
});
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="usuarioDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="props.keepHistory"
    :filters="filters"
    :text-search="{ placeholder: 'Buscar usuários por nome' }"
    row-key="codigo"
    :page-size-options="['10', '20', '30', '40', '50']"
  >
    <template #nomeColumn="{ record }">
      <a-tooltip>
        <template #title>
          Ir para página do usuário
        </template>
        <router-link
          :to="`/usuario/${record.codigo}`"
          style="text-transform: uppercase"
        >
          {{ record.nome }}
        </router-link>
      </a-tooltip>
    </template>
    <template #statusColumn="{ record }">
      <span v-if="record.ativo">ATIVO</span>
      <span v-else>BLOQUEADO</span>
    </template>
    <template #gerenteColumn="{ record }">
      {{ record.gerentes.map((x: any) => x.nome.toUpperCase()).join(', ') }}
    </template>
    <template #equipesColumn="{ record }">
      {{ record.equipes.map((x: any) => x.perfilText.toUpperCase()).join(', ') }}
    </template>
  </GqlDatatable>
</template>
