<script setup lang="ts">
import { onMounted, onUnmounted, reactive, toRaw, watch } from 'vue';
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import type { SizeType } from 'ant-design-vue/es/config-provider';
import { isArray } from 'lodash-es';
import { filtrarSelectOptions } from '~/shared/utils/helpers';

const props = withDefaults(defineProps<Props>(), {
  emptyMessage: 'Nenhum dado encontrado',
  placeholder: 'Selecione...',
});
const emit = defineEmits(['update:value', 'change']);

interface Props
{
  query: DocumentNode
  allowClear?: boolean
  containerStyle?: any
  disabled?: boolean
  emptyMessage?: string | null
  mode?: 'multiple' | 'tags' | undefined
  placeholder?: string | null
  size?: SizeType
  value: any
  valueIsNumber?: boolean
  variables?: any
}

interface State
{
  items: any[]
}

const state = reactive<State>({
  items: [],
});

const { result, loading, refetch, load } = useLazyQuery(
  props.query,
  props.variables,
  {
    fetchPolicy: 'no-cache',
  },
);

async function reloadAsync()
{
  await refetch();
  emit('update:value', null);
  emit('change', null, toRaw(state.items));
}

async function reset()
{
  state.items = [];
  emit('update:value', null);
  emit('change', null, toRaw(state.items));
}

async function onChange(value: any)
{
  const val = value === undefined ? null : value;
  emit('update:value', val);
  emit('change', val, toRaw(state.items));
}

async function onFocus()
{
  if (state.items?.length === 0)
  {
    await load(props.query, props.variables);
  }
}

function clearItems()
{
  state.items = [];
}

function getItems()
{
  return toRaw(state.items);
}

watch(() => result.value, (val) =>
{
  if (val && isArray(val.data))
  {
    state.items = val.data.map((x: any) => ({ ...x, ...{ label: x.label?.toUpperCase() } }));
  }
});

onMounted(() =>
{
  state.items = [];
});

onUnmounted(() =>
{
  state.items = [];
});

defineExpose({
  clearItems,
  getItems,
  reloadAsync,
  reset,
});
</script>

<template>
  <div :style="props.containerStyle">
    <a-spin
      :spinning="loading"
      size="small"
    >
      <a-select
        :allow-clear="props.allowClear"
        :disabled="props.disabled"
        :value="props.value"
        :label-in-value="true"
        :placeholder="props.placeholder"
        :filter-option="filtrarSelectOptions"
        :options="state.items"
        :dropdown-class-name="
          loading ? 'ant-select-dropdown-loading' : ''
        "
        :mode="props.mode"
        :default-active-first-option="false"
        :size="props.size"
        :dropdown-style="loading ? { display: 'none' } : {}"
        show-search
        style="width: 100%"
        @change="onChange"
        @focus="onFocus"
      >
        <template #notFoundContent>
          <a-empty
            v-if="!loading"
            :description="emptyMessage"
          />
        </template>
      </a-select>
    </a-spin>
  </div>
</template>
