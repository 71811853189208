<script setup>
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import { computed, reactive, ref } from 'vue';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const props = defineProps({
  codigoPasta: {
    default: null,
    type: String,
  },
  codigosPastas: {
    default: () => null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
  publicacaoNumeroProcessoCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: criarCompromissoPubErr,
  runAsync: criarCompromissoPubAsync,
  status: criarCompromissoPubStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/fluxo/compromisso-pub/criar-compromisso-pub`,
});

const pastaCodigos = computed(() =>
{
  if (props.codigoPasta !== null)
  {
    return [props.codigoPasta];
  }

  if (props.pastaCodigos !== null)
  {
    return props.pastaCodigos;
  }

  return null;
});

const state = reactive({
  arquivos: [],
  dataPrazo: null,
  descricao: null,
  responsavel: null,
  tipo: null,
});

const mostrarHistoricoSolicitacoes = ref('h');
const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
];

const authStore = useAuthStore();
const formRef = ref(null);
const formRules = reactive({
  dataPrazo: [{ message: 'Prazo é obrigatório', required: true }],
  descricao: [
    {
      message: 'Descrição é obrigatório',
      required: true,
    },
  ],
  responsavel: [
    {
      message: 'Responsável é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
  tipo: [
    {
      message: 'Tipo do compromisso é obrigatório',
      required: true,
      trigger: 'change',
      type: 'object',
    },
  ],
});
const uploaderRef = ref(null);
const uploaderStatus = ref(null);
function disabledDate(current)
{
  return (
    current
    && (current.day() === 0
    || current.day() === 6
    || current < dayjs().endOf('day'))
  );
}

async function uiSalvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    await uploaderRef.value.upload();

    await criarCompromissoPubAsync({
      arquivos: state.arquivos,
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      dataPrazo: state.dataPrazo,
      descricao: state.descricao,
      pastaCodigos: pastaCodigos.value,
      publicacaoNumeroProcessoCodigos: props.publicacaoNumeroProcessoCodigos,
      responsavelCodigo: state.responsavel?.value,
      tipo: state.tipo?.value,
    });

    if (criarCompromissoPubStatus.value === 'error')
    {
      notification.error({
        duration: 0,
        message: criarCompromissoPubErr.value.message,
      });
    }
  });
}
</script>

<template>
  <a-result
    v-if="criarCompromissoPubStatus === 'success'"
    status="success"
    title="Registro realizado com sucesso"
    sub-title="Agora você pode fechar essa janela"
  >
    <template #extra>
      <a-button @click="() => emit('close')">
        Fechar
      </a-button>
    </template>
  </a-result>

  <div v-if="criarCompromissoPubStatus !== 'success'">
    <a-alert
      v-if="
        pastaCodigos?.length > 1 || publicacaoNumeroProcessoCodigos?.length > 1
      "
      type="warning"
      message="Você irá realizar uma alteração em lote"
      show-icon
      style="margin-bottom: 24px"
    />

    <a-collapse
      v-if="props.codigoPasta !== null"
      v-model:activeKey="mostrarHistoricoSolicitacoes"
    >
      <a-collapse-panel key="h" header="HISTÓRICO DE SOLICITAÇÕES">
        <CompromissoGqlDatatable
          :columns="colunas"
          :show-dates-filter="false"
          :show-reload-button="false"
          :show-status-search-bar="false"
          :show-filter="false"
          :variables="{
            order: [{ dataInicio: 'DESC' }],
            where: {
              tipo: {
                eq: 59,
              },
              fluxo: {
                pastaCodigo: {
                  in: [props.codigoPasta],
                },
              },
            },
          }"
        />
      </a-collapse-panel>
    </a-collapse>

    <a-form
      ref="formRef"
      layout="vertical"
      :model="state"
      :rules="formRules"
      style="margin-top: 16px"
    >
      <a-form-item label="Tipo" name="tipo">
        <AjaxSelect
          v-model:value="state.tipo"
          :url="`${API_URL}/ajax-select/tipo-compromisso-pub`"
          :value-is-number="true"
        />
      </a-form-item>

      <a-form-item
        v-if="publicacaoNumeroProcessoCodigos?.length > 0"
        name="responsavel"
      >
        <template #label>
          <slot name="formItemLabel">
            Responsável
          </slot>
        </template>
        <GraphQlSelect
          v-model:value="state.responsavel"
          query="USUARIO_SELECT"
          :variables="{
            where: {
              ativo: {
                eq: true,
              },
            },
            order: [
              {
                nome: 'ASC',
              },
            ],
          }"
          placeholder="Selecione o responsável"
          :allow-clear="true"
        />
      </a-form-item>

      <a-form-item
        v-if="!publicacaoNumeroProcessoCodigos?.length > 0"
        label="Descrição"
        name="descricao"
      >
        <a-textarea v-model:value="state.descricao" :rows="4" />
      </a-form-item>

      <a-form-item
        v-if="!publicacaoNumeroProcessoCodigos?.length > 0"
        label="Prazo"
        name="dataPrazo"
      >
        <a-date-picker
          v-model:value="state.dataPrazo"
          format="DD/MM/YYYY HH:mm"
          value-format="DD/MM/YYYY HH:mm"
          placeholder="Selecione uma data"
          style="width: 100%"
          :disabled-date="disabledDate"
          :show-time="{ defaultValue: dayjs('17:00:00', 'HH:mm:ss') }"
        />
      </a-form-item>

      <h3 style="margin-bottom: 24px">
        Anexar arquivos
      </h3>

      <a-row>
        <a-col :span="24">
          <Uploader
            ref="uploaderRef"
            v-model:fileList="state.arquivos"
            @start="() => (uploaderStatus = 'uploading')"
            @end="() => (uploaderStatus = 'done')"
          />
        </a-col>
      </a-row>

      <a-form-item style="margin-top: 24px; margin-bottom: 0px">
        <a-button
          :disabled="
            !isNilOrEmpty(compromisso)
              && compromisso.responsavel.codigo !== authStore.codigoUsuario
          "
          :loading="
            uploaderStatus === 'uploading'
              || criarCompromissoPubStatus === 'loading'
          "
          type="primary"
          style="margin-right: 8px; min-width: 100px"
          @click.prevent="uiSalvarAsync"
        >
          Salvar
        </a-button>
        <a-button @click="() => emit('close')">
          Fechar
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
