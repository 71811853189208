import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL;

export async function upload(file, chunkNumber, chunk) {
  const formData = new FormData();
  formData.append('uid', file.uid);
  formData.append('fileName', file.name);
  formData.append('fileType', file.type);
  formData.append('fileSize', file.size);
  formData.append('chunkNumber', chunkNumber);
  formData.append('chunk', chunk);
  return axios.post(`${API_URL}/uploader/upload`, formData, {
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function merge(file, totalChunks) {
  return axios.post(
    `${API_URL}/uploader/merge`,
    {
      uid: file.uid,
      fileName: file.name,
      fileType: file.type,
      fileSize: file.size,
      totalChunks,
    },
    {
      timeout: 0,
    }
  );
}
