<script setup>
import { onMounted, computed } from 'vue';
import { colors } from '~/utils';
import { useObterTotalPastasAtivasPorResponsavel } from '~/composables/pastas/useObterTotalPastasAtivasPorResponsavel';

const props = defineProps({
  titulo: {
    type: String,
    required: true,
  },
  gerencia: {
    type: Number,
    default: null,
  },
  tipoResponsavelPasta: {
    type: Number,
    default: null,
  },
});

const {
  status: obterTotalPastasAtivasPorResponsavelStatus,
  data: obterTotalPastasAtivasPorResponsavelData,
  runAsync: obterTotalPastasAtivasPorResponsavelAsync,
} = useObterTotalPastasAtivasPorResponsavel();

const chartOptions = computed(() => {
  if (obterTotalPastasAtivasPorResponsavelStatus.value !== 'success') {
    return null;
  }
  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors,
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    series: [
      {
        name: 'total de pastas',
        data: obterTotalPastasAtivasPorResponsavelData.value.resultados.map(
          (x) => x.total
        ),
      },
    ],
    xaxis: {
      categories: obterTotalPastasAtivasPorResponsavelData.value.resultados.map(
        (x) => x.nome
      ),
      labels: {
        show: false,
      },
    },
  };
});

onMounted(async () => {
  await obterTotalPastasAtivasPorResponsavelAsync({
    gerencia: props.gerencia,
    tipoResponsavelPasta: props.tipoResponsavelPasta,
  });
});
</script>

<template>
  <ChartCard
    :status="obterTotalPastasAtivasPorResponsavelStatus"
    :options="chartOptions"
  >
    <template #title>
      <span style="color: rgb(128 128 128); font-size: 16px">{{
        props.titulo
      }}</span>
    </template>
  </ChartCard>
</template>
