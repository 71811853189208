<script setup lang="ts">
import { ref } from 'vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import pessoaDatatable from '~/graphql/pessoaDatatable.graphql';
import tipoProcessoSelect from '~/graphql/tipoProcessoSelect.graphql';
import tipoPastaStatusSelect from '~/graphql/tipoPastaStatusSelect.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import pessoaSelect from '~/graphql/pessoaSelect.graphql';
import agenciaSelect from '~/graphql/agenciaSelect.graphql';
import tipoAgenciaRegionalSelect from '~/graphql/tipoAgenciaRegionalSelect.graphql';
import tipoAgenciaPorteSelect from '~/graphql/tipoAgenciaPorteSelect.graphql';
import { formatCpfOuCnpj, onlyNumbers } from '~/utils';

interface Props
{
  disabledFilters?: string[]
  variables?: any
  keepHistory?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ nome: 'ASC' }] };
  },
});

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});
const tableRef = ref<any>(null);

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Código',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'nome'))
{
  filters.push({
    key: 'nome',
    label: 'Nome',
    path: 'nome',
    type: 'text-multiple',
    queryKey: 'n',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais nomes',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'gcpj'))
{
  filters.push({
    key: 'gcpj',
    label: 'GCPJ',
    path: 'gcpj',
    type: 'text-multiple',
    queryKey: 'g',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais GCPJs',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroCnj'))
{
  filters.push({
    key: 'numeroCnj',
    label: 'Número CNJ',
    path: 'numeroCnj',
    type: 'text-multiple',
    queryKey: 'nc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números CNJs',
    },
    parseValue: (value: any) =>
    {
      return value.split(',').map((x: any) => onlyNumbers(x.trim()));
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroAntigo'))
{
  filters.push({
    key: 'numeroAntigo',
    label: 'Número antigo',
    path: 'numeroAntigo',
    type: 'text-multiple',
    queryKey: 'na',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números antigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'tipoProcesso'))
{
  filters.push({
    key: 'tipoProcesso',
    label: 'Tipo de processo',
    path: 'tipoProcesso',
    type: 'gql-select',
    queryKey: 'tp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais tipos de processo',
      query: tipoProcessoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'status'))
{
  filters.push({
    key: 'status',
    label: 'Status',
    path: 'status',
    type: 'gql-select',
    queryKey: 's',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais status',
      query: tipoPastaStatusSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelProcessual'))
{
  filters.push({
    key: 'responsavelProcessual',
    label: 'Responsável processual',
    path: 'responsavelProcessualCodigo',
    type: 'gql-select',
    queryKey: 'rp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelNegocial'))
{
  filters.push({
    key: 'responsavelNegocial',
    label: 'Responsável negocial',
    path: 'responsavelNegocialCodigo',
    type: 'gql-select',
    queryKey: 'rn',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelAcordo'))
{
  filters.push({
    key: 'responsavelAcordo',
    label: 'Responsável acordo',
    path: 'responsavelAcordoCodigo',
    type: 'gql-select',
    queryKey: 'ra',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais responsáveis',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'clientePrincipal'))
{
  filters.push({
    key: 'clientePrincipal',
    label: 'Cliente principal',
    path: 'clientePrincipalCodigo',
    type: 'gql-select',
    queryKey: 'clp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais pessoas',
      query: pessoaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'contrarioPrincipal'))
{
  filters.push({
    key: 'contrarioPrincipal',
    label: 'Contrário principal',
    path: 'contrarioPrincipalCodigo',
    type: 'gql-select',
    queryKey: 'cop',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais pessoas',
      query: pessoaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agencia'))
{
  filters.push({
    key: 'agencia',
    label: 'Agência',
    path: 'agencia2Codigo',
    type: 'gql-select',
    queryKey: 'ag',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais agências',
      query: agenciaSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agenciaRegional'))
{
  filters.push({
    key: 'agenciaRegional',
    label: 'Agência / Regional',
    path: 'agencia2.regional',
    type: 'gql-select',
    queryKey: 'agr',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione uma ou mais regionais',
      query: tipoAgenciaRegionalSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'agenciaPorte'))
{
  filters.push({
    key: 'agenciaPorte',
    label: 'Agência / Porte',
    path: 'agencia2.porte',
    type: 'gql-select',
    queryKey: 'agr',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais portes',
      query: tipoAgenciaPorteSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'tempoCadastroEmAnos'))
{
  filters.push({
    key: 'tempoCadastroEmAnos',
    label: 'Tempo de cadastro em anos',
    path: 'tempoCadastroEmAnos',
    type: 'text',
    queryKey: 'tca',
    inWhereClause: false,
    parseValue: (val: any) => Number.parseInt(val),
  });
}

if (!props.disabledFilters.find(x => x === 'tempoAjuizamentoEmAnos'))
{
  filters.push({
    key: 'tempoAjuizamentoEmAnos',
    label: 'Tempo de ajuizamento em anos',
    path: 'tempoAjuizamentoEmAnos',
    type: 'text',
    queryKey: 'taa',
    inWhereClause: false,
    parseValue: (val: any) => Number.parseInt(val),
  });
}

//
// if (!props.disabledFilters.find(x => x === 'dataConclusao'))
// {
//   filters.push({
//     key: 'dataConclusao',
//     label: 'Data de conclusão',
//     path: 'dataConclusao',
//     type: 'date',
//     queryKey: 'dc',
//     componentProps: {
//       format: 'DD/MM/YYYY',
//       placeholder: ['Inicial', 'Final'],
//       style: 'width: 100%',
//       valueFormat: 'DD/MM/YYYY',
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'responsavel'))
// {
//   filters.push({
//     key: 'responsavel',
//     label: 'Responsável pelo compromisso',
//     path: 'responsavelCodigo',
//     type: 'gql-select',
//     queryKey: 'r',
//     componentProps: {
//       placeholder: 'Selecione um ou mais responsáveis',
//       query: usuarioSelect,
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'tipoFluxo'))
// {
//   filters.push({
//     key: 'tipoFluxo',
//     label: 'Tipos de fluxo',
//     path: 'fluxo.tipo',
//     type: 'gql-select',
//     queryKey: 'tf',
//     componentProps: {
//       placeholder: 'Selecione um ou mais tipos de fluxo',
//       query: tipoFluxoSelect,
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'fluxoCodigo'))
// {
//   filters.push({
//     key: 'fluxoCodigo',
//     label: 'Códigos de fluxos',
//     path: 'fluxo.codigo',
//     type: 'text-multiple',
//     queryKey: 'fc',
//     componentProps: {
//       placeholder: 'Digite um ou mais códigos de fluxos',
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'pastaNome'))
// {
//   filters.push({
//     key: 'pastaNome',
//     label: 'Nomes de pastas',
//     path: 'fluxo.pasta.nome',
//     type: 'text-multiple',
//     queryKey: 'pn',
//     componentProps: {
//       placeholder: 'Digite um ou mais nomes',
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'pastaGcpj'))
// {
//   filters.push({
//     key: 'pastaGcpj',
//     label: 'GCPJs de pastas',
//     path: 'fluxo.pasta.gcpj',
//     type: 'text-multiple',
//     queryKey: 'pg',
//     componentProps: {
//       placeholder: 'Digite um ou mais GCPJs',
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'pastaNumeroCnj'))
// {
//   filters.push({
//     key: 'pastaNumeroCnj',
//     label: 'Número CNJ de pastas',
//     path: 'fluxo.pasta.numeroCnj',
//     type: 'text-multiple',
//     queryKey: 'pncnj',
//     componentProps: {
//       placeholder: 'Digite um ou mais números CNJ',
//     },
//   });
// }
//
// if (!props.disabledFilters.find(x => x === 'responsavelProcessual'))
// {
//   filters.push({
//     key: 'responsavelProcessual',
//     label: 'Responsável processual da pasta',
//     path: 'fluxo.pasta.responsaveis.some.usuarioCodigo',
//     filters: { 'fluxo.pasta.responsaveis.some.tipo.eq': 1 },
//     type: 'gql-select',
//     queryKey: 'rp',
//     componentProps: {
//       placeholder: 'Selecione um ou mais responsáveis',
//       query: usuarioSelect,
//     },
//   });
// }

const colunas = [
  {
    key: 'nome',
    title: 'NOME/RAZÃO SOCIAL',
    dataIndex: 'nome',
    sorter: true,
    defaultSortOrder: 'ascend',
  },
  {
    key: 'documento',
    title: 'CPF/CNPJ',
    dataIndex: 'documento',
  },
] as GraphqlDatatableColumn[];

defineExpose({
  reloadAsync: async () =>
  {
    await tableRef.value?.reloadAsync();
  },
});
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="pessoaDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :text-search="{ placeholder: 'Busca contatos por nome ou CPF/CNPJ' }"
    :keep-history="false"
  >
    <template #nomeColumn="{ record }">
      <router-link
        :to="`/contato/${record.codigo}`"
        style="text-transform: uppercase"
      >
        {{ record.nome }}
      </router-link>
    </template>

    <template #documentoColumn="{ record }">
      {{ formatCpfOuCnpj(record.documento) ?? '-' }}
    </template>
  </GqlDatatable>
</template>
