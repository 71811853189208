<script setup>
import { ref } from 'vue';
import { CheckOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  response: {
    type: Object,
    required: true,
  },
});

const columns = ref([
  {
    key: 'carteiraContrato',
    title: 'CARTEIRA/CONTRATO',
  },
  {
    key: 'vigente',
    title: 'VIGENTE',
    dataIndex: 'vigente',
  },
  {
    key: 'original',
    title: 'ORIGINAL',
    dataIndex: 'original',
  },
]);
</script>

<template>
  <div style="padding: 16px 0">
    <a-row style="padding-left: 24px">
      <a-col><h2>Carteiras/Contratos</h2></a-col>
    </a-row>
    <a-divider style="margin-top: 16px" />
    <div style="padding: 0 24px">
      <a-table
        :row-key="(_, index) => index"
        :data-source="props.response.carteirasContratos"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <template #bodyCell="{ record, column, text }">
          <span v-if="column.key === 'carteiraContrato'">
            {{ `${record.carteira.label} / ${record.contrato}` }}
          </span>
          <span v-if="column.key === 'original' || column.key === 'vigente'">
            <CheckOutlined v-if="text" style="margin-left: 24px" />
          </span>
        </template>
      </a-table>
    </div>
  </div>
</template>
