import { usePost } from '../usePost';
import { API_URL } from '~/utils';

export function useAlterarVencimentoCompromisso() {
  return usePost({
    url: `${API_URL}/compromissos/alterar-vencimento-compromisso`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}

export function useAlterarVencimentoCompromissoEmLote() {
  return usePost({
    url: `${API_URL}/compromissos/alterar-vencimento-compromisso/lote`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
