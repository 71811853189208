<script setup lang="ts">
import { ref } from 'vue';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';
import { useDrawerStore } from '~/stores/drawer';
import fluxosEspecificosDatatable from '~/graphql/fluxosEspecificosDatatable.graphql';

interface Props
{
  pastaCodigo?: string
}

const props = defineProps<Props>();

const drawerStore = useDrawerStore();

const columns = [
  {
    key: 'ocorrencia',
    title: 'OCORRÊNCIA',
    width: 220,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    key: 'data',
    title: 'DATA',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const gqlDatatable = ref<any>(null);

function abrirFluxo(codigoFluxo: string)
{
  drawerStore.push({
    componentName: 'FluxoView',
    params: { codigoFluxo },
    onClose: () =>
    {
      gqlDatatable.value?.reloadAsync();
    },
  });
}
</script>

<template>
  <GqlDatatable
    ref="gqlDatatable"
    :query="fluxosEspecificosDatatable"
    :columns="columns"
    :variables="{
      order: [{ dataInicio: 'DESC' }],
      where: {
        tipo: {
          eq: 39,
        },
        pastaCodigo: {
          eq: props.pastaCodigo,
        },
      },
    }"
  >
    <template #ocorrenciaColumn="{ record }">
      <a
        href="#"
        style="text-transform: uppercase"
        @click.prevent="() => abrirFluxo(record.codigo)"
      >{{ record.compromissos[0].data.tipoOcorrenciaAtendimentoLabel }}</a>
    </template>
    <template #responsavelColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.compromissos[0].responsavel.nome }}</span>
    </template>
    <template #dataColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.dataInicioText }}</span>
    </template>
  </GqlDatatable>
</template>
