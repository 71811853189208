<script setup lang="ts">
import { computed, ref } from 'vue';
import { CaretDownFilled } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import type {
  GraphqlDatatableColumn,
  GraphqlDatatableFilter,
} from '~/components/GqlDatatable.vue';
import EnumPerfil from '~/enums/EnumPerfil';
import fluxoDatatable from '~/graphql/fluxoDatatable.graphql';
import tipoFluxoSelect from '~/graphql/tipoFluxoSelect.graphql';
import tipoFluxoStatusSelect from '~/graphql/tipoFluxoStatusSelect.graphql';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import { useCancelarFluxo } from '~/composables/fluxos/useCancelarFluxo';
import EnumFluxoStatus from '~/enums/EnumFluxoStatus';
import { onlyNumbers } from '~/utils';

interface Props
{
  disabledFilters?: string[]
  variables?: any
  columns?: GraphqlDatatableColumn[]
}

const props = withDefaults(defineProps<Props>(), {
  disabledFilters: () => [],
  variables: () =>
  {
    return { order: [{ dataInicio: 'DESC' }] };
  },
});

const {
  status: cancelarFluxoStatus,
  err: cancelarFluxoErr,
  runAsync: cancelarFluxoAsync,
} = useCancelarFluxo();

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const gqlVariables = ref<any>(props.variables ? { ...props.variables } : {});

const colunas = props.columns ?? [
  {
    key: 'fluxo',
    title: 'FLUXO',
    width: 120,
  },
  {
    key: 'pasta',
    title: 'PASTA',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
    sorter: true,
    sortBy: ['status'],
  },
  {
    key: 'iniciadoPor',
    title: 'INICIADO POR',
    width: 120,
  },
  {
    key: 'dataInicio',
    title: 'INICIADO EM',
    dataIndex: 'dataInicioText',
    width: 120,
    sorter: true,
    defaultSortOrder: 'descend',
    sortBy: ['dataInicio'],
  },
  {
    key: 'dataConclusao',
    title: 'CONCLUÍDO EM',
    width: 120,
    dataIndex: 'dataConclusaoText',
    sorter: true,
    sortBy: ['dataConclusao'],
  },
] as GraphqlDatatableColumn[];

const tableRef = ref<any>(null);
const fluxosSelecionados = ref<any>([]);
const cancelarFluxoModalVisivel = ref<boolean>(false);
const justificativaCancelamento = ref<any>(null);

const rowSelection = {
  selectedRowKeys: fluxosSelecionados,
  onChange: onSelectChange,
};

const filters = [] as GraphqlDatatableFilter[];

if (!props.disabledFilters.find(x => x === 'codigo'))
{
  filters.push({
    key: 'codigo',
    label: 'Código',
    path: 'codigo',
    type: 'text-multiple',
    queryKey: 'c',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais códigos',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'tipo'))
{
  filters.push({
    key: 'tipo',
    label: 'Tipo',
    path: 'tipo',
    type: 'gql-select',
    queryKey: 't',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais tipos',
      query: tipoFluxoSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'status'))
{
  filters.push({
    key: 'status',
    label: 'Status',
    path: 'status',
    type: 'gql-select',
    queryKey: 's',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais statuses',
      query: tipoFluxoStatusSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataSolicitacao'))
{
  filters.push({
    key: 'dataInicio',
    label: 'Data de solicitação',
    path: 'dataInicio',
    type: 'date',
    queryKey: 'di',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'dataConclusao'))
{
  filters.push({
    key: 'dataConclusao',
    label: 'Data de conclusão',
    path: 'dataConclusao',
    type: 'date',
    queryKey: 'dc',
    inWhereClause: true,
    componentProps: {
      format: 'DD/MM/YYYY',
      placeholder: ['Inicial', 'Final'],
      style: 'width: 100%',
      valueFormat: 'DD/MM/YYYY',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'iniciadoPor'))
{
  filters.push({
    key: 'iniciadoPor',
    label: 'Iniciado por',
    path: 'iniciadoPorCodigo',
    type: 'gql-select',
    queryKey: 'ip',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais usuários',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'pasta'))
{
  filters.push({
    key: 'pasta',
    label: 'Pastas',
    path: 'pasta.nome',
    type: 'text-multiple',
    queryKey: 'p',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite uma ou mais pastas',
    },
  });
}

if (!props.disabledFilters.find(x => x === 'numeroCnj'))
{
  filters.push({
    key: 'numeroCnj',
    label: 'Número CNJ',
    path: 'pasta.numeroCnj',
    type: 'text-multiple',
    queryKey: 'nc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Digite um ou mais números CNJs',
    },
    parseValue: (value: any) =>
    {
      return value.split(',').map((x: any) => onlyNumbers(x.trim()));
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelProcessual'))
{
  filters.push({
    key: 'responsavelProcessual',
    label: 'Responsável processual',
    path: 'pasta.responsavelProcessualCodigo',
    type: 'gql-select',
    queryKey: 'rp',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais usuários',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsavelNegocial'))
{
  filters.push({
    key: 'responsavelNegocial',
    label: 'Responsável negocial',
    path: 'pasta.responsavelNegocialCodigo',
    type: 'gql-select',
    queryKey: 'rn',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais usuários',
      query: usuarioSelect,
    },
  });
}

if (!props.disabledFilters.find(x => x === 'responsaveisComCompromissos'))
{
  filters.push({
    key: 'responsaveisComCompromissos',
    label: 'Responsáveis com compromissos',
    path: 'compromissos.some.responsavelCodigo',
    type: 'gql-select',
    queryKey: 'rcc',
    inWhereClause: true,
    componentProps: {
      placeholder: 'Selecione um ou mais usuários',
      query: usuarioSelect,
    },
  });
}

const temFluxosNaoCancelaveisCmp = computed(() =>
{
  const fluxos = tableRef.value.getDataSource();
  const fluxoSelecionadosCodigos = fluxosSelecionados.value;

  const fluxosQueForamSelecionados = fluxos.filter((x: any) =>
    fluxoSelecionadosCodigos.includes(x.codigo),
  );

  const temFluxosNaoCancelaveis = fluxosQueForamSelecionados.some(
    (x: any) =>
      x.statusValue === EnumFluxoStatus.Cancelado
      || x.statusValue === EnumFluxoStatus.Concluido,
  );

  return fluxosQueForamSelecionados.length > 1 && temFluxosNaoCancelaveis;
});

const mostrarBotaoAcoes = computed(() =>
{
  return (
    fluxosSelecionados.value.length > 0
    && (authStore.temPerfilAcesso(EnumPerfil.CancelarFluxo)
    || authStore.temPerfilAcesso(EnumPerfil.FalharFluxo))
  );
});

function abrirFluxo(fluxoCodigo: any)
{
  drawerStore.push2({
    componentName: 'FluxoView',
    params: {
      codigoFluxo: fluxoCodigo,
    },
    onClose: async () =>
    {
      await tableRef.value?.reloadAsync();
    },
  });
}

function onSelectChange(keys: any)
{
  fluxosSelecionados.value = [...keys];
}

function acoesCompromissosSelecionadosOnClick({ key }: any)
{
  if (key === 'cancelar')
  {
    cancelarFluxoModalVisivel.value = true;
  }
}

async function uiCancelarFluxoAsync()
{
  await cancelarFluxoAsync({
    fluxoCodigos: fluxosSelecionados.value,
    justificativa: justificativaCancelamento.value,
  });

  justificativaCancelamento.value = null;
  cancelarFluxoModalVisivel.value = false;

  if (cancelarFluxoStatus.value === 'error')
  {
    notification.error({
      message: cancelarFluxoErr.value.error,
      duration: 5,
    });
  }
  else
  {
    notification.success({
      message: 'Fluxo cancelado com sucesso',
      duration: 5,
    });
  }

  await tableRef.value?.reloadAsync();
}
</script>

<template>
  <GqlDatatable
    ref="tableRef"
    :query="fluxoDatatable"
    :columns="colunas"
    :variables="gqlVariables"
    :keep-history="false"
    :row-selection="rowSelection"
    :filters="filters"
    row-key="codigo"
  >
    <template #leftTopActions>
      <a-row>
        <a-col
          v-if="mostrarBotaoAcoes"
          flex="100px"
        >
          <a-dropdown>
            <template #overlay>
              <a-menu @click="acoesCompromissosSelecionadosOnClick">
                <a-menu-item key="cancelar">
                  Cancelar
                </a-menu-item>
              </a-menu>
            </template>
            <a-button
              v-if="fluxosSelecionados.length > 0"
              size="large"
            >
              Ações <CaretDownFilled />
            </a-button>
          </a-dropdown>
        </a-col>
      </a-row>
    </template>
    <template #fluxoColumn="{ record }">
      <a
        href="#"
        style="text-transform: uppercase"
        @click.prevent="abrirFluxo(record.codigo)"
      >{{ record.tipoText }}</a>
    </template>
    <template #pastaColumn="{ record }">
      <router-link
        v-if="record.pasta"
        :to="`/pasta/${record.pasta.codigo}`"
      >
        {{ record.pasta.nome }}
      </router-link>
    </template>
    <template #statusColumn="{ record }">
      <span style="text-transform: uppercase">{{
        record.statusText
      }}</span>
    </template>
    <template #iniciadoPorColumn="{ record }">
      <span style="text-transform: uppercase">{{
        record.iniciadoPor?.nome
      }}</span>
    </template>
  </GqlDatatable>
  <a-modal
    v-model:visible="cancelarFluxoModalVisivel"
    title="Justifique o cancelamento do fluxo"
    cancel-text="Fechar"
    ok-text="Cancelar fluxo"
    :z-index="2000"
    :confirm-loading="cancelarFluxoStatus === 'loading'"
    :ok-button-props="{
      disabled: !justificativaCancelamento?.length,
    }"
    ok-type="danger"
    @ok="uiCancelarFluxoAsync"
    @cancel="() => (justificativaCancelamento = null)"
  >
    <a-alert
      v-if="temFluxosNaoCancelaveisCmp"
      type="warning"
      message="Sua seleção possui fluxos cancelados ou concluídos. Esses fluxos serão ignorados."
      show-icon
      style="margin-bottom: 24px"
    />
    <a-textarea
      v-model:value="justificativaCancelamento"
      :rows="4"
      placeholder="Escreva sua justificativa para o cancelamento"
    />
  </a-modal>
</template>
