<script setup>
import { ref } from 'vue';
import totalPublicacaoNumeroProcesso from '~/graphql/totalPublicacaoNumeroProcesso.graphql';
import { useDrawerStore } from '~/stores/drawer.js';

const props = defineProps({
  variables: {
    default: null,
    type: Object,
  },
  title: {
    type: String,
  },
});

const gqlStatsCardRef = ref(null);

const drawerStore = useDrawerStore();

function openDrawer()
{
  drawerStore.push2({
    componentName: 'PublicacaoNumeroProcessoGqlDatatable',
    onClose: async () =>
    {
      await gqlStatsCardRef.value?.reload();
    },
    params: {
      variables: {
        ...props.variables,
        ...{ keepHistory: false, order: [{
          publicacao: {
            dataMovimento: 'DESC',
          },
        }] },
      },
    },
    title: props.title,
    wrapperStyle: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  });
}
</script>

<template>
  <GqlStatsCard
    ref="gqlStatsCardRef"
    :title="props.title"
    :query="totalPublicacaoNumeroProcesso"
    :variables="props.variables"
    :clickable="true"
    @click="openDrawer"
  />
</template>
