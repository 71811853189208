<script setup>
const props = defineProps({
  componentName: {
    type: String,
    required: true,
  },
  componentProps: {
    type: Object,
    default: () => {},
  },
  codigoPasta: {
    type: String,
    default: null,
  },
  codigosPastas: {
    type: Array,
    default: () => null,
  },
  title: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          {{ title }}
        </span>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <component
        :is="componentName"
        v-bind="{
          ...props.componentProps,
          ...{
            codigoPasta: props.codigoPasta,
            codigosPastas: props.codigosPastas,
          },
        }"
        @close="() => emit('close')"
      />
    </div>
  </div>
</template>
