<script setup>
const props = defineProps({
  tentarNovamenteFn: {
    type: Function,
    default: null,
  },
});

const tentarNovamente =
  props.tentarNovamenteFn !== null
    ? props.tentarNovamenteFn
    : () => {
        window.location.reload();
      };
</script>

<template>
  <a-result
    status="500"
    title="Tivemos um problema inesperado"
    sub-title="Estamos trabalhando para tudo voltar ao normal"
  >
    <template #extra>
      <a-button @click="tentarNovamente"> Tentar novamente </a-button>
    </template>
  </a-result>
</template>
