<script setup>
import ptBr from 'ant-design-vue/es/locale/pt_BR';
import { useDrawerStore } from './stores/drawer';

const drawerStore = useDrawerStore();
</script>

<template>
  <a-config-provider
    :locale="ptBr"
    :theme="{
      token: {
        colorPrimary: '#389393',
        colorLink: '#389393',
        colorLinkActive: '#246b6e',
        colorLinkHover: '#57a19e',
      },
    }"
  >
    <template #renderEmpty>
      <Empty description="Nenhum dado encontrado" />
    </template>
    <router-view :key="$route.path" />

    <a-drawer	
      :open="drawerStore.drawers.length > 0"
      :width="
        drawerStore.drawers.length > 0 && drawerStore.drawers[0].width
          ? drawerStore.drawers[0].width
          : 1200
      "
      :destroy-on-close="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
    >
      <template v-if="drawerStore.drawers.length > 0" #default>
        <DrawerTitle
          v-if="drawerStore.drawers[0].title"
          @close="drawerStore.pop()"
        >
          <template #title>
            <span style="font-size: 16px; font-weight: 500; line-height: 35px">
              {{ drawerStore.drawers[0].title }}
            </span>
          </template>
        </DrawerTitle>

        <div :style="drawerStore.drawers[0].wrapperStyle">
          <component
            :is="drawerStore.drawers[0].componentName"
            v-bind="drawerStore.drawers[0].params"
            @close="() => drawerStore.pop()"
            @success="() => drawerStore.success()"
          />
        </div>
      </template>
    </a-drawer>

    <a-drawer
      :open="drawerStore.drawers.length > 1"
      :width="
        drawerStore.drawers.length > 1 && drawerStore.drawers[1].width
          ? drawerStore.drawers[1].width
          : 1100
      "
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
      @success="() => drawerStore.success()"
    >
      <component
        :is="drawerStore.drawers[1].componentName"
        v-if="drawerStore.drawers.length > 1"
        v-bind="drawerStore.drawers[1].params"
        @close="() => drawerStore.pop()"
        @success="() => drawerStore.success()"
      />
    </a-drawer>

    <a-drawer
      :open="drawerStore.drawers.length > 2"
      :width="
        drawerStore.drawers.length > 2 && drawerStore.drawers[2].width
          ? drawerStore.drawers[2].width
          : 1000
      "
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
      @success="() => drawerStore.success()"
    >
      <component
        :is="drawerStore.drawers[2].componentName"
        v-if="drawerStore.drawers.length > 2"
        v-bind="drawerStore.drawers[2].params"
        @close="() => drawerStore.pop()"
        @success="() => drawerStore.success()"
      />
    </a-drawer>

    <a-drawer
      :open="drawerStore.drawers.length > 3"
      :width="
        drawerStore.drawers.length > 3 && drawerStore.drawers[3].width
          ? drawerStore.drawers[3].width
          : 900
      "
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
      @success="() => drawerStore.success()"
    >
      <component
        :is="drawerStore.drawers[3].componentName"
        v-if="drawerStore.drawers.length > 3"
        v-bind="drawerStore.drawers[3].params"
        @close="() => drawerStore.pop()"
        @success="() => drawerStore.success()"
      />
    </a-drawer>

    <a-drawer
      :open="drawerStore.drawers.length > 4"
      :width="
        drawerStore.drawers.length > 4 && drawerStore.drawers[4].width
          ? drawerStore.drawers[4].width
          : 800
      "
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
    >
      <component
        :is="drawerStore.drawers[4].componentName"
        v-if="drawerStore.drawers.length > 4"
        v-bind="drawerStore.drawers[4].params"
        @close="() => drawerStore.pop()"
        @success="() => drawerStore.success()"
      />
    </a-drawer>

    <a-drawer
      :open="drawerStore.drawers.length > 5"
      :width="
        drawerStore.drawers.length > 5 && drawerStore.drawers[5].width
          ? drawerStore.drawers[5].width
          : 700
      "
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="false"
      :keyboard="false"
      @close="() => drawerStore.pop()"
      @success="() => drawerStore.success()"
    >
      <component
        :is="drawerStore.drawers[5].componentName"
        v-if="drawerStore.drawers.length > 5"
        v-bind="drawerStore.drawers[5].params"
        @close="() => drawerStore.pop()"
        @success="() => drawerStore.success()"
      />
    </a-drawer>
  </a-config-provider>
</template>

<style lang="less">
body {
  margin: 0;
  height: 100vh;
}

h1 {
  margin: 0;
  font-size: 1.7em;
  color: #000000d9;
  font-weight: 500;
}

h2 {
  margin: 0;
  color: #000000d9;
  font-weight: 500;
}

.ant-layout-content {
  height: 100%;
}

.ant-btn-primary {
  box-shadow: none;
}

#app {
  width: 100%;
  height: 100%;

  & h1 {
    font-size: 1.7em;
    margin-bottom: 0;
  }

  & h2 {
    margin-bottom: 0;
  }

  & .page-header-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    height: 100%;
  }

  & .ant-badge-status-text {
    font-size: 12px;
    text-transform: uppercase;
    color: #a9a9a9;
    font-weight: 500;
  }
}

.er-badge .ant-badge-status-text {
  font-size: 12px;
  text-transform: uppercase;
  color: #a9a9a9;
  font-weight: 500;
}

// .er-notification {
//   & .ant-notification-notice-with-icon .ant-notification-notice-message {
//     font-size: 14px;
//     margin-bottom: 0;
//   }
// }

.span-key {
  color: #858383;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

.span-value {
  color: rgb(99, 99, 99);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.ant-col.ant-form-item-label {
  padding-bottom: 4px;
}

.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.55);
}

.ant-statistic {
  & .ant-statistic-content {
    font-size: 18px;
  }
}

.ant-statistic.small {
  & .ant-statistic-content {
    font-size: 16px;
    font-weight: 500;
  }
}

.ant-form-vertical .ant-form-item {
  margin-bottom: 16px;
  padding-bottom: 0px;
}

.ant-result-extra {
  margin-top: 16px;
  text-align: center;
}

.ant-drawer-body {
  padding: 0;
}

.ant-select-dropdown-loading {
  display: none;
}

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
}

.ant-alert-info .ant-alert-icon {
  color: #1890ff;
}

.ant-upload-list.ant-upload-list-text {
  & .ant-upload-list-item-list-type-text {
    height: 100%;
  }

  & .anticon.anticon-paper-clip {
    font-size: 22px;
  }

  & .ant-upload-list-item-name {
    font-size: 18px;
    padding-left: 30px;
  }

  & .ant-upload-list-item-card-actions {
    font-size: 22px;
  }
}

.ant-descriptions-item-content > span {
  text-transform: uppercase;
}
</style>
