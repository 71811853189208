<script setup>
import {
  DownOutlined,
  FileTextOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { ref } from 'vue';
import FiltrosPastas from '../../../components/FiltrosPastas.vue';
import { useCriarJob } from '~/composables/jobs/useCriarJob';
import { usePost } from '~/composables/usePost';
import PERFIL_ACESSO from '~/perfilAcesso';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';

const authStore = useAuthStore();

const { runAsync: gerarRelatorioTodasPublicacoesAsync } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/gerar-relatorio-todas-publicacoes`,
});

const { runAsync: relatorioPastasAsync } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/relatorio-pastas`,
});

const { runAsync: relatorioIntegracaoDiscadorAsync } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/relatorio-integracao-discador`,
});

const { runAsync: gerarRelatorioDocumentosAnexadosAsync } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/gerar-relatorio-documentos-anexados`,
});

const { runAsync: relatorioPessoaEnderecoAsync } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/relatorio-pessoa-endereco`,
});

const { runAsync: relatorioAtualizacoesGcpjOystr } = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}/relatorios/relatorio-atualizacoes-gcpj-oystr`,
});

const { runAsync: criarJobAsync, status: criarJobStatus } = useCriarJob();

const filtrosPastasVisivel = ref(false);
const jobParametros = ref({});

const relatorioJobGraphqlDatatableRef = ref(null);

async function uiCriarJob(tipo)
{
  await criarJobAsync({
    parametros: jobParametros.value,
    tipo,
  });

  if (criarJobStatus.value === 'error')
  {
    notification.error({
      duration: 10,
      message:
        'Tivemos um problema para gerar o relatório, favor entrar em contato com o suporte',
    });
  }
  else if (criarJobStatus.value === 'success')
  {
    notification.success({
      duration: 5,
      message: 'Seu pedido para gerar o relatório foi feito com sucesso',
    });

    await relatorioJobGraphqlDatatableRef.value?.reloadAsync();
  }
}

async function uiGerarRelatorioPastasFiltradas()
{
  filtrosPastasVisivel.value = false;
  await uiCriarJob(14);
  jobParametros.value = {};
}

async function gerarRelatorioMenuClick({ key })
{
  if (key === 'relatorio-documentos-anexados')
  {
    await gerarRelatorioDocumentosAnexadosAsync();
    return;
  }

  if (key === 'relatorio-todas-publicacoes')
  {
    await gerarRelatorioTodasPublicacoesAsync();
    return;
  }

  if (key === 'relatorio-pastas')
  {
    await relatorioPastasAsync();
    return;
  }

  if (key === 'relatorio-integracao-discador')
  {
    await relatorioIntegracaoDiscadorAsync();
    return;
  }

  if (key === 'relatorio-pessoa-endereco')
  {
    await relatorioPessoaEnderecoAsync();
    return;
  }

  if (key === 'relatorio-atualizacoes-gcpj-oystr')
  {
    await relatorioAtualizacoesGcpjOystr();
    return;
  }

  const tipo = Number.parseInt(key, 10);
  jobParametros.value = {};

  if (tipo === 4)
  {
    // pastas ativas
    jobParametros.value = {
      status: [2],
    };
    await uiCriarJob(tipo);
  }
  else if (tipo === 14)
  {
    // pastas filtradas
    filtrosPastasVisivel.value = true;
  }
  else
  {
    await uiCriarJob(tipo);
  }
}

function onFiltrosPastasChange(filtros)
{
  jobParametros.value = {
    agencias: filtros.agencias.map(x => x.value),
    envolvidos: filtros.envolvidos.map(x => x.value),
    gcpj: filtros.gcpj,
    nome: filtros.nome,
    numeroCnj: filtros.numeroCnj,
    regionais: filtros.regionais.map(x => x.value),
    responsaveis: filtros.responsaveis.map(x => x.value),
    responsaveisNegociais: filtros.responsaveisNegociais.map(x => x.value),
    status: filtros.status.map(x => x.value),
    tempoAjuizamentoEmAnos: filtros.tempoAjuizamentoEmAnos,
    tempoCadastroEmAnos: filtros.tempoCadastroEmAnos,
    tiposProcessos: filtros.tiposProcessos.map(x => x.value),
  };
}
</script>

<template>
  <Page menu="relatorios">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/relatorios">
                    Relatórios
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar relatórios
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container" />
            </a-col>
          </a-row>
          <!-- /Page header -->

          <!-- Filtros -->
          <a-drawer
            v-model:visible="filtrosPastasVisivel"
            :destroy-on-close="true"
            :mask-closable="true"
            :closable="false"
            width="600"
          >
            <div style="padding-bottom: 24px">
              <DrawerTitle @close="() => (filtrosPastasVisivel = false)">
                <template #title>
                  <a-row>
                    <a-col :span="12">
                      <span
                        style="
                          font-size: 16px;
                          font-weight: 500;
                          line-height: 35px;
                        "
                      >
                        FILTROS</span>
                    </a-col>
                  </a-row>
                </template>
              </DrawerTitle>
              <FiltrosPastas
                :hide-responsaveis="authStore.ehSomenteAdvogado"
                @change="onFiltrosPastasChange"
              />
              <a-row>
                <a-col>
                  <a-button
                    :loading="criarJobStatus === 'loading'"
                    type="primary"
                    style="
                      margin-left: 24px;
                      margin-right: 8px;
                      min-width: 100px;
                    "
                    @click="() => uiGerarRelatorioPastasFiltradas()"
                  >
                    Gerar relatório
                  </a-button>
                  <a-button @click="() => (filtrosPastasVisivel = false)">
                    Fechar
                  </a-button>
                </a-col>
              </a-row>
            </div>
          </a-drawer>
          <!-- / Filtros -->

          <RelatorioJobGraphqlDatatable
            ref="relatorioJobGraphqlDatatableRef"
            :responsaveis-codigos="[authStore.codigoUsuario]"
          >
            <template #leftTopActions>
              <a-col flex="100px">
                <a-dropdown>
                  <template #overlay>
                    <a-menu @click="gerarRelatorioMenuClick">
                      <a-sub-menu
                        key="pastas"
                        title="Pastas"
                      >
                        <!-- <a-menu-item key="4">
                          Pastas ativas
                        </a-menu-item> -->
                        <!-- <a-menu-item key="5">
                          Todas as pastas
                        </a-menu-item> -->
                        <!-- <a-menu-item key="14">
                          Pastas filtradas
                        </a-menu-item><a-menu-item key="1">
                          Pastas com GCPJ desatualizado
                        </a-menu-item> -->
                        <a-menu-item key="6">
                          Carteira/contrato por pasta
                        </a-menu-item>
                        <a-menu-item key="relatorio-pastas">
                          Pastas completa
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        key="fluxos"
                        title="Fluxos"
                      >
                        <a-menu-item
                          v-if="
                            authStore.ehGerente
                              || authStore.ehGestor
                              || authStore.temPerfilAcesso(PERFIL_ACESSO.Cdi)
                          "
                          key="36"
                        >
                          CDI
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="42"
                        >
                          Encerramento
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="43"
                        >
                          Ocorrência de atendimento
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        key="contatos"
                        title="Contatos"
                      >
                        <a-menu-item key="26">
                          Todos contatos
                        </a-menu-item>
                        <a-menu-item key="relatorio-pessoa-endereco">
                          Endereços
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        key="caq"
                        title="CAQ"
                      >
                        <!-- <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="11"
                        >
                          CAQ - Pastas sem valor da causa
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="12"
                        >
                          CAQ - Pastas sem conta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="13"
                        >
                          CAQ - Pastas sem carteira/contrato
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="15"
                        >
                          CAQ - Pastas
                        </a-menu-item> -->
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="16"
                        >
                          CAQ - Usuários ativos
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="7"
                        >
                          CAQ - Última atualização GCPJ por pasta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="8"
                        >
                          CAQ - Últimos andamentos por pasta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="9"
                        >
                          CAQ - Últimas publicações por pasta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="10"
                        >
                          CAQ - Últimos comentários por pasta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="39"
                        >
                          CAQ - Todos andamentos
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="relatorio-todas-publicacoes"
                        >
                          CAQ - Todas publicações
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="41"
                        >
                          CAQ - Todos comentários
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="relatorio-documentos-anexados"
                        >
                          CAQ - Documentos anexados
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="21"
                        >
                          CAQ - Fluxo compromisso pub
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="22"
                        >
                          CAQ - Fluxo alteração pasta
                        </a-menu-item>
                        <a-menu-item
                          v-if="!authStore.ehSomenteAdvogado"
                          key="23"
                        >
                          CAQ - Fluxo compromisso CAQ
                        </a-menu-item>
                        <a-menu-item
                          v-if="
                            authStore.codigoUsuario
                              === '3cb8eef8-4d48-4101-9673-69ba55d734c5'
                              || authStore.codigoUsuario
                                === '01c27bf7-7367-4813-b237-138253c5689e'
                          "
                          key="24"
                        >
                          CAQ - Registro delegação
                        </a-menu-item>
                        <a-menu-item
                          v-if="
                            authStore.temPerfilAcesso(PERFIL_ACESSO.Caq)
                              || authStore.temPerfilAcesso(PERFIL_ACESSO.GerenteCaq)
                          "
                          key="25"
                        >
                          CAQ - Fluxo verificar publicação capturada
                        </a-menu-item>
                        <a-menu-item
                          v-if="
                            authStore.temPerfilAcesso(PERFIL_ACESSO.Caq)
                              || authStore.temPerfilAcesso(
                                PERFIL_ACESSO.GerenteCaq,
                              )
                              || authStore.ehGestor
                          "
                          key="37"
                        >
                          CAQ - Atualizações GCPJ
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        v-if="authStore.ehGestor"
                        key="deepCenter"
                        title="Deep Center"
                      >
                        <a-menu-item key="38">
                          Integração com Deep Center (CSV)
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        key="rcb"
                        title="RCB"
                      >
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="27"
                        >
                          Clientes RCB
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="28"
                        >
                          Clientes RCB - Telefone
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="29"
                        >
                          Clientes RCB - Email
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="30"
                        >
                          Clientes RCB - Endereço
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="31"
                        >
                          Dívida RCB
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="32"
                        >
                          Dívida RCB - Garantias/Autos
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="33"
                        >
                          Dívida RCB - Processos jurídicos
                        </a-menu-item>
                        <a-menu-item
                          v-if="authStore.ehGerente || authStore.ehGestor"
                          key="34"
                        >
                          Dívida RCB - Avalistas
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        key="discador"
                        title="Discador"
                      >
                        <a-menu-item key="relatorio-integracao-discador">
                          Integração com Discador
                        </a-menu-item>
                      </a-sub-menu>
                      <a-sub-menu
                        v-if="
                          authStore.temPerfilAcesso(
                            PERFIL_ACESSO.ExportarAtualizacoesGcpjOystr,
                          )
                        "
                        key="oystr"
                        title="Oystr"
                      >
                        <a-menu-item key="relatorio-atualizacoes-gcpj-oystr">
                          Exportar atualizações de GCPJ
                        </a-menu-item>
                      </a-sub-menu>
                    </a-menu>
                  </template>
                  <a-button
                    :loading="criarJobStatus === 'loading'"
                    size="large"
                  >
                    <template #icon>
                      <FileTextOutlined />
                    </template>
                    Gerar relatório
                    <DownOutlined />
                  </a-button>
                </a-dropdown>
              </a-col>
            </template>
          </RelatorioJobGraphqlDatatable>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
