import axios from 'axios';
import { ref } from 'vue';
import { error } from '~/utils';

const API_URL = import.meta.env.VITE_API_URL;

export function useReenviarLinkCriacaoSenha(cmd) {
  const status = ref(null);
  const err = ref(null);
  const response = ref(null);

  async function reenviar() {
    status.value = 'loading';
    try {
      const r = await axios.post(`${API_URL}/autenticacao/reenviar-link-criacao-senha`, cmd, {
        timeout: 120000,
        getAccessToken: true,
      });
      status.value = 'success';
      response.value = r.data;
    }
    catch (e) {
      status.value = 'error';
      err.value = error(e);
    }
  }

  return {
    status,
    err,
    response,
    reenviar,
  };
}
