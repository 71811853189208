<script setup>
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import { onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { useGraphQL } from '~/composables/useGraphQL';
import { useSalvarPessoaTelefone } from '~/composables/pessoas/useSalvarPessoaTelefone';

const props = defineProps({
  pessoaCodigo: {
    type: String,
    required: true,
  },
  pessoaTelefoneCodigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['success', 'close']);

const {
  status: pessoaTelefoneQueryStatus,
  data: pessoaTelefoneQueryData,
  runAsync: pessoaTelefoneQueryAsync,
} = useGraphQL({
  query: 'PessoaTelefoneQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const {
  status: salvarPessoaTelefoneStatus,
  err: salvarPessoaTelefoneErr,
  runAsync: salvarPessoaTelefoneAsync,
} = useSalvarPessoaTelefone();

const formRef = ref(null);
const state = reactive({
  status: null,
  telefones: [
    {
      codigo: null,
      ehPrincipal: null,
      descricao: null,
      numero: null,
    },
  ],
});

async function carregarAsync()
{
  await pessoaTelefoneQueryAsync({
    codigo: props.pessoaTelefoneCodigo,
  });

  if (pessoaTelefoneQueryStatus.value === 'success')
  {
    state.telefones[0].codigo = props.pessoaTelefoneCodigo;
    state.telefones[0].ehPrincipal
      = pessoaTelefoneQueryData.value.pessoaTelefone.ehPrincipal;
    state.telefones[0].descricao
      = pessoaTelefoneQueryData.value.pessoaTelefone.descricao;
    state.telefones[0].numero
      = pessoaTelefoneQueryData.value.pessoaTelefone.telefone.numero;
  }

  state.status = pessoaTelefoneQueryStatus.value;
}

async function salvarAsync()
{
  formRef.value.validate().then(async () =>
  {
    const cmd = {
      pessoaCodigo: props.pessoaCodigo,
      pessoaTelefoneCodigo: props.pessoaTelefoneCodigo,
      telefones: state.telefones,
    };

    await salvarPessoaTelefoneAsync(cmd);

    if (salvarPessoaTelefoneStatus.value === 'error')
    {
      notification.error({
        message: salvarPessoaTelefoneErr.value.message,
        description: salvarPessoaTelefoneErr.value.description,
        duration: 5,
      });
    }
  });
}

function adicionarTelefone()
{
  state.telefones.push({
    ehPrincipal: null,
    descricao: null,
    numero: null,
  });
}

function removerTelefone(index)
{
  if (state.telefones.length > 1)
  {
    state.telefones.splice(index, 1);
  }
}

onMounted(async () =>
{
  state.status = 'loading';
  if (props.pessoaTelefoneCodigo)
  {
    await carregarAsync();
  }
  else
  {
    state.status = 'success';
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          :loading="state.status === 'loading'"
          :paragraph="false"
          :active="true"
          :title="{ width: '65%' }"
        >
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            {{
              props.pessoaTelefoneCodigo !== null
                ? 'ATUALIZAR TELEFONE'
                : 'ADICIONAR TELEFONE'
            }}</span>
        </a-skeleton>
      </template>
    </DrawerTitle>
    <div style="padding: 0 24px">
      <div v-if="state.status === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="state.status === 'error'"
          :tentar-novamente-fn="carregarAsync"
        />
        <div v-else-if="state.status === 'success'">
          <a-result
            v-if="salvarPessoaTelefoneStatus === 'success'"
            status="success"
            title="Telefone salvo com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('success')">
                Fechar
              </a-button>
            </template>
          </a-result>
          <a-form v-else ref="formRef" layout="vertical" :model="state">
            {{ state }}
            <template v-for="(telefone, index) in state.telefones" :key="index">
              <a-row type="flex" align="middle">
                <a-col :flex="2">
                  <a-form-item
                    label="É o telefone principal?"
                    :name="['telefones', index, 'ehPrincipal']"
                    :rules="{
                      required: true,
                      message: 'Definir se é principal ou não é obrigatório',
                      type: 'boolean',
                      trigger: 'change',
                    }"
                  >
                    <a-radio-group v-model:value="telefone.ehPrincipal">
                      <a-radio-button :value="true">
                        Sim
                      </a-radio-button>
                      <a-radio-button :value="false">
                        Não
                      </a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col :flex="3" style="text-align: right">
                  <a-button
                    v-if="!props.pessoaTelefoneCodigo"
                    type="dashed"
                    @click="() => removerTelefone(index)"
                  >
                    <template #icon>
                      <MinusCircleOutlined />
                    </template>Remover
                  </a-button>
                </a-col>
              </a-row>

              <a-form-item
                label="Descrição"
                :name="['telefones', index, 'descricao']"
                style="padding-bottom: 0"
                :rules="{
                  required: true,
                  message: 'Descrição é obrigatório',
                }"
              >
                <a-input v-model:value="telefone.descricao" />
              </a-form-item>

              <a-form-item
                label="Número"
                :name="['telefones', index, 'numero']"
                style="padding-bottom: 0"
                :rules="{
                  required: true,
                  message: 'Número é obrigatório',
                }"
              >
                <a-input
                  v-model:value="telefone.numero"
                  v-maska="['(##) #####-####', '(##) ####-####']"
                />
              </a-form-item>

              <a-divider v-if="index !== state.telefones.length - 1" />
            </template>

            <a-form-item v-if="!props.pessoaTelefoneCodigo">
              <a-button type="dashed" block @click="adicionarTelefone">
                <PlusOutlined />
                Adicionar telefone
              </a-button>
            </a-form-item>

            <a-row style="margin-top: 24px">
              <a-col :span="16">
                <a-button
                  :loading="salvarPessoaTelefoneStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="salvarAsync"
                >
                  Salvar
                </a-button>
                <a-button
                  style="margin-right: 8px"
                  @click="() => emit('close')"
                >
                  Cancelar
                </a-button>
              </a-col>
              <a-col :span="8" style="text-align: right" />
            </a-row>
          </a-form>
        </div>
      </div>
    </div>
  </div>
</template>
