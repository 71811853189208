<script setup>
import { useObterTotalPastasAtivasPorGerencia } from '~/composables/pastas/useObterTotalPastasAtivasPorGerencia';
import { onMounted, computed } from 'vue';
import { colors } from '~/utils';

const {
  status: obterTotalPastasAtivasPorGerenciaStatus,
  data: obterTotalPastasAtivasPorGerenciaData,
  runAsync: obterTotalPastasAtivasPorGerenciaAsync,
} = useObterTotalPastasAtivasPorGerencia();

const chartOptions = computed(() => {
  if (obterTotalPastasAtivasPorGerenciaStatus.value !== 'success') {
    return null;
  }
  return {
    chart: {
      type: 'bar',
      height: '400px',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: false,
      },
    },
    colors,
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    series: [
      {
        name: 'total de pastas',
        data: obterTotalPastasAtivasPorGerenciaData.value.items.map(
          (x) => x.total
        ),
      },
    ],
    xaxis: {
      categories: obterTotalPastasAtivasPorGerenciaData.value.items.map(
        (x) => x.gerenciaText
      ),
      labels: {
        show: false,
      },
    },
  };
});

onMounted(async () => {
  await obterTotalPastasAtivasPorGerenciaAsync();
});
</script>

<template>
  <ChartCard
    :status="obterTotalPastasAtivasPorGerenciaStatus"
    :options="chartOptions"
  >
    <template #title>
      <span style="color: rgb(128 128 128); font-size: 16px"
        >PASTAS ATIVAS POR GERÊNCIA</span
      >
    </template>
  </ChartCard>
</template>
