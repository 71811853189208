<script setup lang="ts">
import { RobotOutlined, SettingOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { useLazyQuery } from '@vue/apollo-composable';
import jobQuery from '~/graphql/job.graphql';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';

const { load, onResult } = useLazyQuery(jobQuery, {}, { pollInterval: 2000 });

const historicoGqlDatatableRef = ref<any>(null);
const compromissosQueSeraoExecutadosGqlDatatableRef = ref<any>(null);
const compromissosQueJaForamExecutadosGqlDatatableRef = ref<any>(null);

const modelRef = ref<any>(null);

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
] as GraphqlDatatableColumn[];

onResult((queryResult) =>
{
  modelRef.value = queryResult?.data?.model;
});

async function onQuerySuccess(model: any)
{
  modelRef.value = model;

  if (modelRef.value?.codigo)
  {
    await load(null, { where: { codigo: { eq: modelRef.value.codigo } } });
  }
}

async function reloadTables()
{
  await compromissosQueSeraoExecutadosGqlDatatableRef.value?.reload();
  await compromissosQueJaForamExecutadosGqlDatatableRef.value?.reload();
  await historicoGqlDatatableRef.value?.reload();
}
</script>

<template>
  <GqlPage
    menu="robo-consulta-gcpj"
    :query="jobQuery"
    :query-params="{
      where: {
        tipo: {
          eq: 54,
        },
        status: {
          eq: 1,
        },
      },
    }"
    @query-success="onQuerySuccess"
  >
    <template #default>
      <div>
        <a-row>
          <a-col
            :xs="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <!-- Page header -->
            <a-row>
              <a-col :span="12">
                <a-breadcrumb>
                  <a-breadcrumb-item>
                    <router-link to="/robos">
                      Robôs
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>
                    <router-link to="/robo/consultar-gcpj">
                      Consultar GCPJ
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>Executar</a-breadcrumb-item>
                </a-breadcrumb>
              </a-col>
              <a-col :span="12" />
            </a-row>
            <h1>
              <RobotOutlined style="color: #bebcbc" />
              Consultar GCPJ
            </h1>
          </a-col>
        </a-row>

        <div style="margin-top: 24px">
          <div v-if="modelRef?.status === 1">
            <a-alert
              message="O robô está em execução"
              show-icon
            />
            <a-flex
              justify="center"
              align="center"
            >
              <a-progress
                :percent="modelRef?.resultado?.progress ?? 0.0"
                style="margin-top: 24px"
                type="circle"
              />
            </a-flex>
          </div>
          <div v-else>
            <JobCommandForm
              command="ExecutarRoboConsultaGcpjCommand"
              :show-job-progress="true"
              :notification-on-submit-success="{ message: 'Robô executado sucesso' }"
              submit-button-text="Executar"
              :submit-button-block="false"
              @success="reloadTables"
              @error="reloadTables"
            >
              <template #form="{ model }">
                <a-form-item
                  label="Cookie"
                  name="cookie"
                  :rules="{
                    required: true,
                    message: 'Cookie é obrigatório',
                  }"
                >
                  <a-textarea
                    v-model:value="model.cookie"
                    :allow-clear="true"
                    :rows="4"
                  />
                </a-form-item>
              </template>
              <template #submitButtonIcon>
                <SettingOutlined />
              </template>
            </JobCommandForm>

            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="COMPROMISSOS QUE SERÃO EXECUTADOS"
              >
                <CompromissoGqlDatatable
                  key="compromissosQueSeraoExecutados"
                  ref="compromissosQueSeraoExecutadosGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :total-per-page="5"
                  :variables="{
                    order: [{ dataInicio: 'DESC' }],
                    where: {
                      tipo: {
                        eq: 158,
                      },
                      status: {
                        eq: 1,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>

            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="COMPROMISSOS QUE JÁ FORAM EXECUTADOS"
              >
                <CompromissoGqlDatatable
                  key="compromissosQueJaForamExecutados"
                  ref="compromissosQueJaForamExecutadosGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :total-per-page="5"
                  :variables="{
                    order: [{ dataInicio: 'DESC' }],
                    where: {
                      tipo: {
                        eq: 158,
                      },
                      status: {
                        eq: 3,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>

            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="HISTÓRICO"
              >
                <CompromissoGqlDatatable
                  key="historico"
                  ref="historicoGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :columns="colunas"
                  :total-per-page="5"
                  :variables="{
                    order: [{ dataConclusao: 'DESC' }],
                    where: {
                      tipo: {
                        eq: 160,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>
          </div>
        </div>
      </div>
    </template>
  </GqlPage>
</template>
