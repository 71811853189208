import { API_URL } from '~/utils';
import { usePost } from '~/composables/usePost';

export function useCommand(opts?: any)
{
  const config = opts?.config ?? {};

  const { runAsync: executePost, status, data, err } = usePost({
    url: `${API_URL}/command`,
    config,
  });

  const command = async (commandType: string, commandData?: any) =>
  {
    await executePost({ commandData, commandType });
  };

  return { command, data, err, status };
}
