<script setup lang="ts">
import { RobotOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import jobQuery from '~/graphql/job.graphql';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';

const historicoGqlDatatableRef = ref<any>(null);
const compromissosQueSeraoExecutadosGqlDatatableRef = ref<any>(null);
const compromissosQueJaForamExecutadosGqlDatatableRef = ref<any>(null);

const colunasCompromissosQueSeraoExecutados = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'ascend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const colunasCompromissosJaExecutados = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
] as GraphqlDatatableColumn[];

const colunasHistorico = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'status',
    dataIndex: 'statusText',
    title: 'STATUS',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
] as GraphqlDatatableColumn[];

async function reloadTables()
{
  await compromissosQueSeraoExecutadosGqlDatatableRef.value?.reload();
  await compromissosQueJaForamExecutadosGqlDatatableRef.value?.reload();
  await historicoGqlDatatableRef.value?.reload();
}
</script>

<template>
  <Page
    menu="robo-atualizacao-gcpj"
  >
    <GqlView
      :query="jobQuery"
      :variables="{
        where: {
          tipo: {
            eq: 56,
          },
          status: {
            eq: 1,
          },
        },
      }"
      :poll-interval="2000"
    >
      <template #success="{ data, query: smartQuery } : {data: any, query: any}">
        <a-row>
          <a-col
            :xs="24"
            :md="24"
            :lg="24"
            :xl="24"
          >
            <a-row>
              <a-col :span="12">
                <a-breadcrumb>
                  <a-breadcrumb-item>
                    <router-link to="/robos">
                      Robôs
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>
                    <router-link to="/robo/atualizacao-gcpj">
                      Atualização GCPJ
                    </router-link>
                  </a-breadcrumb-item>
                  <a-breadcrumb-item>Executar</a-breadcrumb-item>
                </a-breadcrumb>
              </a-col>
              <a-col :span="12" />
            </a-row>
            <h1>
              <RobotOutlined style="color: #bebcbc" />
              Atualização GCPJ
            </h1>
          </a-col>
        </a-row>

        <div style="margin-top: 24px">
          <template v-if="data?.model !== null">
            <a-alert
              message="O robô está em execução"
              show-icon
            />
            <a-flex
              justify="center"
              align="center"
            >
              <a-progress
                :percent="data.model.resultado?.progress ?? 0.0"
                style="margin-top: 24px"
                type="circle"
              />
            </a-flex>
          </template>

          <template v-else>
            {{ smartQuery?.stopPolling() }}
            <JobCommandForm
              command="ExecutarRoboAtualizacaoGcpjCommand"
              :show-job-progress="true"
              :notification-on-submit-success="{ message: 'Robô executado sucesso' }"
              :submit-button-block="false"
              submit-button-text="Executar"
              submit-button-loading-text="Executando..."
              @success="reloadTables"
            >
              <template #form="{ model }">
                <a-form-item
                  label="Cookie"
                  name="cookie"
                  :rules="{
                    required: true,
                    message: 'Cookie é obrigatório',
                  }"
                >
                  <a-textarea
                    v-model:value="model.cookie"
                    :allow-clear="true"
                    :rows="4"
                  />
                </a-form-item>
              </template>
            </JobCommandForm>
            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="COMPROMISSOS QUE SERÃO EXECUTADOS"
              >
                <CompromissoGqlDatatable
                  key="compromissosQueSeraoExecutados"
                  ref="compromissosQueSeraoExecutadosGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :total-per-page="5"
                  :columns="colunasCompromissosQueSeraoExecutados"
                  :variables="{
                    order: [{ dataInicio: 'ASC' }],
                    where: {
                      tipo: {
                        eq: 4,
                      },
                      status: {
                        eq: 1,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>

            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="COMPROMISSOS QUE JÁ FORAM EXECUTADOS"
              >
                <CompromissoGqlDatatable
                  key="compromissosQueJaForamExecutados"
                  ref="compromissosQueJaForamExecutadosGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :total-per-page="5"
                  :columns="colunasCompromissosJaExecutados"
                  :variables="{
                    order: [{ dataConclusao: 'DESC' }],
                    where: {
                      tipo: {
                        eq: 4,
                      },
                      status: {
                        eq: 3,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>

            <a-collapse
              default-active-key="h"
              style="margin-top: 24px"
            >
              <a-collapse-panel
                key="h"
                header="HISTÓRICO"
              >
                <CompromissoGqlDatatable
                  key="historico"
                  ref="historicoGqlDatatableRef"
                  :show-dates-filter="false"
                  :show-reload-button="false"
                  :show-status-search-bar="false"
                  :show-filter="false"
                  :columns="colunasHistorico"
                  :total-per-page="5"
                  :variables="{
                    order: [{ dataConclusao: 'DESC' }],
                    where: {
                      tipo: {
                        eq: 167,
                      },
                      status: {
                        eq: 3,
                      },
                    },
                  }"
                />
              </a-collapse-panel>
            </a-collapse>
          </template>
        </div>
      </template>
    </GqlView>
  </Page>
</template>
