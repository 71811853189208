<script setup>
import { useDevolverCompromisso } from '~/composables/compromissos/useDevolverCompromisso';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { CloudUploadOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { onMounted, reactive, ref, toRaw } from 'vue';

const props = defineProps({
  compromisso: {
    required: true,
    type: Object,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  err: tentarContatoTelefonicoErr,
  runAsync: tentarContatoTelefonicoAsync,
  status: tentarContatoTelefonicoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const {
  err: devolverCompromissoErr,
  runAsync: devolverCompromissoAsync,
  status: devolverCompromissoStatus,
} = useDevolverCompromisso();

const $auth = useAuthStore();
const devolverModalVisible = ref(false);
const formRef = ref(null);
const formModel = reactive({
  arquivos: [],
  justificativaDevolucao: null,
  resposta: null,
});
const formRules = reactive({
  resposta: [{ message: 'Resposta é obrigatória', required: true }],
});
const uploaderStatus = ref(null);
const uploaderRef = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    await uploaderRef.value.upload();

    await tentarContatoTelefonicoAsync({
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      ...toRaw(formModel),
    });

    if (tentarContatoTelefonicoStatus.value === 'error') {
      notification.error({
        description: tentarContatoTelefonicoErr.value.description,
        duration: 5,
        message: tentarContatoTelefonicoErr.value.message,
      });
      emit('error');
      return;
    }

    emit('success');
  });
};

const uiDevolverAsync = async () => {
  await devolverCompromissoAsync({
    codigoCompromisso: props.compromisso.codigo,
    justificativaDevolucao: formModel.justificativaDevolucao,
  });

  if (devolverCompromissoStatus.value === 'error') {
    notification.error({
      description: devolverCompromissoErr.value.description,
      duration: 5,
      message: devolverCompromissoErr.value.message,
    });
    emit('error');
    return;
  }
  emit('success');
};

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <div>
    <a-row>
      <a-col :xs="24" :md="24" :lg="24" :xl="24">
        <a-result
          v-if="tentarContatoTelefonicoStatus === 'success'"
          status="success"
          title="Registro realizado com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-result
          v-if="devolverCompromissoStatus === 'success'"
          status="success"
          title="Compromisso devolvido com sucesso"
          sub-title="Agora você pode fechar essa janela"
        >
          <template #extra>
            <a-button @click="() => emit('close')"> Fechar </a-button>
          </template>
        </a-result>

        <a-row
          v-if="
            tentarContatoTelefonicoStatus !== 'success' &&
            devolverCompromissoStatus !== 'success'
          "
          :gutter="24"
        >
          <a-col :span="24">
            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
            >
              <a-form-item label="Resposta" name="resposta">
                <a-textarea v-model:value="formModel.resposta" :rows="4" />
              </a-form-item>

              <Uploader
                ref="uploaderRef"
                v-model:fileList="formModel.arquivos"
                
                @start="() => (uploaderStatus = 'uploading')"
                @end="() => (uploaderStatus = 'done')"
              >
                <template #description>
                  <p class="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                  <p class="ant-upload-text">
                    Clique aqui ou arraste os arquivos
                  </p>
                  <p class="ant-upload-hint">Envie os arquivos necessários</p>
                </template>
              </Uploader>
            </a-form>

            <a-modal
              v-model:visible="devolverModalVisible"
              title="Justifique a devolução"
              cancel-text="Cancelar"
              :z-index="2000"
              :confirm-loading="devolverCompromissoStatus === 'loading'"
              :ok-button-props="{
                disabled: !formModel.justificativaDevolucao?.length,
              }"
              @ok="uiDevolverAsync"
            >
              <a-textarea
                v-model:value="formModel.justificativaDevolucao"
                :rows="4"
                placeholder="Escreva sua justificativa"
              />
            </a-modal>

            <a-row style="margin-top: 24px; margin-bottom: 0">
              <a-col :span="24">
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== $auth.codigoUsuario
                  "
                  :loading="
                    uploaderStatus === 'uploading' ||
                    tentarContatoTelefonicoStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarAsync"
                >
                  Salvar
                </a-button>
                <a-button
                  :disabled="
                    compromisso.responsavel.codigo !== $auth.codigoUsuario
                  "
                  :loading="devolverCompromissoStatus === 'loading'"
                  style="margin-right: 8px; min-width: 100px"
                  @click="() => (devolverModalVisible = true)"
                >
                  Devolver
                </a-button>
                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>
