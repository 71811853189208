import { API_URL } from '~/utils';
import { useGet } from '../useGet';

export function useObterAuditsEnvolvidosNovajus() {
  return useGet({
    url: `${API_URL}/pasta/obter-audits-envolvidos-novajus`,
    config: {
      getAccessToken: true,
      timeout: 120000,
    },
  });
}
