<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { GraphqlDatatableColumn } from './GqlDatatable.vue';
import { useAuthStore } from '~/stores/auth';
import { isNilOrEmpty } from '~/utils';
import referenciaGcpjAndamentoSelect from '~/graphql/referenciaGcpjAndamentoSelect.graphql';

const props = defineProps({
  andamento: {
    default: null,
    type: String,
  },
  compromisso: {
    default: null,
    type: Object,
  },
  encaminharParaEquipeGcpj: {
    default: true,
    type: Boolean,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
  repetirUltimaReferencia: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const jobStatus = ref<any>(null);
const mostrarHistoricoSolicitacoes = ref<any>('h');
const uploadFilesGcpj = ref<any>(null);

const colunas = [
  {
    key: 'compromisso',
    title: 'COMPROMISSO',
    width: 120,
  },
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    dataIndex: 'dataInicioText',
    key: 'dataInicio',
    sortBy: ['dataInicio'],
    sorter: true,
    title: 'SOLICITADO EM',
    width: 120,
    defaultSortOrder: 'descend',
  },
  {
    dataIndex: 'dataVencimentoText',
    key: 'dataVencimento',
    sortBy: ['dataVencimento'],
    sorter: true,
    title: 'VENCIMENTO EM',
    width: 120,
  },
  {
    dataIndex: 'dataConclusaoText',
    key: 'dataConclusao',
    sortBy: ['dataConclusao'],
    sorter: true,
    title: 'CONCLUÍDO EM',
    width: 120,
  },
] as GraphqlDatatableColumn[];

const authStore = useAuthStore();

async function preSubmit(model: any)
{
  const uploadResult = await uploadFilesGcpj.value?.upload();

  if (!uploadResult.ok)
  {
    return false;
  }

  model.arquivos = uploadResult.value;

  return true;
}

async function postSubmit()
{
  jobStatus.value = 'success';
}

onMounted(() =>
{
  emit('loaded');
});
</script>

<template>
  <div>
    <a-collapse
      v-if="jobStatus !== 'success' && props.pastaCodigos?.length === 1"
      v-model:activeKey="mostrarHistoricoSolicitacoes"
    >
      <a-collapse-panel
        key="h"
        header="HISTÓRICO DE SOLICITAÇÕES"
      >
        <CompromissoGqlDatatable
          :columns="colunas"
          :show-dates-filter="false"
          :show-reload-button="false"
          :show-status-search-bar="false"
          :show-filter="false"
          :variables="{
            order: [{ dataInicio: 'DESC' }],
            where: {
              tipo: {
                eq: 3,
              },
              fluxo: {
                pastaCodigo: {
                  in: props.pastaCodigos,
                },
              },
            },
          }"
        />
      </a-collapse-panel>
    </a-collapse>

    <div style="margin-top: 16px" />

    <CompromissoCommandForm2
      command="AtualizacaoGcpj.SolicitarAtualizacaoGcpjCommand"
      :pre-submit="preSubmit"
      :post-submit="postSubmit"
      :show-upload-files="false"
      :submit-button-disabled="!isNilOrEmpty(compromisso)
        && compromisso.responsavel.codigo !== authStore.codigoUsuario"
      :initial-model="{
        pastaCodigos: props.pastaCodigos,
        compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
        encaminharParaEquipeGcpj: props.encaminharParaEquipeGcpj,
        andamento: props.andamento,
        repetirUltimaReferencia: props.repetirUltimaReferencia,
        arquivosForm: [],
      }"
      @close="() => emit('close')"
      @submit-success="() => emit('success')"
    >
      <template #form="{ model }">
        <a-form-item
          v-if="props.repetirUltimaReferencia !== true"
          label="Referência"
          name="referenciaGcpj"
          :rules="[
            {
              message: 'Referência é obrigatório',
              required: true,
              trigger: 'change',
              type: 'object',
            },
          ]"
        >
          <GqlSelect
            v-model:value="model.referenciaGcpj"
            :query="referenciaGcpjAndamentoSelect"
            placeholder="Seleciona a referência do GCPJ"
          />
        </a-form-item>
        <a-form-item
          v-if="props.andamento === null"
          label="Andamento"
          name="andamento"
          style="padding-bottom: 0"
          :rules="[{ message: 'Andamento é obrigatório', required: true }]"
        >
          <a-textarea
            v-model:value="model.andamento"
            :rows="4"
          />
        </a-form-item>
        <UploadFilesGcpj
          ref="uploadFilesGcpj"
          v-model="model.arquivosForm"
        />
        <div style="margin-top: 24px" />
      </template>
    </CompromissoCommandForm2>
  </div>
</template>
