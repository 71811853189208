import axios from 'axios';
import { defineStore } from 'pinia';

interface UserAuthenticationData
{
  id: string
  name: string
  roles: string[]
}

interface State
{
  user: UserAuthenticationData | null
}

export const useAuthenticationStore = defineStore('auth', {
  state: (): State =>
  {
    return {
      user: null,
    };
  },
  actions: {
    async me(): Promise<boolean>
    {
      try
      {
        const { data: user } = await axios.get<UserAuthenticationData>('me');
        this.user = user;
        return true;
      }
      catch
      {
        return false;
      }
    },

    async login(globalSessionId: string): Promise<boolean>
    {
      try
      {
        const { data: user } = await axios.post<UserAuthenticationData>('login', { globalSessionId });
        this.user = user;
        return true;
      }
      catch
      {
        return false;
      }
    },

    async logout(): Promise<void>
    {
      try
      {
        await axios.post('logout');
      }
      finally
      {
        this.user = null;
      }
    },
  },
});
