<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue';
import type { GraphqlDatatableColumn } from '~/components/GqlDatatable.vue';
import fluxosEspecificosDatatable from '~/graphql/fluxosEspecificosDatatable.graphql';

interface Props
{
  pastaCodigo?: string
}

const props = defineProps<Props>();

const columns = [
  {
    key: 'responsavel',
    title: 'RESPONSÁVEL',
    width: 120,
  },
  {
    key: 'data',
    title: 'DATA',
    width: 120,
  },
  {
    key: 'descricao',
    title: 'DESCRIÇÃO',
    width: 120,
  },
] as GraphqlDatatableColumn[];

function obterUltimoCompromisso(compromissos: [any], tipo: number)
{
  const compromissosFiltradosPorTipo = compromissos.filter(x => x.tipo === tipo && x.status === 3);
  compromissosFiltradosPorTipo.sort((a, b) =>
  {
    const dateA = dayjs(a.dataInicio);
    const dateB = dayjs(b.dataInicio);
    return dateA.diff(dateB);
  });
  return compromissosFiltradosPorTipo[compromissosFiltradosPorTipo.length - 1];
}

const gqlDatatable = ref<any>(null);
</script>

<template>
  <GqlDatatable
    ref="gqlDatatable"
    :query="fluxosEspecificosDatatable"
    :columns="columns"
    :variables="{
      order: [{ dataInicio: 'DESC' }],
      where: {
        tipo: {
          eq: 41,
        },
        pastaCodigo: {
          eq: props.pastaCodigo,
        },
      },
    }"
  >
    <template #responsavelColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.iniciadoPor.nome }}</span>
    </template>
    <template #dataColumn="{ record }">
      <span style="text-transform: uppercase">{{ record.dataInicioText }}</span>
    </template>
    <template #descricaoColumn="{ record }">
      <span style="text-transform: uppercase">{{ obterUltimoCompromisso(record.compromissos, 126).data.descricao }}</span>
    </template>
  </GqlDatatable>
</template>
