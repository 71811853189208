<script setup lang="ts">
import { DownloadOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons-vue';
import { merge, set } from 'lodash-es';
import { onMounted, onUnmounted, reactive, ref, toRaw, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useLazyQuery } from '@vue/apollo-composable';
import type { DocumentNode } from 'graphql';
import { notification } from 'ant-design-vue';
import type { ColumnType } from 'ant-design-vue/es/table';
import type { SortOrder } from 'ant-design-vue/es/table/interface';
import router from '~/router';
import {
  arrayToNestedObject,
  isNilOrEmpty,
  mergeWithArrayConcat,
} from '~/shared/utils/helpers';
import { getGoogleDriveUrl } from '~/utils';
import { useJobCommand } from '~/composables/useJobCommand';
import tiposJobs from '~/constants/tipoJob';

interface Props
{
  query: DocumentNode
  bordered?: boolean
  columns?: GraphqlDatatableColumn[]
  exportJob?: any
  variables?: any
  keepHistory?: boolean
  pageSizeOptions?: string[]
  rowKey?: string
  rowSelection?: any
  textSearch?: any
  reloadInterval?: number
  filters?: GraphqlDatatableFilter[]
  exportCommand?: string
  exportJobType?: number
  showReloadButton?: boolean
}

export interface GraphqlDatatableFilter
{
  key: string
  label: string
  queryKey: string
  type: 'text' | 'number' | 'text-multiple' | 'gql-select' | 'date' | 'money' | 'boolean'
  path: string
  componentProps?: any
  parseValue?: any
  inWhereClause?: boolean
}

export interface GraphqlDatatableColumn extends ColumnType<any>
{
  key: string
  title: string
  defaultSortOrder?: SortOrder | undefined
  dataIndex?: string | string[]
  sorter?: boolean
  sortOrder?: SortOrder | undefined
  sortBy?: string[]
  width?: number | string
  totalPerPage?: number
}

const props = withDefaults(defineProps<Props>(), {
  bordered: true,
  columns: () => [],
  exportJob: null,
  variables: () => ({}),
  keepHistory: true,
  pageSizeOptions: () => ['10', '20', '30', '40', '50'],
  filters: () => [],
  showReloadButton: true,
  totalPerPage: 10,
});

const emit = defineEmits(['change', 'success', 'filterReset']);

const { result, loading, variables: gqlVariables, refetch, onError, error, load } = useLazyQuery(
  props.query,
  props.variables,
  {
    fetchPolicy: 'no-cache',
  },
);

const { status: jobCommandStatus, jobCommand, data: jobCommandData } = useJobCommand();

const dataSource = ref<any>([]);

const route = useRoute();

const paginationState = reactive<any>({
  currentPage: 1,
  total: 0,
  totalPerPage: props.totalPerPage,
});

const state = reactive<any>({
  exportJobLastResult: null,
  exportJobLoading: false,
  filtersIsOpened: false,
  text: null,
  totalUsedFilters: 0,
  usedFilters: {},
});

const filterState = reactive<any>({});

function stateToQuery()
{
  const q: any = {};

  if (!isNilOrEmpty(state.text))
  {
    q.t = state.text;
  }

  props.filters.forEach((filter) =>
  {
    if (
      filter.type === 'gql-select'
      && filterState[filter.key]?.length > 0
    )
    {
      q[filter.queryKey] = JSON.stringify(
        filterState[filter.key].map((x: any) => ({ label: x.label, value: x.value })),
      );
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0)
    {
      q[filter.queryKey] = JSON.stringify(filterState[filter.key]);
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key]))
    {
      q[filter.queryKey] = filterState[filter.key];
    }

    if (filter.type === 'boolean' && !isNilOrEmpty(filterState[filter.key]))
    {
      q[filter.queryKey] = filterState[filter.key];
    }

    if (filter.type === 'text-multiple' && !isNilOrEmpty(filterState[filter.key]))
    {
      q[filter.queryKey] = filterState[filter.key];
    }

    if (
      (filter.type === 'number' || filter.type === 'money')
      && (!isNilOrEmpty(filterState[`${filter.key}Min`])
      || !isNilOrEmpty(filterState[`${filter.key}Max`]))
    )
    {
      q[`${filter.queryKey}min`] = filterState[`${filter.key}Min`];
      q[`${filter.queryKey}max`] = filterState[`${filter.key}Max`];
    }
  });

  return q;
}

function initializaFilterState()
{
  props.filters.forEach((filter) =>
  {
    if (filter.type === 'gql-select')
    {
      filterState[filter.key] = [];
    }

    if (filter.type === 'date')
    {
      filterState[filter.key] = [];
    }

    if (filter.type === 'text')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'boolean')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'text-multiple')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'number' || filter.type === 'money')
    {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }
  });
}

function queryToState()
{
  const q: any = route.query || {};

  if (!isNilOrEmpty(q.t))
  {
    state.text = q.t;
  }

  props.filters.forEach((filter) =>
  {
    if (filter.type === 'gql-select')
    {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? JSON.parse(q[filter.queryKey])
        : [];
    }

    if (filter.type === 'date')
    {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? JSON.parse(q[filter.queryKey])
        : [];
    }

    if (filter.type === 'text')
    {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? q[filter.queryKey]
        : null;
    }

    if (filter.type === 'boolean')
    {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? q[filter.queryKey]
        : null;
    }

    if (filter.type === 'text-multiple')
    {
      filterState[filter.key] = !isNilOrEmpty(q[filter.queryKey])
        ? q[filter.queryKey]
        : null;
    }

    if (filter.type === 'number' || filter.type === 'money')
    {
      filterState[`${filter.key}Min`] = !isNilOrEmpty(
        q[`${filter.queryKey}min`],
      )
        ? JSON.parse(q[`${filter.queryKey}min`])
        : null;

      filterState[`${filter.key}Max`] = !isNilOrEmpty(
        q[`${filter.queryKey}max`],
      )
        ? JSON.parse(q[`${filter.queryKey}max`])
        : null;
    }
  });
}

function updateGraphQLVariables()
{
  // let variables = cloneDeep(gqlVariables.value);
  const filterVariables: any = {};

  props.filters.forEach((filter) =>
  {
    if (
      filter.type === 'gql-select'
      && filterState[filter.key]?.length > 0
    )
    {
      const val = filter.parseValue
        ? filter.parseValue(filterState[filter.key])
        : filterState[filter.key].map((x: any) => x.value);

      const fullPath: string = filter.inWhereClause
        ? `where.${filter.path}.in`
        : filter.path;

      const resultObject: any = {};

      set(resultObject, fullPath, val);

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0)
    {
      const valMin = filter.parseValue
        ? filter.parseValue(filterState[filter.key][0])
        : `${filterState[filter.key][0]} 00:00:00`;

      const valMax = filter.parseValue
        ? filter.parseValue(filterState[filter.key][1])
        : `${filterState[filter.key][1]} 23:59:59`;

      const fullPathMin: string = filter.inWhereClause
        ? `where.${filter.path}.gte`
        : `${filter.path}Gte`;
      const fullPathMax: string = filter.inWhereClause
        ? `where.${filter.path}.lte`
        : `${filter.path}Lte`;
      const resultObject: any = {};
      set(resultObject, fullPathMin, valMin);
      set(resultObject, fullPathMax, valMax);

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key]))
    {
      const val = filter.parseValue
        ? filter.parseValue(filterState[filter.key])
        : filterState[filter.key];

      const fullPath: string = filter.inWhereClause
        ? `where.${filter.path}.contains`
        : filter.path;
      const resultObject: any = {};
      set(resultObject, fullPath, val);

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }

    if (filter.type === 'boolean' && !isNilOrEmpty(filterState[filter.key]))
    {
      const val = filter.parseValue
        ? filter.parseValue(filterState[filter.key])
        : filterState[filter.key];

      const fullPath: string = filter.inWhereClause
        ? `where.${filter.path}.eq`
        : filter.path;
      const resultObject: any = {};

      set(resultObject, fullPath, val);

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }

    if (filter.type === 'text-multiple' && !isNilOrEmpty(filterState[filter.key]))
    {
      const val = filter.parseValue
        ? filter.parseValue(filterState[filter.key])
        : filterState[filter.key].split(/[\s,]+/).map((x: any) => x.trim());

      const fullPath: string = filter.inWhereClause
        ? `where.${filter.path}.in`
        : filter.path;

      const resultObject: any = {};
      set(resultObject, fullPath, val);

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }

    if (
      (filter.type === 'number'
      || filter.type === 'money')
      && (!isNilOrEmpty(filterState[`${filter.key}Min`])
      || !isNilOrEmpty(filterState[`${filter.key}Max`]))
    )
    {
      const valMin = filter.parseValue
        ? filter.parseValue(filterState[`${filter.key}Min`])
        : filterState[`${filter.key}Min`];

      const valMax = filter.parseValue
        ? filter.parseValue(filterState[`${filter.key}Max`])
        : filterState[`${filter.key}Max`];

      const resultObject: any = {};

      if (valMin)
      {
        const fullPathMin: string = filter.inWhereClause
          ? `where.${filter.path}.gte`
          : `${filter.path}Gte`;
        set(resultObject, fullPathMin, valMin);
      }

      if (valMax)
      {
        const fullPathMax: string = filter.inWhereClause
          ? `where.${filter.path}.lte`
          : `${filter.path}Lte`;
        set(resultObject, fullPathMax, valMax);
      }

      mergeWithArrayConcat(
        filterVariables,
        resultObject,
      );
    }
  });

  filterVariables.text = !isNilOrEmpty(state.text)
    ? state.text
    : null;

  paginationState.currentPage = 1;
  filterVariables.skip = 0;
  filterVariables.take = Number.parseInt(paginationState.totalPerPage, 10);

  const vars: any = {};
  merge(vars, filterVariables, props.variables);

  gqlVariables.value = { ...vars };
}

async function sortedInfoToGraphQLOrder(sInfo: any)
{
  if (sInfo.column)
  {
    const sortBy = arrayToNestedObject(
      sInfo.column.sortBy,
      sInfo.order === 'descend' ? 'DESC' : 'ASC',
    );

    paginationState.currentPage = 1;
    gqlVariables.value = { ...gqlVariables.value, ...{ order: [sortBy], skip: 0 } };
  }
}

function resetFilterState()
{
  props.filters.forEach((filter) =>
  {
    if (filter.type === 'gql-select')
    {
      filterState[filter.key] = [];
    }

    if (filter.type === 'date')
    {
      filterState[filter.key] = [];
    }

    if (filter.type === 'text')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'boolean')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'text-multiple')
    {
      filterState[filter.key] = null;
    }

    if (filter.type === 'number' || filter.type === 'money')
    {
      filterState[`${filter.key}Min`] = null;
      filterState[`${filter.key}Max`] = null;
    }
  });

  paginationState.currentPage = 1;
  const { order: currentOrder } = gqlVariables.value;
  const { order: propsOrder, ...propsVariablesWithoutOrder } = props.variables;
  gqlVariables.value = { ...propsVariablesWithoutOrder, ...{ skip: 0, order: currentOrder } };
}

function updateQueryString()
{
  if (props.keepHistory)
  {
    router.push({
      path: route.path,
      query: stateToQuery(),
    });
  }
}

async function onTableChange(pagination: any, filters: any, sorter: any)
{
  await sortedInfoToGraphQLOrder(sorter);
  emit('change', pagination, filters, sorter);
}

async function onPaginationChange(page: number, pageSize: number)
{
  if (paginationState.totalPerPage && pageSize !== paginationState.totalPerPage)
  {
    paginationState.currentPage = 1;
  }
  else
  {
    paginationState.currentPage = page;
  }

  paginationState.totalPerPage = pageSize;

  gqlVariables.value = { ...gqlVariables.value, ...{
    skip: (paginationState.currentPage - 1) * Number.parseInt(paginationState.totalPerPage, 10),
    take: Number.parseInt(paginationState.totalPerPage, 10),
  } };
}

function getDataSource()
{
  return toRaw(dataSource.value);
}

function updateTotalUsedFilters()
{
  let total = 0;
  props.filters.forEach((filter) =>
  {
    if (
      filter.type === 'gql-select'
      && filterState[filter.key]?.length > 0
    )
    {
      total += 1;
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0)
    {
      total += 1;
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key]))
    {
      total += 1;
    }

    if (filter.type === 'boolean' && !isNilOrEmpty(filterState[filter.key]))
    {
      total += 1;
    }

    if (filter.type === 'text-multiple' && !isNilOrEmpty(filterState[filter.key]))
    {
      total += 1;
    }

    if (
      (filter.type === 'number' || filter.type === 'money')
      && (!isNilOrEmpty(filterState[`${filter.key}Min`])
      || !isNilOrEmpty(filterState[`${filter.key}Max`]))
    )
    {
      total += 1;
    }
  });
  state.totalUsedFilters = total;
}

function updateUsedFilters()
{
  const filtros: any = {};

  props.filters.forEach((filter) =>
  {
    if (
      filter.type === 'gql-select'
      && filterState[filter.key]?.length > 0
    )
    {
      filtros[filter.label] = filterState[filter.key]
        .map((x: any) => x.label)
        .join(', ');
    }

    if (filter.type === 'date' && filterState[filter.key]?.length > 0)
    {
      filtros[filter.label] = `${filterState[filter.key][0]} - ${
        filterState[filter.key][1]
      }`;
    }

    if (filter.type === 'text' && !isNilOrEmpty(filterState[filter.key]))
    {
      filtros[filter.label] = filterState[filter.key];
    }

    if (filter.type === 'boolean' && !isNilOrEmpty(filterState[filter.key]))
    {
      filtros[filter.label] = filterState[filter.key] ? 'SIM' : 'NÃO';
    }

    if (filter.type === 'text-multiple' && !isNilOrEmpty(filterState[filter.key]))
    {
      filtros[filter.label] = filterState[filter.key];
    }

    if (
      filter.type === 'number'
      && (!isNilOrEmpty(filterState[`${filter.key}Min`])
      || !isNilOrEmpty(filterState[`${filter.key}Max`]))
    )
    {
      const minStr = !isNilOrEmpty(filterState[`${filter.key}Min`])
        ? `> ${filterState[`${filter.key}Min`]}`
        : '';

      const maxStr = !isNilOrEmpty(filterState[`${filter.key}Max`])
        ? `< ${filterState[`${filter.key}Max`]}`
        : '';

      filtros[filter.label]
        = !isNilOrEmpty(minStr) && !isNilOrEmpty(maxStr)
          ? `${minStr} e ${maxStr}`
          : minStr + maxStr;
    }

    // if (
    //   filter.type === 'money'
    //   && (!isNilOrEmpty(filterState[`${filter.key}Min`])
    //   || !isNilOrEmpty(filterState[`${filter.key}Max`]))
    // )
    // {
    //   const minStr = !isNilOrEmpty(filterState[`${filter.key}Min`])
    //     ? `> R$ ${formatDinheiro(filterState[`${filter.key}Min`])}`
    //     : '';

    //   const maxStr = !isNilOrEmpty(filterState[`${filter.key}Max`])
    //     ? `< R$ ${formatDinheiro(filterState[`${filter.key}Max`])}`
    //     : '';

    //   filtros[filter.label]
    //     = !isNilOrEmpty(minStr) && !isNilOrEmpty(maxStr)
    //       ? `${minStr} e ${maxStr}`
    //       : minStr + maxStr;
    // }
  });

  state.usedFilters = filtros;
}

function onDrawerClose()
{
  state.filtersIsOpened = false;
}

function updateState()
{
  updateUsedFilters();
  updateTotalUsedFilters();
  updateGraphQLVariables();
}

function onDrawerSearchClick()
{
  updateQueryString();
  updateState();
  state.filtersIsOpened = false;
}

function onDrawerResetClick()
{
  resetFilterState();
}

function onResetClick()
{
  resetFilterState();
  updateQueryString();
  updateState();
  emit('filterReset');
}

function onTextSearch()
{
  updateQueryString();
  updateState();
}

function initializeState()
{
  initializaFilterState();

  if (props.keepHistory)
  {
    queryToState();
  }

  updateState();
}

async function onExportarParaExcelClick()
{
  if (props.exportCommand)
  {
    await jobCommand(props.exportCommand, { variables: gqlVariables.value }, props.exportJobType ?? tiposJobs.Command);
  }
}

initializeState();

const intervalId = ref<any>(null);

watch(() => result.value, (val) =>
{
  if (val)
  {
    dataSource.value = val.data.items;
    paginationState.total = val.data.totalCount;
  }
});

watch(() => props.variables, async () =>
{
  updateGraphQLVariables();
});

watch(jobCommandStatus, (val) =>
{
  if (val === 'error')
  {
    notification.error({
      message: 'Tivemos um problema inesperado ao tentar exportar a tabela',
    });
  }
});

onError((_) =>
{
  dataSource.value = [];
  paginationState.total = 0;
});

onMounted(async () =>
{
  await load();

  if (props.reloadInterval)
  {
    intervalId.value = setInterval(async () =>
    {
      await refetch();
    }, props.reloadInterval);
  }
});

onUnmounted(() =>
{
  if (intervalId.value)
  {
    clearInterval(intervalId.value);
  }
});

defineExpose({
  getDataSource,
  reloadAsync: refetch,
});
</script>

<template>
  <div>
    <a-row
      :gutter="8"
      style="margin-bottom: 16px"
      type="flex"
    >
      <a-col flex="auto">
        <a-input-search
          v-if="props.textSearch"
          v-model:value="state.text"
          size="large"
          :placeholder="props.textSearch?.placeholder"
          :allow-clear="true"
          enter-button
          @search="onTextSearch"
        />
      </a-col>
      <slot name="leftTopActions" />
      <a-col
        v-if="props.showReloadButton"
        flex="50px"
      >
        <a-button
          size="large"
          @click="refetch"
        >
          <template #icon>
            <ReloadOutlined />
          </template>Recarregar
        </a-button>
      </a-col>
      <a-col
        v-if="props.filters?.length > 0"
        flex="50px"
      >
        <a-badge :count="state.totalUsedFilters">
          <a-button
            size="large"
            @click="() => (state.filtersIsOpened = true)"
          >
            <template #icon>
              <FilterOutlined />
            </template>Filtros
          </a-button>
        </a-badge>
      </a-col>
      <slot name="topActions" />
    </a-row>
    <a-alert
      v-if="state.totalUsedFilters > 0"
      :show-icon="false"
      banner
    >
      <template #message>
        <a-row align="middle">
          <a-col :span="20">
            <span style="font-weight: 600; margin-right: 4px">Filtros usados <a-divider type="vertical" /></span>
            <span
              v-for="([key, value], index) in Object.entries(
                state.usedFilters,
              ).filter(([k, v]) => !isNilOrEmpty(v))"
              :key="key"
            >
              <a-divider
                v-if="index > 0"
                type="vertical"
              />
              <span style="font-weight: 500">{{ key }}</span>: {{ value }}
            </span>
          </a-col>
          <a-col
            :span="4"
            style="text-align: right"
          >
            <a
              href="#"
              @click.prevent="onResetClick"
            >Resetar filtros</a>
          </a-col>
        </a-row>
      </template>
    </a-alert>
    <a-table
      class="graphql-table"
      :loading="loading"
      :columns="props.columns"
      :data-source="dataSource"
      :row-key="props.rowKey"
      :bordered="props.bordered"
      :pagination="false"
      :row-selection="props.rowSelection"
      @change="
        (pagination, filters, sorter) =>
          onTableChange(pagination, filters, sorter)
      "
    >
      <template #emptyText>
        <a-result
          v-if="error"
          status="500"
          title="Tivemos um problema inesperado"
          sub-title="Favor tentar novamente mais tarde"
        >
          <template #extra>
            <a-button @click="refetch">
              Tentar novamente
            </a-button>
          </template>
        </a-result>
        <a-empty
          v-else
          description="Nenhum resultado encontrado"
        />
      </template>

      <template #bodyCell="{ record, column }">
        <slot
          :key="column.key"
          :name="`${column.key}Column`"
          :record="record"
          :column="column"
        />
      </template>

      <template #footer>
        <a-row align="middle">
          <a-col :span="6">
            <span class="table-resultado-span">Resultados: {{ paginationState.total }}
            </span>
          </a-col>
          <a-col
            :span="18"
            style="text-align: right"
          >
            <a-space v-if="props.exportCommand">
              <a-alert
                v-if="paginationState.total >= 10000"
                type="warning"
                message="A busca gerou muitos resultados, e a exportação pode levar alguns minutos."
                show-icon
              />
              <a-button
                :loading="jobCommandStatus === 'loading'"
                @click="onExportarParaExcelClick"
              >
                <template #icon>
                  <DownloadOutlined />
                </template>
                Exportar para excel
              </a-button>

              <a-button
                v-if="jobCommandStatus === 'success' && jobCommandData?.resultado"
                type="link"
                :href="
                  getGoogleDriveUrl(
                    jobCommandData?.resultado
                      .fileServiceId,
                  )
                "
                target="_blank"
              >
                Visualizar última exportação
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </template>
    </a-table>
    <a-row>
      <a-col
        :span="24"
        style="text-align: right; padding-right: 16px; padding-top: 16px"
      >
        <a-pagination
          :current="paginationState.currentPage"
          :page-size="paginationState.totalPerPage"
          :disabled="loading"
          :show-size-changer="true"
          :page-size-options="props.pageSizeOptions"
          :total="paginationState.total"
          @change="onPaginationChange"
        />
      </a-col>
    </a-row>
    <a-drawer
      :visible="state.filtersIsOpened"
      :destroy-on-close="true"
      :mask-closable="true"
      :closable="true"
      title="FILTROS"
      width="600"
      @close="onDrawerClose"
    >
      <div style="padding: 24px">
        <a-form layout="vertical">
          <a-form-item
            v-for="filter in props.filters"
            :key="filter.key"
            :label="filter.label"
          >
            <GqlSelect
              v-if="filter.type === 'gql-select'"
              v-model:value="filterState[filter.key]"
              v-bind="filter.componentProps"
              mode="multiple"
            />
            <a-range-picker
              v-if="filter.type === 'date'"
              v-model:value="filterState[filter.key]"
              v-bind="filter.componentProps"
            />
            <a-input
              v-if="filter.type === 'text'"
              v-model:value="filterState[filter.key]"
              v-bind="filter.componentProps"
            />
            <a-radio-group
              v-if="filter.type === 'boolean'"
              v-model:value="filterState[filter.key]"
            >
              <a-radio-button :value="true">
                Sim
              </a-radio-button>
              <a-radio-button :value="false">
                Não
              </a-radio-button>
              <a-radio-button :value="null">
                Nenhum
              </a-radio-button>
            </a-radio-group>
            <a-input
              v-if="filter.type === 'text-multiple'"
              v-model:value="filterState[filter.key]"
              v-bind="filter.componentProps"
            />
            <a-input-group
              v-if="filter.type === 'number'"
              compact
            >
              <a-input-number
                v-model:value="filterState[`${filter.key}Min`]"
                :controls="false"
                style="width: 47%"
                placeholder="Mínimo"
                v-bind="filter.componentProps"
              />
              <a-input
                style="
                width: 6%;
                border-left: 0;
                pointer-events: none;
                background-color: #fff;
              "
                placeholder="~"
                disabled
                v-bind="filter.componentProps"
              />
              <a-input-number
                v-model:value="filterState[`${filter.key}Max`]"
                :controls="false"
                style="width: 47%; border-left: 0"
                placeholder="Maximo"
                v-bind="filter.componentProps"
              />
            </a-input-group>
          <!-- <a-input-group v-if="filter.type === 'money'" compact>
            <InputMoney
              v-model="filterState[`${filter.key}Min`]"
              :money="{
                locale: 'pt-BR',
                currency: 'BRL',
                currencyDisplay: 'symbol',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: true,
              }"
              v-bind="filter.componentProps"
              style="width: 47%"
              placeholder="Mínimo"
            />
            <a-input
              style="
                width: 6%;
                border-left: 0;
                pointer-events: none;
                background-color: #fff;
              "
              placeholder="~"
              disabled
              v-bind="filter.componentProps"
            />
            <InputMoney
              v-model="filterState[`${filter.key}Max`]"
              :money="{
                locale: 'pt-BR',
                currency: 'BRL',
                currencyDisplay: 'symbol',
                hideCurrencySymbolOnFocus: false,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: false,
                autoDecimalDigits: true,
              }"
              v-bind="filter.componentProps"
              style="width: 47%; border-left: 0"
              placeholder="Maximo"
            />
          </a-input-group> -->
          </a-form-item>
        </a-form>
        <a-row>
          <a-col :span="24">
            <a-button
              :block="true"
              type="primary"
              style="margin-right: 8px; min-width: 100px"
              size="large"
              @click.prevent="onDrawerSearchClick"
            >
              Buscar
            </a-button>
          </a-col>
        </a-row>
        <a-row style="margin-top: 8px">
          <a-col :span="24">
            <a-button
              :block="true"
              style="margin-right: 8px; min-width: 100px"
              size="large"
              @click.prevent="onDrawerResetClick"
            >
              Resetar
            </a-button>
          </a-col>
        </a-row>
      </div>
    </a-drawer>
  </div>
</template>

<style>
.graphql-table {
  & .table-resultado-span {
    color: #9a9a9a;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}
</style>
