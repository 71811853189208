<script setup>
import { LockOutlined } from '@ant-design/icons-vue';
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';

import { useStore } from './store';

const store = useStore();
const route = useRoute();
const token = route.query.t ? route.query.t : '';
const formRef = ref(null);
const formModel = store.$state;
const formRules = reactive({
  senha: [{ required: true, message: 'Senha é obrigatória' }],
});

onUnmounted(() =>
{
  store.$reset();
  store.$dispose();
});

async function resetarSenha()
{
  await store.resetarSenha(token);
}
</script>

<template>
  <a-layout class="resetarsenha-layout">
    <a-layout-content>
      <div class="resetarsenha-page">
        <a-card class="resetarsenha-card">
          <a-result
            v-if="store.status === 'success'"
            status="success"
            title="Sua senha foi resetada com sucesso"
            sub-title="Acesse o escritório agora"
          >
            <template #extra>
              <a-button type="primary" @click="() => $router.push('/login')">
                Acesse o escritório
              </a-button>
            </template>
          </a-result>

          <a-alert
            v-if="store.status === 'error'"
            style="margin-bottom: 32px"
            :message="store.error"
            type="error"
          />

          <div v-if="store.status !== 'success'" class="form-container">
            <div class="logo-container">
              <img src="/images/logo-122x122.png">
            </div>

            <div style="text-align: center">
              <h2 class="titulo">
                Resetar senha
              </h2>
            </div>

            <p class="subtitulo">
              Crie uma nova senha para acessar o escritório
            </p>

            <a-form
              ref="formRef"
              layout="vertical"
              :model="formModel"
              :rules="formRules"
              :hide-required-mark="true"
              @finish="resetarSenha"
            >
              <a-form-item
                label="Nova senha"
                name="senha"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-password
                  v-model:value="formModel.senha"
                  size="large"
                  type="password"
                >
                  <template #prefix>
                    <LockOutlined style="color: #d9d9d9" />
                  </template>
                </a-input-password>
              </a-form-item>
              <a-row>
                <a-col :span="24">
                  <a-button
                    :loading="store.status === 'loading'"
                    size="large"
                    :block="true"
                    type="primary"
                    html-type="submit"
                  >
                    Resetar
                  </a-button>
                </a-col>
              </a-row>
            </a-form>
            <a-row style="margin-top: 24px" type="flex" justify="end">
              <a-col>
                <router-link to="/login">
                  Ir para login
                </router-link>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </div>
    </a-layout-content>
  </a-layout>
</template>

<style lang="less">
@import '../../../assets/styles/base.less';
.resetarsenha-layout {
  &.ant-layout {
    height: 100%;
  }
}

.resetarsenha-page {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  & .ant-alert-message {
    font-weight: 600;
  }

  & .resetarsenha-card.ant-card {
    padding-top: 48px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 56px;

    border: 0;
    width: 480px;
    @media (min-width: @screen-md-min) {
      border: 1px solid #f0f0f0;
      width: 550px;
    }

    & .ant-card-body {
      padding: 0;
    }

    & img {
      width: 96px;
      height: auto;
    }

    & .logo-container {
      margin-bottom: 24px;
      text-align: center;
    }

    & .titulo {
      color: @logo-black;
      margin-bottom: 0;

      font-size: 30px;
      @media (min-width: @screen-md-min) {
        font-size: 36px;
      }
    }

    & .subtitulo {
      margin-bottom: 32px;
      text-align: center;
      font-size: 14px;
    }

    & label {
      font-size: 16px;
    }

    & .ant-input-affix-wrapper .ant-input-prefix {
      margin-right: 12px;
    }
  }
}
</style>
