<script setup lang="ts">
import { SaveOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';

const emit = defineEmits(['close']);

const planilhaRef = ref<any>(null);
const submitButtonLoading = ref<boolean>(false);

async function preSubmit(_: any)
{
  submitButtonLoading.value = true;

  await planilhaRef.value.upload();

  return true;
}

async function postSubmit(_: any)
{
  submitButtonLoading.value = false;
}
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle
      :closable="false"
      @close="() => emit('close')"
    >
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          IMPORTAR PLANILHA BRADESCO</span>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <CompromissoCommandForm
        command="ImportarPlanilhaBradescoCommand"
        :show-upload-files="false"
        :show-job-progress="false"
        :pre-submit="preSubmit"
        :post-submit="postSubmit"
        @close="() => emit('close')"
      >
        <template #form="{ model }">
          <a-form-item
            label="Data remessa"
            name="dataRemessa"
            :rules="{
              message: 'Data da remessa é obrigatória',
              required: true,
              trigger: 'change',
            }"
          >
            <a-date-picker
              v-model:value="model.dataRemessa"
              format="DD/MM/YYYY"
              value-format="DD/MM/YYYY"
              placeholder="Selecione uma data"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item
            label="Planilha"
            name="planilha"
            :rules="{
              message: 'Planilha é obrigatória',
              required: true,
              trigger: 'change',
              type: 'object',
            }"
          >
            <UploadFile
              ref="planilhaRef"
              v-model:file="model.planilha"
            />
          </a-form-item>
        </template>
        <template #submitButton="{ submit }">
          <a-button
            :loading="submitButtonLoading"
            type="primary"
            size="large"
            @click.prevent="submit"
          >
            <template #icon>
              <SaveOutlined />
            </template>
            Salvar
          </a-button>
        </template>
      </CompromissoCommandForm>
    </div>
  </div>
</template>
