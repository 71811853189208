<script setup>
import { ref } from 'vue';
import { CaretDownFilled, CaretDownOutlined, DownOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';
import PERFIL_ACESSO from '~/perfilAcesso.js';

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const dataTableRef = ref(null);

function abrirCompromissoForm(opts)
{
  drawerStore.push2({
    componentName: 'CompromissoForm',
    width: opts?.width ?? 1000,
    params: {
      title: opts?.title ?? 'SEM TÍTULO',
      componentName: opts.componentName,
    },
    onClose: async () =>
    {
      if (dataTableRef.value)
      {
        dataTableRef.value?.reloadAsync();
      }
    },
  });
}

function abrirSolicitarCadastroProcessoDistribuido()
{
  abrirCompromissoForm({
    componentName: 'SolicitarCadastroProcessoDistribuidoForm',
    title: 'SOLICITAR CADASTRO DE PROCESSO DISTRIBUÍDO',
    params: {},
  });
}

function abrirSolicitarCadastroProcessoRedistribuido()
{
  abrirCompromissoForm({
    componentName: 'SolicitarCadastroProcessoRedistribuidoForm',
    title: 'SOLICITAR CADASTRO DE PROCESSO REDISTRIBUÍDO',
    params: {},
  });
}

function abrirSolicitarCadastroProcessoTrabalhista()
{
  abrirCompromissoForm({
    componentName: 'SolicitarCadastroProcessoTrabalhistaForm',
    title: 'SOLICITAR CADASTRO DE PROCESSO TRABALHISTA',
    params: {},
  });
}

function abrirSolicitarCadastroProcessoContrario()
{
  abrirCompromissoForm({
    componentName: 'SolicitarCadastroProcessoContrarioForm',
    title: 'SOLICITAR CADASTRO DE PROCESSO CONTRÁRIO',
    params: {},
  });
}

function abrirSolicitarCadastroProcessoAvulso()
{
  abrirCompromissoForm({
    componentName: 'SolicitarCadastroProcessoAvulsoForm',
    title: 'SOLICITAR CADASTRO DE PROCESSO AVULSO',
    params: {},
  });
}

function onMenuClick({ key })
{
  if (key === 'solicitar-cadastro-processo-distribuido')
  {
    abrirSolicitarCadastroProcessoDistribuido();
  }

  if (key === 'solicitar-cadastro-processo-redistribuido')
  {
    abrirSolicitarCadastroProcessoRedistribuido();
  }

  if (key === 'solicitar-cadastro-processo-trabalhista')
  {
    abrirSolicitarCadastroProcessoTrabalhista();
  }

  if (key === 'solicitar-cadastro-processo-contrario')
  {
    abrirSolicitarCadastroProcessoContrario();
  }

  if (key === 'solicitar-cadastro-processo-avulso')
  {
    abrirSolicitarCadastroProcessoAvulso();
  }
}
</script>

<template>
  <Page menu="pastas">
    <div class="pastas-page">
      <a-row
        justify="center"
        style="padding-bottom: 24px"
      >
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/pastas">
                    Pastas
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar pastas
              </h1>
            </a-col>
            <a-col :span="12">
              <div class="page-header-button-container">
                <a-dropdown
                  v-if="
                    authStore.temPerfilAcesso(PERFIL_ACESSO.Protocolo)
                      || authStore.ehGestor
                  "
                >
                  <a-button size="large">
                    Menu principal <CaretDownFilled />
                  </a-button>
                  <template #overlay>
                    <a-menu @click="onMenuClick">
                      <a-menu-item
                        key="solicitar-cadastro-processo-distribuido"
                      >
                        Adicionar processo distribuído
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-cadastro-processo-redistribuido"
                      >
                        Adicionar processo redistribuído
                      </a-menu-item>
                      <a-menu-item
                        key="solicitar-cadastro-processo-trabalhista"
                      >
                        Adicionar processo trabalhista
                      </a-menu-item>
                      <a-menu-item key="solicitar-cadastro-processo-contrario">
                        Adicionar processo contrário
                      </a-menu-item>
                      <a-menu-item key="solicitar-cadastro-processo-avulso">
                        Adicionar processo avulso
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
          <!-- /Page header -->
          <div style="margin-top: 24px">
            <PastaGqlDatatable
              ref="dataTableRef"
              :enable-row-selection="true"
            />
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
