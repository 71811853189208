<script setup>
import { useGraphQL } from '~/composables/useGraphQL';
import { useSalvarUsuario } from '~/composables/usuarios/useSalvarUsuario';
import tipoPerfil from '~/constants/tipoPerfil';
import { useAuthStore } from '~/stores/auth';
import { notification } from 'ant-design-vue';
import { onMounted, reactive, ref } from 'vue';

const props = defineProps({
  codigo: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['close']);
const authStore = useAuthStore();

const {
  status: usuarioQueryStatus,
  runAsync: usuarioQueryAsync,
  data: usuarioQueryData,
} = useGraphQL({
  query: 'UsuarioQuery',
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
});

const {
  status: salvarUsuarioStatus,
  runAsync: salvarUsuario,
  err: salvarUsuarioErr,
} = useSalvarUsuario();

const formRef = ref(null);
const state = reactive({
  codigoUsuario: null,
  email: null,
  nome: null,
  cpf: null,
  timezone: null,
  perfisAcesso: [],
});
const formRules = reactive({
  email: [{ required: true, message: 'Email é obrigatório' }],
  nome: [{ required: true, message: 'Nome é obrigatório' }],
});
const timezoneOptions = ref([
  { label: '(UTC-04:00) Manaus', value: 'SA Western Standard Time' },
  {
    label: '(UTC-03:00) Brasília',
    value: 'E. South America Standard Time',
  },
]);

const uiUsuarioQueryAsync = async () => {
  await usuarioQueryAsync({ codigo: props.codigo });

  if (usuarioQueryStatus.value === 'success') {
    state.codigoUsuario = usuarioQueryData.value.usuario.codigo;
    state.email = usuarioQueryData.value.usuario.email;
    state.nome = usuarioQueryData.value.usuario.nome;
    state.cpf = usuarioQueryData.value.usuario.cpf;
    state.timezone = usuarioQueryData.value.usuario.timezone;
    state.perfisAcesso = usuarioQueryData.value.usuario.perfisAcesso.map(
      (p) => ({
        key: p.perfilValue,
        value: p.perfilValue,
        label: p.perfilText.toUpperCase(),
      })
    );
  }
};

const uiSalvarUsuario = async () => {
  formRef.value.validate().then(async () => {
    const cmd = {
      codigoUsuario: state.codigoUsuario,
      email: state.email,
      nome: state.nome,
      cpf: state.cpf,
      timezone: state.timezone?.value,
      perfisAcesso: [...state.perfisAcesso.map((x) => x.value)],
    };

    await salvarUsuario(cmd);

    if (salvarUsuarioStatus.value === 'error') {
      notification.error({
        message: salvarUsuarioErr.value.message,
        description: salvarUsuarioErr.value.description,
        duration: 5,
      });
    }
  });
};

onMounted(async () => {
  if (!props.codigo) {
    usuarioQueryStatus.value = 'success';
  } else {
    await uiUsuarioQueryAsync();
  }
});
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle @close="() => emit('close')">
      <template #title>
        <a-skeleton
          :loading="usuarioQueryStatus === 'loading'"
          :paragraph="false"
          :active="true"
          :title="{ width: '65%' }"
        >
          <span style="font-size: 16px; font-weight: 500; line-height: 35px">
            {{ props.codigo !== null ? 'EDITAR USUÁRIO' : 'ADICIONAR USUÁRIO' }}
          </span>
        </a-skeleton>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <div v-if="usuarioQueryStatus === 'loading'">
        <a-skeleton :loading="true" :active="true" :paragraph="{ rows: 6 }" />
      </div>
      <div v-else>
        <ErrorResult
          v-if="usuarioQueryStatus === 'error'"
          :tentar-novamente-fn="uiUsuarioQueryAsync"
        />
        <div v-if="usuarioQueryStatus === 'success'">
          <a-result
            v-if="salvarUsuarioStatus === 'success'"
            status="success"
            title="Usuário salvo com sucesso"
            sub-title="Agora você pode fechar essa janela"
          >
            <template #extra>
              <a-button @click="() => emit('close')"> Fechar </a-button>
            </template>
          </a-result>
          <div v-else>
            <a-form
              ref="formRef"
              layout="vertical"
              :model="state"
              :rules="formRules"
            >
              <a-form-item label="Email" name="email">
                <a-input v-model:value="state.email" />
              </a-form-item>

              <a-form-item label="Nome" name="nome">
                <a-input v-model:value="state.nome" />
              </a-form-item>

              <a-form-item label="CPF" name="cpf">
                <a-input v-model:value="state.cpf" v-maska="'###.###.###-##'" />
              </a-form-item>

              <a-form-item label="Timezone" name="timezone">
                <a-select
                  v-model:value="state.timezone"
                  :options="timezoneOptions"
                  :default-active-first-option="true"
                  :label-in-value="true"
                />
              </a-form-item>

              <a-form-item
                v-if="
                  authStore.temPerfilAcesso(tipoPerfil.AlterarPerfilUsuario)
                "
                label="Perfil de acesso"
                name="perfisAcesso"
              >
                <GraphQlSelect
                  v-model:value="state.perfisAcesso"
                  :variables="{
                    name: 'TipoPerfil',
                  }"
                  query="EnumAsSelectItem"
                  mode="multiple"
                  placeholder="Selecione os perfis de acesso"
                />
              </a-form-item>

              <a-form-item style="margin-top: 24px">
                <a-button
                  :loading="salvarUsuarioStatus === 'loading'"
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click="uiSalvarUsuario"
                >
                  Salvar
                </a-button>
                <a-button @click="() => emit('close')"> Fechar </a-button>
              </a-form-item>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
