<script setup>
import { onMounted } from 'vue';

const props = defineProps({
  compromisso: {
    type: Object,
    required: true,
  },
  fluxo: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['loaded']);

onMounted(() => {
  emit('loaded');
});
</script>

<template>
  <a-descriptions :column="3" :label-style="{ width: '20%' }" bordered>
    <a-descriptions-item label="Sistema" :span="3">
      {{ props.fluxo.andamento.sistemaTribunalText }}
    </a-descriptions-item>
    <a-descriptions-item label="Data" :span="3">
      {{ props.fluxo.andamento.dataMovimentacaoText }}
    </a-descriptions-item>
    <a-descriptions-item label="Movimentação" :span="3">
      {{ props.fluxo.andamento.movimentacao }}
    </a-descriptions-item>
  </a-descriptions>
</template>
