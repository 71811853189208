<script setup lang="ts">
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { create as maskCreate } from 'maska';
import usuarioSelect from '~/graphql/usuarioSelect.graphql';
import alterarPastaForm from '~/graphql/alterarPastaForm.graphql';
import pastaSelect from '~/graphql/pastaSelect.graphql';
import enumSelect from '~/graphql/enumSelect.graphql';
import pessoaSelect from '~/graphql/pessoaSelect.graphql';
import agenciaSelect from '~/graphql/agenciaSelect.graphql';
import estadoSelect from '~/graphql/estadoSelect.graphql';
import cidadeSelect from '~/graphql/cidadeSelect.graphql';
import acaoSelect from '~/graphql/acaoSelect.graphql';
import orgaoSelect from '~/graphql/orgaoSelect.graphql';
import justicaSelect from '~/graphql/justicaSelect.graphql';
import instanciaSelect from '~/graphql/instanciaSelect.graphql';
import classesSelect from '~/graphql/classesSelect.graphql';
import assuntoSelect from '~/graphql/assuntoSelect.graphql';
import comarcaSelect from '~/graphql/comarcaSelect.graphql';
import { isNilOrEmpty, onlyAlphanumeric } from '~/utils';
import { useAuthStore } from '~/stores/auth';

interface Props
{
  compromisso?: any
  pastaCodigos?: string[]
  mostrarTodos?: boolean
  mostrarCampos?: string[]
}

const props = withDefaults(defineProps<Props>(), {
  mostrarTodos: true,
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const authStore = useAuthStore();

const skipQuery = computed(() =>
{
  if (props.pastaCodigos?.length === 1)
  {
    return false;
  }

  if (props.compromisso)
  {
    return false;
  }

  return true;
});

const collapsesAtivos = ref([
  'dadosPasta',
  'clientePrincipal',
  'dadosProcessoJudicial',
  'dadosAjuizamento',
  'andamentoProcessual',
  'andamentoApreensao',
  'dadosVeiculo',
  'dadosAcordo',
  'dadosCrmDeepCenter',
]);

const cidadesSelectRef = ref<any>(null);
const comarcaSelectRef = ref<any>(null);
const instanciasSelectRef = ref<any>(null);
const classesSelectRef = ref<any>(null);
const assuntosSelectRef = ref<any>(null);

async function onEstadoSelectChange(_: any)
{
  nextTick(async () =>
  {
    await cidadesSelectRef.value.reset();
    await cidadesSelectRef.value.clearItems();
    await comarcaSelectRef.value.reset();
    await comarcaSelectRef.value.clearItems();
  });
}

async function onJusticaSelectChange(_: any)
{
  nextTick(async () =>
  {
    await comarcaSelectRef.value.reset();
    await comarcaSelectRef.value.clearItems();
    await instanciasSelectRef.value.reset();
    await instanciasSelectRef.value.clearItems();
  });
}

async function onInstanciaSelectChange(_: any)
{
  nextTick(async () =>
  {
    await classesSelectRef.value.reset();
    await classesSelectRef.value.clearItems();
    await assuntosSelectRef.value.reset();
    await assuntosSelectRef.value.clearItems();
  });
}

async function preSubmit(model: any)
{
  model.numeroCnj = onlyAlphanumeric(model.numeroCnj);
  return true;
}

let mask: any = null;

const numeroCnjInputRef = ref<any>(null);

onMounted(() =>
{
  emit('loaded');
});

onUnmounted(() =>
{
  if (mask)
  {
    mask.destroy();
  }
});

watch(numeroCnjInputRef, (val) =>
{
  if (val)
  {
    mask = maskCreate('.numero-cnj', {
      mask: '#######-##.####.#.##.####',
    });
  }
});
</script>

<template>
  <GqlView
    :query="alterarPastaForm"
    :variables="{
      compromissoCodigo: props.compromisso ? props.compromisso.codigo : null,
      pastaCodigo: props.pastaCodigos?.length === 1 ? props.pastaCodigos[0] : null,
    }"
    :skip="skipQuery"
  >
    <template #success="{ data }: {data: any}">
      <CompromissoCommandForm2
        :command="props.compromisso?.command2 ?? 'AlteracaoPasta.AlterarPastaCommand'"
        :submit-button-disabled="!isNilOrEmpty(compromisso)
          && compromisso.responsavel.codigo !== authStore.codigoUsuario"
        :initial-model="
          {
            ...data?.model ?? {},
            ... {
              tipo: {
                label: data?.model?.tipoText?.toUpperCase(),
                value: data?.model?.tipoCodigo,
              },
              tipoProcesso: {
                label: data?.model?.tipoProcessoText?.toUpperCase(),
                value: data?.model?.tipoProcessoCodigo,
              },
              status: {
                label: data?.model?.statusText?.toUpperCase(),
                value: data?.model?.statusCodigo,
              },
              empresa: {
                label: data?.model?.empresaText?.toUpperCase(),
                value: data?.model?.empresaCodigo,
              },
              clientePrincipalPapel: {
                label: data?.model?.clientePrincipalPapelText?.toUpperCase(),
                value: data?.model?.clientePrincipalPapelCodigo,
              },
              contrarioPrincipalPapel: {
                label: data?.model?.contrarioPrincipalPapelText?.toUpperCase(),
                value: data?.model?.contrarioPrincipalPapelCodigo,
              },
              natureza: {
                label: data?.model?.naturezaText?.toUpperCase(),
                value: data?.model?.naturezaCodigo,
              },
              procedimento: {
                label: data?.model?.procedimentoText?.toUpperCase(),
                value: data?.model?.procedimentoCodigo,
              },
              motivoNaoAjuizamento: {
                label: data?.model?.motivoNaoAjuizamentoText?.toUpperCase(),
                value: data?.model?.motivoNaoAjuizamentoCodigo,
              },
            },
            ...{
              compromissoCodigos: props.compromisso ? [props.compromisso.codigo] : null,
              pastaCodigos: props.pastaCodigos,
            },
          }"
        :pre-submit="preSubmit"
        @close="() => emit('close')"
        @submit-success="() => emit('success')"
      >
        <template #form="{ model }">
          <a-collapse v-model:active-key="collapsesAtivos">
            <a-collapse-panel
              key="dadosPasta"
              header="DADOS DA PASTA"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('nome')"
                label="Nome (identificador único)"
                name="nome"
                :rules="[{ message: 'Nome é obrigatório', required: true }]"
              >
                <a-input v-model:value="model.nome" />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('pastaPrincipal')"
                label="Pasta principal"
                name="pastaPrincipal"
              >
                <GqlSelect
                  v-model:value="model.pastaPrincipal"
                  :query="pastaSelect"
                  :allow-clear="true"
                  placeholder="Selecione a pasta principal"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('tipo')"
                label="Tipo"
                name="tipo"
                :rules="[{ message: 'Tipo é obrigatório', required: true, type: 'object' }]"
              >
                <GqlSelect
                  v-model:value="model.tipo"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPastaEnum' }"
                  placeholder="Selecione o tipo da pasta"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('tipoProcesso')"
                label="Tipo processo"
                name="tipoProcesso"
                :rules="[{ message: 'Tipo do processo é obrigatório', required: true, type: 'object' }]"
              >
                <GqlSelect
                  v-model:value="model.tipoProcesso"
                  :query="enumSelect"
                  :variables="{ name: 'TipoProcessoEnum' }"
                  placeholder="Selecione o tipo do processo"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('status')"
                label="Status"
                name="status"
                :rules="[{ message: 'Status é obrigatório', required: true, type: 'object' }]"
              >
                <GqlSelect
                  v-model:value="model.status"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPastaStatusEnum' }"
                  placeholder="Selecione o status"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('empresa')"
                label="Empresa"
                name="empresa"
              >
                <GqlSelect
                  v-model:value="model.empresa"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPastaEmpresaEnum' }"
                  placeholder="Selecione a empresa"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('responsavelProcessual')"
                name="responsavelProcessual"
                label="Responsável processual"
              >
                <GqlSelect
                  v-model:value="model.responsavelProcessual"
                  :query="usuarioSelect"
                  :variables="{
                    where: {
                      ativo: {
                        eq: true,
                      },
                    },
                    order: [
                      {
                        nome: 'ASC',
                      },
                    ],
                  }"
                  placeholder="Selecione o responsável"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('responsavelNegocial')"
                name="responsavelNegocial"
                label="Responsável negocial"
              >
                <GqlSelect
                  v-model:value="model.responsavelNegocial"
                  :query="usuarioSelect"
                  :variables="{
                    where: {
                      ativo: {
                        eq: true,
                      },
                    },
                    order: [
                      {
                        nome: 'ASC',
                      },
                    ],
                  }"
                  placeholder="Selecione o responsável"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('gerenteNegocialResponsavel')"
                name="gerenteNegocialResponsavel"
                label="Gerente negocial responsável"
              >
                <GqlSelect
                  v-model:value="model.gerenteNegocialResponsavel"
                  :query="usuarioSelect"
                  placeholder="Selecione o gerente negocial responsável"
                  :allow-clear="true"
                  :variables="{ where: { ativo: { eq: true } } }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorEnvolvido')"
                label="Valor envolvido"
                name="valorEnvolvido"
              >
                <CurrencyInput
                  v-model="model.valorEnvolvido"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorEstoque')"
                label="Valor estoque"
                name="valorEstoque"
              >
                <CurrencyInput
                  v-model="model.valorEstoque"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataRemessa')"
                label="Data remessa"
                name="dataRemessa"
              >
                <a-date-picker
                  v-model:value="model.dataRemessa"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataCadastroGcpj')"
                label="Data de cadastro no GCPJ"
                name="dataCadastroGcpj"
              >
                <a-date-picker
                  v-model:value="model.dataCadastroGcpj"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                />
              </a-form-item>
            </a-collapse-panel>
            <a-collapse-panel
              key="clientePrincipal"
              header="CLIENTE PRINCIPAL"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('clientePrincipal')"
                name="clientePrincipal"
                label="Cliente"
              >
                <GqlSelect
                  v-model:value="model.clientePrincipal"
                  :query="pessoaSelect"
                  placeholder="Selecione o cliente"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('clientePrincipalPapel')"
                label="Papel do cliente"
                name="clientePrincipalPapel"
              >
                <GqlSelect
                  v-model:value="model.clientePrincipalPapel"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPapelEnvolvidoEnum' }"
                  placeholder="Selecione o papel do cliente"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('contrarioPrincipal')"
                name="contrarioPrincipal"
                label="Contrário"
              >
                <GqlSelect
                  v-model:value="model.contrarioPrincipal"
                  :query="pessoaSelect"
                  placeholder="Selecione o contrário"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('contrarioPrincipalPapel')"
                label="Papel do contrário"
                name="contrarioPrincipalPapel"
              >
                <GqlSelect
                  v-model:value="model.contrarioPrincipalPapel"
                  :query="enumSelect"
                  :variables="{ name: 'TipoPapelEnvolvidoEnum' }"
                  placeholder="Selecione o papel do contrário"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('gcpj')"
                label="GCPJ"
                name="gcpj"
              >
                <a-input
                  v-model:value="model.gcpj"
                  :maxlength="10"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('gcpjAtivo')"
                label="Status do GCPJ"
                name="gcpjAtivo"
              >
                <a-radio-group v-model:value="model.gcpjAtivo">
                  <a-radio-button :value="true">
                    Ativo
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Encerrado
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dejur')"
                label="Departamento jurídico"
                name="dejur"
              >
                <a-input
                  v-model:value="model.dejur"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('empresaBanco')"
                label="Empresa/Banco"
                name="empresaBanco"
              >
                <a-input
                  v-model:value="model.empresaBanco"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('agencia')"
                label="Agência"
                name="agencia"
              >
                <GqlSelect
                  v-model:value="model.agencia"
                  :query="agenciaSelect"
                  placeholder="Selecione a agência"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('conta')"
                label="Conta"
                name="conta"
              >
                <a-input
                  v-model:value="model.conta"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('contaFicticia')"
                label="Conta fictícia"
                name="contaFicticia"
              >
                <a-input
                  v-model:value="model.contaFicticia"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('estado')"
                label="Estado"
                name="estado"
              >
                <GqlSelect
                  v-model:value="model.estado"
                  :query="estadoSelect"
                  placeholder="Selecione o estado"
                  :allow-clear="true"
                  @change="onEstadoSelectChange"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('cidade')"
                name="cidade"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Cidade
                    <a-tooltip>
                      <template #title>
                        Para selecionar a cidade é necessário que o estado seja
                        escolhido.
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="cidadesSelectRef"
                  v-model:value="model.cidade"
                  :query="cidadeSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      estadoCodigo: {
                        in: !isNilOrEmpty(model.estado)
                          ? [model.estado.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="isNilOrEmpty(model.estado)"
                  placeholder="Selecione a cidade"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataFatoGerador')"
                label="Data fato gerador"
                name="dataFatoGerador"
              >
                <a-date-picker
                  v-model:value="model.dataFatoGerador"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('garantias')"
                label="Garantias"
                name="garantias"
              >
                <a-input
                  v-model:value="model.garantias"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('avalistas')"
                label="Avalistas"
                name="avalistas"
              >
                <GqlSelect
                  v-model:value="model.avalistas"
                  :query="pessoaSelect"
                  placeholder="Seleciona um ou mais avalistas"
                  :allow-clear="true"
                  mode="multiple"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('advogadoBanco')"
                label="Advogado do banco"
                name="advogadoBanco"
              >
                <a-input
                  v-model:value="model.advogadoBanco"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorContrato')"
                label="Valor contrato"
                name="valorContrato"
              >
                <CurrencyInput
                  v-model="model.valorContrato"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('informacaoSobreObito')"
                label="Informação sobre óbito"
                name="informacaoSobreObito"
              >
                <a-input
                  v-model:value="model.informacaoSobreObito"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataDeclaracaoIrrecuperabilidade')"
                label="Data de declaração de irrecuperabilidade"
                name="dataDeclaracaoIrrecuperabilidade"
              >
                <a-date-picker
                  v-model:value="model.dataDeclaracaoIrrecuperabilidade"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataEncerramento')"
                label="Data encerramento"
                name="dataEncerramento"
              >
                <a-date-picker
                  v-model:value="model.dataEncerramento"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('motivoEncerramento')"
                label="Motivo encerramento"
                name="motivoEncerramento"
              >
                <a-input
                  v-model:value="model.motivoEncerramento"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('depositarioFiel')"
                name="depositarioFiel"
                label="Depositário fiel"
              >
                <GqlSelect
                  v-model:value="model.depositarioFiel"
                  :query="pessoaSelect"
                  placeholder="Selecione o depositário fiel"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('telaOperacao')"
                label="Tela de operação"
                name="telaOperacao"
              >
                <a-input
                  v-model:value="model.telaOperacao"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataCelebracaoContratoOriginal')"
                label="Data celebração do contrato original"
                name="dataCelebracaoContratoOriginal"
              >
                <a-date-picker
                  v-model:value="model.dataCelebracaoContratoOriginal"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataVencimentoFinalParcelaContratoOriginal')"
                label="Data vencimento final da parcela do contrato original"
                name="dataVencimentoFinalParcelaContratoOriginal"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoFinalParcelaContratoOriginal"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataPrimeiraParcelaVencida')"
                label="Data primeira parcela vencida"
                name="dataPrimeiraParcelaVencida"
              >
                <a-date-picker
                  v-model:value="model.dataPrimeiraParcelaVencida"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosProcessoJudicial"
              header="DADOS DO PROCESSO JUDICIAL"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('numeroCnj')"
                label="Número CNJ"
                name="numeroCnj"
              >
                <a-input
                  ref="numeroCnjInputRef"
                  v-model:value="model.numeroCnj"
                  class="numero-cnj"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('numeroAntigo')"
                label="Número antigo"
                name="numeroAntigo"
              >
                <a-input
                  v-model:value="model.numeroAntigo"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('numeroAntigo')"
                label="Valor da causa"
                name="valorCausa"
              >
                <CurrencyInput
                  v-model="model.valorCausa"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('natureza')"
                label="Natureza"
                name="natureza"
              >
                <GqlSelect
                  v-model:value="model.natureza"
                  :query="enumSelect"
                  :variables="{ name: 'TipoNaturezaEnum' }"
                  placeholder="Selecione a natureza"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('procedimento')"
                label="Procedimento"
                name="procedimento"
              >
                <GqlSelect
                  v-model:value="model.procedimento"
                  :query="enumSelect"
                  :variables="{ name: 'TipoProcedimentoEnum' }"
                  placeholder="Selecione o procedimento"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('acao')"
                label="Ação"
                name="acao"
              >
                <GqlSelect
                  v-model:value="model.acao"
                  :query="acaoSelect"
                  placeholder="Seleciona a ação"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('orgao')"
                label="Órgão"
                name="orgao"
              >
                <GqlSelect
                  v-model:value="model.orgao"
                  :query="orgaoSelect"
                  placeholder="Selecione o órgão"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('justica')"
                label="Justiça"
                name="justica"
              >
                <GqlSelect
                  v-model:value="model.justica"
                  :query="justicaSelect"
                  placeholder="Selecione a justiça"
                  :allow-clear="true"
                  @change="onJusticaSelectChange"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('instancia')"
                name="instancia"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Instância
                    <a-tooltip>
                      <template #title>
                        Para selecionar a instância é necessário que seja
                        selecionada a justiça (CNJ).
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="instanciasSelectRef"
                  v-model:value="model.instancia"
                  :query="instanciaSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      justicaCodigo: {
                        in: !isNilOrEmpty(model.justica)
                          ? [model.justica.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="isNilOrEmpty(model.justica)"
                  placeholder="Seleciona a instância"
                  @change="onInstanciaSelectChange"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('classe')"
                name="classe"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Classe (CNJ)
                    <a-tooltip>
                      <template #title>
                        Para selecionar a classe é necessário que sejam
                        selecionados a justiça (CNJ) e a instância (CNJ).
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="classesSelectRef"
                  v-model:value="model.classe"
                  :query="classesSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      instanciaCodigo: {
                        in: !isNilOrEmpty(model.instancia)
                          ? [model.instancia.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="isNilOrEmpty(model.instancia)"
                  placeholder="Seleciona a classe"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('assunto')"
                name="assunto"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Assunto (CNJ)
                    <a-tooltip>
                      <template #title>
                        Para selecionar o assunto é necessário que sejam
                        selecionados a justiça (CNJ) e a instância (CNJ).
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="assuntosSelectRef"
                  v-model:value="model.assunto"
                  :query="assuntoSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      instanciaCodigo: {
                        in: !isNilOrEmpty(model.instancia)
                          ? [model.instancia.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="isNilOrEmpty(model.instancia)"
                  placeholder="Seleciona o assunto"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('vara')"
                label="Vara"
                name="vara"
              >
                <a-input
                  v-model:value="model.vara"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('comarca')"
                name="comarca"
              >
                <template #label>
                  <slot name="formItemLabel">
                    Comarca
                    <a-tooltip>
                      <template #title>
                        Para selecionar a comarca é necessário que sejam
                        selecionados o estado e a justiça (CNJ).
                      </template>
                      <QuestionCircleOutlined
                        style="margin-left: 4px; cursor: pointer"
                      />
                    </a-tooltip>
                  </slot>
                </template>
                <GqlSelect
                  ref="comarcaSelectRef"
                  v-model:value="model.comarca"
                  :query="comarcaSelect"
                  :allow-clear="true"
                  :variables="{
                    where: {
                      estadoCodigo: {
                        in: !isNilOrEmpty(model.estado)
                          ? [model.estado.value]
                          : [],
                      },
                      justicaCodigo: {
                        in: !isNilOrEmpty(model.justica)
                          ? [model.justica.value]
                          : [],
                      },
                    },
                  }"
                  :disabled="
                    isNilOrEmpty(model.estado)
                      || isNilOrEmpty(model.justica)
                  "
                  placeholder="Seleciona a comarca"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('atualizacaoValorCausa')"
                label="Atualização do valor da causa"
                name="atualizacaoValorCausa"
              >
                <CurrencyInput
                  v-model="model.atualizacaoValorCausa"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataAtualizacaoValorCausa')"
                label="Data atualização valor da causa"
                name="dataAtualizacaoValorCausa"
              >
                <a-date-picker
                  v-model:value="model.dataAtualizacaoValorCausa"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
            </a-collapse-panel>
            <a-collapse-panel
              key="dadosAjuizamento"
              header="DADOS DO AJUIZAMENTO"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('motivoNaoAjuizamento')"
                label="Motivo do não ajuizamento"
              >
                <GqlSelect
                  v-model:value="model.motivoNaoAjuizamento"
                  :variables="{
                    name: 'TipoMotivoNaoAjuizamentoEnum',
                  }"
                  :allow-clear="true"
                  :query="enumSelect"
                  placeholder="Selecione o motivo do não ajuizamento"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataAjuizamento')"
                label="Data ajuizamento"
                name="dataAjuizamento"
              >
                <a-date-picker
                  v-model:value="model.dataAjuizamento"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  :allow-clear="true"
                  style="width: 100%"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('custasProtesto')"
                label="Custas protesto"
                name="custasProtesto"
              >
                <CurrencyInput
                  v-model="model.custasProtesto"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('custasIniciais')"
                label="Custas iniciais"
                name="custasIniciais"
              >
                <CurrencyInput
                  v-model="model.custasIniciais"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('custasOficialJustica')"
                label="Custas oficial de justiça"
                name="custasOficialJustica"
              >
                <CurrencyInput
                  v-model="model.custasOficialJustica"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
            </a-collapse-panel>
            <a-collapse-panel
              key="andamentoProcessual"
              header="ANDAMENTO PROCESSUAL"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataHabilitacao')"
                label="Data habilitação"
                name="dataHabilitacao"
              >
                <a-date-picker
                  v-model:value="model.dataHabilitacao"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('procuracaoFls')"
                label="Procuração Fls"
                name="procuracaoFls"
              >
                <a-input
                  v-model:value="model.procuracaoFls"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('arquivamentoProvisorio')"
                label="Arquivamento provisório"
                name="arquivamentoProvisorio"
              >
                <a-input
                  v-model:value="model.arquivamentoProvisorio"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataSuspensaoAusenciaBensPenhoraveis')"
                label="Data suspensão por ausência de bens penhoráveis"
                name="dataSuspensaoAusenciaBensPenhoraveis"
              >
                <a-date-picker
                  v-model:value="model.dataSuspensaoAusenciaBensPenhoraveis"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataLiminar')"
                label="Data liminar"
                name="dataLiminar"
              >
                <a-date-picker
                  v-model:value="model.dataLiminar"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataCitacao')"
                label="Data citação"
                name="dataCitacao"
              >
                <a-date-picker
                  v-model:value="model.dataCitacao"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('solicitacaoConversaoExecucao')"
                label="Solicitação de conversão em execução?"
                name="solicitacaoConversaoExecucao"
              >
                <a-radio-group
                  v-model:value="model.solicitacaoConversaoExecucao"
                >
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não aplicável
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataSolicitacaoConversao')"
                label="Data solicitação conversão em execução"
                name="dataSolicitacaoConversao"
              >
                <a-date-picker
                  v-model:value="model.dataSolicitacaoConversaoExecucao"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataConversao')"
                label="Data conversão em execução"
                name="dataConversao"
              >
                <a-date-picker
                  v-model:value="model.dataConversaoExecucao"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataSentenca')"
                label="Data sentença"
                name="dataSentenca"
              >
                <a-date-picker
                  v-model:value="model.dataSentenca"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataBaixa')"
                label="Data baixa"
                name="dataBaixa"
              >
                <a-date-picker
                  v-model:value="model.dataBaixa"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataResultado')"
                label="Data resultado"
                name="dataResultado"
              >
                <a-date-picker
                  v-model:value="model.dataResultado"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('motivoResultado')"
                label="Motivo resultado"
                name="motivoResultado"
              >
                <a-input
                  v-model:value="model.motivoResultado"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('resultado')"
                label="Resultado"
                name="resultado"
              >
                <a-input
                  v-model:value="model.resultado"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('revelia')"
                label="Revelia?"
                name="revelia"
              >
                <a-radio-group v-model:value="model.revelia">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('fundamentoSentenca')"
                label="Fundamento da sentença"
                name="fundamentoSentenca"
              >
                <a-input
                  v-model:value="model.fundamentoSentenca"
                  :allow-clear="true"
                />
              </a-form-item>
            </a-collapse-panel>

            <a-collapse-panel
              key="andamentoApreensao"
              header="ANDAMENTO DA APREENSÃO"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataApreensao')"
                label="Data apreensão"
                name="dataApreensao"
              >
                <a-date-picker
                  v-model:value="model.dataApreensao"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('aptoApreensao')"
                label="Apto para apreensão?"
                name="aptoApreensao"
              >
                <a-radio-group v-model:value="model.aptoApreensao">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('inviavel')"
                label="Inviável?"
                name="inviavel"
              >
                <a-radio-group v-model:value="model.inviavel">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('apreensaoVeiculo')"
                label="Apreensão veículo?"
                name="apreensaoVeiculo"
              >
                <a-radio-group v-model:value="model.apreensaoVeiculo">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não se aplica
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-collapse-panel>

            <a-collapse-panel
              key="dadosVeiculo"
              header="DADOS DO VEÍCULO"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('placa')"
                label="Placa"
                name="placa"
              >
                <a-input
                  v-model:value="model.placa"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('chassiRenavan')"
                label="Chassi/Renavan"
                name="chassiRenavan"
              >
                <a-input
                  v-model:value="model.chassiRenavan"
                  :allow-clear="true"
                />
              </a-form-item>
            </a-collapse-panel>

            <a-collapse-panel
              key="dadosAcordo"
              header="DADOS DO ACORDO"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('teveAcordo')"
                label="Teve acordo?"
                name="teveAcordo"
              >
                <a-radio-group v-model:value="model.teveAcordo">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('responsavelAcordo')"
                name="responsavelAcordo"
                label="Responsável pelo acordo/apreensão"
              >
                <GqlSelect
                  v-model:value="model.responsavelAcordo"
                  :query="usuarioSelect"
                  :variables="{
                    where: {
                      ativo: {
                        eq: true,
                      },
                    },
                    order: [
                      {
                        nome: 'ASC',
                      },
                    ],
                  }"
                  placeholder="Selecione o responsável pelo acordo/apreensão"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('tipoAcordo')"
                label="Tipo do acordo"
                name="tipoAcordo"
              >
                <a-input
                  v-model:value="model.tipoAcordo"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataAcordo')"
                label="Data acordo"
                name="dataAcordo"
              >
                <a-date-picker
                  v-model:value="model.dataAcordo"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('quantidadeParcelas')"
                label="Quantidade de parcelas"
                name="quantidadeParcelas"
              >
                <a-input
                  v-model:value="model.quantidadeParcelas"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorTotalAcordo')"
                label="Valor total do acordo"
                name="valorTotalAcordo"
              >
                <CurrencyInput
                  v-model="model.valorTotalAcordo"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorEntradaAcordo')"
                label="Valor entrada do acordo"
                name="valorEntradaAcordo"
              >
                <CurrencyInput
                  v-model="model.valorEntradaAcordo"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorFinanciadoAcordo')"
                label="Valor financiado do acordo"
                name="valorFinanciadoAcordo"
              >
                <CurrencyInput
                  v-model="model.valorFinanciadoAcordo"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('taxaJurosAcordo')"
                label="Taxa de juros do acordo (A.M.)"
                name="taxaJurosAcordo"
              >
                <a-input-number
                  v-model:value="model.taxaJurosAcordo"
                  :min="0"
                  :max="100"
                  :step="0.01"
                  decimal-separator=","
                  :allow-clear="true"
                  :controls="false"
                />
                <span style="margin-left: 6px">%</span>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataVencimentoPrimeiraParcelaAcordo')"
                label="Data vencimento primeira parcela acordo"
                name="dataVencimentoPrimeiraParcelaAcordo"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoPrimeiraParcelaAcordo"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataVencimentoUltimaParcelaAcordo')"
                label="Data vencimento última parcela acordo"
                name="dataVencimentoUltimaParcelaAcordo"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoUltimaParcelaAcordo"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('acordoEstaSendoCumprido')"
                label="Acordo está sendo cumprido?"
                name="acordoEstaSendoCumprido"
              >
                <a-radio-group v-model:value="model.acordoEstaSendoCumprido">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataDescumprimentoAcordo')"
                label="Data descumprimento acordo"
                name="dataDescumprimentoAcordo"
              >
                <a-date-picker
                  v-model:value="model.dataDescumprimentoAcordo"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('historicoDescumprimento')"
                label="Histórico descumprimento"
                name="historicoDescumprimento"
              >
                <a-input
                  v-model:value="model.historicoDescumprimento"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('teveContabilizacaoAcordo')"
                label="Teve contabilização do acordo?"
                name="teveContabilizacaoAcordo"
              >
                <a-radio-group v-model:value="model.teveContabilizacaoAcordo">
                  <a-radio-button :value="true">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="false">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('volumetria')"
                label="Volumetria"
                name="volumetria"
              >
                <a-input
                  v-model:value="model.volumetria"
                  :allow-clear="true"
                />
              </a-form-item>
            </a-collapse-panel>

            <a-collapse-panel
              key="dadosCrmDeepCenter"
              header="DADOS CRM DEEPCENTER"
            >
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('plano')"
                label="Plano"
                name="plano"
              >
                <a-input-number
                  v-model:value="model.plano"
                  :min="0"
                  :step="1"
                  :allow-clear="true"
                  :controls="false"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorParcelaContratoOriginal')"
                label="Valor da parcela do contrato original"
                name="valorParcelaContratoOriginal"
              >
                <CurrencyInput
                  v-model="model.valorParcelaContratoOriginal"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataVencimentoInicial')"
                label="Data vencimento inicial"
                name="dataVencimentoInicial"
              >
                <a-date-picker
                  v-model:value="model.dataVencimentoInicial"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('motivoEmenda')"
                label="Motivo emenda"
                name="motivoEmenda"
              >
                <a-input
                  v-model:value="model.motivoEmenda"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('liminarDeferida')"
                label="Liminar deferida?"
                name="liminarDeferida"
              >
                <a-radio-group v-model:value="model.liminarDeferida">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Aguardando
                  </a-radio-button>
                  <a-radio-button :value="3">
                    Não aplicável
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataRespostaLiminar')"
                label="Data resposta liminar"
                name="dataRespostaLiminar"
              >
                <a-date-picker
                  v-model:value="model.dataRespostaLiminar"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('motivoNaoDeferimentoLiminar')"
                label="Motivo não deferimento da liminar"
                name="motivoNaoDeferimentoLiminar"
              >
                <a-input
                  v-model:value="model.motivoNaoDeferimentoLiminar"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('agravoInstrumento')"
                label="Agravo de instrumento?"
                name="agravoInstrumento"
              >
                <a-radio-group v-model:value="model.agravoInstrumento">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não se aplica
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('mandadoApreensaoExpedido')"
                label="Mandado apreensão expedido?"
                name="mandadoApreensaoExpedido"
              >
                <a-radio-group v-model:value="model.mandadoApreensaoExpedido">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Aguardando
                  </a-radio-button>
                  <a-radio-button :value="3">
                    Não aplicável
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataMandadoApreensaoExpedido')"
                label="Data mandado apreensão expedido"
                name="dataMandadoApreensaoExpedido"
              >
                <a-date-picker
                  v-model:value="model.dataMandadoApreensaoExpedido"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('liberacaoVendaVeiculo')"
                label="Liberação venda do veículo?"
                name="liberacaoVendaVeiculo"
              >
                <a-radio-group v-model:value="model.liberacaoVendaVeiculo">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não se aplica
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('dataLiberacaoVendaVeiculo')"
                label="Data liberação venda do veículo"
                name="dataLiberacaoVendaVeiculo"
              >
                <a-date-picker
                  v-model:value="model.dataLiberacaoVendaVeiculo"
                  format="DD/MM/YYYY"
                  value-format="YYYY-MM-DDTHH:mm:ss.SSS"
                  placeholder="Selecione uma data"
                  style="width: 100%"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('acaoMandadoNegativo')"
                label="Ação mandado negativo"
                name="acaoMandadoNegativo"
              >
                <a-input
                  v-model:value="model.acaoMandadoNegativo"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('bemLocalizado')"
                label="Bem localizado?"
                name="bemLocalizado"
              >
                <a-radio-group v-model:value="model.bemLocalizado">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('acaoBemNaoLocalizado')"
                label="Ação bem não localizado"
                name="acaoBemNaoLocalizado"
              >
                <a-input
                  v-model:value="model.acaoBemNaoLocalizado"
                  :allow-clear="true"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('ultimoEvento')"
                label="Último evento"
                name="ultimoEvento"
              >
                <a-input
                  v-model:value="model.ultimoEvento"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('deposito')"
                label="Depósito?"
                name="deposito"
              >
                <a-radio-group v-model:value="model.deposito">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não se aplica
                  </a-radio-button>
                  <a-radio-button :value="null">
                    Nenhum
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorDeposito')"
                label="Valor deposito"
                name="valorDeposito"
              >
                <CurrencyInput
                  v-model="model.valorDeposito"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>

              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorLevantadoPeloBanco')"
                label="Valor levantado pelo banco"
                name="valorLevantadoPeloBanco"
              >
                <CurrencyInput
                  v-model="model.valorLevantadoPeloBanco"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorLevantadoPelaParte')"
                label="Valor levantado pela parte"
                name="valorLevantadoPelaParte"
              >
                <CurrencyInput
                  v-model="model.valorLevantadoPelaParte"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('penhora')"
                label="Penhora?"
                name="penhora"
              >
                <a-radio-group v-model:value="model.penhora">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                  <a-radio-button :value="2">
                    Não se aplica
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('tipoPenhora')"
                label="Tipo penhora"
                name="tipoPenhora"
              >
                <a-input
                  v-model:value="model.tipoPenhora"
                  :allow-clear="true"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('valorPenhora')"
                label="Valor penhora"
                name="valorPenhora"
              >
                <CurrencyInput
                  v-model="model.valorPenhora"
                  :options="{
                    locale: 'pt-BR',
                    currency: 'BRL',
                    currencyDisplay: 'symbol',
                    hideCurrencySymbolOnFocus: false,
                    hideGroupingSeparatorOnFocus: false,
                    hideNegligibleDecimalDigitsOnFocus: false,
                    autoDecimalDigits: true,
                  }"
                />
              </a-form-item>
              <a-form-item
                v-if="props.mostrarTodos || props.mostrarCampos?.includes('substabelecido')"
                label="Substabelecido?"
                name="substabelecido"
              >
                <a-radio-group v-model:value="model.substabelecido">
                  <a-radio-button :value="1">
                    Sim
                  </a-radio-button>
                  <a-radio-button :value="0">
                    Não
                  </a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-collapse-panel>
          </a-collapse>

          <a-form-item name="carteirasContratos">
            <div style="margin-top: 16px">
              <CarteiraContratoManager
                v-model:value="model.carteirasContratos"
              />
            </div>
          </a-form-item>
        </template>
      </CompromissoCommandForm2>
    </template>
  </GqlView>
</template>
