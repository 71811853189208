<script setup>
import { FileExcelOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { ref, watch } from 'vue';
import { useCriarJob } from '~/composables/jobs/useCriarJob';

const emit = defineEmits(['close']);

const { runAsync: criarJob, status: criarJobStatus } = useCriarJob();

const arquivoXls = ref([]);
const xlsUploader = ref(null);
const xlsStatus = ref(null);
const xlsError = ref(false);

function xlsBeforeUpload(file)
{
  xlsError.value = false;

  const ext = file.name.split('.').pop().toLowerCase();
  if (ext !== 'xls' && ext !== 'xlsx')
  {
    xlsError.value = true;
    return false;
  }
  return true;
}

async function importar()
{
  if (arquivoXls.value.length === 0)
  {
    notification.error({
      duration: 5,
      message: 'A planilha excel é obrigatória para a importação',
    });
    return;
  }

  await xlsUploader.value.upload();

  if (xlsStatus.value === 'cancelled')
  {
    xlsStatus.value = null;
    return;
  }

  await criarJob({
    parametros: {
      arquivoXls: arquivoXls.value[0],
    },
    tipo: 51,
  });
}

watch(
  () => criarJobStatus.value,
  (val) =>
  {
    if (val === 'error')
    {
      notification.error({
        duration: 5,
        message: 'Tivemos um problema inesperado ao tentar importar',
      });
    }
  },
);

const activeKey = ref(null);
</script>

<template>
  <div style="padding-bottom: 24px">
    <DrawerTitle :closable="false" @close="() => emit('close')">
      <template #title>
        <span style="font-size: 16px; font-weight: 500; line-height: 35px">
          IMPORTAR PLANILHA DE STATUS DE ATUALIZAÇÕES GCPJ DA OYSTR</span>
      </template>
    </DrawerTitle>

    <div style="padding: 0 24px">
      <a-row>
        <a-col :span="24">
          <a-result
            v-if="criarJobStatus === 'success'"
            status="success"
            title="A sua importação está em processamento"
            sub-title="Você pode verificar o resultado na página de importações"
            style="margin-top: 24px"
          >
            <template #extra>
              <a-button @click="emit('close')">
                Fechar
              </a-button>
            </template>
          </a-result>
          <a-row v-else :gutter="24">
            <a-col :span="24">
              <a-row style="margin-top: 24px">
                <a-col span="24">
                  <a-alert
                    v-if="xlsError"
                    message="O arquivo deve possuir o formato .XLS ou .XLSX"
                    type="error"
                    style="margin-bottom: 24px"
                    closable
                    :after-close="() => (xlsError = false)"
                  />

                  <Uploader
                    ref="xlsUploader"
                    v-model:fileList="arquivoXls"
                    :only-one-file="true"
                    :before-upload="xlsBeforeUpload"
                    @start="() => (xlsStatus = 'uploading')"
                    @end="() => (xlsStatus = 'done')"
                  >
                    <template #description>
                      <p class="ant-upload-drag-icon">
                        <FileExcelOutlined />
                      </p>
                      <p class="ant-upload-text">
                        Clique ou arraste o arquivo EXCEL
                      </p>
                      <p class="ant-upload-hint">
                        Você pode baixar o padrão do EXCEL no link acima
                      </p>
                    </template>
                  </Uploader>
                </a-col>
              </a-row>

              <a-row style="margin-top: 24px">
                <a-button
                  :loading="
                    xlsStatus === 'uploading' || criarJobStatus === 'loading'
                  "
                  type="primary"
                  style="margin-right: 8px; min-width: 100px"
                  @click.prevent="importar"
                >
                  Importar
                </a-button>
                <a-button
                  :disabled="
                    xlsStatus === 'uploading' || criarJobStatus === 'loading'
                  "
                  @click="() => emit('close')"
                >
                  Fechar
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
