query atualizacaoGcpjDatatable(
    $referenciasGcpjsAndamentos: [UUID!]
    $referenciasGcpjsEncerramentos: [UUID!]
    $where: CompromissoModelFilterInput
    $order: [CompromissoModelSortInput!]
    $skip: Int
    $take: Int
) {
    data: referenciasGcpjOffsetPagination(
        referenciasGcpjsAndamentos: $referenciasGcpjsAndamentos
        referenciasGcpjsEncerramentos: $referenciasGcpjsEncerramentos
        where: $where
        order: $order
        skip: $skip
        take: $take
    ) {
        items {
            codigo         
            dataInicio
            dataInicioText
            dataConclusao
            dataConclusaoText
            responsavel {
                nome
            }
            anterior {
                data
                responsavel {
                    nome
                }
            }
            fluxo {
                pasta {
                    codigo
                    nome
                }
            }
        }
        totalCount
    }
}
