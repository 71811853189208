<script setup>
import { useGraphQLWithPooling } from '~/composables/useGraphQLWithPooling';
import { usePost } from '~/composables/usePost';
import { useAuthStore } from '~/stores/auth';
import { API_URL } from '~/utils';
import { notification } from 'ant-design-vue';
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  compromisso: {
    default: null,
    type: Object,
  },
  pastaCodigos: {
    default: () => null,
    type: Array,
  },
});

const emit = defineEmits(['close', 'loaded', 'success', 'error']);

const {
  data: concluirFluxoData,
  err: concluirFluxoErr,
  runAsync: concluirFluxoAsync,
  status: concluirFluxoStatus,
} = usePost({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  url: `${API_URL}${props.compromisso.url}`,
});

const jobStatus = ref(null);

const {
  cancel: jobPoolingCancel,
  data: jobPoolingData,
  runAsync: jobPoolingAsync,
  status: jobPoolingStatus,
} = useGraphQLWithPooling({
  config: {
    getAccessToken: true,
    timeout: 120000,
  },
  query: 'JOB_POOLING',
});

const authStore = useAuthStore();
const formRef = ref(null);
const formModel = reactive({
  observacao: null,
  arquivos: [],
});
const formRules = reactive();
const uploaderRef = ref(null);
const uploaderStatus = ref(null);

const uiSalvarAsync = async () => {
  formRef.value.validate().then(async () => {
    jobStatus.value = 'loading';

    await uploaderRef.value.upload();

    await concluirFluxoAsync({
      compromissoCodigos:
        props.compromisso !== null ? [props.compromisso.codigo] : null,
      pastaCodigos: props.pastaCodigos,
      observacao: formModel.observacao,
      arquivos: formModel.arquivos,
    });

    if (concluirFluxoStatus.value === 'error') {
      jobStatus.value = 'error';
      notification.error({
        description: concluirFluxoErr.value.description,
        duration: 5,
        message: concluirFluxoErr.value.message,
      });
      emit('error');
      return;
    }

    const jobCodigo = concluirFluxoData.value;

    await jobPoolingAsync({ codigo: jobCodigo });
  });
};

watch(jobPoolingStatus, (val) => {
  jobStatus.value = val;
  if (val === 'error') {
    notification.error({
      duration: 5,
      message:
        jobPoolingData.value?.job?.resultado?.errorMessage ??
        'Tivemos um problema inesperado',
    });
  }
  if (val === 'success') {
    emit('success');
  }
});

onMounted(() => {
  emit('loaded');
});

onUnmounted(() => {
  jobPoolingCancel();
});
</script>

<template>
  <div>
    <a-result
      v-if="jobStatus === 'success'"
      status="success"
      title="Registro realizado com sucesso"
      sub-title="Agora você pode fechar essa janela"
    >
      <template #extra>
        <a-button @click="() => emit('close')"> Fechar </a-button>
      </template>
    </a-result>

    <div v-if="jobStatus !== 'success'">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formModel"
        :rules="formRules"
        style="margin-top: 16px"
      >
        <a-form-item
          label="Observação"
          name="observacao"
          style="padding-bottom: 0"
        >
          <a-textarea v-model:value="formModel.observacao" :rows="4" />
        </a-form-item>

        <h3 style="margin-bottom: 24px">Anexar arquivos</h3>

        <a-row>
          <a-col :span="24">
            <Uploader
              ref="uploaderRef"
              v-model:fileList="formModel.arquivos"
              @start="() => (uploaderStatus = 'uploading')"
              @end="() => (uploaderStatus = 'done')"
            />
          </a-col>
        </a-row>

        <a-form-item style="margin-top: 24px; margin-bottom: 0px">
          <a-button
            :disabled="
              !isNilOrEmpty(compromisso) &&
              compromisso.responsavel.codigo !== authStore.codigoUsuario
            "
            :loading="jobStatus === 'loading'"
            type="primary"
            style="margin-right: 8px; min-width: 100px"
            @click.prevent="uiSalvarAsync"
          >
            Salvar
          </a-button>
          <a-button @click="() => emit('close')"> Fechar </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
