<script setup>
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import tipoPerfil from '~/constants/tipoPerfil';
import { useAuthStore } from '~/stores/auth';
import { useDrawerStore } from '~/stores/drawer';

const authStore = useAuthStore();
const drawerStore = useDrawerStore();

const tableRef = ref(null);
function onNovaAgenciaClick()
{
  drawerStore.push2({
    componentName: 'SalvarAgenciaForm',
    width: 600,
    onClose: async () =>
    {
      await tableRef.value?.reloadAsync();
    },
  });
}
</script>

<template>
  <Page menu="agencias">
    <div>
      <a-row justify="center">
        <a-col
          :xs="24"
          :md="24"
          :lg="24"
          :xl="24"
        >
          <!-- Page header -->
          <a-row>
            <a-col :span="12">
              <a-breadcrumb>
                <a-breadcrumb-item>
                  <router-link to="/agencias">
                    Agências
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>Buscar</a-breadcrumb-item>
              </a-breadcrumb>
              <h1>
                <UnorderedListOutlined
                  style="color: #bebcbc; font-size: 18px"
                />
                Buscar agências
              </h1>
            </a-col>
            <a-col :span="12" />
          </a-row>
          <!-- /Page header -->
          <div style="margin-top: 24px">
            <AgenciaGraphqlDatatable ref="tableRef">
              <template
                v-if="authStore.temPerfilAcesso(tipoPerfil.CriarAgencia)"
                #leftTopActions
              >
                <a-col flex="100px">
                  <a-button
                    size="large"
                    @click="onNovaAgenciaClick"
                  >
                    <template #icon>
                      <PlusOutlined />
                    </template>Nova agência
                  </a-button>
                </a-col>
              </template>
            </AgenciaGraphqlDatatable>
          </div>
        </a-col>
      </a-row>
    </div>
  </Page>
</template>
